import React from "react";


export const initialAuthState = {

    me: undefined,

};


const authStateReducer = function (state = initialAuthState, action) {
    window.authState = state

    switch (action.type) {

        case 'setMe':
            return {
                ...state,
                me: {
                    ...action.me,
                }
            }

        default:
            return state;
    }
}
export default authStateReducer;
export const AuthStateContext = React.createContext(initialAuthState)
