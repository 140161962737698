
const _dive = function (tokens, data, dflt=undefined) {

    if (tokens.length === 0)
        return data;

    if (!data)
        return dflt;

    const curKey = tokens.shift();

    if (data.hasOwnProperty(curKey)) {
        return _dive(tokens, data[curKey], dflt)
    }

    return dflt;
};

export const pick = function (route, data, dflt=undefined) {
    const tokenized = route.split('.');
    return _dive(tokenized, data, dflt);
};

export const objectOnlyKeys = function (obj, availableKeys) {
    return Object.entries(obj).reduce((acc, [key, value]) => (
        availableKeys.indexOf(key) > -1
            ? {...acc, [key]: value}
            : acc
    ), {})
}

export const objectExceptKey = function (obj, exceptKeys) {
    return Object.entries(obj).reduce((acc, [key, value]) => (
        exceptKeys.indexOf(key) === -1
            ? {...acc, [key]: value}
            : acc
    ), {})
}


const isDefinedObject = (item) => typeof item !== 'undefined' && item !== null

export const isDefined = (item) =>
    Array.isArray(item)
        ? item.map((isDef, itm) => isDef && isDefinedObject(itm))
        : isDefinedObject(item)
