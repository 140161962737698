import {
    getCubeDicts,
    getFieldDict,
    getFieldDictValues,

} from './data'

import {
    getCubeDictValues,
    getCubeQuantilesStruct

} from './stats'

import {
    isFieldScalar,
    isFieldUUID,
} from './field'


export const UNDEFINED_COLOR = '#ffffff'
export const UNDEFINED_COLOR_RGB = [255, 255, 255]

export function isHexColor(color) {
    return typeof color === 'string' && color.length > 0 && color[0] === '#'
}

export function rgbToHex(rgbColor) {
    const rgb = [
        Math.round(rgbColor[0]),
        Math.round(rgbColor[1]),
        Math.round(rgbColor[2])
    ]
    return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
}

export function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}

export function hexToRgba(hex, opacityByte) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
        opacityByte
    ] : null;
}

export function getVectorColor(color) {
    if (isHexColor(color)) color = hexToRgb(color)
    return color ?? UNDEFINED_COLOR_RGB
}

export function getVectorColorAlpha(color, opacity) {
    const opacityByte = Math.max(Math.min(Math.round(opacity * 255), 255), 0)
    if (isHexColor(color)) color = hexToRgba(color, opacityByte)
    if (color.length === 3) color = [...color, opacityByte]
    return color ?? [...UNDEFINED_COLOR_RGB, opacityByte]
}

export function paletteColor(value, color0, color1) {

    const c0 = isHexColor(color0) ? hexToRgb(color0) : color0;
    const c1 = isHexColor(color1) ? hexToRgb(color1): color1;

    return [
        (c1[0] - c0[0]) * value + c0[0],
        (c1[1] - c0[1]) * value + c0[1],
        (c1[2] - c0[2]) * value + c0[2],
    ]
}

export function quantileColor(value, quantiles, colors) {

    for (let i = 0; i < quantiles.length; i++) {
        if (quantiles[i] > value) {
            return isHexColor(colors[i]) ? hexToRgb(colors[i]) : colors[i]
        }
    }

    const theColor = colors[colors.length - 1]

    return isHexColor(theColor) ? hexToRgb(theColor) : theColor
}

export function getDictColors(dictValues, colors) {
    return dictValues?.reduce((acc, value, i) => ({
        ...acc,
        [value.id]: colors[i % colors.length]
    }), {})
}

export function paletteColorMinMax(value, min, max, color0, color1) {
    const scaledValue = value ? ((value - min) / (max - min)) : 0.5;
    return paletteColor(scaledValue, color0, color1)
}

export function stepPaletteColor(value, colors) {
    const colorIndex = Math.floor(value * colors.length)
    const color = colors[colorIndex]
    return isHexColor(color) ? hexToRgb(color) : color
}

export function strictPaletteColor(value, palette) {
    const index = Math.floor(value * palette.length)
    return palette[index]
}

export function paletteLinearGrad(c0, c1, colorNumber = null) {

    const color0 = isHexColor(c0) ? c0 : hexToRgb(c0)
    const color1 = isHexColor(c1) ? c1 : hexToRgb(c1)

    if (!colorNumber) {
        return `linear-gradient(90deg, ${color0}, ${color1})`
    }
}

export function twoColorsPalette(c0, c1, steps) {
    const gradStep = Math.floor(100 / steps)
    const colorPalette = []

    for (let i = 0; i < steps; i++) {
        colorPalette.push(paletteColor(gradStep * i / 100, c0, c1))
    }

    return colorPalette
}

export function stepPaletteLinearGrad(colors) {
    const gradStep = Math.floor(100 / (colors?.length ? colors?.length : 1))
    const gradColors = colors.map((c, i) => {
        const color = isHexColor(c) ? c : rgbToHex(c)
        return `${color} ${gradStep * i}% ${gradStep * (i + 1)}%`
    })
    return `linear-gradient(90deg, ${gradColors.join(', ')})`
}


export const gradientTable = {
    3: [
        ['#DFF2E5', '#A2CDAF', '#618D6F'],
        ['#E9F590', '#F5928B', '#83A2C1'],
        ['#C8E7B8', '#A2CDAF', '#4D8D2A'],
        ['#F8D0C4', '#FF6B3C', '#AC4E47'],

        ['#BAB5B5', '#FFDDDE', '#FF7579'],
        ['#DCE6F6', '#C7D1E0', '#5D7292'],
        ['#8A9DCF', '#FFD06E', '#FFF700'],
        ['#B0F091', '#F08B60', '#8C93ED'],

        ['#9ED4F0', '#F0EC86', '#F06E7B'],
        ['#3ABFC8', '#F5EAB6', '#F46064'],
        ['#5D73C5', '#DBE9CB', '#48CA88'],
        ['#5BCD7D', '#7F93DE', '#ED675C'],

        ['#CDE2F7', '#6796C2', '#82BDF5'],
        ['#EDF087', '#F09895', '#C0D9ED'],
    ],

    4: [
        ['#D1ECDA', '#A2CDAF', '#7CAD8B', '#618D6F'],
        ['#4B8297', '#95C8DB', '#FF7959', '#CD4D2F'],
        ['#64936C', '#85B28D', '#AC4E47', '#DF4438'],
        ['#815FA1', '#B59AD0', '#56B786', '#2F9460'],

        ['#E0C446', '#F7EB1A', '#FF6B3C', '#EB594D'],
        ['#5B8B98', '#C572B0', '#FF9F6E', '#F6E778'],
        ['#6E72AB', '#6CAE89', '#E0A08F', '#E0CB8F'],
        ['#9E8BCB', '#7CB8BE', '#DABDA3', '#FFF2A5'],

        ['#6C73BF', '#AEB2D8', '#FFE87F', '#D5B57C'],
        ['#C8D1E8', '#95B0DA', '#6D8DBE', '#6477A8'],
        ['#F58885', '#EB6D69', '#C16B68', '#8F7170'],
        ['#F0EBB8', '#F9ED82', '#D9D28E', '#BAB687'],

        ['#7563BE', '#56BD91', '#FF9267', '#FFE367'],
        ['#7E85DA', '#FFD06E', '#FF7448', '#EC376D'],
    ],

    5: [
        ['#D1ECDA', '#A2CDAF', '#94B39E', '#5CA171', '#618D6F'],
        ['#9C87F5', '#F5B5A9', '#CEF598', '#F5EFC4', '#D2F2E5'],
        ['#F0EDAF', '#A3F0C8', '#C5ADF0', '#F0C78B', '#F0A9A1'],
        ['#C177A4', '#F4F5A6', '#F5918E', '#76BEF5', '#7A9AB3'],

        ['#F9F2D1', '#FFE985', '#FFDA36', '#DBBC31', '#C8AD36'],
        ['#FFB582', '#DE7771', '#F188F5', '#BDAFDE', '#AFCAF8'],
        ['#4B8297', '#95C8DB', '#CEE3EB', '#FF7959', '#C05C44'],
        ['#4A7752', '#85B28D', '#DBE9CB', '#D8453A', '#BA4038'],

        ['#3E88A4', '#8CC3D8', '#FAE4CD', '#FFB059', '#D28A61'],
        ['#654882', '#8F67B6', '#F1E2FE', '#6BBB92', '#448C67'],
        ['#E3C022', '#ECE23E', '#E8BBAD', '#F3835F', '#B24927'],
    ],

    6: [
        ['#DFF3E6', '#C6E0CE', '#A3DBB4', '#97C9A6', '#8AB196', '#779E83'],
        ['#6D8DBE', '#95B0DA', '#C8D1E8', '#F0EBB8', '#F9ED82', '#EEDF51'],
        ['#C16B68', '#EB6D69', '#F58885', '#97C9A6', '#8AB196', '#779E83'],
        ['#EC5280', '#E2829F', '#C8D1E8', '#E8DDF3', '#BCA3D4', '#9774B8'],

        ['#6E72AB', '#6C73BF', '#9F88B5', '#FF9F6E', '#F3764E', '#EC5280'],
        ['#815FA1', '#9E8BCB', '#AEB2D8', '#F0EBB8', '#F9ED82', '#EEDF51'],
        ['#FACBA0', '#FFE87F', '#F58885', '#A2CEB0', '#C572B0', '#6477A8'],
        ['#E2829F', '#DAB1BD', '#DACFD2', '#E8DDF3', '#BCA3D4', '#9774B8'],
    ],

    7: [
        ['#D1ECDA', '#C6E0CE', '#ADD8B6', '#97C9A6', '#7CAD8B', '#6C9A7A', '#618D6F'],
        ['#6D8DBE', '#849EC6', '#9EB9E2', '#C0CBDC', '#7CAD8B', '#6C9A7A', '#618D6F'],
        ['#6D8DBE', '#849EC6', '#9EB9E2', '#C0CBDC', '#F0EBB8', '#F9ED82', '#EEDF51'],
        ['#9774B8', '#BCA3D4', '#D5BEED', '#E1D7EB', '#FBB5B3', '#F57E7B', '#D17A77'],

        ['#EC5280', '#E2829F', '#DCA6B6', '#EDD7DF', '#F0EBB8', '#F9ED82', '#EEDF51'],
        ['#9774B8', '#858EDE', '#F58885', '#FFDF6E', '#97C9A6', '#8BAF96', '#6E72AB'],
    ],

    8: [
        ['#E2EFE6', '#CFDED4', '#B0D2BB', '#91C3A0', '#8BB999', '#7AAF8A', '#679E78', '#499660'],
        ['#6D8DBE', '#849EC6', '#9EB9E2', '#C0CBDC', '#EBE9DB', '#F0EBB8', '#F9ED82', '#EEDF51'],
        ['#9774B8', '#AF90CD', '#D5BEED', '#E1D7EB', '#FEC4C3', '#FEAFAC', '#F57E7B', '#D17A77'],
        ['#E2EFE6', '#7AA6A0', '#97C7C0', '#D4E1DF', '#F2E1E7', '#DCA6B6', '#E2829F', '#EC5280'],

        ['#7D9685', '#9CB5A4', '#B5CEBD', '#D8E3DC', '#E8C9C2', '#E7AD9F', '#E2836C', '#DB654A'],
    ],

    9: [
        ['#DB654A', '#E2836C', '#E7AD9F', '#F2C6BB', '#DBE7DF', '#C5DBCC', '#A3BEAB', '#87A891', '#618D6F'],
        ['#EEDC2B', '#F8E377', '#F8F1B1', '#EEEBD6', '#DBE2E7', '#C0CBDC', '#9EB9E2', '#849EC6', '#6D8DBE'],
        ['#EC5280', '#E2829F', '#DCA6B6', '#F2E1E7', '#DBE2E7', '#E1D7EB', '#D5BEED', '#AF90CD', '#9774B8'],
        ['#DB654A', '#F9845E', '#F9EE8B', '#F8F3C5', '#DBE2E7', '#C0CBDC', '#9EB9E2', '#B28CD7', '#9D5EC3'],

        ['#F56746', '#F29172', '#F9EE8B', '#A3C0ED', '#D2B3EB', '#A4D8C8', '#7FCBC6', '#958BD2', '#C16390'],
    ],

    10: [
        ['#DB654A', '#E2836C', '#E7AD9F', '#F8CFC5', '#FCDAD2', '#DBE7DF', '#C5DBCC', '#A3BEAB', '#8BA793', '#71927B'],
        ['#EEDC2B', '#F8E377', '#F8F1B1', '#EEEBD6', '#F4F3E6', '#DBE2E7', '#C0CBDC', '#9EB9E2', '#849EC6', '#6D8DBE'],
        ['#EF9D51', '#EDB27B', '#EEC49D', '#F5DEC7', '#F9ECE0', '#F0E8F8', '#E1D7EB', '#D5BEED', '#AF90CD', '#9774B8'],
        ['#F07254', '#F2AE6F', '#F6E277', '#C6EB89', '#B3DFAC', '#93D1BF', '#A1ECF1', '#8BB4F1', '#BB93E2', '#E974AC'],
    ],

    11: [
        ['#DB654A', '#E2836C', '#E29F8E', '#E7AD9F', '#F8CFC5', '#FCDAD2', '#C5DBCC', '#A3BEAB', '#8BA793', '#71927B', '#618D6F'],
        ['#EEDC2B', '#F8E377', '#F8F1B1', '#EEEBD6', '#F4F3E6', '#DBE2E7', '#C0CBDC', '#9EB9E2', '#849EC6', '#6D8DBE', '#647B9F'],
        ['#EF9D51', '#EDB27B', '#EEC49D', '#F5DEC7', '#F9ECE0', '#E1D7EB', '#D0BBE5', '#AF90CD', '#9774B8', '#8764C0', '#855FA3'],
        ['#ED6262', '#F4A97F', '#EFE47F', '#D4DE98', '#9FD7B5', '#6BCEBC', '#79A9E1', '#A98BC7', '#BD68C5', '#E864B3', '#D35D87'],

        ['#DC6363', '#F0A056', '#F9CF3B', '#A5CD4F', '#14BF6D', '#49DEE8', '#2DA6CC', '#1C68DA', '#6C69E3', '#8C2FD4', '#E73939'],
        ['#FFE18B', '#FBB568', '#FB7F58', '#EE202E', '#D70761', '#9C48DE', '#24338A', '#0097DC', '#008A7C', '#00A850', '#ABD962'],
        ['#CEE3EB', '#AF90CD', '#EE6E94', '#A3BEAB', '#6D8DBE', '#85B28D', '#FF7959', '#FFB059', '#2A9BBE', '#CEF598', '#FFE367'],
        ['#D2F2E5', '#FCB0C0', '#C5ADF0', '#EADF5F', '#1A5AC7', '#4EAAD4', '#5CA171', '#F08B60', '#D5BEED', '#E84C29', '#D35D87'],
    ],

    20: [
        ['#F8A8A8', '#FE8D5D', '#FFB545', '#F4E65C', '#BBE785', '#91CE7C', '#5BB986', '#7FDFD9', '#4CBADD', '#5A89CF', '#9BA4F6', '#9E81CC', '#D8A3EB', '#DA85CC', '#DD4990', '#FBB5B3', '#9EB9E2', '#6C69E3', '#FF6161', '#FFD06E', ],
        ['#FF3838', '#FC7A42', '#F9AE3F', '#FEEA2B', '#8FE721', '#59D02F', '#1BB46A', '#25E5DA', '#27BEEE', '#2470E0', '#1F33ED', '#8D52ED', '#BE3EEB', '#FB24D8', '#DE1475', '#F1443F', '#3380F6', '#CEF598', '#F09EB7', '#C9511E', ],
    ]
}

const getIndexInt = function (index) {
    let intIndex = 0;

    if (typeof index === 'string') {
        for (let i = 0; i < index.length; i++) {
            intIndex += index.charCodeAt(i)
        }
    } else {
        intIndex = parseInt(index)
    }

    return intIndex
}

export const getNextColor = function (index) {
    return gradientTable[10][3][getIndexInt(index) % 10]
}

export const grayScalePalette = ['#ffffff', '#bbbbbb', '#777777', '#444444', '#000000']

export const getRandomColor = function () {
    const index = 11;
    const colors = gradientTable[index];
    const rx = Math.round(Math.random() * (colors.length - 1))
    const ry = Math.round(Math.random() * (colors[rx].length - 1))
    return colors[rx][ry]
}

export const gradientTableNumbers = Object.keys(gradientTable).map(item => parseInt(item))


export const getColorQuantile = (color, quantilesStruct) => Array.isArray(color) && quantilesStruct
    ? Object
        .values(quantilesStruct[color.length])
        .sort((a, b) => a > b ? 1 : -1)
    : null


export const computeColor = (cube, field, metricValue, colorPalette, colorQuantile) => {

    if (!Array.isArray(colorPalette)) return getVectorColor(colorPalette)
    if (metricValue === null) return getVectorColor(UNDEFINED_COLOR)
    if (colorPalette.length === 1) return getVectorColor(colorPalette[0])
    if (!field) return  getVectorColor(colorPalette[0])

    const fieldStruct = cube.struct.fields.find(cubeFld => cubeFld.name === field)

    const isScalar = isFieldScalar(fieldStruct)
    const isDict = isFieldUUID(fieldStruct) && getFieldDict({cube}, fieldStruct)
    const dictValues = getCubeDictValues(cube, field)
    const colorDict = getDictColors(dictValues, colorPalette)

    if (isScalar && colorQuantile) {
        return quantileColor(metricValue, colorQuantile, colorPalette)
    } else if (isDict) {
        return  colorDict.hasOwnProperty(metricValue)
            ? getVectorColor(colorDict[metricValue])
            : getVectorColor(UNDEFINED_COLOR)
    }

    return getVectorColor(UNDEFINED_COLOR)
}

export const incrementColorList = (cube, field, metricValue, colorPalette, colorList) => {
    const colorVector = computeColor(cube, field, metricValue, colorPalette)
    for (let ci = 0; ci < colorVector.length; ci++) {
        colorList.push(colorVector[ci])
    }

    return colorList
}