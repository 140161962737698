import { createMuiTheme } from '@material-ui/core/styles';


const color = {
    black: '#394053',
    blackGray: '#35424E',
    darkGray: '#ABBECD',
    semiGray: '#D9DFE4',
    gray: '#D9DFE4',
    lightGray: '#F5FAFF',
    red: '#FD886D'
}

const typography = {

    fontFamily: [
        // Roboto
        'Montserrat',
        'sans-serif'
    ].join(', '),

    h1: {
        fontSize: '18px'
    },

    h2: {
        fontSize: '16pt',
    },

    h3: {
        color: color.black,
        fontSize: '14pt',
        fontWeight: 'normal'
    },

    h4: {
        color: color.black,
        fontSize: '12pt',
        fontWeight: 'normal'
    },

    h5: {
        fontSize: '10pt',
    },

    h6: {
        fontSize: '8pt',
    },

    subtitle1: {

    },

    subtitle2: {

    },

    body1: {

    },

    body2: {

    }
}


const overrides = {
    MuiAccordionSummary: {
        content: {
            ...typography.h3
        }
    }
}

export const lokkTheme = createMuiTheme({
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    palette: {
        secondary: {
            main: color.red,
        },
    },
    color,
    overrides,
    typography,
});