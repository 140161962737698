import { gql } from "@apollo/client";

export const CreateStep = gql`
    mutation CreateStep (
        $title: String,
        $ordering: Int,
        $project_id: uuid,
        $datasets:jsonb,
        $dataviews:jsonb,
        $dataset_items:jsonb,
        $filters:jsonb,
        $algorithm:jsonb,
        $layers:jsonb,
        $metrics:jsonb
    ) {
        createProjectStep(
            object: {
                title: $title,
                project_id: $project_id,
                ordering: $ordering,
                datasets: $datasets,
                dataviews: $dataviews,
                dataset_items: $dataset_items,
                layers: $layers,
                metrics: $metrics,
                filters:$filters,
                algorithm:$algorithm
            },
            on_conflict: {
                constraint: project_step_sat_project_id_ordering_key,
                update_columns: [
                    datasets, dataviews,
                    layers, metrics, filters, algorithm
                ]
            }) {
            id
            title
            ordering
        }
    }
`;
/*{
    "datasets": {},
    "dataviews": {},
    "filters":{},
    "layers":{},
    "metrics": {},
    "algorithm": {},
    "title":"",
    "ordering": 0,
    "project_id":""
}*/


export const SaveStepMeta = gql`
    mutation SaveStepMeta (
        $id: uuid!,
        $title: String,
        $about: String,
    ) {
        updateProjectStep(
            pk_columns: {id: $id}, 
            _set: {
                about: $about, 
                title: $title
            }
        ) {
            id
            title
            about
        }
    }
`;


export const UpdateStep = gql`
    mutation UpdateStep (
        $id:uuid!,
        $datasets:jsonb,
        $dataviews:jsonb,
        $dataset_items:jsonb,
        $filters:jsonb,
        $algorithm:jsonb,
        $layers:jsonb,
        $metrics:jsonb
    ) {
        updateProjectStep(
            pk_columns: {id: $id},
            _set: {
                datasets: $datasets,
                dataviews: $dataviews,
                dataset_items: $dataset_items,
                filters: $filters,
                algorithm: $algorithm,
                layers: $layers,
                metrics: $metrics
            }
        ) {
            id
            title
            about
        }
    }
`;


export const UpdateViewStateInStep = gql`
    mutation UpdateViewState (
        $id:uuid!,
        $viewState:jsonb
    ) {
        updateProjectStep(
            pk_columns: {id: $id},
            _set: {
                view_state: $viewState,
            }
        ) {
            id
            title
            about
        }
    }
`;

export const DeleteStep = gql`
    mutation DeleteProjectStep (
        $id:uuid!
    ) {
        deleteProjectStep(
            id: $id
        ) {
            id
        }
    }
`;
