import React, {useState, useEffect} from 'react';
import {useMutation} from "@apollo/client";
import {useHistory} from 'react-router-dom'
import {
    Step,
    StepLabel,
    StepContent,
    Stepper,
    Paper,
    IconButton,
    Container,
    Typography,
    Button,
    Grid,
    LinearProgress,
    Snackbar
} from '@material-ui/core'
import {
    Alert
} from '@material-ui/lab'
import {makeStyles} from '@material-ui/core/styles';
import {ProjectCreate} from '../../../actions/gql/projectQuery'

import {
    AddCircle
} from '@material-ui/icons'

import {BarChart} from '@material-ui/icons'
import Loader from 'react-loader-spinner'

import css from 'styled-components'

import {appConfig, ApiEndpoint, webLink} from '../../../config'
import apolloClient from '../../../clients/apolloClient'
import {ProjectsQuery} from '../../../actions/gql/app'

import TopBar from '../../../components/topbar'

import {
    MetaInfoStep,
    ScaleStep,
    LocationStep,
} from './steps'
import {useTranslation} from "react-i18next";

const Panel = css.div`
    position: relative;
    box-sizing: border-box;
    padding: 120px 0;
    height: 100vh;
    width: 100%;
    background-color: #29323C;    
`
const ClickArea = css.div`
    cursor: pointer;
`
const PlusIconPlace = css.div`
    height: 120px;
    text-align: center;
`
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    stepper: {
        background: '#F5FAFF',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    widgetContainer: {
        margin: '64px 0',
    },
    finishContainer: {
        padding: theme.spacing(3),
        background: '#F5FAFF',
    },
    finishPhrase: {
        marginBottom: theme.spacing(2),
    }
}));


function getScale(scale) {
    return ['City', 'Region', 'Country'][scale]
}


function getSteps(activeStep, projectData, t) {
    return [
        t('project_new.meta_info_step.title') + ' ' + (activeStep > 0 && projectData.project.name ? ': ' + projectData.project.name : ''),
        // 'Select the scale' + (activeStep > 1 && projectData.project.scale > -1 ? ': ' + getScale(projectData.project.scale) : ''),
        t('project_new.location_step.title') + ' ' + (activeStep > 1 && projectData.geom.properties?.name > -1 ? ': ' + projectData.geom.properties?.name : ''),
    ];
}

function getStepContent(step, t) {
    switch (step) {
        case 0:
            return t('project_new.meta_info_step.text');
        // case 1:
        //     return 'Scale affects the types of available information in layers and types of algorithms';
        case 1:
            return t('project_new.location_step.text');
        default:
            return 'Unknown step';
    }
}

function getStepWidget(step, projectData, setProjectData) {

    const handleSetProjectData = (data) => {

        setProjectData((prevState) => {
            return {
                project: {
                    ...prevState.project,
                    ...data.project ? data.project : {}
                },
                geom: {
                    ...prevState.geom,
                    ...data.geom ? data.geom : {}
                }
            }
        })
    }

    switch (step) {
        case 0:
            return <MetaInfoStep
                projectData={projectData}
                onChange={(data) => {
                    handleSetProjectData(data)
                }}
            />;
/*
        case 1:
            return <ScaleStep
                projectData={projectData}
                onChange={(data) => {
                    handleSetProjectData(data)
                }}
            />;
*/
        case 1:
            return <LocationStep
                projectData={projectData}
                onChange={(data) => {
                    handleSetProjectData(data)
                }}
            />;
        default:
            return;
    }
}

// Viewport settings
function Map() {

    const classes = useStyles();
    const history = useHistory();

    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(0);
    const [projectData, setProjectData] = useState({
        project: {
            title: '',
            about: '',
            scale: 0,
        },
        geom: {
            type: 'Point',
            coordinates: null
        }
    })

    const [isNextAvailable, setNextAvailable] = useState(false)
    useEffect(() => {

        if (activeStep === 0) {
            setNextAvailable(
                projectData.project.title.length > 0
            )
        }

        if (activeStep === 1) {
            setNextAvailable (
                projectData.geom && projectData.geom.coordinates
            )
        }

    }, [projectData, activeStep])

    const [successPopup, setSuccessPopup] = useState({
        open: false,
        message: t('project_new.crated')
    })

    const [errorPopup, setErrorPopup] = useState({
        open: false,
        message: t('project_new.error')
    })

    const steps = getSteps(activeStep, projectData, t);
    const [projectCreate, projectCreateResponse] = useMutation(ProjectCreate, {
        client: apolloClient,
    })

    useEffect(() => {
        // const { data, loading, called, error } = projectCreateResponse;
        // ???
    }, [projectCreateResponse])


    useEffect(() => {
        if (activeStep === steps.length) {
            handleCreate()
        }

    }, [activeStep])


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {

        if (activeStep === 0) {
            history.push(webLink('root'))
        }

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleCreate = () => {
        projectCreate({variables: projectData})
            .then((data) => {
                const { data: { projectCreate: { project_id, project_key } } } = data;
                history.push(webLink('project', project_key, 'new=true'))
            })
            .catch((err) => {
                setErrorPopup(prevState => ({
                    ...prevState,
                    open: true
                }))
            })
    }

    return (
        <>

            <TopBar/>

            <Panel>
                <Container maxWidth="lg">
                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                    <StepContent>
                                        <Typography>{getStepContent(index, t)}</Typography>
                                        <div className={classes.widgetContainer}>
                                            {getStepWidget(index, projectData, setProjectData)}
                                        </div>
                                        <div className={classes.actionsContainer}>
                                            <div>
                                                <Button
                                                    onClick={handleBack}
                                                    className={classes.button}
                                                >
                                                    {activeStep === 0 ? t('project_new.cancel') : t('project_new.back')}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                    disabled={!isNextAvailable}
                                                >
                                                    {activeStep === steps.length - 1 ? t('project_new.finish') : t('project_new.next')}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.finishContainer}>
                                <Typography className={classes.finishPhrase}>{t('project_new.saving')}</Typography>
                                <LinearProgress/>
                            </Paper>
                        )}
                    </div>
                </Container>
            </Panel>

            <Snackbar open={successPopup.open}
                      onClose={() => setSuccessPopup(prevState => ({
                          ...prevState,
                          open: false
                      }))}
                      autoHideDuration={6000} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Alert severity="success">
                    {successPopup.message}
                </Alert>
            </Snackbar>

            <Snackbar open={errorPopup.open}
                      onClose={() => setErrorPopup(prevState => ({
                          ...prevState,
                          open: false
                      }))}
                      autoHideDuration={6000} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Alert severity="error">
                    {errorPopup.message}
                </Alert>
            </Snackbar>

        </>
    );
}

export default Map;