import React, {useEffect, useState} from "react";
import {
    Typography,
    Slider,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1)
    },
}));


export default function LineWidthSelector(props) {
    const classes = useStyles();

    const {title, value: outerValue, onChange} = props

    const [innerValue, setInnerValue] = useState(0.2)
    useEffect(() => {
        if (innerValue !== outerValue) setInnerValue(outerValue)
    }, [outerValue])

    return (
        <div className={classes.root}>
            <Typography gutterBottom>
                {title}
            </Typography>

            <Slider
                value={innerValue}
                min={1}
                step={1}
                max={20}
                getAriaValueText={(value) => (Math.round(value) + 'px')}
                valueLabelFormat={(value) => (Math.round(value) + 'px')}
                onChange={(e, value) => {
                    setInnerValue(value)
                }}
                onChangeCommitted={(e, value) => {
                    if (onChange) onChange(value)
                }}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
            />
        </div>
    )

}