import React, {useState, useEffect, useReducer, useContext} from "react";

import {
    FormControlLabel,
    Switch
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {getCubeData, NATURAL_ORDERING} from '../../../../../../actions/app/data'

import FilterFabric from './filter-fabric'
import {DataStateContext} from "../../../reducer";
import {isFieldScalar} from "../../../../../../helpers/field";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },

    orderingField: {
        width: 280
    },

    enabledControl: {
        marginTop: '0.5rem'
    },

}));

const cubeFilterCache = null
const FilterChain = (props) => {
    const classes = useStyles();

    const { cube } = props

    const {dataState, dataDispatch} = useContext(DataStateContext)
    const { filter } = dataState
    const cubeFilter = filter[cube.id]
    const {fields, enabled, ordering, limit, offset} = cubeFilter
        ? cubeFilter
        : {
            fields: {},
            enabled: true,
            ordering: NATURAL_ORDERING,
            limit: undefined,
            offset: 0
        }

    const [cubeData, setCubeData] = useState([])

    const usedFieldsHash = fields ? Object.keys(fields).join('') : ''
    useEffect(() => {
        getCubeData(cube, fields, ordering, enabled, true)
            .then((data) => {
                setCubeData(data)
            }).catch((e) => {
                console.log('E_fc> ', e)
            })
    }, [fields, ordering])

    const [scalarsState, resetScalars] = useState(new Date())
    const handleResetScalars = () => resetScalars(new Date())

    // todo: current
    // useLiveQuery(async () => {
    //
    //     return await getCubeData(cube, fields, ordering, enabled, true)
    //
    // }, [fields, enabled, ordering])

    // useEffect(() => {
    //     if (data)
    //         setCubeData(data)
    // }, [data])

    const handleChange = (field, value, naturalValue) => {
        dataDispatch({
            type: 'setFilter',
            cube_id: cube.id,
            cube_name: cube.name,
            cube_object: cube.struct?.fields.find(fld => fld.is_object)?.name,
            fields: {
                ...fields,
                [field]: {
                    value,
                    naturalValue: naturalValue ? naturalValue : value
                }
            },
            enabled,
            limit,
            offset,
            ordering
        });
    }

    const handleEnable = (isEnable) => {
        dataDispatch({
            type: 'setFilter',
            cube_id: cube.id,
            cube_name: cube.name,
            cube_object: cube.struct?.fields.find(fld => fld.is_object)?.name,
            enabled: isEnable,
            fields,
            ordering,
            limit,
            offset
        });
    }

    // const handleLimitOffset = ([newOffset, newLimit]) => {
    //     dataDispatch({
    //         type: 'setFilter',
    //         cube_id: cube.id,
    //         enabled: isEnabled,
    //         fields,
    //         ordering,
    //         limit: newLimit,
    //         offset: newOffset
    //     });
    // }

    return (
        <div className={classes.root}>

            <FormControlLabel
                control={<Switch
                    size="small"
                    color="secondary"
                    checked={enabled}
                    onChange={() => handleEnable(!enabled)}
                />}
                label="Filter is enabled"
                className={classes.enabledControl}
            />

{/*todo: current*/}

            <div style={{opacity: enabled ? 1 : 0.5}}>
                {
                    cube?.struct?.fields && cube?.struct?.fields
                        .filter(field => field.is_filter !== false)
                        .map((field) => {
                        const dict = cube.dicts.find(dct => dct.field === field.name)
                        const stats = cube.stats[field.name]
                        const timeseries = cube.struct.timeseries;
                        const isScalar = isFieldScalar(field)

                        return (
                            <FilterFabric
                                stats={stats}
                                field={field}
                                value={fields[field.name]}
                                dict={dict}
                                data={cubeData}
                                limit={limit}
                                offset={offset}
                                timeseries={timeseries}
                                onChange={handleChange}
                                onResetScalars={handleResetScalars}
                                scalarsState={isScalar ? scalarsState : null}
                                // onLimitOffset={handleLimitOffset}
                            />
                        );
                    })
                }
            </div>

        </div>
    );
}

export default FilterChain;