import {v4 as uuidV4} from 'uuid'
import {getNextColor} from "../helpers/color";
import {getLitera} from "../helpers/repr";


const metricDefaults = {
    index: 0,
    title: '',
    litera: 'A',
    color: '#00FF00',
    metric: {
        id: '',
        key: '',
        meta: {
            title: '',
            about: '',
            copyright: '',
        }
    },
    dataset: {

    },
    object: {

    },
    objectType: {

    }
}

export const MetricModel = (props, objectType, projectObjectType) => {

    const { title, index, id } = props
    const color = getNextColor(index);
    const litera = getLitera(index);

    const metricId = id ? id : uuidV4()
    const metricKey = metricId

    return {
        ...metricDefaults,
        ...props,
        id: metricId,
        index,
        color,
        litera,
        title,
        metric: {
            id: metricId,
            key: metricKey,
            meta: {
                title,
                about: '',
                copyright: '',
            }
        },
        ver: 1,
        object: objectType,
        dataset: projectObjectType,
        objectType: projectObjectType,
    }
}