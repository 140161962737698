import React from "react";
import Scrollbar from "react-perfect-scrollbar";
import {Avatar, Divider, Grid, IconButton, Input, ListItem, ListItemSecondaryAction, Tooltip} from "@material-ui/core";
import {Close, Delete} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 15px',
        margin: '10px 0',
        boxSizing: 'border-box'
    },

    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    controls: {
        position: 'relative',
        margin: '10px 0',
        textAlign: 'center',
    },

    fieldChip: {
        display: 'inline-block',
        maxWidth: 120,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '12px',
        lineHeight: '32px',
    },

    fieldWeight: {
        display: 'inline-block',
        width: 80,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '12px',
        lineHeight: '32px',
        textAlign: 'right'
    },

    operandWeightLabel: {
        textAlign: 'right'
    },

    operandRow: {
        margin: '2px 0'
    },

    addItemButton: {
        paddingTop: '1rem'
    },

    controlDivider: {
        margin: '1rem 0'
    },

    selectFieldPlace: {
        marginLeft: 10
    },

    lineWidthControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    deleteButton: {
        margin: 0,
        float: 'right'
    },


}));

export const GravityIndexForm = (props) => {
    const {metric, lineage, onDelete, onModify} = props
    const {algorithm, filters, operands, params} = lineage
    const { t } = useTranslation();

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div>

                <Grid container className={classes.operandRow}>
                    <Grid item xs={8}>
                        {t('widgets.alg_form.metric_and_field')}
                    </Grid>
                    <Grid item xs={4} className={classes.operandWeightLabel}>
                        {t('widgets.alg_form.weight')}
                    </Grid>
                </Grid>
                {
                    Object.entries(operands).map(((operandKeyPair) => {
                        const [key, operand] = operandKeyPair
                        return (
                            <Grid container key={key} className={classes.operandRow}>
                                <Grid item xs={2}>
                                    <Tooltip title={operand.tag}>
                                        <Avatar style={{
                                            // backgroundColor: operand.metric.color
                                        }} className={classes.litera}>{operand.tag[0]}</Avatar>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                    <Tooltip title={operand.value}>
                                        <span className={classes.fieldChip}>{operand.value}</span>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={4}>
                                    <span className={classes.fieldWeight}>{operand.weight}</span>
                                </Grid>
                            </Grid>
                        )
                    }))
                }
            </div>
            <Divider className={classes.controlDivider}/>
            <div className={classes.controls}>
                <Button
                    onClick={() => {
                        if (onModify) onModify({metric, lineage})
                    }}
                    variant="contained"
                    size="small"
                >
                    {t('widgets.alg_form.modify')}
                </Button>

                <IconButton edge="end" aria-label="delete" size="small" className={classes.deleteButton}>
                    <Delete
                        onClick={() => {
                            if (onDelete) onDelete(metric)
                        }}
                        fontSize="small" />
                </IconButton>
            </div>
        </div>
    )
}
