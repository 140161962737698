import React, {useEffect, useState} from "react";
import {
    Avatar,
    Button,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputBase,
    Divider,
    IconButton,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions
} from "@material-ui/core";
import {
    Search
} from "@material-ui/icons";
import {
    TreeView
} from "@material-ui/lab"
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
    MinusSquare, PlusSquare, CloseSquare,
    EmptySquare, CheckedSquare, Circle
} from '../../../../../../components/icons'
import {StyledTreeItem, LiteraTreeItem} from '../../../../../../components/tree'

import {getNextColor, getRandomColor} from '../../../../../../helpers/color'
import {getLitera} from '../../../../../../helpers/repr'
import Scrollbar from "react-perfect-scrollbar";
import {useTranslation} from "react-i18next";


const DSAccordion = withStyles({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto 16px auto auto',
        },
    },
    expanded: {},
})(Accordion);

const DSAccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(AccordionSummary);

const DSAccordionDetails = withStyles((theme) => ({
    root: {
        display: 'block',
        padding: theme.spacing(1),
    },
}))(AccordionDetails);


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        // padding: theme.spacing(0.5),
        background: 'none',
        padding: 0,
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
        maxWidth: 130,
        textOverflow: 'ellipsis'
    },
    tree: {
        minHeight: 300,
        flexGrow: 1,
        minWidth: 220,
        maxHeight: '65vh',
        // maxWidth: '80vw',
    },
    controls: {
        marginTop: theme.spacing(1),
        textAlign: 'center'
    },
    dialog: {
        transition: 'all 0.3s easeInOutSine',
        // width: '70vw',
        // height: '70vh'
    },

    elementRow: {
        minHeight: 48,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),

        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        '&:last-child': {
            borderBottom: 0,
        },
    },

    elementLitera: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: 48,
        // height: 48
    },

    elementTitle: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },

    metricTreeItem: {
        label: {
            paddingLeft: 8
        }
    },

    list: {},

    dialogInner: {
        // width: '65vw',
        // height: '65vh'
        transition: 'all 0.4s ease'
    },

    dialogObjects: {
        // height: '65vh',
        overflowX: 'none'
    },

    dialogDatasets: {
        height: '65vh',
    },

    datasetItem: {
        marginRight: theme.spacing(2),
        '&.expanded': {
            marginRight: theme.spacing(2)
        }
    },

    listHeader: {
        fontSize: '1rem',
        fontWeight: 'bold',
        background: theme.color.darkGray
    },

    listDatasetItem: {
        borderBottom: '1px solid rgba(0,0,0,0.12)'
    },

    listItemText: {
        '& span': {
            fontSize: 12
        }
    },

    dialogTitle: {
        position: 'relative'
    },

    searchControl: {
        position: 'absolute',
        top: theme.spacing(2),
        right: 40
    },

    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1
    },

    searchIconButton: {
        padding: 10
    },

}));


const MetricTreeLayer = (props) => {
    const classes = useStyles();

    const {element, env, state, nextIndex} = props
    // const [endIcon, setEndIcon] = React.useState(<Circle/>)

    const isChecked = Object.keys(state).indexOf(element.id) > -1

    const color = isChecked ? state[element.id].color : getNextColor(nextIndex);
    const litera = isChecked ? state[element.id].litera : getLitera(nextIndex);
    const index = isChecked ? state[element.id].index : nextIndex
    const endIcon = isChecked ? <Avatar style={{
        backgroundColor: color
    }} className={classes.litera}>{litera}</Avatar> : <Circle/>

    const handleItemClick = (e) => {

        if (props.onChange) {
            const stateElement = {
                ...env,
                ...element,
                title: element.meta.title,
                metric: element,
                is_metric: true
            }
            props.onChange(e, stateElement, !isChecked, color, litera, index)
        }
    }

    return (
        <Grid container
              wrap="nowrap"
              spacing={1}
              onClick={(e) => {
                handleItemClick(e)}}

              className={classes.elementRow}
        >
            <Grid item className={classes.elementLitera}>
                {endIcon}
            </Grid>
            <Grid item xs className={classes.elementTitle}>
                <Typography>{element.meta.title}</Typography>
            </Grid>
        </Grid>
    );

    /*
        return <LiteraTreeItem nodeId={root.id}
                               label={root.meta.title}
                               endIcon={endIcon}
                               className={classes.metricTreeItem}
                               onClick={(e) => {

                                   setIsChecked(!isChecked)

                                   if (props.onChange) {
                                       props.onChange(e, {
                                           ...env,
                                           id: root.id,
                                           title: root.meta.title,
                                           index: nextIndex,
                                           litera: litera,
                                           color: color,
                                           metric: root,
                                           is_metric: false
                                       }, !isChecked)
                                   }
                               }}
            // expandIcon={!hasChildren ? <CheckedSquare /> : undefined}
        />;
    */
};


const TreeLayer = (props) => {
    const {root, env, state, nextIndex, isRoot} = props
    const isObjectTypeSelect = Object.keys(env).length === 0;
    const hasChildren = !!root.children && root.children.length > 0;
    const hasDatasets = !!root.datasets && root.datasets.length > 0;
    const hasMetrics = !!root.metrics && root.metrics.length > 0;
    const [endIcon, setEndIcon] = React.useState(isObjectTypeSelect && !hasChildren ? <CloseSquare/> : undefined)
    const [isChecked, setIsChecked] = React.useState(root.id in state)

    const hasAnyChild = hasChildren || hasDatasets || hasMetrics;
    const isSelectableObjectType = !hasAnyChild;

    React.useEffect(() => {

        if (isObjectTypeSelect && !hasChildren) {
            setEndIcon(<CloseSquare/>)
            return
        }

        setEndIcon(isChecked && !hasAnyChild ? <CheckedSquare/> : <EmptySquare/>)

    }, [isChecked])


    let children = (hasChildren ? root.children.map((item) => {
        return <TreeLayer state={state}
                          root={item}
                          nextIndex={nextIndex}
                          env={{
                              ...env,
                              objectType: root
                          }}
                          onSelect={(e, el, isChecked) => props.onSelect(e, el, isChecked)}
                          onChange={(e, stateElement, isChecked, color, litera, index) => {
                              if (props.onChange) {
                                  props.onChange(e, stateElement, isChecked, color, litera, index)
                              }
                          }}/>
    }) : [])
    // .concat(hasDatasets ? root.datasets.map((itemDataset) => {
    //     return <TreeLayer state={state}
    //                       root={itemDataset}
    //                       nextIndex={nextIndex}
    //                       env={{
    //                           ...env,
    //                           object: root
    //                       }}
    //                       onChange={(e, el, isChecked) => {
    //                           if (props.onChange) {
    //                               props.onChange(e, el, isChecked)
    //                           }
    //                       }}/>
    // }) : []).concat(hasMetrics ? root.metrics.map((itemMetric) => {
    //     return <MetricTreeLayer state={state}
    //                             root={itemMetric}
    //                             nextIndex={nextIndex}
    //                             env={{
    //                                 ...env,
    //                                 dataset: root
    //                             }}
    //                             onChange={(e, el, isChecked) => {
    //                                 if (props.onChange) {
    //                                     props.onChange(e, el, isChecked)
    //                                 }
    //                             }}/>
    // }) : [])

    return <StyledTreeItem nodeId={root.id}
                           label={root.meta.title}
                           endIcon={endIcon}
                           onClick={(e) => {

                               // if (hasChildren || hasDatasets || hasMetrics || isRoot) {
                               //     return;
                               // }

                               if (isRoot) {
                                   return
                               }

                               setIsChecked(!isChecked)

                               if (props.onSelect) {
                                   props.onSelect(e, {
                                       ...env,
                                       id: root.id,
                                       title: root.meta.title,
                                       index: -1,
                                       litera: null,
                                       color: getRandomColor(),
                                       metric: null,
                                       dataset: null,
                                       object: root,
                                       is_metric: false,
                                   }, !isChecked)
                               }

                               if (isSelectableObjectType && props.onChange) {
                                   const color = isChecked ? state[root.id].color : getNextColor(nextIndex);
                                   const litera = isChecked ? state[root.id].litera : getLitera(nextIndex);
                                   const index = isChecked ? state[root.id].index : nextIndex

                                   const stateElement = {
                                       ...env,
                                       ...root,
                                       color,
                                       litera,
                                       index,
                                       title: root.meta.title,
                                       metric: null,
                                       dataset: null,
                                       object: root,
                                       is_metric: false,
                                   }
                                   props.onChange(e, stateElement, !isChecked, color, litera, index)
                               }

                           }}
        // expandIcon={!hasChildren ? <CheckedSquare /> : undefined}
    >{children}</StyledTreeItem>;
}


const DataViewDialog = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const isFullScreen = window.innerWidth < 420;

    const {data, selected} = props;
    const reducedSelected = selected

    const reducedIndex = Object.values(selected).reduce((mx, item) => (
        item.index > mx ? item.index : mx
    ), 0)

    const [state, setState] = React.useState(reducedSelected)
    const [nextIndex, setNextIndex] = React.useState(reducedIndex)

    const [selectedObject, setSelectedObject] = React.useState(null)
    const [expandedDatasets, setExpandedDatasets] = React.useState([])

    useEffect(() => {
        setState(reducedSelected)
    }, [selected])

    const handleClose = () => {
        props.onClose();
    };

    const handleOk = () => {

        const checkedItems = Object.entries(state)
            .filter(([id, treeItem]) => treeItem !== null)
            .map(([id, treeItem]) => treeItem)

        if (props.onOk) {
            props.onOk(checkedItems)
        }

        props.onClose()
    }

    const handleElementSelect = (e, element, isChecked, color, litera, index) => {
        setState(oldState => {
            if (!isChecked) {
                return Object.values(oldState).filter(item => item.id !== element.id).reduce((agg, item) => ({
                    ...agg,
                    [item.id]: item
                }), {})
            }

            return {
                ...oldState,
                [element.id]: {
                    ...element,
                    color,
                    litera,
                    index
                }
            }
        });
    }

    useEffect(() => {
        const idx = Object.values(state).reduce((mx, item) => (
            item.index > mx ? item.index : mx
        ), -1)
        setNextIndex(idx + 1)
    }, [state]);

    const checkedItems = Object.entries(state)
        .filter(([id, treeItem]) => treeItem !== null)
        .map(([id, treeItem]) => treeItem)


    const handleObjectSelection = function (e, object, isChecked) {
        setSelectedObject(object)
    }

    const handleExpandDataset = (datasetId) => (e, isExpanded) => {
        setExpandedDatasets((oldExpanded) => (isExpanded
            ? oldExpanded.concat([datasetId])
            : oldExpanded.filter(id => id !== datasetId)))
    }

    const isPureObject = selectedObject && (!selectedObject?.object?.datasets || selectedObject?.object?.datasets?.length === 0)

    const isDatasets = selectedObject?.object?.datasets?.length > 0
    const columnSize = isDatasets ? 4 : 12
    const innerDialogStyle = isDatasets ? {width: '65vw'} : {width: 'auto'}

    const [searchPhrase, setSearchPrase] = useState(null)
    const handleSearch = (search) => {
        if (search.length < 3) {
            setSearchPrase(null)
            return
        }

        setSearchPrase(search)
    }

    return (
        <Dialog
            open={props.isOpened}
            onClose={handleClose}
            aria-labelledby="object-type-dialog-title"
            aria-describedby="object-type-dialog-description"
            className={classes.dialog}
            fullScreen={isFullScreen}
            maxWidth='xl'
        >
            <DialogTitle id="object-type-dialog-title" className={classes.dialogTitle}>
                {t('project.leftbar.panel1.popup.data.title')}
                {isDatasets &&
                    <div className={classes.searchControl}>
                        <InputBase
                            className={classes.searchInput}
                            placeholder={t('project.leftbar.panel1.popup.data.search')} onChange={(e) => handleSearch(e.target.value)} />

                        <IconButton type="submit" className={classes.searchIconButton}>
                            <Search />
                        </IconButton>
                    </div>
                }
            </DialogTitle>
            <DialogContent>
                <div className={classes.dialogInner}  style={innerDialogStyle}>
                    <Grid container>
                        <Grid item xs={columnSize} className={classes.dialogObjects} key="g01">
                            <Scrollbar>
                                <TreeView
                                    className={classes.tree}
                                    multiSelect={true}
                                    defaultExpanded={['1']}
                                    defaultCollapseIcon={<MinusSquare/>}
                                    defaultExpandIcon={<PlusSquare/>}
                                    defaultEndIcon={<EmptySquare/>}
                                    defaultChecked={<CheckedSquare/>}
                                    // defaultSelected={<CheckedSquare />}
                                    // onNodeSelect={(e, value) => console.log('V>', value)}
                                >

                                    <StyledTreeItem nodeId="1" label="Object Types">
                                        {
                                            data.map((item) => <TreeLayer state={state}
                                                                          root={item}
                                                                          env={{}}
                                                                          isRoot={true}
                                                                          nextIndex={nextIndex}
                                                                          onChange={handleElementSelect}
                                                                          onSelect={handleObjectSelection} />)
                                        }
                                    </StyledTreeItem>
                                </TreeView>
                            </Scrollbar>
                        </Grid>
                        {isDatasets &&
                        <Grid item xs={8} className={classes.dialogDatasets} key="g02">
                            <Scrollbar>
                                {selectedObject?.object?.datasets
                                    .sort((a, b) => {

                                        if (!searchPhrase) return 0;

                                        const aIndex = a.meta.title.toLowerCase().indexOf(searchPhrase.toLowerCase());
                                        const bIndex = b.meta.title.toLowerCase().indexOf(searchPhrase.toLowerCase());

                                        if (aIndex > -1 && bIndex === -1) return -1
                                        if (bIndex > -1 && aIndex === -1) return 1

                                        return aIndex < bIndex ? -1 : 1
                                    })
                                    .map(dataset => {
                                    const isExpanded = expandedDatasets.indexOf(dataset.id) > -1
                                    return (
                                        <div>
                                            {/*<h3>{dataset.meta.title}</h3>*/}
                                            <DSAccordion square
                                                         expanded={isExpanded}
                                                         onChange={handleExpandDataset(dataset.id)}
                                                         className={classes.datasetItem}
                                            >
                                                <DSAccordionSummary>
                                                    <Typography>{dataset?.meta?.title}</Typography>
                                                </DSAccordionSummary>
                                                <DSAccordionDetails>
                                                    {isExpanded && dataset.metrics.map(metric => {
                                                        return (
                                                            <MetricTreeLayer state={state}
                                                                             element={metric}
                                                                             nextIndex={nextIndex}
                                                                             env={{
                                                                                 ...selectedObject,
                                                                                 dataset
                                                                             }}
                                                                             onChange={handleElementSelect}
                                                            />
                                                        )
                                                    })}
                                                </DSAccordionDetails>
                                            </DSAccordion>

                                            {/*
                                            {dataset.metrics?.map(metric => {
                                                return (
                                                    // <div>{metric.meta.title}</div>
                                                    <MetricTreeLayer state={state}
                                                                     root={metric}
                                                                     nextIndex={nextIndex}
                                                                     env={{
                                                                         // ...env,
                                                                         dataset
                                                                     }}
                                                        //                         onChange={(e, el, isChecked) => {
                                                        //                             // if (props.onChange) {
                                                        //                             //     props.onChange(e, el, isChecked)
                                                        //                             // }
                                                        //                         }}
                                                    />
                                                )
                                            })}
*/}
                                        </div>
/*

                                                                                <DSAccordion square
                                                                                             expanded={isExpanded}
                                                                                             onChange={handleExpandDataset(dataset.id)}
                                                                                             className={classes.datasetItem}
                                                                                >
                                                                                    <DSAccordionSummary>
                                                                                        <Typography>{dataset?.meta?.title}</Typography>
                                                                                    </DSAccordionSummary>
                                                                                    <DSAccordionDetails>
                                                                                        {isExpanded && dataset.metrics.map(metric => {
                                                                                            return (
                                                                                                <MetricTreeLayer state={state}
                                                                                                                 root={metric}
                                                                                                                 nextIndex={nextIndex}
                                                                                                                 env={{
                                                                                                                     // ...env,
                                                                                                                     dataset
                                                                                                                 }}
                                                                                                    //                         onChange={(e, el, isChecked) => {
                                                                                                    //                             // if (props.onChange) {
                                                                                                    //                             //     props.onChange(e, el, isChecked)
                                                                                                    //                             // }
                                                                                                    //                         }}
                                                                                                />
                                                                                            )
                                                                                        })}
                                                                                    </DSAccordionDetails>
                                                                                </DSAccordion>
                                        */
                                    );
                                })}
                                {
                                    isPureObject &&
                                    <MetricTreeLayer state={state}
                                                     element={selectedObject.object}
                                                     nextIndex={nextIndex}
                                                     env={selectedObject}
                                                     onChange={handleElementSelect}
                                    />
                                }
                            </Scrollbar>
                        </Grid>
                        }
                    </Grid>
                </div>
                {/*
                <Paper component="ul" className={classes.root} elevation={0}>
                    {checkedItems._map((data) => {
                        let icon;

                        return (
                            <li key={data.id}>
                                <Chip
                                    icon={icon}
                                    label={data.meta?.title}
                                    // onDelete={handleDelete(data)}
                                    className={classes.chip}
                                />
                            </li>
                        );
                    })}
                </Paper>
*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('project.leftbar.panel1.popup.data.cancel')}
                </Button>
                <Button onClick={handleOk} color="primary" autoFocus>
                    {t('project.leftbar.panel1.popup.data.save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DataViewDialog;

