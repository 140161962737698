export const columns = [
    {
        "value": 1,
        "centroid": [
            11.323241,
            50.97088771
        ],
        "vertices": [
            [
                11.3193347,
                50.97178708
            ],
            [
                11.3221036,
                50.97398118
            ],
            [
                11.3260099,
                50.97308171
            ],
            [
                11.3271472,
                50.96998822
            ],
            [
                11.3243784,
                50.96779417
            ],
            [
                11.3204722,
                50.96869356
            ]
        ]
    },
    {
        "value": 0.893316195,
        "centroid": [
            11.3216096,
            50.96559998
        ],
        "vertices": [
            [
                11.3177034,
                50.96649927
            ],
            [
                11.3204722,
                50.96869356
            ],
            [
                11.3243784,
                50.96779417
            ],
            [
                11.3255157,
                50.96470058
            ],
            [
                11.322747,
                50.96250634
            ],
            [
                11.318841,
                50.96340565
            ]
        ]
    },
    {
        "value": 0.758354756,
        "centroid": [
            11.3282844,
            50.96689467
        ],
        "vertices": [
            [
                11.3243784,
                50.96779417
            ],
            [
                11.3271472,
                50.96998822
            ],
            [
                11.4110532,
                50.96908863
            ],
            [
                11.4121903,
                50.96599506
            ],
            [
                11.3294215,
                50.96380106
            ],
            [
                11.3255157,
                50.96470058
            ]
        ]
    },
    {
        "value": 0.721079692,
        "centroid": [
            11.3165658,
            50.96959283
        ],
        "vertices": [
            [
                11.3126593,
                50.97049199
            ],
            [
                11.3154282,
                50.97268633
            ],
            [
                11.3193347,
                50.97178708
            ],
            [
                11.3204722,
                50.96869356
            ],
            [
                11.3177034,
                50.96649927
            ],
            [
                11.3137971,
                50.96739845
            ]
        ]
    },
    {
        "value": 0.701799486,
        "centroid": [
            11.3181971,
            50.97488053
        ],
        "vertices": [
            [
                11.3142904,
                50.97577977
            ],
            [
                11.3170594,
                50.97797392
            ],
            [
                11.3209661,
                50.97707458
            ],
            [
                11.3221036,
                50.97398118
            ],
            [
                11.3193347,
                50.97178708
            ],
            [
                11.3154282,
                50.97268633
            ]
        ]
    },
    {
        "value": 0.598971722,
        "centroid": [
            11.4216336,
            50.96948272
        ],
        "vertices": [
            [
                11.4177279,
                50.97038264
            ],
            [
                11.4204968,
                50.97257621
            ],
            [
                11.4244025,
                50.9716762
            ],
            [
                11.4255391,
                50.9685827
            ],
            [
                11.4227702,
                50.96638917
            ],
            [
                11.4188647,
                50.9672891
            ]
        ]
    },
    {
        "value": 0.560411311,
        "centroid": [
            11.3266529,
            50.96160692
        ],
        "vertices": [
            [
                11.322747,
                50.96250634
            ],
            [
                11.3255157,
                50.96470058
            ],
            [
                11.3294215,
                50.96380106
            ],
            [
                11.4105586,
                50.96070739
            ],
            [
                11.32779,
                50.7785132
            ],
            [
                11.3238843,
                50.77941264
            ]
        ]
    },
    {
        "value": 0.553984576,
        "centroid": [
            11.3149348,
            50.96430484
        ],
        "vertices": [
            [
                11.3110284,
                50.96520393
            ],
            [
                11.3137971,
                50.96739845
            ],
            [
                11.3177034,
                50.96649927
            ],
            [
                11.318841,
                50.96340565
            ],
            [
                11.3160724,
                50.96121117
            ],
            [
                11.3121661,
                50.96211027
            ]
        ]
    },
    {
        "value": 0.551413882,
        "centroid": [
            11.4149591,
            50.96818892
        ],
        "vertices": [
            [
                11.4110532,
                50.96908863
            ],
            [
                11.4138221,
                50.97128244
            ],
            [
                11.4177279,
                50.97038264
            ],
            [
                11.4188647,
                50.9672891
            ],
            [
                11.416096,
                50.96509534
            ],
            [
                11.4121903,
                50.96599506
            ]
        ]
    },
    {
        "value": 0.5218509,
        "centroid": [
            11.3199784,
            50.96031196
        ],
        "vertices": [
            [
                11.3160724,
                50.96121117
            ],
            [
                11.318841,
                50.96340565
            ],
            [
                11.322747,
                50.96250634
            ],
            [
                11.3238843,
                50.77941264
            ],
            [
                11.3211158,
                50.77721821
            ],
            [
                11.3172099,
                50.77811744
            ]
        ]
    },
    {
        "value": 0.484575835,
        "centroid": [
            11.4200015,
            50.9641955
        ],
        "vertices": [
            [
                11.416096,
                50.96509534
            ],
            [
                11.4188647,
                50.9672891
            ],
            [
                11.4227702,
                50.96638917
            ],
            [
                11.4239068,
                50.96329555
            ],
            [
                11.4211381,
                50.96110184
            ],
            [
                11.4172328,
                50.96200169
            ]
        ]
    },
    {
        "value": 0.479434447,
        "centroid": [
            11.3250215,
            50.77631887
        ],
        "vertices": [
            [
                11.3211158,
                50.77721821
            ],
            [
                11.3238843,
                50.77941264
            ],
            [
                11.32779,
                50.7785132
            ],
            [
                11.3289271,
                50.77541941
            ],
            [
                11.3261587,
                50.77322504
            ],
            [
                11.3222531,
                50.7741244
            ]
        ]
    },
    {
        "value": 0.458868895,
        "centroid": [
            11.3133038,
            50.77901655
        ],
        "vertices": [
            [
                11.3893976,
                50.77991556
            ],
            [
                11.3121661,
                50.96211027
            ],
            [
                11.3160724,
                50.96121117
            ],
            [
                11.3172099,
                50.77811744
            ],
            [
                11.3144414,
                50.77592278
            ],
            [
                11.3105353,
                50.7768218
            ]
        ]
    },
    {
        "value": 0.456298201,
        "centroid": [
            11.3299161,
            50.97218213
        ],
        "vertices": [
            [
                11.3260099,
                50.97308171
            ],
            [
                11.3287789,
                50.97527557
            ],
            [
                11.412685,
                50.9743759
            ],
            [
                11.4138221,
                50.97128244
            ],
            [
                11.4110532,
                50.96908863
            ],
            [
                11.3271472,
                50.96998822
            ]
        ]
    },
    {
        "value": 0.433161954,
        "centroid": [
            11.3898905,
            50.96829752
        ],
        "vertices": [
            [
                11.3859838,
                50.96919647
            ],
            [
                11.3887526,
                50.97139104
            ],
            [
                11.3126593,
                50.97049199
            ],
            [
                11.3137971,
                50.96739845
            ],
            [
                11.3110284,
                50.96520393
            ],
            [
                11.3871218,
                50.9661029
            ]
        ]
    },
    {
        "value": 0.429305913,
        "centroid": [
            11.4183695,
            50.77890798
        ],
        "vertices": [
            [
                11.4144641,
                50.77980774
            ],
            [
                11.4172328,
                50.96200169
            ],
            [
                11.4211381,
                50.96110184
            ],
            [
                11.4222747,
                50.77800811
            ],
            [
                11.4195062,
                50.77581421
            ],
            [
                11.415601,
                50.77671399
            ]
        ]
    },
    {
        "value": 0.315886889,
        "centroid": [
            11.4234113,
            50.77491432
        ],
        "vertices": [
            [
                11.4195062,
                50.77581421
            ],
            [
                11.4222747,
                50.77800811
            ],
            [
                11.4261798,
                50.77710813
            ],
            [
                11.4273161,
                50.77401432
            ],
            [
                11.4245477,
                50.77182047
            ],
            [
                11.4206428,
                50.77272038
            ]
        ]
    },
    {
        "value": 0.388174807,
        "centroid": [
            11.4116956,
            50.77761365
        ],
        "vertices": [
            [
                11.32779,
                50.7785132
            ],
            [
                11.4105586,
                50.96070739
            ],
            [
                11.4144641,
                50.77980774
            ],
            [
                11.415601,
                50.77671399
            ],
            [
                11.4128325,
                50.77451985
            ],
            [
                11.3289271,
                50.77541941
            ]
        ]
    },
    {
        "value": 0.38688946,
        "centroid": [
            11.4133272,
            50.96290143
        ],
        "vertices": [
            [
                11.3294215,
                50.96380106
            ],
            [
                11.4121903,
                50.96599506
            ],
            [
                11.416096,
                50.96509534
            ],
            [
                11.4172328,
                50.96200169
            ],
            [
                11.4144641,
                50.77980774
            ],
            [
                11.4105586,
                50.96070739
            ]
        ]
    },
    {
        "value": 0.38688946,
        "centroid": [
            11.4100641,
            50.77232557
        ],
        "vertices": [
            [
                11.3261587,
                50.77322504
            ],
            [
                11.3289271,
                50.77541941
            ],
            [
                11.4128325,
                50.77451985
            ],
            [
                11.4139693,
                50.77142598
            ],
            [
                11.411201,
                50.76923166
            ],
            [
                11.3272957,
                50.77013114
            ]
        ]
    },
    {
        "value": 0.385604113,
        "centroid": [
            11.4232658,
            50.97476965
        ],
        "vertices": [
            [
                11.41936,
                50.97566964
            ],
            [
                11.4221291,
                50.97786303
            ],
            [
                11.4260349,
                50.97696294
            ],
            [
                11.4271715,
                50.97386954
            ],
            [
                11.4244025,
                50.9716762
            ],
            [
                11.4204968,
                50.97257621
            ]
        ]
    },
    {
        "value": 0.362467866,
        "centroid": [
            11.3248725,
            50.97617513
        ],
        "vertices": [
            [
                11.3209661,
                50.97707458
            ],
            [
                11.3237351,
                50.97926849
            ],
            [
                11.3276416,
                50.97836895
            ],
            [
                11.3287789,
                50.97527557
            ],
            [
                11.3260099,
                50.97308171
            ],
            [
                11.3221036,
                50.97398118
            ]
        ]
    },
    {
        "value": 0.307197943,
        "centroid": [
            11.3183473,
            50.77502364
        ],
        "vertices": [
            [
                11.3144414,
                50.77592278
            ],
            [
                11.3172099,
                50.77811744
            ],
            [
                11.3211158,
                50.77721821
            ],
            [
                11.3222531,
                50.7741244
            ],
            [
                11.3194847,
                50.77192978
            ],
            [
                11.3155789,
                50.77282893
            ]
        ]
    },
    {
        "value": 0.299485861,
        "centroid": [
            11.4167377,
            50.77362017
        ],
        "vertices": [
            [
                11.4128325,
                50.77451985
            ],
            [
                11.415601,
                50.77671399
            ],
            [
                11.4195062,
                50.77581421
            ],
            [
                11.4206428,
                50.77272038
            ],
            [
                11.4178744,
                50.77052629
            ],
            [
                11.4139693,
                50.77142598
            ]
        ]
    },
    {
        "value": 0.299485861,
        "centroid": [
            11.4217793,
            50.76962648
        ],
        "vertices": [
            [
                11.4178744,
                50.77052629
            ],
            [
                11.4206428,
                50.77272038
            ],
            [
                11.4245477,
                50.77182047
            ],
            [
                11.4256841,
                50.76872656
            ],
            [
                11.4229158,
                50.76653252
            ],
            [
                11.419011,
                50.76743234
            ]
        ]
    },
    {
        "value": 0.291773779,
        "centroid": [
            11.3284327,
            50.76703719
        ],
        "vertices": [
            [
                11.3245274,
                50.76793658
            ],
            [
                11.3272957,
                50.77013114
            ],
            [
                11.411201,
                50.76923166
            ],
            [
                11.4123378,
                50.76613768
            ],
            [
                11.3295696,
                50.76394317
            ],
            [
                11.3256645,
                50.76484258
            ]
        ]
    },
    {
        "value": 0.290488432,
        "centroid": [
            11.4333496,
            50.9667823
        ],
        "vertices": [
            [
                11.4294444,
                50.96768256
            ],
            [
                11.4322133,
                50.96987584
            ],
            [
                11.4361184,
                50.9689755
            ],
            [
                11.4372546,
                50.96588194
            ],
            [
                11.4344858,
                50.9636887
            ],
            [
                11.4305808,
                50.96458897
            ]
        ]
    },
    {
        "value": 0.251928021,
        "centroid": [
            11.3233903,
            50.77103052
        ],
        "vertices": [
            [
                11.3194847,
                50.77192978
            ],
            [
                11.3222531,
                50.7741244
            ],
            [
                11.3261587,
                50.77322504
            ],
            [
                11.3272957,
                50.77013114
            ],
            [
                11.3245274,
                50.76793658
            ],
            [
                11.320622,
                50.76883586
            ]
        ]
    },
    {
        "value": 0.246786632,
        "centroid": [
            11.3115215,
            50.97358548
        ],
        "vertices": [
            [
                11.3876147,
                50.97448451
            ],
            [
                11.3103836,
                50.97667889
            ],
            [
                11.3142904,
                50.97577977
            ],
            [
                11.3154282,
                50.97268633
            ],
            [
                11.3126593,
                50.97049199
            ],
            [
                11.3887526,
                50.97139104
            ]
        ]
    },
    {
        "value": 0.203084833,
        "centroid": [
            11.4115479,
            50.97746929
        ],
        "vertices": [
            [
                11.3276416,
                50.97836895
            ],
            [
                11.4104107,
                50.80056262
            ],
            [
                11.414317,
                50.97966287
            ],
            [
                11.415454,
                50.97656952
            ],
            [
                11.412685,
                50.9743759
            ],
            [
                11.3287789,
                50.97527557
            ]
        ]
    },
    {
        "value": 0.203084833,
        "centroid": [
            11.4151061,
            50.76833206
        ],
        "vertices": [
            [
                11.411201,
                50.76923166
            ],
            [
                11.4139693,
                50.77142598
            ],
            [
                11.4178744,
                50.77052629
            ],
            [
                11.419011,
                50.76743234
            ],
            [
                11.4162428,
                50.76523807
            ],
            [
                11.4123378,
                50.76613768
            ]
        ]
    },
    {
        "value": 0.194087404,
        "centroid": [
            11.4400234,
            50.96807503
        ],
        "vertices": [
            [
                11.4361184,
                50.9689755
            ],
            [
                11.4388874,
                50.97116855
            ],
            [
                11.4427924,
                50.97026799
            ],
            [
                11.4439283,
                50.96717446
            ],
            [
                11.4411594,
                50.96498146
            ],
            [
                11.4372546,
                50.96588194
            ]
        ]
    },
    {
        "value": 0.190231362,
        "centroid": [
            11.4266756,
            50.96548912
        ],
        "vertices": [
            [
                11.4227702,
                50.96638917
            ],
            [
                11.4255391,
                50.9685827
            ],
            [
                11.4294444,
                50.96768256
            ],
            [
                11.4305808,
                50.96458897
            ],
            [
                11.427812,
                50.96239549
            ],
            [
                11.4239068,
                50.96329555
            ]
        ]
    },
    {
        "value": 0.190231362,
        "centroid": [
            11.4250433,
            50.96020187
        ],
        "vertices": [
            [
                11.4211381,
                50.96110184
            ],
            [
                11.4239068,
                50.96329555
            ],
            [
                11.427812,
                50.96239549
            ],
            [
                11.4289484,
                50.77930179
            ],
            [
                11.4261798,
                50.77710813
            ],
            [
                11.4222747,
                50.77800811
            ]
        ]
    },
    {
        "value": 0.185089974,
        "centroid": [
            11.4300847,
            50.77620803
        ],
        "vertices": [
            [
                11.4261798,
                50.77710813
            ],
            [
                11.4289484,
                50.77930179
            ],
            [
                11.4328532,
                50.7784016
            ],
            [
                11.4339894,
                50.77530783
            ],
            [
                11.4312209,
                50.77311421
            ],
            [
                11.4273161,
                50.77401432
            ]
        ]
    },
    {
        "value": 0.179948586,
        "centroid": [
            11.3217592,
            50.76574187
        ],
        "vertices": [
            [
                11.3178538,
                50.76664106
            ],
            [
                11.320622,
                50.76883586
            ],
            [
                11.3245274,
                50.76793658
            ],
            [
                11.3256645,
                50.76484258
            ],
            [
                11.3228964,
                50.76264783
            ],
            [
                11.3189911,
                50.76354703
            ]
        ]
    },
    {
        "value": 0.178663239,
        "centroid": [
            11.416591,
            50.97347611
        ],
        "vertices": [
            [
                11.412685,
                50.9743759
            ],
            [
                11.415454,
                50.97656952
            ],
            [
                11.41936,
                50.97566964
            ],
            [
                11.4204968,
                50.97257621
            ],
            [
                11.4177279,
                50.97038264
            ],
            [
                11.4138221,
                50.97128244
            ]
        ]
    },
    {
        "value": 0.172236504,
        "centroid": [
            11.3236887,
            50.73130697
        ],
        "vertices": [
            [
                11.3197846,
                50.73220602
            ],
            [
                11.3225519,
                50.73440166
            ],
            [
                11.326456,
                50.73350251
            ],
            [
                11.3275926,
                50.73040781
            ],
            [
                11.3248254,
                50.72821222
            ],
            [
                11.3209214,
                50.72911128
            ]
        ]
    },
    {
        "value": 0.16966581,
        "centroid": [
            11.3253193,
            50.73659716
        ],
        "vertices": [
            [
                11.3214151,
                50.73749628
            ],
            [
                11.3241825,
                50.73969174
            ],
            [
                11.3280867,
                50.73879251
            ],
            [
                11.3292234,
                50.73569792
            ],
            [
                11.326456,
                50.73350251
            ],
            [
                11.3225519,
                50.73440166
            ]
        ]
    },
    {
        "value": 0.165809769,
        "centroid": [
            11.3882597,
            50.96300926
        ],
        "vertices": [
            [
                11.3843532,
                50.96390813
            ],
            [
                11.3871218,
                50.9661029
            ],
            [
                11.3110284,
                50.96520393
            ],
            [
                11.3121661,
                50.96211027
            ],
            [
                11.3893976,
                50.77991556
            ],
            [
                11.3854912,
                50.96081445
            ]
        ]
    },
    {
        "value": 0.159383033,
        "centroid": [
            11.3220582,
            50.72601649
        ],
        "vertices": [
            [
                11.3181543,
                50.72691546
            ],
            [
                11.3209214,
                50.72911128
            ],
            [
                11.3248254,
                50.72821222
            ],
            [
                11.325962,
                50.7251174
            ],
            [
                11.3231949,
                50.72292163
            ],
            [
                11.3192911,
                50.72382061
            ]
        ]
    },
    {
        "value": 0.151670951,
        "centroid": [
            11.3268015,
            50.76174851
        ],
        "vertices": [
            [
                11.3228964,
                50.76264783
            ],
            [
                11.3256645,
                50.76484258
            ],
            [
                11.3295696,
                50.76394317
            ],
            [
                11.4107065,
                50.76084909
            ],
            [
                11.3279384,
                50.75865438
            ],
            [
                11.3240334,
                50.75955371
            ]
        ]
    },
    {
        "value": 0.140102828,
        "centroid": [
            11.4201475,
            50.76433834
        ],
        "vertices": [
            [
                11.4162428,
                50.76523807
            ],
            [
                11.419011,
                50.76743234
            ],
            [
                11.4229158,
                50.76653252
            ],
            [
                11.4240521,
                50.76343849
            ],
            [
                11.421284,
                50.76124427
            ],
            [
                11.4173794,
                50.76214401
            ]
        ]
    },
    {
        "value": 0.138817481,
        "centroid": [
            11.428308,
            50.97077608
        ],
        "vertices": [
            [
                11.4244025,
                50.9716762
            ],
            [
                11.4271715,
                50.97386954
            ],
            [
                11.4310769,
                50.97296932
            ],
            [
                11.4322133,
                50.96987584
            ],
            [
                11.4294444,
                50.96768256
            ],
            [
                11.4255391,
                50.9685827
            ]
        ]
    },
    {
        "value": 0.136246787,
        "centroid": [
            11.4299405,
            50.97606274
        ],
        "vertices": [
            [
                11.4260349,
                50.97696294
            ],
            [
                11.428804,
                50.97915609
            ],
            [
                11.4327096,
                50.97825579
            ],
            [
                11.433846,
                50.97516242
            ],
            [
                11.4310769,
                50.97296932
            ],
            [
                11.4271715,
                50.97386954
            ]
        ]
    },
    {
        "value": 0.131105398,
        "centroid": [
            11.4351254,
            50.77221399
        ],
        "vertices": [
            [
                11.4312209,
                50.77311421
            ],
            [
                11.4339894,
                50.77530783
            ],
            [
                11.4378939,
                50.77440751
            ],
            [
                11.4390298,
                50.77131365
            ],
            [
                11.4362614,
                50.76912009
            ],
            [
                11.432357,
                50.77002033
            ]
        ]
    },
    {
        "value": 0.128534704,
        "centroid": [
            11.4450641,
            50.96408087
        ],
        "vertices": [
            [
                11.4411594,
                50.96498146
            ],
            [
                11.4439283,
                50.96717446
            ],
            [
                11.4478329,
                50.96627378
            ],
            [
                11.4489686,
                50.96318017
            ],
            [
                11.4461998,
                50.96098721
            ],
            [
                11.4422953,
                50.96188782
            ]
        ]
    },
    {
        "value": 0.128534704,
        "centroid": [
            11.431717,
            50.96149532
        ],
        "vertices": [
            [
                11.427812,
                50.96239549
            ],
            [
                11.4305808,
                50.96458897
            ],
            [
                11.4344858,
                50.9636887
            ],
            [
                11.4356219,
                50.96059503
            ],
            [
                11.4328532,
                50.7784016
            ],
            [
                11.4289484,
                50.77930179
            ]
        ]
    },
    {
        "value": 0.127249357,
        "centroid": [
            11.4182231,
            50.97876301
        ],
        "vertices": [
            [
                11.414317,
                50.97966287
            ],
            [
                11.4170861,
                50.80185631
            ],
            [
                11.4209923,
                50.80095635
            ],
            [
                11.4221291,
                50.97786303
            ],
            [
                11.41936,
                50.97566964
            ],
            [
                11.415454,
                50.97656952
            ]
        ]
    },
    {
        "value": 0.127249357,
        "centroid": [
            11.4268204,
            50.76563258
        ],
        "vertices": [
            [
                11.4229158,
                50.76653252
            ],
            [
                11.4256841,
                50.76872656
            ],
            [
                11.4295886,
                50.76782652
            ],
            [
                11.4307248,
                50.76473253
            ],
            [
                11.4279566,
                50.76253854
            ],
            [
                11.4240521,
                50.76343849
            ]
        ]
    },
    {
        "value": 0.127249357,
        "centroid": [
            11.3251704,
            50.75645954
        ],
        "vertices": [
            [
                11.3212654,
                50.75735878
            ],
            [
                11.3240334,
                50.75955371
            ],
            [
                11.3279384,
                50.75865438
            ],
            [
                11.3290753,
                50.75556019
            ],
            [
                11.3263074,
                50.7533653
            ],
            [
                11.3224025,
                50.75426456
            ]
        ]
    },
    {
        "value": 0.12596401,
        "centroid": [
            11.3150856,
            50.76444612
        ],
        "vertices": [
            [
                11.31118,
                50.76534509
            ],
            [
                11.3139482,
                50.76754013
            ],
            [
                11.3178538,
                50.76664106
            ],
            [
                11.3189911,
                50.76354703
            ],
            [
                11.316223,
                50.76135204
            ],
            [
                11.3123175,
                50.76225103
            ]
        ]
    },
    {
        "value": 0.122107969,
        "centroid": [
            11.4383906,
            50.96278832
        ],
        "vertices": [
            [
                11.4344858,
                50.9636887
            ],
            [
                11.4372546,
                50.96588194
            ],
            [
                11.4411594,
                50.96498146
            ],
            [
                11.4422953,
                50.96188782
            ],
            [
                11.4395266,
                50.77969463
            ],
            [
                11.4356219,
                50.96059503
            ]
        ]
    },
    {
        "value": 0.116966581,
        "centroid": [
            11.4466972,
            50.96936732
        ],
        "vertices": [
            [
                11.4427924,
                50.97026799
            ],
            [
                11.4455614,
                50.9724608
            ],
            [
                11.4494662,
                50.97156004
            ],
            [
                11.4506018,
                50.96846654
            ],
            [
                11.4478329,
                50.96627378
            ],
            [
                11.4439283,
                50.96717446
            ]
        ]
    },
    {
        "value": 0.116966581,
        "centroid": [
            11.436758,
            50.7775013
        ],
        "vertices": [
            [
                11.4328532,
                50.7784016
            ],
            [
                11.4356219,
                50.96059503
            ],
            [
                11.4395266,
                50.77969463
            ],
            [
                11.4406625,
                50.77660088
            ],
            [
                11.4378939,
                50.77440751
            ],
            [
                11.4339894,
                50.77530783
            ]
        ]
    },
    {
        "value": 0.116966581,
        "centroid": [
            11.3235395,
            50.75117027
        ],
        "vertices": [
            [
                11.3196347,
                50.75206943
            ],
            [
                11.3224025,
                50.75426456
            ],
            [
                11.3263074,
                50.7533653
            ],
            [
                11.3274442,
                50.750271
            ],
            [
                11.3246764,
                50.74807593
            ],
            [
                11.3207718,
                50.7489751
            ]
        ]
    },
    {
        "value": 0.115681234,
        "centroid": [
            11.3204279,
            50.72072571
        ],
        "vertices": [
            [
                11.3165241,
                50.7216246
            ],
            [
                11.3192911,
                50.72382061
            ],
            [
                11.3231949,
                50.72292163
            ],
            [
                11.3243315,
                50.7198267
            ],
            [
                11.3215646,
                50.71763074
            ],
            [
                11.317661,
                50.71852965
            ]
        ]
    },
    {
        "value": 0.109254499,
        "centroid": [
            11.4349822,
            50.97206899
        ],
        "vertices": [
            [
                11.4310769,
                50.97296932
            ],
            [
                11.433846,
                50.97516242
            ],
            [
                11.4377513,
                50.974262
            ],
            [
                11.4388874,
                50.97116855
            ],
            [
                11.4361184,
                50.9689755
            ],
            [
                11.4322133,
                50.96987584
            ]
        ]
    },
    {
        "value": 0.109254499,
        "centroid": [
            11.32695,
            50.74188705
        ],
        "vertices": [
            [
                11.3230457,
                50.74278625
            ],
            [
                11.3258133,
                50.74498152
            ],
            [
                11.3297176,
                50.74408222
            ],
            [
                11.4108543,
                50.74098773
            ],
            [
                11.3280867,
                50.73879251
            ],
            [
                11.3241825,
                50.73969174
            ]
        ]
    },
    {
        "value": 0.106683805,
        "centroid": [
            11.4432895,
            50.97864758
        ],
        "vertices": [
            [
                11.4393842,
                50.9795482
            ],
            [
                11.4421535,
                50.80174088
            ],
            [
                11.4460587,
                50.80084016
            ],
            [
                11.4471946,
                50.97774685
            ],
            [
                11.4444255,
                50.97555422
            ],
            [
                11.4405204,
                50.97645486
            ]
        ]
    },
    {
        "value": 0.105398458,
        "centroid": [
            11.3187977,
            50.71543463
        ],
        "vertices": [
            [
                11.3148941,
                50.71633344
            ],
            [
                11.317661,
                50.71852965
            ],
            [
                11.3215646,
                50.71763074
            ],
            [
                11.3227012,
                50.71453571
            ],
            [
                11.3199344,
                50.71233955
            ],
            [
                11.3160309,
                50.71323838
            ]
        ]
    },
    {
        "value": 0.104113111,
        "centroid": [
            11.4484711,
            50.77479972
        ],
        "vertices": [
            [
                11.4445669,
                50.77570036
            ],
            [
                11.4473355,
                50.7778935
            ],
            [
                11.4512397,
                50.77699276
            ],
            [
                11.4523751,
                50.77389896
            ],
            [
                11.4496066,
                50.77170587
            ],
            [
                11.4457025,
                50.77260653
            ]
        ]
    },
    {
        "value": 0.104113111,
        "centroid": [
            11.4535105,
            50.7708051
        ],
        "vertices": [
            [
                11.4496066,
                50.77170587
            ],
            [
                11.4523751,
                50.77389896
            ],
            [
                11.456279,
                50.7729981
            ],
            [
                11.4574142,
                50.76990422
            ],
            [
                11.4546457,
                50.76771118
            ],
            [
                11.450742,
                50.76861197
            ]
        ]
    },
    {
        "value": 0.104113111,
        "centroid": [
            11.3171677,
            50.71014326
        ],
        "vertices": [
            [
                11.3132642,
                50.71104199
            ],
            [
                11.3160309,
                50.71323838
            ],
            [
                11.3199344,
                50.71233955
            ],
            [
                11.3210711,
                50.70924442
            ],
            [
                11.3183044,
                50.70704808
            ],
            [
                11.3144011,
                50.70794683
            ]
        ]
    },
    {
        "value": 0.100257069,
        "centroid": [
            11.4366151,
            50.97735538
        ],
        "vertices": [
            [
                11.4327096,
                50.97825579
            ],
            [
                11.4354788,
                50.80044871
            ],
            [
                11.4393842,
                50.9795482
            ],
            [
                11.4405204,
                50.97645486
            ],
            [
                11.4377513,
                50.974262
            ],
            [
                11.433846,
                50.97516242
            ]
        ]
    },
    {
        "value": 0.100257069,
        "centroid": [
            11.4134746,
            50.76304365
        ],
        "vertices": [
            [
                11.3295696,
                50.76394317
            ],
            [
                11.4123378,
                50.76613768
            ],
            [
                11.4162428,
                50.76523807
            ],
            [
                11.4173794,
                50.76214401
            ],
            [
                11.4146113,
                50.75994955
            ],
            [
                11.4107065,
                50.76084909
            ]
        ]
    },
    {
        "value": 0.100257069,
        "centroid": [
            11.3155379,
            50.70485159
        ],
        "vertices": [
            [
                11.3116345,
                50.70575025
            ],
            [
                11.3144011,
                50.70794683
            ],
            [
                11.3183044,
                50.70704808
            ],
            [
                11.3194411,
                50.70395283
            ],
            [
                11.3166746,
                50.7017563
            ],
            [
                11.3127714,
                50.70265497
            ]
        ]
    },
    {
        "value": 0.095115681,
        "centroid": [
            11.4284524,
            50.77092046
        ],
        "vertices": [
            [
                11.4245477,
                50.77182047
            ],
            [
                11.4273161,
                50.77401432
            ],
            [
                11.4312209,
                50.77311421
            ],
            [
                11.432357,
                50.77002033
            ],
            [
                11.4295886,
                50.76782652
            ],
            [
                11.4256841,
                50.76872656
            ]
        ]
    },
    {
        "value": 0.093830334,
        "centroid": [
            11.3167164,
            50.76973503
        ],
        "vertices": [
            [
                11.3128106,
                50.77063408
            ],
            [
                11.3155789,
                50.77282893
            ],
            [
                11.3194847,
                50.77192978
            ],
            [
                11.320622,
                50.76883586
            ],
            [
                11.3178538,
                50.76664106
            ],
            [
                11.3139482,
                50.76754013
            ]
        ]
    },
    {
        "value": 0.093830334,
        "centroid": [
            11.4334931,
            50.76692638
        ],
        "vertices": [
            [
                11.4295886,
                50.76782652
            ],
            [
                11.432357,
                50.77002033
            ],
            [
                11.4362614,
                50.76912009
            ],
            [
                11.4373973,
                50.76602612
            ],
            [
                11.434629,
                50.76383237
            ],
            [
                11.4307248,
                50.76473253
            ]
        ]
    },
    {
        "value": 0.083547558,
        "centroid": [
            11.4318608,
            50.76163847
        ],
        "vertices": [
            [
                11.4279566,
                50.76253854
            ],
            [
                11.4307248,
                50.76473253
            ],
            [
                11.434629,
                50.76383237
            ],
            [
                11.4357649,
                50.76073829
            ],
            [
                11.4329968,
                50.75854435
            ],
            [
                11.4290927,
                50.75944444
            ]
        ]
    },
    {
        "value": 0.077120823,
        "centroid": [
            11.4501042,
            50.9600865
        ],
        "vertices": [
            [
                11.4461998,
                50.96098721
            ],
            [
                11.4489686,
                50.96318017
            ],
            [
                11.4528729,
                50.96227935
            ],
            [
                11.4540083,
                50.77918566
            ],
            [
                11.4512397,
                50.77699276
            ],
            [
                11.4473355,
                50.7778935
            ]
        ]
    },
    {
        "value": 0.074550129,
        "centroid": [
            11.4551437,
            50.77609191
        ],
        "vertices": [
            [
                11.4512397,
                50.77699276
            ],
            [
                11.4540083,
                50.77918566
            ],
            [
                11.4579123,
                50.77828472
            ],
            [
                11.4590475,
                50.77519095
            ],
            [
                11.456279,
                50.7729981
            ],
            [
                11.4523751,
                50.77389896
            ]
        ]
    },
    {
        "value": 0.073264781,
        "centroid": [
            11.313455,
            50.75915691
        ],
        "vertices": [
            [
                11.3895495,
                50.7600558
            ],
            [
                11.3123175,
                50.76225103
            ],
            [
                11.316223,
                50.76135204
            ],
            [
                11.3173603,
                50.7582579
            ],
            [
                11.3145924,
                50.75606272
            ],
            [
                11.310687,
                50.75696164
            ]
        ]
    },
    {
        "value": 0.073264781,
        "centroid": [
            11.4354116,
            50.7324948
        ],
        "vertices": [
            [
                11.4315086,
                50.73339481
            ],
            [
                11.434276,
                50.73558946
            ],
            [
                11.4381791,
                50.73468935
            ],
            [
                11.4393145,
                50.73159468
            ],
            [
                11.4365472,
                50.72940009
            ],
            [
                11.4326443,
                50.73030012
            ]
        ]
    },
    {
        "value": 0.071979434,
        "centroid": [
            11.3219088,
            50.74588071
        ],
        "vertices": [
            [
                11.3180041,
                50.74677978
            ],
            [
                11.3207718,
                50.7489751
            ],
            [
                11.3246764,
                50.74807593
            ],
            [
                11.3258133,
                50.74498152
            ],
            [
                11.3230457,
                50.74278625
            ],
            [
                11.3191411,
                50.74368535
            ]
        ]
    },
    {
        "value": 0.070694087,
        "centroid": [
            11.4251884,
            50.76034441
        ],
        "vertices": [
            [
                11.421284,
                50.76124427
            ],
            [
                11.4240521,
                50.76343849
            ],
            [
                11.4279566,
                50.76253854
            ],
            [
                11.4290927,
                50.75944444
            ],
            [
                11.4263246,
                50.75725026
            ],
            [
                11.4224203,
                50.75815013
            ]
        ]
    },
    {
        "value": 0.06940874,
        "centroid": [
            11.4517374,
            50.96537298
        ],
        "vertices": [
            [
                11.4478329,
                50.96627378
            ],
            [
                11.4506018,
                50.96846654
            ],
            [
                11.4545063,
                50.96756565
            ],
            [
                11.4556417,
                50.96447207
            ],
            [
                11.4528729,
                50.96227935
            ],
            [
                11.4489686,
                50.96318017
            ]
        ]
    },
    {
        "value": 0.06940874,
        "centroid": [
            11.421925,
            50.74976717
        ],
        "vertices": [
            [
                11.4180208,
                50.75066687
            ],
            [
                11.4207887,
                50.75286148
            ],
            [
                11.4246929,
                50.75196168
            ],
            [
                11.425829,
                50.74886736
            ],
            [
                11.4230612,
                50.74667281
            ],
            [
                11.4191572,
                50.74757252
            ]
        ]
    },
    {
        "value": 0.064267352,
        "centroid": [
            11.3131526,
            50.97887314
        ],
        "vertices": [
            [
                11.3892456,
                50.97977225
            ],
            [
                11.3120147,
                50.80196645
            ],
            [
                11.3159217,
                50.80106724
            ],
            [
                11.3170594,
                50.97797392
            ],
            [
                11.3142904,
                50.97577977
            ],
            [
                11.3103836,
                50.97667889
            ]
        ]
    },
    {
        "value": 0.064267352,
        "centroid": [
            11.4417983,
            50.77350707
        ],
        "vertices": [
            [
                11.4378939,
                50.77440751
            ],
            [
                11.4406625,
                50.77660088
            ],
            [
                11.4445669,
                50.77570036
            ],
            [
                11.4457025,
                50.77260653
            ],
            [
                11.4429341,
                50.7704132
            ],
            [
                11.4390298,
                50.77131365
            ]
        ]
    },
    {
        "value": 0.064267352,
        "centroid": [
            11.3118245,
            50.7538674
        ],
        "vertices": [
            [
                11.3879192,
                50.75476622
            ],
            [
                11.310687,
                50.75696164
            ],
            [
                11.3145924,
                50.75606272
            ],
            [
                11.3157297,
                50.75296847
            ],
            [
                11.3129619,
                50.75077311
            ],
            [
                11.3890567,
                50.75167194
            ]
        ]
    },
    {
        "value": 0.062982005,
        "centroid": [
            11.3202781,
            50.74059085
        ],
        "vertices": [
            [
                11.3163736,
                50.74148985
            ],
            [
                11.3191411,
                50.74368535
            ],
            [
                11.3230457,
                50.74278625
            ],
            [
                11.3241825,
                50.73969174
            ],
            [
                11.3214151,
                50.73749628
            ],
            [
                11.3175107,
                50.7383953
            ]
        ]
    },
    {
        "value": 0.061696658,
        "centroid": [
            11.4468381,
            50.76951264
        ],
        "vertices": [
            [
                11.4429341,
                50.7704132
            ],
            [
                11.4457025,
                50.77260653
            ],
            [
                11.4496066,
                50.77170587
            ],
            [
                11.450742,
                50.76861197
            ],
            [
                11.4479736,
                50.76641869
            ],
            [
                11.4440697,
                50.76731927
            ]
        ]
    },
    {
        "value": 0.061696658,
        "centroid": [
            11.4739339,
            50.7151049
        ],
        "vertices": [
            [
                11.4700328,
                50.71600597
            ],
            [
                11.4727999,
                50.71819989
            ],
            [
                11.476701,
                50.71729872
            ],
            [
                11.4778348,
                50.71420372
            ],
            [
                11.4750678,
                50.71200985
            ],
            [
                11.4711668,
                50.71291093
            ]
        ]
    },
    {
        "value": 0.059125964,
        "centroid": [
            11.4235566,
            50.75505594
        ],
        "vertices": [
            [
                11.4196523,
                50.75595572
            ],
            [
                11.4224203,
                50.75815013
            ],
            [
                11.4263246,
                50.75725026
            ],
            [
                11.4274608,
                50.75415605
            ],
            [
                11.4246929,
                50.75196168
            ],
            [
                11.4207887,
                50.75286148
            ]
        ]
    },
    {
        "value": 0.057840617,
        "centroid": [
            11.4416564,
            50.97336146
        ],
        "vertices": [
            [
                11.4377513,
                50.974262
            ],
            [
                11.4405204,
                50.97645486
            ],
            [
                11.4444255,
                50.97555422
            ],
            [
                11.4455614,
                50.9724608
            ],
            [
                11.4427924,
                50.97026799
            ],
            [
                11.4388874,
                50.97116855
            ]
        ]
    },
    {
        "value": 0.05655527,
        "centroid": [
            11.3100424,
            50.76843909
        ],
        "vertices": [
            [
                11.3861364,
                50.76933794
            ],
            [
                11.3889047,
                50.77153302
            ],
            [
                11.3128106,
                50.77063408
            ],
            [
                11.3139482,
                50.76754013
            ],
            [
                11.31118,
                50.76534509
            ],
            [
                11.3872742,
                50.76624396
            ]
        ]
    },
    {
        "value": 0.055269923,
        "centroid": [
            11.4168844,
            50.75376116
        ],
        "vertices": [
            [
                11.4129799,
                50.75466073
            ],
            [
                11.4157479,
                50.75685539
            ],
            [
                11.4196523,
                50.75595572
            ],
            [
                11.4207887,
                50.75286148
            ],
            [
                11.4180208,
                50.75066687
            ],
            [
                11.4141165,
                50.75156646
            ]
        ]
    },
    {
        "value": 0.055269923,
        "centroid": [
            11.4755671,
            50.72039366
        ],
        "vertices": [
            [
                11.4716658,
                50.72129481
            ],
            [
                11.4744331,
                50.72348854
            ],
            [
                11.4783343,
                50.7225873
            ],
            [
                11.4794681,
                50.71949241
            ],
            [
                11.476701,
                50.71729872
            ],
            [
                11.4727999,
                50.71819989
            ]
        ]
    },
    {
        "value": 0.053984576,
        "centroid": [
            11.311673,
            50.77372797
        ],
        "vertices": [
            [
                11.3877669,
                50.7746269
            ],
            [
                11.3105353,
                50.7768218
            ],
            [
                11.3144414,
                50.77592278
            ],
            [
                11.3155789,
                50.77282893
            ],
            [
                11.3128106,
                50.77063408
            ],
            [
                11.3889047,
                50.77153302
            ]
        ]
    },
    {
        "value": 0.053984576,
        "centroid": [
            11.415253,
            50.74847213
        ],
        "vertices": [
            [
                11.4113487,
                50.74937162
            ],
            [
                11.4141165,
                50.75156646
            ],
            [
                11.4180208,
                50.75066687
            ],
            [
                11.4191572,
                50.74757252
            ],
            [
                11.4163895,
                50.74537773
            ],
            [
                11.4124853,
                50.74627724
            ]
        ]
    },
    {
        "value": 0.053984576,
        "centroid": [
            11.3152364,
            50.74458433
        ],
        "vertices": [
            [
                11.3113315,
                50.7454832
            ],
            [
                11.3140992,
                50.74767875
            ],
            [
                11.3180041,
                50.74677978
            ],
            [
                11.3191411,
                50.74368535
            ],
            [
                11.3163736,
                50.74148985
            ],
            [
                11.3124689,
                50.74238873
            ]
        ]
    },
    {
        "value": 0.053984576,
        "centroid": [
            11.4287412,
            50.73120003
        ],
        "vertices": [
            [
                11.424838,
                50.73209983
            ],
            [
                11.4276054,
                50.73429471
            ],
            [
                11.4315086,
                50.73339481
            ],
            [
                11.4326443,
                50.73030012
            ],
            [
                11.429877,
                50.72810529
            ],
            [
                11.4259739,
                50.72900511
            ]
        ]
    },
    {
        "value": 0.051413882,
        "centroid": [
            11.4634499,
            50.96266991
        ],
        "vertices": [
            [
                11.4595459,
                50.96357104
            ],
            [
                11.4623148,
                50.96576352
            ],
            [
                11.4662187,
                50.96486229
            ],
            [
                11.4673537,
                50.96176866
            ],
            [
                11.4645849,
                50.77957623
            ],
            [
                11.4606811,
                50.96047739
            ]
        ]
    },
    {
        "value": 0.051413882,
        "centroid": [
            11.4635876,
            50.76281525
        ],
        "vertices": [
            [
                11.4596843,
                50.76371628
            ],
            [
                11.4624527,
                50.76590927
            ],
            [
                11.4663559,
                50.76500815
            ],
            [
                11.4674907,
                50.76191411
            ],
            [
                11.4647224,
                50.75972117
            ],
            [
                11.4608193,
                50.76062221
            ]
        ]
    },
    {
        "value": 0.051413882,
        "centroid": [
            11.4706679,
            50.70452648
        ],
        "vertices": [
            [
                11.4667671,
                50.70542739
            ],
            [
                11.469534,
                50.70762168
            ],
            [
                11.4734348,
                50.70672068
            ],
            [
                11.4745686,
                50.70362546
            ],
            [
                11.4718019,
                50.70143121
            ],
            [
                11.4679012,
                50.70233214
            ]
        ]
    },
    {
        "value": 0.050128535,
        "centroid": [
            11.4668548,
            50.77338869
        ],
        "vertices": [
            [
                11.4629512,
                50.77428988
            ],
            [
                11.4657199,
                50.7764825
            ],
            [
                11.4696234,
                50.77558121
            ],
            [
                11.4707581,
                50.7724874
            ],
            [
                11.4679896,
                50.77029483
            ],
            [
                11.4640862,
                50.77119603
            ]
        ]
    },
    {
        "value": 0.050128535,
        "centroid": [
            11.4118432,
            50.75775494
        ],
        "vertices": [
            [
                11.3279384,
                50.75865438
            ],
            [
                11.4107065,
                50.76084909
            ],
            [
                11.4146113,
                50.75994955
            ],
            [
                11.4157479,
                50.75685539
            ],
            [
                11.4129799,
                50.75466073
            ],
            [
                11.3290753,
                50.75556019
            ]
        ]
    },
    {
        "value": 0.050128535,
        "centroid": [
            11.4253334,
            50.74048388
        ],
        "vertices": [
            [
                11.4214297,
                50.74138364
            ],
            [
                11.4241974,
                50.74357838
            ],
            [
                11.4281011,
                50.74267853
            ],
            [
                11.429237,
                50.73958402
            ],
            [
                11.4264694,
                50.73738933
            ],
            [
                11.4225659,
                50.7382891
            ]
        ]
    },
    {
        "value": 0.048843188,
        "centroid": [
            11.4584106,
            50.96666464
        ],
        "vertices": [
            [
                11.4545063,
                50.96756565
            ],
            [
                11.4572753,
                50.96975817
            ],
            [
                11.4611796,
                50.96885707
            ],
            [
                11.4623148,
                50.96576352
            ],
            [
                11.4595459,
                50.96357104
            ],
            [
                11.4556417,
                50.96447207
            ]
        ]
    },
    {
        "value": 0.048843188,
        "centroid": [
            11.4185159,
            50.7590499
        ],
        "vertices": [
            [
                11.4146113,
                50.75994955
            ],
            [
                11.4173794,
                50.76214401
            ],
            [
                11.421284,
                50.76124427
            ],
            [
                11.4224203,
                50.75815013
            ],
            [
                11.4196523,
                50.75595572
            ],
            [
                11.4157479,
                50.75685539
            ]
        ]
    },
    {
        "value": 0.048843188,
        "centroid": [
            11.3101942,
            50.7485776
        ],
        "vertices": [
            [
                11.386289,
                50.74947634
            ],
            [
                11.3890567,
                50.75167194
            ],
            [
                11.3129619,
                50.75077311
            ],
            [
                11.3140992,
                50.74767875
            ],
            [
                11.3113315,
                50.7454832
            ],
            [
                11.3874265,
                50.74638195
            ]
        ]
    },
    {
        "value": 0.047557841,
        "centroid": [
            11.328581,
            50.74717664
        ],
        "vertices": [
            [
                11.3246764,
                50.74807593
            ],
            [
                11.3274442,
                50.750271
            ],
            [
                11.4113487,
                50.74937162
            ],
            [
                11.4124853,
                50.74627724
            ],
            [
                11.3297176,
                50.74408222
            ],
            [
                11.3258133,
                50.74498152
            ]
        ]
    },
    {
        "value": 0.046272494,
        "centroid": [
            11.4567771,
            50.96137843
        ],
        "vertices": [
            [
                11.4528729,
                50.96227935
            ],
            [
                11.4556417,
                50.96447207
            ],
            [
                11.4595459,
                50.96357104
            ],
            [
                11.4606811,
                50.96047739
            ],
            [
                11.4579123,
                50.77828472
            ],
            [
                11.4540083,
                50.77918566
            ]
        ]
    },
    {
        "value": 0.042416452,
        "centroid": [
            11.4499638,
            50.97993934
        ],
        "vertices": [
            [
                11.4460587,
                50.80084016
            ],
            [
                11.448828,
                50.8030326
            ],
            [
                11.4527331,
                50.80213168
            ],
            [
                11.4538688,
                50.9790384
            ],
            [
                11.4510996,
                50.97684601
            ],
            [
                11.4471946,
                50.97774685
            ]
        ]
    },
    {
        "value": 0.042416452,
        "centroid": [
            11.4772004,
            50.72568213
        ],
        "vertices": [
            [
                11.473299,
                50.72658336
            ],
            [
                11.4760664,
                50.7287769
            ],
            [
                11.4799678,
                50.72787558
            ],
            [
                11.4811016,
                50.7247808
            ],
            [
                11.4783343,
                50.7225873
            ],
            [
                11.4744331,
                50.72348854
            ]
        ]
    },
    {
        "value": 0.042416452,
        "centroid": [
            11.4539291,
            50.71122458
        ],
        "vertices": [
            [
                11.4500275,
                50.71212503
            ],
            [
                11.4527944,
                50.71431966
            ],
            [
                11.456696,
                50.71341912
            ],
            [
                11.4578306,
                50.71032403
            ],
            [
                11.4550637,
                50.70812945
            ],
            [
                11.4511622,
                50.70902991
            ]
        ]
    },
    {
        "value": 0.041131105,
        "centroid": [
            11.4434311,
            50.77879412
        ],
        "vertices": [
            [
                11.4395266,
                50.77969463
            ],
            [
                11.4422953,
                50.96188782
            ],
            [
                11.4461998,
                50.96098721
            ],
            [
                11.4473355,
                50.7778935
            ],
            [
                11.4445669,
                50.77570036
            ],
            [
                11.4406625,
                50.77660088
            ]
        ]
    },
    {
        "value": 0.041131105,
        "centroid": [
            11.4103599,
            50.73260326
        ],
        "vertices": [
            [
                11.326456,
                50.73350251
            ],
            [
                11.3292234,
                50.73569792
            ],
            [
                11.4131273,
                50.73479856
            ],
            [
                11.4142636,
                50.73170389
            ],
            [
                11.4114964,
                50.72950853
            ],
            [
                11.3275926,
                50.73040781
            ]
        ]
    },
    {
        "value": 0.039845758,
        "centroid": [
            11.4769302,
            50.76539813
        ],
        "vertices": [
            [
                11.4730274,
                50.76629957
            ],
            [
                11.4757958,
                50.76849209
            ],
            [
                11.4796987,
                50.76759055
            ],
            [
                11.480833,
                50.76449657
            ],
            [
                11.4780646,
                50.76230411
            ],
            [
                11.4741619,
                50.76320557
            ]
        ]
    },
    {
        "value": 0.037275064,
        "centroid": [
            11.3798005,
            50.97628223
        ],
        "vertices": [
            [
                11.3758931,
                50.97718093
            ],
            [
                11.3786621,
                50.9793756
            ],
            [
                11.3825694,
                50.97847681
            ],
            [
                11.3837076,
                50.97538343
            ],
            [
                11.3809388,
                50.9731888
            ],
            [
                11.3770316,
                50.97408751
            ]
        ]
    },
    {
        "value": 0.035989717,
        "centroid": [
            11.4585493,
            50.76681028
        ],
        "vertices": [
            [
                11.4546457,
                50.76771118
            ],
            [
                11.4574142,
                50.76990422
            ],
            [
                11.4613177,
                50.76900323
            ],
            [
                11.4624527,
                50.76590927
            ],
            [
                11.4596843,
                50.76371628
            ],
            [
                11.4557809,
                50.76461719
            ]
        ]
    },
    {
        "value": 0.035989717,
        "centroid": [
            11.4385331,
            50.76293209
        ],
        "vertices": [
            [
                11.434629,
                50.76383237
            ],
            [
                11.4373973,
                50.76602612
            ],
            [
                11.4413014,
                50.76512575
            ],
            [
                11.4424371,
                50.76203171
            ],
            [
                11.4396689,
                50.759838
            ],
            [
                11.4357649,
                50.76073829
            ]
        ]
    },
    {
        "value": 0.035989717,
        "centroid": [
            11.4285968,
            50.75106177
        ],
        "vertices": [
            [
                11.4246929,
                50.75196168
            ],
            [
                11.4274608,
                50.75415605
            ],
            [
                11.4313648,
                50.75325604
            ],
            [
                11.4325007,
                50.75016175
            ],
            [
                11.4297328,
                50.74796743
            ],
            [
                11.425829,
                50.74886736
            ]
        ]
    },
    {
        "value": 0.035989717,
        "centroid": [
            11.4737982,
            50.73496626
        ],
        "vertices": [
            [
                11.4698964,
                50.73586744
            ],
            [
                11.472664,
                50.73806084
            ],
            [
                11.4765658,
                50.73715957
            ],
            [
                11.4776999,
                50.73406497
            ],
            [
                11.4749323,
                50.73187161
            ],
            [
                11.4710307,
                50.73277281
            ]
        ]
    },
    {
        "value": 0.03470437,
        "centroid": [
            11.4684886,
            50.77867497
        ],
        "vertices": [
            [
                11.4645849,
                50.77957623
            ],
            [
                11.4673537,
                50.96176866
            ],
            [
                11.4712573,
                50.9608673
            ],
            [
                11.4723921,
                50.77777359
            ],
            [
                11.4696234,
                50.77558121
            ],
            [
                11.4657199,
                50.7764825
            ]
        ]
    },
    {
        "value": 0.03470437,
        "centroid": [
            11.3884119,
            50.76314991
        ],
        "vertices": [
            [
                11.3845061,
                50.76404868
            ],
            [
                11.3872742,
                50.76624396
            ],
            [
                11.31118,
                50.76534509
            ],
            [
                11.3123175,
                50.76225103
            ],
            [
                11.3895495,
                50.7600558
            ],
            [
                11.3856439,
                50.76095459
            ]
        ]
    },
    {
        "value": 0.03470437,
        "centroid": [
            11.4569161,
            50.76152315
        ],
        "vertices": [
            [
                11.4530126,
                50.76242397
            ],
            [
                11.4557809,
                50.76461719
            ],
            [
                11.4596843,
                50.76371628
            ],
            [
                11.4608193,
                50.76062221
            ],
            [
                11.4580511,
                50.75842903
            ],
            [
                11.4541479,
                50.75932987
            ]
        ]
    },
    {
        "value": 0.03470437,
        "centroid": [
            11.4605975,
            50.71251847
        ],
        "vertices": [
            [
                11.456696,
                50.71341912
            ],
            [
                11.459463,
                50.71561351
            ],
            [
                11.4633645,
                50.71471277
            ],
            [
                11.4644988,
                50.7116177
            ],
            [
                11.4617319,
                50.70942336
            ],
            [
                11.4578306,
                50.71032403
            ]
        ]
    },
    {
        "value": 0.033419023,
        "centroid": [
            11.3848458,
            50.97228998
        ],
        "vertices": [
            [
                11.3809388,
                50.9731888
            ],
            [
                11.3837076,
                50.97538343
            ],
            [
                11.3876147,
                50.97448451
            ],
            [
                11.3887526,
                50.97139104
            ],
            [
                11.3859838,
                50.96919647
            ],
            [
                11.382077,
                50.97009531
            ]
        ]
    },
    {
        "value": 0.033419023,
        "centroid": [
            11.4751608,
            50.77996583
        ],
        "vertices": [
            [
                11.4712573,
                50.9608673
            ],
            [
                11.4740262,
                50.96305949
            ],
            [
                11.4779297,
                50.96215793
            ],
            [
                11.4790642,
                50.77906425
            ],
            [
                11.4762954,
                50.7768721
            ],
            [
                11.4723921,
                50.77777359
            ]
        ]
    },
    {
        "value": 0.033419023,
        "centroid": [
            11.4735267,
            50.77467982
        ],
        "vertices": [
            [
                11.4696234,
                50.77558121
            ],
            [
                11.4723921,
                50.77777359
            ],
            [
                11.4762954,
                50.7768721
            ],
            [
                11.4774299,
                50.77377832
            ],
            [
                11.4746613,
                50.77158599
            ],
            [
                11.4707581,
                50.7724874
            ]
        ]
    },
    {
        "value": 0.030848329,
        "centroid": [
            11.4752963,
            50.7601115
        ],
        "vertices": [
            [
                11.4713936,
                50.76101286
            ],
            [
                11.4741619,
                50.76320557
            ],
            [
                11.4780646,
                50.76230411
            ],
            [
                11.4791989,
                50.75921002
            ],
            [
                11.4764307,
                50.75701737
            ],
            [
                11.4725281,
                50.75791875
            ]
        ]
    },
    {
        "value": 0.030848329,
        "centroid": [
            11.4352686,
            50.75235592
        ],
        "vertices": [
            [
                11.4313648,
                50.75325604
            ],
            [
                11.4341327,
                50.75545017
            ],
            [
                11.4380365,
                50.75454996
            ],
            [
                11.4391722,
                50.7514557
            ],
            [
                11.4364043,
                50.74926162
            ],
            [
                11.4325007,
                50.75016175
            ]
        ]
    },
    {
        "value": 0.029562982,
        "centroid": [
            11.4701225,
            50.96396095
        ],
        "vertices": [
            [
                11.4662187,
                50.96486229
            ],
            [
                11.4689877,
                50.96705453
            ],
            [
                11.4728915,
                50.96615309
            ],
            [
                11.4740262,
                50.96305949
            ],
            [
                11.4712573,
                50.9608673
            ],
            [
                11.4673537,
                50.96176866
            ]
        ]
    },
    {
        "value": 0.029562982,
        "centroid": [
            11.417031,
            50.7338991
        ],
        "vertices": [
            [
                11.4131273,
                50.73479856
            ],
            [
                11.4158947,
                50.73699373
            ],
            [
                11.4197984,
                50.73609417
            ],
            [
                11.4209346,
                50.73299952
            ],
            [
                11.4181672,
                50.73080441
            ],
            [
                11.4142636,
                50.73170389
            ]
        ]
    },
    {
        "value": 0.029562982,
        "centroid": [
            11.4754317,
            50.74025411
        ],
        "vertices": [
            [
                11.4715297,
                50.74115536
            ],
            [
                11.4742975,
                50.74334858
            ],
            [
                11.4781995,
                50.74244723
            ],
            [
                11.4793335,
                50.73935274
            ],
            [
                11.4765658,
                50.73715957
            ],
            [
                11.472664,
                50.73806084
            ]
        ]
    },
    {
        "value": 0.028277635,
        "centroid": [
            11.4652211,
            50.76810212
        ],
        "vertices": [
            [
                11.4613177,
                50.76900323
            ],
            [
                11.4640862,
                50.77119603
            ],
            [
                11.4679896,
                50.77029483
            ],
            [
                11.4691243,
                50.7672009
            ],
            [
                11.4663559,
                50.76500815
            ],
            [
                11.4624527,
                50.76590927
            ]
        ]
    },
    {
        "value": 0.028277635,
        "centroid": [
            11.470259,
            50.76410691
        ],
        "vertices": [
            [
                11.4663559,
                50.76500815
            ],
            [
                11.4691243,
                50.7672009
            ],
            [
                11.4730274,
                50.76629957
            ],
            [
                11.4741619,
                50.76320557
            ],
            [
                11.4713936,
                50.76101286
            ],
            [
                11.4674907,
                50.76191411
            ]
        ]
    },
    {
        "value": 0.028277635,
        "centroid": [
            11.4770653,
            50.74554166
        ],
        "vertices": [
            [
                11.4731632,
                50.74644299
            ],
            [
                11.4759311,
                50.74863602
            ],
            [
                11.4798333,
                50.74773459
            ],
            [
                11.4809673,
                50.74464021
            ],
            [
                11.4781995,
                50.74244723
            ],
            [
                11.4742975,
                50.74334858
            ]
        ]
    },
    {
        "value": 0.026992288,
        "centroid": [
            11.4518774,
            50.765518
        ],
        "vertices": [
            [
                11.4479736,
                50.76641869
            ],
            [
                11.450742,
                50.76861197
            ],
            [
                11.4546457,
                50.76771118
            ],
            [
                11.4557809,
                50.76461719
            ],
            [
                11.4530126,
                50.76242397
            ],
            [
                11.4491091,
                50.76332467
            ]
        ]
    },
    {
        "value": 0.026992288,
        "centroid": [
            11.4452053,
            50.76422527
        ],
        "vertices": [
            [
                11.4413014,
                50.76512575
            ],
            [
                11.4440697,
                50.76731927
            ],
            [
                11.4479736,
                50.76641869
            ],
            [
                11.4491091,
                50.76332467
            ],
            [
                11.4463408,
                50.76113121
            ],
            [
                11.4424371,
                50.76203171
            ]
        ]
    },
    {
        "value": 0.026992288,
        "centroid": [
            11.4520173,
            50.74565996
        ],
        "vertices": [
            [
                11.4481143,
                50.74656054
            ],
            [
                11.4508821,
                50.74875433
            ],
            [
                11.4547851,
                50.74785366
            ],
            [
                11.4559201,
                50.74475926
            ],
            [
                11.4531523,
                50.74256552
            ],
            [
                11.4492495,
                50.74346612
            ]
        ]
    },
    {
        "value": 0.026992288,
        "centroid": [
            11.4220706,
            50.72990481
        ],
        "vertices": [
            [
                11.4181672,
                50.73080441
            ],
            [
                11.4209346,
                50.73299952
            ],
            [
                11.424838,
                50.73209983
            ],
            [
                11.4259739,
                50.72900511
            ],
            [
                11.4232066,
                50.72681004
            ],
            [
                11.4193034,
                50.72770965
            ]
        ]
    },
    {
        "value": 0.025706941,
        "centroid": [
            11.4454876,
            50.72450489
        ],
        "vertices": [
            [
                11.4415852,
                50.72540516
            ],
            [
                11.4443525,
                50.7275997
            ],
            [
                11.4482549,
                50.72669934
            ],
            [
                11.4493899,
                50.72360451
            ],
            [
                11.4466227,
                50.72141002
            ],
            [
                11.4427204,
                50.7223103
            ]
        ]
    },
    {
        "value": 0.024421594,
        "centroid": [
            11.4618162,
            50.77738366
        ],
        "vertices": [
            [
                11.4579123,
                50.77828472
            ],
            [
                11.4606811,
                50.96047739
            ],
            [
                11.4645849,
                50.77957623
            ],
            [
                11.4657199,
                50.7764825
            ],
            [
                11.4629512,
                50.77428988
            ],
            [
                11.4590475,
                50.77519095
            ]
        ]
    },
    {
        "value": 0.023136247,
        "centroid": [
            11.481833,
            50.96125625
        ],
        "vertices": [
            [
                11.4779297,
                50.96215793
            ],
            [
                11.4806986,
                50.96434988
            ],
            [
                11.4846019,
                50.9634481
            ],
            [
                11.4857361,
                50.96035445
            ],
            [
                11.4829673,
                50.77816255
            ],
            [
                11.4790642,
                50.77906425
            ]
        ]
    },
    {
        "value": 0.023136247,
        "centroid": [
            11.4601827,
            50.77209712
        ],
        "vertices": [
            [
                11.456279,
                50.7729981
            ],
            [
                11.4590475,
                50.77519095
            ],
            [
                11.4629512,
                50.77428988
            ],
            [
                11.4640862,
                50.77119603
            ],
            [
                11.4613177,
                50.76900323
            ],
            [
                11.4574142,
                50.76990422
            ]
        ]
    },
    {
        "value": 0.023136247,
        "centroid": [
            11.4721649,
            50.72967811
        ],
        "vertices": [
            [
                11.4682632,
                50.73057921
            ],
            [
                11.4710307,
                50.73277281
            ],
            [
                11.4749323,
                50.73187161
            ],
            [
                11.4760664,
                50.7287769
            ],
            [
                11.473299,
                50.72658336
            ],
            [
                11.4693975,
                50.72748447
            ]
        ]
    },
    {
        "value": 0.023136247,
        "centroid": [
            11.4505248,
            50.72050963
        ],
        "vertices": [
            [
                11.4466227,
                50.72141002
            ],
            [
                11.4493899,
                50.72360451
            ],
            [
                11.453292,
                50.72270402
            ],
            [
                11.4544267,
                50.71960912
            ],
            [
                11.4516596,
                50.71741467
            ],
            [
                11.4477577,
                50.71831509
            ]
        ]
    },
    {
        "value": 0.023136247,
        "centroid": [
            11.4822354,
            50.72168595
        ],
        "vertices": [
            [
                11.4783343,
                50.7225873
            ],
            [
                11.4811016,
                50.7247808
            ],
            [
                11.4850027,
                50.72387935
            ],
            [
                11.4861363,
                50.72078448
            ],
            [
                11.483369,
                50.71859103
            ],
            [
                11.4794681,
                50.71949241
            ]
        ]
    },
    {
        "value": 0.0218509,
        "centroid": [
            11.4785643,
            50.77068447
        ],
        "vertices": [
            [
                11.4746613,
                50.77158599
            ],
            [
                11.4774299,
                50.77377832
            ],
            [
                11.4813329,
                50.7728767
            ],
            [
                11.4824672,
                50.76978283
            ],
            [
                11.4796987,
                50.76759055
            ],
            [
                11.4757958,
                50.76849209
            ]
        ]
    },
    {
        "value": 0.0218509,
        "centroid": [
            11.4269651,
            50.74577298
        ],
        "vertices": [
            [
                11.4230612,
                50.74667281
            ],
            [
                11.425829,
                50.74886736
            ],
            [
                11.4297328,
                50.74796743
            ],
            [
                11.4308687,
                50.74487303
            ],
            [
                11.4281011,
                50.74267853
            ],
            [
                11.4241974,
                50.74357838
            ]
        ]
    },
    {
        "value": 0.0218509,
        "centroid": [
            11.4637252,
            50.74295754
        ],
        "vertices": [
            [
                11.4598227,
                50.74385846
            ],
            [
                11.4625906,
                50.74605196
            ],
            [
                11.466493,
                50.74515095
            ],
            [
                11.4676276,
                50.74205651
            ],
            [
                11.4648598,
                50.73986305
            ],
            [
                11.4609575,
                50.74076399
            ]
        ]
    },
    {
        "value": 0.0218509,
        "centroid": [
            11.468762,
            50.738962
        ],
        "vertices": [
            [
                11.4648598,
                50.73986305
            ],
            [
                11.4676276,
                50.74205651
            ],
            [
                11.4715297,
                50.74115536
            ],
            [
                11.472664,
                50.73806084
            ],
            [
                11.4698964,
                50.73586744
            ],
            [
                11.4659944,
                50.7367685
            ]
        ]
    },
    {
        "value": 0.020565553,
        "centroid": [
            11.410212,
            50.75246594
        ],
        "vertices": [
            [
                11.3263074,
                50.7533653
            ],
            [
                11.3290753,
                50.75556019
            ],
            [
                11.4129799,
                50.75466073
            ],
            [
                11.4141165,
                50.75156646
            ],
            [
                11.4113487,
                50.74937162
            ],
            [
                11.3274442,
                50.750271
            ]
        ]
    },
    {
        "value": 0.020565553,
        "centroid": [
            11.388564,
            50.74328751
        ],
        "vertices": [
            [
                11.3846589,
                50.74418617
            ],
            [
                11.3874265,
                50.74638195
            ],
            [
                11.3113315,
                50.7454832
            ],
            [
                11.3124689,
                50.74238873
            ],
            [
                11.3897014,
                50.74019299
            ],
            [
                11.3857965,
                50.74109167
            ]
        ]
    },
    {
        "value": 0.020565553,
        "centroid": [
            11.4521571,
            50.72579887
        ],
        "vertices": [
            [
                11.4482549,
                50.72669934
            ],
            [
                11.4510222,
                50.72889365
            ],
            [
                11.4549244,
                50.72799308
            ],
            [
                11.4560592,
                50.72489828
            ],
            [
                11.453292,
                50.72270402
            ],
            [
                11.4493899,
                50.72360451
            ]
        ]
    },
    {
        "value": 0.020565553,
        "centroid": [
            11.4823694,
            50.70182308
        ],
        "vertices": [
            [
                11.4784691,
                50.70272432
            ],
            [
                11.4812359,
                50.70491833
            ],
            [
                11.4851362,
                50.70401699
            ],
            [
                11.4862695,
                50.70092172
            ],
            [
                11.4835028,
                50.69872776
            ],
            [
                11.4796027,
                50.69962902
            ]
        ]
    },
    {
        "value": 0.019280206,
        "centroid": [
            11.488505,
            50.96254622
        ],
        "vertices": [
            [
                11.4846019,
                50.9634481
            ],
            [
                11.4873708,
                50.96563982
            ],
            [
                11.4912739,
                50.96473784
            ],
            [
                11.492408,
                50.96164422
            ],
            [
                11.4896391,
                50.77945255
            ],
            [
                11.4857361,
                50.96035445
            ]
        ]
    },
    {
        "value": 0.019280206,
        "centroid": [
            11.4369008,
            50.75764416
        ],
        "vertices": [
            [
                11.4329968,
                50.75854435
            ],
            [
                11.4357649,
                50.76073829
            ],
            [
                11.4396689,
                50.759838
            ],
            [
                11.4408046,
                50.75674385
            ],
            [
                11.4380365,
                50.75454996
            ],
            [
                11.4341327,
                50.75545017
            ]
        ]
    },
    {
        "value": 0.019280206,
        "centroid": [
            11.4819672,
            50.76140254
        ],
        "vertices": [
            [
                11.4780646,
                50.76230411
            ],
            [
                11.480833,
                50.76449657
            ],
            [
                11.4847355,
                50.76359491
            ],
            [
                11.4858695,
                50.76050085
            ],
            [
                11.4831013,
                50.75830843
            ],
            [
                11.4791989,
                50.75921002
            ]
        ]
    },
    {
        "value": 0.019280206,
        "centroid": [
            11.4786991,
            50.75082891
        ],
        "vertices": [
            [
                11.4747969,
                50.75173033
            ],
            [
                11.4775649,
                50.75392317
            ],
            [
                11.4814672,
                50.75302166
            ],
            [
                11.4826012,
                50.74992739
            ],
            [
                11.4798333,
                50.74773459
            ],
            [
                11.4759311,
                50.74863602
            ]
        ]
    },
    {
        "value": 0.019280206,
        "centroid": [
            11.4303728,
            50.73648945
        ],
        "vertices": [
            [
                11.4264694,
                50.73738933
            ],
            [
                11.429237,
                50.73958402
            ],
            [
                11.4331403,
                50.73868404
            ],
            [
                11.434276,
                50.73558946
            ],
            [
                11.4315086,
                50.73339481
            ],
            [
                11.4276054,
                50.73429471
            ]
        ]
    },
    {
        "value": 0.019280206,
        "centroid": [
            11.483869,
            50.72697415
        ],
        "vertices": [
            [
                11.4799678,
                50.72787558
            ],
            [
                11.4827352,
                50.73006889
            ],
            [
                11.4866364,
                50.72916736
            ],
            [
                11.48777,
                50.7260726
            ],
            [
                11.4850027,
                50.72387935
            ],
            [
                11.4811016,
                50.7247808
            ]
        ]
    },
    {
        "value": 0.019280206,
        "centroid": [
            11.4438556,
            50.71921539
        ],
        "vertices": [
            [
                11.4399533,
                50.72011557
            ],
            [
                11.4427204,
                50.7223103
            ],
            [
                11.4466227,
                50.72141002
            ],
            [
                11.4477577,
                50.71831509
            ],
            [
                11.4449907,
                50.71612041
            ],
            [
                11.4410885,
                50.71702061
            ]
        ]
    },
    {
        "value": 0.019280206,
        "centroid": [
            11.4672657,
            50.7138119
        ],
        "vertices": [
            [
                11.4633645,
                50.71471277
            ],
            [
                11.4661315,
                50.71690692
            ],
            [
                11.4700328,
                50.71600597
            ],
            [
                11.4711668,
                50.71291093
            ],
            [
                11.4683999,
                50.71071682
            ],
            [
                11.4644988,
                50.7116177
            ]
        ]
    },
    {
        "value": 0.017994859,
        "centroid": [
            11.4852358,
            50.77197497
        ],
        "vertices": [
            [
                11.4813329,
                50.7728767
            ],
            [
                11.4841016,
                50.77506879
            ],
            [
                11.4880044,
                50.77416697
            ],
            [
                11.4891385,
                50.77107313
            ],
            [
                11.4863699,
                50.76888109
            ],
            [
                11.4824672,
                50.76978283
            ]
        ]
    },
    {
        "value": 0.017994859,
        "centroid": [
            11.4502444,
            50.76023059
        ],
        "vertices": [
            [
                11.4463408,
                50.76113121
            ],
            [
                11.4491091,
                50.76332467
            ],
            [
                11.4530126,
                50.76242397
            ],
            [
                11.4541479,
                50.75932987
            ],
            [
                11.4513797,
                50.75713645
            ],
            [
                11.4474763,
                50.75803708
            ]
        ]
    },
    {
        "value": 0.017994859,
        "centroid": [
            11.438818,
            50.72321048
        ],
        "vertices": [
            [
                11.4349154,
                50.72411054
            ],
            [
                11.4376826,
                50.72630531
            ],
            [
                11.4415852,
                50.72540516
            ],
            [
                11.4427204,
                50.7223103
            ],
            [
                11.4399533,
                50.72011557
            ],
            [
                11.4360509,
                50.72101565
            ]
        ]
    },
    {
        "value": 0.016709512,
        "centroid": [
            11.4767951,
            50.96525154
        ],
        "vertices": [
            [
                11.4728915,
                50.96615309
            ],
            [
                11.4756604,
                50.9683451
            ],
            [
                11.4795641,
                50.96744345
            ],
            [
                11.4806986,
                50.96434988
            ],
            [
                11.4779297,
                50.96215793
            ],
            [
                11.4740262,
                50.96305949
            ]
        ]
    },
    {
        "value": 0.016709512,
        "centroid": [
            11.4619542,
            50.75752809
        ],
        "vertices": [
            [
                11.4580511,
                50.75842903
            ],
            [
                11.4608193,
                50.76062221
            ],
            [
                11.4647224,
                50.75972117
            ],
            [
                11.4658571,
                50.75662703
            ],
            [
                11.463089,
                50.7544339
            ],
            [
                11.4591861,
                50.75533486
            ]
        ]
    },
    {
        "value": 0.016709512,
        "centroid": [
            11.4202935,
            50.74447811
        ],
        "vertices": [
            [
                11.4163895,
                50.74537773
            ],
            [
                11.4191572,
                50.74757252
            ],
            [
                11.4230612,
                50.74667281
            ],
            [
                11.4241974,
                50.74357838
            ],
            [
                11.4214297,
                50.74138364
            ],
            [
                11.4175259,
                50.74228327
            ]
        ]
    },
    {
        "value": 0.016709512,
        "centroid": [
            11.4453465,
            50.74436661
        ],
        "vertices": [
            [
                11.4414433,
                50.74526698
            ],
            [
                11.4442111,
                50.74746101
            ],
            [
                11.4481143,
                50.74656054
            ],
            [
                11.4492495,
                50.74346612
            ],
            [
                11.4464818,
                50.74127214
            ],
            [
                11.4425788,
                50.74217253
            ]
        ]
    },
    {
        "value": 0.016709512,
        "centroid": [
            11.4404499,
            50.72849995
        ],
        "vertices": [
            [
                11.4365472,
                50.72940009
            ],
            [
                11.4393145,
                50.73159468
            ],
            [
                11.4432173,
                50.73069445
            ],
            [
                11.4443525,
                50.7275997
            ],
            [
                11.4415852,
                50.72540516
            ],
            [
                11.4376826,
                50.72630531
            ]
        ]
    },
    {
        "value": 0.016709512,
        "centroid": [
            11.4337799,
            50.72720536
        ],
        "vertices": [
            [
                11.429877,
                50.72810529
            ],
            [
                11.4326443,
                50.73030012
            ],
            [
                11.4365472,
                50.72940009
            ],
            [
                11.4376826,
                50.72630531
            ],
            [
                11.4349154,
                50.72411054
            ],
            [
                11.4310127,
                50.72501048
            ]
        ]
    },
    {
        "value": 0.015424165,
        "centroid": [
            11.4401656,
            50.76821973
        ],
        "vertices": [
            [
                11.4362614,
                50.76912009
            ],
            [
                11.4390298,
                50.77131365
            ],
            [
                11.4429341,
                50.7704132
            ],
            [
                11.4440697,
                50.76731927
            ],
            [
                11.4413014,
                50.76512575
            ],
            [
                11.4373973,
                50.76602612
            ]
        ]
    },
    {
        "value": 0.015424165,
        "centroid": [
            11.3201283,
            50.76045293
        ],
        "vertices": [
            [
                11.316223,
                50.76135204
            ],
            [
                11.3189911,
                50.76354703
            ],
            [
                11.3228964,
                50.76264783
            ],
            [
                11.3240334,
                50.75955371
            ],
            [
                11.3212654,
                50.75735878
            ],
            [
                11.3173603,
                50.7582579
            ]
        ]
    },
    {
        "value": 0.015424165,
        "centroid": [
            11.4435727,
            50.7589376
        ],
        "vertices": [
            [
                11.4396689,
                50.759838
            ],
            [
                11.4424371,
                50.76203171
            ],
            [
                11.4463408,
                50.76113121
            ],
            [
                11.4474763,
                50.75803708
            ],
            [
                11.4447082,
                50.75584343
            ],
            [
                11.4408046,
                50.75674385
            ]
        ]
    },
    {
        "value": 0.015424165,
        "centroid": [
            11.4686253,
            50.75882001
        ],
        "vertices": [
            [
                11.4647224,
                50.75972117
            ],
            [
                11.4674907,
                50.76191411
            ],
            [
                11.4713936,
                50.76101286
            ],
            [
                11.4725281,
                50.75791875
            ],
            [
                11.4697599,
                50.75572585
            ],
            [
                11.4658571,
                50.75662703
            ]
        ]
    },
    {
        "value": 0.015424165,
        "centroid": [
            11.4736625,
            50.75482457
        ],
        "vertices": [
            [
                11.4697599,
                50.75572585
            ],
            [
                11.4725281,
                50.75791875
            ],
            [
                11.4764307,
                50.75701737
            ],
            [
                11.4775649,
                50.75392317
            ],
            [
                11.4747969,
                50.75173033
            ],
            [
                11.4708944,
                50.75263163
            ]
        ]
    },
    {
        "value": 0.015424165,
        "centroid": [
            11.4336365,
            50.7470674
        ],
        "vertices": [
            [
                11.4297328,
                50.74796743
            ],
            [
                11.4325007,
                50.75016175
            ],
            [
                11.4364043,
                50.74926162
            ],
            [
                11.43754,
                50.74616724
            ],
            [
                11.4347723,
                50.74397298
            ],
            [
                11.4308687,
                50.74487303
            ]
        ]
    },
    {
        "value": 0.015424165,
        "centroid": [
            11.4586879,
            50.74695286
        ],
        "vertices": [
            [
                11.4547851,
                50.74785366
            ],
            [
                11.457553,
                50.75004721
            ],
            [
                11.4614558,
                50.74914632
            ],
            [
                11.4625906,
                50.74605196
            ],
            [
                11.4598227,
                50.74385846
            ],
            [
                11.4559201,
                50.74475926
            ]
        ]
    },
    {
        "value": 0.015424165,
        "centroid": [
            11.442082,
            50.73378913
        ],
        "vertices": [
            [
                11.4381791,
                50.73468935
            ],
            [
                11.4409466,
                50.73688376
            ],
            [
                11.4448494,
                50.73598344
            ],
            [
                11.4459847,
                50.7328888
            ],
            [
                11.4432173,
                50.73069445
            ],
            [
                11.4393145,
                50.73159468
            ]
        ]
    },
    {
        "value": 0.015424165,
        "centroid": [
            11.4488926,
            50.71522009
        ],
        "vertices": [
            [
                11.4449907,
                50.71612041
            ],
            [
                11.4477577,
                50.71831509
            ],
            [
                11.4516596,
                50.71741467
            ],
            [
                11.4527944,
                50.71431966
            ],
            [
                11.4500275,
                50.71212503
            ],
            [
                11.4461257,
                50.71302536
            ]
        ]
    },
    {
        "value": 0.015424165,
        "centroid": [
            11.4723008,
            50.70981584
        ],
        "vertices": [
            [
                11.4683999,
                50.71071682
            ],
            [
                11.4711668,
                50.71291093
            ],
            [
                11.4750678,
                50.71200985
            ],
            [
                11.4762016,
                50.70891474
            ],
            [
                11.4734348,
                50.70672068
            ],
            [
                11.469534,
                50.70762168
            ]
        ]
    },
    {
        "value": 0.014138817,
        "centroid": [
            11.4483304,
            50.97465348
        ],
        "vertices": [
            [
                11.4444255,
                50.97555422
            ],
            [
                11.4471946,
                50.97774685
            ],
            [
                11.4510996,
                50.97684601
            ],
            [
                11.4522352,
                50.97375262
            ],
            [
                11.4494662,
                50.97156004
            ],
            [
                11.4455614,
                50.9724608
            ]
        ]
    },
    {
        "value": 0.014138817,
        "centroid": [
            11.3168669,
            50.74987416
        ],
        "vertices": [
            [
                11.3129619,
                50.75077311
            ],
            [
                11.3157297,
                50.75296847
            ],
            [
                11.3196347,
                50.75206943
            ],
            [
                11.3207718,
                50.7489751
            ],
            [
                11.3180041,
                50.74677978
            ],
            [
                11.3140992,
                50.74767875
            ]
        ]
    },
    {
        "value": 0.014138817,
        "centroid": [
            11.4921715,
            50.73355336
        ],
        "vertices": [
            [
                11.4882703,
                50.73445508
            ],
            [
                11.491038,
                50.73664796
            ],
            [
                11.4949391,
                50.73574615
            ],
            [
                11.4960725,
                50.73265153
            ],
            [
                11.4933049,
                50.7304587
            ],
            [
                11.4894039,
                50.73136043
            ]
        ]
    },
    {
        "value": 0.01285347,
        "centroid": [
            11.4868703,
            50.77726074
        ],
        "vertices": [
            [
                11.4829673,
                50.77816255
            ],
            [
                11.4857361,
                50.96035445
            ],
            [
                11.4896391,
                50.77945255
            ],
            [
                11.4907731,
                50.77635882
            ],
            [
                11.4880044,
                50.77416697
            ],
            [
                11.4841016,
                50.77506879
            ]
        ]
    },
    {
        "value": 0.01285347,
        "centroid": [
            11.4919071,
            50.77326503
        ],
        "vertices": [
            [
                11.4880044,
                50.77416697
            ],
            [
                11.4907731,
                50.77635882
            ],
            [
                11.4946758,
                50.77545679
            ],
            [
                11.4958096,
                50.77236298
            ],
            [
                11.493041,
                50.77017117
            ],
            [
                11.4891385,
                50.77107313
            ]
        ]
    },
    {
        "value": 0.01285347,
        "centroid": [
            11.4836014,
            50.7666889
        ],
        "vertices": [
            [
                11.4796987,
                50.76759055
            ],
            [
                11.4824672,
                50.76978283
            ],
            [
                11.4863699,
                50.76888109
            ],
            [
                11.4875039,
                50.76578714
            ],
            [
                11.4847355,
                50.76359491
            ],
            [
                11.480833,
                50.76449657
            ]
        ]
    },
    {
        "value": 0.01285347,
        "centroid": [
            11.4953085,
            50.76398328
        ],
        "vertices": [
            [
                11.4914063,
                50.76488526
            ],
            [
                11.4941748,
                50.76707726
            ],
            [
                11.498077,
                50.76617517
            ],
            [
                11.4992105,
                50.76308118
            ],
            [
                11.4964422,
                50.76088923
            ],
            [
                11.4925401,
                50.76179124
            ]
        ]
    },
    {
        "value": 0.01285347,
        "centroid": [
            11.4537896,
            50.73108781
        ],
        "vertices": [
            [
                11.4498872,
                50.73198836
            ],
            [
                11.4526547,
                50.73418248
            ],
            [
                11.4565571,
                50.73328183
            ],
            [
                11.4576918,
                50.73018714
            ],
            [
                11.4549244,
                50.72799308
            ],
            [
                11.4510222,
                50.72889365
            ]
        ]
    },
    {
        "value": 0.011568123,
        "centroid": [
            11.4717567,
            50.96924663
        ],
        "vertices": [
            [
                11.4678527,
                50.97014805
            ],
            [
                11.4706218,
                50.97234011
            ],
            [
                11.4745257,
                50.97143859
            ],
            [
                11.4756604,
                50.9683451
            ],
            [
                11.4728915,
                50.96615309
            ],
            [
                11.4689877,
                50.96705453
            ]
        ]
    },
    {
        "value": 0.011568123,
        "centroid": [
            11.4834675,
            50.96654169
        ],
        "vertices": [
            [
                11.4795641,
                50.96744345
            ],
            [
                11.4823331,
                50.96963522
            ],
            [
                11.4862365,
                50.96873336
            ],
            [
                11.4873708,
                50.96563982
            ],
            [
                11.4846019,
                50.9634481
            ],
            [
                11.4806986,
                50.96434988
            ]
        ]
    },
    {
        "value": 0.011568123,
        "centroid": [
            11.3867815,
            50.75786044
        ],
        "vertices": [
            [
                11.3828759,
                50.75875913
            ],
            [
                11.3856439,
                50.76095459
            ],
            [
                11.3895495,
                50.7600558
            ],
            [
                11.310687,
                50.75696164
            ],
            [
                11.3879192,
                50.75476622
            ],
            [
                11.3840137,
                50.75566493
            ]
        ]
    },
    {
        "value": 0.011568123,
        "centroid": [
            11.4886379,
            50.76269313
        ],
        "vertices": [
            [
                11.4847355,
                50.76359491
            ],
            [
                11.4875039,
                50.76578714
            ],
            [
                11.4914063,
                50.76488526
            ],
            [
                11.4925401,
                50.76179124
            ],
            [
                11.4897718,
                50.75959906
            ],
            [
                11.4858695,
                50.76050085
            ]
        ]
    },
    {
        "value": 0.011568123,
        "centroid": [
            11.4186622,
            50.73918875
        ],
        "vertices": [
            [
                11.4147583,
                50.7400883
            ],
            [
                11.4175259,
                50.74228327
            ],
            [
                11.4214297,
                50.74138364
            ],
            [
                11.4225659,
                50.7382891
            ],
            [
                11.4197984,
                50.73609417
            ],
            [
                11.4158947,
                50.73699373
            ]
        ]
    },
    {
        "value": 0.011568123,
        "centroid": [
            11.4119908,
            50.73789318
        ],
        "vertices": [
            [
                11.3280867,
                50.73879251
            ],
            [
                11.4108543,
                50.74098773
            ],
            [
                11.4147583,
                50.7400883
            ],
            [
                11.4158947,
                50.73699373
            ],
            [
                11.4131273,
                50.73479856
            ],
            [
                11.3292234,
                50.73569792
            ]
        ]
    },
    {
        "value": 0.011568123,
        "centroid": [
            11.3103459,
            50.72871306
        ],
        "vertices": [
            [
                11.3864414,
                50.72961169
            ],
            [
                11.3892086,
                50.73180781
            ],
            [
                11.3131131,
                50.73090908
            ],
            [
                11.3142502,
                50.72781431
            ],
            [
                11.311483,
                50.72561825
            ],
            [
                11.3875788,
                50.7265169
            ]
        ]
    },
    {
        "value": 0.010282776,
        "centroid": [
            11.3184975,
            50.75516369
        ],
        "vertices": [
            [
                11.3145924,
                50.75606272
            ],
            [
                11.3173603,
                50.7582579
            ],
            [
                11.3212654,
                50.75735878
            ],
            [
                11.3224025,
                50.75426456
            ],
            [
                11.3196347,
                50.75206943
            ],
            [
                11.3157297,
                50.75296847
            ]
        ]
    },
    {
        "value": 0.010282776,
        "centroid": [
            11.4302288,
            50.75635027
        ],
        "vertices": [
            [
                11.4263246,
                50.75725026
            ],
            [
                11.4290927,
                50.75944444
            ],
            [
                11.4329968,
                50.75854435
            ],
            [
                11.4341327,
                50.75545017
            ],
            [
                11.4313648,
                50.75325604
            ],
            [
                11.4274608,
                50.75415605
            ]
        ]
    },
    {
        "value": 0.010282776,
        "centroid": [
            11.460321,
            50.75224062
        ],
        "vertices": [
            [
                11.456418,
                50.75314149
            ],
            [
                11.4591861,
                50.75533486
            ],
            [
                11.463089,
                50.7544339
            ],
            [
                11.4642238,
                50.75133964
            ],
            [
                11.4614558,
                50.74914632
            ],
            [
                11.457553,
                50.75004721
            ]
        ]
    },
    {
        "value": 0.010282776,
        "centroid": [
            11.4471198,
            50.7297941
        ],
        "vertices": [
            [
                11.4432173,
                50.73069445
            ],
            [
                11.4459847,
                50.7328888
            ],
            [
                11.4498872,
                50.73198836
            ],
            [
                11.4510222,
                50.72889365
            ],
            [
                11.4482549,
                50.72669934
            ],
            [
                11.4443525,
                50.7275997
            ]
        ]
    },
    {
        "value": 0.010282776,
        "centroid": [
            11.4705317,
            50.72438967
        ],
        "vertices": [
            [
                11.4666301,
                50.72529069
            ],
            [
                11.4693975,
                50.72748447
            ],
            [
                11.473299,
                50.72658336
            ],
            [
                11.4744331,
                50.72348854
            ],
            [
                11.4716658,
                50.72129481
            ],
            [
                11.4677644,
                50.72219585
            ]
        ]
    },
    {
        "value": 0.010282776,
        "centroid": [
            11.4422237,
            50.71392558
        ],
        "vertices": [
            [
                11.4383216,
                50.71482569
            ],
            [
                11.4410885,
                50.71702061
            ],
            [
                11.4449907,
                50.71612041
            ],
            [
                11.4461257,
                50.71302536
            ],
            [
                11.4433588,
                50.71083049
            ],
            [
                11.4394568,
                50.71173062
            ]
        ]
    },
    {
        "value": 0.010282776,
        "centroid": [
            11.4872698,
            50.71768955
        ],
        "vertices": [
            [
                11.483369,
                50.71859103
            ],
            [
                11.4861363,
                50.72078448
            ],
            [
                11.490037,
                50.7198829
            ],
            [
                11.4911703,
                50.71678796
            ],
            [
                11.4884032,
                50.71459456
            ],
            [
                11.4845026,
                50.71549606
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.4683518,
            50.97852686
        ],
        "vertices": [
            [
                11.4644474,
                50.97942824
            ],
            [
                11.4672167,
                50.80162015
            ],
            [
                11.4711211,
                50.80071868
            ],
            [
                11.472256,
                50.97762538
            ],
            [
                11.4694868,
                50.97543352
            ],
            [
                11.4655826,
                50.9763349
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.4550043,
            50.97594505
        ],
        "vertices": [
            [
                11.4510996,
                50.97684601
            ],
            [
                11.4538688,
                50.9790384
            ],
            [
                11.4577735,
                50.97813734
            ],
            [
                11.458909,
                50.97504398
            ],
            [
                11.4561398,
                50.97285164
            ],
            [
                11.4522352,
                50.97375262
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.3849986,
            50.77243185
        ],
        "vertices": [
            [
                11.3810923,
                50.77333057
            ],
            [
                11.3838607,
                50.77552571
            ],
            [
                11.3877669,
                50.7746269
            ],
            [
                11.3889047,
                50.77153302
            ],
            [
                11.3861364,
                50.76933794
            ],
            [
                11.3822303,
                50.77023667
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.4801986,
            50.7759705
        ],
        "vertices": [
            [
                11.4762954,
                50.7768721
            ],
            [
                11.4790642,
                50.77906425
            ],
            [
                11.4829673,
                50.77816255
            ],
            [
                11.4841016,
                50.77506879
            ],
            [
                11.4813329,
                50.7728767
            ],
            [
                11.4774299,
                50.77377832
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.4718928,
            50.76939352
        ],
        "vertices": [
            [
                11.4679896,
                50.77029483
            ],
            [
                11.4707581,
                50.7724874
            ],
            [
                11.4746613,
                50.77158599
            ],
            [
                11.4757958,
                50.76849209
            ],
            [
                11.4730274,
                50.76629957
            ],
            [
                11.4691243,
                50.7672009
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.3869339,
            50.73799711
        ],
        "vertices": [
            [
                11.383029,
                50.73889569
            ],
            [
                11.3857965,
                50.74109167
            ],
            [
                11.3897014,
                50.74019299
            ],
            [
                11.3108387,
                50.73709842
            ],
            [
                11.3880713,
                50.73490249
            ],
            [
                11.3841666,
                50.73580109
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.4703954,
            50.74424982
        ],
        "vertices": [
            [
                11.466493,
                50.74515095
            ],
            [
                11.4692609,
                50.74734422
            ],
            [
                11.4731632,
                50.74644299
            ],
            [
                11.4742975,
                50.74334858
            ],
            [
                11.4715297,
                50.74115536
            ],
            [
                11.4676276,
                50.74205651
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.423702,
            50.7351945
        ],
        "vertices": [
            [
                11.4197984,
                50.73609417
            ],
            [
                11.4225659,
                50.7382891
            ],
            [
                11.4264694,
                50.73738933
            ],
            [
                11.4276054,
                50.73429471
            ],
            [
                11.424838,
                50.73209983
            ],
            [
                11.4209346,
                50.73299952
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.3153872,
            50.72471949
        ],
        "vertices": [
            [
                11.311483,
                50.72561825
            ],
            [
                11.3142502,
                50.72781431
            ],
            [
                11.3181543,
                50.72691546
            ],
            [
                11.3192911,
                50.72382061
            ],
            [
                11.3165241,
                50.7216246
            ],
            [
                11.3126201,
                50.72252338
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.4604593,
            50.73238107
        ],
        "vertices": [
            [
                11.4565571,
                50.73328183
            ],
            [
                11.4593246,
                50.73547571
            ],
            [
                11.4632268,
                50.73457486
            ],
            [
                11.4643613,
                50.7314802
            ],
            [
                11.4615939,
                50.72928637
            ],
            [
                11.4576918,
                50.73018714
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.3238378,
            50.71144061
        ],
        "vertices": [
            [
                11.3199344,
                50.71233955
            ],
            [
                11.3227012,
                50.71453571
            ],
            [
                11.3266046,
                50.71363667
            ],
            [
                11.327741,
                50.71054156
            ],
            [
                11.3249743,
                50.70834546
            ],
            [
                11.3210711,
                50.70924442
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.4355547,
            50.71263063
        ],
        "vertices": [
            [
                11.4316524,
                50.71353053
            ],
            [
                11.4344193,
                50.71572569
            ],
            [
                11.4383216,
                50.71482569
            ],
            [
                11.4394568,
                50.71173062
            ],
            [
                11.43669,
                50.70953552
            ],
            [
                11.4327878,
                50.71043543
            ]
        ]
    },
    {
        "value": 0.008997429,
        "centroid": [
            11.465633,
            50.70852258
        ],
        "vertices": [
            [
                11.4617319,
                50.70942336
            ],
            [
                11.4644988,
                50.7116177
            ],
            [
                11.4683999,
                50.71071682
            ],
            [
                11.469534,
                50.70762168
            ],
            [
                11.4667671,
                50.70542739
            ],
            [
                11.4628662,
                50.70632819
            ]
        ]
    },
    {
        "value": 0.007712082,
        "centroid": [
            11.4419402,
            50.75364963
        ],
        "vertices": [
            [
                11.4380365,
                50.75454996
            ],
            [
                11.4408046,
                50.75674385
            ],
            [
                11.4447082,
                50.75584343
            ],
            [
                11.4458436,
                50.7527492
            ],
            [
                11.4430757,
                50.75055535
            ],
            [
                11.4391722,
                50.7514557
            ]
        ]
    },
    {
        "value": 0.007712082,
        "centroid": [
            11.446979,
            50.7496549
        ],
        "vertices": [
            [
                11.4430757,
                50.75055535
            ],
            [
                11.4458436,
                50.7527492
            ],
            [
                11.4497469,
                50.75184865
            ],
            [
                11.4508821,
                50.74875433
            ],
            [
                11.4481143,
                50.74656054
            ],
            [
                11.4442111,
                50.74746101
            ]
        ]
    },
    {
        "value": 0.007712082,
        "centroid": [
            11.4403078,
            50.74836137
        ],
        "vertices": [
            [
                11.4364043,
                50.74926162
            ],
            [
                11.4391722,
                50.7514557
            ],
            [
                11.4430757,
                50.75055535
            ],
            [
                11.4442111,
                50.74746101
            ],
            [
                11.4414433,
                50.74526698
            ],
            [
                11.43754,
                50.74616724
            ]
        ]
    },
    {
        "value": 0.007712082,
        "centroid": [
            11.4437142,
            50.73907802
        ],
        "vertices": [
            [
                11.4398111,
                50.73997831
            ],
            [
                11.4425788,
                50.74217253
            ],
            [
                11.4464818,
                50.74127214
            ],
            [
                11.447617,
                50.73817761
            ],
            [
                11.4448494,
                50.73598344
            ],
            [
                11.4409466,
                50.73688376
            ]
        ]
    },
    {
        "value": 0.007712082,
        "centroid": [
            11.3137571,
            50.71942844
        ],
        "vertices": [
            [
                11.3898532,
                50.72032713
            ],
            [
                11.3126201,
                50.72252338
            ],
            [
                11.3165241,
                50.7216246
            ],
            [
                11.317661,
                50.71852965
            ],
            [
                11.3148941,
                50.71633344
            ],
            [
                11.3109903,
                50.71723214
            ]
        ]
    },
    {
        "value": 0.007712082,
        "centroid": [
            11.4371863,
            50.7179207
        ],
        "vertices": [
            [
                11.4332838,
                50.71882068
            ],
            [
                11.4360509,
                50.72101565
            ],
            [
                11.4399533,
                50.72011557
            ],
            [
                11.4410885,
                50.71702061
            ],
            [
                11.4383216,
                50.71482569
            ],
            [
                11.4344193,
                50.71572569
            ]
        ]
    },
    {
        "value": 0.007712082,
        "centroid": [
            11.4806019,
            50.71639745
        ],
        "vertices": [
            [
                11.476701,
                50.71729872
            ],
            [
                11.4794681,
                50.71949241
            ],
            [
                11.483369,
                50.71859103
            ],
            [
                11.4845026,
                50.71549606
            ],
            [
                11.4817356,
                50.71330243
            ],
            [
                11.4778348,
                50.71420372
            ]
        ]
    },
    {
        "value": 0.007712082,
        "centroid": [
            11.4856362,
            50.71240102
        ],
        "vertices": [
            [
                11.4817356,
                50.71330243
            ],
            [
                11.4845026,
                50.71549606
            ],
            [
                11.4884032,
                50.71459456
            ],
            [
                11.4895366,
                50.71149951
            ],
            [
                11.4867696,
                50.70930592
            ],
            [
                11.4828692,
                50.71020734
            ]
        ]
    },
    {
        "value": 0.007712082,
        "centroid": [
            11.4773354,
            50.70581956
        ],
        "vertices": [
            [
                11.4734348,
                50.70672068
            ],
            [
                11.4762016,
                50.70891474
            ],
            [
                11.4801022,
                50.70801352
            ],
            [
                11.4812359,
                50.70491833
            ],
            [
                11.4784691,
                50.70272432
            ],
            [
                11.4745686,
                50.70362546
            ]
        ]
    },
    {
        "value": 0.006426735,
        "centroid": [
            11.4784295,
            50.97053696
        ],
        "vertices": [
            [
                11.4745257,
                50.97143859
            ],
            [
                11.4772948,
                50.9736304
            ],
            [
                11.4811986,
                50.97272868
            ],
            [
                11.4823331,
                50.96963522
            ],
            [
                11.4795641,
                50.96744345
            ],
            [
                11.4756604,
                50.9683451
            ]
        ]
    },
    {
        "value": 0.006426735,
        "centroid": [
            11.4486116,
            50.7549429
        ],
        "vertices": [
            [
                11.4447082,
                50.75584343
            ],
            [
                11.4474763,
                50.75803708
            ],
            [
                11.4513797,
                50.75713645
            ],
            [
                11.4525149,
                50.75404225
            ],
            [
                11.4497469,
                50.75184865
            ],
            [
                11.4458436,
                50.7527492
            ]
        ]
    },
    {
        "value": 0.006426735,
        "centroid": [
            11.4870035,
            50.75740673
        ],
        "vertices": [
            [
                11.4831013,
                50.75830843
            ],
            [
                11.4858695,
                50.76050085
            ],
            [
                11.4897718,
                50.75959906
            ],
            [
                11.4909056,
                50.75650492
            ],
            [
                11.4881374,
                50.75431255
            ],
            [
                11.4842353,
                50.75521427
            ]
        ]
    },
    {
        "value": 0.006426735,
        "centroid": [
            11.3119759,
            50.73400378
        ],
        "vertices": [
            [
                11.3880713,
                50.73490249
            ],
            [
                11.3108387,
                50.73709842
            ],
            [
                11.3147433,
                50.73619961
            ],
            [
                11.3158803,
                50.73310495
            ],
            [
                11.3131131,
                50.73090908
            ],
            [
                11.3892086,
                50.73180781
            ]
        ]
    },
    {
        "value": 0.006426735,
        "centroid": [
            11.4954401,
            50.74412775
        ],
        "vertices": [
            [
                11.4915386,
                50.74502963
            ],
            [
                11.4943066,
                50.74722214
            ],
            [
                11.498208,
                50.74632016
            ],
            [
                11.4993414,
                50.74322576
            ],
            [
                11.4965735,
                50.7410333
            ],
            [
                11.4926722,
                50.7419352
            ]
        ]
    },
    {
        "value": 0.006426735,
        "centroid": [
            11.4638628,
            50.72309677
        ],
        "vertices": [
            [
                11.4599611,
                50.72399758
            ],
            [
                11.4627284,
                50.7261916
            ],
            [
                11.4666301,
                50.72529069
            ],
            [
                11.4677644,
                50.72219585
            ],
            [
                11.4649972,
                50.72000188
            ],
            [
                11.4610956,
                50.7209027
            ]
        ]
    },
    {
        "value": 0.006426735,
        "centroid": [
            11.4571939,
            50.72180342
        ],
        "vertices": [
            [
                11.453292,
                50.72270402
            ],
            [
                11.4560592,
                50.72489828
            ],
            [
                11.4599611,
                50.72399758
            ],
            [
                11.4610956,
                50.7209027
            ],
            [
                11.4583285,
                50.7187085
            ],
            [
                11.4544267,
                50.71960912
            ]
        ]
    },
    {
        "value": 0.006426735,
        "centroid": [
            11.4688986,
            50.71910094
        ],
        "vertices": [
            [
                11.4649972,
                50.72000188
            ],
            [
                11.4677644,
                50.72219585
            ],
            [
                11.4716658,
                50.72129481
            ],
            [
                11.4727999,
                50.71819989
            ],
            [
                11.4700328,
                50.71600597
            ],
            [
                11.4661315,
                50.71690692
            ]
        ]
    },
    {
        "value": 0.006426735,
        "centroid": [
            11.458965,
            50.70722887
        ],
        "vertices": [
            [
                11.4550637,
                50.70812945
            ],
            [
                11.4578306,
                50.71032403
            ],
            [
                11.4617319,
                50.70942336
            ],
            [
                11.4628662,
                50.70632819
            ],
            [
                11.4600994,
                50.70413365
            ],
            [
                11.4561982,
                50.70503424
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.480064,
            50.97582208
        ],
        "vertices": [
            [
                11.4761601,
                50.97672378
            ],
            [
                11.4789294,
                50.97891541
            ],
            [
                11.4828333,
                50.97801361
            ],
            [
                11.4839678,
                50.97492025
            ],
            [
                11.4811986,
                50.97272868
            ],
            [
                11.4772948,
                50.9736304
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4667177,
            50.97324151
        ],
        "vertices": [
            [
                11.4628134,
                50.9741428
            ],
            [
                11.4655826,
                50.9763349
            ],
            [
                11.4694868,
                50.97543352
            ],
            [
                11.4706218,
                50.97234011
            ],
            [
                11.4678527,
                50.97014805
            ],
            [
                11.4639486,
                50.97104936
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4533708,
            50.97065916
        ],
        "vertices": [
            [
                11.4494662,
                50.97156004
            ],
            [
                11.4522352,
                50.97375262
            ],
            [
                11.4561398,
                50.97285164
            ],
            [
                11.4572753,
                50.96975817
            ],
            [
                11.4545063,
                50.96756565
            ],
            [
                11.4506018,
                50.96846654
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.3866291,
            50.7777207
        ],
        "vertices": [
            [
                11.3827227,
                50.7786195
            ],
            [
                11.3854912,
                50.96081445
            ],
            [
                11.3893976,
                50.77991556
            ],
            [
                11.3105353,
                50.7768218
            ],
            [
                11.3877669,
                50.7746269
            ],
            [
                11.3838607,
                50.77552571
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.3851513,
            50.75257067
        ],
        "vertices": [
            [
                11.3812458,
                50.75346928
            ],
            [
                11.3840137,
                50.75566493
            ],
            [
                11.3879192,
                50.75476622
            ],
            [
                11.3890567,
                50.75167194
            ],
            [
                11.386289,
                50.74947634
            ],
            [
                11.3823836,
                50.75037497
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.3818914,
            50.74199024
        ],
        "vertices": [
            [
                11.3779862,
                50.74288869
            ],
            [
                11.3807537,
                50.74508472
            ],
            [
                11.3846589,
                50.74418617
            ],
            [
                11.3857965,
                50.74109167
            ],
            [
                11.383029,
                50.73889569
            ],
            [
                11.379124,
                50.73979416
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4536501,
            50.75094798
        ],
        "vertices": [
            [
                11.4497469,
                50.75184865
            ],
            [
                11.4525149,
                50.75404225
            ],
            [
                11.456418,
                50.75314149
            ],
            [
                11.457553,
                50.75004721
            ],
            [
                11.4547851,
                50.74785366
            ],
            [
                11.4508821,
                50.74875433
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4136218,
            50.7431828
        ],
        "vertices": [
            [
                11.3297176,
                50.74408222
            ],
            [
                11.4124853,
                50.74627724
            ],
            [
                11.4163895,
                50.74537773
            ],
            [
                11.4175259,
                50.74228327
            ],
            [
                11.4147583,
                50.7400883
            ],
            [
                11.4108543,
                50.74098773
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.3802617,
            50.73669958
        ],
        "vertices": [
            [
                11.3763566,
                50.73759795
            ],
            [
                11.379124,
                50.73979416
            ],
            [
                11.383029,
                50.73889569
            ],
            [
                11.3841666,
                50.73580109
            ],
            [
                11.3813993,
                50.73360493
            ],
            [
                11.3774943,
                50.73450332
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4720289,
            50.74953734
        ],
        "vertices": [
            [
                11.4681264,
                50.75043855
            ],
            [
                11.4708944,
                50.75263163
            ],
            [
                11.4747969,
                50.75173033
            ],
            [
                11.4759311,
                50.74863602
            ],
            [
                11.4731632,
                50.74644299
            ],
            [
                11.4692609,
                50.74734422
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4386756,
            50.74307281
        ],
        "vertices": [
            [
                11.4347723,
                50.74397298
            ],
            [
                11.43754,
                50.74616724
            ],
            [
                11.4414433,
                50.74526698
            ],
            [
                11.4425788,
                50.74217253
            ],
            [
                11.4398111,
                50.73997831
            ],
            [
                11.4359079,
                50.7408785
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4370435,
            50.73778396
        ],
        "vertices": [
            [
                11.4331403,
                50.73868404
            ],
            [
                11.4359079,
                50.7408785
            ],
            [
                11.4398111,
                50.73997831
            ],
            [
                11.4409466,
                50.73688376
            ],
            [
                11.4381791,
                50.73468935
            ],
            [
                11.434276,
                50.73558946
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4821013,
            50.74154577
        ],
        "vertices": [
            [
                11.4781995,
                50.74244723
            ],
            [
                11.4809673,
                50.74464021
            ],
            [
                11.4848691,
                50.74373865
            ],
            [
                11.4860029,
                50.74064419
            ],
            [
                11.4832352,
                50.73845126
            ],
            [
                11.4793335,
                50.73935274
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4620922,
            50.73766945
        ],
        "vertices": [
            [
                11.4581898,
                50.73857029
            ],
            [
                11.4609575,
                50.74076399
            ],
            [
                11.4648598,
                50.73986305
            ],
            [
                11.4659944,
                50.7367685
            ],
            [
                11.4632268,
                50.73457486
            ],
            [
                11.4593246,
                50.73547571
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.3287291,
            50.72731304
        ],
        "vertices": [
            [
                11.3248254,
                50.72821222
            ],
            [
                11.3275926,
                50.73040781
            ],
            [
                11.4114964,
                50.72950853
            ],
            [
                11.4126327,
                50.72641375
            ],
            [
                11.3298656,
                50.72421821
            ],
            [
                11.325962,
                50.7251174
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4671288,
            50.73367389
        ],
        "vertices": [
            [
                11.4632268,
                50.73457486
            ],
            [
                11.4659944,
                50.7367685
            ],
            [
                11.4698964,
                50.73586744
            ],
            [
                11.4710307,
                50.73277281
            ],
            [
                11.4682632,
                50.73057921
            ],
            [
                11.4643613,
                50.7314802
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4855027,
            50.73226206
        ],
        "vertices": [
            [
                11.4816014,
                50.73316357
            ],
            [
                11.484369,
                50.73535669
            ],
            [
                11.4882703,
                50.73445508
            ],
            [
                11.4894039,
                50.73136043
            ],
            [
                11.4866364,
                50.72916736
            ],
            [
                11.4827352,
                50.73006889
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4788339,
            50.73097031
        ],
        "vertices": [
            [
                11.4749323,
                50.73187161
            ],
            [
                11.4776999,
                50.73406497
            ],
            [
                11.4816014,
                50.73316357
            ],
            [
                11.4827352,
                50.73006889
            ],
            [
                11.4799678,
                50.72787558
            ],
            [
                11.4760664,
                50.7287769
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4588265,
            50.72709239
        ],
        "vertices": [
            [
                11.4549244,
                50.72799308
            ],
            [
                11.4576918,
                50.73018714
            ],
            [
                11.4615939,
                50.72928637
            ],
            [
                11.4627284,
                50.7261916
            ],
            [
                11.4599611,
                50.72399758
            ],
            [
                11.4560592,
                50.72489828
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4121383,
            50.71802836
        ],
        "vertices": [
            [
                11.328235,
                50.71892759
            ],
            [
                11.411002,
                50.72112332
            ],
            [
                11.4149053,
                50.72022399
            ],
            [
                11.4160415,
                50.71712902
            ],
            [
                11.4132746,
                50.71493334
            ],
            [
                11.3293714,
                50.71583259
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4905374,
            50.72826572
        ],
        "vertices": [
            [
                11.4866364,
                50.72916736
            ],
            [
                11.4894039,
                50.73136043
            ],
            [
                11.4933049,
                50.7304587
            ],
            [
                11.4944383,
                50.72736397
            ],
            [
                11.4916709,
                50.72517095
            ],
            [
                11.48777,
                50.7260726
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4757024,
            50.70053017
        ],
        "vertices": [
            [
                11.4718019,
                50.70143121
            ],
            [
                11.4745686,
                50.70362546
            ],
            [
                11.4784691,
                50.70272432
            ],
            [
                11.4796027,
                50.69962902
            ],
            [
                11.476836,
                50.69743483
            ],
            [
                11.4729357,
                50.69833588
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4750253,
            50.9798171
        ],
        "vertices": [
            [
                11.4711211,
                50.80071868
            ],
            [
                11.4738905,
                50.80291036
            ],
            [
                11.4777947,
                50.80200868
            ],
            [
                11.4789294,
                50.97891541
            ],
            [
                11.4761601,
                50.97672378
            ],
            [
                11.472256,
                50.97762538
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4901398,
            50.96783139
        ],
        "vertices": [
            [
                11.4862365,
                50.96873336
            ],
            [
                11.4890056,
                50.97092489
            ],
            [
                11.4929089,
                50.97002283
            ],
            [
                11.4940429,
                50.96692932
            ],
            [
                11.4912739,
                50.96473784
            ],
            [
                11.4873708,
                50.96563982
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4935419,
            50.77855053
        ],
        "vertices": [
            [
                11.4896391,
                50.77945255
            ],
            [
                11.492408,
                50.96164422
            ],
            [
                11.4963108,
                50.9607421
            ],
            [
                11.4974445,
                50.77764841
            ],
            [
                11.4946758,
                50.77545679
            ],
            [
                11.4907731,
                50.77635882
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4985783,
            50.77455464
        ],
        "vertices": [
            [
                11.4946758,
                50.77545679
            ],
            [
                11.4974445,
                50.77764841
            ],
            [
                11.501347,
                50.77674616
            ],
            [
                11.5024806,
                50.77365239
            ],
            [
                11.4997119,
                50.77146082
            ],
            [
                11.4958096,
                50.77236298
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4969433,
            50.76926911
        ],
        "vertices": [
            [
                11.493041,
                50.77017117
            ],
            [
                11.4958096,
                50.77236298
            ],
            [
                11.4997119,
                50.77146082
            ],
            [
                11.5008455,
                50.76836693
            ],
            [
                11.498077,
                50.76617517
            ],
            [
                11.4941748,
                50.76707726
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4936738,
            50.75869715
        ],
        "vertices": [
            [
                11.4897718,
                50.75959906
            ],
            [
                11.4925401,
                50.76179124
            ],
            [
                11.4964422,
                50.76088923
            ],
            [
                11.4975757,
                50.75779513
            ],
            [
                11.4948075,
                50.755603
            ],
            [
                11.4909056,
                50.75650492
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.3136061,
            50.7392942
        ],
        "vertices": [
            [
                11.3897014,
                50.74019299
            ],
            [
                11.3124689,
                50.74238873
            ],
            [
                11.3163736,
                50.74148985
            ],
            [
                11.3175107,
                50.7383953
            ],
            [
                11.3147433,
                50.73619961
            ],
            [
                11.3108387,
                50.73709842
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4920393,
            50.75341072
        ],
        "vertices": [
            [
                11.4881374,
                50.75431255
            ],
            [
                11.4909056,
                50.75650492
            ],
            [
                11.4948075,
                50.755603
            ],
            [
                11.4959411,
                50.75250878
            ],
            [
                11.493173,
                50.75031646
            ],
            [
                11.4892712,
                50.75121831
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4320046,
            50.74177857
        ],
        "vertices": [
            [
                11.4281011,
                50.74267853
            ],
            [
                11.4308687,
                50.74487303
            ],
            [
                11.4347723,
                50.74397298
            ],
            [
                11.4359079,
                50.7408785
            ],
            [
                11.4331403,
                50.73868404
            ],
            [
                11.429237,
                50.73958402
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.3652877,
            50.72881168
        ],
        "vertices": [
            [
                11.3613824,
                50.72970956
            ],
            [
                11.3641495,
                50.73190644
            ],
            [
                11.3680548,
                50.73100846
            ],
            [
                11.3691928,
                50.72791369
            ],
            [
                11.3664258,
                50.72571687
            ],
            [
                11.3625207,
                50.72661476
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4970746,
            50.7494145
        ],
        "vertices": [
            [
                11.493173,
                50.75031646
            ],
            [
                11.4959411,
                50.75250878
            ],
            [
                11.4998427,
                50.75160673
            ],
            [
                11.500976,
                50.74851243
            ],
            [
                11.498208,
                50.74632016
            ],
            [
                11.4943066,
                50.74722214
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4837352,
            50.74683305
        ],
        "vertices": [
            [
                11.4798333,
                50.74773459
            ],
            [
                11.4826012,
                50.74992739
            ],
            [
                11.4865032,
                50.74902575
            ],
            [
                11.487637,
                50.7459314
            ],
            [
                11.4848691,
                50.74373865
            ],
            [
                11.4809673,
                50.74464021
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.457055,
            50.74166481
        ],
        "vertices": [
            [
                11.4531523,
                50.74256552
            ],
            [
                11.4559201,
                50.74475926
            ],
            [
                11.4598227,
                50.74385846
            ],
            [
                11.4609575,
                50.74076399
            ],
            [
                11.4581898,
                50.73857029
            ],
            [
                11.4542873,
                50.73947102
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4154,
            50.72860915
        ],
        "vertices": [
            [
                11.4114964,
                50.72950853
            ],
            [
                11.4142636,
                50.73170389
            ],
            [
                11.4181672,
                50.73080441
            ],
            [
                11.4193034,
                50.72770965
            ],
            [
                11.4165362,
                50.72551435
            ],
            [
                11.4126327,
                50.72641375
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.388716,
            50.72342204
        ],
        "vertices": [
            [
                11.3848117,
                50.7243206
            ],
            [
                11.3875788,
                50.7265169
            ],
            [
                11.311483,
                50.72561825
            ],
            [
                11.3126201,
                50.72252338
            ],
            [
                11.3898532,
                50.72032713
            ],
            [
                11.385949,
                50.7212257
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4938057,
            50.73884071
        ],
        "vertices": [
            [
                11.4899044,
                50.7397425
            ],
            [
                11.4926722,
                50.7419352
            ],
            [
                11.4965735,
                50.7410333
            ],
            [
                11.4977069,
                50.73793879
            ],
            [
                11.4949391,
                50.73574615
            ],
            [
                11.491038,
                50.73664796
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4804675,
            50.73625819
        ],
        "vertices": [
            [
                11.4765658,
                50.73715957
            ],
            [
                11.4793335,
                50.73935274
            ],
            [
                11.4832352,
                50.73845126
            ],
            [
                11.484369,
                50.73535669
            ],
            [
                11.4816014,
                50.73316357
            ],
            [
                11.4776999,
                50.73406497
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4271097,
            50.72591032
        ],
        "vertices": [
            [
                11.4232066,
                50.72681004
            ],
            [
                11.4259739,
                50.72900511
            ],
            [
                11.429877,
                50.72810529
            ],
            [
                11.4310127,
                50.72501048
            ],
            [
                11.4282455,
                50.72281547
            ],
            [
                11.4243426,
                50.7237152
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4988401,
            50.73484422
        ],
        "vertices": [
            [
                11.4949391,
                50.73574615
            ],
            [
                11.4977069,
                50.73793879
            ],
            [
                11.5016078,
                50.73703677
            ],
            [
                11.502741,
                50.73394218
            ],
            [
                11.4999733,
                50.73174958
            ],
            [
                11.4960725,
                50.73265153
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4321483,
            50.72191561
        ],
        "vertices": [
            [
                11.4282455,
                50.72281547
            ],
            [
                11.4310127,
                50.72501048
            ],
            [
                11.4349154,
                50.72411054
            ],
            [
                11.4360509,
                50.72101565
            ],
            [
                11.4332838,
                50.71882068
            ],
            [
                11.4293812,
                50.71972055
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4555614,
            50.71651415
        ],
        "vertices": [
            [
                11.4516596,
                50.71741467
            ],
            [
                11.4544267,
                50.71960912
            ],
            [
                11.4583285,
                50.7187085
            ],
            [
                11.459463,
                50.71561351
            ],
            [
                11.456696,
                50.71341912
            ],
            [
                11.4527944,
                50.71431966
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4222162,
            50.7100394
        ],
        "vertices": [
            [
                11.4183136,
                50.71093889
            ],
            [
                11.4210804,
                50.71313451
            ],
            [
                11.424983,
                50.71223493
            ],
            [
                11.4261187,
                50.7091398
            ],
            [
                11.423352,
                50.70694422
            ],
            [
                11.4194495,
                50.70784372
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.4472606,
            50.70993026
        ],
        "vertices": [
            [
                11.4433588,
                50.71083049
            ],
            [
                11.4461257,
                50.71302536
            ],
            [
                11.4500275,
                50.71212503
            ],
            [
                11.4511622,
                50.70902991
            ],
            [
                11.4483954,
                50.70683509
            ],
            [
                11.4444938,
                50.70773534
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4951769,
            50.96383574
        ],
        "vertices": [
            [
                11.4912739,
                50.96473784
            ],
            [
                11.4940429,
                50.96692932
            ],
            [
                11.4979459,
                50.96602713
            ],
            [
                11.4990797,
                50.96293353
            ],
            [
                11.4963108,
                50.9607421
            ],
            [
                11.492408,
                50.96164422
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4803331,
            50.75611587
        ],
        "vertices": [
            [
                11.4764307,
                50.75701737
            ],
            [
                11.4791989,
                50.75921002
            ],
            [
                11.4831013,
                50.75830843
            ],
            [
                11.4842353,
                50.75521427
            ],
            [
                11.4814672,
                50.75302166
            ],
            [
                11.4775649,
                50.75392317
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4669918,
            50.75353282
        ],
        "vertices": [
            [
                11.463089,
                50.7544339
            ],
            [
                11.4658571,
                50.75662703
            ],
            [
                11.4697599,
                50.75572585
            ],
            [
                11.4708944,
                50.75263163
            ],
            [
                11.4681264,
                50.75043855
            ],
            [
                11.4642238,
                50.75133964
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4987092,
            50.75470096
        ],
        "vertices": [
            [
                11.4948075,
                50.755603
            ],
            [
                11.4975757,
                50.75779513
            ],
            [
                11.5014775,
                50.756893
            ],
            [
                11.5026108,
                50.75379881
            ],
            [
                11.4998427,
                50.75160673
            ],
            [
                11.4959411,
                50.75250878
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.3786321,
            50.73140862
        ],
        "vertices": [
            [
                11.3747271,
                50.73230692
            ],
            [
                11.3774943,
                50.73450332
            ],
            [
                11.3813993,
                50.73360493
            ],
            [
                11.3825368,
                50.73051021
            ],
            [
                11.3797697,
                50.72831386
            ],
            [
                11.3758649,
                50.72921217
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.3719599,
            50.73011037
        ],
        "vertices": [
            [
                11.3680548,
                50.73100846
            ],
            [
                11.370822,
                50.7332051
            ],
            [
                11.3747271,
                50.73230692
            ],
            [
                11.3758649,
                50.72921217
            ],
            [
                11.3730978,
                50.72701559
            ],
            [
                11.3691928,
                50.72791369
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.3170173,
            50.73001024
        ],
        "vertices": [
            [
                11.3131131,
                50.73090908
            ],
            [
                11.3158803,
                50.73310495
            ],
            [
                11.3197846,
                50.73220602
            ],
            [
                11.3209214,
                50.72911128
            ],
            [
                11.3181543,
                50.72691546
            ],
            [
                11.3142502,
                50.72781431
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4887707,
            50.74283698
        ],
        "vertices": [
            [
                11.4848691,
                50.74373865
            ],
            [
                11.487637,
                50.7459314
            ],
            [
                11.4915386,
                50.74502963
            ],
            [
                11.4926722,
                50.7419352
            ],
            [
                11.4899044,
                50.7397425
            ],
            [
                11.4860029,
                50.74064419
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4487522,
            50.73508302
        ],
        "vertices": [
            [
                11.4448494,
                50.73598344
            ],
            [
                11.447617,
                50.73817761
            ],
            [
                11.4515197,
                50.73727709
            ],
            [
                11.4526547,
                50.73418248
            ],
            [
                11.4498872,
                50.73198836
            ],
            [
                11.4459847,
                50.7328888
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.3270985,
            50.72202253
        ],
        "vertices": [
            [
                11.3231949,
                50.72292163
            ],
            [
                11.325962,
                50.7251174
            ],
            [
                11.3298656,
                50.72421821
            ],
            [
                11.411002,
                50.72112332
            ],
            [
                11.328235,
                50.71892759
            ],
            [
                11.3243315,
                50.7198267
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4254784,
            50.72062031
        ],
        "vertices": [
            [
                11.4215755,
                50.72151995
            ],
            [
                11.4243426,
                50.7237152
            ],
            [
                11.4282455,
                50.72281547
            ],
            [
                11.4293812,
                50.71972055
            ],
            [
                11.4266142,
                50.71752535
            ],
            [
                11.4227114,
                50.71842501
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.3254681,
            50.71673172
        ],
        "vertices": [
            [
                11.3215646,
                50.71763074
            ],
            [
                11.3243315,
                50.7198267
            ],
            [
                11.328235,
                50.71892759
            ],
            [
                11.3293714,
                50.71583259
            ],
            [
                11.3266046,
                50.71363667
            ],
            [
                11.3227012,
                50.71453571
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4105077,
            50.71273752
        ],
        "vertices": [
            [
                11.3266046,
                50.71363667
            ],
            [
                11.3293714,
                50.71583259
            ],
            [
                11.4132746,
                50.71493334
            ],
            [
                11.4144107,
                50.71183826
            ],
            [
                11.411644,
                50.70964239
            ],
            [
                11.327741,
                50.71054156
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4288855,
            50.71133524
        ],
        "vertices": [
            [
                11.424983,
                50.71223493
            ],
            [
                11.4277499,
                50.71443032
            ],
            [
                11.4316524,
                50.71353053
            ],
            [
                11.4327878,
                50.71043543
            ],
            [
                11.430021,
                50.70824009
            ],
            [
                11.4261187,
                50.7091398
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4155468,
            50.70874312
        ],
        "vertices": [
            [
                11.411644,
                50.70964239
            ],
            [
                11.4144107,
                50.71183826
            ],
            [
                11.4183136,
                50.71093889
            ],
            [
                11.4194495,
                50.70784372
            ],
            [
                11.4166828,
                50.70564791
            ],
            [
                11.4127801,
                50.7065472
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4733909,
            50.97453202
        ],
        "vertices": [
            [
                11.4694868,
                50.97543352
            ],
            [
                11.472256,
                50.97762538
            ],
            [
                11.4761601,
                50.97672378
            ],
            [
                11.4772948,
                50.9736304
            ],
            [
                11.4745257,
                50.97143859
            ],
            [
                11.4706218,
                50.97234011
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4600443,
            50.97195056
        ],
        "vertices": [
            [
                11.4561398,
                50.97285164
            ],
            [
                11.458909,
                50.97504398
            ],
            [
                11.4628134,
                50.9741428
            ],
            [
                11.4639486,
                50.97104936
            ],
            [
                11.4611796,
                50.96885707
            ],
            [
                11.4572753,
                50.96975817
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4650837,
            50.96795586
        ],
        "vertices": [
            [
                11.4611796,
                50.96885707
            ],
            [
                11.4639486,
                50.97104936
            ],
            [
                11.4678527,
                50.97014805
            ],
            [
                11.4689877,
                50.96705453
            ],
            [
                11.4662187,
                50.96486229
            ],
            [
                11.4623148,
                50.96576352
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3833682,
            50.76714271
        ],
        "vertices": [
            [
                11.3794621,
                50.76804134
            ],
            [
                11.3822303,
                50.77023667
            ],
            [
                11.3861364,
                50.76933794
            ],
            [
                11.3872742,
                50.76624396
            ],
            [
                11.3845061,
                50.76404868
            ],
            [
                11.3806001,
                50.76494733
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3835213,
            50.7472806
        ],
        "vertices": [
            [
                11.3796159,
                50.74817913
            ],
            [
                11.3823836,
                50.75037497
            ],
            [
                11.386289,
                50.74947634
            ],
            [
                11.3874265,
                50.74638195
            ],
            [
                11.3846589,
                50.74418617
            ],
            [
                11.3807537,
                50.74508472
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.455283,
            50.75623571
        ],
        "vertices": [
            [
                11.4513797,
                50.75713645
            ],
            [
                11.4541479,
                50.75932987
            ],
            [
                11.4580511,
                50.75842903
            ],
            [
                11.4591861,
                50.75533486
            ],
            [
                11.456418,
                50.75314149
            ],
            [
                11.4525149,
                50.75404225
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3735893,
            50.7354016
        ],
        "vertices": [
            [
                11.369684,
                50.73629976
            ],
            [
                11.3724513,
                50.73849621
            ],
            [
                11.3763566,
                50.73759795
            ],
            [
                11.3774943,
                50.73450332
            ],
            [
                11.3747271,
                50.73230692
            ],
            [
                11.370822,
                50.7332051
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4853693,
            50.75212004
        ],
        "vertices": [
            [
                11.4814672,
                50.75302166
            ],
            [
                11.4842353,
                50.75521427
            ],
            [
                11.4881374,
                50.75431255
            ],
            [
                11.4892712,
                50.75121831
            ],
            [
                11.4865032,
                50.74902575
            ],
            [
                11.4826012,
                50.74992739
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4653584,
            50.74824533
        ],
        "vertices": [
            [
                11.4614558,
                50.74914632
            ],
            [
                11.4642238,
                50.75133964
            ],
            [
                11.4681264,
                50.75043855
            ],
            [
                11.4692609,
                50.74734422
            ],
            [
                11.466493,
                50.74515095
            ],
            [
                11.4625906,
                50.74605196
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3186477,
            50.73530069
        ],
        "vertices": [
            [
                11.3147433,
                50.73619961
            ],
            [
                11.3175107,
                50.7383953
            ],
            [
                11.3214151,
                50.73749628
            ],
            [
                11.3225519,
                50.73440166
            ],
            [
                11.3197846,
                50.73220602
            ],
            [
                11.3158803,
                50.73310495
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.385304,
            50.73270642
        ],
        "vertices": [
            [
                11.3813993,
                50.73360493
            ],
            [
                11.3841666,
                50.73580109
            ],
            [
                11.3880713,
                50.73490249
            ],
            [
                11.3892086,
                50.73180781
            ],
            [
                11.3864414,
                50.72961169
            ],
            [
                11.3825368,
                50.73051021
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.490405,
            50.748124
        ],
        "vertices": [
            [
                11.4865032,
                50.74902575
            ],
            [
                11.4892712,
                50.75121831
            ],
            [
                11.493173,
                50.75031646
            ],
            [
                11.4943066,
                50.74722214
            ],
            [
                11.4915386,
                50.74502963
            ],
            [
                11.487637,
                50.7459314
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4503846,
            50.74037164
        ],
        "vertices": [
            [
                11.4464818,
                50.74127214
            ],
            [
                11.4492495,
                50.74346612
            ],
            [
                11.4531523,
                50.74256552
            ],
            [
                11.4542873,
                50.73947102
            ],
            [
                11.4515197,
                50.73727709
            ],
            [
                11.447617,
                50.73817761
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4554222,
            50.73637646
        ],
        "vertices": [
            [
                11.4515197,
                50.73727709
            ],
            [
                11.4542873,
                50.73947102
            ],
            [
                11.4581898,
                50.73857029
            ],
            [
                11.4593246,
                50.73547571
            ],
            [
                11.4565571,
                50.73328183
            ],
            [
                11.4526547,
                50.73418248
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4137691,
            50.7233189
        ],
        "vertices": [
            [
                11.3298656,
                50.72421821
            ],
            [
                11.4126327,
                50.72641375
            ],
            [
                11.4165362,
                50.72551435
            ],
            [
                11.4176723,
                50.72241948
            ],
            [
                11.4149053,
                50.72022399
            ],
            [
                11.411002,
                50.72112332
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4654957,
            50.72838548
        ],
        "vertices": [
            [
                11.4615939,
                50.72928637
            ],
            [
                11.4643613,
                50.7314802
            ],
            [
                11.4682632,
                50.73057921
            ],
            [
                11.4693975,
                50.72748447
            ],
            [
                11.4666301,
                50.72529069
            ],
            [
                11.4627284,
                50.7261916
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3121273,
            50.7141371
        ],
        "vertices": [
            [
                11.3882234,
                50.71503571
            ],
            [
                11.3109903,
                50.71723214
            ],
            [
                11.3148941,
                50.71633344
            ],
            [
                11.3160309,
                50.71323838
            ],
            [
                11.3132642,
                50.71104199
            ],
            [
                11.3893605,
                50.71194062
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3104975,
            50.70884546
        ],
        "vertices": [
            [
                11.3865939,
                50.70974399
            ],
            [
                11.3893605,
                50.71194062
            ],
            [
                11.3132642,
                50.71104199
            ],
            [
                11.3144011,
                50.70794683
            ],
            [
                11.3116345,
                50.70575025
            ],
            [
                11.3877309,
                50.70664879
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4272543,
            50.70604461
        ],
        "vertices": [
            [
                11.423352,
                50.70694422
            ],
            [
                11.4261187,
                50.7091398
            ],
            [
                11.430021,
                50.70824009
            ],
            [
                11.4311565,
                50.70514488
            ],
            [
                11.4283899,
                50.70294935
            ],
            [
                11.4244877,
                50.70384898
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4139162,
            50.70345195
        ],
        "vertices": [
            [
                11.4100135,
                50.70435115
            ],
            [
                11.4127801,
                50.7065472
            ],
            [
                11.4166828,
                50.70564791
            ],
            [
                11.4178188,
                50.70255264
            ],
            [
                11.4150522,
                50.70035664
            ],
            [
                11.4111497,
                50.70125585
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.327247,
            50.70215496
        ],
        "vertices": [
            [
                11.3233441,
                50.70305395
            ],
            [
                11.3261107,
                50.70525024
            ],
            [
                11.4100135,
                50.70435115
            ],
            [
                11.4111497,
                50.70125585
            ],
            [
                11.3283832,
                50.69905961
            ],
            [
                11.3244805,
                50.69995862
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.498971,
            50.71498443
        ],
        "vertices": [
            [
                11.4950708,
                50.71588625
            ],
            [
                11.4978379,
                50.71807941
            ],
            [
                11.5017382,
                50.71717749
            ],
            [
                11.5028711,
                50.7140825
            ],
            [
                11.500104,
                50.71188939
            ],
            [
                11.4962039,
                50.71279123
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4840027,
            50.7071122
        ],
        "vertices": [
            [
                11.4801022,
                50.70801352
            ],
            [
                11.4828692,
                50.71020734
            ],
            [
                11.4867696,
                50.70930592
            ],
            [
                11.487903,
                50.70621076
            ],
            [
                11.4851362,
                50.70401699
            ],
            [
                11.4812359,
                50.70491833
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4640004,
            50.70323295
        ],
        "vertices": [
            [
                11.4600994,
                50.70413365
            ],
            [
                11.4628662,
                50.70632819
            ],
            [
                11.4667671,
                50.70542739
            ],
            [
                11.4679012,
                50.70233214
            ],
            [
                11.4651345,
                50.70013766
            ],
            [
                11.4612337,
                50.70103837
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4573327,
            50.70193898
        ],
        "vertices": [
            [
                11.4534315,
                50.70283948
            ],
            [
                11.4561982,
                50.70503424
            ],
            [
                11.4600994,
                50.70413365
            ],
            [
                11.4612337,
                50.70103837
            ],
            [
                11.4584671,
                50.69884365
            ],
            [
                11.4545661,
                50.69974417
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4957031,
            50.70440755
        ],
        "vertices": [
            [
                11.4918031,
                50.70530921
            ],
            [
                11.49457,
                50.70750275
            ],
            [
                11.4984699,
                50.70660099
            ],
            [
                11.4996028,
                50.70350578
            ],
            [
                11.496836,
                50.7013123
            ],
            [
                11.4929362,
                50.70221397
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4890363,
            50.70311554
        ],
        "vertices": [
            [
                11.4851362,
                50.70401699
            ],
            [
                11.487903,
                50.70621076
            ],
            [
                11.4918031,
                50.70530921
            ],
            [
                11.4929362,
                50.70221397
            ],
            [
                11.4901695,
                50.70002025
            ],
            [
                11.4862695,
                50.70092172
            ]
        ]
    },
    {
        "value": 0.005141388,
        "centroid": [
            11.4522969,
            50.70593472
        ],
        "vertices": [
            [
                11.4483954,
                50.70683509
            ],
            [
                11.4511622,
                50.70902991
            ],
            [
                11.4550637,
                50.70812945
            ],
            [
                11.4561982,
                50.70503424
            ],
            [
                11.4534315,
                50.70283948
            ],
            [
                11.4495302,
                50.70373986
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.3836743,
            50.72741544
        ],
        "vertices": [
            [
                11.3797697,
                50.72831386
            ],
            [
                11.3825368,
                50.73051021
            ],
            [
                11.3864414,
                50.72961169
            ],
            [
                11.3875788,
                50.7265169
            ],
            [
                11.3848117,
                50.7243206
            ],
            [
                11.3809072,
                50.72521904
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4616781,
            50.97723618
        ],
        "vertices": [
            [
                11.4577735,
                50.97813734
            ],
            [
                11.4605428,
                50.8003295
            ],
            [
                11.4644474,
                50.97942824
            ],
            [
                11.4655826,
                50.9763349
            ],
            [
                11.4628134,
                50.9741428
            ],
            [
                11.458909,
                50.97504398
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.3752187,
            50.74069252
        ],
        "vertices": [
            [
                11.3713133,
                50.74159077
            ],
            [
                11.3740808,
                50.74378703
            ],
            [
                11.3779862,
                50.74288869
            ],
            [
                11.379124,
                50.73979416
            ],
            [
                11.3763566,
                50.73759795
            ],
            [
                11.3724513,
                50.73849621
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4972058,
            50.72955685
        ],
        "vertices": [
            [
                11.4933049,
                50.7304587
            ],
            [
                11.4960725,
                50.73265153
            ],
            [
                11.4999733,
                50.73174958
            ],
            [
                11.5011065,
                50.72865489
            ],
            [
                11.498339,
                50.7264621
            ],
            [
                11.4944383,
                50.72736397
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4305168,
            50.71662557
        ],
        "vertices": [
            [
                11.4266142,
                50.71752535
            ],
            [
                11.4293812,
                50.71972055
            ],
            [
                11.4332838,
                50.71882068
            ],
            [
                11.4344193,
                50.71572569
            ],
            [
                11.4316524,
                50.71353053
            ],
            [
                11.4277499,
                50.71443032
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4622301,
            50.71780777
        ],
        "vertices": [
            [
                11.4583285,
                50.7187085
            ],
            [
                11.4610956,
                50.7209027
            ],
            [
                11.4649972,
                50.72000188
            ],
            [
                11.4661315,
                50.71690692
            ],
            [
                11.4633645,
                50.71471277
            ],
            [
                11.459463,
                50.71561351
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4339232,
            50.70734027
        ],
        "vertices": [
            [
                11.430021,
                50.70824009
            ],
            [
                11.4327878,
                50.71043543
            ],
            [
                11.43669,
                50.70953552
            ],
            [
                11.4378252,
                50.70644033
            ],
            [
                11.4350585,
                50.70424504
            ],
            [
                11.4311565,
                50.70514488
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4256233,
            50.70075368
        ],
        "vertices": [
            [
                11.4217211,
                50.70165322
            ],
            [
                11.4244877,
                50.70384898
            ],
            [
                11.4283899,
                50.70294935
            ],
            [
                11.4295254,
                50.69985403
            ],
            [
                11.4267589,
                50.69765832
            ],
            [
                11.4228568,
                50.69855787
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3045596,
            50.97657174
        ],
        "vertices": [
            [
                11.3006497,
                50.97746817
            ],
            [
                11.3034185,
                50.97966513
            ],
            [
                11.3073283,
                50.9787686
            ],
            [
                11.3084694,
                50.9756752
            ],
            [
                11.3057007,
                50.97347829
            ],
            [
                11.301791,
                50.97437474
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4902724,
            50.76797923
        ],
        "vertices": [
            [
                11.4863699,
                50.76888109
            ],
            [
                11.4891385,
                50.77107313
            ],
            [
                11.493041,
                50.77017117
            ],
            [
                11.4941748,
                50.76707726
            ],
            [
                11.4914063,
                50.76488526
            ],
            [
                11.4875039,
                50.76578714
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4871367,
            50.73754967
        ],
        "vertices": [
            [
                11.4832352,
                50.73845126
            ],
            [
                11.4860029,
                50.74064419
            ],
            [
                11.4899044,
                50.7397425
            ],
            [
                11.491038,
                50.73664796
            ],
            [
                11.4882703,
                50.73445508
            ],
            [
                11.484369,
                50.73535669
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4188084,
            50.71932456
        ],
        "vertices": [
            [
                11.4149053,
                50.72022399
            ],
            [
                11.4176723,
                50.72241948
            ],
            [
                11.4215755,
                50.72151995
            ],
            [
                11.4227114,
                50.71842501
            ],
            [
                11.4199444,
                50.71622957
            ],
            [
                11.4160415,
                50.71712902
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4238472,
            50.71533
        ],
        "vertices": [
            [
                11.4199444,
                50.71622957
            ],
            [
                11.4227114,
                50.71842501
            ],
            [
                11.4266142,
                50.71752535
            ],
            [
                11.4277499,
                50.71443032
            ],
            [
                11.424983,
                50.71223493
            ],
            [
                11.4210804,
                50.71313451
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3288773,
            50.70744639
        ],
        "vertices": [
            [
                11.3249743,
                50.70834546
            ],
            [
                11.327741,
                50.71054156
            ],
            [
                11.411644,
                50.70964239
            ],
            [
                11.4127801,
                50.7065472
            ],
            [
                11.4100135,
                50.70435115
            ],
            [
                11.3261107,
                50.70525024
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.440592,
            50.70863549
        ],
        "vertices": [
            [
                11.43669,
                50.70953552
            ],
            [
                11.4394568,
                50.71173062
            ],
            [
                11.4433588,
                50.71083049
            ],
            [
                11.4444938,
                50.70773534
            ],
            [
                11.441727,
                50.70554029
            ],
            [
                11.4378252,
                50.70644033
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4923036,
            50.71369295
        ],
        "vertices": [
            [
                11.4884032,
                50.71459456
            ],
            [
                11.4911703,
                50.71678796
            ],
            [
                11.4950708,
                50.71588625
            ],
            [
                11.4962039,
                50.71279123
            ],
            [
                11.4934369,
                50.71059788
            ],
            [
                11.4895366,
                50.71149951
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4389604,
            50.70334509
        ],
        "vertices": [
            [
                11.4350585,
                50.70424504
            ],
            [
                11.4378252,
                50.70644033
            ],
            [
                11.441727,
                50.70554029
            ],
            [
                11.442862,
                50.70244503
            ],
            [
                11.4400954,
                50.70024978
            ],
            [
                11.4361938,
                50.70114975
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.497337,
            50.70969614
        ],
        "vertices": [
            [
                11.4934369,
                50.71059788
            ],
            [
                11.4962039,
                50.71279123
            ],
            [
                11.500104,
                50.71188939
            ],
            [
                11.5012369,
                50.70879429
            ],
            [
                11.4984699,
                50.70660099
            ],
            [
                11.49457,
                50.70750275
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4906699,
            50.70840439
        ],
        "vertices": [
            [
                11.4867696,
                50.70930592
            ],
            [
                11.4895366,
                50.71149951
            ],
            [
                11.4934369,
                50.71059788
            ],
            [
                11.49457,
                50.70750275
            ],
            [
                11.4918031,
                50.70530921
            ],
            [
                11.487903,
                50.70621076
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4506649,
            50.70064457
        ],
        "vertices": [
            [
                11.4467636,
                50.70154485
            ],
            [
                11.4495302,
                50.70373986
            ],
            [
                11.4534315,
                50.70283948
            ],
            [
                11.4545661,
                50.69974417
            ],
            [
                11.4517995,
                50.69754921
            ],
            [
                11.4478983,
                50.69844951
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.3063552,
            50.96200038
        ],
        "vertices": [
            [
                11.3024459,
                50.96289678
            ],
            [
                11.3052143,
                50.96509406
            ],
            [
                11.3091235,
                50.96419757
            ],
            [
                11.3102643,
                50.96110386
            ],
            [
                11.3074961,
                50.77890663
            ],
            [
                11.3035869,
                50.77980305
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4889035,
            50.72297778
        ],
        "vertices": [
            [
                11.4850027,
                50.72387935
            ],
            [
                11.48777,
                50.7260726
            ],
            [
                11.4916709,
                50.72517095
            ],
            [
                11.4928043,
                50.72207611
            ],
            [
                11.490037,
                50.7198829
            ],
            [
                11.4861363,
                50.72078448
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3222076,
            50.70614921
        ],
        "vertices": [
            [
                11.3183044,
                50.70704808
            ],
            [
                11.3210711,
                50.70924442
            ],
            [
                11.3249743,
                50.70834546
            ],
            [
                11.3261107,
                50.70525024
            ],
            [
                11.3233441,
                50.70305395
            ],
            [
                11.3194411,
                50.70395283
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3205776,
            50.70085752
        ],
        "vertices": [
            [
                11.3166746,
                50.7017563
            ],
            [
                11.3194411,
                50.70395283
            ],
            [
                11.3233441,
                50.70305395
            ],
            [
                11.3244805,
                50.69995862
            ],
            [
                11.3217141,
                50.69776214
            ],
            [
                11.3178112,
                50.69866094
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4456287,
            50.70464013
        ],
        "vertices": [
            [
                11.441727,
                50.70554029
            ],
            [
                11.4444938,
                50.70773534
            ],
            [
                11.4483954,
                50.70683509
            ],
            [
                11.4495302,
                50.70373986
            ],
            [
                11.4467636,
                50.70154485
            ],
            [
                11.442862,
                50.70244503
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.496812,
            50.96912065
        ],
        "vertices": [
            [
                11.4929089,
                50.97002283
            ],
            [
                11.495678,
                50.97221412
            ],
            [
                11.4995811,
                50.97131185
            ],
            [
                11.5007149,
                50.96821837
            ],
            [
                11.4979459,
                50.96602713
            ],
            [
                11.4940429,
                50.96692932
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4204395,
            50.72461483
        ],
        "vertices": [
            [
                11.4165362,
                50.72551435
            ],
            [
                11.4193034,
                50.72770965
            ],
            [
                11.4232066,
                50.72681004
            ],
            [
                11.4243426,
                50.7237152
            ],
            [
                11.4215755,
                50.72151995
            ],
            [
                11.4176723,
                50.72241948
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4205853,
            50.7047485
        ],
        "vertices": [
            [
                11.4166828,
                50.70564791
            ],
            [
                11.4194495,
                50.70784372
            ],
            [
                11.423352,
                50.70694422
            ],
            [
                11.4244877,
                50.70384898
            ],
            [
                11.4217211,
                50.70165322
            ],
            [
                11.4178188,
                50.70255264
            ]
        ]
    },
    {
        "value": 0.003856041,
        "centroid": [
            11.3079827,
            50.9672912
        ],
        "vertices": [
            [
                11.3040732,
                50.96818768
            ],
            [
                11.3068417,
                50.97038478
            ],
            [
                11.3107511,
                50.96948821
            ],
            [
                11.3118919,
                50.96639461
            ],
            [
                11.3091235,
                50.96419757
            ],
            [
                11.3052143,
                50.96509406
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.3013048,
            50.96599045
        ],
        "vertices": [
            [
                11.2973952,
                50.96688672
            ],
            [
                11.3001636,
                50.96908405
            ],
            [
                11.3040732,
                50.96818768
            ],
            [
                11.3052143,
                50.96509406
            ],
            [
                11.3024459,
                50.96289678
            ],
            [
                11.2985365,
                50.96379307
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.4955716,
            50.72426918
        ],
        "vertices": [
            [
                11.4916709,
                50.72517095
            ],
            [
                11.4944383,
                50.72736397
            ],
            [
                11.498339,
                50.7264621
            ],
            [
                11.4994721,
                50.7233673
            ],
            [
                11.4967048,
                50.72117432
            ],
            [
                11.4928043,
                50.72207611
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.381738,
            50.76185326
        ],
        "vertices": [
            [
                11.3778321,
                50.76275182
            ],
            [
                11.3806001,
                50.76494733
            ],
            [
                11.3845061,
                50.76404868
            ],
            [
                11.3856439,
                50.76095459
            ],
            [
                11.3828759,
                50.75875913
            ],
            [
                11.3789701,
                50.7596577
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3870863,
            50.71813073
        ],
        "vertices": [
            [
                11.3831821,
                50.71902921
            ],
            [
                11.385949,
                50.7212257
            ],
            [
                11.3898532,
                50.72032713
            ],
            [
                11.3109903,
                50.71723214
            ],
            [
                11.3882234,
                50.71503571
            ],
            [
                11.3843194,
                50.7159342
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4171775,
            50.71403398
        ],
        "vertices": [
            [
                11.4132746,
                50.71493334
            ],
            [
                11.4160415,
                50.71712902
            ],
            [
                11.4199444,
                50.71622957
            ],
            [
                11.4210804,
                50.71313451
            ],
            [
                11.4183136,
                50.71093889
            ],
            [
                11.4144107,
                50.71183826
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4939375,
            50.71898121
        ],
        "vertices": [
            [
                11.490037,
                50.7198829
            ],
            [
                11.4928043,
                50.72207611
            ],
            [
                11.4967048,
                50.72117432
            ],
            [
                11.4978379,
                50.71807941
            ],
            [
                11.4950708,
                50.71588625
            ],
            [
                11.4911703,
                50.71678796
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.388868,
            50.70355353
        ],
        "vertices": [
            [
                11.3849644,
                50.70445198
            ],
            [
                11.3877309,
                50.70664879
            ],
            [
                11.3116345,
                50.70575025
            ],
            [
                11.3127714,
                50.70265497
            ],
            [
                11.3100049,
                50.70045821
            ],
            [
                11.3861015,
                50.70135667
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3770026,
            50.72611737
        ],
        "vertices": [
            [
                11.3730978,
                50.72701559
            ],
            [
                11.3758649,
                50.72921217
            ],
            [
                11.3797697,
                50.72831386
            ],
            [
                11.3809072,
                50.72521904
            ],
            [
                11.3781402,
                50.7230225
            ],
            [
                11.3742356,
                50.72392074
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3832151,
            50.96700175
        ],
        "vertices": [
            [
                11.3793083,
                50.9679005
            ],
            [
                11.382077,
                50.97009531
            ],
            [
                11.3859838,
                50.96919647
            ],
            [
                11.3871218,
                50.9661029
            ],
            [
                11.3843532,
                50.96390813
            ],
            [
                11.3804465,
                50.96480689
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4851022,
            50.97182684
        ],
        "vertices": [
            [
                11.4811986,
                50.97272868
            ],
            [
                11.4839678,
                50.97492025
            ],
            [
                11.4878714,
                50.97401832
            ],
            [
                11.4890056,
                50.97092489
            ],
            [
                11.4862365,
                50.96873336
            ],
            [
                11.4823331,
                50.96963522
            ]
        ]
    },
    {
        "value": 0.002570694,
        "centroid": [
            11.3854567,
            50.71283913
        ],
        "vertices": [
            [
                11.3815527,
                50.71373752
            ],
            [
                11.3843194,
                50.7159342
            ],
            [
                11.3882234,
                50.71503571
            ],
            [
                11.3893605,
                50.71194062
            ],
            [
                11.3865939,
                50.70974399
            ],
            [
                11.38269,
                50.7106424
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3703308,
            50.72481886
        ],
        "vertices": [
            [
                11.3664258,
                50.72571687
            ],
            [
                11.3691928,
                50.72791369
            ],
            [
                11.3730978,
                50.72701559
            ],
            [
                11.3742356,
                50.72392074
            ],
            [
                11.3714686,
                50.72172396
            ],
            [
                11.3675638,
                50.72262199
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.4789686,
            50.71110865
        ],
        "vertices": [
            [
                11.4750678,
                50.71200985
            ],
            [
                11.4778348,
                50.71420372
            ],
            [
                11.4817356,
                50.71330243
            ],
            [
                11.4828692,
                50.71020734
            ],
            [
                11.4801022,
                50.70801352
            ],
            [
                11.4762016,
                50.70891474
            ]
        ]
    },
    {
        "value": 0.001285347,
        "centroid": [
            11.3864766,
            50.97757791
        ],
        "vertices": [
            [
                11.3825694,
                50.97847681
            ],
            [
                11.3853385,
                50.80067125
            ],
            [
                11.3892456,
                50.97977225
            ],
            [
                11.3103836,
                50.97667889
            ],
            [
                11.3876147,
                50.97448451
            ],
            [
                11.3837076,
                50.97538343
            ]
        ]
    }
];

export const people = [
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.325635612010956,
                    50.99113875619027
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.325836777687073,
                    50.991089795720065
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.32603794336319,
                    50.99102901713348
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.326569020748138,
                    50.991054341554225
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.326255202293396,
                    50.991045900082185
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.326813101768494,
                    50.99106109473077
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.32700890302658,
                    50.99109486059869
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.326995491981506,
                    50.991008757586954
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.325831413269043,
                    50.991045900082185
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.326729953289032,
                    50.9910222639523
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.325611472129822,
                    50.99105940643675
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.326480507850645,
                    50.991025640543015
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.325552463531494,
                    50.991148885936276
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.325587332248688,
                    50.99125693642279
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.325571238994598,
                    50.99134303897411
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.327212750911713,
                    50.991027328838285
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.32721811532974,
                    50.99111343181557
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.32739782333374,
                    50.99110330206182
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.311824917793274,
                    50.98822129733115
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.311733722686768,
                    50.987915696412124
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.311752498149872,
                    50.98801193559261
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.311773955821991,
                    50.9881301237869
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.313380599021912,
                    50.98079513199594
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.313471794128418,
                    50.980808641333326
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.313238441944122,
                    50.98080526399936
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.313702464103699,
                    50.980796820663336
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.313576400279999,
                    50.98080695266637
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.313887536525726,
                    50.98079006599341
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.314018964767456,
                    50.980808641333326
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Point",
                "coordinates": [
                    11.31327599287033,
                    50.98073940593763
                ]
            }
        }
    ];

export const weimarAtrium = {
    q: 0,
    self_q: 420,
    height: 20,
    rent_price: 10,
    geom: {
        "type": "Polygon",
        "coordinates": [
            [
                [
                    [
                        11.329766213893889,
                        50.98562109264818
                    ],
                    [
                        11.328164935111998,
                        50.98562446963183
                    ],
                    [
                        11.32813811302185,
                        50.985617715664276
                    ],
                    [
                        11.32813274860382,
                        50.985014920102145
                    ],
                    [
                        11.328425109386444,
                        50.98500816604591
                    ],
                    [
                        11.328430473804474,
                        50.985028428211685
                    ],
                    [
                        11.329763531684875,
                        50.98503011672511
                    ],
                    [
                        11.329766213893889,
                        50.98562109264818
                    ]
                ]
            ]
        ]
    }
};

export const buildingProto = {

    data: {
        q: 0,
        self_q: 12,
        height: 20,
        rent_price: 0,
        geom: {
            coordinates: []
        }
    },

    coordinates: [
        [0.00082054,0.00029680,],
        [-0.00078074,0.00030018,],
        [-0.00080756,0.00029343,],
        [-0.00081292,-0.00030937,],
        [-0.00052056,-0.00031612,],
        [-0.00051520,-0.00029586,],
        [0.00081786,-0.00029417,],
        [0.00082054,0.00029680],
    ],

    dx: 0.00001,
    dy: 0.00001
}

export const buildingProto2 = {

    data: {
        q: 0,
        self_q: 22,
        height: 20,
        rent_price: 8,
        geom: {
            coordinates: []
        }
    },

    dx: 0.00001,
    dy: 0.00001,

    coordinates: [
        [-0.00008474, -0.00005723],
        [-0.00004987, -0.00005638],
        [-0.00004987, -0.00004414],
        [-0.00000092, -0.00004456],
        [0.00000177, -0.00005765],
        [0.00007955, -0.00005891],
        [0.00009095, 0.00005000],
        [-0.00007803, 0.00005506],
        [-0.00008809, 0.00005084],
        [-0.00008474, -0.00005723],
    ]
}

export const landCover = [[[11.32, 50.95], [11.35, 50.95], [11.35, 50.98], [11.32, 50.98]]];

