import {WeightedIndex, WeightedIndexCalc, WeightedIndexForm, WeightedIndexTask, WeightedIndexCalcOne} from './alg/weightedindex'
import {GravityIndex, GravityIndexCalc, GravityIndexForm, GravityIndexTask, GravityIndexCalcOne} from './alg/gravityindex'
import {
    WeightedGravityIndexCalcOne, WeightedGravityIndexTask, WeightedGravityIndexForm, WeightedGravityIndexCalc, WeightedGravityIndex
} from './alg/weightgravity'
import {
    SpreadMetricCalcOne, SpreadMetricTask, SpreadMetricForm, SpreadMetric, SpreadMetricCalc
} from './alg/spreadmetric'

const CreateAlgo = function ({id, key, title, component, calculate, calcOne, form, task}) {
    return {
        id,
        key,
        title,
        component,
        calculate,
        calcOne,
        form,
        task
    }
}


export const algorithms = {
    // 'a9752d98-7331-fc75-f18f-9fe6812d0dfb': CreateAlgo({
    //     id: 'a9752d98-7331-fc75-f18f-9fe6812d0dfb',
    //     key: 'weightedindex',
    //     title: 'Weighted Index',
    //     component: WeightedIndex,
    //     calculate: WeightedIndexCalc,
    //     calcOne: WeightedIndexCalcOne,
    //     form: WeightedIndexForm,
    //     task: WeightedIndexTask,
    // }),
    // '0ef6a421-40c5-f763-f39f-501d585abebf': CreateAlgo({
    //     id: '0ef6a421-40c5-f763-f39f-501d585abebf',
    //     key: 'gravityindex',
    //     title: 'Gravity Index',
    //     calcOne: GravityIndexCalcOne,
    //     component: GravityIndex,
    //     calculate: GravityIndexCalc,
    //     form: GravityIndexForm,
    //     task: GravityIndexTask,
    // }),

/*    '4c941970-5ddc-36a2-1c0d-a8883cac4614': CreateAlgo({
        id: '4c941970-5ddc-36a2-1c0d-a8883cac4614',
        key: 'spreadmetric',
        title: 'Spread Metric',
        calcOne: SpreadMetricCalcOne,
        component: SpreadMetric,
        calculate: SpreadMetricCalc,
        form: SpreadMetricForm,
        task: SpreadMetricTask,
    }),*/

    'de1acfd0-a156-974b-d3c1-214ea0faf5a3': CreateAlgo({
        id: 'de1acfd0-a156-974b-d3c1-214ea0faf5a3',
        key: 'weightgravity',
        title: 'Weighted Gravity Index',
        calcOne: WeightedGravityIndexCalcOne,
        component: WeightedGravityIndex,
        calculate: WeightedGravityIndexCalc,
        form: WeightedGravityIndexForm,
        task: WeightedGravityIndexTask,
    })
}

const DEFAULT_ALGORITHM = Object.values(algorithms)[0].key;

export function getAlgorithms() {
    return Object.values(algorithms)
}

export function getAlgorithm(key) {
    return Object.values(algorithms).find(item => item.key === key)
}

export function getDebugAlgorithm() {
    return DEFAULT_ALGORITHM
}
