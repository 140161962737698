import { gql } from "@apollo/client";

export const GetObjectTypes = gql`
    query GetObjectTypes ($project_id:uuid) {
        objectTypes(order_by: {ordering: asc}, where: {parent_id: {_is_null: true}}) {
            id
            key
            meta {
                title
                lang 
                about
            }
            children(
                order_by: {ordering: asc},
                where: {
                    _or: [
                        {is_private: {_eq: false}},
                        {projects_link: {project_id: {_eq: $project_id}}}
                    ]
                }
            ) {
                id
                key
                colorScheme
                geometryType
                meta {
                    title
                    lang
                    about
                }
                layerTypes(order_by: {ordering: asc}) {
                    key
                    icon
                    is_default
                    settings
                    meta {
                        title
                    }
                }
                metrics_link(
#                    distinct_on: [metric_id, dataset_id],
                    order_by: {metric: {meta: {title: asc}}, dataset: {meta: {title: asc}}}, 
                    where: {
                        _and: [
                            {_or: [
                                {metric: {is_private: {_eq: false}}},
                                {metric: {dataset_links: {dataset: {project_link: {project_id: {_eq: $project_id}}}}}}
                            ]},
                            {_or: [
                                {dataset: {is_private: {_eq: false}}},
                                {dataset: {project_link: {project_id: {_eq: $project_id}}}}
                            ]},
                        ]
                    }
                ) {
                    dataset {
                        id
                        key
                        meta {
                            title
                            about
                            copyright
                        }
                    }
                    metric {
                        id
                        key
                        meta {
                            title
                            about
                            copyright
                        }
                        lineage {
                            algorithm
                            cr
                            filters
                            operands
                            params
                            ver
                        }
                    }
                }
            }
        }
    }
`;



export const GetDemoObjectTypes = gql`
    query GetDemoObjectTypes ($project_id:uuid) {
        objectTypes(order_by: {ordering: asc}, where: {parent_id: {_is_null: true}, _and: {is_demo: {_eq: true}}}) {
            id
            key
            meta {
                title
                lang
                about
            }
            children(order_by: {ordering: asc}, where: {
                _or: [
                    {is_private: {_eq: false}}, 
                    {projects_link: {project_id: {_eq: $project_id}}}
                ], 
                is_demo: {_eq: true}
            }) {
                id
                key
                colorScheme
                geometryType
                meta {
                    title
                    lang
                    about
                }
                layerTypes(order_by: {ordering: asc}) {
                    key
                    icon
                    is_default
                    settings
                    meta {
                        title
                    }
                }
                metrics_link(
                    #                    distinct_on: [metric_id, dataset_id],
                    order_by: {metric: {meta: {title: asc}}, dataset: {meta: {title: asc}}},
                    where: {
                        _and: [
                            {_or: [
                                {metric: {is_private: {_eq: false}}},
                                {metric: {dataset_links: {dataset: {project_link: {project_id: {_eq: $project_id}}}}}}
                            ]},
                            {_or: [
                                {dataset: {is_private: {_eq: false}}},
                                {dataset: {project_link: {project_id: {_eq: $project_id}}}}
                            ]},
                        ]
                    }
                ) {
                    dataset {
                        id
                        key
                        meta {
                            title
                            about
                            copyright
                        }
                    }
                    metric {
                        id
                        key
                        meta {
                            title
                            about
                            copyright
                        }
                        lineage {
                            algorithm
                            cr
                            filters
                            operands
                            params
                            ver
                        }
                    }
                }
            }
        }
    }
`;


export const GetMe = gql`
    query GetMe {
        me {
            cr
            key
            id
            meta
            role
            roles {
                role
            }
        }
    }
`;


export const GetProjectMe = gql`
    query GetProjectMe($project_id: uuid) {
        projectMe (where: {project_id: {_eq: $project_id}}) {
            cr
            key
            id
            meta
            role
            global {
                settings
            }
        }
    }
`;
