/**
 * Created by Stainwoortsel on 04.03.2017.
 */
import { appConfig } from '../config';
import axios from 'axios';
import Tokenizer from '../helpers/tokenizer';

const directAxios = axios.create({
    baseURL: appConfig.api.base,
    responseType: appConfig.api.type
});

const RETRY_TIMEOUT = 30000

export const authAxios = function(state) {
    const token = state.auth.access_token;

    return axios.create({
        baseURL: appConfig.api.base,
        responseType: appConfig.api.type,
        headers: {
            Authorization: Tokenizer.authHeader(token)
        }
    });
};

export const authAxios_timeout = function(state) {
    const token = state.auth.access_token;

    return axios.create({
        baseURL: appConfig.api.base,
        responseType: appConfig.api.type,
        timeout: RETRY_TIMEOUT,
        headers: {
            Authorization: Tokenizer.authHeader(token)
        }
    });
};


export const geocoderAxios = function (state) {
    return axios.create({
        baseURL: appConfig.mapbox.base,
        responseType: appConfig.api.type,
        headers: {

        }
    });
}

export default directAxios;
