import React, {useContext} from "react";
import {getDB} from '../../../db'
import {NATURAL_ORDERING} from "../../../actions/app/data";
import {MapStateContext} from "../../../pages/project/map/reducer";
import {DataStateContext} from "../../../reducers";
import { CubeModel } from '../../../models/cube'
import { MetricModel } from '../../../models/metric'
import {DEFAULT_LAYER_TYPE, LayerModel} from '../../../models/layer'
import {
    normalizeDate, isFieldScalar,
    isFieldTimestamp, isFieldKey,
    isFieldUUID, scalarTypeFloat
} from '../../../helpers/field'
import {ApiEndpoint} from "../../../config";
import {authAxios} from "../../../clients/directAxios";
import {getToken} from "../../../actions/auth";


const algApi = new ApiEndpoint('alg')
const parquetApi = new ApiEndpoint('parquet')

const apiClient = authAxios({
    auth: {
        access_token: getToken()
    }
});



export default async function WeightedIndexCalc({
    objectType: objectTypeMetric, title, algorithmParams,
    filters,  dataState,
    existMetric, metricLayer
}) {

    const {
        project,
        cube: dataCubes,
        metric: dataMetrics,
    } = dataState

    const {operands, gravityRadius} = algorithmParams

    const params = Object.values(operands).map((param) => ({
        metric_id: param.metric.id,
        field: param.field.name,
        weight: param.weight
    }))

    filters = Object.values(filters).map((filter) => ({
        metric_id: filter.cube_id,
        fields: filter.fields,
        limit: filter.limit,
        offset: filter.offset,
        ordering: filter.ordering
    }))

    const paramsMetricIds = params.map(p => p.metric_id);

    const nextMetricIndex = Object.keys(dataMetrics).length

    console.log('CALC>>')

    const requestParams = {
        project_id: project.id,
        algorithm: "weightedindex",
        object_type_id: objectTypeMetric.id,
        ver: existMetric ? existMetric.ver : 1,
        title,
        filter: [],
        params: {
            gravityRadius
        },
        operands: Object.values(operands).map(operand => ({
            object_type_id: operand.metric.object.id,
            metric_id: operand.metric.id,
            weight: operand.weight,
            tag: operand.tag,
            field: operand.field?.name,
            value: operand.is_dict ? operand.value.id : operand.value,
            is_dict: !!operand.is_dict
        })),
    }

    if (existMetric) {
        requestParams['metric_id'] = existMetric.id
    }

    const response = await apiClient.post(algApi.endpoint('/execute'), requestParams)

    console.log('end CALC>>')

    const projectObjectType = {
        id: project.id,
        key: project.id,
        meta: project.meta,
    };
    // const calculatedCubeData = response.data.data
    const cubeLineage = response.data.lineage

    const newMetric = existMetric
        ? {
            ...existMetric,
            ver: existMetric.ver + 1,
            lineage: cubeLineage
        } : MetricModel({
            id: response.data.metric_id,
            index: nextMetricIndex,
            title,
            lineage: cubeLineage
        }, objectTypeMetric, projectObjectType)

    const newCube = CubeModel({
        id: newMetric.id,
        src_id: 0,
        ver: newMetric.ver,
        struct: {
            fields: response.data.struct,
            timeseries: response.data.timeseries
        }
    })

    const layerType = objectTypeMetric.layerTypes.length
        ? objectTypeMetric.layerTypes.find(item => item.is_default)?.key || (objectTypeMetric.layerTypes[0])?.key
        : DEFAULT_LAYER_TYPE

    const newLayer = metricLayer ? metricLayer : LayerModel({
        title,
        layerType,
        metrics: [
            newMetric
        ],
        color: newMetric.color,
        object: objectTypeMetric,
        objectType: objectTypeMetric,
        colorScheme: objectTypeMetric.colorScheme
    })

    return {
        name: newCube.name,
        data: response.data.data,

        metric: newMetric,
        cube: newCube,
        layer: newLayer,
        // data: calculatedCubeData,
        source: parquetApi.endpoint(response.data.path),
        isDebug: false
    }

}