import React, {useContext, useEffect, useState} from "react";
import { useQuery } from "@apollo/client";

import {
    IconButton,
    Paper,
    Divider,
    SwipeableDrawer,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tabs, Tab, Box,
    WebMercatorViewport
} from "@material-ui/core";


import {BarChart, ChevronRightRounded, CloudDownload} from "@material-ui/icons";
import css from 'styled-components'

import dxfSerializer from '@jscad/dxf-serializer'
import { geometries } from '@jscad/modeling'
import { Proj4Projection } from '@math.gl/proj4';


import apolloClient from "../../../../../../clients/apolloClient";
import {GetProjectDataviews} from "../../../../../../actions/app/gql/dataview";
import { MapStateContext, DataStateContext } from "../../../reducer";

import { isManager } from '../../../../../../helpers/user.js'


import DataBarSummaryTable from './databar-summary'
import DataBarItemTable from './databar-item'
import CurrentViewItem from '../../currentview-item'
import {makeStyles} from "@material-ui/core/styles";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const DataBarHeader = css.div`
    min-width: 680px;
    width: auto;
    box-sizing: border-box;
    text-align: right;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 40px',
        overflow: 'hidden',
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
    },
    databarPanelHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    databarPanel: {
        width: '100vw'
        // '& .MuiDrawer-paper': {
        //     width: '100vw'
        // }
    },
}));


const DataBar = function (props) {
    const classes = useStyles();

    const { viewState } = props;
    const {mapState, mapDispatch} = useContext(MapStateContext)

    const { dataState, dataDispatch } = useContext(DataStateContext)

    const { me } = dataState

    const { mapLayers, layers, dataBar, dataviews, dataitem, viewBbox, viewState: { zoom } } = mapState
    const { item: dataItem, visible: panelIsOpened } = dataBar

    const { filter: metricFilters, cube: metricCubes } = dataState

    const hasItem = dataItem !== null;

    const isObjectAvailable = false
    // !!(
    //     Object.values(layers).find(layer => ['object', 'trail'].indexOf(layer.layerType) > -1)
    // )

    const isDatabarAvailable = false

    const [currentTab, setCurrentTab] = useState(hasItem ? 1 : 0);

    const isCurrentView = zoom > 14;

    const handlePanelOpened = (visible) => {
        mapDispatch({
            type: 'toggleDataBar',
            visible,
        })
    }

    const createPath2 = (points) => {
        const { path2 } = geometries
        return path2.create( points)
    }

    const createGeom3 = (points, height) => {
        const { geom3, path2 } = geometries
        const vertices = []
        const top = [], bottom = []

        for (let i = 0; i < points.length - 1; i++) {
            vertices.push({
                vertices: [
                    [...points[i], 0],
                    [...points[i+1], 0],
                    [...points[i+1], height],
                    [...points[i], height],
                ]
            })
            top.push([...points[i], height])
            bottom.push([...points[i], 0])
        }

        vertices.push({ vertices: top })
        vertices.push({ vertices: bottom })

        return geom3.create(vertices)
    }

    const createObjectLayer = (layer, projection, center) => {
        return layer.props.data.map(item => {
            const preparedPoly = item.polygon.map(coords => {
                const [x, y] = projection.project(coords)
                return [x - center[0], y - center[1]];
            })
            return createGeom3(preparedPoly, item.height)
        })
    }

    const createTrailLayer = (layer, projection, center) => {
        return layer.props.data.map(item => {
            return createPath2(item.geometry.coordinates.map(coords => {
                const [x, y] = projection.project(coords)
                return [x - center[0], y - center[1]];
            }))
        })
    }

    const createLayersGeometries = () => {
        const { viewState } = mapState;

        const projection = new Proj4Projection({
            from: 'EPSG:4269', // '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees +no_defs',
            to: 'EPSG:3857', // '+proj=utm +ellps=GRS80 +datum=nad83 +units=m +no_defs'
        })

        const center = projection.project([viewState.longitude, viewState.latitude])

        return  Object.values(layers).filter(layer => ['object', 'trail'].indexOf(layer.layerType) > -1)
            .reduce((features, layer) => {
                const mapLayer = mapLayers.find(ml => ml.id === layer.id)

                if (!mapLayer) return features;

                if (layer.layerType === 'object') {
                    return features.concat(createObjectLayer(mapLayer, projection, center))
                } else if (layer.layerType === 'trail') {
                    return features.concat(createTrailLayer(mapLayer, projection, center))
                }

                return features;
            }, [])
    }

    const handleDownloadData = () => {
        // const data = compileLayersGeoJSON()
        // const { geom3, geom2, path2 } = geometries
        // const geoms = geom3.create([
        //     {"vertices": [[-1,-1,-1], [-1,-1,1], [-1,1,1], [-1,1,-1]]},
        //     {"vertices": [[1,-1,-1], [1,1,-1], [1,1,1], [1,-1,1]]},
        //     {"vertices": [[-1,-1,-1], [1,-1,-1], [1,-1,1], [-1,-1,1]]},
        //     {"vertices": [[-1,1,-1], [-1,1,1], [1,1,1], [1,1,-1]]},
        //     {"vertices": [[-1,-1,-1], [-1,1,-1], [1,1,-1], [1,-1,-1]]},
        //     {"vertices": [[-1,-1,1], [1,-1,1], [1,1,1], [-1,1,1]]}
        // ])

        mapDispatch({
            type: 'setLoading',
            isLoading: true
        })

        try {
            const link = document.createElement('a');

            const geoms = createLayersGeometries()

            const data = dxfSerializer.serialize({}, geoms)
            const uriData = encodeURIComponent(data[0])

            link.href = `data:application/json;charset=UTF-8,${uriData}`;
            link.download="layers.dxf"

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (e) {
            console.log(e)
            // ...
        }

        mapDispatch({
            type: 'setLoading',
            isLoading: false
        })

    }

    useEffect(() => {
        if (dataItem) {
            setCurrentTab(1)
        }
    }, [mapState.dataBar.item])

    const { projectId } = mapState;

    const { data: projectDataviews, loading: projectDVLoading, projectDVError } = useQuery(GetProjectDataviews, {
        client: apolloClient,
        variables: {
            project_id: projectId
        },
    });

    useEffect(() => {
        if (projectDataviews) {
            mapDispatch({
                type: 'setDataviews',
                dataviews: projectDataviews.project_dataviews
            })
        }}, [projectDataviews])


    if (!isManager(me)) return null;

    return (
        <React.Fragment>
            <div style={{
                position: 'fixed',
                top: '50%',
                right: '10px',
                zIndex: 10,
            }}>
                {isDatabarAvailable &&
                    <IconButton
                        variant="contained"
                        onClick={() => handlePanelOpened(true)}
                        style={{background: '#DEE6F1', display: 'block'}}
                    >
                        <BarChart style={{color: '#222'}} />
                    </IconButton>
                }

                {isObjectAvailable &&
                <IconButton
                    variant="contained"
                    onClick={() => handleDownloadData(true)}
                    style={{background: '#DEE6F1', display: 'block', marginTop: 10}}
                >
                    <CloudDownload style={{color: '#222'}} />
                </IconButton>
                }
            </div>

            <SwipeableDrawer
                anchor='right'
                open={panelIsOpened}
                className={classes.databarPanel}
                onOpen={() => {}}
                onClose={() => {}}
            >
                <div className={classes.databarPanelHeader}>
                    <IconButton onClick={() => handlePanelOpened(false)}>
                        <ChevronRightRounded />
                    </IconButton>
                </div>

                <Tabs
                    value={currentTab}
                    onChange={(e, tab) => {
                        setCurrentTab(tab)
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {isCurrentView &&
                        <Tab label="Current view" />
                    }

                    {dataviews.map(({dataview}) => {
                        return <Tab label={dataview.meta.title} />
                    })}

                </Tabs>

                {isCurrentView &&
                    <TabPanel value={currentTab} index={0}>
                        <CurrentViewItem
                            title='Current View'
                            bbox={viewBbox}
                            zoom={zoom}
                        />
                    </TabPanel>
                }

                {
                    dataviews.map(({dataview}, di) => {
                        return (
                            <TabPanel value={currentTab} index={di + 1}>
                                <DataBarItemTable
                                    title={dataview.meta.title}
                                    dataview={ dataview }
                                    bbox={viewBbox}
                                />
                            </TabPanel>
                        )
                    })
                }

{/*
                {
                    dataviews._map(({dataview}, di) => {
                        return (
                            <TabPanel value={currentTab} index={0}>
                                <DataBarSummaryTable
                                    title='Summary'
                                    data={mapState.dataBar.summary}
                                />
                            </TabPanel>
                        )
                    })
                }
*/}

            </SwipeableDrawer>

        </React.Fragment>
    )
}

export default DataBar;
