import React, {useContext, useEffect, useState, useMemo} from "react";

import Md5 from 'md5'

import Map, {AttributionControl} from "react-map-gl";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import DeckGL from '@deck.gl/react';

import {MapView} from '@deck.gl/core';

import {
    Select,
    MenuItem,
    FormControl
} from '@material-ui/core';
import {Restore} from '@material-ui/icons';

import {makeStyles} from '@material-ui/core/styles';

import {
    mapLayerFabric,
    layerFabric,
    createUserActivityLayer,
    createEditableLayer
} from './layer-fabric'
import BuildingCardModal from './widgets/building-card-modal'
import {MapStateContext, MapStyleContext, DataStateContext, CacheStateContext} from '../../reducer'
import {appConfig} from "../../../../../config";
import dataFabric from '../../../../../data/layer-fabric';
import {createObject} from '../../../../../models/object'
import {getFormatedValue, getValueRepr} from '../../../../../helpers/repr'
import {geomToVector4, vector4toArray} from '../../../../../helpers/geom'
import {isDefined} from '../../../../../helpers/fn'
import {addObjectAction, updateObjectAction, deleteObjectAction} from '../../../../../actions/app/data'
import {prepareLayerCollection} from "../../../../../data/layer";
import {
    isUserActivityLayer,
    isActivityModelLayer,
    userEditLayerId,
    createActorLayerId,
    userActivityLayerId, isLayerDefined
} from "../../../../../models/layer";
import createGLBLayer from './layers/glb-layer'
import {useSnackbar} from "notistack";

import createActorLayer from './layers/actor-layer'

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    mapContainer: {
        overflow: 'hidden',
        width: '100%',
        height: '100vh',
        position: 'relative'
    },
    stepper: {
        background: '#F5FAFF',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    widgetContainer: {
        margin: '64px 0',
    },
    finishContainer: {
        padding: theme.spacing(3),
        background: '#F5FAFF',
    },
    finishPhrase: {
        marginBottom: theme.spacing(2),
    },
    tooltipPanel: {
        position: 'absolute',
        zIndex: 10,
        pointerEvents: 'none',
        padding: theme.spacing(1),
        background: '#35424E',
        color: '#F5FAFF',
        opacity: 0,
        boxSizing: 'border-box',
    },
    tooltipPanelTitle: {
        margin: 0,
        padding: 0,
    },
    tooltipPanelText: {
        margin: 0,
        padding: '0.5rem 0 0 0'
    },

    copyright: {
        position: 'fixed',
        bottom: 0,
        right: 270,
        maxWidth: '50vw',
        [theme.breakpoints.down(640)]: {
            right: 40,
            bottom: 12,
            maxWidth: '50vw',
        },
        whiteSpaces: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '20px',
        lineHeight: '20px',
        boxSizing: 'border-box',
        padding: '0 5px',
        fontSize: '0.8rem',
        color: '#000',
        background: 'rgba(255,255,255,0.5)'
    },

    heightForm: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    heightFormControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    heightFormControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

const material = {
    ambient: 0.5,
    diffuse: 0.5,
    shininess: 400,
    specularColor: [255, 255, 255]
};

const userMaterial = {
    ambient: 0.8,
    diffuse: 0.8,
    shininess: 15,
    specularColor: [73, 110, 255]
};

const DEFAULT_VIEWS = [
    new MapView({id: 'map', width: '100%', controller: true}),
    // new FirstPersonView({width: '50%', x: '50%', fovy: 50})
];

const DEFAULT_THEME = {
    material,
    userMaterial,
    effects: [] // [lightingEffect]
};


const MapComponent = (props) => {
    const classes = useStyles();
    const theme = DEFAULT_THEME;

    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    // const {renderingData,} = props

    const {dataState, dataDispatch} = useContext(DataStateContext)
    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {mapStyle} = useContext(MapStyleContext)
    const {cacheState, cacheDispatch} = useContext(CacheStateContext)

    const {
        viewState,
        initialViewState,
        layers,
        mapLayersUpdate,
        projectId,
        editModeComponent,
        userEditLayer,
    } = mapState;
    const {filter: dataFilters, cube: metricCubes, metric: theMetrics, me, activityObjects} = dataState

    const isEditMode = !!editModeComponent
    const [tooltip, setTooltip] = useState({
        id: null,
        visible: false,
        x: 0,
        y: 0,
        title: '',
        values: [],
    })
    const [activeItem, setActiveItem] = useState(null)
    const [defaultCursor, setDefaultCursor] = useState('grab')

    const hideTooltip = () => setTooltip((oldState) => ({...oldState, visible: false}))

    const layerOnHover = (props) => async (item, event) => {
        const {layer, cube, heightCube} = props

        let object = null;
        try {
            const collection = await prepareLayerCollection(props)
            object = await collection.addCondition({
                fieldName: 'id',
                condition: '=',
                value: item.identity
            }).getItem()
        } catch (e) {
            console.error(e)
        }

        if (!object) {
            hideTooltip()
            return
        }

        const dictValues = cube?.dicts.find(d => d.field === layer.colorMetricField)?.values
        const isDict = !!dictValues
        const isScalar = !isDict
        const dictMap = isDict && dictValues ? (dictValues.reduce((acc, value, i) => ({
            ...acc,
            [value.id]: value.title
        }), {})) : undefined

        const objectTitle = object?.meta?.title ? object?.meta?.title : layer.title

        const colorMetric = layer?.metrics?.find(metricItem => metricItem.id === layer.colorMetricId)
        const colorField = cube?.struct?.fields.find(fieldItem => fieldItem.name === layer.colorMetricField)
        const valueTitle = colorField ? colorField.title : colorMetric?.meta?.title
        let colorMetricValue = object?.metricValue

        if (isDict && dictMap) {
            if (Array.isArray(colorMetricValue)) colorMetricValue = colorMetricValue.map(item => dictMap[item]).join(', ')
            else colorMetricValue = dictMap[item]
        }

        if (isScalar) {
            colorMetricValue = Math.round(colorMetricValue) === Math.floor(colorMetricValue)
                ? Math.round(colorMetricValue)
                : getFormatedValue(colorMetricValue, 4)
        }

        const tooltipValues = [
            // `idd: ${item.identity}`,
            // `index: ${item.index}`,
        ]

        if (colorMetric) {
            tooltipValues.push(`${valueTitle}: ${colorMetricValue}`)
        }

        const isHeightMetric = layer.height?.metricId && layer.height?.field
        const heightField = heightCube?.struct.fields.find(item => item.name === layer.height?.field)
        const hasHeightMetric = isHeightMetric && heightField


        if (hasHeightMetric) {
            tooltipValues.push(`${heightField.title}: ${getFormatedValue(object?.heightMetricValue)}`)
        }

        setTooltip({
            visible: true,
            id: object.id,
            x: item.x,
            y: item.y,
            title: objectTitle,
            values: tooltipValues
        })
    };

    const layerOnClick = (props) => async (item) => {
        const {layer} = props

        let object = null;
        try {
            const collection = await prepareLayerCollection(props)
            object = await collection.addCondition({
                fieldName: 'id',
                condition: '=',
                value: item.identity
            }).getItem()
        } catch (e) {
            console.error(e)
        }

        if (!object) return
        if (isEditMode) return

        setCurrentModalCard({
            type: layer.objectType.key,
            object: object,
            layer
        })
    };

    const [localMapLayers, setLocalMapLayers] = useState({})
    const [editMapLayer, setEditMapLayer] = useState(null)
    const [actorMapLayer, setActorMapLayer] = useState(null)

    const [currentModalCard, setCurrentModalCard] = useState(null)

    const handlerEditDone = (props) => {
        const {updatedData, selectedFeatureIndexes} = props

        const layer = Object
            .values(layers)
            .find(item => item.objectType.key === editModeComponent)

        if (!layer) return

        const objectTypeId = layer.objectType.id
        const tableName = editModeComponent
        const newObject = createObject({
            geom: geomToVector4(updatedData.features[0].geometry, updatedData.features[0].geometry.type),
            geom_type: updatedData.features[0].geometry.type,
        }, me?.id)

        addObjectAction({
            projectId,
            objectTypeId,
            tableName,
            newObject,
        }).then(() => {
            setCurrentModalCard({
                type: layer.objectType.key,
                object: newObject,
                layer
            })
            mapDispatch({
                type: 'addNewObject',
                object: newObject,
                layer
            })
        }).then(() => {
            mapDispatch({
                type: 'cancelEditMode'
            })
        })
    }

    const handlerUpdateObject = (props) => {
        const {context, object, metrics} = props

        const {layer} = context
        const objectType = layer.objectType
        const tableName = context.type

        const updatingLayersIds = Object
            .values(mapState.layers)
            .filter(lr => lr.objectType?.key === layer.objectType?.key)
            .map(lr => lr.id)

        let metricValue = object.metricValue
        let heightMetricValue = object.heightMetricValue

        if (layer.colorMetricField && layer.colorMetricId) {
            try {
                metricValue = Array.isArray(metrics[layer.colorMetricId][layer.colorMetricField])
                    ? metrics[layer.colorMetricId][layer.colorMetricField]
                    : [metrics[layer.colorMetricId][layer.colorMetricField]]
            } catch (e) {
                // ...
            }
        }

        if (layer.height?.metricId && layer.height?.field) {
            try {
                heightMetricValue = metrics[layer.height.metricId][layer.height.field]
            } catch (e) {
                // ...
            }
        }

        mapDispatch({type: 'setCalculating', isCalculating: true})
        updateObjectAction({
            projectId,
            objectType,
            tableName,
            cubes: metricCubes,
            object: {
                ...object,
                metricValue,
                heightMetricValue,
                geom: vector4toArray(object.geom)
            },
            metrics,
        }).then(() => {
            mapDispatch({
                type: 'updateUserLayer',
                updatedLayers: updatingLayersIds.concat([userActivityLayerId])
            })
            mapDispatch({type: 'setCalculating', isCalculating: false})
        }).catch((e) => {
            mapDispatch({type: 'setCalculating', isCalculating: false})
            console.log('UP_e', e)
        })
    }

    const handlerDeleteObject = ({context, object}) => {

        const {layer} = context
        const objectType = layer.objectType
        const tableName = context.type

        const updatingLayersIds = Object
            .values(mapState.layers)
            .filter(lr => lr.objectType?.key === layer.objectType?.key)
            .map(lr => lr.id)

        mapDispatch({type: 'setCalculating', isCalculating: true})
        deleteObjectAction({
            projectId,
            objectType,
            tableName,
            object,
        }).then(() => {
            mapDispatch({
                type: 'updateUserLayer',
                updatedLayers: updatingLayersIds.concat([userActivityLayerId])
            })
            mapDispatch({type: 'setCalculating', isCalculating: false})
        }).catch((e) => {
            mapDispatch({type: 'setCalculating', isCalculating: false})
            console.log('DEL_e', e)
        })

    }


    useEffect(() => {

        if (editModeComponent) {
            setEditMapLayer(createEditableLayer({
                editModeComponent,
                onEditDone: handlerEditDone
            }))
        } else {
            setEditMapLayer(null)
        }


    }, [editModeComponent])

    useEffect(() => {
        if (!mapLayersUpdate.needRemove) return

// console.log('needRemove', mapLayersUpdate)

        const layersToRemoveIds = Array.isArray(mapLayersUpdate.removedLayers)
            ? mapLayersUpdate.removedLayers
            : [mapLayersUpdate.removedLayers]

        setLocalMapLayers(existLayers => Object.fromEntries(
            Object.entries(existLayers)
                .filter(([layerId, layer]) => layersToRemoveIds.indexOf(layerId) === -1)
            )
        )
        mapDispatch({type: 'resetLayerUpdate'})
    }, [mapLayersUpdate.needRemove])

    useEffect(() => {
        if (!mapLayersUpdate.needReoption) return

// console.log('needReoption', mapLayersUpdate)

        const updateDeckLayersIds = Array.isArray(mapLayersUpdate.updatedLayers)
            ? mapLayersUpdate.updatedLayers
            : [mapLayersUpdate.updatedLayers]

        setLocalMapLayers(existLayers => {
            const updatedDeckLayers = updateDeckLayersIds
                .filter(layerId => !!existLayers[layerId])
                .reduce((updatedDeckLayers, layerId) => ({
                ...updatedDeckLayers,
                [layerId]: existLayers[layerId].clone(mapLayersUpdate.option)
            }), {})

            return {
                ...existLayers,
                ...updatedDeckLayers
            }
        })
        mapDispatch({type: 'resetLayerUpdate'})
    }, [mapLayersUpdate.needReoption])

    useEffect(() => {
        if (!mapLayersUpdate.needRerender) return

// console.log('needRerender', mapLayersUpdate)

        const updateDeckLayersIds = Array.isArray(mapLayersUpdate.updatedLayers)
            ? mapLayersUpdate.updatedLayers
            : [mapLayersUpdate.updatedLayers]

        const newDeckLayers = Object.fromEntries(
            Object.entries(localMapLayers)
                .map(([layerId, deckLayer]) => {
console.log('>>>>', {deckLayer, layerId})

                    if (updateDeckLayersIds.indexOf(layerId) === -1) {
                        return [layerId, deckLayer];
                    }

                    if (isUserActivityLayer(deckLayer)) {
                        return [
                            layerId,
                            createUserActivityLayer({
                                getData: dataFabric({layer: mapState.userActivityLayer, mapDispatch, dataState}),
                                me
                            })
                        ]
                    }

                    if (deckLayer.id === createActorLayerId) {
console.log('IS isActivityModelLayer!!!>', {deckLayer, layerId, layer: mapState[createActorLayerId]})
                        return [
                            layerId,
                            createActorLayer(mapState[createActorLayerId])
                        ]
                    }

                    const layer = layers[layerId]
                    const cube = metricCubes[layer.colorMetricId]
                    const metric = theMetrics[layer.colorMetricId]
                    // const stats = cube?.stats
                    const filters = layer.colorMetricFilters

                    const heightCube = metricCubes[layer.height?.metricId]
                    const heightMetric = metricCubes[layer.height?.metricId]

                    return [layerId, layerFabric({
                        layer,
                        theme,
                        viewState,
                        cube,
                        options: {
                            onClick: layerOnClick({layer}),
                            onHover: layerOnHover({layer, metric, cube, filters, heightCube}),
                            onExit: hideTooltip
                        },
                        getData: dataFabric({layer, metric, cube, filters, heightMetric, heightCube, mapDispatch}),
                        // stats,
                        // metric,
                        // filters,
                        // ver: cacheState.ver[layer.id]
                    })]
                })
        )

        setLocalMapLayers(newDeckLayers)

        mapDispatch({type: 'resetLayerUpdate'})
    }, [mapLayersUpdate.needRerender])

    useEffect(() => {
        if (!mapLayersUpdate.needCreate) return

// console.log('needCreate', mapLayersUpdate)

        const createDeckLayersIds = Array.isArray(mapLayersUpdate.updatedLayers)
            ? mapLayersUpdate.updatedLayers
            : [mapLayersUpdate.updatedLayers]

        setLocalMapLayers(existLayers => Object.assign(
            existLayers,
            createDeckLayersIds
                .filter(layerId => layers[layerId] && isLayerDefined(layers[layerId]))
                .reduce((newDeckLayers, layerId) => {
                    const layer = layers[layerId]
                    const cube = metricCubes[layer.colorMetricId]
                    const metric = theMetrics[layer.colorMetricId]
                    // const stats = cube?.stats
                    const filters = layer.colorMetricFilters

                    const heightCube = metricCubes[layer.height?.metricId]
                    const heightMetric = metricCubes[layer.height?.metricId]

                    const deckLayer = layerFabric({
                        layer,
                        theme,
                        viewState,
                        cube,
                        options: {
                            onClick: layerOnClick({layer}),
                            onHover: layerOnHover({layer, metric, cube, filters, heightCube}),
                            onExit: hideTooltip
                        },
                        getData: dataFabric({layer, metric, cube, filters, heightMetric, heightCube, mapDispatch}),
                        // stats,
                        // metric,
                        // filters,
                        // ver: cacheState.ver[layer.id]
                    })

                    return {
                        ...newDeckLayers,
                        [layerId]: deckLayer
                    }
                }, {})
            )
        )

        mapDispatch({type: 'resetLayerUpdate'})
    }, [mapLayersUpdate.needCreate])

    useEffect(() => {
        if (!mapLayersUpdate.needReassemble) return

// console.log('needReassemble', mapLayersUpdate)

        const layersToRemoveIds = Array.isArray(mapLayersUpdate.removedLayers)
            ? mapLayersUpdate.removedLayers
            : [mapLayersUpdate.removedLayers]

        const createLayersIds = Array.isArray(mapLayersUpdate.updatedLayers)
            ? mapLayersUpdate.updatedLayers
            : [mapLayersUpdate.updatedLayers]

        setLocalMapLayers(existLayers => Object.assign(
            Object.fromEntries(
                Object.entries(existLayers)
                    .filter(([layerId, layer]) => layersToRemoveIds.indexOf(layerId) === -1)
            ),
            createLayersIds
                .filter(layerId => layers[layerId] && isLayerDefined(layers[layerId]))
                .reduce((newDeckLayers, layerId) => {
                    const layer = layers[layerId]
                    const cube = metricCubes[layer.colorMetricId]
                    const metric = theMetrics[layer.colorMetricId]
                    // const stats = cube?.stats
                    const filters = layer.colorMetricFilters

                    const heightCube = metricCubes[layer.height?.metricId]
                    const heightMetric = metricCubes[layer.height?.metricId]

                    const deckLayer = layerFabric({
                        layer,
                        theme,
                        viewState,
                        cube,
                        options: {
                            onClick: layerOnClick({layer}),
                            onHover: layerOnHover({layer, metric, cube, filters, heightCube}),
                            onExit: hideTooltip
                        },
                        getData: dataFabric({layer, metric, cube, filters, heightMetric, heightCube, mapDispatch}),
                        // stats,
                        // metric,
                        // filters,
                        // ver: cacheState.ver[layer.id]
                    })

                    return {
                        ...newDeckLayers,
                        [layerId]: deckLayer
                    }
                }, {})
        ))
        mapDispatch({type: 'resetLayerUpdate'})

    }, [mapLayersUpdate.needReassemble])

    useEffect(() => {
        if (!mapLayersUpdate.needSetup) return

// console.log('needSetup', mapLayersUpdate)
        // Please wait a bit while rendering...
        enqueueSnackbar(`Please wait a bit while rendering...`, {
            autoHideDuration: 5000,
            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
            variant: 'info'
        })


        const deckLayers = mapState.layers ? mapLayerFabric({
            data: Object.values(mapState.layers)
                .filter(layer => isLayerDefined(layer))
                .sort((a, b) => layers[a.id].ordering < layers[b.id].ordering ? -1 : 1)
                .map((layer) => {
                    const cube = metricCubes[layer.colorMetricId]
                    const metric = theMetrics[layer.colorMetricId]
                    const stats = cube?.stats
                    const filters = layer.colorMetricFilters

                    const heightCube = metricCubes[layer.height?.metricId]
                    const heightMetric = metricCubes[layer.height?.metricId]

                    return {
                        layer,
                        stats,
                        theme,
                        metric,
                        cube,
                        filters,
                        options: {
                            onClick: layerOnClick({layer}),
                            onHover: layerOnHover({layer, metric, cube, filters, heightCube}),
                            onExit: hideTooltip
                        },
                        getData: dataFabric({layer, metric, cube, filters, heightMetric, heightCube, mapDispatch, dataState}),
                        // ver: cacheState.ver[layer.id]
                    };
                }),
            viewState,
        }) : {}

        deckLayers[userActivityLayerId] = createUserActivityLayer({
            getData: dataFabric({layer: mapState.userActivityLayer, mapDispatch, dataState}),
            me
        })

        // deckLayers['glb-layer'] = createGLBLayer({
        //     material: theme.material,
        //     autoHighlight: true,
        //     highlightColor: [0, 0, 128, 128],
        //
        // })

        if (appConfig.isExperimentalEnabled) {
            deckLayers[createActorLayerId] = createActorLayer(mapState[createActorLayerId])
        }

        setLocalMapLayers(deckLayers)
        mapDispatch({type: 'resetLayerUpdate'})

    }, [
        dataFilters,
        mapLayersUpdate.needSetup,
        // mapState.userActivityLayer,
        // mapState.userEditLayer,
        // editModeComponent,
        // activityObjects
    ])

    const renderActorLayer = () => {
        if (appConfig.isExperimentalEnabled) {
            setLocalMapLayers(layers => ({
                ...layers,
                [createActorLayerId]: createActorLayer(mapState[createActorLayerId])
            }))
        }
    }

    const theMapItem = useMemo(() => {

        const renderingLayers = Object.values(localMapLayers)
            .concat(editMapLayer ? [editMapLayer] : [])

        return (
            <DeckGL
                initialViewState={initialViewState}
                controller={false}
                // effects={theme.effects}
                layers={renderingLayers}
                getCursor={() => isEditMode ? "crosshair" : defaultCursor}
                onDragEnd={(info) => {

                    mapDispatch({
                        type: 'setVisibleViewState',
                        viewState: info?.viewport
                    })

                }}

                onViewStateChange={(viewState, interactionState) => {
                    // if (interactionState.isZooming) {
                    //     mapDispatch({
                    //         type: 'setVisibleViewState',
                    //         viewState: viewState
                    //     })
                    // }
                }}

                onHover={(info, event) => {
                    if (info.object) {
                        setDefaultCursor('pointer')
                    } else {
                        setDefaultCursor('grab')
                    }
                }}

                onClick={(data) => {
                    if (isEditMode) {
                        // handlePlaceBuilding(data)
                    }
                }}

                style={{overflow: 'hidden'}}
                views={DEFAULT_VIEWS}
            >

                <Map
                    reuseMaps
                    mapStyle={mapStyle.mapStyle}
                    // mapStyle='https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json'
                    mapboxAccessToken={appConfig.mapbox.token}
                    preventStyleDiffing={true}
                    attributionControl={false}
                    style={{zIndex: 2}}
                >
                    <AttributionControl
                        customAttribution={[
                            '© Statistische Ämter des Bundes und der Länder, Deutschland, 2021',
                        ]}
                        style={{
                            bottom: 0,
                            right: 0
                        }}
                    />

                </Map>

            </DeckGL>

        )
    }, [initialViewState, localMapLayers, editMapLayer, mapStyle.mapStyle])

    return (
        <>

            <BuildingCardModal
                isOpened={currentModalCard !== null}
                context={currentModalCard}
                onClose={() => {
                    setCurrentModalCard(null)
                }}
                onSave={(object, metrics) => {
                    handlerUpdateObject({context: currentModalCard, object, metrics})
                    setCurrentModalCard(null)
                }}
                onDelete={(object) => {
                    handlerDeleteObject({context: currentModalCard, object})
                    setCurrentModalCard(null)
                }}
            />

            <div
                className={classes.mapContainer}
                onContextMenu={(e) => e.preventDefault()}>
                {theMapItem}

                <div className={classes.tooltipPanel} style={{
                    opacity: tooltip.visible ? 1 : 0,
                    left: tooltip.x,
                    top: tooltip.y
                }}>
                    <h4 className={classes.tooltipPanelTitle}>{tooltip.title}</h4>
                    {tooltip.values.map(item => <p className={classes.tooltipPanelText}>{item}</p>)}
                </div>
            </div>
        </>
    )
}

export default MapComponent;