import React from "react";
import {
    Button,
    Select, MenuItem, InputLabel, TextField,
    Accordion, AccordionSummary, AccordionDetails
} from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import css from 'styled-components'
import {mapLayers} from '../data/map'

const PanelGroup = css.div`
    position: fixed;
    top: 75px;
    left: 10px;
    width: 210px;    
    z-index: 1;
`

const PanelLayer = styled(Accordion)({
    '& .MuiAccordionSummary-content': {
        'font-weight': 'bold'
    }
})

const AddButton = styled(Button)({
    width: '100%'
});

const PanelContent = styled(AccordionDetails)({
    display: 'block'
})

const PanelLayer1 = styled(PanelLayer)({
    background: '#ABBECD',
});

const PanelLayer2 = styled(PanelLayer)({
    background: '#D9DFE4',
});

const FormItem = css.div`
    margin-bottom: 10px;
`

const LeftBar = (props) => {

    const { onLayerChange, onAddBuildingModeChange, onBuildingSettingsChange } = props;

    const [expanded, setExpanded] = React.useState('layer1');
    const [mapLayer, setMapLayer] = React.useState(mapLayers[0].id);
    const [buildingSettings, setBuildingSettings] = React.useState({
        q: 20,
        rent: 9
    })
    const [addBuildingMode, setAddBuildingMode] = React.useState(false)

    const handleChangePanel = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const toggleAddBuildingMode = () => {
        setAddBuildingMode( !addBuildingMode )
        if (onAddBuildingModeChange) onAddBuildingModeChange(!addBuildingMode);
    }

    const handleChangeMapLayer = (event) => {
        setMapLayer(event.target.value);
        if (onLayerChange) onLayerChange(event.target.value);
    };

    const handleBuildingSettingsChange = (settings) => {
        setBuildingSettings((prevState => ({
            ...prevState, ...settings
        })))
        if (onBuildingSettingsChange) onBuildingSettingsChange(settings)
    }

    return (
        <PanelGroup>

            <PanelLayer1 expanded={expanded === 'layer1'} onChange={handleChangePanel('layer1')}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="layer1-content"

                >Layer selection</AccordionSummary>
                <PanelContent>
                    <Select
                        value={mapLayer}
                        onChange={handleChangeMapLayer}
                    >
                        {mapLayers.map(item => (
                            <MenuItem value={item.id}>{item.title}</MenuItem>
                        ))}
                    </Select>
                </PanelContent>
            </PanelLayer1>

            <PanelLayer2 expanded={expanded === 'layer2'} onChange={handleChangePanel('layer2')}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="layer1-content"
                >Add building</AccordionSummary>
                <PanelContent>
                    <FormItem>
                        <TextField
                            label="Q value"
                            value={buildingSettings.q}
                            onChange={(e) => handleBuildingSettingsChange({q: (e.target.value)})}
                        />
                    </FormItem>

                    <FormItem>
                        <TextField
                            label="Rent price"
                            value={buildingSettings.rent}
                            onChange={(e) => handleBuildingSettingsChange({rent: (e.target.value)})}
                        />
                    </FormItem>

                    <FormItem>
                        <AddButton
                            variant="contained"
                            color="primary"
                            onClick={toggleAddBuildingMode}
                        >
                            {addBuildingMode ? 'Cancel' : 'Add building'}
                        </AddButton>
                    </FormItem>

                </PanelContent>
            </PanelLayer2>

{/*
            <PanelComponent>
                <PanelTitle>Layers</PanelTitle>
            </PanelComponent>

            <PanelComponent2>
                <PanelTitle>Layers</PanelTitle>
                <div>
                    <TextField
                        id="standard-number"
                        label="Number"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </PanelComponent2>
*/}

        </PanelGroup>
    )
}

export default LeftBar;
