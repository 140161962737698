import {getDB} from "../../db";
import {isFieldObject, isFieldUUID, isFieldScalar} from "../../helpers/field";
import {getCubeQuantilesStruct, getCubeStats} from "../../helpers/stats";
import {computeColor, getColorQuantile} from "../../helpers/color";
import {getMetricValueOrFirstSample} from "../../helpers/data";
import {prepareLayerCollection} from '../layer'

const DEFAULT_WEIGHT = 1.0
const ZERO_WEIGHT = 0.0
const getHitmapWeight = (metricValue, colorStats, isScalar, hasColorMetric, hasColorFilters) => {
    const isNotNan = !Number.isNaN(metricValue)

    if (isScalar && isNotNan) {
        return colorStats
            ? (metricValue - colorStats.min) / (colorStats.max - colorStats.min)
            : DEFAULT_WEIGHT
    }

    if (hasColorMetric && hasColorFilters) {
        return metricValue
            ? DEFAULT_WEIGHT
            : ZERO_WEIGHT
    }

    return DEFAULT_WEIGHT
}


export default function heatmapLayerDataFabric({layer, cube, filters, heightCube, mapDispatch}) {
    return async function getData() {

        if (!layer) return []

        mapDispatch({type: 'setCalculating', isCalculating: true})

        const collection = await prepareLayerCollection({layer, cube, filters, heightCube})

        // console.log('query>', collection.buildQuery())

        const t0 = Date.now()
        const result = await collection.rawExecute()

        window.rr = result

        // console.log(`quering: t=${(Date.now() - t0)}, c=${result.numRows}`)

        const lngColumn = result.getChild('lng')
        const latColumn = result.getChild('lat')

        const hasColorMetric = layer.colorMetricId && layer.colorMetricField
        const hasColorFilters = !!layer.colorMetricFilters
        // const hasHeightMetric = layer.height?.metricId && layer.height?.field
        const metricField = hasColorMetric
            ? cube?.struct?.fields?.find(field => field.name === layer.colorMetricField)
            : null
        const isScalar = metricField ? isFieldScalar(metricField) : false
        const metricValues = hasColorMetric ? result.getChild('metricValue')?.toArray() : null

        const batchCount = lngColumn.data.length
        const currentColorStats = getCubeStats(cube, layer.colorMetricField)

        const geometryCount = lngColumn.length
        const positionArray = new Float32Array(geometryCount * 2)
        const weightArray = new Float32Array(geometryCount)

        let indexOffset = 0;
        let positionIndex = 0
        let weightIndex = 0

        for (let bi = 0; bi < batchCount; bi++) {
            const lngPoints = lngColumn.data[bi]
            const latPoints = latColumn.data[bi]
            // const elevationVector = hasHeightMetric ? elevationColumn.data[bi].values : null
            const batchPointsCount = lngPoints.length

            for (let i = 0; i < batchPointsCount; i++) {
                positionArray[positionIndex++] = lngPoints.values[i]
                positionArray[positionIndex++] = latPoints.values[i]

                const metricValue = hasColorMetric
                    ? getMetricValueOrFirstSample(metricValues[i + indexOffset] ?? null)
                    : null

                weightArray[weightIndex++] = getHitmapWeight(
                    metricValue, currentColorStats, isScalar, hasColorMetric, hasColorFilters)

            }

            indexOffset += batchPointsCount
        }


        mapDispatch({type: 'setCalculating', isCalculating: false})

        return  {
            length: geometryCount,
            // startIndices: startIndices,
            attributes: {
                getPosition: {value: positionArray, size: 2},
                getWeight: {value: weightArray, size: 1}
                // getFillColor: {value: colorArray, size: 3},
                // getElevation: {value: elevationArray, size: 1}
            }
        }
    }
}
