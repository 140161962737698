import React, { useContext } from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControlLabel, Paper, Switch} from "@material-ui/core";

import ObjectSelector from './widgets/object-selector'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: 280,
        background: '#F5FAFF',
        borderRadius: 0,
        paddingBottom: 10,
        margin: '0 16px 16px 16px'

    },
    title: {
        boxSizing: 'border-box',
        padding: '10px',
        margin: '0',
        fontWeight: 'normal',
        fontSize: '12pt'
    },
    displayModeControl: {
        background: '#F5FAFF'

    },
    colorBaseControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    colorBaseGradControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    colorBaseGradNumberControl: {
        margin: theme.spacing(1),
        minWidth: 95,
    },
    oneColorControl: {
        margin: theme.spacing(1)
    },
    lineWidthControl: {
        margin: theme.spacing(1)
    },
    opacityControl: {
        margin: theme.spacing(1)
    },
    gradientItem: {
        width: '100%',
        height: '40px',
    },
    layerControls: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    layerOrderingControls: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(1)
    }
}));


export default function LayerPalette(props) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <h4 className={classes.title}>{props.title}</h4>

            <div className={classes.layerControls}>
                <ObjectSelector />
            </div>

        </Paper>
    )
}