import React from "react";
import AppReducer from "./app";
import MapReducer from "./map";
import { combineReducers } from "redux"
import authStateReducer, {initialAuthState, AuthStateContext} from './auth'
import popupStateReducer, { initialPopupState, PopupStateContext } from './popup'

// Redux State
const INITIAL_SETTINGS_STATE = {};

const rootReducer = combineReducers({
    app: AppReducer,
    map: MapReducer,
    auth: authStateReducer,
    popup: popupStateReducer,
    settings: (state = INITIAL_SETTINGS_STATE, action) => {
        switch (action.type) {
            default:
                return state;
        }
    },

});

export default rootReducer;
export {
    authStateReducer, initialAuthState, AuthStateContext,
    popupStateReducer, initialPopupState, PopupStateContext,
}
