import * as duckdb from '@duckdb/duckdb-wasm';
import {isFieldScalar, isFieldTimestamp, isFieldMultipleValue, normalizeDate} from "../helpers/field";
import {objectSchema} from '../models/object'


let DB_VER = 1;

// const ddb = new duckdb.Database(':memory:');

const fieldStructMap = (field, value, struct) => {
    try {
        if (struct[field] && struct[field] === 'JSON')
            return JSON.parse(value)
        if (struct[field] && struct[field] === 'Vector')
            return value ? value.toArray() : null
        return value
    } catch (e) {
        console.log('E_db>', {e, struct, field, value})
        return value
    }

}
export const rowStructMap = (struct) => (row) => {
    return Object.fromEntries(Object.entries(row).map(([k, v]) => ([k, fieldStructMap(k, v, struct)])))
}

const getKeysList = (struct, exceptType) => struct.fields
    .filter(field => field.is_key)
    .filter(field => !exceptType || field.type.indexOf(exceptType) === -1)
    .map(field => field.name)


const Table = function (tableName, db) {
    // this.map = map ? map : new Map()
    // this.set = (k, v) => this.map.set(k, v)
    // this.toArray = () => Array.from(this.map, ([name, value]) => value)

    this.db = db
    this.name = tableName
    this.fieldStruct = this.db.getStruct(this.name)
    this.fields = this.fieldStruct?.fields.reduce((acc, fld) => ({...acc, [fld.name]: fld.type}), {})

    // this.set = (k, v) => this.map[k] = v
    // this.get = (k) => this.map[k]
    this.find = async (f, k) => {
        // this.indexes[f][k] && this.indexes[f][k].length ? this.map[this.indexes[f][k][0]] : null

        const conn = await this.db.connection();
        return (await conn.query(`select * from ${DuckDBClient.project_schema}."${this.name}" where ${f} = '${k}'`))
            .toArray()
            .map(Object.fromEntries)
            .map(rowStructMap(this.fields))
            ;
    }
    this.joinArray = async ({
                                tableName, tableKey, tableFields, rootKey, rootFields,
                                joinType = 'left', defaultValue = 0, filters = {}
                            }) => {
        const conn = await this.db.connection();

        const conditions = [
            `x."${tableKey}" = t."${rootKey}"`
        ]

        const joinTableStruct = this.db.getStruct(tableName)

        if (filters) Object.entries(filters).forEach(([filterName, filter]) => {
            const values = filter?.naturalValue
            const field = joinTableStruct.fields.find(fld => fld.name === filterName)

            if (!field) return

            const isTimestamp = isFieldTimestamp(field)  // field.type.indexOf('timestamp') > -1
            const isScalar = isFieldScalar(field)

            if (values && values.length) {

                if (isTimestamp) {
                    const dateValues = values.map(val => normalizeDate(val))
                    conditions.push(`x."${field.name}" between '${dateValues[0]}' and '${dateValues[1]}'`)
                    return
                }

                if (isScalar) {
                    conditions.push(`x."${field.name}" between '${values[0]}' and '${values[1]}'`)
                    return;
                }

                conditions.push(`x."${field.name}" in (${values.map(item => `'${item}'`).join(', ')})`)
            }

        })

        const rootAliasedFields = rootFields.map(fld => `t."${fld}"`)
        const fields = rootAliasedFields
            .concat(Object.entries(tableFields).map(([fld, alias]) => `coalesce(avg(x."${fld}"), ${defaultValue}) as ${alias}`))
        const query = `
            select 
                ${fields.join(', ')}                 
            from ${DuckDBClient.project_schema}."${this.name}" t
            ${joinType} join ${DuckDBClient.project_schema}."${tableName}" as x on ${conditions.join(' and ')}
            group by ${rootAliasedFields.join(', ')}
        `

        return (await conn.query(query))
            .toArray()
            .map(Object.fromEntries)
            .map(rowStructMap(this.fields))
            ;
    }
    this.toArray = async () => {
        const conn = await this.db.connection();

        return (await conn.query(`select * from ${DuckDBClient.project_schema}."${this.name}"`))
            .toArray()
            .map(Object.fromEntries)
            .map(rowStructMap(this.fields))
            ;
    }
    this.getRow = async (rowIndex = 0) => {
        const conn = await this.db.connection();
        return rowStructMap(Object.fromEntries(this.fields)((await conn.query(
            `select count(*) as cnt from ${DuckDBClient.project_schema}."${this.name}"`
        )).get(rowIndex)))
    }
    this.count = async () => {
        const conn = await this.db.connection();
        return parseInt((await conn.query(
            `select count(*) as cnt from ${DuckDBClient.project_schema}."${this.name}"`
        )).get(0)['cnt'])
    }
    this.toCollection = () => new Collection(this, db)

    return this
}

const Collection = function (table, db) {
    this.db = db
    this.table = table
    this.params = {}
    this.expressions = []

    this.limitExpr = ''
    this.offsetExpr = ''

    this.fields = ['t.*']
    this.conditions = []
    this._leftJoins = []
    this._unionStruct = {...this.table?.fields}
    this.params = {}
    this.groupByExpression = []
    this.orderingExpression = []

    const filterFieldType = (type) => type.replaceAll('not null', '')

    this.getWhereExpr = () => (this.conditions.length > 0 ? `where ${this.conditions.join(' and ')}` : '')

    this.between = (field, left, right) => {
        this.conditions.push(`t."${field}" between '${left}' and '${right}'`)
        return this
    }
    this.eq = (field, value) => {
        this.conditions.push(`t."${field}" = '${value}'`)
        return this
    }
    this.ge = (field, value) => {
        this.conditions.push(`t."${field}" >= '${value}'`)
        return this
    }
    this.le = (field, value) => {
        this.conditions.push(`t."${field}" < '${value}'`)
        return this
    }
    this.in = (field, values) => {
        if (!values.length) return
        this.conditions.push(`t."${field}" in (${values.map(item => `'${item}'`).join(', ')})`)
        return this
    }
    this.notIn = (field, values) => {
        if (!values.length) return
        this.conditions.push(`t."${field}" not in (${values.map(item => `'${item}'`).join(', ')})`)
        return this
    }

    this.limit = (limit) => {
        this.limitExpr = `limit ${parseInt(limit)}`
        return this
    }
    this.offset = (offset) => {
        this.offsetExpr = `offset ${parseInt(offset)}`
        return this
    }
    // this.leftJoinArray = async ({tableName, tableKey, tableFields, rootKey, rootFields}) => {
    // this.leftJoin = (table, key, link_key, fields) => {

    this.joinFields = ({
                           tableName, tableKey, rootKey, tableFields,
                           aggregateFunction = 'avg',
                           valueLimit = null,
                           defaultValue = 0,
                           filters = {}
                       }) => {
        // const mainTableFields = this.table?.fields
        const tableStruct = this.db.getStruct(tableName)
        const tableStructFields = tableStruct
            ? tableStruct.fields?.reduce((acc, fld) => ({...acc, [fld.name]: fld.type}))
            : {}

        const index = this.fields.length
        const joinAlias = `${tableKey}${index}`
        const filterConditions = Object
            .entries(filters)
            .filter(([k, v]) => v !== null)
            .filter(([k, v]) => k in tableStructFields)
            .map(([k, v]) => {
                if (tableStructFields[k] && tableStructFields[k].indexOf('TIMESTAMP') > -1) {
                    return `year(${joinAlias}.${k}) = '${v}'`
                }
                return `${joinAlias}.${k} = '${v}'`
            })

        const joinCondition = `${joinAlias}."${tableKey}" = t."${rootKey}"`

        this._unionStruct = {
            ...this._unionStruct,
            ...Object.entries(tableFields)
                .reduce((acc, [fld, alias]) => ({
                    ...acc,
                    [alias]: aggregateFunction ? tableStructFields[fld] : 'Vector'
                }), {})
        } // ...tableStructFields

        this._leftJoins = this._leftJoins.concat(
            Object.entries(tableFields)
                .map(([fld, alias]) =>
                    aggregateFunction
                        ? `left join (
                            select ${joinAlias}."${tableKey}", ${aggregateFunction}(${joinAlias}."${fld}")::${filterFieldType(tableStructFields[fld])} as "${alias}" 
                            from ${DuckDBClient.project_schema}."${tableName}" ${joinAlias}
                            ${filterConditions.length ? `where ${filterConditions.join(' and ')}` : ''}
                            group by ${joinAlias}."${tableKey}"
                            ${valueLimit ? `limit ${valueLimit}` : ''}
                        ) "${joinAlias}" on ${joinCondition}`
                        : `left join (
                            select ${joinAlias}."${tableKey}", list(${joinAlias}."${fld}"::${filterFieldType(tableStructFields[fld])}) as "${alias}" 
                            from ${DuckDBClient.project_schema}."${tableName}" ${joinAlias}
                            ${filterConditions.length ? `where ${filterConditions.join(' and ')}` : ''}
                            group by ${joinAlias}."${tableKey}"
                            ${valueLimit ? `limit ${valueLimit}` : ''}
                        ) "${joinAlias}" on ${joinCondition}`)
        )
        this.fields = this.fields.concat(
            Object.entries(tableFields)
                .map(([fld, alias]) => `"${joinAlias}".${alias} as ${alias}`)
        )

        // this.fields = this.fields.concat(
        //     Object.entries(tableFields)
        //         .map(([fld, alias]) =>
        //             aggregateFunction
        //                 ? `(
        //                     select ${aggregateFunction}(${joinAlias}."${fld}")
        //                     from ${DuckDBClient.project_schema}."${tableName}" ${joinAlias}
        //                     where ${joinConditions.join(' and ')}
        //                     ${valueLimit ? `limit ${valueLimit}` : ''}
        //                 )::${filterFieldType(tableStructFields[fld])} "${alias}"`
        //                 : `(
        //                     select list(${joinAlias}."${fld}"::${filterFieldType(tableStructFields[fld])})
        //                     from ${DuckDBClient.project_schema}."${tableName}" ${joinAlias}
        //                     where ${joinConditions.join(' and ')}
        //                     ${valueLimit ? `limit ${valueLimit}` : ''}
        //                 ) "${alias}"`)
        // )

        return this
    }

    this.leftJoin = ({
                         tableName, tableKey, rootKey, tableFields,
                         joinType = 'left', aggregateFunction = '',
                         defaultValue = 0, filters = {}
                     }) => {
        const mainTableFields = this.table?.fields
        const tableStruct = this.db.getStruct(tableName)
        const tableStructFields = tableStruct
            ? tableStruct.fields?.reduce((acc, fld) => ({...acc, [fld.name]: fld.type}))
            : {}

        const index = this._leftJoins.length
        const joinAlias = `${tableKey}${index}`
        const joinConditions = [
            `${joinAlias}."${tableKey}" = t."${rootKey}"`,
        ].concat(Object.entries(filters).map(([k, v]) => {
            if (tableStructFields[k] && tableStructFields[k].indexOf('TIMESTAMP') > -1) {
                return `year(${joinAlias}.${k}) = '${v}'`
            }
            return `${joinAlias}.${k} = '${v}'`
        }))

        this._leftJoins.push(`${joinType} join "${tableName}" ${joinAlias} on ${joinConditions.join(' and ')}`)

        // tableStructFields -- not needed, the only ${alias} is used
        this._unionStruct = {
            ...this._unionStruct,
            ...Object.entries(tableFields)
                .reduce((acc, [fld, alias]) => ({...acc, [alias]: tableStructFields[fld]}), {})
        } // ...tableStructFields

        this.fields = this.fields.concat(
            Object.entries(tableFields)
                .map(([fld, alias]) =>
                    `coalesce(${aggregateFunction}(${joinAlias}."${fld}"), ${defaultValue})::${tableStructFields[fld]} "${alias}"`)
        )
        if (aggregateFunction !== '') {
            this.groupByExpression = Object.keys(mainTableFields)?.map(fldName => `t."${fldName}"`) // ['all']
        }
        // this.orderingExpression = [`t."${rootKey}"`, `${joinAlias}."${tableKey}"`]
        return this
    }

    this.filter = ({tableName, tableKey, rootKey, filterFields}) => {
        const index = this.conditions.length
        const condAlias = `${tableKey}${index}`
        const filterCondition = Object
            .entries(filterFields)
            .filter(([fldName, fldValue]) => (
                fldValue.naturalValue
                && fldValue.naturalValue?.length > 1
                && (!!fldValue.naturalValue[0] || !!fldValue.naturalValue[1])))
            .map(([fldName, fldValue]) => {
                const values = fldValue?.naturalValue
                return values.length > 2
                    ? `("${condAlias}"."${fldName}" in (${values.map(item => `'${item}'`).join(', ')}))`
                    : `("${condAlias}"."${fldName}" between '${values[0] == null ? 0 : values[0]}' and '${values[1] == null ? 0 : values[1]}')`
            })

        if (filterCondition.length === 0) return this;

        this.conditions.push(`t.${rootKey} in (
            select "${condAlias}"."${tableKey}"
            from ${DuckDBClient.project_schema}."${tableName}" as "${condAlias}"
            where ${filterCondition.join(' and ')}
        )`)

        return this
    }

    this.addCondition = ({fieldName, condition, value}) => {
        this.conditions.push(`t.${fieldName} ${condition} '${value}'`)
        return this
    }

    this.buildQuery = (exceptFields = []) => {
        const whereExpr = this.getWhereExpr()
        return (`
            select ${this.fields.filter(field => exceptFields.indexOf(field) === -1).join(', ')}
            from ${DuckDBClient.project_schema}."${this.table.name}" t
            ${this._leftJoins.join(' ')}
            ${whereExpr}
            ${this.groupByExpression.length > 0 ? `group by ${this.groupByExpression.join(', ')}` : ''}
            ${this.orderingExpression.length > 0 ? `order by ${this.orderingExpression.join(', ')}` : ''}
            ${this.limitExpr}
            ${this.offsetExpr}
        `)
    }

    this.prepareUnconditionalQuery = (exceptFields = []) => {
        return (`
            select ${this.fields.filter(field => exceptFields.indexOf(field) === -1).join(', ')}
            from ${DuckDBClient.project_schema}."${this.table.name}" t
            ${this._leftJoins.join(' ')}
            where {{condition}}
            ${this.groupByExpression.length > 0 ? `group by ${this.groupByExpression.join(', ')}` : ''}
            ${this.orderingExpression.length > 0 ? `order by ${this.orderingExpression.join(', ')}` : ''}
            ${this.limitExpr}
            ${this.offsetExpr}
        `)
    }

    this.copy = () => {
        const newCollection = new Collection(table, db)
        newCollection.params = {...this.params}
        newCollection.expressions = [...this.expressions]
        newCollection.limitExpr = this.limitExpr
        newCollection.offsetExpr = this.offsetExpr
        newCollection.fields = [...this.fields]
        newCollection.conditions = [...this.conditions]
        newCollection._leftJoins = [...this._leftJoins]
        newCollection._unionStruct = {...this._unionStruct}
        newCollection.params = {...this.params}
        newCollection.groupByExpression = [...this.groupByExpression]
        newCollection.orderingExpression = [...this.orderingExpression]

        return newCollection
    }

    this.getItem = async (index = 0) => {
        const conn = await this.db.connection();
        const query = this.buildQuery()
        const result = (await conn.query(query)).get(index)
        return result ? rowStructMap(this._unionStruct)(result) : null
    }

    this.rawExecute = async () => {
        const conn = await this.db.connection();
        const query = this.buildQuery()
        return  await conn.query(query)
    }

    this.toArray = async () => {
        const conn = await this.db.connection();
        const query = this.buildQuery()

        return (await conn.query(query))
            .toArray()
            .map(Object.fromEntries)
            .map(rowStructMap(this._unionStruct))
            ;
    }

    return this
}

const JSDELIVR_BUNDLES = duckdb.getJsDelivrBundles();

const makeDB = async function () {
    const bundle = await duckdb.selectBundle(JSDELIVR_BUNDLES);

    const worker_url = URL.createObjectURL(
        new Blob([`importScripts("${bundle.mainWorker}");`], {type: 'text/javascript'})
    );
    const worker = new Worker(worker_url);
    // const worker = await duckdb.createWorker(bundle.mainWorker);

    const logger = new duckdb.VoidLogger();  //  ConsoleLogger()
    const db = new duckdb.AsyncDuckDB(logger, worker);
    await db.instantiate(bundle.mainModule);
    return db
}

export const PROJECT_SCHEMA = 'project'

export class DuckDBClient {

    static project_schema = PROJECT_SCHEMA

    constructor() {
        // this._db = db
        this._struct = {}
        this._tmpCounter = 0
    }

    setStruct(name, struct) {
        this._struct[name] = struct
    }

    getStruct(name) {
        return this._struct[name]
    }

    dropStruct(name) {
        delete this._struct[name]
    }

    async db() {
        if (!this._db) {
            this._db = await makeDB();
            DB_VER = await this._db.getVersion()

            await this._db.open({
                query: {
                    castTimestampToDate: true
                }
            });

            await this._createProjectSchema();

            window._raw_db = this._db
            window.ddb = this
            window.ddb_ver = DB_VER
        }
        return this._db;
    }

    async reset() {
        if (!this._db) {
            await this.db()
        }
        await this._refreshProjectSchema()
        this._struct = {}
    }

    async connection() {
        if (!this._conn) {
            const db = await this.db();
            this._conn = await db.connect();
            await this._conn.query('PRAGMA force_index_join;')
            // await this._conn.query("SET allocator_flush_threshold = '500MB'")
            const availableMem = (parseFloat(navigator?.deviceMemory ? navigator.deviceMemory : 2) * 0.75).toFixed(2)
            await this._conn.query(`SET memory_limit='${availableMem}GB'`)
            // await this._conn.query("SET arrow_large_buffer_size=true")
            // await this._conn.query("SET external_threads=8")
            // await this._conn.query("SET threads='8'")
        }
        return this._conn;
    }

    disconnect() {
        if (this._conn) {
            this._conn.close();
        }
        this._conn = undefined;
    }

    async run(query, params) {
        let result;

        const conn = await this.connection();
        if (params) {
            const stmt = await conn.prepare(query)
            result = await stmt.query(...params)
        } else {
            result = await conn.query(query);
        }

        return result;
    }

    async getTables() {
        return (await this.run(`SHOW TABLES`)).toArray();
    }

    async table(tabName) {
        const tables = await this.getTables();
        const table = tables.find(({name}) => name === tabName)
        return table
            ? new Table(table.name, this)
            : null
    }

    getTableName(tableName) {
        return `${DuckDBClient.project_schema}."${tableName}"`
    }

    async hasTable(tabName) {
        // const tables = (await this.run(`SHOW TABLES`)).toArray();
        const tables = await this.getTables();
        return tables.find(({name}) => name === tabName)
    }

    async _createProjectSchema() {
        const db = await this.db()
        const conn = await db.connect();
        await conn.query(`CREATE SCHEMA IF NOT EXISTS ${DuckDBClient.project_schema};`);
        await conn.close();
    }

    async _dropProjectSchema() {
        const db = await this.db()
        const conn = await db.connect();
        await conn.query(`DROP SCHEMA IF EXISTS ${DuckDBClient.project_schema} CASCADE;`);
        await conn.close();
    }

    async _refreshProjectSchema() {
        const db = await this.db()
        const conn = await db.connect();
        await conn.query(`DROP SCHEMA IF EXISTS ${DuckDBClient.project_schema} CASCADE;`);
        await conn.query(`CREATE SCHEMA IF NOT EXISTS ${DuckDBClient.project_schema};`);
        await conn.close();
    }

    async deleteItem(tableName, idField, idValue) {
        await this.run(`delete from ${DuckDBClient.project_schema}."${tableName}" where ${idField} = ?`, [idValue])
    }

    async updateItem(tableName, updatingItem, idField, idValue) {

        const columns = this.getStruct(tableName).fields.reduce((acc, fld) => ({...acc, [fld.name]: fld.type}), {})
        const item = Object.fromEntries(Object.entries(updatingItem).filter(([k, v]) => !!columns[k]))
        let params = []
        const queryParams = Object.entries(item)
            .filter(([field, value]) => field !== idField)
            .map(([field, value]) => {
                const dataType = columns[field]

                if (!dataType) {
                    params.push(value)
                    return `${field} = ?`
                }

                if (dataType === 'struct') {
                    // return Object.keys(item[field]).map(k => `${field}.${k} = ?`).join(', ')
                    const structArgs = Object.keys(item[field]).map(k => `${k} := ?`).join(', ')
                    params = params.concat(Object.values(item[field]))
                    return `${field} = (select struct_pack(${structArgs}))`
                }

                if (dataType === 'map') {
                    // todo: это надо запустить
                    const mapKeys = Object.keys(item[field])
                        .map(key => `'${key.replace("'", "\'")}'`).join(', ')
                    const mapValues = Object.keys(item[field]).map(_ => '?').join(', ')
                    params = params.concat(Object.values(item[field]))
                    return `${field} = (select map([${mapKeys}], [${mapValues}]))`
                    // return `${field} = ${field}`
                }

                if (dataType.indexOf('[]') > -1) {
                    const casting = (list, castingType, deepness) => {
                        if (deepness > 0) return `${list.map(item => `[${casting(item, castingType, deepness - 1)}]`).join(', ')}`
                        return list.map(item => `${item}::${castingType}`).join(', ')
                    }

                    const rootType = dataType.substr(0, dataType.indexOf('[]'))
                    const deep = (dataType.match(/\[\]/g) || []).length - 1

                    return `${field} = (select [${casting(item[field], rootType, deep)}])`

                }

                params.push(value)
                return `${field} = ?`
            })
        params.push(idValue)

        const query = `
            update ${DuckDBClient.project_schema}."${tableName}" set
                ${queryParams.join(', ')}                        
            where ${idField} = ?
        `

        const db = await this.db();
        const conn = await db.connect();
        const stmt = await conn.prepare(query)

        await stmt.query(...params)

        await conn.close();
    }

    async upsertItem(tableName, upsertingItem, idField, idValue) {
        await this.deleteItem(tableName, idField, idValue)
        await this.insertItem(tableName, {...upsertingItem, [idField]: idValue})
    }

    async upsertItems(tableName, upsertingItem, idField, idValue) {
        await this.deleteItem(tableName, idField, idValue)
        const fields = this.getStruct(tableName).fields.reduce((acc, fld) => ({...acc, [fld.name]: fld}), {})

        const decartRowCount = Object.entries(upsertingItem)
            .filter(([field, value]) => isFieldMultipleValue(fields[field]))
            .reduce((acc, [field, values]) => (
                acc * values.length
            ), 1)

        const singleValues = Object.entries(upsertingItem)
            .filter(([field, value]) => !isFieldMultipleValue(fields[field]))
            .reduce((acc, [field, value]) => ({...acc, [field]: value}), {})

        singleValues[idField] = idValue

        const multipleValues = Object.entries(upsertingItem)
            .filter(([field, value]) => isFieldMultipleValue(fields[field]))
            .reduce((acc, [field, values]) => ({...acc, [field]: values}), {})

        const insertingItems = [...Array(decartRowCount)]
            .map((x) => singleValues)
            .map((row, rowIndex) => {
                const multiValuesRow = Object.entries(multipleValues).reduce((acc, [mvField, values]) => ({
                    ...acc, [mvField]: values[rowIndex % values.length]
                }), {})
                return {...row, ...multiValuesRow}
            })

        await Promise.all(insertingItems.map(item => this.insertItem(tableName, item)))
    }

    async insertItem(tableName, insertingItem) {
        const fields = this.getStruct(tableName).fields.reduce((acc, fld) => ({...acc, [fld.name]: fld}), {})
        const columnTypes = this.getStruct(tableName).fields.reduce((acc, fld) => ({...acc, [fld.name]: fld.type}), {})
        const item = Object.fromEntries(Object.entries(insertingItem).filter(([k, v]) => !!columnTypes[k]))
        let params = []

        const queryParams = Object.entries(item).map(([field, value]) => {
            const dataType = columnTypes[field]

            if (!dataType) {
                params.push(value)
                return '?'
            }

            if (dataType === 'struct') {
                const structArgs = Object.keys(item[field]).map(k => `${k} := ?`).join(', ')
                params = params.concat(Object.values(item[field]))
                return `struct_pack(${structArgs})`
            }

            if (dataType === 'map') {
                const keys = Object.keys(item[field]).join(', ')
                const values = Object.keys(item[field]).map(_ => '?').join(', ')
                params = params.concat(Object.values(item[field]))
                return `map([${keys}], [${values}])`
            }

            if (dataType.indexOf('[]') > -1) {
                const casting = (list, castingType, deepness) => {
                    if (deepness > 0) return `[${list.map(item => casting(item, castingType, deepness - 1)).join(', ')}]`
                    return list.map(item => `${item}::${castingType}`).join(', ')
                }

                const rootType = dataType.substr(0, dataType.indexOf('[]'))
                const deep = (dataType.match(/\[\]/g) || []).length - 1

                return `[${casting(item[field], rootType, deep)}]`
            }

            params.push(value)
            return '?'
        })

        const query = `
            insert into ${DuckDBClient.project_schema}."${tableName}" (${Object.keys(item).join(', ')})
            values (${queryParams.join(', ')})
        `

        const db = await this.db();
        const conn = await db.connect();
        const stmt = await conn.prepare(query)

        await stmt.query(...params)

        await conn.close();
        // await this.insertJSON(tableName, [item], {columns: {}})
    }

    async insertParquet(tableName, source) {
        const db = await this.db();
        const conn = await db.connect();
        await conn.query(`
            CREATE TABLE if not exists ${DuckDBClient.project_schema}."${tableName}" AS  
            SELECT * FROM "${source}"
        `);
        await conn.close();
    }

    async insertJSON(tableName, data, options) {
        const db = await this.db();
        const fileName = `${tableName}_${this._tmpCounter}.json`
        await db.registerFileText(fileName, JSON.stringify(data))
        // await db.insertJSONFromPath(fileName, {name: tableName, schema: 'main'})
        // await db.registerFileBuffer(name, new Uint8Array(buffer));

        const tableName_tmp = `${tableName}_tmp_${this._tmpCounter}`
        this._tmpCounter++;

        const columns = this.getStruct(tableName).fields.map(item => `"${item.name}"`)

        const conn = await db.connect();
        await conn.insertJSONFromPath(fileName, {
            name: tableName_tmp,
            schema: DuckDBClient.project_schema,
            ...options
        });

        await conn.query(`
            insert into ${DuckDBClient.project_schema}."${tableName}" (${columns.join(', ')})
            select ${columns.join(', ')} 
            from ${DuckDBClient.project_schema}."${tableName_tmp}"
        `)
        // where id not in (select id from ${tableName})

        await conn.query(`
            drop table ${DuckDBClient.project_schema}."${tableName_tmp}"
        `)

        await db.dropFile(fileName)

        await conn.close();
    }

    async createTable(tableName, struct, createRule = 'create table if not exists') {
        const db = await this.db();
        const conn = await db.connect();

        this.setStruct(tableName, this.prepareStruct(struct))

        await conn.query(this.prepareCreateTableQuery(tableName, struct, createRule))
        await this.prepareCreateTableIndexes(tableName, struct).map(async (createQuery) => {
            await conn.query(createQuery)
        })

        await conn.close();
    }

    async createTableIndexes(tableName, struct) {
        const db = await this.db();
        const conn = await db.connect();
        await this.prepareCreateTableIndexes(tableName, this.prepareStruct(struct)).map(async (createQuery) => {
            await conn.query(createQuery)
        })
        await conn.close();
    }

    async dropTableIndexes(tableName, struct) {
        const db = await this.db();
        const conn = await db.connect();
        await this.prepareDropTableIndexes(tableName, this.getStruct(tableName)).map(async (dropQuery) => {
            await conn.query(dropQuery)
        })
        await conn.close();
    }

    async dropTable(tableName, cascade = false) {
        const db = await this.db();
        const conn = await db.connect();

        await conn.query(this.prepareDropTableQuery(tableName, cascade))
        await this.prepareDropTableIndexes(tableName, this.getStruct(tableName)).map(async (dropQuery) => {
            await conn.query(dropQuery)
        })

        await conn.close();

        this.dropStruct(tableName)
    }

    prepareCreateTableQuery(name, struct, createRule = 'create table if not exists') {
        const fieldList = struct.fields.map(field => `"${field.name}" ${field.type}`)
        return `${createRule} ${DuckDBClient.project_schema}."${name}" (${fieldList.join(', ')})`
    }

    prepareCreateTableIndexes (name, struct) {
        const keyList = getKeysList(struct)
        return keyList.map((key) => `create index "${DuckDBClient.project_schema}_${name}_${key}_idx" on ${DuckDBClient.project_schema}."${name}" (${key})`).concat(
            struct.indexes
                ? struct.indexes.map(item =>
                    `create index "${DuckDBClient.project_schema}_${name}_fld${item.fields.join('_')}_idx" on ${DuckDBClient.project_schema}."${name}" (${item.fields.join(', ')})`)
                : []
        )
    }

    prepareDropTableQuery(tableName, cascade = false) {
        return `drop table ${DuckDBClient.project_schema}."${tableName}" ${cascade ? 'cascade' : ''}`
    }

    prepareDropTableIndexes (name, struct) {
        const keyList = getKeysList(struct)
        return keyList.map((key) => `drop index if exists "${DuckDBClient.project_schema}_${name}_${key}_idx";`).concat(
            struct.indexes
                ? struct.indexes.map(item =>
                    `drop index if exists "${DuckDBClient.project_schema}_${name}_fld${item.fields.join('_')}_idx";`)
                : []
        )
    }

    prepareStruct (struct) {
        return {
            ...struct,
            fields: struct.fields.map(field => ({
                ...field,
                type: field.type.toLowerCase().indexOf('timestamptz') > -1
                    ? field.type.replace(RegExp('timestamptz', 'i'), 'TIMESTAMP_S')
                    : field.type
            }))
        }
    }
}

const global_db = new DuckDBClient();
Promise.all([global_db.db()]).then(() => {
}).catch((e) => {
    console.error('DB Error>', e)
})

export const getDB = async () => {
    return global_db;
}

export async function PrepareIndexesAndStruct(name, schema) {
    global_db.setStruct(name, schema)
    await global_db.dropTableIndexes(name, schema)
    await global_db.createTableIndexes(name, schema)
}


export const AddTable = async function (cube, data, isExists = false) {
    if (isExists) {
        await global_db.dropTable(cube.name, true)
    }

    await global_db.createTable(cube.name, cube.struct)
    await FillTable({
        name: cube.name,
        data
    })
}

export const AddTableParquet = async function (cube, source, isExists = false) {
    if (isExists) {
        await global_db.dropTable(cube.name, true)
    }

    global_db.setStruct(cube.name, cube.struct)
    await global_db.insertParquet(cube.name, source)
    await global_db.createTableIndexes(cube.name, cube.struct)
}

export const FillTableParquet = async function (tableName, source) {
    await global_db.insertParquet(tableName, source)
}

export const FillTable = async function (data) {
    await global_db.insertJSON(data.name, data.data, {
        columns: {
            // todo: проблема с типами! пишет что не может geom упаковать, coordinates не нравится
        }
    })
}

export const refreshDB = async function () {
    console.info('REF>')
    return await global_db.reset()
}