import React from "react";


export const initialCacheState = {
    layers: {},
    ver: {},
    crc: {},
};

const cacheStateReducer = function (state = initialCacheState, action) {

    window.cacheState = state

    switch (action.type) {

        case 'setLayer':
            return {
                ...state,
                layers: {
                    ...state.layers,
                    [action.cacheKey]: action.data
                },
                ver: {
                    ...state.ver,
                    [action.cacheKey]: state.ver[action.cacheKey] ? state.ver[action.cacheKey] + 1 : 1
                },
                crc: {
                    ...state.crc,
                    [action.cacheKey]: action.cacheCRC
                }
            }

        case 'resetCRC':
            return {
                ...state,
                crc: {
                    ...state.crc,
                    [action.cacheKey]: ''
                }
            }

        case 'addItem':
            // not a clean but rapid
            return {
                ...state,
                ver: {
                    ...state.ver,
                    [action.cacheKey]: state.ver[action.cacheKey] ? state.ver[action.cacheKey] + 1 : 1
                },
                layers: {
                    ...state.layers,
                    [action.cacheKey]: state.layers[action.cacheKey]
                        ? state.layers[action.cacheKey].concat([action.item])
                        : [action.item]
                }
            }

        case 'updateItem':
            return {
                ...state,
                ver: {
                    ...state.ver,
                    [action.cacheKey]: state.ver[action.cacheKey] ? state.ver[action.cacheKey] + 1 : 1
                },
                // crc: {
                //     ...state.crc,
                //     [action.cacheKey]: `-r-${Date.now()}`
                // },
                layers: {
                    ...state.layers,
                    // [action.cacheKey]: state.layers[action.cacheKey].map(item => (
                    //     item.id ===` action.item.id ? action.item : item
                    // ))
                    [action.cacheKey]: state.layers[action.cacheKey].map(item => {
                        if (item.id === action.item.id) {
                            return action.item
                        }

                        return item
                    })
                }
            }

        case 'deleteItem':
            return {
                ...state,
                ver: {
                    ...state.ver,
                    [action.cacheKey]: state.ver[action.cacheKey] ? state.ver[action.cacheKey] + 1 : 1
                },
                crc: {
                    ...state.crc,
                    [action.cacheKey]: `-d-${Date.now()}`
                },
                layers: {
                    ...state.layers,
                    [action.cacheKey]: state.layers[action.cacheKey].filter(item => (item.id !== action.item.id))
                }
            }

        case 'clearLayer':
            return {
                ...state,
                layers: Object.fromEntries(Object.entries(state.layers).filter(([key]) => key !== action.cacheKey)),
            }

        default:
            return state;
    }
}
export default cacheStateReducer;

export const CacheStateContext = React.createContext(initialCacheState)

