import React, {useContext} from "react";
import {PopupStateContext, AuthStateContext} from '../reducers'
import {logOut} from "../actions/auth";
import {Divider, IconButton, Menu, MenuItem, Toolbar} from "@material-ui/core";
import {AccountCircle, Help} from "@material-ui/icons";
import { useTranslation } from 'react-i18next';


const HelpButton = (props) => {
    const {popupState, popupDispatch} = useContext(PopupStateContext)

    const handleHelp = () => {
        popupDispatch({
            type: 'openPopup',
            popup: 'welcomeTour'
        })
    }

    // const { me } = authState
    // const { t } = useTranslation();
    //
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    //
    // const handleMenu = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    //
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    //
    // const handleLogout = () => {
    //     logOut()
    // }
    //
    // const handleProfilePopup = () => {
    //     popupDispatch({
    //         type: 'openPopup',
    //         popup: 'profile'
    //     })
    //     setAnchorEl(null);
    // }

    return (
        <div>
            <IconButton
                aria-label="account of current user"
                aria-controls="topbar-menu-appbar"
                aria-haspopup="true"
                onClick={handleHelp}
                color="inherit"
                style={{padding: '12px 0 12px 12px'}}
            >
                <Help style={{'color': '#DEE6F1'}} />
            </IconButton>
{/*
            <Menu
                id="topbar-menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleProfilePopup}>{t('topbar.user.profile')}</MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>

                    <ExitToApp style={{'color': '#DEE6F1'}} />
                    {t('topbar.user.exit')}

                </MenuItem>
            </Menu>
*/}
        </div>
    )
}

export default HelpButton