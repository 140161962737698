import React, {useState, useEffect, useReducer, useContext} from "react";
import {
    Chip,
    Paper,
    Avatar,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Collapse,
    List,
    ListSubheader,
    ListItemText,
    ListItem,
    ListItemIcon,
    Badge,
    ListItemSecondaryAction
} from "@material-ui/core";
import {
    TreeItem,
    TreeView
} from "@material-ui/lab"
import {
    ExpandMore,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    CheckBox as CheckBoxIcon,
    PlusOne,
    ExpandLess,
    Delete
} from '@material-ui/icons';
import {makeStyles, styled} from '@material-ui/core/styles';
import {deleteMetricAction} from '../../../../../../actions/app/data'
import {MapStateContext, DataStateContext} from "../../../reducer";
import FilterChain from './filter-chain'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        // padding: theme.spacing(0.5),
        background: 'none',
        padding: 0,
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
        maxWidth: 130,
        textOverflow: 'ellipsis'
    },
    tree: {
        minHeight: 300,
        maxHeight: '80vh',
        flexGrow: 1,
        minWidth: 300,
        maxWidth: '80vw',
    },
    controls: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: 'center'
    },
    dialog: {
        transition: 'all 0.3s easeInOutSine'
    },

    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    metricTreeItem: {
        label: {
            paddingLeft: 8
        }
    },

    list: {},

    listHeader: {
        fontSize: '1rem',
        fontWeight: 'bold',
        background: theme.color.darkGray
    },

    listDatasetItem: {
        borderBottom: '1px solid rgba(0,0,0,0.12)'
    },

    listMetricItem: {
        borderBottom: '1px solid rgba(0,0,0,0.12)'
    },

    listCollapseItem: {
        borderBottom: '1px solid rgba(0,0,0,0.12)'
    },

    listItemText: {
        '& span': {
            textOverflow: 'ellipsis',
            display: 'inline-block',
            overflow: 'hidden',
            fontSize: 12,
            height: '2rem'
        }
    },

    selectControlPanel: {

    },

    selectControl: {
        width: 200,
    },

    okButtonLayout: {
        display: 'block',
        verticalAlign: 'bottom',
        width: 200
    },
    okButton: {
        padding: '4px 0',
        width: 60,
        minWidth: 60,
        borderRadius: 0
    },

}));


const LayerFilters = function (props) {
    const classes = useStyles();

    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {dataState, dataDispatch} = useContext(DataStateContext)
    const {
        metric: selectedMetrics,
        cube: cubes
    } = dataState;

    const selectedDataTree = Object.values(selectedMetrics).reduce((data, item) => {
        const newData = {...data}
        const {litera, color, object, dataset, metric} = item;

        if (!(object.id in newData)) {
            newData[object.id] = {
                ...object,
                datasets: {}
            }
        }

        if (dataset && metric) {
            if (!(dataset.id in newData[object.id].datasets)) {
                newData[object.id].datasets[dataset.id] = {
                    ...dataset,
                    metrics: {}
                }
            }

            newData[object.id].datasets[dataset.id].metrics[metric.id] = {
                ...metric,
                color,
                litera
            };
        }

        return newData
    }, {})

    const handleDelete = (metricToDelete) => () => {
        deleteMetricAction(
            metricToDelete.id,
            {
                mapContext: [mapState, mapDispatch],
                dataContext: [dataState, dataDispatch],
            }
        )
    };

    const [openedDatasets, setOpenedDatasets] = React.useState([]);
    const [openedMetrics, setOpenedMetrics] = React.useState([]);

    return (
        <>
            {
                Object.values(selectedDataTree).map((objectTypeItem) => {
                    return (
                        <List
                            component='nav'
                            subheader={
                                <ListSubheader component='div' className={classes.listHeader}>
                                    {objectTypeItem.meta.title}
                                </ListSubheader>
                            }
                            className={classes.list}
                        >
                            {
                                Object.values(objectTypeItem.datasets).map((datasetItem) => {
                                    const isOpened = openedDatasets.indexOf(datasetItem.id) > -1
                                    return (
                                        <>
                                            <ListItem button onClick={() => setOpenedDatasets(oldState => {

                                                return isOpened
                                                    ? oldState.filter(dsItem => dsItem !== datasetItem.id)
                                                    : oldState.concat(datasetItem.id)
                                            })} className={classes.listDatasetItem}>
                                                {/*<Badge badgeContent={Object.values(datasetItem.metrics).length}></Badge>*/}
                                                <ListItemText>{datasetItem.meta.title}</ListItemText>
                                                {isOpened ? <ExpandLess/> : <ExpandMore/>}
                                            </ListItem>
                                            <Collapse in={isOpened} timeout="auto" unmountOnExit>
                                                <List component='div' disablePadding>
                                                    {
                                                        Object.values(datasetItem.metrics).map((metricItem) => {
                                                            const isOpened = openedMetrics.indexOf(metricItem.id) > -1
                                                            const cube = cubes[metricItem.id]

                                                            if (!cube)
                                                                return null;

                                                            return (
                                                                <>
                                                                    <ListItem button onClick={() => setOpenedMetrics(oldState => {
                                                                        return isOpened
                                                                            ? oldState.filter(mItem => mItem !== metricItem.id)
                                                                            : oldState.concat(metricItem.id)
                                                                    })} className={classes.listMetricItem}>
                                                                        <ListItemIcon>
                                                                            <Avatar
                                                                                style={{background: metricItem.color}}
                                                                                className={classes.litera}>
                                                                                {metricItem.litera}
                                                                            </Avatar>
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            className={classes.listItemText}>{metricItem.meta.title}</ListItemText>
                                                                        {isOpened ? <ExpandLess/> : <ExpandMore/>}
                                                                    </ListItem>
                                                                    <Collapse in={isOpened} timeout="auto" unmountOnExit className={classes.listCollapseItem}>
                                                                        <FilterChain
                                                                            objectType={objectTypeItem}
                                                                            metric={metricItem}
                                                                            cube={cube}
                                                                        />
                                                                    </Collapse>
{/*
                                                                    <div className={classes.controls}>
                                                                        <Button variant="contained"
                                                                                onClick={console.log}>Add data</Button>
                                                                    </div>
*/}
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </Collapse>
                                        </>
                                    )
                                })
                            }
                        </List>
                    );
                })
            }


            {/*
            <div className={classes.controls}>
                <Button variant="contained" onClick={handleObjectTypeDialog}>Add data</Button>
            </div>
*/}

        </>
    );
}

export default LayerFilters;