export const isFieldUUID = field => field.type.indexOf('uuid') > -1
export const isFieldTimestamp = field => field.type.indexOf('timestamp') > -1
export const isFieldKey = field => field.is_key
export const isFieldObject = field => field.is_object === true
export const isFieldMultipleValue = field => field.is_multiple === true
export const isFieldScalar = field => !isFieldUUID(field) && !isFieldTimestamp(field)
export const isFieldFilterable = field => field.is_filter !== false
export const scalarTypeFloat = 'float'
export const scalarTypeInt = 'float'

const _findSplitter = (date) => ['/', '.', '-', '_'].find(splitter => date.indexOf(splitter) > -1)
const _tryTurnToYear = (date) => {
    try {
        const splitter = _findSplitter(date)
        const yearPart = String(date).split(splitter).find(part => part.length === 4)
        const year = new Date(yearPart).getFullYear()
        return !isNaN(year) ? year : yearPart
    } catch (e) {
        return date
    }
}
export const normalizeDate = (date) => {
    try {
        const year = new Date(date).getFullYear()
        return !isNaN(year) ? year : _tryTurnToYear(date)
    } catch (e) {
        return date
    }
}

export const normalizeDateByValue = (value) => {
    try{
        return new Date(Date.parse(value)).toISOString().slice(0, 10)
    } catch (e) {
        return new Date(Date.now()).toISOString().slice(0, 10)
    }
}