import React from "react";
import {getMapStyle, getMapOptions} from '../map-styles/index'
import {objectOnlyKeys} from "../../../../helpers/fn";

const DEFAULT_THEME = 'black'

const getMapStyleState = (theme) => ({
    theme,
    mapStyle: getMapStyle(theme),
    mapOptions: getMapOptions(theme)
})

export const initialMapStyle = getMapStyleState(DEFAULT_THEME);


export const saveableMapStyle = (state) => state


const mapStyleReducer = function (state = initialMapStyle, action) {
    switch (action.type) {

        case 'setMapStyle':
            return getMapStyleState(action.theme)

        case 'setOption':
            const mapOptions = {
                ...state.mapOptions,
                [action.option]: action.enabled
            }

            const disabledLayers = Object.keys(mapOptions).filter((optKey) => mapOptions[optKey] === false)

            return {
                ...state,
                mapOptions,
                mapStyle: {
                    ...state.mapStyle,
                    layers: state.mapStyle.layers.map((layer) => {
                        if (layer.layout) {
                            return {
                                ...layer,
                                layout: {
                                    ...layer.layout,
                                    visibility: disabledLayers.indexOf(layer['metadata']['mapbox:group']) > -1
                                        ? 'none'
                                        : 'visible'
                                }
                            }
                        }

                        return layer;
                    })
                }
            }

        case 'load':
            return {
                ...state,
                ...action.state
            }

        default:
            return state;
    }
}

export default mapStyleReducer;

export const MapStyleContext = React.createContext(initialMapStyle)