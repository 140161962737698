import {bboxPolygon} from "@turf/turf";

function _getBoundsZoomLevel(bounds, mapDim) {
    const WORLD_DIM = {height: 256 * 4, width: 256 * 4};
    const ZOOM_MAX = 19;

    function latRad(lat) {
        const sin = Math.sin(lat * Math.PI / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds[0];
    const sw = bounds[3];

    const [y0, y1] = ne[1] < sw[1] ? [ne[1], sw[1]] : [sw[1], ne[1]];

    const latFraction = (latRad(y0) - latRad(y1)) / Math.PI;

    const lngDiff = Math.abs(ne[0] - sw[0]);
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX) - 1;
}

export function getBoundsZoomLevel(bounds, mapDim) {
    try {
        return _getBoundsZoomLevel(bounds, mapDim)
    } catch (e) {
        return 10;
    }
}

export function vector4toArray(geomVector) {
    if (Array.isArray(geomVector)) return geomVector

    return geomVector.toArray()
        .map(item => item.toArray()
            .map(item => item.toArray()
                .map(item => ([...item.toArray()]))))
}

export function isPossible3d(geomType) {
    return ['Point', 'MultiPoint', 'LineString', 'MultiLineString'].indexOf(geomType) === -1
}

export function isLineType(geomType) {
    return ['LineString', 'MultiLineString'].indexOf(geomType) > -1
}

export function isLineString(geomType) {
    return geomType === 'LineString'
}


export function vector4toGeom(geomVector, geomType) {

    const arrayGeom = vector4toArray(geomVector)

    if (geomType === 'Point') return {
        type: geomType,
        coordinates: arrayGeom[0][0][0]
    }

    if (geomType === 'MultiPoint') return {
        type: geomType,
        coordinates: arrayGeom[0][0]
    }

    if (geomType === 'Polygon' || geomType === 'MultiLineString') return {
        type: geomType,
        coordinates: arrayGeom[0]
    }

    if (geomType === 'LineString') return {
        type: geomType,
        coordinates: arrayGeom[0][0]
    }

    return {
        type: geomType,
        coordinates: arrayGeom
    }
}

export function vector4toPolygon(geomVector, geomType) {

    if (geomType === 'Point') return null
    if (geomType === 'MultiPoint') return null

    const arrayGeom = vector4toArray(geomVector)

    if (geomType === 'Polygon') return arrayGeom[0]
    return arrayGeom[0][0]
}

export function buildGeomFeature(geometry, item = {}, properties = {}) {
    return {
        type: 'Feature',
        properties,
        geometry,
        ...item
    }
}

export function pointFloatCasting(point) {
    return [
        parseFloat(point[0].toFixed(7)),
        parseFloat(point[1].toFixed(7)),
    ]
}

export function vector4FloatCasting(vector) {
    return vector.map(item => item.map(item => item.map(item => (pointFloatCasting(item)))))
}

export function buildGeomPoint(coordinates, item = {}, properties = {}) {
    return buildGeomFeature({
        type: 'Point',
        coordinates,
    }, item, properties)
}


export function polygonToVector4(geometry) {
    return [geometry.coordinates]
}


export function multiPolygonToVector4(geometries) {
    return geometries.map(geom => geom.coordinates)
}


export function lineStringToVector4(geometry) {
    return [[geometry.coordinates]]
}


export function pointToVector4(geometry) {
    return [[geometry.coordinates]]
}


export function geomToVector4(geometry, geometryType) {
    if (geometryType === 'Polygon') return polygonToVector4(geometry)
    if (geometryType === 'MultiPolygon') return multiPolygonToVector4(geometry)
    if (geometryType === 'LineString') return lineStringToVector4(geometry)
    if (geometryType === 'Point') return pointToVector4(geometry)

    return null
}


export function Vector4Bbox(geomVector) {
    const bboxCoords = [[-180, -90], [180, 90]]
    for (let geomIndex = 0; geomIndex < geomVector.length; geomIndex++) {
            for (let polyIndex = 0; polyIndex < geomVector[geomIndex].length; polyIndex++) {
                for (let pointIndex = 0; pointIndex < geomVector[geomIndex][polyIndex].length; pointIndex++) {
                    const point = geomVector[geomIndex][polyIndex][pointIndex]
                    if (point[0] > bboxCoords[0][0]) bboxCoords[0][0] = point[0]
                    if (point[0] < bboxCoords[1][0]) bboxCoords[1][0] = point[0]
                    if (point[1] > bboxCoords[0][1]) bboxCoords[0][1] = point[1]
                    if (point[1] < bboxCoords[1][1]) bboxCoords[1][1] = point[1]
                }
            }
    }
    return bboxCoords
}


export function Vector4Centroid(geomVector) {
    const bboxCoords = Vector4Bbox(geomVector)
    return [
        bboxCoords[0][0] + (bboxCoords[1][0] - bboxCoords[0][0]) / 2,
        bboxCoords[0][1] + (bboxCoords[1][1] - bboxCoords[0][1]) / 2,
    ]
}


export function vector4MiddlePoint(geomVector) {
    try {
        const middlePointIndex = Math.round(geomVector[0][0].length / 2) - 1
        return [
            geomVector[0][0][middlePointIndex][0],
            geomVector[0][0][middlePointIndex][1]
        ]
    } catch (e) {
        return [0, 0]
    }
}
