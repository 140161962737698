import React, {useState, useEffect, useReducer, useContext} from "react";
import {
    Grid,
    Button,
    Switch,
    Select, MenuItem, InputLabel, TextField,
    Checkbox,
    Paper,
    BottomNavigation,
    BottomNavigationAction,
    FormControl,
    Typography,
    Slider

} from "@material-ui/core";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {SliderPicker} from 'react-color'
import {
    hexToRgb, stepPaletteLinearGrad, twoColorsPalette,
    gradientTable,
    gradientTableNumbers,
    grayScalePalette,
    getRandomColor,
    UNDEFINED_COLOR
} from '../../../../../../../helpers/color'
import {
    getAnyValue
} from '../../../../../../../helpers/repr'
import {
    isFieldObject,
    isFieldScalar,
    isFieldFilterable,
    isFieldTimestamp,
    isFieldKey
} from '../../../../../../../helpers/field'

import ColorMetricField from './color-metric-fields'

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import {useTranslation} from "react-i18next";


const Accordion = withStyles((theme) => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
    root: {
        background: theme.color.gray,
        border: 'none',
        marginBottom: -1,
        minHeight: 20,
        padding: 10,
        '&$expanded': {
            background: theme.color.semiGray,
            minHeight: 20,
        },
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: 0,
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        display: 'block',
        padding: 0,
    },
}))(MuiAccordionDetails);


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 10px'
    },

    title: {
        margin: 0,
        padding: 0
    },

    shortColor: {
        minWidth: '60px',
        height: '20px'
    },

    gradSelector: {
        boxSizing: 'border-box',
    },

    gradItem: {
        width: '100%',
        cursor: 'pointer',
        padding: 4,
        margin: 1,
        boxSizing: 'border-box',
        background: 'transparent',
        '&:hover': {
            background: theme.color.semiGray,
        }
    },

    gradItemColor: {
        width: '100%',
        height: '12px',
    },

    gradItemSelected: {
        width: '100%',
        cursor: 'pointer',
        padding: 4,
        margin: 1,
        boxSizing: 'border-box',
        background: theme.color.semiGray,
    },

    colorBaseControl: {

        // margin: theme.spacing(1),
        // width: 244
    },

    baseColorControls: {
        marginBottom: theme.spacing(1)
    },

    colorStepsControl: {
        width: 30,
        marginLeft: 10
    },

    baseColorHeader: {
        marginBottom: 0
    },

    baseMetricControl: {
        width: 100
    },

    baseFieldControl: {
        width: 100,
        marginLeft: 10
    },

    colorControl: {
        margin: theme.spacing(1)
    },

    opacityControl: {
        margin: theme.spacing(1)
    },

    grayScaleControl: {
        marginTop: 20
    },

    grayScaleColor: {
        boxSizing: 'border-box',
        width: '20%',
        paddingRight: 1,
        float: 'left',
    },

    grayScaleColorPicker: {
        height: 12,
        cursor: 'pointer'
    },

    grayScaleColorPickerSelected: {
        height: 12,
        cursor: 'pointer',
        transform: 'scaleY(1.8)',
        borderRadius: '3.6px / 2px'
    },

    legendTable: {

    },

    legendRow: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        // padding: theme.spacing(0.5),
        background: 'none',
        padding: 0,
        margin: '2px 0 0 0',
    },

    legendColor: {
        width: 40,
        height: 12
    },

    legendValues: {
        paddingLeft: 15,
        width: 220
    }

}));

const getColorLegendTable = ({valueStruct, colorPalette, stats, dict, isScalar, isDict }) => {

    if (!isScalar && !isDict)
        return

    if (!colorPalette)
        return

    if (isDict && dict && dict?.values.length > 0) {
        return dict?.values.map((value, i) => ({
            color: colorPalette[i % colorPalette.length],
            title: [value.title]
        })).concat([{
            color: UNDEFINED_COLOR,
            title: 'Undefined'
        }])
    }

    const quantiles = Object.entries(stats?.quantiles)
        .filter(([quant, _]) => String(quant).startsWith(`q_${valueStruct?.gradSteps}_`))
        .map(([quant, value]) => (value))

    const maxQuantile = quantiles ? Math.max.apply(null, quantiles) : 0
    const fractions = maxQuantile < 1 ? 4 :2

    return colorPalette?.map((color, i) => ({
        color,
        title: [
            i === 0 ? '...' : getAnyValue(quantiles[i - 1], fractions),
            i === colorPalette?.length - 1 ? '...' : getAnyValue(quantiles[i], fractions)
        ]
    })).concat([{
        color: UNDEFINED_COLOR,
        title: 'Undefined'
    }])
}

let timeout = null
const ColorSelector = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const title = props.title ? props.title : 'Color'

    const {
        id,
        onChange, onBaseMetricChange, onToggle,
        opacity, withoutBase, isEnabled,
        value, defaultValue,
        baseMetric: baseMetricId,
        baseField: baseMetricField,
        baseFilters: baseMetricFilters,
        metrics: availableMetrics,
        legendEnabled,
        cubes,
        gradOnly
    } = props;

    const defaultColor = value
        ? value.color
        : getRandomColor()
    const defaultGradIndex = value && value.gradIndex ? value.gradIndex : 0;
    const defaultGradSteps = value && value.gradSteps ? value.gradSteps : gradientTableNumbers[0];

    const [color, setColor] = React.useState(defaultColor)
    const [initialColor] = useState(defaultColor)

    const [colorFilters, setColorFilters] = useState(baseMetricFilters ?? {})

    const defaultBaseMetricStruct = {
        id: baseMetricId,
        field: baseMetricField,
        filters: baseMetricFilters
    }
    const [baseMetricStruct, setBaseMetricStruct] = React.useState(defaultBaseMetricStruct)
    const [gradSteps, setGradSteps] = React.useState(defaultGradSteps)
    const [gradIndex, setGradIndex] = React.useState(defaultGradIndex)
    const [expanded, setExpanded] = React.useState(false);

    const isGradAvailable = gradOnly || (!withoutBase && !!baseMetricId && !!baseMetricField)
    const isOneColor = !gradOnly && (!isGradAvailable || typeof color === 'string');

    const baseCube = cubes.find(cube => cube && cube.id === baseMetricId)
    const baseMetric = availableMetrics.find(metric => metric && metric.id === baseMetricId)
    const availableFields = baseCube?.struct.fields.filter(fld => isFieldScalar(fld) || (
        isFieldKey(fld) && !isFieldObject(fld) && !isFieldTimestamp(fld)
    ))
    const availableFieldDict = baseCube?.dicts.find(d => d.field === baseMetricField)
    const isLegendAvailable = legendEnabled && !isOneColor && baseCube && baseMetricField &&
        (baseCube?.stats[baseMetricField] || availableFieldDict)

    // yes, a trick, but make the code easy
    const isScalar = !!baseCube?.stats[baseMetricField]
    const isDict = !!availableFieldDict
    const legendTable = isLegendAvailable ? getColorLegendTable({
        valueStruct: value,
        colorPalette: color,
        stats: baseCube?.stats[baseMetricField],
        dict: availableFieldDict,
        isScalar,
        isDict
    }) : null

    useEffect(() => {
        if (value.color) {
            setColor(value.color)
        }
        if (value.gradIndex) {
            setGradIndex(value.gradIndex)
        }
        if (value.gradSteps) {
            setGradSteps(value.gradSteps)
        }
    }, [value])

    useEffect(() => {
        if (isGradAvailable) {
            setColor(gradientTable[gradSteps][gradIndex])
        } else {
            setColor(initialColor)
        }
    }, [gradIndex, gradSteps, baseMetricStruct])

    useEffect(() => {
        if (props.onChange) {

            if (timeout) {
                clearTimeout(timeout)
            }

            timeout = setTimeout(() => {
                props.onChange({
                    color,
                    gradSteps,
                    gradIndex,
                    // colorFilters
                })
            }, 300)

        }
    }, [color, gradIndex, gradSteps, colorFilters])

    useEffect(() => {

        if (isGradAvailable) {
            setGradIndex(defaultGradIndex)
        }

        if (props.onBaseMetricChange && isGradAvailable) {
            props.onBaseMetricChange(baseMetricStruct)
        }

        if (!baseMetricStruct) {
            setColor(defaultColor)
        }

    }, [baseMetricStruct])

    const shortColorStyle = {
        background: color ? (!isOneColor && Array.isArray(color)? stepPaletteLinearGrad(color) : color) : defaultColor,
        width: isOneColor ? '60px' : '100%',
        opacity,
    }

    return (
        <div className={classes.root}>

            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={8}>
                    <h5 className={classes.title}>{title}</h5>
                </Grid>
                <Grid item>
                    <Switch
                        defaultChecked={true}
                        value={isEnabled}
                        color="primary"
                        inputProps={{'aria-label': 'checkbox with default color'}}
                        onChange={(e) => {
                            if (onToggle) onToggle(e.target.checked)
                        }}
                    />
                </Grid>
            </Grid>


            <Accordion square expanded={expanded} onChange={(event, isExpanded) => {
                setExpanded(isExpanded);
            }}>
                <AccordionSummary aria-controls="panel1d-content" id={`panel1d-header-${id}`}>
                    <div className={classes.shortColor} style={shortColorStyle}/>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        !withoutBase && availableMetrics && availableMetrics.length > 0 &&
                        <div className={classes.baseColorControls} key="baseColorControls">
                            <h5 className={classes.baseColorHeader}>{t('widgets.color_selector.color_base')}</h5>
                            <FormControl key='metric' className={classes.baseMetricControl}>
                                <InputLabel>{t('widgets.color_selector.metric')}:</InputLabel>
                                <Select
                                    value={baseMetricId}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        if (props.onMetricIdChange) {
                                            props.onMetricIdChange(value)
                                        }

                                        setBaseMetricStruct(oldValue => ({
                                            ...oldValue,
                                            id: value ? value : null
                                        }))
                                    }}
                                >
                                    <MenuItem key={-1} value="">&times; Clear color base </MenuItem>
                                    {
                                        availableMetrics?.map((item, mi) => (
                                            <MenuItem key={mi}
                                                      value={item?.metric?.id}>{item?.metric?.meta?.title}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl key='field' className={classes.baseFieldControl}>
                                <InputLabel>{t('widgets.color_selector.field')}:</InputLabel>
                                <Select
                                    value={baseMetricField}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        if (props.onMetricFieldChange) {
                                            props.onMetricFieldChange(value)
                                        }

                                        setBaseMetricStruct(oldValue => ({
                                            ...oldValue,
                                            field: value ? value : null
                                        }))
                                    }}
                                >
                                    <MenuItem key={-1} value="">&times; Clear color base </MenuItem>
                                    {
                                        availableFields?.map((field, fi) => (
                                            <MenuItem key={fi}
                                                      value={field.name}>{field.title}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            <FormControl key='steps' className={classes.colorStepsControl} disabled={isOneColor}>
                                <InputLabel>{t('widgets.color_selector.steps')}:</InputLabel>
                                <Select
                                    value={gradSteps}
                                    onChange={(e) => {
                                        setGradSteps(parseInt(e.target.value))
                                        setGradIndex(0)
                                    }}
                                >
                                    {
                                        isGradAvailable && gradientTableNumbers
                                            .map((num) => (
                                                <MenuItem key={num} value={num}>{num}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>

                            <ColorMetricField
                                key="colors"
                                metric={baseMetric}
                                cube={baseCube}
                                values={colorFilters}
                                onChange={(filter, filterName, filterValue) => {

                                    const renewedFilter = filterValue !== null
                                        ? {...colorFilters, ...filter}
                                        : Object.fromEntries(Object.entries(colorFilters)
                                            .filter(([field, value]) => field !== filterName))

                                    setColorFilters(renewedFilter)
                                    setBaseMetricStruct(oldValue => ({
                                        ...oldValue,
                                        filters: renewedFilter
                                    }))

                                    if (props.onMetricFiltersChange) {
                                        props.onMetricFiltersChange(renewedFilter)
                                    }
                                }}
                            />

                        </div>
                    }

                    {
                        isGradAvailable &&
                        <>
                            <div className={classes.gradSelector} key="gradSelector">
                                {
                                    gradientTable[gradSteps].map((item, i) => {
                                        const gradClass = i === gradIndex ? classes.gradItemSelected : classes.gradItem;
                                        return (
                                            <div className={gradClass} onClick={() => {
                                                setGradIndex(i)
                                            }} key={i}>
                                                <div className={classes.gradItemColor} style={{
                                                    'background': stepPaletteLinearGrad(item)
                                                }}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                    }

                    {
                        isOneColor && !isGradAvailable &&
                        <div className={classes.colorControl} key="colorControl">
                            <Typography gutterBottom>
                                {t('widgets.color_selector.color')}
                            </Typography>

                            <SliderPicker color={color} onChange={(clr) => {

                                setColor(clr.hex)

                            }} className={classes.oneColorControl}/>

                            <div className={classes.grayScaleControl}>
                                {
                                    grayScalePalette.map((grayColor, paletteIndex) => {
                                        const isSelected = grayColor === color
                                        const className = isSelected
                                            ? classes.grayScaleColorPickerSelected
                                            : classes.grayScaleColorPicker

                                        return (
                                            <div className={classes.grayScaleColor} key={paletteIndex}>
                                                <div
                                                    className={className}
                                                    style={{background: grayColor}}
                                                    onClick={() => {
                                                        setColor(grayColor)
                                                    }}
                                                />
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>
                    }

                    {
                        !isOneColor && !!legendTable &&
                        <div className={classes.baseColorControls} key="legendItem">
                            <h5 className={classes.baseColorHeader}>{t('widgets.color_selector.legend')}</h5>
                            <div className={classes.legendTable}>
                                {legendTable?.map((item, i) => (
                                    <div className={classes.legendRow} key={i}>
                                        <div className={classes.legendColor} style={{backgroundColor: item.color}} />
                                        <div className={classes.legendValues}>{
                                            Array.isArray(item.title)
                                                ? item.title.join(' → ')
                                                : item.title
                                        }</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ColorSelector;