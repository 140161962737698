import React, {useEffect, useState} from "react";
import GL from '@luma.gl/constants';
import {
    ScatterplotLayer,
} from '@deck.gl/layers';
import { uuidStringify } from '../../../../../../helpers/uuid'


class LOKKPointLayer extends ScatterplotLayer {
    initializeState(params) {
        super.initializeState(params);
        const attributeManager = this.getAttributeManager();

        attributeManager.addInstanced({
            identity: {size: 16, accessor: 'getIdentity', type: GL.UNSIGNED_BYTE}
        })
    }
    getIdentity() {
        return this.identity
    }
    getPickingInfo(pickParams) {

        const data = super.getPickingInfo(pickParams)

        const identityData = this.getAttributeManager().getAttributes().identity.value
        const identityBytes = identityData.subarray(data.index * 16, data.index * 16 + 16)
        const identity = uuidStringify(identityBytes)

        return {...data, identity}
    }
    shouldUpdateState(params) {
        const {props, oldProps, context, changeFlags} = params
        const result = super.shouldUpdateState(params)
        return result
    }
}


LOKKPointLayer.defaultProps = {
    ...LOKKPointLayer.defaultProps,
    getIdentity: {type: 'accessor', value: d => d.identity}
}

export default LOKKPointLayer;