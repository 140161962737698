import {v4 as uuidV4} from 'uuid'
import {getRandomColor} from '../helpers/color'
import {isDefined} from "../helpers/fn";
import {HEIGHT_DEFAULT_STRUCTURE} from "../helpers/const";

window.uuidV4 = uuidV4

export const DEFAULT_LAYER_TYPE = 'point';
export const DEFAULT_LAYER_OPACITY = 0.2
export const DEFAULT_LAYER_RADIUS = 5

const layerDefaults = {
    isEnabled: true,
    isPublic: false,
    gradSteps: 3,
    gradIndex: 0,
    filled: true,
    stroked: true,
    lineWidth: 1,
    height: HEIGHT_DEFAULT_STRUCTURE,
    strokeColor: '#000000',
    radius: DEFAULT_LAYER_RADIUS,
    opacity: DEFAULT_LAYER_OPACITY,
    layerType: DEFAULT_LAYER_TYPE,
    color: getRandomColor(),
    objectType: null,
    metrics: [],
    colorScheme: 'full',
    colorMetricId: null,
    colorMetricField: null,
    objectIds: [],
    collection: null,
    ordering: 0
}

export const isLayerDefined = (layer) => isDefined([layer?.object, layer?.objectType])

export const LayerModel = (props) => {

    const {object} = props;

    const state = {
        id: uuidV4(),
        ...layerDefaults,
        ...props,
        meta: {
            title: props.title ? props.title : props?.object?.meta?.title
        },
        object,
    }

    const isOnlyStroke = state.colorScheme === 'stroke'

    return {
        ...state,
        isFill: !isOnlyStroke,
        strokeColor: isOnlyStroke ? getRandomColor() : '#000000'
    }
}

export const userActivityLayerId = 'userActivityLayer'
export const userActivityDeckLayers = {
    main: userActivityLayerId,
    icon: 'userActivityLayer-icon',
    label: 'userActivityLayer-label'
}
export const isUserActivityLayer = (layer) => Object.values(userActivityDeckLayers).indexOf(layer?.id) > -1
export const LayerActivityModel = (props) => {
    return {
        id: userActivityLayerId,
        layerType: 'userActivity',
        isEnabled: true,
        deckLayers: userActivityDeckLayers,
        ...props,
    }
}

export const userEditLayerId = 'userEditLayer'
export const LayerEditModel = (props) => {
    return {
        id: userEditLayerId,
        isEnabled: false,
        ...props,
    }
}

export const createActorLayerId = 'actorsModelLayer'
export const LayerActorsModel = (props) => {
    return {
        id: createActorLayerId,
        isEnabled: false,
        agentGroup: null,
        hour: null,
        opacity: 1,
        ...props,
    }
}



const transformObjectType = (objectType) => ({
    colorScheme: objectType.color_scheme,
    geometryType: objectType.geometry_type,
    datasets: [],
    id: objectType.id,
    key: objectType.name,
    meta: objectType.meta,
    metrics_link: [],
    layerTypes: objectType.display,
})

export const addLayerObjectType = (layer, objectType) => {
    return {
        ...layer,
        object: transformObjectType(objectType),
        objectType: transformObjectType(objectType),
    }
}

export const addLayerProps = (layer, props) => ({...layer, ...props})