import {Grid, Switch} from "@material-ui/core";
import SeriesControl from "./series-control";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    IconButton,
} from "@material-ui/core"
import {
    DeleteForever,
    Close
} from "@material-ui/icons"
import {LineSeries, LineSeriesCanvas} from "react-vis";


const useStyles = makeStyles((theme) => ({
    root: {
        width: props => props.width,
        '& .MuiDrawer-paper': {
            width: '100vw'
        }
    },

    title: {
        margin: 0,
        padding: 0
    },

    enableSwitcherPlace: {
        marginRight: -8
    },

    dataValue: {
        color: '#394053',
        width: 50,
        padding: '2px 2px',
        textAlign: 'right'
    }
}));


function SeriesSelector(props) {
    const title = props.title ? props.title : 'Metric'
    const width = props.width ? props.width : 275
    const height = props.height ? props.height : 40

    const { data, stats, value, field, onChange, onDelete } = props;

    const classes = useStyles({
        width,
        height,
    });

    const dataLength = data.length;
    const valueMin = 0
    const valueMax = dataLength ? dataLength - 1 : 0

    const sortedData = data.map(item => item[field.name]).sort((a, b) =>
        a == null ? -1 : (b == null ? 1 : (a > b ? 1 : -1))
    )

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={8}>
                    <h5 className={classes.title}>{title}</h5>
                </Grid>
                <Grid item className={classes.enableSwitcherPlace}>
                    <IconButton size="small" onClick={() => {
                        // if (onDelete) onDelete()
                        const hasData = sortedData && dataLength
                        const directValue = hasData ? [0, dataLength - 1] : [0, 0]
                        const naturalValue = hasData ? [sortedData[0], sortedData[dataLength - 1]] : [0, 0]
                        if (onChange) onChange({
                            value: directValue,
                            naturalValue
                        })
                    }}>
                        {/*<Close fontSize="small" />*/}
                        <small>Clear</small>
                    </IconButton>

                    {/*
                    <Switch
                        defaultChecked={true}
                        value={isEnabled}
                        color="primary"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                        onChange={(e) => {
                            if (onToggle) onToggle(e.target.checked)
                        }}
                    />
*/}

                </Grid>
            </Grid>

            <SeriesControl
                data={sortedData}
                field={field}
                value={value}
                // valueMin={valueMin}
                // valueMax={valueMax}
                stats={stats}
                width={width}
                height={height}
                onChange={(value, naturalValue) => {
                    if (onChange) onChange(value, naturalValue)
                }}
            />
        </div>
    );
}

export default SeriesSelector;