import {authAxios} from "../../../clients";
import {getToken} from "../../auth";
import {ApiEndpoint, appConfig} from "../../../config";

const datasetApi = new ApiEndpoint('dataset')

const apiClient = authAxios({
    auth: {
        access_token: getToken()
    }
});


export const getStructure = (project_id) => {
    const structureUrl = appConfig.isDemo ? `/d//structure?project_id=${project_id}` : `/structure?project_id=${project_id}`
    return apiClient.get(datasetApi.endpoint(structureUrl))
}