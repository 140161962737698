import React, {useEffect, useState} from "react";
import {
    PathLayer,
    SolidPolygonLayer,
} from '@deck.gl/layers';
import {
    CompositeLayer,
} from '@deck.gl/core';
import GL from "@luma.gl/constants";
import {uuidStringify} from "../../../../../../helpers/uuid";
import LOKKPathLayer from './path-layer'
import LOKKObjectLayer from './object-layer'

class LOKKPolygonLayer extends LOKKObjectLayer {

}

LOKKPolygonLayer.defaultProps = {
    ...LOKKObjectLayer.defaultProps,

    _normalize: false,
    _windingOrder: "CCW",

    getElevation: 0,
    elevationScale: 1,
    extruded: false,
}

export default LOKKPolygonLayer;

/*
export default class LOKKPolygonLayer extends CompositeLayer {
    renderLayers() {
        return [
            // new LOKKPathLayer({
            //     id: `${this.props.id}-stroked`,
            //     data: this.props.data,
            //     _normalize: false,
            //     _windingOrder: "CCW",
            //
            //     getWidth: this.props.getWidth ?? 1,
            //     visible: this.props.visible && this.props.stroked,
            //
            //     ...this.props,
            // }),

            new LOKKObjectLayer({
                id: `${this.props.id}-filled`,
                data: this.props.data,
                _normalize: false,
                _windingOrder: "CCW",

                visible: this.props.visible && this.props.filled,

                getElevation: 0,
                elevationScale: 1,
                extruded: false,
                filled: this.props.filled,

                ...this.props,

            }),

        ];
    }
}*/
