import {getDB} from "../../db";
import {isFieldObject, isFieldUUID} from "../../helpers/field";
import {getCubeQuantilesStruct} from "../../helpers/stats";
import {computeColor, getColorQuantile, getVectorColor} from "../../helpers/color";
import {getMetricValueOrFirstSample} from "../../helpers/data";
import {uuidParse} from "../../helpers/uuid";

export default function lineLayerDataFabric({layer, cube, filters, mapDispatch}) {
    return async function getData() {
        const hasFilters = !!filters && filters?.length > 0

        mapDispatch({type: 'setCalculating', isCalculating: true})

        const DB = await getDB();

        const isColorMetric = layer.colorMetricId && layer.colorMetricField
        const colorFilters = layer.colorMetricFilters

        const dataTable = await DB.table(layer.object.key)
        const joinKey = cube?.struct.fields.find(item => item.is_object)
        const joinKeyName = joinKey?.name

        const hasColorMetric = isColorMetric && joinKey

        const metricField = cube?.struct.fields.find(item => item.name === layer.colorMetricField)
        const isMetricFieldDict = metricField && isFieldUUID(metricField) && !isFieldObject(metricField)

        if (!dataTable) {
            return []
        }

        let collection = dataTable.toCollection()

        if (hasColorMetric) {
            collection = collection.joinFields({
                rootKey: 'id',
                tableKey: joinKeyName,
                tableName: cube.name,
                tableFields: {
                    [layer.colorMetricField]: 'metricValue'
                },
                aggregateFunction: isMetricFieldDict ? null : 'avg',
                filters: colorFilters
            })
        }

        if (hasFilters) {
            filters.forEach(filter => {
                collection = collection.filter({
                    rootKey: 'id',
                    tableKey: filter.cube_object,
                    tableName: filter.cube_name,
                    filterFields: filter.fields
                })
            })
        }

// collection.addCondition({
//     fieldName: 'id',
//     condition: '=',
//     value: 'ce55a229-9eaf-f06c-9d3a-edef9035b38b'
// })

 // console.log('query>', collection.buildQuery())

        const t0 = Date.now()
        const result = await DB.run(collection.buildQuery())

        // console.log(`quering: t=${(Date.now() - t0)}, c=${result.numRows}`)

        const geometryColumn = result.getChild('geom')
        const metricValues = hasColorMetric ? result.getChild('metricValue')?.toArray() : null
        const identifiers = result.getChild('id').toArray()
        const identity = result.getChild('id')

        mapDispatch({
            type: 'setLayerCollection',
            layerId: layer.id,
            collection: collection.prepareUnconditionalQuery()
        })

        let offset = 0;
        let indexOffset = 0;
        const batchCount = geometryColumn.data.length

        const quantilesStruct = hasColorMetric
            ? getCubeQuantilesStruct(cube, layer.colorMetricField) : null

        const colorQuantile = quantilesStruct && Array.isArray(layer.strokeColor)
            ? getColorQuantile(layer.strokeColor, quantilesStruct) : null

        let geometryCount = 0
        let pointArrayLength = 0
        for (let bi = 0; bi < batchCount; bi++) {
            const roadIndices = geometryColumn.getChildAt(0).data[bi].valueOffsets
            const pointCount = geometryColumn.getChildAt(0).getChildAt(0).getChildAt(0).getChildAt(0).data[bi].length

            geometryCount += roadIndices.length
            // geometryCount += geometryColumn.getChildAt(0).data[bi].length
            pointArrayLength += pointCount
        }

        const colorArrayLength = pointArrayLength / 2 * 3

        const flatCoordinateArray = new Float32Array(pointArrayLength)
        const resolvedIndices = new Int32Array(geometryCount)
        const colorArray = new Uint8Array(colorArrayLength)
        const identityArray = new Uint8Array(geometryCount * 16)

        let pointIndex = 0
        let resolvedIndex = 0
        let colorIndex = 0
        let identityArrayIndex = 0

        const idOffsets = []

        for (let bi = 0; bi < batchCount; bi++) {
            const roadIndices = geometryColumn.getChildAt(0).data[bi].valueOffsets
            const lineIndices = geometryColumn.getChildAt(0).getChildAt(0).data[bi].valueOffsets
            const lineCount = geometryColumn.getChildAt(0).getChildAt(0).getChildAt(0).data[bi].length
            const flatCoordinateVector = geometryColumn.getChildAt(0).getChildAt(0).getChildAt(0).getChildAt(0)
            const points = flatCoordinateVector.data[bi]
            const pointCount = points.length

            for (let i = 0; i < roadIndices.length; ++i) {
                const objectIndex = i + indexOffset
                const lineIndex = lineIndices[roadIndices[i]]
                resolvedIndices[resolvedIndex++] = lineIndex + offset

                const metricValue = hasColorMetric
                    ? getMetricValueOrFirstSample(metricValues[objectIndex])
                    : null

                const roadColor = computeColor(cube, layer.colorMetricField, metricValue, layer.strokeColor, colorQuantile)

                const nextRingIndex = i + 1 < roadIndices.length
                    ? lineIndices[roadIndices[i + 1]]
                    : lineIndex // lineCount - 1
                const indexDelta = nextRingIndex - lineIndex

                if (indexDelta) {
                    for (let ri = 0; ri < indexDelta; ri++) {
                        roadColor.forEach((colorByte, i) => {
                            colorArray[colorIndex++] = colorByte
                        })
                    }
                }
            }

            for (let i = 0; i < roadIndices.length; ++i) {
                const objectIndex = i + indexOffset

                if (identifiers[objectIndex]) {
                    const byteIdentity = uuidParse(identifiers[objectIndex])
                    for(let ii = 0; ii < byteIdentity.length; ii++) {
                        identityArray[identityArrayIndex++] = byteIdentity[ii]
                    }
                } else {
                    for(let ii = 0; ii < 16; ii++) {
                        identityArray[identityArrayIndex++] = 0
                    }
                }
            }

            for (let vi = 0; vi < pointCount; vi++) {
                flatCoordinateArray[pointIndex++] = points.values[vi]
            }

            offset = resolvedIndices[resolvedIndex - 1]
            indexOffset += geometryColumn.data[bi].length

            idOffsets.push(indexOffset)
        }

        mapDispatch({type: 'setCalculating', isCalculating: false})

        return {
            length: geometryCount,
            startIndices: resolvedIndices,
            positions: {value: flatCoordinateArray, size: 2},
            holeIndices: [],
            attributes: {
                getPath: {value: flatCoordinateArray, size: 2},
                getColor: {value: colorArray, size: 3},
                getIdentity: {value: identityArray, size: 16},
            }
        }
    }
}
