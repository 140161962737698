import React, {useEffect, useReducer, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem, Grid,
} from '@material-ui/core'

import {isFieldUUID, isFieldObject, isFieldTimestamp, normalizeDate} from '../../../../../../helpers/field'
import {DuckDBClient, getDB} from '../../../../../../db'
import {getAnyValue} from '../../../../../../helpers/repr'
import {useTranslation} from "react-i18next";
import {isScalarType} from "graphql";
import {initialMapState, mapStateReducer} from "../../../reducer";
import {MetricFilters} from "./filter";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 16px'
    },

    commonStatistics: {

    },

    commonStatisticsTitle: {

    },

    filteringByTitle: {
        marginTop: 0,
        marginBottom: 0
    },

    statValues: {

    },

    valueItem: {
        fontSize: '21px',
        color: '#222222'
    },

    valueTitle: {
        display: 'inline-block',
        width: '140px'
    },

    valueValue: {
        display: 'inline-block',
        width: '140px',
        textAlign: 'right'
    },

    filterControl: {
        width: 280,
        marginRight: '16px',
    },

    filterSelector: {
        width: '100%'
    }
}))


const RESET_COMMMON_STATISTICS = {
    count: 0,
    namedCount: 0,
    userCount: 0,
}
export function CommonStatistics(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {metric} = props;
    const [mapState, mapDispatch] = useReducer(mapStateReducer, initialMapState)

    const [commonStatistics, setCommonStatistics] = useState(RESET_COMMMON_STATISTICS)

    const [filters, setFilters] = useState({})

    const loadCommonStatistics = async (metric) => {
        const db = await getDB()
        const objectKey = metric?.object?.key

        // todo: useful only with objects as it is, but not with metric
        // const rudeDegreeKm = 111.3
        // (count(*) / ( abs(max(lng) - min(lng)) * ${rudeDegreeKm} * abs(max(lat) - min(lat)) * ${rudeDegreeKm} )) as avg_density

        const filterConditions = Object.entries(filters)
            .map(([filterName, filterValue]) => (`${filterName}='${filterValue}'`))

        const whereClause = filterConditions.length > 0 ? `where ${filterConditions.join(' and ')}` : ''

        const query = `
            select
                count(distinct ${objectKey}) as cnt,
                count(distinct ${objectKey}) FILTER (where t.meta is not null) as named_cnt,               
                count(distinct ${objectKey}) FILTER (where user_id is not null) as user_cnt
            from ${DuckDBClient.project_schema}."${metric.cube.name}" c
            left join ${DuckDBClient.project_schema}."${objectKey}" as t on t.id = c.${objectKey}
            ${whereClause}
        `

        const result = await db.run(query)
        const stats = result.get(0)
        return {
            count: parseInt(stats.cnt),
            namedCount: parseInt(stats.named_cnt),
            userCount: parseInt(stats.user_cnt),
        }
    }

    useEffect(() => {

        if (!metric) return

        mapDispatch({
            type: 'setCalculating',
            isCalculating: true
        })

        loadCommonStatistics(metric).then((data) => {
            setCommonStatistics(data)

            mapDispatch({
                type: 'setCalculating',
                isCalculating: false
            })
        }).catch((e) => {
            mapDispatch({
                type: 'setCalculating',
                isCalculating: false
            })
            console.log('E_t', e)
        })

    }, [metric, filters])

    useEffect(() => {
        setFilters({})
        setCommonStatistics(RESET_COMMMON_STATISTICS)
    }, [metric])

    return (
        <div className={classes.commonStatistics} >
            <h2 className={classes.commonStatisticsTitle}>Common statistics</h2>
            <div className={classes.statValues}>
                <p className={classes.valueItem}><span className={classes.valueTitle}>Total:</span> <span className={classes.valueValue}>{commonStatistics.count}</span></p>
                <p className={classes.valueItem}><span className={classes.valueTitle}>Named:</span> <span className={classes.valueValue}>{commonStatistics.namedCount}</span></p>
                <p className={classes.valueItem}><span className={classes.valueTitle}>User-created:</span> <span className={classes.valueValue}>{commonStatistics.userCount}</span></p>
            </div>

            <MetricFilters metric={metric} onChange={(filters) => {
                setFilters(filters)
            }} />

        </div>
    )
}

export default function MetricStatistics(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {metric} = props;
    // const [mapState, mapDispatch] = useReducer(mapStateReducer, initialMapState)

    return (
        <div className={classes.root}>
            <CommonStatistics metric={metric} />
        </div>
    )
}