import React, {useEffect, useReducer, Suspense} from 'react';
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import {CssBaseline} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack'

import {I18nextProvider} from 'react-i18next'

import {lokkTheme} from './styles/theme'

import reducers from "./reducers";
import {checkAuth} from './actions/auth'

import './App.css';
import AppRouter from './app-router'
import {
    AuthStateContext, authStateReducer, initialAuthState,
    popupStateReducer, initialPopupState, PopupStateContext,
} from "./reducers";
import PopupModals from "./components/popup";
import LoadMe from "./components/load-me";
import i18n from './i18n'


function App() {
    const store = createStore(reducers, applyMiddleware(thunk));
    const [authState, authDispatch] = useReducer(authStateReducer, initialAuthState)
    const [popupState, popupDispatch] = useReducer(popupStateReducer, initialPopupState)

    React.useEffect(() => {
        checkAuth()
    }, [])

    return (
        <Provider {...{store}}>
            <ThemeProvider theme={lokkTheme}>
                <AuthStateContext.Provider value={{authState, authDispatch}}>
                    <PopupStateContext.Provider value={{popupState, popupDispatch}}>
                        <SnackbarProvider maxSnack={8}>
                            <CssBaseline/>
                            <AppRouter/>
                            <LoadMe/>
                            <PopupModals/>
                        </SnackbarProvider>
                    </PopupStateContext.Provider>
                </AuthStateContext.Provider>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
