import {getDB} from "../../db";
import {isFieldObject, isFieldUUID} from "../../helpers/field";
import {getCubeQuantilesStruct} from "../../helpers/stats";
import {computeColor, getColorQuantile} from "../../helpers/color";
import {getMetricValueOrFirstSample} from "../../helpers/data";
import {DEFAULT_HEIGHT} from "../../helpers/const";
import {uuidParse} from "../../helpers/uuid";

export default function objectLayerDataFabric({layer, cube, filters, heightCube, mapDispatch}) {
    return async function getData() {
        const hasFilters = !!filters && filters?.length > 0

        mapDispatch({type: 'setCalculating', isCalculating: true})

        const DB = await getDB();

        const isColorMetric = layer.colorMetricId && layer.colorMetricField
        const colorFilters = layer.colorMetricFilters
        const isHeightMetric = layer.height?.metricId && layer.height?.field
        const heightJoinKey = heightCube?.struct.fields.find(item => item.is_object)
        const heightJoinKeyName = heightJoinKey?.name

        const dataTable = await DB.table(layer.object.key)
        const joinKey = cube?.struct.fields.find(item => item.is_object)
        const joinKeyName = joinKey?.name

        const hasColorMetric = isColorMetric && joinKey
        const hasHeightMetric = isHeightMetric && heightJoinKey

        const metricField = cube?.struct.fields.find(item => item.name === layer.colorMetricField)
        const isMetricFieldDict = metricField && isFieldUUID(metricField) && !isFieldObject(metricField)

        if (!dataTable) {
            return []
        }

        let collection = dataTable.toCollection()

        if (hasColorMetric) {
            collection = collection.joinFields({
                rootKey: 'id',
                tableKey: joinKeyName,
                tableName: cube.name,
                tableFields: {
                    [layer.colorMetricField]: 'metricValue'
                },
                aggregateFunction: isMetricFieldDict ? null : 'avg',
                filters: colorFilters
            })
        }

        if (hasHeightMetric) {
            collection = collection.joinFields({
                rootKey: 'id',
                tableKey: heightJoinKeyName,
                tableName: heightCube.name,
                tableFields: {
                    [layer.height?.field]: 'heightMetricValue'
                },
                aggregateFunction: 'avg',
            })
        }

        if (hasFilters) {
            filters.forEach(filter => {
                collection = collection.filter({
                    rootKey: 'id',
                    tableKey: filter.cube_object,
                    tableName: filter.cube_name,
                    filterFields: filter.fields
                })
            })
        }

        const t0 = Date.now()
        const result = await DB.run(collection.buildQuery())

        // console.log(`quering: t=${(Date.now() - t0)}, c=${result.numRows}`)

        const geometryColumn = result.getChild('geom')
        const elevationColumn = hasHeightMetric ? result.getChild('heightMetricValue') : null
        const elevationValues = elevationColumn?.toArray()
        const metricValues = hasColorMetric ? result.getChild('metricValue')?.toArray() : null
        const identifiers = result.getChild('id').toArray()

        mapDispatch({
            type: 'setLayerCollection',
            layerId: layer.id,
            collection: collection.prepareUnconditionalQuery()
        })

        let offset = 0;
        let indexOffset = 0;
        const batchCount = geometryColumn.data.length

        const quantilesStruct = hasColorMetric
            ? getCubeQuantilesStruct(cube, layer.colorMetricField) : null

        const colorQuantile = quantilesStruct && Array.isArray(layer.color)
            ? getColorQuantile(layer.color, quantilesStruct) : null

        let geometryCount = 0
        let pointArrayLength = 0
        for (let bi = 0; bi < batchCount; bi++) {
            const polygonIndices = geometryColumn.getChildAt(0).data[bi].valueOffsets
            const pointCount = geometryColumn.getChildAt(0).getChildAt(0).getChildAt(0).getChildAt(0).data[bi].length

            geometryCount += polygonIndices.length
            pointArrayLength += pointCount
        }

        const colorArrayLength = pointArrayLength / 2 * 3
        const elevationArrayLength = pointArrayLength / 2

        const flatCoordinateArray = new Float32Array(pointArrayLength)
        const resolvedIndices = new Int32Array(geometryCount)
        const elevationArray = new Float32Array(elevationArrayLength);
        const colorArray = new Uint8Array(colorArrayLength)
        const identityArray = new Uint8Array(geometryCount * 16)

        let identityIndex = 0
        let pointIndex = 0
        let resolvedIndex = 0
        let colorIndex = 0
        let elevationIndex = 0
        let identityArrayIndex = 0
        let maxHeight = {
            value: 0,
            index: 0,
            id: ''
        }
        for (let bi = 0; bi < batchCount; bi++) {
            const polygonIndices = geometryColumn.getChildAt(0).data[bi].valueOffsets
            const ringIndices = geometryColumn.getChildAt(0).getChildAt(0).data[bi].valueOffsets
            const ringCount = geometryColumn.getChildAt(0).getChildAt(0).getChildAt(0).data[bi].length
            const flatCoordinateVector = geometryColumn.getChildAt(0).getChildAt(0).getChildAt(0).getChildAt(0)
            const points = flatCoordinateVector.data[bi]
            const pointCount = points.length

            // const elevationVector = hasHeightMetric ? elevationColumn.data[bi].values : null

            for (let i = 0; i < polygonIndices.length; ++i) {
                const objectIndex = i + indexOffset
                const ringIndex = ringIndices[polygonIndices[i]]
                resolvedIndices[resolvedIndex++] = ringIndex + offset

                const elevationValue = hasHeightMetric && elevationValues[objectIndex] && !Number.isNaN(elevationValues[objectIndex])
                    ? elevationValues[objectIndex]
                    : (layer.height?.value ?? DEFAULT_HEIGHT)

                if (elevationValue > maxHeight.value) maxHeight = {
                    value: elevationValue,
                    index: objectIndex,
                    id: identifiers[objectIndex]
                }

                const metricValue = hasColorMetric
                    ? getMetricValueOrFirstSample(metricValues[objectIndex])
                    : null

                const objectColor = computeColor(cube, layer.colorMetricField, metricValue, layer.color, colorQuantile)

                if (identifiers[objectIndex]) {
                    const byteIdentity = uuidParse(identifiers[objectIndex])
                    for(let ii = 0; ii < byteIdentity.length; ii++) {
                        identityArray[identityArrayIndex++] = byteIdentity[ii]
                    }
                } else {
                    for(let ii = 0; ii < 16; ii++) {
                        identityArray[identityArrayIndex++] = 0
                    }
                }

                const nextRingIndex = i + 1 < polygonIndices.length
                    ? ringIndices[polygonIndices[i + 1]]
                    : ringIndex // ringPointsCount - 1
                const indexDelta = nextRingIndex - ringIndex

                if (indexDelta) {
                    for (let ri = 0; ri < indexDelta; ri++) {
                        objectColor.forEach((colorByte, i) => {
                            colorArray[colorIndex++] = colorByte
                        })
                        elevationArray[elevationIndex++] = elevationValue

                        // elevationArray[elevationIndex++] = hasHeightMetric
                        //     ? (elevationVector[ringIndex] ?? DEFAULT_HEIGHT)
                        //     : (layer.height?.value ?? DEFAULT_HEIGHT)
                    }
                }
            }

            for (let vi = 0; vi < pointCount; vi++) {
                flatCoordinateArray[pointIndex++] = points.values[vi]
            }

            offset = resolvedIndices[resolvedIndex - 1]
            indexOffset += geometryColumn.data[bi].length
        }

        mapDispatch({type: 'setCalculating', isCalculating: false})

        return {
            length: geometryCount,
            startIndices: resolvedIndices,
            attributes: {
                getPolygon: {value: flatCoordinateArray, size: 2},
                getFillColor: {value: colorArray, size: 3},
                getElevation: {value: elevationArray, size: 1},
                getIdentity: {value: identityArray, size: 16}
            }
        }
    }
}
