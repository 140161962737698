import React, {useContext, useEffect, useState} from "react";
import { useQuery } from "@apollo/client";

import {
    IconButton,
    Paper,
    SwipeableDrawer,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tabs, Tab, Box,

    useMediaQuery
} from "@material-ui/core";

import {BarChart, ChevronRightRounded} from "@material-ui/icons";
import css from 'styled-components'

import apolloClient from "../../../../../../clients/apolloClient";
import {GetProjectDataviews} from "../../../../../../actions/app/gql/dataview";
import { MapStateContext } from "../../../reducer";


import ItemBarItemTable from './itembar-item'
import { makeStyles } from "@material-ui/core/styles";
import DataBarItemTable from "../databar/databar-item";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const DataBarHeader = css.div`
    min-width: 680px;
    width: auto;
    box-sizing: border-box;
    text-align: right;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 40px',
        overflow: 'hidden',
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
    },
    databarPanel: {
        'width': '100vw',
        '& .MuiDrawer-paper': {
            width: '50vw',
            [theme.breakpoints.down('sm')]: {
                width: '100vw'
            }
        }
    },
}));


const ItemBar = function (props) {
    const classes = useStyles();

    const { viewState } = props;
    const {mapState, mapDispatch} = useContext(MapStateContext)

    const { dataBar, itemBarOpened } = mapState
    const { item: dataItem, visible: panelIsOpened } = dataBar

    const hasItem = dataItem !== null;

    const [currentTab, setCurrentTab] = useState(hasItem ? 1 : 0);

    const handlePanelOpened = (visible) => {
        mapDispatch({
            type: 'toggleDataBar',
            visible
        })
    }

    useEffect(() => {
        if (dataItem) {
            setCurrentTab(1)
        }
    }, [mapState.dataBar.item])

    const { projectId } = mapState;

    const { data: projectDataviews, loading: projectDVLoading, projectDVError } = useQuery(GetProjectDataviews, {
        client: apolloClient,
        variables: {
            project_id: projectId
        },
    });

    useEffect(() => {
        if (projectDataviews) {
            mapDispatch({
                type: 'setDataviews',
                dataviews: projectDataviews.project_dataviews
            })
        }
    }, [projectDataviews])

    const { itemview, itemBarItemId, itemViewState } = mapState;

    return (
        <React.Fragment>
            <SwipeableDrawer
                anchor='right'
                open={itemBarOpened}
                className={classes.databarPanel}
                onOpen={() => {}}
                onClose={() => {}}
            >
                <DataBarHeader>
                    <IconButton onClick={() => mapDispatch({type: 'closeItemBar'})}>
                        <ChevronRightRounded />
                    </IconButton>
                </DataBarHeader>

                <ItemBarItemTable
                    itemId={itemBarItemId}
                    itemview={ itemview }
                    itemViewState={itemViewState}
                />

            </SwipeableDrawer>

        </React.Fragment>
    )
}

export default ItemBar;
