import React from 'react';
import PropTypes from 'prop-types';
import { withStyles,  } from '@material-ui/core/styles';

import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { animated, useSpring } from '@react-spring/web'

function TransitionComponent(props) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

export const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    selected: {
        '& > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
        }
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed rgba(255, 255, 255, 0.4)`,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

export const LiteraTreeItem = withStyles((theme) => ({
    root: {
        margin: '8px 0'
    },
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
        width: theme.spacing(4)
    },
    label: {
        fontSize: '1em'
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed rgba(255, 255, 255, 0.4)`,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);
