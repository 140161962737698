import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {FlyToInterpolator} from 'deck.gl';
import DeckGL from '@deck.gl/react';
import { MapView, View } from '@deck.gl/core';
import { IconLayer, GeoJsonLayer } from '@deck.gl/layers';
import { geoByPoint, geocodeSearch }  from '../../../../actions/geocoder'
import Geocoding from '@mapbox/mapbox-sdk/services/geocoding'
import {
    Paper,
    IconButton,
    InputBase,
    Divider,
    TextField,
    CircularProgress
} from '@material-ui/core';

import {
    Search,
    Directions,

} from '@material-ui/icons'
import StaticMap from "react-map-gl";
import { appConfig } from "../../../../config";
import { getBoundsZoomLevel } from '../../../../helpers/geom'
import Loader from "react-loader-spinner";
import {useTranslation} from "react-i18next";

const ICON_MAPPING = {
    marker: {x: 0, y: 0, width: 128, height: 128, mask: true}
};

const useStyles = makeStyles((theme) => ({
    root: {

    },

    geocoder: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: 400,
        background: '#D9DFE4'
    },

    geocoderInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    geocoderIconButton: {
        padding: 10,
    },
    geocoderDivider: {
        height: 28,
        margin: 4,
    },


    geocoderRow: {
        marginBottom: theme.spacing(2),
    },

    mapView: {
        position: 'relative',
        width: '100%',
        minHeight: '400px'
    },
}));


const INITIAL_VIEW_STATE = {
    longitude: 10.4541194,
    latitude: 51.1642292,
    zoom: 6,
    pitch: 0,
    bearing: 0,
    width: window.innerWidth,
    height: window.innerHeight
};


let searchTimeout = null;
export default function LocationStep(props) {
    const classes = useStyles();
    const { projectData, onChange } = props
    const { project: { scale }, geom: { coordinates } } = projectData;
    const { t } = useTranslation();

    const views = [
        new MapView({id: 'map', width: '100%', controller: true}),
        // new FirstPersonView({width: '50%', x: '50%', fovy: 50})
    ];

    const layer = new IconLayer({
        id: 'icon-layer',
        data: [{
            coordinates
        }],
        pickable: true,
        // iconAtlas and iconMapping are required
        // getIcon: return a string
        iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
        iconMapping: ICON_MAPPING,
        getIcon: d => 'marker',

        sizeScale: 15,
        getPosition: d => d.coordinates,
        getSize: d => 5,
        getColor: d => [50, 140, 0],
        opacity: 0.6
    });

    const mapRef = React.useRef();

    const [open, setOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [mapLayers, setMapLayers] = React.useState([])
    const [mapInitialState, setMapInitialState] = React.useState(INITIAL_VIEW_STATE)

    React.useEffect(() => {

    }, [options])

    const doSearch = (search) => {
        return geocodeSearch(search)
            .then(response => {
                response && setOptions(response.data?.search)
            })
    }

    const flyMeTo = (longitude, latitude, zoom = 14) => {

        if (!latitude || !longitude || !zoom)
            return

        setMapInitialState({
            longitude,
            latitude,
            zoom,
            pitch: INITIAL_VIEW_STATE.pitch,
            bearing: INITIAL_VIEW_STATE.bearing,
            transitionDuration: 1000,
            transitionInterpolator: new FlyToInterpolator()
        })
    }

    const prepareSearch = (e) => {
        if (searchTimeout) clearTimeout(searchTimeout)

        const value = e.target.value;
        setSearchValue(value);

        searchTimeout = setTimeout(() => {
            setIsLoading(true);
            setOpen(true);

            doSearch(value)
                .then(() => setIsLoading(false));
        }, 700);
    }

    React.useEffect(() => {
        if (!selectedOption)
            return;

        setMapLayers([
            new GeoJsonLayer({
                id: 'geojson-layer',
                data: selectedOption.geom,
                pickable: false,
                stroked: true,
                filled: true,
                // extruded: true,
                pointType: 'circle',
                lineWidthScale: 20,
                lineWidthMinPixels: 4,
                getFillColor: [50, 140, 0],
                getLineColor: [50, 140, 0],
                getPointRadius: 4,
                pointRadiusUnits: 'pixels',
                getLineWidth: 4,
                getElevation: 30,
                opacity: 0.9
            })
        ])

    }, [selectedOption])

    return (
        <div className={classes.root}>
            <div className={classes.geocoderRow}>
                <Paper component="outlined" className={classes.geocoder}>
{/*

                    <InputBase
                        className={classes.geocoderInput}
                        placeholder="Search location"
                        inputProps={{ 'aria-label': 'search location' }}
                        onChange={prepareSearch}
                    />

*/}

                    <Autocomplete
                        id="asynchronous-demo"
                        style={{ width: 350 }}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        autoSelect={true}
                        selectOnFocus={true}
                        filterOptions={(options, state) => options}
                        // freeSolo={true}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.title}
                        options={options}
                        loading={isLoading}
                        onChange={(e, value) => {
                            setSelectedOption(value);

                            const mapInstance = mapRef.current.getMap()

                            const zoom = getBoundsZoomLevel(value?.bbox?.coordinates[0], {
                                width: mapInstance?.transform.width,
                                height: mapInstance?.transform.height
                            })
                            flyMeTo(value?.lng, value?.lat, zoom)

                            onChange({
                                project: {
                                    scale: zoom + 2
                                },
                                geom: value?.geom
                            })

                            // renderOptionOnMap(value);
                        }}
                        value={selectedOption ? selectedOption.value : undefined}
                        onHighlightChange={(e, option) => {
                            setSelectedOption(option)
                        }}

/*                        renderOption={(option => (
                            <div
                                key={option.id}
                                onClick={renderOptionOnMap(option)}
                            >
                                {option.title}
                            </div>
                        ))}*/

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('project_new.location_step.search')}
                                variant="outlined"
                                onChange={prepareSearch}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />

{/*
                    <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={top100Films._map((option) => option.title)}
                        renderInput={(params) => <TextField {...params} label="freeSolo" />}
                    />
*/}

                    <Divider className={classes.geocoderDivider} orientation="vertical" />
                    <IconButton
                        color="primary" className={classes.geocoderIconButton} aria-label="directions"
                        onClick={() => {
                            const value = selectedOption;

                            if (!value)
                                return

                            const zoom = getBoundsZoomLevel(value?.bbox?.coordinates[0], {
                                width: mapRef.current.props.width,
                                height: mapRef.current.props.height
                            })
                            flyMeTo(value?.lng, value?.lat, zoom)

                            onChange({
                                project: {
                                    scale: zoom
                                },
                                geom: value?.geom
                            })

                        }}
                    >
                        <Directions />
                    </IconButton>
                </Paper>
            </div>

            <div className={classes.mapView}>
                <DeckGL
                    initialViewState={mapInitialState}
                    controller={true}
                    layers={mapLayers}
                    onViewStateChange={(data) => {

                    }}
                    onDragStart={() => {

                    }}
                    onDragEnd={(data) => {

                    }}
                    onResize={(data) => {
                        // console.log(data)
                    }}
                    onClick={(data) => {
                        // const geocodingClient = Geocoding({
                        //     accessToken: appConfig.mapbox.token
                        // })
                        //
                        // geocodingClient.reverseGeocode({
                        //     query: data.coordinate
                        // }).send().then(response => {
                        //     console.log(response)
                        // })
                        //
                        // geoByPoint(data.coordinate).then(data => {
                        //     console.log('D>',data)
                        // })

                        // onChange({
                        //     geom: {
                        //         type: 'Point',
                        //         coordinates: data.coordinate
                        //     }
                        // })
                    }}
                    views={views}
                    >
                    <StaticMap
                        reuseMaps
                        mapStyle={appConfig.mapbox.style.dark}
                        mapboxAccessToken={appConfig.mapbox.token}
                        preventStyleDiffing={true}
                        ref={mapRef}
                    />

                    <Loader
                        style={{position: 'fixed', left: '50vw', top: '50vh', transform: 'translate(-50%, -50%)'}}
                        visible={isLoading}
                        type="Oval"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        timeout={10000}
                    />

                </DeckGL>

            </div>
        </div>
    );
}