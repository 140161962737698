import { mapStyleBlack } from "./black";
import { mapStyleWhite } from "./white";


export const getMapStyle = (theme) => {
    if (theme === 'satellite') return 'mapbox://styles/mapbox/satellite-v9'
    if (theme === 'white') return mapStyleWhite
    return mapStyleBlack
}

export const getGroupedMapOptions = (theme) => theme === 'satellite' ? {} : getMapStyle(theme).layers.reduce((acc, cur) => {
    const mapboxGroup = cur['metadata']['mapbox:group'];
    const [group, option] = mapboxGroup.split(',')
    return {
        ...acc,
        [group]: {
            ...(acc[group]? acc[group] : {}),
            [option.trim().replace('-', ' ')]: {
                id: mapboxGroup
            }
        }
    }
}, {})


export const getMapOptions = (theme) => theme === 'satellite' ? {} : getMapStyle(theme).layers.reduce((acc, cur) => {
    const mapboxGroup = cur['metadata']['mapbox:group'];
    return {
        ...acc,
        [mapboxGroup]: true
    }
}, {})
