import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        // fill: 'currentColor'
        stroke: 'currentColor', // #4E4F54
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.1rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none'
    }
}));

const PolygonIcon = (props) => {
    const classes = useStyles();

    return(
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"
             {...props}
             className={classes.root}
        >
            <path d="M0.5 4.93601L2.14766 0.5H8.5V8.5H0.5V4.93601Z" />
        </svg>
    )
};

export default PolygonIcon;