import {useTranslation} from "react-i18next";
import {isFieldObject, isFieldScalar, isFieldTimestamp, isFieldUUID} from "../../../../../../helpers/field";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {gradientTable} from "../../../../../../helpers/color";
import {DuckDBClient, getDB} from "../../../../../../db";
import {HorizontalGridLines, VerticalBarSeries, VerticalGridLines, XAxis, XYPlot, YAxis} from "react-vis";
import LegendTable from "../../../../../../components/legend-table";
import {MetricAggregate, MetricFilters} from "./filter";
import {chartWidthFormula, windowMaxChartWidth} from "./helper";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 16px'
    },

    barChart: {
        marginTop: '80px',
        '&:first-child': {
            marginTop: 0,
        }
    }

}))


function MetricBarChart(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        metric,
        field,
        dict
    } = props

    const colorDomain = gradientTable[20][1]
    const scalarFields = metric?.cube?.struct?.fields?.filter(item => isFieldScalar(item))

    const [aggregate, setAggregate] = useState('count')
    const [chartData, setChartData] = useState([])

    const [filters, setFilters] = useState({})
    const [chartWidth, setChartWidth] = useState(windowMaxChartWidth())

    const loadData = async (filters, aggregateFields) => {
        const db = await getDB()
        const objectKey = metric?.object?.key

        const filterConditions = Object.entries(filters)
            .filter(([filterName, filterValue]) => (filterValue !== undefined && filterValue !== null && (!Array.isArray(filterValue) || filterValue.length > 0)))
            .map(([filterName, filterValue]) => {
                if (Array.isArray(filterValue)) {
                    if (filterValue.length === 2 && filterValue[0] === filterValue[1]) {
                        return `${filterName} = '${filterValue[0]}'`
                    } else if (filterValue.length === 2 && filterValue[0] !== filterValue[1]) {
                        return `${filterName} between '${filterValue[0]}' and '${filterValue[1]}'`
                    } else {
                        return `${filterName} in (${filterValue.map(val => `'${val}'`).join(', ')})`
                    }
                }

                return `${filterName}='${filterValue}'`
            })
        const whereClause = filterConditions.length > 0 ? `where ${filterConditions.join(' and ')}` : ''

        const query = `
            select
                ${field.name} as field,
                ${aggregateFields.map(field => `${aggregate}(${field}) as ${field}`).join(', ')}                
            from ${DuckDBClient.project_schema}."${metric.cube.name}" c
            ${whereClause}
            group by ${field.name}
            order by field
        `
        const result = await db.run(query)
        return result.toArray().map(Object.fromEntries)
    }

    useEffect(() => {
        if (!metric) return

        const aggregateFields = scalarFields.map(item => item.name)
        loadData(filters, aggregateFields).then(data => {
            const summary = data.reduce((acc, item) => (acc + parseFloat(item.value)), 0)
            setChartData(data)
        }).catch((e) => console.log('E_c>', e))
    }, [metric, filters, aggregate])

    useEffect(() => {
        setChartWidth(chartWidthFormula(chartData))
    }, [chartData])
    window.onresize = () => setChartWidth(chartWidthFormula(chartData))

    return (
        <section className={classes.barChart}>
            <h2>{field.title}</h2>

            <section>
                <XYPlot width={chartWidth} height={300}
                        xType="ordinal"
                        stackBy="y"
                        animation={"gentle"}
                        xDistance={100}
                >
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <XAxis title={field.name} style={{
                        fontSize: '10px',
                    }} />
                    <YAxis title={aggregate.toString()} style={{
                        fontSize: '10px'
                    }} />
                    {scalarFields.map((scalarField, i) => (
                        <VerticalBarSeries color={colorDomain[i%colorDomain.length]} key={scalarField.name} data={chartData.map(row => ({
                            x: dict[row.field],
                            y: parseFloat(row[scalarField.name])
                        }))} />
                    ))}
                </XYPlot>
            </section>

            <LegendTable
                title={`${field.title} legend`}
                table={scalarFields.map((scalarField, i) => ({
                    title: scalarField.title,
                    color: colorDomain[i%colorDomain.length]
                }))}
            />

            <MetricAggregate
                value={aggregate}
                onChange={(value) => setAggregate(value)}
                except={['min']}
            />

            <MetricFilters
                metric={metric}
                field={field}
                onChange={(filters) => {setFilters(filters)}}
                hideTimeSeries={isFieldTimestamp(field)}
            />

        </section>

    )
}


export default function MetricBarCharts(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {metric} = props;

    const metricDicts = metric.cube.dicts.reduce((acc, item) => ({
        ...acc,
        [item.field]: item.values.reduce((valAcc, valItem) => ({...valAcc, [valItem.id]: valItem.title}), {})
    }), {})
    const dictFields = metric?.cube?.struct?.fields?.filter(item => isFieldUUID(item) && !isFieldObject(item))

    return (
        <div className={classes.root}>
            {dictFields && dictFields.map((field) => (
                <MetricBarChart metric={metric} field={field} dict={metricDicts[field.name]} />
            ))}

        </div>
    )
}