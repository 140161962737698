import { gql } from "@apollo/client";

export const ProjectCreate = gql`
    mutation ProjectCreate ($project: CreateProjectModel!, $geom: GeomModel!) {
        projectCreate(
            project: $project,
            geom: $geom
        ) {
            project_id
            project_key
        }
    }
`;

export const SetProjectCurrentStep = gql`
    mutation SetProjectCurrentStep ($project_id: uuid!, $current_step_id: uuid!) {
        updateProject(pk_columns: {id: $project_id}, _set: {current_step_id: $current_step_id}) {
            current_step_id
            currentStep {
                datasets
                dataset_items
                dataviews
                layers
                filters
                metrics
                algorithm
            }
        }
    }
`

export const ProjectQuery = gql`
    query GetProject ($project_id: uuid!) {
        appProject(id: $project_id) {
            id
            meta {
                title
                about
            }
            scale_type
            geom
            centroid
            projectState {
                data
                state
                style
            }
        }
    }
`;

export const CreateProjectState = gql`
    mutation CreateProjectState($project_id: uuid!, $data: jsonb, $state: jsonb, $style: jsonb) {
        createProjectState(object: {
            data: $data,
            state: $state,
            style: $style,
            project_id: $project_id,
            is_public: false
        }, on_conflict: {
            constraint: project_state_sat_project_id_user_id_ver_key,
            update_columns: [state, style, data]
        }) {
            ver
        }
    }
`


export const UpdateProjectState = gql`
    mutation UpdateProjectState($project_id: uuid!, $data: jsonb, $state: jsonb, $style: jsonb, $ver: Int, $user_id: uuid) {
        updateProjectStates(
            where: {project_id: {_eq: $project_id}, ver: {_eq: $ver}, user_id: {_eq: $user_id}, is_public: {_eq: false}},
            _set: {
                state: $state, 
                style: $style, 
                data: $data}
        ) {
            returning {
                ver
            }
        }
    }
`

export const ProjectDelete = gql`
    mutation ProjectDelete ($project_id: String!) {
        projectDelete(project_id: $project_id) {
            project_id
        }
    }
`
