import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Grid, Slider, Tooltip} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {AreaSeries, Borders, GradientDefs, LineSeries, LineSeriesCanvas, XYPlot} from "react-vis";
import PropTypes from "prop-types";
import {getValueRepr} from "../../../../../../../helpers/repr";


function ValueLabelComponent(props) {
    const {children, open, value} = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};


function DataSliderThumbComponent(props) {
    return (
        <div {...props}>
            <div className="pointer">
                <span className="bar"/>
                <span className="bar"/>
                <span className="bar"/>
            </div>
        </div>
    );
}


const SeriesSelectorDefaults = {
    width: 280,
    height: 80,
    pointerWidth: 8,
    pointerHeight: 20,
}


const useStylesSelector = makeStyles((theme) => ({
    root: {
        position: 'relative',
        background: 'transparent',
        overflow: 'hidden',
        width: props => props.widgetWidth
    },
}));


const DataSlider = withStyles({
    root: {
        position: 'absolute',
        top: props => props.widgetHeight / 2,
        left: 0,
        width: props => props.widgetWidth,
        height: 3,
        color: '#3a8589',
        padding: 0,
    },
    thumb: {
        height: props => props.widgetHeight,
        background: '#F5FAFF',
        borderRadius: 0,
        opacity: 0.85,
        marginTop: props => -props.widgetHeight / 2,

        '&:nth-child(odd)': {
            width: props => props.widgetWidth + 20,
            marginLeft: -1,
            justifyContent: 'flex-start',
            boxShadow: 'none',
            // boxShadow: '#ebebeb 0 2px 2px',
            '& .pointer': {
                left: props => -props.pointerWidth,
            }
        },

        '&:nth-child(even)': {
            width: props => props.widgetWidth + 20,
            marginLeft: props => -(props.widgetWidth + 20),
            justifyContent: 'flex-end',
            boxShadow: 'none',
            // boxShadow: '#ebebeb 0 2px 2px',
            '& .pointer': {
                left: props => props.pointerWidth
            }
        },

        '&:focus, &:hover, &$active': {
            boxShadow: 'none',
            // boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .pointer': {
            position: 'relative',
            width: props => props.pointerWidth,
            height: props => props.pointerHeight,
            background: '#29323C',
            '& .bar': {
                // display: inline-block !important;
                height: props => props.pointerHeight,
                width: 1,
                backgroundColor: '#000',
                marginLeft: 1,
                marginRight: 1,
            },
        },
    },
    input: {
        width: 50,
    },
    active: {},
    track: {
        height: 3,
        opacity: 0,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 0,
        height: 3,
    },
})(Slider);


function valuetext(value) {
    return `${value}°C`;
}


const SeriesControl = (props) => {

    const {data, stats, width, height, field, onChange, value} = props;

    const dataLength = data.length;
    const valueMin = 0
    const valueMax = dataLength ? dataLength - 1 : 0

    const isOneValue = dataLength === 1;
    const [displayedData, setDisplayedData] = useState([])

    const interpretValues = (val) => {
        try {
            return ({
                value: val,
                naturalValue: val ? val.map(val => displayedData[val]?.y) : [0, 0]
            })

        } catch (e) {
            console.error('>>>>', {
                e, val
            })
        }
    }
    const currentValue = value && value?.value
        ? value
        : interpretValues([valueMin, valueMax])

    const [values, setValues] = useState(currentValue)

    useEffect(() => {
        setValues(currentValue)
    }, [value])

    useEffect(() => {
        setDisplayedData(
            isOneValue
                ? [{x: 0, y: data[0], y0: 0}, {x: 1, y: data[0], y0: 0}]
                : data.map((item, i) => ({x: i, y: item}))
        )
// console.log(`D_${field.name}>>`, displayedData.slice(displayedData.length - 10).map(item => item.y))
// setValues(interpretValues([0, data.length - 1]))
    }, [data])

// console.log(`D_${field.name}>>`, displayedData.slice(displayedData.length - 10).map(item => item.y))

    const hasLeftValue = values.value && values.value.length
    const leftValue = hasLeftValue && displayedData[values.value[0]]
        ? displayedData[values.value[0]]?.y
        : displayedData[0] ? displayedData[0]?.y : 0

    const hasRightValue = values.value && values.value.length > 1
    const rightValue = hasRightValue && displayedData[values.value[1]]
        ? displayedData[values.value[1]]?.y
        : isOneValue
            ? displayedData[0]?.y
            : displayedData[displayedData.length - 1] ? displayedData[displayedData.length - 1].y : 0

// console.log(`${field.name}_LR`, {leftValue, rightValue, values})

    const isValueOutOfData = (
        hasLeftValue && values.value[0] >= displayedData.length
    ) || (
        hasRightValue && values.value[1] >= displayedData.length
    )

    const widgetHeight = height ? height : SeriesSelectorDefaults.height;
    const widgetWidth = width ? width : SeriesSelectorDefaults.width;
    const pointerHeight = widgetHeight;
    const pointerWidth = SeriesSelectorDefaults.pointerWidth;

    const classes = useStylesSelector({
        widgetWidth,
        widgetHeight,
        pointerWidth,
        pointerHeight
    });

    useEffect(() => {
        if (isOneValue && isValueOutOfData) {
            // onChange(null, [0, 0])
            // handleChange(null, [0, 0])
        }
    }, [data])

    return (
        <div className={classes.root}>
            <XYPlot
                width={widgetWidth}
                height={widgetHeight}
                margin={{left: 0, right: 0, top: 0, bottom: 0}}
            >
                <GradientDefs>
                    <linearGradient id="CoolGradient" x1="0" x2="0" y1="0" y2="1">
                        <stop offset="0%" stopColor={'#cc0000'} stopOpacity={0.4}/>
                        <stop offset="50%" stopColor="blue" stopOpacity={0.3}/>
                        <stop offset="100%" stopColor="green" stopOpacity={0.3}/>
                    </linearGradient>
                </GradientDefs>

                <Borders style={{
                    bottom: {fill: '#fff'},
                    left: {fill: '#fff'},
                    right: {fill: '#fff'},
                    top: {fill: '#fff'}
                }}/>

                <AreaSeries
                    color={'url(#CoolGradient)'}
                    data={displayedData}
                />

            </XYPlot>
            {!isOneValue &&
            <DataSlider
                min={valueMin}
                max={valueMax}
                // ValueLabelComponent={ValueLabelComponent}
                ThumbComponent={DataSliderThumbComponent}
                value={values.value}
                onChange={(e, val) => {
                    setValues(interpretValues(val))
                }}
                onChangeCommitted={(e, val) => {
                    if (onChange && dataLength > 0) {
                        onChange(interpretValues(val))
                    }
                }}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}

                widgetHeight={widgetHeight}
                widgetWidth={widgetWidth}

                pointerHeight={pointerHeight}
                pointerWidth={pointerWidth}
            />
            }
            <div>
                <Grid container spacing={2} alignItems="center" justify="space-between">
                    <Grid item>
                        {!isOneValue &&
                        <Tooltip title={leftValue}>
                            <div className={classes.dataValue} style={{textAlign: 'left'}}>
                                {getValueRepr(leftValue)}</div>
                        </Tooltip>
                        }
                    </Grid>
                    <Grid item>
                        <Tooltip title={rightValue}>
                            <div className={classes.dataValue}>
                                {getValueRepr(rightValue)}</div>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>

        </div>
    );
}

export default SeriesControl;