import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select
} from "@material-ui/core";
import {AddCircleOutline} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

import {isFieldObject} from "../../../../helpers/field";
import {getCubeDicts} from "../../../../helpers/data";
import {getDB} from "../../../../db";

import {DataStateContext, MapStateContext} from "../../../../pages/project/map/reducer";
import {OperandModel} from '../../../../models/alg'

const useStyles = makeStyles((theme) => ({
    root: {
        width: 280,
        background: '#D9DFE4',
        borderRadius: 0,
        padding: theme.spacing(1),
        boxSizing: 'border-box'
    },
    selectMetric: {
        width: 265
    },
    selectField: {
        width: 265
    },
    selectValueField: {
        width: 265
    },
    selectValue: {
        width: 265
    },
    title: {
        boxSizing: 'border-box',
        padding: '1rem 10px',
        margin: '0'
    },
    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    fieldChip: {
        display: 'inline-block',
        maxWidth: 120,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '12px',
        lineHeight: '32px',
    },

    operandRow: {
        margin: '2px 0'
    },

    inputControl: {
        width: '100%',
        textAlign: 'right'
    },

    addItemButton: {
        paddingTop: '1rem',
        width: '100%',
    },

    controlDivider: {
        margin: '1rem 0'
    },

    selectFieldPlace: {

    },

    lineWidthControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));

const DEFAULT_OPERAND_TYPE = 'tag'


const TagOperand = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        objectType,
        onLoadingTags,
        onLoadedTags,
        onChangeTag,
        onChangeTagValue
    } = props

    const [selectedTag, setSelectedTag] = useState(null)
    const [availableTags, setAvailableTags] = useState({})
    const [selectedTagValue, setSelectedTagValue] = useState(null)

    const selectedTagValues = availableTags && selectedTag && selectedTag in availableTags
        ? availableTags[selectedTag]
        : []


    const handleSelectTag = (tag) => {
        if (onChangeTag) onChangeTag(tag, `Tag: ${tag}`)
        setSelectedTag(tag)
    }

    const handleSelectTagValue = (tagValue) => {
        if (onChangeTagValue) onChangeTagValue(tagValue, `Tag: ${selectedTag}=${tagValue}`)
        setSelectedTagValue(tagValue)
    }

    useEffect(() => {
        if (!objectType) {
            setSelectedTag(null)
            setSelectedTagValue(null)
            return
        }

        const accAvailableTags = {}
        if (onLoadingTags) onLoadingTags()

        Promise.resolve(getDB())
            .then(DB => DB.table(objectType.name))
            .then(table => table.toArray())
            .then(array =>{
                array
                    .filter(obj => !!obj.tags)
                    .map(obj => Object.entries(obj.tags).forEach(([tag, value]) => {
                        if (tag in accAvailableTags) {
                            if (!accAvailableTags[tag][value]) {
                                accAvailableTags[tag][value] = (Array.isArray(value) ? value.join(', ') : value).toString().toLowerCase()
                            }
                        } else {
                            accAvailableTags[tag] = {
                                [value]: (Array.isArray(value) ? value.join(', ') : value).toString().toLowerCase()
                            }
                        }
                    }))
                setAvailableTags(accAvailableTags)
                setSelectedTag(null)

                if (onLoadedTags) onLoadedTags(accAvailableTags)

            }).catch((e) => {
console.log('accAvailableTags ERR>', e)
                if (onLoadedTags) onLoadedTags(null)
            })
    }, [objectType])


    return (
        <div>
            <FormControl>
                <InputLabel>{t('project.leftbar.panel5.tag')}:</InputLabel>
                <Select
                    value={selectedTag}
                    onChange={(e) => {handleSelectTag(e.target.value)}}
                    className={classes.selectMetric}
                >
                    <MenuItem value=""></MenuItem>
                    {
                        Object.keys(availableTags).sort().map((tag, mi) => (
                            <MenuItem key={mi} value={tag}>{tag}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            <FormControl className={classes.selectFieldPlace}>
                <InputLabel>{t('project.leftbar.panel5.field')}:</InputLabel>
                <Select
                    value={selectedTagValue}
                    onChange={(e) => handleSelectTagValue(e.target.value)}
                    className={classes.selectField}
                >
                    <MenuItem value=""></MenuItem>
                    {selectedTagValues &&
                    Object.keys(selectedTagValues).sort().map((tagKey, fi) => (
                        <MenuItem key={fi} value={tagKey}>{selectedTagValues[tagKey]}</MenuItem>
                    ))
                    }
                </Select>
            </FormControl>

        </div>
    )
}

const MetricOperand = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [selectedMetricId, setSelectedMetricId] = useState(null)
    const [selectedFieldName, setSelectedFieldName] = useState(null)
    const [selectedValue, setSelectedValue] = useState(null)

    const {
        objectType,
        metrics,
        cubes,

        onChangeMetric,
        onChangeField,
        onChangeValue,
    } = props

    const selectedMetric = metrics.find(m => m.id === selectedMetricId)

    useEffect(() => {
        if (!objectType) {
            setSelectedMetricId(null)
            setSelectedFieldName(null)
            setSelectedValue(null)
        }

    }, [objectType])

    useEffect(() => {

        if (!selectedMetric) {
            setSelectedMetricId(null)
            setSelectedFieldName(null)
            setSelectedValue(null)
        }

    }, [selectedMetric])

    const selectedCube = cubes[selectedMetricId]
    const cubeDicts = getCubeDicts(selectedCube)

    const availableFields = selectedCube?.struct?.fields.filter(fld => !isFieldObject(fld))
    const selectedField = availableFields?.find(fld => fld.name === selectedFieldName)

    const isDictField = selectedField && cubeDicts && cubeDicts[selectedFieldName]

    const availableDictValues = isDictField ? cubeDicts[selectedFieldName] : {}

    const handleChangeMetric = (metricId, title) => {
        setSelectedMetricId(metricId)
        if (onChangeMetric) onChangeMetric(metricId, title)
    }

    const handleChangeField = (field, title) => {
        setSelectedFieldName(field)
        if (onChangeField) onChangeField(field, title)
    }

    const handleChangeValue = (value, title) => {
        setSelectedValue(value)
        if (onChangeValue) onChangeValue(value, title)
    }

    return (
        <div>
            <FormControl>
                {/*<InputLabel>{t('project.leftbar.panel5.metric')}:</InputLabel>*/}
                <Select
                    value={selectedMetricId}
                    onChange={(e) => handleChangeMetric(e.target.value, '')}
                    className={classes.selectMetric}
                >
                    <MenuItem value="" />
                    {
                        metrics.map((metric, mi) => (
                            <MenuItem key={mi} value={metric.metric?.id}>
                                {metric.metric?.meta?.title}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <FormControl className={classes.selectFieldPlace}>
                <InputLabel>{t('project.leftbar.panel5.field')}:</InputLabel>
                <Select
                    value={selectedFieldName}
                    onChange={(e) => {
                        const theField = availableFields?.find(fld => fld.name === e.target.value)
                        handleChangeField(
                            e.target.value,
                            `${selectedMetric.metric?.meta?.title} ${theField?.title}`
                        )
                    }}
                    className={classes.selectField}
                >
                    <MenuItem value="" />
                    {
                        availableFields?.map((field, fi) => (
                            <MenuItem key={fi} value={field.name}>{field.title}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            {isDictField &&
            <FormControl className={classes.selectValue}>
                <InputLabel>{t('project.leftbar.panel5.value')}:</InputLabel>
                <Select
                    value={selectedValue}
                    onChange={(e) => handleChangeValue(
                        e.target.value,
                        `${selectedMetric.metric?.meta?.title} ${availableDictValues[e.target.value]}`
                    )}
                    className={classes.selectValueField}
                >
                    <MenuItem value="" />
                    {
                        Object.entries(availableDictValues)?.map(([value, title], fi) => (
                            <MenuItem key={fi} value={value}>{title}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            }

        </div>
    )
}


export const OperandForm = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        onAddOperand
    } = props

    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {dataState, dataDispatch} = useContext(DataStateContext)

    const {
        metric: metrics,
        cube: cubes,
        objectType: objectTypes,
    } = dataState;

    const [operand, setOperand] = useState(OperandModel())
    const [operandSource, setOperandSource] = useState(DEFAULT_OPERAND_TYPE)
    const [selectedObjectId, setSelectedObjectId] = useState(null)
    const selectedObjectType = selectedObjectId ? objectTypes[selectedObjectId] : null

    const availableObjectTypes = Object.values(objectTypes)
    const availableMetrics = Object.values(metrics).filter(m => m?.metric?.object_type_id === selectedObjectId)

    useEffect(() => {

        if (!selectedObjectId) {
            setOperand(OperandModel())
        }

        setOperand(OperandModel({
            object_type_id: selectedObjectId
        }))

    }, [selectedObjectId])

    const handleUpdateOperand = (field, value, title) => {
        setOperand(prevValue => ({...prevValue, title, [field]: value}))
    }

    const handleAddOperand = () => {
        /*
                setOperands(oldOperands => ({
                    ...oldOperands,
                    [metricFieldKey(selectedMetricId, selectedFieldName, selectedValue)]: {
                        field: selectedField,
                        metric: selectedMetric,
                        value: isDictField
                            ? {title: availableDictValues[selectedValue], id: selectedValue}
                            : selectedValue,
                        is_dict: isDictField,
                        weight: 1
                    }
                }))
        */

        onAddOperand(operand)
        setOperand(OperandModel())
        setSelectedObjectId(null)
    }

    const isObjectTypeSelected = !!selectedObjectId
    const canAddOperand = isObjectTypeSelected && (!!operand.tag || operand.field || operand.value)

    return (
        <div>
            <div>
                <div>
                    <FormControl>
                        <InputLabel>{t('project.leftbar.panel5.object_type')}:</InputLabel>
                        <Select
                            value={selectedObjectId}
                            onChange={(e) => setSelectedObjectId(e.target.value)}
                            className={classes.selectMetric}
                        >
                            <MenuItem value="" />
                            {
                                availableObjectTypes.map((objectType, oti) => (
                                    <MenuItem key={oti} value={objectType.id}>
                                        {objectType.meta?.title}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>

                {isObjectTypeSelected &&
                <div>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row aria-label="position" name="position" defaultValue="top"
                            value={operandSource}
                            onChange={(e) => {setOperandSource(e.target.value)}}
                        >
                            <FormControlLabel value="tag" control={<Radio color="primary" />} label="Tag" />
                            <FormControlLabel value="metric" control={<Radio color="primary" />} label="Metric" />
                        </RadioGroup>
                    </FormControl>
                </div>
                }

                {isObjectTypeSelected && operandSource === 'tag' &&
                    <TagOperand objectType={selectedObjectType}
                                onLoadingTags={() => {
                                    mapDispatch({
                                        type: 'setCalculating',
                                        isCalculating: true
                                    })
                                }}
                                onLoadedTags={() => {
                                    mapDispatch({
                                        type: 'setCalculating',
                                        isCalculating: false
                                    })
                                }}
                                onChangeTag={(tag, title) => {
                                    handleUpdateOperand('tag', tag, title)
                                }}
                                onChangeTagValue={(tag, title) => {
                                    handleUpdateOperand('value', tag, title)
                                }}
                    />
                }

                {isObjectTypeSelected && operandSource === 'metric' &&
                    <MetricOperand objectType={selectedObjectType}
                                   metrics={availableMetrics}
                                   cubes={cubes}
                                   onChangeMetric={(metricId, title) => {
                                       handleUpdateOperand('metric_id', metricId, title)
                                   }}
                                   onChangeField={(field, title) => {
                                       handleUpdateOperand('field', field, title)
                                   }}
                                   onChangeValue={(value, title) => {
                                       handleUpdateOperand('value', value, title)
                                   }}
                    />
                }

            </div>

            <div>
                <FormControl className={classes.addItemButton}>
                    <Button
                        color="primary"
                        startIcon={<AddCircleOutline />}
                        disabled={!canAddOperand || !isObjectTypeSelected}
                        onClick={(e) => handleAddOperand()}>
                        {t('project.leftbar.panel5.add')}
                    </Button>
                </FormControl>
            </div>

        </div>
    )
}

