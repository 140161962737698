export const mapLayers = [
    {
        id: 1,
        title: '1 - Rent'
    },
    {
        id: 2,
        title: '2 - Q purchasing',
    },
    {
        id: 3,
        title: '3 - Leakage'
    },
]

export const wurzenLayers = [
    {layer: false, title: 'Hide all'},
    {layer: true, title: 'Show all'},
    {layer: 'amenity'        , title: 'Amenity'},
    {layer: 'building'       , title: 'Building'},
    {layer: 'healthcare'     , title: 'Healthcare'},
    {layer: 'historic'       , title: 'Historic'},
    // {layer: 'landuse'        , title: 'Landuse'},
    {layer: 'leisure'        , title: 'Leisure'},
    {layer: 'natural'        , title: 'Natural'},
    {layer: 'shop'           , title: 'Shop'},
    {layer: 'sport'          , title: 'Sport'},
]