import { gql } from "@apollo/client";


export const ListAlgorithms = gql`
    query ListAlgorithms {
        listAlgorithms {
            algorithms
        }
    }
`


