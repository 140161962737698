import {useTranslation} from "react-i18next";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    filterControl: {
        width: 280,
        marginRight: '16px',
    },

    filterSelector: {
        width: '100%'
    },

}))


export default function DictFilter(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        filterField,
        filterDict,
        value,
        onChange,
    } = props

    // const filterDict = metric.cube.dicts.find((item) => (item.field === filterField.name))?.values

    return (
        <FormControl className={classes.filterControl} key={filterField.name}>
            <InputLabel id={`common-stat-${filterField.name}-label`}>{filterField.title}</InputLabel>
            <Select
                labelId={`common-stat-${filterField.name}-label`}
                id={`common-stat-${filterField.name}-selector`}
                value={value ?? null}
                onChange={(e) => {
                    if (onChange) onChange(e.target.value)
                }}
                className={classes.filterSelector}
            >
                <MenuItem key={-1} value={null}>-unused-</MenuItem>
                {!!filterDict && Object.entries(filterDict).map(([dictId, dictTitle]) => (
                    <MenuItem key={dictId} value={dictId}>{dictTitle}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
