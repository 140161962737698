const prefixes = {
    1000000000000: 'T',
    1000000000: 'B',
    1000000: 'M',
    1000: 'K',
}

export function getPrefixDelimeter(value) {
    return Object
        .keys(prefixes)
        .sort((a, b) => parseInt(b) > parseInt(a) ? 1 : -1)
        .find(item => Math.abs(value) >= item);
}

export function getValuePrefix(value) {
    const delimeter = getPrefixDelimeter(value);
    return getPrefixDelimeter ? prefixes[delimeter] : ''
}
export function getNormalizedValue(value) {
    let delimeter = getPrefixDelimeter(value);

    try {
        if (delimeter) {
            return (value * 1.0 / delimeter)
        } else {
            // ..
        }
    } catch (e) {
        // ..
    }
    return value
}

export function getValueRepr(value, postfix=null) {

    if (value === undefined)
        return null

    const normValue = getNormalizedValue(value);
    const prefix = getValuePrefix(value);

    const formatedValue = !normValue
        ? normValue
        : (parseInt(normValue) === normValue
            ? parseInt(normValue)
            : normValue.toFixed(prefix ? 1 : 2));

    return  String(formatedValue) + (prefix ? prefix : '') + (postfix ? postfix : '');
}

export function getFormatedValue(value, fractions=2) {
    return parseInt(value) === value
        ? parseInt(value)
        : parseFloat(value).toFixed(fractions);
}

export function getAnyValue(value, fractions=2) {
    return parseInt(value) || parseInt(value) === 0
        ? getFormatedValue(value, fractions)
        : String(value)
}

const alphabet = 'ABCDEFJHIJKLMNOPQRSTUVWXYZ';
export function getLitera(index) {
    try {
        if (index === 0) return alphabet[0];

        let litera = ''
        while (index >= 0) {
            litera = alphabet[index % alphabet.length] + litera
            index = Math.floor(index / alphabet.length - 1)
        }
        return litera;
    } catch (e) {
        return alphabet[0]
    }
}
window.getLitera = getLitera