import React, {useEffect, useState} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import { XGrid } from '@material-ui/x-grid';

import '../../../../styles/scrollbar.css';
import Scrollbar from 'react-perfect-scrollbar'
import {directAxios} from "../../../../clients";
import {ApiEndpoint} from "../../../../config";
import {getValueRepr, getFormatedValue} from '../../../../helpers/repr'
import {paletteColorMinMax, rgbToHex} from '../../../../helpers/color'


const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
    },

    tablePlace: {
        marginTop: '2rem',
        '&:first-child': {
            marginTop: 0
        }
    },

    headCell: {
        minWidth: 180
    }
}));

let isLoading = false;

const CurrentViewItem = function (props) {
    const classes = useStyles();

    const { title, hint, bbox } = props;

    const [currentViewData, setCurrentViewData] = useState(null)

    const loadDataview = () => {
        const dataApi = new ApiEndpoint('dataview');
        const requestPromise = directAxios
                .post(dataApi.endpoint('/view'), {
                    dataview_id: '',
                    bbox
                });

        requestPromise.then((response) => {
            isLoading = false;
            setCurrentViewData(response.data.currentview)
            // setDatasets(response.data.datasets)
        }).catch(() => {
            isLoading = false;
        });
    }

    useEffect(() => {
        loadDataview()
    }, [])

    useEffect(() => {
        loadDataview()
    }, [bbox])

    const maxHeight = window.innerHeight - 175

    if (!currentViewData)
        return null;

    return (
        <section className={classes.root}>
            {hint &&
            <Typography variant="caption" component="small" style={{marginBottom: '1rem'}}>{hint}</Typography>
            }

            <TableContainer style={{maxWidth: '100%'}} component={Paper}>
                <Scrollbar>
                    <section style={{height: maxHeight}}>
                        <div className={classes.tablePlace} key={1}>
                            <h3>Roads</h3>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {['Class', 'Max speed', 'Avg length', 'Count', 'Connectivity'].map(colTitle => {
                                            return (
                                                <TableCell className={classes.headCell}><b>{colTitle}</b></TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentViewData.roads.map((dataItem) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{dataItem.highway}</TableCell>
                                                <TableCell>{getFormatedValue(dataItem.maxspeed)}</TableCell>
                                                <TableCell>{getFormatedValue(dataItem.length)}</TableCell>
                                                <TableCell>{getFormatedValue(dataItem.count)}</TableCell>
                                                <TableCell>{getFormatedValue(dataItem.connectivity)}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>

                        <div className={classes.tablePlace} key={2}>
                            <h3>Transport</h3>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.headCell}><b>Transport</b></TableCell>
                                        <TableCell className={classes.headCell}><b>Count</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Bus stations</TableCell>
                                        <TableCell>{currentViewData.transport.bus_station}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Platforms</TableCell>
                                        <TableCell>{currentViewData.transport.platform}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Parking</TableCell>
                                        <TableCell>{currentViewData.transport.parking}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>

                        <div className={classes.tablePlace} key={2}>
                            <h3>POI</h3>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.headCell}><b>POI objects</b></TableCell>
                                        <TableCell className={classes.headCell}><b>Count</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Social facility</TableCell>
                                        <TableCell>{currentViewData.poi.social_facility}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Shops</TableCell>
                                        <TableCell>{currentViewData.poi.shops}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Kitas</TableCell>
                                        <TableCell>{currentViewData.poi.kitas}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Schools</TableCell>
                                        <TableCell>{currentViewData.poi.schools}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Cafes</TableCell>
                                        <TableCell>{currentViewData.poi.cafes}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>

                    </section>
                </Scrollbar>
            </TableContainer>

        </section>
    )
}

export default CurrentViewItem;