import React, {useContext, useEffect, useState} from "react";
import {
    Grid, Paper, Slider,
    Switch, Typography,
    Select, MenuItem,
    TextField,
    Tooltip
} from "@material-ui/core";
import LayerFilterDialog from "../map/filters/layer-filter-dialog";
import {makeStyles} from "@material-ui/core/styles";
import { DataStateContext, MapStyleContext } from "../../reducer";

import FormSelector from "../map/layers/widgets/form-selector";
import ColorSelector from "../map/layers/widgets/color-selector";
import { getGroupedMapOptions } from '../../map-styles'
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        width: 280,
        background: '#F5FAFF',
        borderRadius: 0,
        paddingBottom: 10,
        marginBottom: 10
    },

    themeSelectorContainer: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },

    selector: {
        width: '100%'
    },

    // #C8CCD9
    title: {
        boxSizing: 'border-box',
        padding: '10px',
        margin: '0',
        fontWeight: 'normal',
        fontSize: '12pt'
    },

    subtitle: {
        boxSizing: 'border-box',
        padding: '5px 10px',
        margin: '0',
        // fontWeight: 'normal',
        // fontSize: '12pt'
    },

    displayModeControl: {
        background: '#F5FAFF'

    },
    colorBaseControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    colorBaseGradControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    colorBaseGradNumberControl: {
        margin: theme.spacing(1),
        minWidth: 95,
    },
    oneColorControl: {
        margin: theme.spacing(1)
    },
    lineWidthControl: {
        margin: theme.spacing(1)
    },
    opacityControl: {
        margin: theme.spacing(1)
    },
    gradientItem: {
        width: '100%',
        height: '40px',
    }
}));


export default function MapSettings (props) {
    const classes = useStyles();
    const { dataState, dataDispatch } = useContext(DataStateContext)
    const { mapStyle, mapStyleDispatch } = useContext(MapStyleContext)
    const { t } = useTranslation();

    const { project, me } = dataState

    const mapOptions = {}

    const groupedMapOptions = getGroupedMapOptions()

    const handleChangeTheme = (e) => {
        mapStyleDispatch({
            type: 'setMapStyle',
            theme: e.target.value
        })
    }

    const showJoinLink = !!project
    const joinLink = `https://auth.lokk-platform.com/join/${project?.id}`

    const [showTooltip, setShowTooltip] = useState(false)

    useEffect(() => {
        if (showTooltip) {
            setTimeout(() => {
                setShowTooltip(false)
            }, 2000)
        }
    }, [showTooltip])

    return (
        <>
            {showJoinLink &&
            <Paper className={classes.root}>
                <h4 className={classes.title}>{t('project.leftbar.panel6.join_link')}</h4>
                <div className={classes.themeSelectorContainer}>
                    <Tooltip arrow
                             title={t('project.leftbar.panel6.join_link_copied')}
                             open={showTooltip}
                             onClose={() => {setShowTooltip(false)}}
                             disableFocusListener
                             disableHoverListener
                             disableTouchListener
                    >
                        <TextField
                            label={t('project.leftbar.panel6.join_link_text')}
                            defaultValue={joinLink}
                            InputProps={{
                                readOnly: true,
                            }}
                            style={{width: '100%'}}
                            onFocus={(e) => e.target.select()}
                            onDoubleClick={(e) => {
                                e.target.select()
                                console.log(e.target.value)
                                navigator.clipboard.writeText(e.target.value);
                                setShowTooltip(true)
                            }}
                            // variant="outlined"
                        />
                    </Tooltip>
                </div>
            </Paper>
            }

            <Paper className={classes.root}>
                <h4 className={classes.title}>{t('project.leftbar.panel6.map_theme')}</h4>
                <div className={classes.themeSelectorContainer}>
                    <Select
                        value={mapStyle.theme}
                        onChange={handleChangeTheme}
                        className={classes.selector}
                    >
                        <MenuItem value="black">{t('project.leftbar.panel6.black')}</MenuItem>
                        <MenuItem value="white">{t('project.leftbar.panel6.white')}</MenuItem>
                        <MenuItem value="satellite">{t('project.leftbar.panel6.satellite')}</MenuItem>
                    </Select>
                </div>
            </Paper>

            {Object.keys(groupedMapOptions).map((mlKey) => {
                const currentLayer = groupedMapOptions[mlKey]
                return (
                    <Paper className={classes.root} key={mlKey}>
                        <h4 className={classes.title}>{mlKey}</h4>

                        {Object.keys(currentLayer).map((optKey) => {
                            const currentOption = groupedMapOptions[mlKey][optKey]
                            return (
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    key={optKey}
                                >
                                    <Grid item xs={8}>
                                        <h5 className={classes.subtitle}>{optKey}</h5>
                                    </Grid>
                                    <Grid item>
                                        <Switch
                                            defaultChecked={true}
                                            value={mapOptions[currentOption.id]}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                            onChange={(e) => {
                                                mapStyleDispatch({
                                                    type: 'setOption',
                                                    option: currentOption.id,
                                                    enabled: e.target.checked
                                                })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Paper>
                );
            })}
        </>
    )
}