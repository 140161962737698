import React from 'react';
import ProfileModal from './profile'
import WelcomeTourModal from './welcome-tour'

export default function PopupModals(props) {
    return (
        <React.Fragment>
            <ProfileModal />
            <WelcomeTourModal />
        </React.Fragment>
    )
}
