import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ProjectsPage from "./pages/projects";
import NewProjectPage from "./pages/project/new";
import ProjectMap from "./pages/project/map";
import Map from "./pages/map";
import AuthPage from "./pages/auth";

// import SeriesSelectorPage from "./pages/tmp/series-selector";
// import {ThemeProvider} from "@material-ui/core/styles";


const AppRouter = (props) => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={ProjectsPage}/>
                <Route path="/project/new" exact component={NewProjectPage}/>
                <Route path="/(project)?" exact component={ProjectsPage}/>
                <Route path="/project/:slug" exact component={ProjectMap}/>
                <Route path="/map" exact component={Map}/>
                <Route path="/auth" exact component={AuthPage}/>

                {/*<Route path="/project/:slug(weimar)" exact component={Map}/>*/}
                {/*<Route path="/project/:slug(wurzen)" exact component={WurzenPage}/>*/}
                {/*<Route path="/w" exact component={SeriesSelectorPage}/>*/}
            </Switch>
        </Router>
    )
}

export default AppRouter
