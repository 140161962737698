import React, {useEffect, useState} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import { XGrid } from '@material-ui/x-grid';

import '../../../../../../styles/scrollbar.css';
import Scrollbar from 'react-perfect-scrollbar'
import {directAxios} from "../../../../../../clients";
import {ApiEndpoint} from "../../../../../../config";
import {getFormatedValue, getValueRepr} from '../../../../../../helpers/repr'
import {paletteColorMinMax, rgbToHex} from '../../../../../../helpers/color'


const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
    },

    headCell: {
        minWidth: 180
    }
}));

let isLoading = false;

const DataBarItemTable = function (props) {
    const classes = useStyles();

    const { title, dataview, hint, bbox } = props;
    const metrics = dataview?.metrics ? dataview?.metrics: [];
    const { id: dataviewId, dataset_link: {dataset: {meta: {title: datasetTitle}}} } = dataview

    const [dataviewData, setDataviewData] = useState([])

    const loadDataview = () => {
        const dataApi = new ApiEndpoint('dataview');
        const requestPromise = directAxios
                .post(dataApi.endpoint('/data'), {
                    dataview_id: dataviewId
                });

        requestPromise.then((response) => {
            isLoading = false;
            setDataviewData(response.data.dataview)
            // setDatasets(response.data.datasets)
        }).catch(() => {
            isLoading = false;
        });
    }

    useEffect(() => {
        if (dataviewId) {
            loadDataview()
        }
    }, [dataviewId])

    const maxHeight = window.innerHeight - 150

    const metricsMinMax = metrics.reduce((acc, {color, metric}) => {
        const metricValues = dataviewData.map(dataItem => dataItem.values[metric.id])
        return {
            ...acc,
            [metric.id]: {
                min: Math.min.apply(null, metricValues),
                max: Math.max.apply(null, metricValues),
                color,
            }
        }
    }, {})

    if (!dataviewData)
        return

    return (
        <section className={classes.root}>
            {hint &&
            <Typography variant="caption" component="small" style={{marginBottom: '1rem'}}>{hint}</Typography>
            }

                <TableContainer style={{maxWidth: '100%'}}>
                    <Scrollbar>
                        <section style={{height: maxHeight}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.headCell}><b>{datasetTitle}</b></TableCell>
                                        {metrics.map(({metric}, mi) => (
                                            <TableCell align="left" key={mi} className={classes.headCell}>
                                                <b>{metric.meta.short}</b>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataviewData.map((dataItem) => {

                                        return (
                                            <TableRow>
                                                <TableCell>{dataItem.title}</TableCell>
                                                {metrics.map(({metric}, mi) => {
                                                    const value = dataItem.values[metric.id]
                                                    const metricColorItem = metricsMinMax[metric.id]
                                                    const colorRgb = metricColorItem.color ? paletteColorMinMax(
                                                        value,
                                                        metricColorItem.min,
                                                        metricColorItem.max,
                                                        metricColorItem.color[0],
                                                        metricColorItem.color[1]
                                                    ) : [0, 0, 0];

                                                    const color = rgbToHex(colorRgb)

                                                    return (
                                                        <TableCell
                                                            style={{color}}
                                                        >{getFormatedValue(value)}</TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </section>
                    </Scrollbar>
                </TableContainer>

        </section>
    )
}

export default DataBarItemTable;