import React, {useState, useEffect, useReducer, useContext} from "react";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {
    isFieldObject,
    isFieldScalar,
    isFieldFilterable,
    isFieldTimestamp,
    normalizeDateByValue
} from '../../../../../../../helpers/field'
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    baseColorHeader: {
        marginBottom: 0
    },
    baseFieldControl: {
        width: 210,
        marginLeft: 0,
        // '&:nth-child(odd)': {
        //     marginLeft: 10,
        // }
    },
    fieldLabel: {
        fontSize: 12
        // width: 100,
        // height: 24,
        // textOverflow: 'ellipsis',
        // overflow: 'hidden',
        // '&:.Mui-focused': {
        //     height: 'auto',
        // }
    }
}));


const ColorMetricField = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {cube, values, onChange} = props

    const filterFields = cube?.struct?.fields?.filter(
        item => !isFieldObject(item) && !isFieldScalar(item) && isFieldFilterable(item))

    const timeSeriesValues = cube?.struct?.timeseries?.map(item => ({
        id: normalizeDateByValue(item),
        key: item,
        title: item
    }))
    const timeSeriesField = cube?.struct?.fields.find(item => isFieldTimestamp(item))

    const dictValues = cube?.dicts.reduce((acc, item) => ({
        ...acc,
        [item.field]: item.values
    }), {})

    const availableValues = Object.assign(
        {},
        dictValues,
        timeSeriesField ? {
            [timeSeriesField.name]: timeSeriesValues
        }: {})

    // console.log('>>BC>>', {
    //     cube,
    //     dictValues,
    //     filterFields,
    //     availableValues
    // })

    if (!filterFields || filterFields?.length === 0)
        return null

    return (
        <div className={classes.root}>
            <h5 className={classes.baseColorHeader}>{t('widgets.color_selector.metric_filter')}</h5>
            {
                filterFields?.map((field, fieldIndex) => (
                <FormControl className={classes.baseFieldControl} key={fieldIndex}>
                    <InputLabel className={classes.fieldLabel}>{field.title}:</InputLabel>
                    <Select
                        value={values[field.name] ?? null}
                        onChange={(e) => {
                        const value = e.target.value;
                        if (onChange) {
                            onChange({
                                [field.name]: value
                            }, field.name, value)
                        }
                    }}
                    >
                    <MenuItem value={null}> All values </MenuItem>
                    {
                        availableValues[field.name]?.map((value, fi) => (
                            <MenuItem key={fi}
                                      value={value.id}>{value.title}</MenuItem>
                        ))
                    }
                    </Select>
                </FormControl>
                ))
            }

        </div>
    )
}

export default ColorMetricField;