import React, {useEffect} from "react";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import {
    TreeView
} from "@material-ui/lab"
import {makeStyles} from '@material-ui/core/styles';
import {
    MinusSquare, PlusSquare, CloseSquare,
    EmptySquare, CheckedSquare, Circle
} from '../../../../../../components/icons'
import {StyledTreeItem, LiteraTreeItem} from '../../../../../../components/tree'

import {getNextColor, getRandomColor} from '../../../../../../helpers/color'
import {getLitera} from '../../../../../../helpers/repr'
import Scrollbar from "react-perfect-scrollbar";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        // padding: theme.spacing(0.5),
        background: 'none',
        padding: 0,
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
        maxWidth: 130,
        textOverflow: 'ellipsis'
    },
    tree: {
        minHeight: 300,
        maxHeight: '80vh',
        flexGrow: 1,
        minWidth: 300,
        maxWidth: '80vw',
    },
    controls: {
        marginTop: theme.spacing(1),
        textAlign: 'center'
    },
    dialog: {
        transition: 'all 0.3s easeInOutSine'
    },

    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    metricTreeItem: {
        label: {
            paddingLeft: 8
        }
    },

    list: {

    },

    listHeader: {
        fontSize: '1rem',
        fontWeight: 'bold',
        background: theme.color.darkGray
    },

    listDatasetItem: {
        borderBottom: '1px solid rgba(0,0,0,0.12)'
    },

    listItemText: {
        '& span': {
            fontSize: 12
        }
    },
}));


const MetricTreeLayer = (props) => {
    const classes = useStyles();

    const {root, env, state, nextIndex} = props
    const [endIcon, setEndIcon] = React.useState(<Circle />)
    const [isChecked, setIsChecked] = React.useState(root.id in state)

    const color = getNextColor(nextIndex);
    const litera = getLitera(nextIndex);

    React.useEffect(() => {

        setEndIcon(isChecked ? <Avatar style={{
            backgroundColor: color
        }} className={classes.litera}>{litera}</Avatar>: <Circle />)

    }, [isChecked])

    return <LiteraTreeItem nodeId={root.id}
                           label={root.meta.title}
                           endIcon={endIcon}
                           className={classes.metricTreeItem}
                           onClick={(e) => {

                               setIsChecked(!isChecked)

                               if (props.onChange) {
                                   props.onChange(e, {
                                       ...env,
                                       id: root.id,
                                       title: root.meta.title,
                                       index: nextIndex,
                                       litera: litera,
                                       color: color,
                                       metric: root,
                                       is_metric: true
                                   }, !isChecked)
                               }
                           }}
        // expandIcon={!hasChildren ? <CheckedSquare /> : undefined}
    />;
};


const TreeLayer = (props) => {
    const {root, env, state, nextIndex, isRoot} = props
    const isObjectTypeSelect = Object.keys(env).length === 0;
    const hasChildren = !!root.children && root.children.length > 0;
    const hasDatasets = !!root.datasets && root.datasets.length > 0;
    const hasMetrics = !!root.metrics && root.metrics.length > 0;
    const [endIcon, setEndIcon] = React.useState(isObjectTypeSelect && !hasChildren ? <CloseSquare/> : undefined)
    const [isChecked, setIsChecked] = React.useState(root.id in state)

    React.useEffect(() => {

        if (isObjectTypeSelect && !hasChildren) {
            setEndIcon(<CloseSquare/>)
            return
        }

        setEndIcon(isChecked ? <CheckedSquare/> : <EmptySquare/>)

    }, [isChecked])


    let children = (hasChildren ? root.children.map((item) => {
        return <TreeLayer state={state}
                          root={item}
                          nextIndex={nextIndex}
                          env={{
                              ...env,
                              objectType: root
                          }}
                          onChange={(e, el, isChecked) => {
                              if (props.onChange) {
                                  props.onChange(e, el, isChecked)
                              }
                          }}/>
    }) : []).concat(hasDatasets ? root.datasets.map((itemDataset) => {
        return <TreeLayer state={state}
                          root={itemDataset}
                          nextIndex={nextIndex}
                          env={{
                              ...env,
                              object: root
                          }}
                          onChange={(e, el, isChecked) => {
                              if (props.onChange) {
                                  props.onChange(e, el, isChecked)
                              }
                          }}/>
    }) : []).concat(hasMetrics ? root.metrics.map((itemMetric) => {
        return <MetricTreeLayer state={state}
                                root={itemMetric}
                                nextIndex={nextIndex}
                                env={{
                                    ...env,
                                    dataset: root
                                }}
                                onChange={(e, el, isChecked) => {
                                    if (props.onChange) {
                                        props.onChange(e, el, isChecked)
                                    }
                                }}/>
    }) : [])

    return <StyledTreeItem nodeId={root.id}
                           label={root.meta.title}
                           endIcon={endIcon}
                           onClick={(e) => {

                               if (hasChildren || hasDatasets || hasMetrics || isRoot) {
                                   return;
                               }

                               setIsChecked(!isChecked)

                               if (props.onChange) {
                                   props.onChange(e, {
                                       ...env,
                                       id: root.id,
                                       title: root.meta.title,
                                       index: -1,
                                       litera: null,
                                       color: getRandomColor(),
                                       metric: null,
                                       dataset: null,
                                       object: root,
                                       is_metric: false
                                   }, !isChecked)
                               }

                           }}
        // expandIcon={!hasChildren ? <CheckedSquare /> : undefined}
    >{children}</StyledTreeItem>;
}


const CheckTreeDialog = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const isFullScreen = window.innerWidth < 420;

    const {data, selected} = props;
    const reducedSelected = selected

    const reducedIndex = Object.values(selected).reduce((mx, item) => (
        item.index > mx ? item.index : mx
    ), 0)

    const [state, setState] = React.useState(reducedSelected)
    const [nextIndex, setNextIndex] = React.useState(reducedIndex)

    useEffect(() => {
        setState(reducedSelected)
    }, [selected])

    const handleClose = () => {
        props.onClose();
    };

    const handleOk = () => {

        const checkedItems = Object.entries(state)
            .filter(([id, treeItem]) => treeItem !== null)
            .map(([id, treeItem]) => treeItem)

        if (props.onOk) {
            props.onOk(checkedItems)
        }

        props.onClose()
    }

    const handleChange = (e, treeItem, isChecked) => {
        setState(oldState => {
            if (!isChecked) {
                return Object.values(oldState).filter(item => item.id !== treeItem.id).reduce((agg, item) => ({
                    ...agg,
                    [item.id]: item
                }), {})
            }

            return {
                ...oldState,
                [treeItem.id]: treeItem
            }
        });
    }

    useEffect(() => {
        const idx = Object.values(state).reduce((mx, item) => (
            item.index > mx ? item.index : mx
        ), -1)
        setNextIndex(idx + 1)
    }, [state]);

    const checkedItems = Object.entries(state)
        .filter(([id, treeItem]) => treeItem !== null)
        .map(([id, treeItem]) => treeItem)

    return (
        <Dialog
            open={props.isOpened}
            onClose={handleClose}
            aria-labelledby="object-type-dialog-title"
            aria-describedby="object-type-dialog-description"
            className={classes.dialog}
            fullScreen={isFullScreen}
            maxWidth='xl'
        >
            <DialogTitle id="object-type-dialog-title">{t('project.slide1.popup.data.title')}</DialogTitle>
            <DialogContent>
                <Scrollbar>
                    <TreeView
                        className={classes.tree}
                        multiSelect={true}
                        defaultExpanded={['1']}
                        defaultCollapseIcon={<MinusSquare/>}
                        defaultExpandIcon={<PlusSquare/>}
                        defaultEndIcon={<EmptySquare/>}
                        defaultChecked={<CheckedSquare/>}
                        // defaultSelected={<CheckedSquare />}
                        // onNodeSelect={(e, value) => console.log('V>', value)}
                    >

                        <StyledTreeItem nodeId="1" label="Object Types">
                            {
                                data.map((item, i) => <TreeLayer
                                                              key={i}
                                                              state={state}
                                                              root={item}
                                                              env={{}}
                                                              isRoot={true}
                                                              nextIndex={nextIndex}
                                                              onChange={handleChange}/>)
                            }
                        </StyledTreeItem>
                    </TreeView>
                </Scrollbar>
                {/*
                <Paper component="ul" className={classes.root} elevation={0}>
                    {checkedItems._map((data) => {
                        let icon;

                        return (
                            <li key={data.id}>
                                <Chip
                                    icon={icon}
                                    label={data.meta?.title}
                                    // onDelete={handleDelete(data)}
                                    className={classes.chip}
                                />
                            </li>
                        );
                    })}
                </Paper>
*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOk} color="primary" autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CheckTreeDialog;

