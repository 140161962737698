import React, {useState, useEffect, useReducer, useContext} from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Badge, Button, Collapse, TextField, Slider, Grid} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab"
import {makeStyles} from "@material-ui/core/styles";
import SeriesSelector from "./widgets/series-selector";

import {isFieldKey, isFieldScalar, isFieldTimestamp, isFieldUUID} from '../../../../../../helpers/field'
import FilterSelector from './widgets/filter-selector'
import FilterSlider from './widgets/filter-slider'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    title: {
        margin: 0,
        padding: 0
    },
}));

const FilterFabric = (props) => {
    const classes = useStyles();
    const {
        field, dict, stats,
        timeseries,
        limit, offset,
        data,
        value: filterValue,
        onChange, onLimitOffset, scalarsState, onResetScalars } = props

    const isUUID = isFieldUUID(field)
    const isTimestamp = isFieldTimestamp(field)
    const isKey = isFieldKey(field)
    const isScalar = isFieldScalar(field)

    const hasData = data && data.length > 0

    const initialValue = isTimestamp
        ? {
            value: [
                0,
                timeseries.length - 1
            ],
            naturalValue: timeseries
        } : isScalar ? {
            value: hasData ? [
                0,
                data.length - 1
            ] : null,
            naturalValue: hasData ? [
                data && data.length ? data[0][field.name] : 0,
                data && data.length ? data[data?.length - 1][field.name] : 0
            ] : null
        } : {
            value: [],
            naturalValue: []
        }
    ;

    // const correctFilterValue = isTimestamp && timeseries
    //     ? filterValue?.map(val => timeseries.indexOf(val)).filter((item, i) => i === 0 || i === filterValue.length - 1)
    //     : filterValue

    // const currentValue = correctFilterValue
    //     ? correctFilterValue
    //     : initialValue

    const currentValue = filterValue
        ? filterValue
        : initialValue

    const [values, setValues] = useState(currentValue)

    let timeout;

    useEffect(() => {
        if (!onChange) return
        if (values === null) return

        onChange(
            field.name,
            values.value,
            values.naturalValue
        )


//         if (timeout) clearTimeout(timeout);
//         timeout = setTimeout(() => {
//         }, 1000)
    }, [values])

    const handleChangeValues = (value, naturalValue) => {
        setValues({
            value,
            naturalValue
        })
    }

    const handleReset = () => {
        handleChangeValues([
            0,
            data?.length - 1
        ], [
            data[0][field.name],
            data[data.length - 1][field.name]
        ])
    }

    useEffect(() => {
        if (isScalar && hasData) {
            handleReset()
        }
    }, [scalarsState])

    // Case 1: the key field with dict
    if (isUUID && dict && isKey) {
        return (
            <FilterSelector
                field={field}
                dict={dict}
                value={currentValue}
                onChange={(values) => {
                    onChange(
                        field.name,
                        values.value,
                        values.naturalValue
                    )
                }}
                onResetScalars={onResetScalars}
            />
        )
    }

    // Case 2: is timestamp key with timeseries
    if (isTimestamp && timeseries) {
        return (
            <FilterSlider
                field={field}
                timeseries={timeseries}
                value={currentValue}
                onChange={(values) => {
                    onChange(
                        field.name,
                        values.value,
                        values.naturalValue
                    )
                }}
                onResetScalars={onResetScalars}
            />
        )
    }

    // Case 3: scalar value: now it controls limit/offset
    if (isScalar && !isKey && hasData) {

        return (
            <div className={classes.root}>
                <SeriesSelector
                    title={field.title}
                    field={field}
                    data={data}
                    value={currentValue}
                    // value={limit ? [offset, offset + limit] : null}
                    stats={stats}
                    onChange={(values) => {
                        onChange(
                            field.name,
                            values.value,
                            values.naturalValue
                        )
                    }}
                    // onChange={(newValue, naturalValue) => handleChangeValues(newValue, naturalValue)}
                />
            </div>

            // <div className={classes.root}>
            //     <h4>{field.title}</h4>
            //     <div>Roller</div>
            // </div>
        )
    }

    // Case 4: ignore others
    return null;
}

export default FilterFabric;