import { appConfig, geocoderLink, ApiEndpoint } from '../config'
import { geocoderAxios } from "../clients/directAxios";
import {directAxios} from "../clients";


// https://api.mapbox.com/geocoding/v5/mapbox.places/-110.24444022587863%2C38.44796206807729.json?access_token=pk.eyJ1Ijoic2VhcmNoLW1hY2hpbmUtdXNlci0xIiwiYSI6ImNrN2Y1Nmp4YjB3aG4zZ253YnJoY21kbzkifQ.JM5ZeqwEEm-Tonrk5wOOMw&cachebuster=1613075388154&autocomplete=true


export function geoByPoint (coodrinates) {
    const url = geocoderLink(coodrinates.join(','))
    return directAxios
        .get(url).then((loaded_data) => {
            return loaded_data
        }).catch(() => {
            // isLoading = false;
        });

}


export function geocodeSearch(search) {
    const url = (new ApiEndpoint('geocode')).endpoint('search')
    return directAxios
        .post(url, {
            search
        })
        .then((response) => response)
        .catch(() => {})
}

