import React, {useState, useEffect, useReducer, useContext} from "react";
import {
    Grid,
    Button,
    Switch,
    Select, MenuItem, InputLabel, TextField,
    Checkbox,
    Paper,
    BottomNavigation,
    BottomNavigationAction,
    FormControl,
    Typography,
    Slider, Input

} from "@material-ui/core";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {SliderPicker} from 'react-color'
import {
    hexToRgb, stepPaletteLinearGrad, twoColorsPalette,
    gradientTable,
    gradientTableNumbers,
    grayScalePalette,
    getRandomColor,
    UNDEFINED_COLOR
} from '../../../../../../../helpers/color'
import {
    getAnyValue
} from '../../../../../../../helpers/repr'
import {
    isFieldObject,
    isFieldScalar,
    isFieldFilterable,
    isFieldTimestamp,
    isFieldKey
} from '../../../../../../../helpers/field'

import ColorMetricField from './color-metric-fields'

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import {useTranslation} from "react-i18next";
import {DEFAULT_HEIGHT, HEIGHT_DEFAULT_STRUCTURE, MAX_HEIGHT} from "../../../../../../../helpers/const";


const Accordion = withStyles((theme) => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
    root: {
        background: theme.color.gray,
        border: 'none',
        marginBottom: -1,
        minHeight: 20,
        padding: 10,
        '&$expanded': {
            background: theme.color.semiGray,
            minHeight: 20,
        },
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: 0,
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        display: 'block',
        padding: 0,
    },
}))(MuiAccordionDetails);


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 10px'
    },

    title: {
        margin: '10px 0',
        padding: 0,

    },

    shortHeight: {
        width: '100%',
        height: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: 14,
        fontWeight: 'bold'
    },

    gradSelector: {
        boxSizing: 'border-box',
    },

    gradItem: {
        width: '100%',
        cursor: 'pointer',
        padding: 4,
        margin: 1,
        boxSizing: 'border-box',
        background: 'transparent',
        '&:hover': {
            background: theme.color.semiGray,
        }
    },

    gradItemColor: {
        width: '100%',
        height: '12px',
    },

    gradItemSelected: {
        width: '100%',
        cursor: 'pointer',
        padding: 4,
        margin: 1,
        boxSizing: 'border-box',
        background: theme.color.semiGray,
    },

    colorBaseControl: {

        // margin: theme.spacing(1),
        // width: 244
    },

    baseColorControls: {
        marginBottom: theme.spacing(1)
    },

    colorStepsControl: {
        width: 30,
        marginLeft: 10
    },

    baseColorHeader: {
        marginBottom: 0
    },

    baseMetricControl: {
        width: 120
    },

    baseFieldControl: {
        width: 120,
        marginLeft: 10
    },

    colorControl: {
        margin: theme.spacing(1)
    },

    opacityControl: {
        margin: theme.spacing(1)
    },

    grayScaleControl: {
        marginTop: 20
    },

    grayScaleColor: {
        boxSizing: 'border-box',
        width: '20%',
        paddingRight: 1,
        float: 'left',
    },

    grayScaleColorPicker: {
        height: 12,
        cursor: 'pointer'
    },

    grayScaleColorPickerSelected: {
        height: 12,
        cursor: 'pointer',
        transform: 'scaleY(1.8)',
        borderRadius: '3.6px / 2px'
    },

    legendTable: {

    },

    legendRow: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        // padding: theme.spacing(0.5),
        background: 'none',
        padding: 0,
        margin: '2px 0 0 0',
    },

    legendColor: {
        width: 40,
        height: 12
    },

    legendValues: {
        paddingLeft: 15,
        width: 220
    },

    heightSlider: {
        '& .MuiSlider-valueLabel': {
            fontSize: 10
        }
    },
    scaleSlider: {
        '& .MuiSlider-valueLabel': {
            fontSize: 10
        },
        '& .MuiSlider-markLabel': {
            fontSize: 10,
        },
        '& .MuiSlider-markLabel[data-index=0]': {
            left: '3% !important'
        },
        '& .MuiSlider-markLabel[data-index=6]': {
            left: '97% !important'
        },
        '& span:nth-child(5)': {
            left: '3% !important'
        },
        '& span:nth-child(17)': {
            left: '97% !important'
        },

    }
}));


const HeightSelector = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const title = props.title ? props.title : 'Color'

    const {
        id,
        onChange,
        onBaseMetricChange,
        value,
        metrics: availableMetrics,
        cubes
    } = props;

    const heightStructure = value ?? HEIGHT_DEFAULT_STRUCTURE

    const heightScales = [
        {value: 0, label: '0.001', realValue: 0.001},
        {value: 1, label: '0.01', realValue: 0.01},
        {value: 2, label: '0.1', realValue: 0.1},
        {value: 3, label: '1', realValue: 1},
        {value: 4, label: '10', realValue: 10},
        {value: 5, label: '100', realValue: 100},
        {value: 6, label: '1000', realValue: 1000},
    ]

    const [heightValue, setHeightValue] = useState(heightStructure.value)
    const [heightFinalValue, setHeightFinalValue] = useState(heightStructure.value)
    const [heightScale, setHeightScale] = useState(heightScales.find(item => item.realValue === heightStructure.value)?.value ?? 3)
    const [baseMetricId, setBaseMetricId] = useState(heightStructure.metricId)
    const [baseMetricField, setBaseMetricField] = useState(heightStructure.field)

    const isBaseMetric = !!baseMetricId && !!baseMetricField

    useEffect(() => {
        const newHeightStructure = {...heightStructure,
            value: heightValue,
            scale: heightScales.find(item => item.value === heightScale)?.realValue ?? 1,
            metricId: isBaseMetric ? baseMetricId : null,
            field: isBaseMetric ? baseMetricField : null
        }

        if (onChange) onChange(newHeightStructure)
        if (onBaseMetricChange) onBaseMetricChange(newHeightStructure)

    }, [heightFinalValue, heightScale, baseMetricId, baseMetricField])

    const [expanded, setExpanded] = React.useState(false);

    const baseCube = cubes.find(cube => cube && cube.id === baseMetricId)
    const baseMetric = availableMetrics.find(metric => metric && metric.id === baseMetricId)
    const availableFields = baseCube?.struct.fields.filter(fld => isFieldScalar(fld))

    const availableFieldDict = baseCube?.dicts.find(d => d.field === heightStructure.field)

    return (
        <div className={classes.root}>

            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={8}>
                    <h5 className={classes.title}>{title}</h5>
                </Grid>
                <Grid item>

                </Grid>
            </Grid>


            <Accordion square expanded={expanded} onChange={(event, isExpanded) => {
                setExpanded(isExpanded);
            }}>
                <AccordionSummary aria-controls="panel1d-content" id={`panel1d-header-${id}`}>
                    <div className={classes.shortHeight}>
                        {baseMetric
                            ? `${t('widgets.height_selector.metric')}: ${baseMetric.metric?.meta?.title}`
                            : `${t('widgets.height_selector.default_height')}: ${heightStructure.value}`}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.baseColorControls} key="baseColorControls">
                            <h5 className={classes.baseColorHeader}>{t('widgets.height_selector.height_base')}</h5>
                            <FormControl key='metric' className={classes.baseMetricControl}>
                                <InputLabel>{t('widgets.height_selector.metric')}:</InputLabel>
                                <Select
                                    value={baseMetricId}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setBaseMetricId(value)
                                    }}
                                >
                                    <MenuItem key={-1} value="">&times; {t('widgets.height_selector.clear_base')} </MenuItem>
                                    {
                                        availableMetrics?.map((item, mi) => (
                                            <MenuItem key={mi}
                                                      value={item.metric.id}>{item.metric.meta.title}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl key='field' className={classes.baseFieldControl}>
                                <InputLabel>{t('widgets.height_selector.field')}:</InputLabel>
                                <Select
                                    value={baseMetricField}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setBaseMetricField(value)
                                    }}
                                >
                                    <MenuItem key={-1} value="">&times; {t('widgets.height_selector.clear_base')} </MenuItem>
                                    {
                                        availableFields?.map((field, fi) => (
                                            <MenuItem key={fi}
                                                      value={field.name}>{field.title}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                        </div>

                    <h5 className={classes.baseColorHeader}>{t('widgets.height_selector.default_height')}</h5>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                            <Slider
                                value={heightValue}
                                min={0}
                                step={1}
                                max={MAX_HEIGHT}
                                getAriaValueText={(value) => (value + ' m')}
                                valueLabelFormat={(value) => (value + ' m')}
                                onChange={(e, value) => {
                                    setHeightValue(value)
                                }}
                                onChangeCommitted={(e, value) => {
                                    setHeightValue(value)
                                    setHeightFinalValue(value)
                                }}
                                className={classes.heightSlider}
                                valueLabelDisplay="auto"
                                aria-labelledby="non-linear-slider"

                            />
                        </Grid>
                        <Grid item>
                            <Input
                                className={classes.input}
                                value={heightValue}
                                margin="dense"
                                onChange={(e) => {
                                    const value = e.target.value === '' ? heightValue : Number(e.target.value)
                                    setHeightValue(value)
                                    setHeightFinalValue(value)
                                }}
                                onBlur={() => {
                                    if (heightStructure.value < 0) {
                                        setHeightValue(0)
                                        setHeightFinalValue(value)
                                    } else if (heightStructure.value > MAX_HEIGHT) {
                                        setHeightValue(MAX_HEIGHT)
                                        setHeightFinalValue(value)
                                    }
                                }}
                                inputProps={{
                                    step: 1,
                                    min: 0,
                                    max: MAX_HEIGHT,
                                    type: 'number',
                                }}
                            />
                        </Grid>
                    </Grid>

                    {isBaseMetric &&
                        <div>
                            <h5 className={classes.baseColorHeader}>{t('widgets.height_selector.height_scale')}</h5>

                            <Slider
                                // value={heightScale}
                                defaultValue={heightScale}
                                min={0}
                                max={6}
                                step={null}
                                marks={heightScales}
                                getAriaValueText={(value) => (value)}
                                valueLabelFormat={(value) => (heightScales.find(item => item.value === value).label)}
                                onChangeCommitted={(e, value) => {
                                    setHeightScale(value)
                                }}
                                className={classes.scaleSlider}
                                valueLabelDisplay="auto"
                            />

                        </div>

                    }

                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default HeightSelector;