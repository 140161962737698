import {MetricModel} from "../../../models/metric";
import {CubeModel} from "../../../models/cube";
import {DEFAULT_LAYER_TYPE, LayerModel} from "../../../models/layer";
import {ApiEndpoint} from "../../../config";
import {authAxios} from "../../../clients";
import {getToken} from "../../../actions/auth";

const algApi = new ApiEndpoint('alg')
const parquetApi = new ApiEndpoint('parquet')

const apiClient = authAxios({
    auth: {
        access_token: getToken()
    }
});

export default async function WeightedGravityIndexCalc({
                                                           objectType: objectTypeMetric, title, algorithmParams,
                                                           filters,  dataState,
                                                           existMetric, metricLayer
                                                       })
{
    const {
        project,
        objectType: dataObjectTypes,
        metric: dataMetrics,
    } = dataState

    const {operands, gravityRadius} = algorithmParams

    const objectType = dataObjectTypes[objectTypeMetric.id]
    const objectTypes = Object.entries(operands).reduce((ot, [tagKey, operand]) => {
        // operand.tag
        // operand.value

        return {
            ...ot,
            [operand.objectType.id]: {
                ...operand.objectType,
                tags: {
                    ...(ot[operand.objectType.id] && ot[operand.objectType.id].tags ? ot[operand.objectType.id].tags : {}),
                    [tagKey]: {
                        tag: operand.tag,
                        value: operand.value,
                        weight: operand.weight
                    }
                }
            }
        }
    }, {})

    console.log('CALC>>')

    const executeParams = {
        project_id: project.id,
        algorithm: "gravityindex",
        object_type_id: objectTypeMetric.id,
        ver: existMetric ? existMetric.ver : 1,
        title,
        filter: [],
        params: {
            gravityRadius
        },
        operands: Object.values(operands).map(operand => ({
            object_type_id: operand.objectType.id,
            weight: operand.weight,
            tag: operand.tag,
            value: operand.value
        })),
    }

    if (existMetric) {
        executeParams['metric_id'] = existMetric.id
    }

    const response = await apiClient.post(algApi.endpoint('/execute'), executeParams)

    console.log('end CALC>>')

    // const calculatedCubeData = response.data.data
    const cubeLineage = response.data.lineage
    const projectObjectType = {
        id: project.id,
        key: project.id,
        meta: project.meta,
    };

    const nextMetricIndex = Object.keys(dataMetrics).length
    const newMetric = existMetric
        ? {
            ...existMetric,
            ver: response.data.ver,
            lineage: cubeLineage
        } : MetricModel({
            id: response.data.metric_id,
            index: nextMetricIndex,
            title,
            ver: response.data.ver,
            lineage: cubeLineage,
        }, objectTypeMetric, projectObjectType)

    const newCube = CubeModel({
        id: newMetric.id,
        src_id: 0,
        ver: response.data.ver,
        struct: {
            fields: response.data.struct,
            timeseries: response.data.timeseries
        },
    })

    const layerType = objectTypeMetric.layerTypes.length
        ? (objectTypeMetric.layerTypes.find(item => item.is_default)?.key || objectTypeMetric.layerTypes[0]).key
        : DEFAULT_LAYER_TYPE

    const newLayer = metricLayer ? metricLayer : LayerModel({
        title,
        layerType,
        metrics: [
            newMetric
        ],
        color: newMetric.color,
        object: objectTypeMetric,
        objectType: objectTypeMetric,
        colorScheme: objectTypeMetric.colorScheme
    })

    return {
        metric: newMetric,
        cube: newCube,
        layer: newLayer,
        source: parquetApi.endpoint(response.data.path),
        isDebug: false
    }

}