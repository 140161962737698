import {v4 as uuidV4} from 'uuid'
import {Vector4Centroid, isLineType, vector4MiddlePoint} from '../helpers/geom'

export const objectSchema = {
    indexes: [
        {
            fields: ['lng', 'lat']
        },
        {
            fields: ['id']
        }
    ],
    fields: [
        {
            name: 'id',
            type: 'uuid not null',
            title: 'ID',
            is_key: true,
        },
        {
            name: 'lng',
            type: 'double',
            title: 'Longitude',
            is_key: false,
        },
        {
            name: 'lat',
            type: 'double',
            title: 'Latitude',
            is_key: false,
        },
        {
            name: 'key',
            type: 'text',
            title: 'Key',
            is_key: false,
        },
        {
            name: 'meta',
            type: 'struct',
            title: 'Meta data',
            is_key: false,
        },
        {
            name: 'tags',
            type: 'map',
            title: 'Meta data',
            is_key: false,
        },
        {
            name: 'geom_type',
            type: 'text',
            title: 'Geometry type',
            is_key: false,
        },
        {
            name: 'geom',
            type: 'double[][][][]',
            title: 'Geometry',
            is_key: false,
        },
        {
            name: 'user_id',
            type: 'uuid',
            title: 'User',
            is_key: false
        }
    ]
}


export function createObject(props, user_id=null) {
    const unique = uuidV4()
    const {geom, geom_type} = props
    const [lng, lat] = isLineType(geom_type) ? vector4MiddlePoint(geom) : Vector4Centroid(geom)

    return Object.assign({}, {
        id: unique,
        key: unique,
        meta: {
            title: '',
            about: ''
        },
        lng,
        lat,
        metricValue: null,
        tags: {},
        ...props,
        user_id,
    })
}