import React, {useState, useEffect, useReducer, useContext, useRef} from "react";
import {
    Button,
    Select, MenuItem, InputLabel, TextField,
    Accordion, AccordionSummary, AccordionDetails,
    Checkbox, Slider
} from "@material-ui/core";
import {
    Autocomplete
} from "@material-ui/lab"
import {
    ExpandMore,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    CheckBox as CheckBoxIcon,
    ChevronLeft,
    ChevronRight,
    Restore
} from '@material-ui/icons';
import {makeStyles, styled} from '@material-ui/core/styles';
import css from 'styled-components'

import '../../../../../styles/scrollbar.css';
import Scrollbar from 'react-perfect-scrollbar'

import {LayerModel} from '../../../../../models/layer'

import {appConfig} from '../../../../../config'

import LayerVisualization from './layers/layer-viz'
import LayerUserActivity from './layers/layer-user-activity'
import LayerAlg from '../alg/layer-alg'
import ObjectTypeSelector from '../object-type-selector'
import LayerFilters from './filters'
import MapSettings from '../settings/map-settings'
import LayerPalette from './palette'

import { isManager } from '../../../../../helpers/user'
import { createActorLayerId } from '../../../../../models/layer'


import {initialMapState, mapStateReducer, MapStateContext, DataStateContext} from '../../reducer'
import {useTranslation} from "react-i18next";
import LayerUserEdit from "./layers/layer-user-edit";
import LayerNew from "./layers/layer-new";
import LayerActorsModel from './layers/layer-actors-model'


const PanelGroup = css.div`
    position: fixed;
    top: 63px;
    left: 10px;
    width: 310px;    
    z-index: 1;
    transition: left .2s ease-in-out;
`

const PanelSlideButton = css.div`
    position: absolute;
    top: 0px;
    right: -50px;
    z-index: 2;
    width: 30px;
    height: 30px;
    background: #D9DFE4;
    text-align: center;
    line-height: 45px;
`

const PanelLayer = styled(Accordion)({

    '& .MuiAccordionSummary-root': {
        minHeight: 48,

        '&.Mui-expanded': {
            minHeight: 48,
        }
    },

    '& .MuiAccordionSummary-content': {
        'font-weight': 'normal',
        margin: '10px 0',
        '&.Mui-expanded': {
            margin: '10px 0'
        }
    },

    '&:first-child': {
        '&.Mui-expanded': {
            marginTop: 0,
        }
    },

    '&.Mui-expanded': {
        paddingBottom: 10,

    }
})

const AddButton = styled(Button)({
    width: '100%'
});

const PanelContent = styled(AccordionDetails)({
    display: 'block',
})

const PanelLayer1 = styled(PanelLayer)({
    background: '#ABBECD',
});

const PanelLayer2 = styled(PanelLayer)({
    background: '#D9DFE4',
});

const FormItem = css.div`
    margin-bottom: 10px;
`

const useStyles = makeStyles((theme) => ({
    root: {},

    defaultPanel: {
        background: theme.color.semiGray
    },

    darkPanel: {
        background: theme.color.darkGray
    },

    panelTitle: {
        ...theme.typography.h3
    },

    vizPanel: {
        padding: '0 16px 16px 16px'
    },

    settingsPanel: {},

    filteringPanel: {
        padding: 0,
        'overflow-x': 'none'
    },

    createLayerControl: {
        textAlign: 'center',
        boxSizing: 'border-box',
        padding: '16px 10px 6px 10px'
    }

}));

const MapLeftBar = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {onLayerChange, onAddBuildingModeChange, onBuildingSettingsChange} = props;

    const {dataState, dataDispatch} = useContext(DataStateContext)
    const { me } = dataState;


    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {
        layers: mapLayers,
        userActivityLayer,
        userEditLayer,

    } = mapState

    const actorsModelLayer = mapState[createActorLayerId]

    const maxPanelSize = window.innerHeight - 400

    const [expanded, setExpanded] = React.useState(isManager(me) ? 'layer1' : 'layerPalette');

    const scrollLayersRef = useRef(null)

    const handleChangePanel = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleCreateLayer = () => {
        const maxOrdering = mapLayers && Object.values(mapLayers).length
            ? Math.max.apply(null, Object.values(mapLayers).map(lr => lr.ordering ?? 0))
            : 1

        mapDispatch({
            type: 'addLayerProto',
            layer: LayerModel({title: '', object: null, ordering: maxOrdering + 1})
        })

        setTimeout(() => {
            try {
                scrollLayersRef.current._container.scrollTop = scrollLayersRef.current._container.scrollHeight + 200
            } catch (e) {
                // ...
            }
        }, 10)
    }

    const handleLayerChreated = (layer) => {
        setTimeout(() => {
            try {
                scrollLayersRef.current._container.scrollTop = scrollLayersRef.current._container.scrollHeight + 200
            } catch (e) {
                // ...
            }
        }, 10)
    }


    const [isMnimized, setIsMinimized] = useState(false)
    const panelStyle = {
        left: isMnimized ? -310 : 10
    }

    const showObjects = isManager(me)
    const showLayers = isManager(me)
    const showCreateObjects = userEditLayer?.isEnabled
    const showAlgo = isManager(me) && appConfig.isAlgEnabled
    const showSettings = appConfig.isSettingsEnabled

    return (
        <PanelGroup style={panelStyle}>

            <PanelSlideButton onClick={() => setIsMinimized((oldValue) => !oldValue)}>
                {
                    isMnimized
                        ? <ChevronRight/>
                        : <ChevronLeft/>
                }
            </PanelSlideButton>

            <div>
                {showObjects &&
                <PanelLayer
                    square
                    expanded={expanded === 'layer1'}
                    onChange={handleChangePanel('layer1')}
                    className={classes.defaultPanel}
                    key={'p0'}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="layer1-content"
                        className={classes.panelTitle}
                    >{t('project.leftbar.panel1.title')}</AccordionSummary>
                    <Scrollbar>
                        <PanelContent style={{maxHeight: maxPanelSize}}>
                            {/*<DatasetSelector onLayerChange={props.onLayerChange} />*/}
                            <ObjectTypeSelector
                                onModify={() => {
                                    setExpanded('layer4')
                                }}
                                onChange={props.onLayerChange}
                            />
                        </PanelContent>
                    </Scrollbar>
                </PanelLayer>
                }

                {showLayers &&
                <PanelLayer
                    square
                    expanded={expanded === 'layer2'}
                    onChange={handleChangePanel('layer2')}
                    className={classes.defaultPanel}
                    key={'p1'}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="layer2-content"
                        className={classes.panelTitle}
                    >{t('project.leftbar.panel2.title')}</AccordionSummary>
                    <Scrollbar ref={scrollLayersRef}>
                        <PanelContent className={classes.vizPanel} style={{maxHeight: maxPanelSize}}>
                            <LayerUserActivity
                                key={'userActivityLayer'}
                                layerId={'userActivityLayer'}
                                layer={userActivityLayer}
                                title={t('project.leftbar.panel2.user_activity_title')}
                            />
                            <LayerUserEdit
                                key={'userEditLayer'}
                                layerId={'userEditLayer'}
                                layer={userEditLayer}
                                title={t('project.leftbar.panel2.user_edit_title')}
                            />
                            {appConfig.isExperimentalEnabled &&
                            <LayerActorsModel
                                key={createActorLayerId}
                                layerId={createActorLayerId}
                                layer={actorsModelLayer}
                                title={t('Actors model')}
                            />
                            }
                            {
                                Object.values(mapLayers)
                                    .sort((a, b) => a.ordering < b.ordering ? -1 : 1)
                                    .map((item, i) => {
                                            if (!item.objectType) {
                                                return (
                                                    <LayerNew
                                                        key={i}
                                                        layerId={item.id}
                                                        title={item.title}
                                                        layer={item}
                                                        ordering={item.ordering}
                                                        onCreated={handleLayerChreated}
                                                    />
                                                )
                                            }

                                            return (
                                                <LayerVisualization
                                                    key={i}
                                                    layerId={item.id}
                                                    title={item.title}
                                                    layer={item}
                                                    ordering={item.ordering}
                                                    // dataset={mapState.datasets[item.id]}
                                                />
                                            );
                                        }
                                    )
                            }
                        </PanelContent>
                    </Scrollbar>

                    <div className={classes.createLayerControl}>
                        <Button variant="contained" onClick={handleCreateLayer}>Add layer</Button>
                    </div>
                </PanelLayer>
                }

{/*
                {showFilters &&
                <PanelLayer
                    square
                    expanded={expanded === 'layer3'}
                    onChange={handleChangePanel('layer3')}
                    className={classes.defaultPanel}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="layer3-content"
                        className={classes.panelTitle}
                    >Filtering</AccordionSummary>
                    <Scrollbar>
                        <PanelContent className={classes.filteringPanel} style={{maxHeight: maxPanelSize}}>
                            <LayerFilters />
                        </PanelContent>
                    </Scrollbar>
                </PanelLayer>
                }
*/}

                {showCreateObjects &&
                <PanelLayer
                    square
                    expanded={expanded === 'layerPalette'}
                    onChange={handleChangePanel('layerPalette')}
                    className={classes.defaultPanel}
                    key={'p2'}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="layerPalette-content"
                        className={classes.panelTitle}
                    >{t('project.leftbar.panel4.title')}</AccordionSummary>
                    <Scrollbar>
                        <PanelContent className={classes.filteringPanel} style={{maxHeight: maxPanelSize}}>
                            <LayerPalette />
                        </PanelContent>
                    </Scrollbar>
                </PanelLayer>
                }

                {showAlgo &&
                <PanelLayer
                    square
                    expanded={expanded === 'layer4'}
                    onChange={handleChangePanel('layer4')}
                    className={classes.defaultPanel}
                    key={'p3'}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="layer4-content"
                        className={classes.panelTitle}
                    >{t('project.leftbar.panel5.title')}</AccordionSummary>
                    <Scrollbar>
                        <PanelContent style={{maxHeight: maxPanelSize}}>
                            <LayerAlg layerId={'0'} onExecute={() => {
                                // setUpdateTime((new Date()).getTime())
                                // mapDispatch({
                                //     type: 'addProjectStep'
                                // })

                                setExpanded('layer2')

                            }}/>
                        </PanelContent>
                    </Scrollbar>
                </PanelLayer>
                }

                {showSettings &&
                <PanelLayer
                    square
                    expanded={expanded === 'layer5'}
                    onChange={handleChangePanel('layer5')}
                    className={classes.darkPanel}
                    key={'p4'}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="layer5-content"
                        className={classes.panelTitle}
                    >{t('project.leftbar.panel6.title')}</AccordionSummary>
                    <Scrollbar>
                        <PanelContent className={classes.settingsPanel} style={{maxHeight: maxPanelSize}}>
                            <MapSettings/>
                        </PanelContent>
                    </Scrollbar>
                </PanelLayer>
                }


                {/*
            <PanelComponent>
                <PanelTitle>Layers</PanelTitle>
            </PanelComponent>

            <PanelComponent2>
                <PanelTitle>Layers</PanelTitle>
                <div>
                    <TextField
                        id="standard-number"
                        label="Number"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </PanelComponent2>
*/}
            </div>

        </PanelGroup>
    )
}

export default MapLeftBar;
