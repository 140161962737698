import { gql } from "@apollo/client";

export const GetProjectDataviews = gql`
    query GetProjectDataviews ($project_id: uuid!) {
        project_dataviews(where: {project_id: {_eq: $project_id}}) {
            dataview {
                id
                meta {
                    title
                }
                dataset_link {
                    dataset {
                        id
                        meta {
                            title
                        }
                    }
                }
                metrics(order_by: {ordering: asc}) {
                    ordering
                    color
                    metric {
                        id
                        meta {
                            title
                            short
                        }
                    }
                }
            }
        }
    }
`