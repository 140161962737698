import {isFieldTimestamp, isFieldScalar, isFieldFilterable, normalizeDate, isFieldObject} from './field'


export function hasMetrics(dataState) {
    return dataState?.metric && Object.values(dataState?.metric).findIndex(item => !!item.metric) > -1
}

export function getChronometricField(metric) {
    return metric?.cube?.struct?.fields?.find(field => isFieldTimestamp(field))
}

export function getCubeDicts(metric) {
    let pointer

    if (metric?.cube) {
        pointer = metric.cube
    } else if (metric?.dicts) {
        pointer = metric
    }

    return pointer?.dicts?.reduce((acc, item) => ({
        ...acc,
        [item.field]: item.values.reduce((valAcc, valItem) => ({...valAcc, [valItem.id]: valItem.title}), {})
    }), {})
}

export function hasMultiMetrics(dicts) {
    return Object.values(dicts).findIndex(dict => Object.values(dict).length > 2) > -1
}

export function getObjectFields(metric) {
    return metric?.cube?.struct?.fields?.filter(field => isFieldObject(field))
}

export function getOrtFields(metric) {
    return metric?.cube?.struct?.fields?.filter(field => !isFieldScalar(field))
}

export function getScalarFields(metric) {
    return metric?.cube?.struct?.fields?.filter(field => isFieldScalar(field))
}

export function geUsableScalarFields(metric) {
    return metric?.cube?.struct?.fields?.filter(field => isFieldScalar(field) && isFieldFilterable(field))
}

export function getTimeSeries(metric) {
    return metric?.cube?.struct?.timeseries?.map(year => normalizeDate(year))
}

export function getFieldDict(metric, field) {
    return metric?.cube?.dicts.find(item => item.field === field.name)
}

export function getFieldDictValues(metric, field) {
    const dict = getFieldDict(metric, field)
    return dict?.values.reduce((acc, val) =>({...acc, [val.id]: val}), {})
}


export function getMetricValueOrFirstSample(metricValue) {
    try{
        if (metricValue.constructor.name === 'Vector') return metricValue.toArray()[0]
        if (metricValue.hasOwnProperty('data')) return metricValue.toArray()[0]
        if (typeof metricValue === 'number') return metricValue
        if (typeof metricValue === 'string') return metricValue
    } catch (e) {
        return null
    }

    return metricValue
}