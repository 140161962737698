import React, {useState, useEffect, useReducer, useContext} from 'react';
import {useQuery, useMutation} from "@apollo/client";

import apolloClient from "../../../clients/apolloClient";
import TopBar from "./components/topbar";

import {ProjectQuery} from '../../../actions/app/gql/projectQuery'
import {GetProjectMe} from '../../../actions/app/gql/data'
import {
    mapStateReducer, mapStyleReducer, dataStateReducer, cacheStateReducer,
    initialMapState, initialMapStyle,
    MapStateContext, MapStyleContext,
    DataStateContext, initialDataState,
    CacheStateContext, initialCacheState,

    saveableMapStyle,
    saveableDataState,
    saveableMapState,

} from './reducer'

import {ApiEndpoint, parquetUri} from '../../../config'
import {getDB, refreshDB, AddTableParquet, FillTableParquet, PrepareIndexesAndStruct} from '../../../db'
import {objectSchema} from '../../../models/object'
import {CreateProjectState} from "../../../actions/app/gql/projectQuery";
import {GetActivityObjects} from "../../../actions/app/gql/activity";

import SaveStateProvider from './save-state'
import Main from './components/main'
import {useSnackbar} from "notistack";


const loadAllData = async ({
                               projectId,
                               data,

                               onBeforeLoading,
                               onAfterLoading,

                               onBeforeLoadingCube,
                               onAfterLoadingCube,

                               onPreparing,
                               onReady}) => {
    const parquetApi = new ApiEndpoint('parquet')

    if (onPreparing) onPreparing()

    await Object.values(data.cube)
        .map(async (cube) => {
            if (onBeforeLoadingCube) onBeforeLoadingCube(cube, data.metric[cube.id])

            const dataUrl = parquetApi.endpoint(parquetUri(projectId, 'cube', cube.id))
            await AddTableParquet(cube, dataUrl)

            if (onAfterLoadingCube) onAfterLoadingCube(cube, data.metric[cube.id])
        })

    const databasePromises = Object.values(data.objectType).map(async (objectType) => {

        if (onBeforeLoading) onBeforeLoading(objectType)

        const dataUrl = parquetApi.endpoint(parquetUri(projectId, 'object', objectType.id))
        await FillTableParquet(objectType.name, dataUrl);
        await PrepareIndexesAndStruct(objectType.name, objectSchema)

        if (onAfterLoading) onAfterLoading(objectType)
    })

    for (let promise of databasePromises) {
        try {
            await promise
        } catch (e) {
            console.log('E_indb', e)
        }
    }

    if (onReady) onReady(Object.values(data.objectType), Object.values(data.cube))
}

function ProjectMap(props) {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    const [dataState, dataDispatch] = useReducer(dataStateReducer, initialDataState)
    const [mapState, mapDispatch] = useReducer(mapStateReducer, initialMapState)
    const [mapStyle, mapStyleDispatch] = useReducer(mapStyleReducer, initialMapStyle)
    const [cacheState, cacheDispatch] = useReducer(cacheStateReducer, initialCacheState)

    const [isProjectLoaded, setProjectLoaded] = useState(false)

    const projectIdSlug = props.match?.params.slug;
    const {currentPage} = mapState

    const {data: projectData, loading: projectLoading, error} = useQuery(ProjectQuery, {
        client: apolloClient,
        fetchPolicy: "no-cache",
        variables: {
            project_id: projectIdSlug
        },
    });

    const {data: meData, loading: meLoading, error: meError} = useQuery(GetProjectMe, {
        client: apolloClient,
        fetchPolicy: "no-cache",
        variables: {
            project_id: projectIdSlug
        }
    })

    const {data: activityObjects} = useQuery(GetActivityObjects, {
        client: apolloClient,
        fetchPolicy: "no-cache",
        variables: {
            project_id: projectIdSlug
        }
    })

    useEffect(() => {
        mapDispatch({
            type: 'setCalculating',
            isCalculating: true
        })

        return () => {
            // refreshDB().then(() => {}).catch(() => {})
        };
    }, [])

    const [createProjectState, createProjectStateResponse] = useMutation(CreateProjectState, {
        client: apolloClient,
    })

    useEffect(() => {

        if (projectData) {
            refreshDB().then(() => {

                const {appProject} = projectData
                const {
                    id: projectId,
                    centroid: {coordinates: projectCoordinates},
                    scale_type: projectScale,
                    projectState
                } = appProject;

                dataDispatch({
                    type: 'setProject',
                    project: appProject
                })
                mapDispatch({
                    type: 'setProject',
                    projectId
                })
                setProjectLoaded(true)

                dataDispatch({
                    type: 'setDataLoaded',
                    isLoaded: false
                })

                if (!projectState) {
                    return createProjectState({
                        variables: {
                            project_id: projectId,
                            data: saveableDataState(dataState),
                            state: saveableMapState(mapState),
                            style: saveableMapStyle(mapStyle)
                        }
                    })
                        .then((data) => {

                            dataDispatch({
                                type: 'setDataLoaded',
                                isLoaded: true
                            })

                            mapDispatch({
                                type: 'setCalculating',
                                isCalculating: false
                            })

                            mapDispatch({
                                type: 'setViewStateCoordinates',
                                longitude: projectCoordinates[0],
                                latitude: projectCoordinates[1],
                                zoom: projectScale
                            })

                            mapDispatch({
                                type: 'setup',
                            })

                        })
                        .catch((err) => {
                            console.log('E_ps>>', err)
                            mapDispatch({
                                type: 'setCalculating',
                                isCalculating: false
                            })
                        })
                } else {

                    mapStyleDispatch({
                        type: 'load',
                        state: saveableMapStyle(projectState.style),
                    })

                    mapDispatch({
                        type: 'setCalculating',
                        isCalculating: true
                    })

                    // data first!
                    return loadAllData({
                        projectId,
                        data: projectState.data,
                        onBeforeLoadingCube: (cube, metric) => {
                            enqueueSnackbar(`Loading metric: ${metric?.meta?.title}...`, {
                                autoHideDuration: 5000,
                                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                                variant: 'info'
                            })
                        },
                        onAfterLoadingCube: (cube, metric) => {
                            enqueueSnackbar(`Metric loaded: ${metric?.meta?.title}`, {
                                autoHideDuration: 3000,
                                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                                variant: 'info'
                            })
                        },
                        onBeforeLoading: (objectType) => {
                            enqueueSnackbar(`Loading layer: ${objectType?.meta?.title}...`, {
                                autoHideDuration: 5000,
                                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                                variant: 'info'
                            })
                        },
                        onAfterLoading: (objectType) => {
                            mapDispatch({
                                type: 'objectTypeLoaded',
                                object_type_id: objectType.id,
                            })

                            enqueueSnackbar(`Layer loaded: ${objectType?.meta?.title}`, {
                                autoHideDuration: 3000,
                                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                                variant: 'info'
                            })
                        },
                        onPreparing: () => {

                        },
                        onReady: (objectTypes, cubes) => {
                            if (objectTypes?.length > 0) {
                                // Please wait a bit while rendering...
                                enqueueSnackbar(`All layers are loaded!`, {
                                    autoHideDuration: 5000,
                                    anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                                    variant: 'success'
                                })
                            }
                        }
                    })
                        .then(() => {

                            mapDispatch({
                                type: 'setCalculating',
                                isCalculating: false
                            })

                            // state second!
                            dataDispatch({
                                type: 'load',
                                state: saveableDataState(projectState.data),
                            })
                            mapDispatch({
                                type: 'load',
                                state: saveableMapState(projectState.state),
                            })

                            dataDispatch({
                                type: 'setDataLoaded',
                                isLoaded: true
                            })

                        }).then(() => {
                            mapDispatch({
                                type: 'setViewStateCoordinates',
                                longitude: projectCoordinates[0],
                                latitude: projectCoordinates[1],
                                zoom: projectScale
                            })
                        }).catch((e) => {
                            console.log('E_r1>', e)
                            mapDispatch({
                                type: 'setCalculating',
                                isCalculating: false
                            })
                        })
                }

            }).catch((e) => {
                console.log('E_r2>', e)
                window.location = window.location.toString()
            })
        }

    }, [projectData])

    useEffect(() => {
        if (meLoading || !meData) return

        dataDispatch({
            type: 'setMe',
            me: meData.projectMe[0]
        })
    }, [meData])

    useEffect(() => {
        if (activityObjects) {
            dataDispatch({
                type: 'addActivityObjects',
                activityObjects: activityObjects.comments.map(item => item.object_id).concat(
                    activityObjects.objectRanks.map(item => item.object_id)
                )
            })
        }
    }, [activityObjects])

    return (
        <DataStateContext.Provider value={{dataState, dataDispatch}}>
            <MapStateContext.Provider value={{mapState, mapDispatch}}>
                <MapStyleContext.Provider value={{mapStyle, mapStyleDispatch}}>
                    <CacheStateContext.Provider value={{cacheState, cacheDispatch}}>

                            <SaveStateProvider projectData={projectData} isLoaded={!projectLoading}>
                                <TopBar/>
                                <Main isProjectLoaded={isProjectLoaded} currentPage={currentPage}/>
                            </SaveStateProvider>

                    </CacheStateContext.Provider>
                </MapStyleContext.Provider>
            </MapStateContext.Provider>
        </DataStateContext.Provider>
    );
}

export default ProjectMap;