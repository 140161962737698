import {getDB} from "../../db";
import {isFieldObject, isFieldUUID} from "../../helpers/field";
import {getCubeQuantilesStruct} from "../../helpers/stats";
import {computeColor, getColorQuantile} from "../../helpers/color";
import {getMetricValueOrFirstSample} from "../../helpers/data";
import {DEFAULT_HEIGHT} from "../../helpers/const";
import {uuidParse} from "../../helpers/uuid";


export default function pointLayerDataFabric({layer, cube, filters, heightCube, mapDispatch}) {
    return async function getData() {
        const hasFilters = !!filters && filters?.length > 0

        mapDispatch({type: 'setCalculating', isCalculating: true})

        const DB = await getDB();

        const isColorMetric = layer.colorMetricId && layer.colorMetricField
        const colorFilters = layer.colorMetricFilters
        const isHeightMetric = layer.height?.metricId && layer.height?.field
        const heightJoinKey = heightCube?.struct.fields.find(item => item.is_object)
        const heightJoinKeyName = heightJoinKey?.name

        const dataTable = await DB.table(layer.object.key)
        const joinKey = cube?.struct.fields.find(item => item.is_object)
        const joinKeyName = joinKey?.name

        const hasColorMetric = isColorMetric && joinKey
        const hasHeightMetric = isHeightMetric && heightJoinKey

        const metricField = cube?.struct.fields.find(item => item.name === layer.colorMetricField)
        const isMetricFieldDict = metricField && isFieldUUID(metricField) && !isFieldObject(metricField)

        if (!dataTable) {
            return []
        }

        let collection = dataTable.toCollection()

        if (hasColorMetric) {
            collection = collection.joinFields({
                rootKey: 'id',
                tableKey: joinKeyName,
                tableName: cube.name,
                tableFields: {
                    [layer.colorMetricField]: 'metricValue'
                },
                aggregateFunction: isMetricFieldDict ? null : 'avg',
                filters: colorFilters
            })
        }

        if (hasHeightMetric) {
            collection = collection.joinFields({
                rootKey: 'id',
                tableKey: heightJoinKeyName,
                tableName: heightCube.name,
                tableFields: {
                    [layer.height?.field]: 'heightMetricValue'
                },
                aggregateFunction: 'avg',
            })
        }

        if (hasFilters) {
            filters.forEach(filter => {
                collection = collection.filter({
                    rootKey: 'id',
                    tableKey: filter.cube_object,
                    tableName: filter.cube_name,
                    filterFields: filter.fields
                })
            })
        }

        const t0 = Date.now()
        const result = await DB.run(collection.buildQuery())

        // console.log(`quering: t=${(Date.now() - t0)}, c=${result.numRows}`)

        const lngColumn = result.getChild('lng')
        const latColumn = result.getChild('lat')
        const elevationColumn = hasHeightMetric ? result.getChild('heightMetricValue') : null
        const metricValues = hasColorMetric ? result.getChild('metricValue').toArray() : null
        const identifiers = result.getChild('id').toArray()

        mapDispatch({
            type: 'setLayerCollection',
            layerId: layer.id,
            collection: collection.prepareUnconditionalQuery()
        })

        const batchCount = lngColumn.data.length

        const quantilesStruct = hasColorMetric
            ? getCubeQuantilesStruct(cube, layer.colorMetricField) : null

        const colorQuantile = quantilesStruct && Array.isArray(layer.color)
            ? getColorQuantile(layer.color, quantilesStruct) : null

        const geometryCount = lngColumn.length
        const positionArray = new Float32Array(geometryCount * 2)
        const startIndices = new Int32Array(geometryCount)
        const elevationArray = new Float32Array(geometryCount);
        const colorArray = new Uint8Array(geometryCount * 3)
        const identityArray = new Uint8Array(geometryCount * 16)

        let indexOffset = 0;
        let positionIndex = 0
        let startIndex = 0
        let colorIndex = 0
        let elevationIndex = 0
        let identityArrayIndex = 0
        for (let bi = 0; bi < batchCount; bi++) {
            const lngPoints = lngColumn.data[bi]
            const latPoints = latColumn.data[bi]
            const elevationVector = hasHeightMetric ? elevationColumn.data[bi].values : null
            const batchPointsCount = lngPoints.length

            for (let i = 0; i < batchPointsCount; i++) {
                positionArray[positionIndex++] = lngPoints.values[i]
                positionArray[positionIndex++] = latPoints.values[i]
                startIndices[startIndex++] = (indexOffset + i) * 2
                elevationArray[elevationIndex++] = hasHeightMetric
                    ? (elevationVector[i] ?? 0)
                    : (layer.height?.value ?? 0)

                const metricValue = hasColorMetric
                    ? getMetricValueOrFirstSample(metricValues[i + indexOffset] ?? null)
                    : null

                if (identifiers[i + indexOffset]) {
                    const byteIdentity = uuidParse(identifiers[i + indexOffset])
                    for(let ii = 0; ii < byteIdentity.length; ii++) {
                        identityArray[identityArrayIndex++] = byteIdentity[ii]
                    }
                } else {
                    for(let ii = 0; ii < 16; ii++) {
                        identityArray[identityArrayIndex++] = 0
                    }
                }

                const objectColor = computeColor(cube, layer.colorMetricField, metricValue, layer.color, colorQuantile)
                objectColor.forEach((colorByte, i) => {
                    colorArray[colorIndex++] = colorByte
                })
            }

            indexOffset += batchPointsCount
        }

        mapDispatch({type: 'setCalculating', isCalculating: false})

        const dataStruct = {
            length: geometryCount,
            // startIndices: startIndices,
            attributes: {
                getPosition: {value: positionArray, size: 2},
                getFillColor: {value: colorArray, size: 3},
                getIdentity: {value: identityArray, size: 16}
                // getElevation: {value: elevationArray, size: 1}
            }
        }

        return dataStruct
    }
}
