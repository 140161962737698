import React, {useContext, useState} from "react";
import {
    Paper,
    FormControlLabel, Switch,
    Button,
    Tooltip,
    IconButton, TextField, FormControl,
    InputAdornment

} from "@material-ui/core";

import {
    Delete,
    Done,
    Restore
} from '@material-ui/icons'

import {makeStyles} from '@material-ui/core/styles';

import FormSelector from './widgets/form-selector'
import ColorSelector from './widgets/color-selector'

import {
    MapStateContext,
    DataStateContext,
    MapStyleContext,
    saveableDataState,
    saveableMapState,
    saveableMapStyle
} from '../../../reducer'
import {HEIGHT_DEFAULT_STRUCTURE} from "../../../../../../helpers/const";
import {appConfig} from "../../../../../../config";
import {authAxios} from "../../../../../../clients/directAxios";
import {getToken} from "../../../../../../actions/auth";
import {useTranslation} from "react-i18next";
import HeightSelector from "./widgets/height-selector";
import LineWidthSelector from "./widgets/line-width-selector"
import RadiusSelector from "./widgets/radius-selector"
import OpacitySelector from "./widgets/opacity-selector"
import {toDeckLayerOption} from '../layer-fabric'
import {isDefined} from "../../../../../../helpers/fn";


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: 280,
        background: '#F5FAFF',
        borderRadius: 0,
        paddingBottom: 10,
        marginBottom: 10
    },
    title: {
        boxSizing: 'border-box',
        padding: '10px',
        margin: '0',
        fontWeight: 'normal',
        fontSize: '12pt',
        position: 'relative'
    },
    subtitle: {
        margin: '0 0 10px -5px',
        padding: 0
    },
    editLayerTitle: {
        marginTop: 0,
        width: 220
    },
    deleteLayerButton: {
        position: 'absolute',
        top: 6,
        right: 0,
        width: 40,
        height: 40,
    },
    layerControlButtons: {
        display: 'block',
        margin: '10px 0 10px -5px',
    },
    displayModeControl: {
        background: '#F5FAFF'

    },
    colorBaseControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    colorBaseGradControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    colorBaseGradNumberControl: {
        margin: theme.spacing(1),
        minWidth: 95,
    },
    oneColorControl: {
        margin: theme.spacing(1)
    },
    lineWidthControl: {
        margin: theme.spacing(1)
    },
    heightControl: {
        margin: theme.spacing(1)
    },
    opacityControl: {
        margin: theme.spacing(1)
    },
    gradientItem: {
        width: '100%',
        height: '40px',
    },
    layerControls: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    layerPublish: {
        marginTop: 4,
        marginLeft: -10,
        cursor: 'pointer'
    },
    layerOrderingControls: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(1)
    },
    heightSlider: {
        '& .MuiSlider-valueLabel': {
            fontSize: 10
        }
    },
    scaleSlider: {
        '& .MuiSlider-valueLabel': {
            fontSize: 10
        },
        '& .MuiSlider-markLabel': {
            fontSize: 10
        }
    },
    publishButton: {
        fontSize: '11px',
    },
    publishButtonIcon: {
        marginRight: '10px'
    },
}));

export default function LayerVisualization(props) {
    const classes = useStyles();
    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {dataState} = useContext(DataStateContext)
    const {mapStyle} = useContext(MapStyleContext)
    const {t} = useTranslation();

    const {
        id,
        isEnabled,
        isPublic,
        radius: layerRadius,
        opacity: layerOpacity,
        strokeColor: layerStrokeColor,
        colorMetricId: layerColorMetricId,
        colorMetricField: layerColorMetricField,
        colorMetricFilters: layerColorMetricFilters,
        colorScheme,
        layerType,
        color,
        gradSteps,
        gradIndex,
        filled,
        stroked,
        lineWidth,
        height,
        object,
        objectType,
    } = props.layer

    const {cube: exitCubes, metric: existMetrics, project} = dataState

    const realLayerTitle = isDefined(props.title) && props.title !== '' ? props.title : 'untitled'
    const metrics = existMetrics
        ? Object.values(existMetrics)
            .filter(metric => metric.object?.id === object?.id)
        : []

    const metricCubes = metrics?.map(item => exitCubes[item.id])
    const [isEditLayer, setEditLayer] = useState(false)
    const [layerTitle, setLayerTitle] = useState(null)


    const handleEnable = (newIsEnabled) => {
        mapDispatch({
            type: 'setLayerIsEnabled',
            layerId: id,
            isEnabled: newIsEnabled
        })
    }

    const handlePublicate = (newIsPublic) => {
        mapDispatch({
            type: 'setLayerIsPublic',
            layerId: id,
            isPublic: newIsPublic
        })
    }

    const layerOrderingHandler = (way) => {
        mapDispatch({
            type: 'setLayerOrdering',
            layerId: id,
            way
        })
    }

    const handleEditLayerTitle = () => {
        setLayerTitle(isDefined(props.title) && props.title !== '' ? props.title : '')
        setEditLayer(true)
    }

    const handleSaveLayerTitle = () => {
        mapDispatch({
            type: 'setLayerOption',
            layerId: id,
            option: {
                title: layerTitle
            }
        })
        setLayerTitle('')
        setEditLayer(false)
    }

    const apiClient = authAxios({
        auth: {
            access_token: getToken()
        }
    });

    const publishChanges = () => {
        apiClient.post(`${appConfig.api.base}/project/${project.id}/state/public`, {
            data: saveableDataState(dataState),
            state: saveableMapState(mapState, true),
            style: saveableMapStyle(mapStyle),
        }).then(() => {
        }).catch(() => {
        })
    }

    // const isFullColorMulti = Array.isArray(color) && !!layerColorMetricId && !!layerColorMetricField

    const isStrokeSchema = colorScheme === 'stroke'
    const isHeatmap = layerType === 'heatmap'
    const isObject = layerType === 'object'
    const isPoint = layerType === 'point'
    const isRadiusAvailable = ['point', 'heatmap'].indexOf(layerType) > -1
    const isLineWidthAvailable = !isHeatmap && !isObject && !isPoint
    const isFullColorAvailable = !isStrokeSchema || isHeatmap || isPoint
    const isStrokeColorAvailable = !isHeatmap && !isObject && !isPoint //   || !isFullColorMulti
    const defaultStrokeColor = !isFullColorAvailable ? '#000000' : null
    const isHeightAvailable = isObject
    const heightStructure = height ?? HEIGHT_DEFAULT_STRUCTURE
    const isGradOnly = isHeatmap

    const fullColorTitle = isHeatmap
        ? t('project.leftbar.panel2.primary_color')
        : t('project.leftbar.panel2.fill_color')

    const strokeColorTitle = isHeatmap
        ? t('project.leftbar.panel2.secondary_color')
        : t('project.leftbar.panel2.stroke_color')

    const isLegendEnabled = !isHeatmap

    const handleDeleteLayer = () => {
        mapDispatch({
            type: 'removeLayer',
            layerId: id,
        })
    }

    // const currentDataset = mapState.datasets[props.layerId];
    // const { metrics: datasetMetrics } = currentDataset;
    // const { colorMetricId } = mapState.layers[props.layerId]
    // const currentMetric = colorMetricId ? datasetMetrics.find(item => item.metric?.id === colorMetricId) : null
    // const datasetMetrics = metrics; // todo: enable this!!! metrics;

    return (
        <Paper className={classes.root}>
            <h4 className={classes.title}>
                {!isEditLayer &&
                <Tooltip title="Edit layer title">
                    <span onClick={handleEditLayerTitle}>{realLayerTitle}</span>
                </Tooltip>
                }
                {isEditLayer &&
                <TextField label={t('widgets.layer_new.layer_title')}
                           defaultValue={props.title ?? ''}
                           className={classes.editLayerTitle}
                           onChange={(e) => {
                               setLayerTitle(e.target.value)
                           }}
                           InputProps={{
                               endAdornment: (
                                   <InputAdornment position="end">
                                       <IconButton
                                           size="small"
                                           onClick={handleSaveLayerTitle}
                                       >
                                           <Done/>
                                       </IconButton>
                                   </InputAdornment>
                               )
                           }}
                />
                }
                <div className={classes.deleteLayerButton}>
                    <IconButton onClick={handleDeleteLayer} size="small"><Delete/></IconButton>
                </div>
            </h4>

            <div className={classes.layerControls}>
                <h5 className={classes.subtitle}>Controls</h5>

{/*
                <FormControl
                    className={classes.layerControlButtons}
                ><Button
                    size="small"
                    variant={"outlined"}
                >Source</Button></FormControl>
*/}

                <FormControlLabel
                    control={<Switch
                        size="small"
                        color="secondary"
                        checked={isEnabled}
                        onChange={() => handleEnable(!isEnabled)}
                    />}
                    label={t('project.leftbar.panel2.layer_is_enabled')}
                    className={classes.enabledControl}
                />

                <Tooltip title="Make layer available to invited users" arrow>
                    <FormControlLabel
                        control={<Switch
                            size="small"
                            color="secondary"
                            checked={isPublic ?? undefined}
                            onChange={() => handlePublicate(!isPublic)}
                        />}
                        label={t('project.leftbar.panel2.layer_is_public')}
                        className={classes.enabledControl}
                    />
                </Tooltip>
                <div className={classes.layerPublish}>
                    <Button
                        disabled={!isPublic}
                        size="small"
                        variant="text"
                        onClick={publishChanges}
                        className={classes.publishButton}
                    >
                        <Restore className={classes.publishButtonIcon} /> {t('project.leftbar.panel2.publish')}
                    </Button>
                </div>
            </div>


            {/*
            <div className={classes.layerOrderingControls}>
                <ButtonGroup
                    orientation="vertical"
                    color="primary"
                    aria-label="vertical contained primary button group"
                    variant="text"
                >
                    <IconButton onClick={() => layerOrderingHandler(-1)} size="small" key="i0">
                        <ArrowDropUpIcon />
                    </IconButton>
                    <IconButton onClick={() => layerOrderingHandler(1)} size="small" key="i1">
                        <ArrowDropDownIcon />
                    </IconButton>
                </ButtonGroup>
            </div>
*/}

            <FormSelector
                value={layerType}
                objectType={objectType}
                onChange={(layerType) => {

                    mapDispatch({
                        type: 'setLayerType',
                        layerId: props.layerId,
                        layerType
                    })
                }}
            />

            {isFullColorAvailable &&
            <ColorSelector
                id={id + 'full'}
                key={id + 'full'}
                isEnabled={filled}
                value={{
                    color,
                    gradSteps,
                    gradIndex
                }}
                gradOnly={isGradOnly}
                title={fullColorTitle}
                opacity={layerOpacity}
                metrics={metrics}
                cubes={metricCubes}
                baseMetric={layerColorMetricId}
                baseField={layerColorMetricField}
                baseFilters={layerColorMetricFilters}
                legendEnabled={isLegendEnabled}
                onChange={(newValue) => {
                    mapDispatch({
                        type: 'setLayerColor',
                        layerId: props.layerId,
                        options: newValue
                    })
                }}
                onToggle={(filled) => {
                    mapDispatch({
                        type: 'setLayerOption',
                        layerId: props.layerId,
                        layerOption: toDeckLayerOption(layerType, 'filled', filled),
                        option: {
                            filled
                        }
                    })
                }}
                onMetricIdChange={(colorMetricId) => {
                    mapDispatch({
                        type: 'setLayerColorMetricId',
                        colorMetricId,
                        layerId: props.layerId,
                    })
                }}
                onMetricFieldChange={(colorMetricField) => {
                    mapDispatch({
                        type: 'setLayerColorMetricField',
                        colorMetricField,
                        layerId: props.layerId,
                    })
                }}
                onMetricFiltersChange={(colorMetricFilters) => {
                    mapDispatch({
                        type: 'setLayerColorMetricFilters',
                        colorMetricFilters,
                        layerId: props.layerId,
                    })
                }}

            />
            }

            {isStrokeColorAvailable &&
            <ColorSelector
                id={id + 'stroke'}
                key={id + 'stroke'}
                isEnabled={stroked}
                defaultValue={defaultStrokeColor}
                value={{
                    color: layerStrokeColor,
                    gradSteps,
                    gradIndex
                }}
                title={strokeColorTitle}
                opacity={layerOpacity}
                metrics={metrics}
                cubes={metricCubes}
                baseMetric={layerColorMetricId}
                baseField={layerColorMetricField}
                baseFilters={layerColorMetricFilters}
                withoutBase={colorScheme !== 'stroke'}
                legendEnabled={isLegendEnabled}
                onChange={(newValue) => {
                    mapDispatch({
                        type: 'setLayerStrokeColor',
                        layerId: props.layerId,
                        options: {
                            strokeColor: newValue.color,
                            gradSteps: newValue.gradSteps,
                            gradIndex: newValue.gradIndex
                        }
                    })
                }}
                onToggle={(stroked) => {
                    mapDispatch({
                        type: 'setLayerOption',
                        layerId: props.layerId,
                        layerOption: toDeckLayerOption(layerType, 'stroked', stroked),
                        option: {
                            stroked
                        }
                    })
                }}
                onMetricIdChange={(colorMetricId) => {
                    mapDispatch({
                        type: 'setLayerColorMetricId',
                        colorMetricId,
                        layerId: props.layerId,
                    })
                }}
                onMetricFieldChange={(colorMetricField) => {
                    mapDispatch({
                        type: 'setLayerColorMetricField',
                        colorMetricField,
                        layerId: props.layerId,
                    })
                }}
                onMetricFiltersChange={(colorMetricFilters) => {
                    mapDispatch({
                        type: 'setLayerColorMetricFilters',
                        colorMetricFilters,
                        layerId: props.layerId,
                    })
                }}
                /*
                                onBaseMetricChange={(baseMetric) => {
                                    mapDispatch({
                                        type: 'setLayerColorBase',
                                        colorMetricId: baseMetric.id,
                                        colorMetricField: baseMetric.field,
                                        layerId: props.layerId,
                                    })
                                }}
                */
            />
            }

            {isHeightAvailable &&
            <HeightSelector
                id={id + 'height'}
                value={heightStructure}
                title={t('project.leftbar.panel2.height')}
                metrics={metrics}
                cubes={metricCubes}
                onChange={(height) => {
                    mapDispatch({
                        type: 'setLayerHeight',
                        layerId: props.layerId,
                        layerOption: toDeckLayerOption(layerType, 'height', height),
                        option: {
                            height
                        }
                    })
                }}
            />
            }

            {
                isLineWidthAvailable &&
                <LineWidthSelector
                    title={t('project.leftbar.panel2.line_width')}
                    value={lineWidth ?? 1}
                    onChange={(value) => {
                        mapDispatch({
                            type: 'setLayerOption',
                            layerId: props.layerId,
                            layerOption: toDeckLayerOption(layerType, 'lineWidth', value ?? 1),
                            option: {
                                lineWidth: value ?? 1
                            }
                        })
                        // handleOnChange(e, {opacity: value})
                    }}
                />
            }

            {
                isRadiusAvailable &&
                <RadiusSelector
                    title={t('project.leftbar.panel2.point_radius')}
                    value={layerRadius}
                    onChange={(value) => {
                        mapDispatch({
                            type: 'setLayerOption',
                            layerId: props.layerId,
                            layerOption: toDeckLayerOption(layerType, 'radius', value ?? 1),
                            option: {
                                radius: value ?? 1
                            }
                        })
                    }}
                />
            }

            <OpacitySelector
                title={t('project.leftbar.panel2.opacity')}
                value={layerOpacity}
                onChange={(value) => {
                    mapDispatch({
                        type: 'setLayerOption',
                        layerId: props.layerId,
                        layerOption: toDeckLayerOption(layerType, 'opacity', value),
                        option: {
                            opacity: value
                        }
                    })
                }}/>
        </Paper>
    )
}