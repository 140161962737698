import React, {useState, useEffect, useReducer, useContext, useCallback} from "react";
import Promise from 'promise'
import {LayerModel, DEFAULT_LAYER_TYPE} from "../../models/layer";
import {authAxios, authAxios_timeout} from '../../clients/directAxios'
import {PrepareIndexesAndStruct, FillTable, FillTableParquet, getDB, PROJECT_SCHEMA} from '../../db'
import {objectSchema} from '../../models/object'
import {ApiEndpoint, parquetUri, parquetOperationUri} from '../../config'
import {getToken} from '../auth'
import {normalizeDate, isFieldTimestamp, isFieldScalar} from '../../helpers/field'



const DEATH_LIMIT = 64000000;
const DEFAULT_LIMIT = 16000;
const DEFAULT_OT_LIMIT = 16000;

const dataApi = new ApiEndpoint('thedata')
const parquetApi = new ApiEndpoint('parquet')
const apiClient = authAxios({
    auth: {
        access_token: getToken()
    }
});

const apiClient_timeout = authAxios_timeout({
    auth: {
        access_token: getToken()
    }
})

// Promise.config({
//     cancellation: true
// })

const MAX_RETRY_COUNT = 10;

const getCubeIndexesStruct = (cube) => ({
    indexes: cube.struct.fields
        .filter(fld => fld.is_key).map(fld => ({
            fields: [fld.name]
        })),
    ...cube.struct
})

const loadCube = function (cube, project_id, mapDispatch, dataDispatch, loaded=0, retryCount=0) {
    mapDispatch({
        type: 'setCalculating',
        isCalculating: false
    })

    const dataUrl = parquetApi.endpoint(parquetUri(project_id, 'cube', cube.id))

    return FillTableParquet(cube.name, dataUrl).then(() => {
        return PrepareIndexesAndStruct(cube.name, getCubeIndexesStruct(cube))
    }).then(() => {
        dataDispatch({
            type: 'cubeLoaded',
            cube_id: cube.id,
        })

        return CalculateStats(cube).then((fieldStats) => {
            dataDispatch({
                type: 'addCubeStats',
                cubeId: cube.id,
                stats: fieldStats
            })
        })
    }).then(() => {

        mapDispatch({
            type: 'setCalculating',
            isCalculating: false
        })
    }).catch(() => {
        mapDispatch({
            type: 'setCalculating',
            isCalculating: false
        })
    })

    // apiClient_timeout.post(dataApi.endpoint('/cubedata'), {
    //     cube_id: cube.id,
    //     project_id,
    //     limit: DEFAULT_LIMIT,
    //     offset: loaded
    // }).then((response) => {
    //     FillTable(response.data)
    //
    //     dispatch({
    //         type: 'cubeLoaded',
    //         cube_id: cube.id,
    //         loaded: loaded + response.data.count,
    //         count: cube.count
    //     })
    //
    //     // if (loaded + response.data.count < cube.count) {
    //     //     loadCube(cube, project_id, dispatch, loaded + DEFAULT_LIMIT)
    //     // }
    // }).then(() => {
    //     dispatch({
    //         type: 'setCalculating',
    //         isCalculating: false
    //     })
    // }).catch((e) => {
    //
    //     console.log('ERR>', e)
    //
    //     dispatch({
    //         type: 'setCalculating',
    //         isCalculating: false
    //     })
    //
    //     if (retryCount > MAX_RETRY_COUNT) return e
    //     return loadCube(cube, project_id, dispatch, loaded, retryCount + 1)
    // })
}

const LOAD_OBJECT_DEFAULT_PROPS = {
    loaded: 0,
    retryCount: 0
}

const loadObjectType = function (props) {
    const {ot, project_id, dispatch, loaded, bbox, zoom, cubes, retryCount} = {...LOAD_OBJECT_DEFAULT_PROPS, ...props}

    dispatch({
        type: 'setCalculating',
        isCalculating: true
    })

    return apiClient_timeout.post(dataApi.endpoint('/objectdata'), {
        object_type_id: ot.id,
        project_id,
        limit: DEFAULT_OT_LIMIT,
        offset: loaded,
        bbox,
        zoom,
        cubes,
    }).then((response) => {
        const data = {
            ...response.data,
            data: response.data.data.map(item => ({
                ...item,
                meta: JSON.stringify(item.meta),
                tags: JSON.stringify(item.tags ? item.tags : []),
                geom_type: item.geom.type,
                geom: item.geom.type === 'Point'
                    ? [[[item.geom.coordinates]]] : item.geom.type === 'MultiPoint'
                        ? [[item.geom.coordinates]] : item.geom.type === 'Polygon' || item.geom.type === 'MultiLineString'
                            ? [item.geom.coordinates] : item.geom.type === 'LineString'
                                ? [[item.geom.coordinates]] : item.geom.coordinates
            }))
        }
        return FillTable(data).then(() => {
            dispatch({
                type: 'objectTypeLoaded',
                object_type_id: ot.id,
                loaded: loaded + response.data.count,
                count: ot.count,
                objects: response.data
            })
        })


        // if (loaded + response.data.count < ot.count) {
        //     loadObjectType(ot, project_id, dispatch, loaded + DEFAULT_OT_LIMIT)
        // }
    }).then(() => {
        dispatch({
            type: 'setCalculating',
            isCalculating: false
        })
    }).catch((e) => {
        console.log(e)
        dispatch({
            type: 'setCalculating',
            isCalculating: false
        })

        if (retryCount > MAX_RETRY_COUNT) return e
        return loadObjectType({ot, project_id, dispatch, loaded, bbox, zoom, cubes, retryCount: retryCount + 1})
    })
}

const loadCubeInfo = function (cube_id, project_id, loadedCubes, mapDispatch, dataDispatch, retryCount = 0) {
    const cubeStatUrl = parquetApi.endpoint(`/${project_id}/cube/${cube_id}/stat`)
    return apiClient_timeout.get(cubeStatUrl).then((response) => {
        const cube = response.data
        loadedCubes.push(cube)
        dataDispatch({
            type: 'addCube',
            cube
        })
        return loadCube(cube, project_id, mapDispatch, dataDispatch)
    }).catch((e) => {
        if (retryCount > MAX_RETRY_COUNT) return e
        return loadCubeInfo(cube_id, project_id, loadedCubes, mapDispatch, dataDispatch, retryCount + 1)
    });
}

const loadObjectTypeInfo = function (object_type_id, project_id, loadedObjectTypes, mapDispatch, dataDispatch, cubes, retryCount = 0) {
    const objStatUrl = parquetApi.endpoint(`/${project_id}/object/${object_type_id}/stat`)
    return apiClient_timeout.get(objStatUrl).then((response) => {
        const objectType = response.data
        loadedObjectTypes.push(objectType)
        dataDispatch({
            type: 'addObjectType',
            objectType
        })
        return loadObjects(objectType, project_id, mapDispatch, dataDispatch)
    }).catch((e) => {
        if (retryCount > MAX_RETRY_COUNT) return e
        return loadObjectTypeInfo(object_type_id, project_id, loadedObjectTypes, mapDispatch, dataDispatch, cubes,retryCount + 1)
    })
}

const loadObjectType_withReloading = (ot, project_id, dispatch, loaded = 0, bbox, zoom) => {
    return loadObjectType({ot, project_id, dispatch, loaded, bbox, zoom}).catch(() => {
        return loadObjectType_withReloading(ot, project_id, dispatch, loaded = 0, bbox, zoom)
    })
}



export function deleteObjectTypeAction(objectTypeToDeleteId, states) {
    const {mapContext, dataContext} = states;
    const [mapState, mapDispatch] = mapContext;
    const [dataState, dataDispatch] = dataContext;

    const {
        metric: existMetrics, // same as metrics
        cube: existCubes,
        objectType: existObjectType,
    } = dataState;

    const metricsToDelete = Object.values(existMetrics).filter(metric => metric.object.id === objectTypeToDeleteId)
    const metricsToDeleteIDs = metricsToDelete.map(metric => metric.id)
    const cubesToDelete = Object.values(existCubes).filter(cube => metricsToDeleteIDs.indexOf(cube.id) > -1)
    const objectToDelete = existObjectType[objectTypeToDeleteId]
    const currentObjectTypes = Object.values(existObjectType).filter(item => item.id !== objectTypeToDeleteId)
    const currentMetrics = Object.values(existMetrics).filter(item => metricsToDeleteIDs.indexOf(item.id) === -1)
    const currentCubes = Object.values(existCubes)
        .filter(cube => metricsToDeleteIDs.indexOf(cube.id) === -1)
        .reduce((cubes, cube) => ({...cubes, [cube.id]: cube}), {})

    dataDispatch({
        type: 'setObjectType',
        objectType: currentObjectTypes.reduce((objectTypes, objectType) => ({
            ...objectTypes,
            [objectType.id]: objectType
        }), {})
    })

    mapDispatch({
        type: 'removeLayerByObjects',
        objectIds: objectTypeToDeleteId
    })

    dataDispatch({
        type: 'setMetric',
        metric: currentMetrics.reduce((metrics, metric) => ({
            ...metrics,
            [metric.id]: metric
        }), {})
    })

    dataDispatch({
        type: 'setCubes',
        cube: currentCubes
    })

    getDB().then((db) => {
        return Promise.all(
            [db.dropTable(objectToDelete.name)].concat(cubesToDelete.map(cube => db.dropTable(cube.name))))
    }).then(() => {
        // ...
    }).catch((e) => {
        console.log('DEL_e>', e)
    })
}


export function deleteMetricAction(metricToDeleteId, states) {
    const {mapContext, dataContext} = states;
    const [mapState, mapDispatch] = mapContext;
    const [dataState, dataDispatch] = dataContext;

    const {
        metric: existMetrics, // same as metrics
        cube: existCubes,
        objectType: existObjectType,
    } = dataState;

    const metricToDelete = existMetrics[metricToDeleteId]
    const currentMetrics = Object.values(existMetrics).filter(item => item.id !== metricToDeleteId)

    dataDispatch({
        type: 'setMetric',
        metric: currentMetrics.reduce((metrics, metric) => ({
            ...metrics,
            [metric.id]: metric
        }), {})
    })

    const needToDeleteLayer = currentMetrics.filter(item => item.object.id === metricToDelete.object.id).length === 0

    if (needToDeleteLayer) {
        mapDispatch({
            type: 'removeLayerByObjects',
            objectIds: [metricToDelete.object.id]
        })
    }

    // dataDispatch({
    //     type: 'removeCubeById',
    //     cubeId: metricToDelete.id
    // })

    const currentCubes = Object.values(existCubes)
        .filter(cube => cube.id !== metricToDelete.id)
        .reduce((cubes, cube) => ({
            ...cubes,
            [cube.id]: cube
        }), {})

    const currentObjectTypes = Object.values(existObjectType)

    dataDispatch({
        type: 'setCubes',
        cube: currentCubes
    })
}


export function loadObjects(objectType, project_id, mapDispatch, dataDispatch, bbox, zoom) {
    mapDispatch({
        type: 'setCalculating',
        isCalculating: false
    })

    const dataUrl = parquetApi.endpoint(parquetUri(project_id, 'object', objectType.id))

    return FillTableParquet(objectType.name, dataUrl).then(() => {
        return PrepareIndexesAndStruct(objectType.name, objectSchema)
    }).then(() => {
        mapDispatch({
            type: 'objectTypeLoaded',
            object_type_id: objectType.id,
        })

        mapDispatch({
            type: 'setCalculating',
            isCalculating: false
        })
    }).catch((e) => {
        console.log('OTL_e', e)
        mapDispatch({
            type: 'setCalculating',
            isCalculating: false
        })
    })
}


export function selectMetricsAction({metrics, states,
                                        onBeforeLoading, onAfterLoading,
                                        onBeforeLoadingCube, onAfterLoadingCube,
                                    }) {

    const {mapContext, dataContext} = states;
    const [mapState, mapDispatch] = mapContext;
    const [dataState, dataDispatch] = dataContext;

    const {
        layers: existLayers,
    } = mapState;

    let layerNextOrdering = existLayers
        ? Object.values(existLayers).reduce((ord, item) => item.ordering > ord ? item.ordering : ord, 0) + 1
        : 0

    mapDispatch({
        type: 'setLoading',
        isLoading: true
    })

    const {
        metric: existMetrics, // same as metrics
        cube: existCubes,
        objectType: existObjectTypes,
        project
    } = dataState;

    dataDispatch({
        type: 'setMetric',
        metric: metrics.reduce((metrics, metric) => ({
            ...metrics,
            [metric.id]: metric
        }), {})
    })

    const newObjectTypes = metrics
        .filter(metItem => Object.keys(existObjectTypes).indexOf(metItem.object.id) === -1)
        .reduce((acc, metric) => ({
            ...acc,
            [metric.object.id]: {
                ...metric.object,
                name: metric.object.key
            }
        }), {})

    const addedLayers = {}

    const newMetrics = Object.values(metrics)
        .filter(metric =>
            Object.keys(existMetrics).find(existMetricId => metric.id === existMetricId) === undefined)

    newMetrics.forEach(metric => {
        const existLayer = Object.values(existLayers)
            .concat(Object.values(addedLayers))
            .find(layer => layer.object.id === metric.object.id)

        if (!existLayer) {
            const defaultLayerType = metric.object.layerTypes.find(item => item.is_default)?.key || DEFAULT_LAYER_TYPE
            const layer = LayerModel({
                title: metric.object.meta.title,
                color: metric.color,
                object: metric.object,
                metrics: metric.is_metric ? [metric] : [],
                layerType: defaultLayerType,
                objectType: metric.object,
                colorScheme: metric.object.colorScheme,
                ordering: layerNextOrdering++
            })

            addedLayers[layer.id] = layer
        } else {
            existLayer.metrics.push(metric)
        }
    })

    const deletedObjectsIds = Object.values(existLayers)
        .filter(layer => metrics.find(metric => metric.object.id === layer.object.id) === undefined)
        .map(layer => layer.object.id);

    if (newMetrics.length || newObjectTypes.length) {
        const loadedCubes = []
        const loadedObjectTypes = [];

        const newCubesList = newMetrics.filter(metricItem => metricItem.is_metric)
        const newObjectCubes = newMetrics.reduce((acc, item) => ({
            ...acc,
            [item.object.id]: item.is_metric
                ?  acc[item.object.id]
                    ? acc[item.object.id].concat([item.id])
                    : [item.id]
                : []
        }), {})

        const listOfPromises = newCubesList.map(item =>{
            if (onBeforeLoadingCube) onBeforeLoadingCube(item)

            return loadCubeInfo(item.id, project.id, loadedCubes, mapDispatch, dataDispatch).then(() => {
                if (onAfterLoadingCube) onAfterLoadingCube(item)
            })
        }).concat(Object.values(newObjectTypes).map(item =>{
            if (onBeforeLoading) onBeforeLoading(item)
            return loadObjectTypeInfo(item.id, project.id, loadedObjectTypes, mapDispatch, dataDispatch, newObjectCubes[item.id]).then(() => {
                if (onAfterLoading) onAfterLoading(item)
            })
        }))

        Promise.all(listOfPromises).then(() => {
            mapDispatch({
                type: 'setLoading',
                isLoading: false
            })

            mapDispatch({
                type: 'reassembleLayers',
                removedObjectsIds: deletedObjectsIds,
                addedLayers: addedLayers
            })
        }).catch((e) => {
            console.log('E>', e)
            mapDispatch({
                type: 'setLoading',
                isLoading: false
            })
        })
    } else {
        // just stop loading
        mapDispatch({
            type: 'setLoading',
            isLoading: false
        })
        mapDispatch({
            type: 'reassembleLayers',
            removedObjectsIds: deletedObjectsIds,
            addedLayers: addedLayers
        })
    }
}


export const NATURAL_ORDERING = null
export async function getCubeData(cube, filters = [], ordering = NATURAL_ORDERING, enabled= true, keysOnly = false, limit = undefined, offset = 0) {

    const db = await getDB()
    const cubeName = cube?.name;
    const cubeFields = keysOnly
        ? cube.struct.fields.filter((fld, i) => fld.is_key)
        : cube.struct.fields

    if (!cubeFields || cubeFields.length === 0)
        return []

    const t0 = (new Date()).getTime()

    let collection = (await db.table(cubeName)).toCollection()

    const hasFilters = Object.keys(filters).length > 0

    let query = enabled && hasFilters
        ? cubeFields.reduce((query, field) => {
                const values = filters[field.name]?.naturalValue
                const isTimestamp = isFieldTimestamp(field)  // field.type.indexOf('timestamp') > -1
                const isScalar = isFieldScalar(field)

                if (values && values.length) {

                    if (isTimestamp) {
                        const dateValues = values.map(val => normalizeDate(val))
                        return query.between(field.name, dateValues[0], dateValues[1])
                        // return query.in(field.name, dateValues)
                    }

                    if (isScalar) {
                        return query.between(field.name, values[0], values[1])
                    }

                    return query.in(field.name, values)
                }

                return query
            }, collection)
        : collection

    if (limit) query = query.limit(limit)
    if (offset) query = query.offset(offset)

    let result = await query.toArray();

    const t1 = (new Date()).getTime() - t0
    console.log('T>', t1)

    return result ? result : [];
}

export async function CalculateStats(cube) {
    const db = await getDB()
    const tableName = cube?.name;
    // const keyFields = cube.struct.fields.filter((fld, i) => fld.is_key)
    const scalarFields = cube.struct.fields.filter((fld, i) => !fld.is_key)

    const percentGroups = [3, 4, 5, 6, 7, 8, 9, 10, 11, 20]

    const fieldStats = {}

    const rows = await Promise.all(scalarFields.map(async (field) => {
        const quantileFields = []

        for(let i in percentGroups) {
            const percentGroup = percentGroups[i]
            for (let percent = 1; percent < percentGroup; percent++) {
                quantileFields.push(`(percentile_disc(${percent}.0/${percentGroup}) WITHIN GROUP (ORDER BY ${field.name}))::float as q_${percentGroup}_${percent}`)
            }
        }

        const query = `
            select                
                min(${field.name})::float as min,
                max(${field.name})::float as max,
                avg(${field.name})::float as avg,
                sum(${field.name})::float as sum,
                count(${field.name})::integer as count,
                ${quantileFields.join(', ')}
            from ${PROJECT_SCHEMA}."${tableName}"            
        `
        // засунуть q_1_1 в quantiles и сумма тупит
        try {
            const value = Object.fromEntries((await db.run(query)).toArray()[0])
            fieldStats[field.name] = {
                min: value.min,
                max: value.max,
                avg: value.avg,
                sum: value.sum,
                count: value.count,
                quantiles: Object.entries(value)
                    .filter(([k, v]) => String(k).startsWith('q_'))
                    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
            }
        } catch (e) {
console.log('stat E>', e)
        }

        return fieldStats[field.name]
    }))

    return fieldStats
}

const enrichMetricsWithObject = (metrics, objectType, objectId) => Object
    .fromEntries(Object.entries(metrics).map(([id, metric]) => ([id, {
        [objectType.key]: objectId,
        ...metric
    }])))

export const addObjectAction = function ({
    projectId,
    objectTypeId,
    tableName,
    newObject,
}) {
    return getDB().then((db) => {
        db.insertItem(tableName, newObject).then(() => {
            const addObjectUrl = parquetApi.endpoint(parquetOperationUri(projectId, 'object', objectTypeId, 'add'))
            return apiClient.post(addObjectUrl, newObject)
        }).catch(console.error)
    })
}

export const updateObjectAction = function ({
                                             projectId,
                                             objectType,
                                             tableName,
                                             cubes, object, metrics,
                                         }) {
    return getDB().then((db) => {
        const updatePromises = [db.updateItem(tableName, object, 'id', object.id)].concat(Object.entries(metrics).map(
            ([metricId, metric]) => db.upsertItems(cubes[metricId].name, metric, objectType.key, object.id)
        ))

        return Promise.all(updatePromises).then(() => {
            const updateObjectUrl = parquetApi.endpoint(parquetOperationUri(projectId, 'object', objectType.id, 'edit'))
            return apiClient.post(updateObjectUrl, {
                object,
                metrics: enrichMetricsWithObject(metrics, objectType, object.id)
            })
        }).catch(console.error)
    })
}

export const deleteObjectAction = function ({
                                                projectId,
                                                objectType,
                                                tableName,
                                                object,
                                            }) {
    return getDB().then((db) => {
        return db.deleteItem(tableName, 'id', object.id).then(() => {
            const deleteObjectUrl = parquetApi.endpoint(parquetOperationUri(projectId, 'object', objectType.id, 'delete'))
            return apiClient.post(deleteObjectUrl, object)
        }).catch(console.error)
    })
}