/**
 * Created by stainwoortsel on 24.04.2020.
 */

const env = {
  // base: "http://localhost:8111",
  // data: "",
  base: "https://api.lokk-platform.com/v1.1",
  data: "/data/v1",
  authBase: "https://api.lokk-platform.com",
  authLokk: '/auth/v1/lokk',
  graphqlBase: "https://api.lokk-platform.com",
  graphql: "/v1/graphql",
  dataset: "/dataset",
  dataview: "/dataview",
  geocode: '/geocode',
  thedata: "/data",
  parquet: "/parquet",
  alg: '/alg',
  auth_service: 'https://auth.lokk-platform.com',
  jwt_secret: 'kf90349j6ca7D5RfyHyQvdkYRRWrD4ro',
};

const IS_DEMO = false
const IS_EXPERIMENTAL = false

export const appConfig = {
  mapbox: {
    token: 'pk.eyJ1IjoibG9ra2kiLCJhIjoiY2tnZXRuMmNvMGt3NDJ5czR1bDZtcjFnMSJ9.qJ5YC4avQy4JK1_as3kllw',
    // 'pk.eyJ1Ijoic3RhaW53b29ydHNlbCIsImEiOiJjazlqNjIwdmIwMzRxM2VtaHIxZHFwZXJkIn0.u1vdFsRvuWW-gAmOsflYpQ',
    style: {
      dark: 'mapbox://styles/mapbox/dark-v9',
      bright: 'mapbox://styles/lokki/ckgevni03cqpq1as4qfhga8rs'
    },
    base: 'https://api.mapbox.com',
    type: 'json',
    geocoding: '/geocoding/v5/mapbox.places/'
  },
  api: {
    base: env.base,
    type: "json",
    data: env.base + env.data,
    auth: env.authBase + env.authLokk,
    graphql: env.graphqlBase + env.graphql,
    dataset: env.base + env.dataset,
    dataview: env.base + env.dataview,
    geocode: env.base + env.geocode,
    thedata: env.base + env.thedata,
    parquet: env.base + env.parquet,
    alg: env.base + env.alg,
  },
  auth: {
    service: env.auth_service,
    secret: env.jwt_secret,
  },
  isAlgEnabled: !IS_DEMO,
  isExperimentalEnabled: IS_EXPERIMENTAL && !IS_DEMO,
  isSettingsEnabled: true,
  isProduction: process.env.NODE_ENV === "production",
  isDemo: IS_DEMO,
  version: process.env.VERSION,
  debugToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhIiwiYWRtaW4iOmZhbHNlLCJleHAiOjE5NDEzNjg4MjAsImlhdCI6MTYyNjAwODgyMCwidXNlcl9pZCI6ImQzOWI5OWZjLWVmNmQtZWU2Yy04ZjhiLTNkMzBkNzc1NTFmMSIsInVzZXJuYW1lIjoic3RhaW53b29ydHNlbEBnbWFpbC5jb20iLCJwcm90b2NvbCI6ImRlZmF1bHQiLCJoYXN1cmFfY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsidXNlciJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJ1c2VyIiwieC1oYXN1cmEtdXNlci1pZCI6ImQzOWI5OWZjLWVmNmQtZWU2Yy04ZjhiLTNkMzBkNzc1NTFmMSIsIngtaGFzdXJhLW9yZy1pZCI6ImQzOWI5OWZjLWVmNmQtZWU2Yy04ZjhiLTNkMzBkNzc1NTFmMSJ9fQ.I1wKlaZHuO11oE4rThkVw68pZHesffR3FGg_JuP61Xc'
};

export const ApiEndpoint = function (api) {
  this.apiName = api;
  this.apiBase = appConfig.api[this.apiName];
  this.endpoint = function (endpoint, params) {
    if (endpoint.length && endpoint[0] !== "/") {
      endpoint = "/" + endpoint;
    }
    if (params) {
      endpoint +=
        "?" +
        Object.keys(params)
          .map((k) => {
            return k + "=" + params[k];
          })
          .join("&");
    }

    return this.apiBase + endpoint;
  };
};

export const apiUrl = (api, endpoint) => {
  return appConfig.api[api] + endpoint;
};

export const parquetUri = (projectId, itemType, itemId) => `/${projectId}/${itemType}/${itemId}.parquet`
export const parquetOperationUri = (projectId, itemType, itemId, operation) => `/${projectId}/${itemType}/${itemId}/${operation}`
export const parquetAddUri = (projectId, itemType, itemId) => `/${projectId}/${itemType}/${itemId}/add`
export const parquetEditUri = (projectId, itemType, itemId) => `/${projectId}/${itemType}/${itemId}/edit`
export const parquetStatsUri = (projectId, itemType, itemId) => `/${projectId}/${itemType}/${itemId}/stats`

export const webLink = (entity, slug, params) => {
  switch (entity) {
    case 'root':
      return '/';
    case "project":
      return "/" + entity + "/" + slug + (params ? `?${params}` : '');
    default:
      return "/" + entity + "/" + slug + (params ? `?${params}` : '');
  }
};

export const geocoderLink = (url, params) => {
  params = {...(params ? params : {}),
    'access_token': appConfig.mapbox.token
  }

  const paramString = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');

  return appConfig.mapbox.geocoding + url + '.json?' + paramString;
}
