import React from "react";
import {
    Button,
    InputLabel, TextField,
} from "@material-ui/core";
// import { ExpandMore } from '@material-ui/icons';
// import { styled } from '@material-ui/core/styles';
import css from 'styled-components'

const AuthPanel = css.div`
    position: fixed;
    max-width: 400px;    
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
`

const AuthPage = (props) => {
    return (
        <AuthPanel>
            <TextField
                label={'Login'}
                value={'test'}
            />
            <TextField
                label={'Password'}
                type={'password'}
                value={'test'}
            />
            <Button
                variant="contained"
                color="primary"
            >
                Sign In
            </Button>
        </AuthPanel>
    );
}

export default AuthPage;