export const asc = arr => arr.sort((a, b) => a - b);

export const desc = arr => arr.sort((a, b) => b - a);

export const arrayMin = arr => arr.reduce((theMin, item) => (item < theMin ? item : theMin), arr[0])

export const arrayMax = arr => arr.reduce((theMin, item) => (item > theMin ? item : theMin), arr[0])

export const sum = arr => arr.reduce((a, b) => a + b, 0);

export const mean = arr => sum(arr) / arr.length;

// sample standard deviation
export const std = (arr) => {
    const mu = mean(arr);
    const diffArr = arr.map(a => (a - mu) ** 2);
    return Math.sqrt(sum(diffArr) / (arr.length - 1));
};

export const quantile = (arr, q) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

export const getCubeStats = (cube, field) => {
    return cube?.stats && field ? cube.stats[field] : null
}

export const getCubeQuantilesStruct = (cube, field) => {
    const fieldStats = getCubeStats(cube, field)
    const quantiles = fieldStats?.quantiles

    return  quantiles ? Object.entries(quantiles).reduce((total, item) => {
        const [key, value] = item
        const [x, j, i] = key.split('_')

        const row = total[j] ? {
            ...total[j],
            [i]: value
        } : {
            [i]: value
        }
        return {
            ...total,
            [j]: row
        }
    }, {}) : null

}

export const getCubeDictValues = (cube, field) => {
    return cube?.dicts.find(d => d.field === field)?.values
}