import objectLayerDataFabric from './objectLayerDataFabric'
import polygonLayerDataFabric from './polygonLayerDataFabric'
import defaultLayerDataFabric from './defaultLayerDataFabric'
import lineLayerDataFabric from './lineLayerDataFabric'
import pointLayerDataFabric from './pointLayerDataFabric'
import heatmapLayerDataFabric from './heatmapLayerDataFabric'
import userLayerDataFabric from './userLayerDataFabric'

const DEFAULT_FABRIC = 'default'
export const dataFabrics = {
    'object': objectLayerDataFabric,
    'polygon': polygonLayerDataFabric,
    'line': lineLayerDataFabric,
    'point': pointLayerDataFabric,
    'heatmap': heatmapLayerDataFabric,
    'userActivity': userLayerDataFabric,
    [DEFAULT_FABRIC]: defaultLayerDataFabric,
}

export default function dataFabric(props) {
    const {layer} = props
    return Object.keys(dataFabrics).indexOf(layer.layerType) > -1
        ? dataFabrics[layer.layerType](props)
        : dataFabrics[DEFAULT_FABRIC](props)
}

