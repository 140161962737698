import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {
    Divider, FormControl, InputLabel, MenuItem, Select, Slider, Typography,
    RadioGroup, Radio, FormControlLabel, Button, Grid, Tooltip, Avatar, Input, IconButton
} from "@material-ui/core";

import {operandKey} from "../../../../helpers/alg";
import {OperandForm} from './operandForm'
import Scrollbar from "react-perfect-scrollbar";
import {Close} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    root: {
        width: 280,
        background: '#D9DFE4',
        borderRadius: 0,
        padding: theme.spacing(1),
        boxSizing: 'border-box'
    },
    selectMetric: {
        width: 100
    },
    selectField: {
        width: 80
    },
    selectValueField: {
        width: 190
    },
    selectValue: {
        width: '100%'
    },
    title: {
        boxSizing: 'border-box',
        padding: '1rem 10px',
        margin: '0'
    },
    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    fieldChip: {
        display: 'inline-block',
        maxWidth: 120,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '12px',
        lineHeight: '32px',
    },

    operandRow: {
        margin: '2px 0'
    },

    inputControl: {
        width: '100%',
        textAlign: 'right'
    },

    addItemButton: {
        paddingTop: '1rem',
        width: '100%',
    },

    controlDivider: {
        margin: '1rem 0'
    },

    selectFieldPlace: {
        marginLeft: 10
    },

    lineWidthControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));


const WeightedGravityIndex = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        struct,
        params,
        objectType,
        onChangeParams,
    } = props;

    const [operands, setOperands] = useState({})

    const handleChangeWeight = (key, value) => {
        const weight = parseFloat(value)

        if (!Number.isNaN(weight)) {
            setOperands(oldOperands => ({
                ...oldOperands,
                [key]: {
                    ...oldOperands[key],
                    weight
                }
            }))
        }
    }

    const handleDeleteOperand = (key) => {
        setOperands(oldOperands => (Object.keys(oldOperands)
            .filter(existKey => existKey !== key)
            .reduce((operands, opKey) => ({
                ...operands,
                [opKey]: oldOperands[opKey]
            }), {})))
    }

    const MIN_RADIUS = 500;
    const MAX_RADIUS = 5000;
    const [gravityRadius, setGravityRadius] = useState(MIN_RADIUS);

    useEffect(() => {
        if (onChangeParams) {
            onChangeParams({
                operands,
                gravityRadius
            })
        }
    }, [operands, gravityRadius])

    return (
        <div>
            <div className={classes.lineWidthControl}>
                <Typography id="non-linear-slider" gutterBottom>
                    {t('project.leftbar.panel5.gravity')}
                </Typography>

                <Slider
                    value={gravityRadius}
                    min={MIN_RADIUS}
                    step={100}
                    max={MAX_RADIUS}
                    getAriaValueText={(value) => (value + 'm')}
                    valueLabelFormat={(value) => (value + 'm')}
                    onChange={(e, value) => {
                        setGravityRadius(value)
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                />
            </div>

            {
                Object.values(operands).length > 0 &&
                <div>
                    <Scrollbar>

                        <Grid container className={classes.operandRow}>
                            <Grid item xs={8}>
                                {t('project.leftbar.panel5.metric_and_field')}
                            </Grid>
                            <Grid item xs={4}>
                                {t('project.leftbar.panel5.weight')}
                            </Grid>
                        </Grid>
                        {
                            Object.entries(operands).map(((operandKeyPair) => {
                                const [key, operand] = operandKeyPair
                                return (
                                    <Grid container key={key} className={classes.operandRow}>
                                        <Grid item xs={2}>
                                            <Tooltip title={operand.tag}>
                                                <Avatar style={{
                                                    // backgroundColor: operand.metric.color
                                                }} className={classes.litera}>{operand?.title[0]}</Avatar>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title={operand.title}>
                                                <span className={classes.fieldChip}>{operand.title}</span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Input
                                                type="number"
                                                className={classes.inputControl}
                                                value={operand.weight}
                                                onChange={(e) => handleChangeWeight(key, e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" onClick={() => handleDeleteOperand(key)}>
                                                <Close style={{fontSize: 14}}/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            }))
                        }
                    </Scrollbar>
                </div>
            }

            <Divider className={classes.controlDivider} />

            <OperandForm onAddOperand={(operand) => {
                setOperands(existOperands => ({
                    ...existOperands,
                    [operandKey(
                        operand.object_type_id,
                        operand.tag || operand.metric_id,
                        operand.value || operand.field
                    )]: operand
                }))
            }} />

        </div>
    )
}

export default WeightedGravityIndex;
