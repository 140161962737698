import jwt from 'jsonwebtoken'
import { appConfig } from '../config.js'
import Cookies from 'js-cookie'

const gotoAuth = () => window.location = appConfig.auth.service


export const getToken = () => {

    if (!appConfig.isProduction) {
        return appConfig.debugToken
    }

    return Cookies.get('token')
}

export const getPayload = (token) => {

    if (!token) {
        token = getToken()
    }

    return jwt.verify(token, appConfig.auth.secret)
}

export const getUser = (payload) => {

    if (!payload) {
        const token = getToken()
        payload = getPayload(token)
    }

    return payload['hasura_claims']['x-hasura-user-id'];
}


export const checkAuth = () => {
    const token = getToken()

    try {
        const payload = getPayload(token)
        if (payload.exp * 1000 < (new Date()).getTime()) {
            gotoAuth()
            return false
        }

        if (!getUser(payload)) {
            gotoAuth()
            return false
        }
    } catch (e) {
        gotoAuth()
        return false
    }

    return true;
}

export const logOut = () => {
    Cookies.remove('token')
    gotoAuth()
}