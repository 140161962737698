import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';


export function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

export function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

export function CloseSquare(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

export function CheckedSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}

            <path d="M23.4,0.6C23,0.2,22.6,0,22.1,0H1.9c-0.5,0-1,0.2-1.4,0.6C0.2,1,0,1.4,0,1.9V22c0,0.5,0.2,1,0.6,1.4
			C0.9,23.8,1.4,24,1.9,24H22c0.5,0,1-0.2,1.4-0.6S24,22.6,24,22V1.9C24,1.4,23.8,1,23.4,0.6z M22,22.1H1.9V1.9H22V22.1z"/>
            <rect x="6" y="6" width="11.9" height="11.9"/>
        </SvgIcon>
    )
}

/*
<path d="M23.4,0.6C23,0.2,22.6,0,22.1,0H1.9c-0.5,0-1,0.2-1.4,0.6C0.2,1,0,1.4,0,1.9v20.1c0,0.5,0.2,1,0.6,1.4
		C0.9,23.8,1.4,24,1.9,24H22c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.8,0.6-1.4V1.9C24,1.4,23.8,1,23.4,0.6z M22,22.1H1.9V1.9H22V22.1z"
/>
<polyline className="st0" points="18,6 12.1,18.1 6,12 	"/>
*/

export function EmptySquare(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M23.4,0.6C23,0.2,22.6,0,22.1,0H1.9c-0.5,0-1,0.2-1.4,0.6C0.2,1,0,1.4,0,1.9v20.1c0,0.5,0.2,1,0.6,1.4
            C0.9,23.8,1.4,24,1.9,24H22c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.8,0.6-1.4V1.9C24,1.4,23.8,1,23.4,0.6z M22,22.1H1.9V1.9H22V22.1z"
            />
        </SvgIcon>
    )
}

export function Circle(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 16, height: 16 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <circle className="st4" cx="12" cy="12" r="11" style={{fill: "transparent", stroke: "#000000"}} />
        </SvgIcon>
    )
}
