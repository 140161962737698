import React from "react";
import {Link} from 'react-router-dom'
import {AppBar, IconButton, Toolbar, Typography} from "@material-ui/core";
import {AccountCircle, Apps, CloudQueue, MoreVert, ExitToApp} from "@material-ui/icons";
import { styled } from '@material-ui/core/styles';

import { logOut } from '../actions/auth'
import ProfileButton from "./profile-button";
import LangButton from "./lang-button";
import HelpButton from './help-button'


const AppTopBar = styled(AppBar)({
    flexGrow: 1,
    background: '#35424E',
    zIndex: 1000,
    '& .MuiToolbar-regular': {
        minHeight: '48px'
    }
})

const LogoButton = styled(IconButton)({
    'padding-left': '15px'
})

const AppTitle = styled(Typography)({
    flexGrow: 1,
})

const TopBar = (props) => {
    return (
        <AppTopBar position="fixed">
            <Toolbar>
                <Link to={'/'}>
                    <LogoButton edge="start" color="inherit" aria-label="menu">
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 1H1V8.4994M17 1V16M17 1L1 8.4994M17 16H1V8.4994M17 16L1 8.4994" stroke="#D1D7DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </LogoButton>
                </Link>

                <AppTitle />

{/*                <IconButton edge="end" aria-label="menu">
                    <CloudQueue style={{'color': '#DEE6F1'}} />
                </IconButton>
                <IconButton edge="end" aria-label="menu">
                    <Apps style={{'color': '#DEE6F1'}}  />
                </IconButton>
                <IconButton edge="end" aria-label="menu">
                    <AccountCircle style={{'color': '#DEE6F1'}}  />
                </IconButton>
                <IconButton edge="end">
                    <MoreVert style={{'color': '#DEE6F1'}}  />
                </IconButton>*/}

                <ProfileButton />
                <HelpButton />
                <LangButton />
            </Toolbar>
        </AppTopBar>
    );
}

export default TopBar;
