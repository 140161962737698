import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 10px'
    },
    legendControls: {
        marginBottom: theme.spacing(1)
    },
    legendHeader: {
        marginTop: 0,
        marginBottom: theme.spacing(1)
    },
    legendTable: {

    },

    legendRow: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        // padding: theme.spacing(0.5),
        background: 'none',
        padding: 0,
        margin: '2px 0 0 0',
    },

    legendColor: {
        width: 40,
        height: 12
    },

    legendValues: {
        paddingLeft: 15,
        width: 240
    }

}))

export default function LegendTable(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const {title, table: legendTable} = props

    return (
        <div className={classes.legendControls} key="legendItem">
            <h5 className={classes.legendHeader}>{title}</h5>
            <div className={classes.legendTable}>
                {legendTable?.map((item, i) => (
                    <div className={classes.legendRow} key={i}>
                        <div className={classes.legendColor} style={{backgroundColor: item.color}} />
                        <div className={classes.legendValues}>{
                            Array.isArray(item.title)
                                ? item.title.join(' → ')
                                : item.title
                        }</div>
                    </div>
                ))}
            </div>
        </div>
    )
}