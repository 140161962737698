import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControlLabel, Paper, Switch, Tooltip} from "@material-ui/core";
import {Cancel, Block} from "@material-ui/icons"
import {
    ArcIcon,
    HeatmapIcon,
    LineIcon,
    ObjectIcon,
    PointsIcon,
    PolygonIcon,
    TrailIcon
} from "../../../../../../../icons";
import {MapStateContext, DataStateContext} from "../../../../reducer";
import {useTranslation} from "react-i18next";

const iconButtonClass = (theme) => ({
    flex: 1,
    color: 'rgba(0, 0, 0, 0.54)',
    background: theme.color.gray,
    padding: '15px',
    boxSizing: 'border-box',
    maxWidth: '20px',
    minWidth: '20px',
    width: '20px',
    height: '20px',
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    border: 0,
    cursor: 'pointer',
    margin: '0 10px 0 0',
    display: 'inline-flex',
    outline: 0,
    position: 'relative',
    alignItems: 'center',
    userSelect: 'none',
    borderRadius: 0,
    verticalAlign: 'middle',
    justifyContent: 'center',

    textDecoration: 'none',
    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
    '-webkit-tap-highlight-color': 'transparent',
})

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 10px'
    },

    title: {
        margin: '0 0 10px 0',
        padding: 0
    },

    buttonBar: {
        display: 'flex',
        justifyContent: 'start'
    },

    iconButton: {
        ...iconButtonClass(theme),

        // '&:first-child': {
        //     marginLeft: 0
        // },

        '&:hover': {
            background: theme.color.darkGray
        },
    },

    iconButtonActive: {
        ...iconButtonClass(theme),

        background: theme.color.darkGray
    },

    displayModeControl: {
        background: theme.color.gray,

    },
}));


const layerTypeIcons = {
    'polygon': <PolygonIcon />,
    'heatmap': <HeatmapIcon />,
    'point': <PointsIcon />,
    'trail': <TrailIcon />,
    'line': <LineIcon />,
    'arc': <ArcIcon />,
    'object': <ObjectIcon />
}

const objectTypesPalette = [
    {
        id: 1,
        key: 'building',
        meta: {
            title: 'Building'
        },
        icon: 'object'
    },
    {
        id: 1,
        key: 'road',
        meta: {
            title: 'Road'
        },
        icon: 'line'
    },
]


export default function ObjectSelector(props) {
    const classes = useStyles();
    const { mapState, mapDispatch } = useContext(MapStateContext)
    const { dataState } = useContext(DataStateContext)
    const { editModeComponent } = mapState
    const { t } = useTranslation();

    const { objectType } = dataState
    const existObjectTypesNames = objectType ? Object.values(objectType).map(ot => ot.name) : []
    const availableObjectTypes = objectTypesPalette.filter(item => existObjectTypesNames.indexOf(item.key) > -1)

    if (availableObjectTypes.length === 0) return null;

    return (
        <div className={classes.root}>
            <h5 className={classes.title}>{t('project.leftbar.panel4.text')}</h5>
            <div className={classes.buttonBar}>
                {
                    availableObjectTypes.map((item) => (
                        <Tooltip title={t(`project.leftbar.panel4.${item.meta?.title?.toLowerCase()}`)} key={item.key}>
                            <button
                                className={item.key === editModeComponent ? classes.iconButtonActive : classes.iconButton}
                                onClick={(e) => {
                                    mapDispatch({
                                        type: 'setEditModeComponent',
                                        component: item.key
                                    })
                                    if(props.onChange) props.onChange(item.key)
                                }}
                            >
                                {layerTypeIcons[item.icon]}
                            </button>
                        </Tooltip>
                    ))

                }
                <Tooltip title={t('project.leftbar.panel4.cancel')} key="cancel">
                    <button
                        className={classes.iconButton}
                        onClick={(e) => {
                            mapDispatch({type: 'cancelEditMode'})
                            if(props.onChange) props.onChange( null)
                        }}
                    >
                        <Block />
                    </button>
                </Tooltip>

            </div>
        </div>
    )
}