import React, {useContext, useEffect, useReducer} from 'react';
import {useQuery} from "@apollo/client";
import {GetMe} from "../actions/app/gql/data";
import apolloClient from "../clients/apolloClient";
import {AuthStateContext} from "../reducers";


const LoadMe = (props) => {
    const {authState, authDispatch} = useContext(AuthStateContext)

    const {data: meData, loading: meLoading, error: meError} = useQuery(GetMe, {
        client: apolloClient,
        fetchPolicy: "no-cache",
        variables: {
        }
    })

    useEffect(() => {
        if (meLoading || !meData) return

        authDispatch({
            type: 'setMe',
            me: meData.me[0]
        })
    }, [meData])


    return null
}

export default LoadMe;
