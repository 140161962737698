import React from "react";
import {MapStyleContext} from './map-styles'
import {objectOnlyKeys} from "../../../../helpers/fn";
import {HEIGHT_DEFAULT_STRUCTURE} from "../../../../helpers/const";
import {LayerActivityModel, LayerEditModel, userActivityLayerId, LayerActorsModel, createActorLayerId} from "../../../../models/layer";
import {getDefaultEditData, getEditMode} from '../components/map/layers/edit-layer'


const defaultLayerSettings = {
    id: null,
    title: null,
    color: '#cc0000',
    colorMetricId: null,
    colorMetricFilters: {},
    colorMetricField: null,
    objectIds: [],
    collection: null,
    strokeColor: '#000000',
    strokeColorMetricId: null,
    elevation: 0,
    elevationMetricId: null,
    filled: true,
    stroked: true,
    lineWidth: 1,
    height: HEIGHT_DEFAULT_STRUCTURE,
    opacity: 1,
    radius: 5,
    display: 1,
    visible: true,
    filters: []
}


export const defaultStepState = {
    datasets: {},
    datasetItems: {},
    dataviews: [],
    layers: {},
    layerFilters: {},
    filterStats: {},
    metrics: [],
    metricStats: {},
    algorithm: null,
    algorithmParams: {},
}

export const mapToStepVariables = (mapState, projectId, projeccStepLatestIndex) => ({
    "datasets": mapState.datasets,
    "dataset_items": mapState.datasetItems,
    "dataviews": mapState.dataviews,
    "layers": mapState.layers,
    "filters": mapState.filterStats,
    "metrics": {
        metrics: mapState.metrics,
        metricStats: mapState.metricStats,
    },
    "algorithm": {
        algorithm: mapState.algorithm,
        algorithmParams: mapState.algorithmParams,
    },
    "title": "Step " + (projeccStepLatestIndex + 1),
    "ordering": (projeccStepLatestIndex + 1),
    "project_id": projectId,
})


const DEFAULT_MAP_LAYERS_STATE = {
    updateTime: null,
    updatedLayers: null,
    removedLayers: null,
    needSetup: false,
    needCreate: false,
    needRerender: false,
    needRemove: false,
    needReoption: false,
    needReassemble: false,
    option: null,
}


export const initialMapState = {
    projectId: null,
    layerId: 1,
    isLoading: false,
    isCalculating: false,
    datasets: {},
    datasetItems: {},
    dataviews: [],
    itemview: null,
    itemBarItemId: null,
    itemBarOpened: false,
    itemViewState: null,
    layers: {},
    userActivityLayer: LayerActivityModel({}),
    userEditLayer: LayerEditModel({}),
    [createActorLayerId]: LayerActorsModel({}),
    layerFilters: {},
    metrics: [],
    metricStats: {},
    filterStats: {},
    projectCurrentStepId: null,
    projectSteps: [
        // {
        //     id: 1,
        //     number: 1,
        //     meta: {
        //         title: 'Start',
        //         about: 'The very beginning',
        //     },
        // },
        // {
        //     id: 2,
        //     number: 2,
        //     meta: {
        //         title: 'Step 2',
        //         about: 'Second one',
        //     },
        // },
        // {
        //     id: 2,
        //     number: 2,
        //     meta: {
        //         title: 'Step 3',
        //         about: 'May be this is a final step...',
        //     },
        // },
    ],
    ids: [],
    objects: {},
    mapLayers: [],
    dataUpdate: (new Date()).getTime(),

    updatedObjectType: null,

    mapLayersUpdate: DEFAULT_MAP_LAYERS_STATE,


    currentPage:  'map',  // todo: 'data',

    dataBar: {
        visible: false,
        item: null,
        bbox: null,
        summary: {}
    },

    editModeComponent: null,

    algorithm: null,
    algorithms: [],
    algorithmHistory: [],
    algorithmParams: {},
    algorithmObjectType: null,
    algorithmMetricId: null,
    algorithmMetricTitle: null,

    viewBbox: [0, 0, 0, 0],
    viewZoom: 8,
    initialViewState: {
        longitude: 0,
        latitude: 0,
        zoom: 1,
        pitch: 0,
        bearing: 0,
    },
    visibleViewState: {
        longitude: 0,
        latitude: 0,
        zoom: 1,
        pitch: 0,
        bearing: 0,
    },
    viewState: {
        longitude: 0,
        latitude: 0,
        zoom: 1,
        pitch: 0,
        bearing: 0,
        width: window.innerWidth,
        height: window.innerHeight
    }
};


const setupMetricsLayers = (state, action, field, fieldValue) => {
    const layers = {
        ...state.layers,
        [action.layerId]: {
            ...state.layers[action.layerId],
            [field]: fieldValue,
        }
    }
    const metrics =  Object.values(layers).map(item => item.colorMetricId).filter(item => !!item)
    return {metrics, layers}
}

export const saveableMapState = (state, publicOnly=false) => {

    const finalState = publicOnly
        ? {...state,
            layers: Object.fromEntries(Object.entries(state.layers).filter(([layerId, layer]) => layer.isPublic))}
        : state

    return objectOnlyKeys(finalState,
    [
        'layers', 'userActivityLayer', 'userEditLayer', createActorLayerId,
        'layerFilters', 'metrics', 'metricStats', 'filterStats', 'objects', 'mapLayers'
    ])
}


const mapStateReducer = function (state = initialMapState, action) {
    window.mapState = state

    switch (action.type) {

        case 'setEditModeComponent':
            return {
                ...state,
                editModeComponent: action.component,
                userEditLayer: {
                    ...state.userEditLayer,
                    isEnabled: true
                },
                // mapLayersUpdate: {
                //     updatedLayers: state.userEditLayer.id,
                //     updateTime: (new Date()).getTime(),
                //     needCreate: true,
                // },
            }

        case 'cancelEditMode':
// console.log('CANCEL>>')
            return {
                ...state,
                editModeComponent: null,
                // userEditLayer: {
                //     ...state.userEditLayer,
                //     isEnabled: false
                // },
                // mapLayersUpdate: {
                //     updatedLayers: state.userEditLayer.id,
                //     updateTime: (new Date()).getTime(),
                //     needReoption: true,
                //     option: {visible: false}
                // },
            }

        // case 'addNewObject':
        //     return {
        //
        //     }

        case 'setCurrentPage':
            return {
                ...state,
                currentPage: action.page
            }

        case 'setMapLayers':
            return {
                ...state,
                mapLayers: action.mapLayers
            }

        case 'setLoading':
            return {
                ...state,
                isLoading: action.isLoading
            }

        case 'setCalculating':
            return {
                ...state,
                isCalculating: action.isCalculating
            }

        case 'setAlgorithmMetricTitle':
            return {
                ...state,
                algorithmMetricTitle: action.title
            }

        case 'setAlgorithmObjectType':
            return {
                ...state,
                algorithmObjectType: action.object_type_id
            }

        case 'objectTypeLoaded':
            return {
                ...state,
                updatedObjectType: action.object_type_id,
                // dataUpdate: (new Date()).getTime(),
            }

        case 'setLayerOrdering':

            if (state.layers.length < 2)
                return state

            const reorderingLayer = state.layers[action.layerId]
            const layersArray = Object.values(state.layers)
            const minOrdering = layersArray.reduce((ord, item) => item.ordering < ord ? item.ordering : ord, 0)
            const maxOrdering = layersArray.reduce((ord, item) => item.ordering > ord ? item.ordering : ord, 0)

            if (action.way === -1 && reorderingLayer.ordering === minOrdering)
                return state

            if (action.way === 1 && reorderingLayer.ordering >= maxOrdering)
                return state

            const swapLayer = action.way === -1
                ? layersArray.reduce((prev, cur) => cur.ordering < reorderingLayer.ordering ? cur : prev)
                : layersArray.find(item => item.ordering > reorderingLayer.ordering)

            const {id: rId, ordering: rOrdering} = reorderingLayer
            const {id: sId, ordering: sOrdering} = swapLayer

            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: false
                },
                layers: layersArray.reduce((acc, cur) => {
                    if (cur.id === sId) {
                        cur.ordering = rOrdering
                    }

                    if (cur.id === rId) {
                        cur.ordering = sOrdering
                    }

                    return {
                        ...acc,
                        [cur.id]: cur
                    }
                }, {})
            }

        case 'removeLayerByObjects':
            const removedLayersIds = Object.values(state.layers)
                .filter(item => action.objectIds.indexOf(item.object.id) !== -1)
                .map(item => item.id)

            return {
                ...state,
                mapLayersUpdate: {
                    removedLayers: removedLayersIds,
                    updateTime: (new Date()).getTime(),
                    needRemove: true
                },
                layers: Object.values(state.layers)
                    .filter(item => action.objectIds.indexOf(item.object.id) === -1)
                    .reduce((acc, cur) => {
                        return {
                            ...acc,
                            [cur.id]: cur
                        }
                    }, {}),
            }

        case 'removeLayer':
            return {
                ...state,
                mapLayersUpdate: {
                    removedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRemove: true
                },
                layers: Object.values(state.layers).filter(item => item.id !== action.layerId).reduce((acc, cur) => {
                    return {
                        ...acc,
                        [cur.id]: cur
                    }
                }, {}),
            }

        case 'removeLayerProto':
            return {
                ...state,
                layers: Object.values(state.layers).filter(item => item.id !== action.layerId).reduce((acc, cur) => {
                    return {
                        ...acc,
                        [cur.id]: cur
                    }
                }, {}),
            }

        case 'reassembleLayers':
// console.log('reassembleLayers>')
            const reassembledLayers = Object.fromEntries(
                Object.entries(state.layers)
                    .filter(([layerId, layer]) => action.removedObjectsIds.indexOf(layer.object.id) === -1)
                    .concat(Object.entries(action.addedLayers))
            )

            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: Object.keys(action.addedLayers),
                    removedLayers: Object.values(state.layers)
                        .filter(item => action.removedObjectsIds.indexOf(item.object.id) !== -1)
                        .map(item => item.id),
                    updateTime: (new Date()).getTime(),
                    needReassemble: true,
                },
                layers: reassembledLayers,
            }

        case 'addLayers':
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: Object.keys(action.layers),
                    updateTime: (new Date()).getTime(),
                    needCreate: true
                },
                layers: {
                    ...state.layers,
                    ...action.layers
                }
            };

        case 'addLayer':
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: [action.layer.id],
                    updateTime: (new Date()).getTime(),
                    needCreate: true
                },
                layers: {
                    ...state.layers,
                    [action.layer.id]: action.layer
                }
            }

        case 'addLayerProto':
            return {
                ...state,
                layers: {
                    ...state.layers,
                    [action.layer.id]: action.layer
                }
            }

        case 'reloadUserActivity':
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: [ userActivityLayerId ],
                    updateTime: (new Date()).getTime(),
                    needRerender: true
                },
            }

        case 'recalcActorsModel':
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: [ createActorLayerId ],
                    updateTime: (new Date()).getTime(),
                    needRerender: true
                },
                [createActorLayerId]: action.layer
            }

        case 'updateLayer':
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: [action.layer.id],
                    updateTime: (new Date()).getTime(),
                    needRerender: true
                },
                layers: {
                    ...state.layers,
                    [action.layer.id]: action.layer
                }
            }

        // case 'setLayerObjectIds':
        //     return {
        //         ...state,
        //         layers: {
        //             ...state.layers,
        //             [action.layerId]: {
        //                 ...state.layers[action.layerId],
        //                 objectIds: action.ids
        //             }
        //         }
        //     }

        case 'setLayerCollection':
            return {
                ...state,
                layers: {
                    ...state.layers,
                    [action.layerId]: {
                        ...state.layers[action.layerId],
                        collection: action.collection
                    }
                }
            }

        case 'setCurrentStep':
            return {
                ...state,
                projectCurrentStepId: action.currentStepId
            }

        case 'deleteStep':
            return {
                ...state,
                projectSteps: state.projectSteps.filter(item => item.id !== action.step.id)
            }

        case 'appendStep':

            const stepIsPlaced = state.projectSteps.find(item => item.id === action.step.id)

            if (stepIsPlaced)
                return state

            return {
                ...state,
                projectSteps: state.projectSteps.concat([{
                    ...action.step
                }])
            }

        case 'loadStepState':
            return {
                ...state,
                datasets: action.currentStep.datasets,
                datasetItems: action.currentStep.dataset_items,
                dataviews: action.currentStep.dataviews,
                layers: action.currentStep.layers,
                filterStats: action.currentStep.filters,
                metrics: action.currentStep.metrics.metrics,
                metricStats: action.currentStep.metrics.metricStats,
                algorithm: action.currentStep.algorithm.algorithm,
                algorithmParams: action.currentStep.algorithm.algorithmParams,
            }

        case 'setProjectSteps':
            return {
                ...state,
                projectSteps: action.steps,
                projectCurrentStepId: action.currentStep ? action.currentStep.id : null
            }

        case 'addProjectStep':
            return {
                ...state,
                projectSteps: state.projectSteps.concat({
                    id: state.projectSteps.length + 1,
                    number: state.projectSteps + 1,
                    meta: {
                        title: action.title ? action.title : 'Step ' + (state.projectSteps + 1),
                        about: action.about ? action.about : '',
                    }
                })
            }

        case 'setMetricStats':
            return {
                ...state,
                metricStats: {
                    ...state.metricStats,
                    ...action.metricStats
                }
            }

        case 'setFilterStats':
            return {
                ...state,
                filterStats: {
                    ...state.filterStats,
                    ...action.filterStats,
                }
            }

        case 'setProject':
            window.mapState = {
                ...state,
                projectId: action.projectId
            }
            window.action = action
            return {
                ...state,
                projectId: action.projectId
            }

        case 'openItemBar':
            return {
                ...state,
                itemBarOpened: true,
                itemBarItemId: action.item_id,
                itemViewState: action.viewState,
            }

        case 'closeItemBar':
            return {
                ...state,
                itemBarOpened: false,
                itemBarItemId: null,
                itemViewState: null,
            }

        case 'addAlgorithmToHistory':
            return {
                ...state,
                algorithmHistory: state.algorithmHistory.concat(action.item)
            }

        case 'resetAlgorithmForm':
            return {
                ...state,
                algorithm: null,
                algorithmParams: {},
                algorithmObjectType: null,
                algorithmMetricId: null,
                algorithmMetricTitle: '',
            }

        case 'setAlgorithmParams':

            return {
                ...state,
                algorithmParams: action.params
            }

        case 'setAlgorithmParam':
            return {
                ...state,
                algorithmParams: {
                    ...state.algorithmParams,
                    [action.param]: action.value
                }
            }
        case 'setAlgorithm':

            return {
                ...state,
                algorithm: action.algorithm,
                algorithmParams: action.params ? action.params : {},
                algorithmObjectType: action.objectType ? action.objectType : state.algorithmObjectType,
                algorithmMetricId: action.metricId ? action.metricId : null,
                algorithmMetricTitle: action.title ? action.title : state.algorithmMetricTitle,
            }

        case 'setAlgorithms':
            return {
                ...state,
                algorithms: action.algorithms
            }

        case 'setViewState':
            return {
                ...state,
                viewState: action.viewState,
                viewBbox: action.bbox,
                viewZoom: action.zoom,
                visibleViewState: action.viewState,
            }

        case 'setLayerFilter':
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: true
                },
                layerFilters: {
                    ...state.layerFilters,
                    [action.layerId]: [
                        ...(state.layerFilters[action.layerId] ? state.layerFilters[action.layerId] : []),
                        {
                            metricId: action.metricId,
                            field: action.field,
                            value: action.value,
                            stats: action.stats,
                            cubeId: action.metricId
                        }
                    ]
                }
            }

        case 'addLayerFilter':
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: true
                },
                layerFilters: {
                    ...state.layerFilters,
                    [action.layerId]: [
                        ...(state.layerFilters[action.layerId] ? state.layerFilters[action.layerId] : []),
                        {
                            cubeId: action.metricId,
                            metricId: action.metricId,
                            metric: action.metric,
                            fieldName: action.fieldName,
                            field: action.field,
                            stats: action.stats,
                            dict: action.dict,
                            value: action.value,
                        }
                    ]
                }
            }

        case 'deleteLayerFilter':
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: true
                },
                layerFilters: {
                    ...state.layerFilters,
                    [action.layerId]: state.layerFilters[action.layerId].filter(item => (
                        item.metricId === action.metricId && item.field === action.field
                    ))
                }
            }

        case 'toggleDataBar':
            return {
                ...state,
                dataBar: {
                    ...state.dataBar,
                    visible: action.visible,
                    bbox: action.bbox ? action.bbox : null
                }
            }

        case 'setSummaryStatData':
            return {
                ...state,
                dataBar: {
                    ...state.dataBar,
                    summary: action.summary
                }
            }

        case 'setItemStatData':
            return {
                ...state,
                dataBar: {
                    ...state.dataBar,
                    item: action.item
                }
            }

        case 'setViewStateCoordinates':
            return {
                ...state,
                initialViewState: {
                    ...state.viewState,
                    latitude: action.latitude,
                    longitude: action.longitude,
                    zoom: action.zoom
                },
                viewState: {
                    ...state.viewState,
                    latitude: action.latitude,
                    longitude: action.longitude,
                    zoom: action.zoom
                },
                visibleViewState: {
                    ...state.visibleViewState,
                    latitude: action.latitude,
                    longitude: action.longitude,
                    zoom: action.zoom
                },
            }

        case 'setVisibleViewState':
            return {
                ...state,
                visibleViewState: action.viewState
            }


        case 'setLayerIsEnabled':

            if (['userActivityLayer', 'userEditLayer', createActorLayerId].indexOf(action.layerId) > -1) {
                return {
                    ...state,
                    mapLayersUpdate: {
                        updatedLayers: action.layerId,
                        updateTime: (new Date()).getTime(),
                        needReoption: true,
                        option: {visible: action.isEnabled}
                    },
                    [action.layerId]: {
                        ...state[action.layerId],
                        isEnabled: action.isEnabled
                    }
                }
            }

            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: false,
                    needReoption: true,
                    option: {visible: action.isEnabled}
                },
                layers: {
                    ...state.layers,
                    [action.layerId]: {
                        ...state.layers[action.layerId],
                        isEnabled: action.isEnabled
                    }
                }
            }

        case 'setLayerIsPublic':
            return {
                ...state,
                layers: {
                    ...state.layers,
                    [action.layerId]: {
                        ...state.layers[action.layerId],
                        isPublic: action.isPublic
                    }
                }
            }

        case 'setLayerOption':
            if (['userActivityLayer', 'userEditLayer', createActorLayerId].indexOf(action.layerId) > -1) {
                return {
                    ...state,
                    mapLayersUpdate: {
                        updatedLayers: action.layerId,
                        updateTime: (new Date()).getTime(),
                        needReoption: true,
                        option: action.layerOption,
                    },
                    [action.layerId]: {
                        ...state[action.layerId],
                        ...action.option,
                    }
                }
            }

            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: false,
                    needReoption: true,
                    option: action.layerOption,
                },
                layers: {
                    ...state.layers,
                    [action.layerId]: {
                        ...state.layers[action.layerId],
                        ...action.option,
                    }
                },
            }

        case 'setLayerHeight':
// console.log('setLayerHeight>')
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: true,
                },
                layers: {
                    ...state.layers,
                    [action.layerId]: {
                        ...state.layers[action.layerId],
                        ...action.option, // height
                    }
                },
            }

        case 'setLayerType':
// console.log('setLayerType>')
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: true,
                },
                layers: {
                    ...state.layers,
                    [action.layerId]: {
                        ...state.layers[action.layerId],
                        layerType: action.layerType,
                    }
                },
            }

        case 'setLayerStrokeColor':
        case 'setLayerColor':
// console.log('setLayerColor>')
            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: true
                },
                layers: {
                    ...state.layers,
                    [action.layerId]: {
                        ...state.layers[action.layerId],
                        ...action.options,
                    }
                },
            }

        case 'setLayerColorMetricId':
// console.log('setLayerColorMetricId>')
            return {
                ...state,
                ...setupMetricsLayers(state, action, 'colorMetricId', action.colorMetricId),
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: true
                },
            }

        case 'setLayerColorMetricField':
// console.log('setLayerColorMetricField>')
            return {
                ...state,
                ...setupMetricsLayers(state, action, 'colorMetricField', action.colorMetricField),
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: true
                },
            }

        case 'setLayerColorMetricFilters':
// console.log('setLayerColorMetricFilters>')
            return {
                ...state,
                ...setupMetricsLayers(state, action, 'colorMetricFilters', action.colorMetricFilters),
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: true
                },
            }

        case 'setLayerColorBase':
// console.log('setLayerColorBase>')
            const usableMetrics = Object.values(state.layers)
                .filter(item => item.colorMetricId !== null)
                .map(item => item.colorMetricId)
            const layerConfig = {
                ...state.layers[action.layerId],
                colorMetricId: action.colorMetricId,
                colorMetricField: action.colorMetricField,
                colorMetricFilters: action.colorMetricFilters,
                ...(action.options ? action.options : {})
            }

            const stateMetrics = action.colorMetricId ? usableMetrics.concat(action.colorMetricId) : usableMetrics;
            const stateLayers = {
                ...state.layers,
                [action.layerId]: layerConfig
            }

            return {
                ...state,
                mapLayersUpdate: {
                    updatedLayers: action.layerId,
                    updateTime: (new Date()).getTime(),
                    needRerender: true // todo: maybe not
                },
                metrics: stateMetrics,
                layers: stateLayers,
            }

        case 'addMetric':
            return {
                ...state,
                metrics: state.metrics.concat(action.metric)
            }

        case 'setMetrics':
            return {
                ...state,
                metrics: action.metrics
            }

        case 'setDatasets':
            const newState = {
                ...state,
                datasets: action.datasets.reduce((acc, cur) => ({
                    ...acc,
                    [cur.id]: {...cur},
                }), {}),
                layers: action.datasets.reduce((acc, cur) => {
                    const item = {
                        ...defaultLayerSettings, ...{
                            title: cur.meta.title,
                            color: cur.options?.color ? cur.options.color : '#cc0000',
                            display: cur.options?.display ? cur.options?.display : 0,
                            extrude: cur.options?.extrude ? cur.options?.extrude : 0
                        }, ...cur
                    };

                    if (Object(state.layers).hasOwnProperty(item.id))
                        return {...acc, [item.id]: state.layers[item.id]}

                    return {...acc, [item.id]: item}
                }, {})
            }

            return newState;

        case 'setDatasetItems':
            return {
                ...state,
                datasetItems: action.datasets.reduce((acc, cur) => ({
                    ...acc,
                    [cur.id]: cur.items
                }), {})
            };

        case 'setDataviews':
            return {
                ...state,
                dataviews: action.dataviews,
                itemview: action.dataviews && action.dataviews.length ? action.dataviews[0] : null
            }

        case 'updateUserLayer':
// console.log('updateUserLayer>')
            return {
                ...state,
                mapLayersUpdate: {
                    updateTime: (new Date()).getTime(),
                    needRerender: true,
                    updatedLayers: action.updatedLayers,
                },
            }

        case 'setup':
            return {
                ...state,
                userActivityLayer: LayerActivityModel({}),
                userEditLayer: LayerEditModel({}),
                [createActorLayerId]: LayerActorsModel({}),
                mapLayersUpdate: {
                    updateTime: (new Date()).getTime(),
                    needSetup: true
                },
            }

        case 'load':
            return {
                ...state,
                ...action.state,
                userActivityLayer: LayerActivityModel(action.state?.userActivityLayer),
                userEditLayer: LayerEditModel(action.state?.userEditLayer),
                [createActorLayerId]: LayerActorsModel(action.state[createActorLayerId]),
                mapLayersUpdate: {
                    updateTime: (new Date()).getTime(),
                    needSetup: true
                },
            }

        case 'resetLayerUpdate':
            return {
                ...state,
                mapLayersUpdate: DEFAULT_MAP_LAYERS_STATE
            }

        default:
            return state;
    }
}
export default mapStateReducer;

export const MapStateContext = React.createContext(initialMapState)
