export const operandKey = (selectedObjectType, tag, tagValue) => `${selectedObjectType}:${tag}:${tagValue}`;

export const operandsToAlgorithmParams = (operands) => operands.reduce((acc, item) => ({
    ...acc,
    [operandKey(item.object_type_id, item.tag, item.value)]: {
        tag: item.tag,
        value: item.value,
        objectType: {
            id: item.object_type_id
        },
        weight: item.weight
    }
}), {})