import React from "react";
import Cookies from 'js-cookie'


export const initialPopupState = {
    profile: {
        open: false
    },
    welcomeTour: {
        open: !Cookies.get('habitue')
    }
};

Cookies.set('habitue', 1)


const popupStateReducer = function (state = initialPopupState, action) {

    window.popupState = state

    switch (action.type) {
        case 'openPopup':
            return {
                ...state,
                [action.popup]: {
                    ...state[action.popup],
                    open: true
                }
            }

        case 'closePopup':
            return {
                ...state,
                [action.popup]: {
                    ...state[action.popup],
                    open: false
                }
            }

        default:
            return state;
    }
}
export default popupStateReducer;
export const PopupStateContext = React.createContext(initialPopupState)
