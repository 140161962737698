import React, {useContext, useEffect} from "react";
import {
    Paper,
    FormControlLabel,
    Switch,
} from "@material-ui/core";

import {makeStyles} from '@material-ui/core/styles';

import {
    MapStateContext,
} from '../../../reducer'
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: 280,
        background: '#F5FAFF',
        borderRadius: 0,
        paddingBottom: 10,
        marginBottom: 10
    },
    title: {
        boxSizing: 'border-box',
        padding: '10px',
        margin: '0',
        fontWeight: 'normal',
        fontSize: '12pt'
    },
    displayModeControl: {
        background: '#F5FAFF'

    },
    colorBaseControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    colorBaseGradControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    colorBaseGradNumberControl: {
        margin: theme.spacing(1),
        minWidth: 95,
    },
    oneColorControl: {
        margin: theme.spacing(1)
    },
    lineWidthControl: {
        margin: theme.spacing(1)
    },
    opacityControl: {
        margin: theme.spacing(1)
    },
    gradientItem: {
        width: '100%',
        height: '40px',
    },
    layerControls: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    layerPublish: {
        marginTop: 4,
        marginLeft: 28
    },
    layerOrderingControls: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(1)
    },
    enabledControl: {

    },
}));


export default function LayerUserActivity(props) {
    const classes = useStyles();
    const {mapDispatch} = useContext(MapStateContext)
    const {t} = useTranslation();

    const {
        id,
        isEnabled,
    } = props.layer

    const handleEnable = (newIsEnabled) => {
        mapDispatch({
            type: 'setLayerIsEnabled',
            layerId: id,
            isEnabled: newIsEnabled
        })
    }

    return (
        <Paper className={classes.root}>
            <h4 className={classes.title}>{props.title}</h4>

            <div className={classes.layerControls}>
                <FormControlLabel
                    control={<Switch
                        size="small"
                        color="secondary"
                        checked={isEnabled}
                        onChange={() => handleEnable(!isEnabled)}
                    />}
                    label={t('project.leftbar.panel2.layer_is_enabled')}
                    className={classes.enabledControl}
                />

            </div>

        </Paper>
    )
}