import {getDB} from "../../db";
import {isFieldObject, isFieldUUID} from "../../helpers/field";


export default function defaultLayerDataFabric({layer, cube, filters, heightCube, mapDispatch}) {
    return async function getData() {
        const hasFilters = !!filters && filters?.length > 0

        mapDispatch({type: 'setCalculating', isCalculating: true})

        const DB = await getDB();

        const isColorMetric = layer.colorMetricId && layer.colorMetricField
        const colorFilters = layer.colorMetricFilters
        const isHeightMetric = layer.height?.metricId && layer.height?.field
        const heightJoinKey = heightCube?.struct.fields.find(item => item.is_object)
        const heightJoinKeyName = heightJoinKey?.name

        const dataTable = await DB.table(layer.object?.key)

        if (!dataTable) {
            mapDispatch({type: 'setCalculating', isCalculating: false})
            return []
        }

        const joinKey = cube?.struct.fields.find(item => item.is_object)
        const joinKeyName = joinKey?.name

        const hasColorMetric = isColorMetric && joinKey
        const hasHeightMetric = isHeightMetric && heightJoinKey

        const metricField = cube?.struct.fields.find(item => item.name === layer.colorMetricField)
        const isMetricFieldDict = metricField && isFieldUUID(metricField) && !isFieldObject(metricField)

        let query = dataTable.toCollection()

        if (hasColorMetric) {
            query = query.joinFields({
                rootKey: 'id',
                tableKey: joinKeyName,
                tableName: cube.name,
                tableFields: {
                    [layer.colorMetricField]: 'metricValue'
                },
                aggregateFunction: isMetricFieldDict ? null : 'avg',
                filters: colorFilters
            })
        }

        if (hasHeightMetric) {
            query = query.joinFields({
                rootKey: 'id',
                tableKey: heightJoinKeyName,
                tableName: heightCube.name,
                tableFields: {
                    [layer.height?.field]: 'heightMetricValue'
                },
                aggregateFunction: 'avg',
            })
        }

        if (hasFilters) {
            filters.forEach(filter => {
                query = query.filter({
                    rootKey: 'id',
                    tableKey: filter.cube_object,
                    tableName: filter.cube_name,
                    filterFields: filter.fields
                })
            })
        }

        // if (prepareQuery) query = prepareQuery(query)

        const data = await query.toArray()
        // const data = prepareData ? prepareData(await query.toArray()) : await query.toArray()

        mapDispatch({type: 'setCalculating', isCalculating: false})

        return data
        // return prepareData ? prepareData(data) : data
    }
}