import React, {useContext, useEffect, useState} from "react";
import {
    MapStateContext,
    DataStateContext,
    MapStyleContext,
    saveableDataState,
    saveableMapState,
    saveableMapStyle
} from "./reducer";
import {UpdateProjectState} from "../../../actions/app/gql/projectQuery";
import {useMutation} from "@apollo/client";
import apolloClient from "../../../clients/apolloClient";
import {authAxios} from '../../../clients/directAxios'
import {isManager} from '../../../helpers/user'
import {getToken} from "../../../actions/auth";
import {appConfig} from "../../../config";

let saveTimer = null
export default function SaveStateProvider(props) {

    const { projectData, isLoaded: isHttpLoaded } = props

    const {dataState} = useContext(DataStateContext)
    const {mapState} = useContext(MapStateContext)
    const {mapStyle} = useContext(MapStyleContext)

    const {me} = dataState

    const {isLoaded} = dataState

    const [updateProjectState, updateProjectStateResponse] = useMutation(UpdateProjectState, {
        client: apolloClient,
    })

    const apiClient = authAxios({
        auth: {
            access_token: getToken()
        }
    });

    // todo: ВЫЖИРАЕТ ПАМЯТЬ!
    useEffect(() => {
        if (!!projectData && isLoaded && isHttpLoaded) {

            if (saveTimer) clearTimeout(saveTimer)

            saveTimer = setTimeout(() => {
                updateProjectState({
                    variables: {
                        project_id: mapState.projectId,
                        data: saveableDataState(dataState),
                        state: saveableMapState(mapState),
                        style: saveableMapStyle(mapStyle),
                        ver: 1,
                        user_id: me.id
                    }
                })
                    .then((data) => {
                    })
                    .catch((err) => {
                    })

                const { appProject } = projectData
                if (isManager(me) && appProject) {
                    apiClient.post(`${appConfig.api.base}/project/${appProject.id}/state/public`, {
                        data: saveableDataState(dataState),
                        state: saveableMapState(mapState, true),
                        style: saveableMapStyle(mapStyle),
                    }).then(() => {}).catch(() => {})
                }
            }, 20 * 1000)

        }
    }, [dataState, mapState, mapStyle, projectData])

    return props.children
}