const operandDefaults = {
    title: '',
    object_type_id: null,
    metric_id: null,
    ver: 1,
    field: null,
    tag: null,
    value: null,
    weight: 1,
}


export const OperandModel = (props) => ({
    ...operandDefaults,
    ...props
})
