import React, {useEffect, useState} from "react";
import {
    GeoJsonLayer,
    TextLayer,
    IconLayer,
} from '@deck.gl/layers';
import {
    HeatmapLayer,
} from '@deck.gl/aggregation-layers'
import {EditableGeoJsonLayer} from "@nebula.gl/layers";
import {DrawLineStringMode, DrawRectangleUsingThreePointsMode} from "@nebula.gl/edit-modes";

import LOKKPointLayer from './layers/point-layer'
import LOKKPolygonLayer from './layers/polygon-leayer'
import LOKKObjectLayer from './layers/object-layer'
import LOKKPathLayer from './layers/path-layer'

import userLayerDataFabric from '../../../../../data/layer-fabric/userLayerDataFabric'
import LOKKUserActivityLayer from '../map/layers/user-activity-layer'
import {getDefaultEditData, getEditMode} from '../map/layers/edit-layer'
import {userEditLayerId, userActivityLayerId} from '../../../../../models/layer'


import {
    hexToRgb,
    gradientTable,
} from '../../../../../helpers/color'

import {
    vector4toGeom,
    buildGeomFeature,
    isPossible3d,
} from '../../../../../helpers/geom'

import {
    DEFAULT_HEIGHT
} from '../../../../../helpers/const'


const optionMap = {
    default: {
        'filled': 'filled',
        'stroked': 'stroked',
        'opacity': 'opacity',
        'radius': 'getRadius',
        'isEnabled': 'visible',
    },
    line: {
        'lineWidth': 'widthScale',
        'stroked': 'visible'
    },
    heatmap: {
        'radius': ['radiusPixels', (value) => value * 5]
    }
}

export const toDeckLayerOption = (layerType, option, value) => {
    const layerOptionMap = optionMap[layerType]
        ? {...optionMap.default, ...optionMap[layerType]}
        : optionMap.default

    const deckOption = layerOptionMap[option]
    if (Array.isArray(deckOption)) {
        const [deckOptionName, lambda] = deckOption
        return {
            [deckOptionName]: lambda(value)
        }
    }

    return {
        [deckOption]: value
    }
}

export const layerFabric = (props) => {

    const {
        layer,
        theme,
        options,
        getData,
    } = props
    const layerOptions = options ? options : {}
    const isVisible = layer.isEnabled

    const layerType = layer.layerType
    const unique = '' // -${Date.now()}
    const visualLayerIdentity = `${layer.id}_${layerType}_${unique}` // `${layer.id}_${ver}`
    const commonOptions = {
        stroked: layer.stroked,
        filled: layer.filled,
        opacity: layer.opacity,
        pickable: true,
        cursor: 'pointer',
        material: theme.material,
        visible: isVisible,

        autoHighlight: true,
        highlightColor: [0, 0, 128, 128],
    }

// console.log('RENDER TYPE>', layerType)

    switch (layerType) {
        case 'point':
            return new LOKKPointLayer({
                id: visualLayerIdentity,
                data: getData(),

                ...commonOptions,
                ...layerOptions,

                getRadius: layer.radius,
                radiusUnits: 'pixels',

                elevationScale: (layer.height?.scale ?? 1),
                extruded: true,
                filled: true,

                antialiasing: false,
            });

        case 'heatmap':
            return new HeatmapLayer({
                id: visualLayerIdentity,
                data: getData(),

                ...commonOptions,
                ...layerOptions,

                radiusPixels: layer.radius * 5,
                aggregation: 'SUM',

                colorRange: Array.isArray(layer.color) && layer.color.length > 2
                    ? layer.color.map(hexToRgb)
                    : gradientTable[20][1].map(hexToRgb),

                // colorRange: gradientTable[20][1].map(hexToRgb),

                onHover: () => {
                },
                onClick: () => {
                },
            });

        case 'trail':
        case 'line':
            return new LOKKPathLayer({
                id: visualLayerIdentity,
                data: getData(),

                ...commonOptions,
                ...layerOptions,

                _normalize: false,
                _windingOrder: "CCW",

                getWidth: 1,
                widthUnits: 'pixels',
                widthScale: layer.lineWidth ?? 1,
                widthMinPixels: 1,
            })

        case 'polygon':
            return new LOKKObjectLayer({
                id: visualLayerIdentity,
                data: getData(),

                ...commonOptions,
                ...layerOptions,

                _normalize: false,
                _windingOrder: "CCW",

                getElevation: 0,
                elevationScale: 1,
                extruded: false,
                filled: true,

                getWidth: layer.stroked ? layer.lineWidth : 0,
                widthUnits: 'pixels',
            })
            // return new LOKKPolygonLayer({
            //     id: visualLayerIdentity,
            //     data: getData(),
            //
            //     ...commonOptions,
            //     ...layerOptions,
            //
            //     getWidth: layer.stroked ? layer.lineWidth : 0,
            //     widthUnits: 'pixels',
            // })

        case 'object':
            return new LOKKObjectLayer({
                id: visualLayerIdentity,
                data: getData(),

                ...commonOptions,
                ...layerOptions,

                _normalize: false,
                _windingOrder: "CCW",

                elevationScale: (layer.height?.scale ?? 1),
                extruded: true,
                filled: true,
            })

        default:
            // points by default
            return new LOKKPointLayer({
                id: visualLayerIdentity,
                data: getData(),

                ...commonOptions,
                ...layerOptions,

                getRadius: layer.radius,
                radiusUnits: 'pixels',

                elevationScale: (layer.height?.scale ?? 1),
                extruded: true,
                filled: true,

                antialiasing: false,
            });
    }
}

const selectedFeatureIndexes = [];
export function createEditableLayer({editModeComponent, onEditDone}) {
    return new EditableGeoJsonLayer({
        id: userEditLayerId,
        data: getDefaultEditData(),
        mode: getEditMode(editModeComponent),
        selectedFeatureIndexes,
        onEdit: (editProps) => {
            const {updatedData, editType, editContext} = editProps
            if (editType === "addFeature" && onEditDone) onEditDone({...editProps, editModeComponent, selectedFeatureIndexes})
        },
        visible: true // !!editModeComponent
    })
}

export function createUserActivityLayer({me, getData}) {
    return new LOKKUserActivityLayer({
        id: userActivityLayerId,
        data: getData(),
        me,
    })
}

export function mapLayerFabric({data, viewState}) {
    return data
        .filter(item => item !== null)
        .reduce((acc, item) => ({
            ...acc,
            [item.layer.id]: layerFabric({...item, viewState})
        }), {})
}
