import React, {useState, useEffect} from 'react';
import DeckGL from '@deck.gl/react';
import StaticMap from 'react-map-gl';
import {PolygonLayer, GridCellLayer, GeoJsonLayer} from '@deck.gl/layers';
import {AmbientLight, PointLight, LightingEffect, MapView, FirstPersonView,
    View,
    WebMercatorViewport} from '@deck.gl/core';
import {TripsLayer} from '@deck.gl/geo-layers';
import {CancelToken} from 'axios'
// import {columns} from './data'
import {ScreenGridLayer} from '@deck.gl/aggregation-layers';

import {
    IconButton,
} from '@material-ui/core'
import { BarChart } from '@material-ui/icons'

import {directAxios} from '../../clients'
import {appConfig, ApiEndpoint} from '../../config'

import Loader from 'react-loader-spinner'

import TopBar from '../../components/topbar'
import LeftBar from '../../components/leftbar'

import {rgbToHex, hexToRgb, paletteColor} from '../../helpers/color'
import {people, weimarAtrium, buildingProto2, landCover} from './data'
import { mapLayers } from '../../data/map'
import { roadType } from '../../helpers/roads'

import DataBar from '../../components/databar';


// Viewport settings
const INITIAL_VIEW_STATE = {
    longitude: 11.324819,
    latitude: 50.979606,
    zoom: 17,
    pitch: 45,
    bearing: 0,
    width: window.innerWidth,
    height: window.innerHeight
};

const views = [
    new MapView({id: 'map', width: '100%', controller: true}),
    // new FirstPersonView({width: '50%', x: '50%', fovy: 50})
];

const ambientLight = new AmbientLight({
    color: [255, 255, 255],
    intensity: 1.0
});
const pointLight = new PointLight({
    color: [255, 255, 255],
    intensity: 2.0,
    position: [-74.05, 40.7, 8000]
});
const lightingEffect = new LightingEffect({ambientLight, pointLight});
const material = {
    ambient: 0.1,
    diffuse: 0.6,
    shininess: 32,
    specularColor: [60, 64, 70]
};

const DEFAULT_THEME = {
    // buildingColor: [74, 80, 87],
    buildingColor: [255,255,255],
    trailType: [
        ['razed'],

        ['steps', 'footway','path','service','pedestrian','unclassified',],

        ['cycleway'],
        ['track','secondary', 'living_street', 'residential'],
        ['primary','motorway_link',],
        ['tertiary','tertiary_link',],

    ],
    buildingColors: [
        // [0, 0, 0],
        // [255, 255, 255]
        // [96, 96, 255],
        // [255, 64, 64],
        // [49, 140, 231],
        // [255, 64, 64]

        // [63, 0, 255],
        // [255, 0, 63],
        // [135, 206, 250],
        // [255, 85, 0],

        [135, 206, 250],
        [255, 85, 0],
    ],
    trailColors: [
        [238, 238, 238],

        [23, 184, 190],

        [253, 227, 94],
        [253, 198, 94],
        [253, 174, 94],
        [253, 128, 94],

        [253, 104, 94],

    ],
    material,
    // effects: [lightingEffect]
};
const theme = DEFAULT_THEME;
const loopLength = 1200;
const animationSpeed = 10;
const trailLength = 800;
let requestTimeout = null;
let currentViewState = INITIAL_VIEW_STATE;
let cancelRequest;
let isLoading = false;


// DeckGL react component
function Map() {

    const [data, setData] = useState({
        roads: [],
        buildings: [],
        userBuildings: [
            weimarAtrium
        ]
    })
    const [layerSettings, setlayerSettings] = useState({
        layer: mapLayers[0].id,
        buildingColors: theme.buildingColors,
        building: {
            q: 20,
            rent: 8
        }
    });
    const [isEditMode, setEditMode] = useState(false)
    const [time, setTime] = useState(0);
    const [animation] = useState({});

    const animate = () => {
        setTime(t => (t + animationSpeed) % loopLength);
        animation.id = window.requestAnimationFrame(animate);
    };

    useEffect(
        () => {
            animation.id = window.requestAnimationFrame(animate);
            return () => window.cancelAnimationFrame(animation.id);
        },
        [animation]
    );

    const handleChangeLayer = (layerId) => {
        setlayerSettings((prevState) => ({
            ...prevState,
            layer: layerId
        }))
    }

    const loadData = () => {

        if (cancelRequest) {
            cancelRequest();
            cancelRequest = null;
        }

        isLoading = true;

        const viewport = new WebMercatorViewport(currentViewState)
        const bbox = viewport.getBounds();

        // const nw = viewport.unproject([0, viewport.height]);
        // const se = viewport.unproject([viewport.width, 0]);

        const dataApi = new ApiEndpoint('data');
        directAxios
            .post(dataApi.endpoint('/layer/all'), {
                bbox,
                zoom: currentViewState.zoom
            }, {
                cancelToken: new CancelToken ((c) => {
                    cancelRequest = c;
                })
            }).then((loaded_data) => {
                isLoading = false;
                setData((prevState) => {
                    return {
                        ...prevState,
                        ...loaded_data.data
                    }
                })
            }).catch(() => {
                // isLoading = false;
            });
    }

    useEffect(
        () => {
            requestTimeout = setTimeout(() => {
                loadData()
            }, 500)

        },
        []
    );

    const getCentroid = (item) => {
        const minX = Math.min.apply(null, item.geom.coordinates[0][0].map(xy => xy[0]))
        const minY = Math.min.apply(null, item.geom.coordinates[0][0].map(xy => xy[1]))
        const maxX = Math.max.apply(null, item.geom.coordinates[0][0].map(xy => xy[0]))
        const maxY = Math.max.apply(null, item.geom.coordinates[0][0].map(xy => xy[1]))
        return [
            minX + (maxX - minX) / 2,
            minY + (maxY - minY) / 2
        ]
    }

    const getDistance = (a, b) => {
        const ca = getCentroid(a);
        const cb = getCentroid(b);

        return Math.sqrt(
            Math.abs(cb[0] - ca[0]) ** 2 + Math.abs(cb[1] - ca[1]) ** 2
        )
    }

    const calcFinalQ = (item) => {

        return  data.userBuildings.reduce((acc_q, b) => {
            const d = getDistance(item, b);

            // 200 m
            if(d && d < 0.002) {
                acc_q += b.self_q / Math.min(Math.max((d * 10000) ^ 2, 1), 200)
            }

            return acc_q
        }, item.q)
    }


    //     "1 - Rent",
    //     "2 - Q purchasing",
    //     "3 - Pedestrant leakage"
    const layerSet = {

        // Rent Price
        [mapLayers[0].id]: [
            new PolygonLayer({
                id: 'ground',
                data: landCover,
                getPolygon: f => f,
                stroked: false,
                getFillColor: [0, 0, 0, 0]
            }),
            new TripsLayer({
                id: 'trips',
                data: data.roads.map((item) => {
                    return {
                        vendor: roadType(item),
                        path: item.geom.coordinates,
                        timestamps: item.geom.coordinates.map((_, i) => {
                            return i * 400
                        })
                    }
                }),
                getPath: d => d.path,
                getTimestamps: d => {
                    return d.timestamps
                },
                currentTime: time,
                getColor: d => (theme.trailColors[d.vendor]),
                opacity: 0.3,
                widthMinPixels: 4,
                rounded: true,
                trailLength,
                shadowEnabled: false
            }),
            new PolygonLayer({
                id: 'buildings',
                data: data.buildings.concat(data.userBuildings).map((item) => {
                    return {
                        q: calcFinalQ(item),
                        self_q: item.self_q,
                        height: 20,
                        rent_price: item.rent_price,
                        polygon: item.geom.coordinates[0][0].map((coords) => {
                            return [coords[0], coords[1]]
                        })
                    }
                }),
                extruded: true,
                wireframe: false,
                pickable: true,
                onDragStart: (data) => {

                },
                onDrag: (data) => {

                },

                // opacity: 0.9,
                getPolygon: f => f.polygon,
                getElevation: f => f.height,
                getFillColor: b => {

                    let rp = b.rent_price;


                    if (!rp) {
                        return [255, 255, 255]
                    }

                    return paletteColor((b.rent_price - 5) * 2 / 10, layerSettings.buildingColors[0], layerSettings.buildingColors[1]);
                },
                material: theme.material
            })
        ],

        // Q function
        [mapLayers[1].id]: [
            new PolygonLayer({
                id: 'ground',
                data: landCover,
                getPolygon: f => f,
                stroked: false,
                getFillColor: [0, 0, 0, 0]
            }),
            new TripsLayer({
                id: 'trips',
                data: data.roads.map((item) => {

                    let vendor = 0;
                    theme.trailType.forEach((types, i) => {
                        const finded = types.findIndex((t) => String(item.highway).search(t) > -1)
                        if (finded > -1) {
                            vendor = i
                        }
                    });

                    if (item.maxspeed) {
                        if (item.maxspeed > 20 && item.maxspeed <= 30) {
                            vendor = Math.max(2, vendor);
                        } else if (item.maxspeed > 30 && item.maxspeed <= 50) {
                            vendor = Math.max(3, vendor);
                        } else if (item.maxspeed > 50 && item.maxspeed <= 70) {
                            vendor = Math.max(4, vendor);
                        } else if (item.maxspeed > 70) {
                            vendor = Math.max(6, vendor)
                        }
                    }

                    return {
                        vendor,
                        path: item.geom.coordinates,
                        timestamps: item.geom.coordinates.map((_, i) => {
                            return i * 400
                        })
                    }
                }),
                getPath: d => d.path,
                getTimestamps: d => {
                    return d.timestamps
                },
                currentTime: time,
                getColor: d => (theme.trailColors[d.vendor]),
                opacity: 0.3,
                widthMinPixels: 4,
                rounded: true,
                trailLength,
                shadowEnabled: false
            }),
            new PolygonLayer({
                id: 'buildings',
                data: data.buildings.concat(data.userBuildings).map((item) => {
                    return {
                        q: calcFinalQ(item),
                        self_q: item.self_q,
                        height: 20,
                        rent_price: item.rent_price,
                        polygon: item.geom.coordinates[0][0].map((coords) => {
                            return [coords[0], coords[1]]
                        })
                    }
                }),
                extruded: true,
                wireframe: false,
                pickable: true,
                // opacity: 0.9,
                getPolygon: f => f.polygon,
                getElevation: f => f.height,
                getFillColor: b => {

                    let q = Math.round((b.self_q + b.q) / 10);

                    if (q >= 5) {
                        q = 1
                    } else {
                        q = q / 5
                    }

                    if (!q) q = 0;

                    return paletteColor(q, layerSettings.buildingColors[0], layerSettings.buildingColors[1])

                    // if (q == 0){
                    //     return theme.buildingColors[0]
                    // } else if (q === 1) {
                    //     return theme.buildingColors[1]
                    // } else if (q === 8) {
                    //     return theme.buildingColors[2]
                    // } else if (q === 9) {
                    //     return theme.buildingColors[3]
                    // } else {
                    //     return paletteColor(q, theme.buildingColors[1], theme.buildingColors[2])
                    // }
                },
                material: theme.material
            })
        ],

        // Leakage
        [mapLayers[2].id]: [
        new ScreenGridLayer({
            id: 'grid',
            data: people,
            opacity: 0.8,
            getPosition: d => [
                d['geometry']['coordinates'][0] + Math.random() * 0.001,
                d['geometry']['coordinates'][1] + Math.random() * 0.001
            ],
            getWeight: d => Math.random() * time,
            cellSizePixels: 20,
            currentTime: time,
            colorRange: [
                [255, 255, 178, 25],
                [254, 217, 118, 85],
                [254, 178, 76, 127],
                [253, 141, 60, 170],
                [240, 59, 32, 212],
                [189, 0, 38, 255]
            ],
            gpuAggregation: true,
            aggregation: 'SUM',
        })
    ],

}

    const mapLayerComponents = layerSet[layerSettings.layer];

    const handleAddBuildingMode = (mode) => {
        setEditMode(mode);
    }

    const handleBuildingSettingsChange = (settings) => {
        setlayerSettings(prevState => ({
            ...prevState,
            building: {
                ...settings
            }
        }));
    }

    const handlePlaceBuilding = (data) => {

        const newBuilding = {
            q: 0,
            self_q: layerSettings.building.q,
            height: 20,
            rent_price: layerSettings.building.rent,
            geom: {
                coordinates: [[
                    buildingProto2.coordinates.map((pair) => {
                        return [
                            data.lngLat[0] + pair[0] + (Math.random() * 2 - 1) * (buildingProto2.dx * 2),
                            data.lngLat[1] + pair[1] + (Math.random() * 2 - 1) * (buildingProto2.dx * 2),
                        ]
                    })
                ]]
            }
        }

        setData((state) => {
            return {
                ...state,
                userBuildings: state.userBuildings.concat([
                    newBuilding
                ])
            }
        })
    }

    return (
        <>

            <TopBar />

            <LeftBar
                onLayerChange={handleChangeLayer}
                onAddBuildingModeChange={handleAddBuildingMode}
                onBuildingSettingsChange={handleBuildingSettingsChange}
            />

            <DataBar viewState={currentViewState} />

            <DeckGL
                initialViewState={INITIAL_VIEW_STATE}
                controller={true}
                effects={theme.effects}
                layers={mapLayerComponents}
                getCursor={() => isEditMode ? "crosshair" : "grab"}
                onViewStateChange={(data) => {
                    currentViewState = data.viewState;
                    if (data.oldViewState.zoom !== currentViewState.zoom) {
                        if (requestTimeout) {
                            clearTimeout(requestTimeout)
                        }
                        requestTimeout = setTimeout(() => {
                            loadData()
                        }, 500)

                    }
                }}
                onDragStart={() => {
                    if (requestTimeout) {
                        clearTimeout(requestTimeout)
                    }
                }}
                onDragEnd={(data) => {
                    if (requestTimeout) {
                        clearTimeout(requestTimeout)
                    }

                    loadData();
                }}
                onResize={(data) => {
                    // console.log(data)
                }}
                onClick={(data) => {
                    if (isEditMode) {
                        handlePlaceBuilding(data)
                    }
                }}
                views={views} >
                <View id="map">
                    <StaticMap
                        reuseMaps
                        mapStyle={appConfig.mapbox.style.dark}
                        preventStyleDiffing={true}
                        mapboxApiAccessToken={appConfig.mapbox.token}
                    />

                    <Loader
                        style={{position: 'fixed', left: '50vw', top: '50vh', transform: 'translate(-50%, -50%)'}}
                        visible={isLoading}
                        type="Oval"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        timeout={10000}
                    />

                </View>

            </DeckGL>

        </>
    );
}

export default Map;