import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        // fill: 'currentColor'
        stroke: 'currentColor', // #4E4F54
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
        marginTop: 0,
    }
}));

const ObjectIcon = (props) => {
    const classes = useStyles();

    return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="13" height="13"
         viewBox="0 0 768 767.999994"
         fill="none"
         zoomAndPan="magnify" preserveAspectRatio="xMidYMid meet"
         version="1.0"
         className={classes.root}
    >
        <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
              d="M 76.800781 163.753906 L 76.800781 580.222656 L 383.925781 691.179688 L 383.925781 163.753906 L 76.800781 163.753906 "
              fillOpacity="1" fillRule="nonzero"/>
        <path fill="rgb(60.389709%, 65.489197%, 69.799805%)"
              d="M 383.925781 163.753906 L 383.925781 691.179688 L 691.050781 580.222656 L 691.050781 163.753906 L 383.925781 163.753906 "
              fillOpacity="1" fillRule="nonzero"/>
        <path fill="rgb(95.689392%, 94.898987%, 94.898987%)"
              d="M 383.925781 81.429688 L 76.800781 163.753906 L 383.925781 246.082031 L 691.050781 163.753906 L 383.925781 81.429688 "
              fillOpacity="1" fillRule="nonzero"/>
    </svg>
)
};

export default ObjectIcon;