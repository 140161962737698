import React, {useEffect, useState} from "react";
import GL from '@luma.gl/constants';
import {
    SolidPolygonLayer,
} from '@deck.gl/layers';
import { uuidStringify } from '../../../../../../helpers/uuid'


class LOKKObjectLayer extends SolidPolygonLayer {
    initializeState(params) {
        super.initializeState(params);
        const attributeManager = this.getAttributeManager();

        attributeManager.addInstanced({
            identity: {size: 16, accessor: 'getIdentity', type: GL.UNSIGNED_BYTE}
        })
    }
    getIdentity() {
        return this.identity
    }
    getPickingInfo(pickParams) {
        // идея:
        // 1. делаю свой пикинг колор который тупо 0-1-2-3-4-5,
        // 2. на его основе выбираю из identity объект со своим uuid
        // 3. расшифровываю uuid
        const data = super.getPickingInfo(pickParams)

        const identityData = this.getAttributeManager().getAttributes().identity.value
        const identityBytes = identityData.subarray(data.index * 16, data.index * 16 + 16)
        const identity = uuidStringify(identityBytes)

        return {...data, identity}
    }
    shouldUpdateState(params) {
        const {props, oldProps, context, changeFlags} = params
        const result = super.shouldUpdateState(params)
        return result
    }
}


LOKKObjectLayer.defaultProps = {
    ...LOKKObjectLayer.defaultProps,
    getIdentity: {type: 'accessor', value: d => d.identity}
}

export default LOKKObjectLayer;