import {authAxios} from '../../../clients/directAxios'
import {ApiEndpoint} from "../../../config";
import {getToken} from "../../../actions/auth";


const algApi = new ApiEndpoint('alg')

const apiClient = authAxios({
    auth: {
        access_token: getToken()
    }
});

export default async function SpreadMetricCalcOne(projectId, objectId, title, lineage) {

    // const executeParams = {
    //     title,
    //     project_id: projectId,
    //     algorithm: "weightgravity",
    //     object_type_id: lineage.object_type_id,
    //     ver: lineage.ver,
    //     filter: lineage.filters,
    //     params: lineage.params,
    //     operands: lineage.operands,
    //     metric_id: lineage.metric_id,
    //     object_id: objectId
    // }
    //
    // const response = await apiClient.post(algApi.endpoint('/execute/one'), executeParams)

    return null // response.data?.data[0]
}
