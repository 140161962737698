import React, {useState} from "react";
import {
    IconButton,
    Paper,
    SwipeableDrawer,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tabs, Tab, Box,
} from "@material-ui/core";
import {WebMercatorViewport} from '@deck.gl/core';
import {BarChart, ChevronRightRounded} from "@material-ui/icons";
import css from 'styled-components'
import {ApiEndpoint} from "../config";
import {directAxios} from "../clients";
// import {CancelToken} from "axios";


const TabPabelDiv = css.div`
    width: auto;
    box-sizing: border-box;    
`

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const RoadsDataTable = function (props) {
    const { title, data, hint } = props;
    return (
        <section style={{padding: '10px 40px'}}>
            <Typography variant="h6" component='h6' style={{marginBottom: '1rem'}}>
                {title}
            </Typography>

            {hint &&
            <Typography variant="caption" component="small" style={{marginBottom: '1rem'}}>{hint}</Typography>
            }

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: 'bold'}} >Road class</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Speed&nbsp;(km/h)</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Length&nbsp;(m)</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Connectivity&nbsp;(s)</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.class}>
                                <TableCell component="th" scope="row">
                                    {row.class_name}
                                </TableCell>
                                <TableCell align="right">{parseFloat(row.avg_speed).toFixed(2)}</TableCell>
                                <TableCell align="right">{parseFloat(row.length).toFixed(2)}</TableCell>
                                <TableCell align="right">{parseFloat(row.connectivity).toFixed(2)}</TableCell>
                                <TableCell align="right">{row.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </section>
    )
}

const buildingFeatures = {
    'q': 'Purchasing power (city)',
    'self_q': 'Purchasing power (by building)',
    'rent_price': 'Rent price (euro/month)',
    'height': 'Height (m)',
    'levels': 'Number of storeys',
    'pop': 'Number of households',
    'count': 'Number of buildings'
}
const BuildingsDataTable = function (props) {
    const { title, data, hint } = props;
    return (
        <section style={{padding: '10px 40px'}}>
            <Typography variant="h6" component='h6' style={{marginBottom: '1rem'}}>
                {title}
            </Typography>

            {hint &&
            <Typography variant="caption" component="small" style={{marginBottom: '1rem'}}>{hint}</Typography>
            }

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: 'bold'}}>Feature</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(buildingFeatures).map((k) => (
                            <TableRow key={k}>
                                <TableCell component="th" scope="row">
                                    {buildingFeatures[k]}
                                </TableCell>
                                <TableCell align="right">{k === 'count'
                                    ? data[k]
                                    : parseFloat(data[k]).toFixed(2)
                                }</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </section>
    )
}

const DataBarHeader = css.div`
    min-width: 680px;
    width: auto;
    box-sizing: border-box;
    text-align: right;
`;


const DataBar = function (props) {

    const { viewState } = props;

    const [dataPanelOpened, setDataPanelOpened] = useState(false)

    const [currentTab, setCurrentTab] = useState(0);

    const [tableData, setTableData] = useState({
        city: {
            road: [],
            building: []
        },
        view: {
            road: [],
            building: []
        }
    })

    const loadData = () => {

        const viewport = new WebMercatorViewport(viewState)
        const bbox = viewport.getBounds();

        // const nw = viewport.unproject([0, viewport.height]);
        // const se = viewport.unproject([viewport.width, 0]);

        const dataApi = new ApiEndpoint('data');
        directAxios
            .post(dataApi.endpoint('/layer/stat'), {
                bbox,
                zoom: viewState.zoom
            }).then((loaded_data) => {
            setTableData((prevState) => loaded_data.data)
        }).catch(() => {
            // isLoading = false;
        });
    }

    const handlePanelOpened = (isOpen) => {
        setDataPanelOpened(isOpen)

        if (isOpen) {
            loadData()
        }
    }


    return (
        <React.Fragment>
            <div style={{
                position: 'fixed',
                top: '50%',
                right: '10px',
                zIndex: 10,
            }}>
                <IconButton
                    variant="contained"
                    onClick={() => handlePanelOpened(true)}
                    style={{background: '#DEE6F1'}}
                >
                    <BarChart style={{color: '#222'}} />
                </IconButton>
            </div>

            <SwipeableDrawer
                anchor='right'
                open={dataPanelOpened}
                onOpen={() => {}}
                onClose={() => {}}
            >
                <DataBarHeader>
                    <IconButton onClick={() => handlePanelOpened(false)}>
                        <ChevronRightRounded />
                    </IconButton>
                </DataBarHeader>

                <Tabs
                    value={currentTab}
                    onChange={(e, tab) => {
                        setCurrentTab(tab)
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Roads" />
                    <Tab label="Buildings" />
                </Tabs>

                <TabPanel value={currentTab} index={0}>
                    <RoadsDataTable title='City' data={tableData.city.road} />

                    {tableData.view?.road?.length > 0 &&
                    <RoadsDataTable
                        title='Current view'
                        data={tableData.view.road}
                        hint='Current view includes nearest radius about 200 meters to make good data analysis'
                    />
                    }
                </TabPanel>


                <TabPanel value={currentTab} index={1}>
                    <BuildingsDataTable title='City' data={tableData.city.building} />

                    {tableData.view?.building &&
                    <BuildingsDataTable
                        title='Current view'
                        hint='Current view includes nearest radius about 200 meters to make good data analysis'
                        data={tableData.view.building}
                    />
                    }
                </TabPanel>


            </SwipeableDrawer>

        </React.Fragment>
    )
}

export default DataBar;