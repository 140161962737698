import React from 'react';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Scrollbar from "react-perfect-scrollbar";

import {
    Card,
    CardHeader,

    Grid,
    Button,
    Checkbox,
    Divider,

    List,
    ListItem,
    ListItemIcon,
    ListItemText,

} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '8px auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: '100%',
        height: 300,
        // backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    transferButtons: {
        width: '100%'
    },
    transferCard: {
        background: 'transparent',
        boxShadow: 'none'
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter((value) => !b.find(item => item.id === value));
}

function intersection(a, b) {
    return a.filter((value) => !!b.find(item => item.id === value));
}

function union(a, b) {
    return [...a, ...not(a, b)];
}


export default function TransferSelect(props) {
    const classes = useStyles();
    const {options, defaultValue, disabled, onChange} = props

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(options.filter(option => defaultValue.indexOf(option.id) === -1));
    const [right, setRight] = React.useState(options.filter(option => defaultValue.indexOf(option.id) > -1));

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(items.map(item => item.id));
        }
    };

    const handleCheckedRight = () => {
        const values = right.concat(options.filter(option => leftChecked.indexOf(option.id) > -1)).sort((a, b) => a.title < b.title ? -1 : 1)

        setRight(values);
        setLeft(left.filter(item => leftChecked.indexOf(item.id) === -1).sort((a, b) => a.title < b.title ? -1 : 1));
        setChecked(checked.filter(item => leftChecked.indexOf(item) === -1));

        if (onChange) onChange({
            target: {
                value: values.map(value => value.id)
            }
        })
    };

    const handleCheckedLeft = () => {
        const values = right.filter(item => rightChecked.indexOf(item.id) === -1).sort((a, b) => a.title < b.title ? -1 : 1)

        setLeft(left.concat(options.filter(option => rightChecked.indexOf(option.id) > -1)).sort((a, b) => a.title < b.title ? -1 : 1));
        setRight(values);
        setChecked(checked.filter(item => rightChecked.indexOf(item) === -1));

        if (onChange) onChange({
            target: {
                value: values.map(value => value.id)
            }
        })
    };

    const customList = (title, items) => (
        <Card className={classes.transferCard}>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{'aria-label': 'all items selected'}}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider/>
            <List className={classes.list} dense component="div" role="list">
                <Scrollbar>
                    {items.map((value) => {
                        const labelId = `transfer-list-all-item-${value.id}-label`;

                        return (
                            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value.id)}>
                                <ListItemIcon>
                                    <Checkbox
                                        disabled={disabled}
                                        checked={checked.indexOf(value.id) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{'aria-labelledby': labelId}}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value.title}/>
                            </ListItem>
                        );
                    })}
                    <ListItem/>
                </Scrollbar>
            </List>
        </Card>
    );

    return (
        <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid xs={12} sm={5} item>{customList('Choices', left)}</Grid>
            <Grid xs={12} sm={2} item justify="center">
                <Grid container direction="column" alignItems="center" className={classes.transferButtons}>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={disabled || leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={disabled || rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid xs={12} sm={5} item>{customList('Chosen', right)}</Grid>
        </Grid>
    );
}
