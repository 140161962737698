import React from "react";
import {objectOnlyKeys, objectExceptKey} from '../../../../helpers/fn'


export const initialDataState = {

    me: undefined,
    project: {},
    steps: [],

    activityObjects: [],

    objectType: {},
    metric: {},
    cube: {},
    filter: {},
    isLoaded: true
};


const prepareMetric = (state) => ({
    ...state,
    metric: Object.fromEntries(Object.entries(state.metric).map(([key, metric]) => {
        return [key, {
            ...metric,
            object: metric.object ? objectExceptKey(metric.object, ['datasets', 'metrics_link']) : {},
            objectType: metric.objectType ? objectExceptKey(metric.objectType, ['children']) : {}
        }]
    }))
})

export const saveableDataState = (state) => prepareMetric(
    objectOnlyKeys(state, ['objectType', 'metric', 'cube', 'filter']))


const dataStateReducer = function (state = initialDataState, action) {
    window.dataState = state

    switch (action.type) {

        case 'addActivityObjects':
            return {
                ...state,
                activityObjects: state.activityObjects.concat(action.activityObjects)
            }

        case 'removeActivityObject':
            return {
                ...state,
                activityObjects: state.activityObjects.filter(id => id !== action.objectId)
            }

        case 'setMe':
            return {
                ...state,
                me: {
                    ...action.me,
                }
            }

        case 'setDataLoaded':
            return {
                ...state,
                isLoaded: action.isLoaded
            }

        case 'setFilter':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.cube_id]: {
                        cube_id: action.cube_id,
                        cube_name: action.cube_name,
                        cube_object: action.cube_object,
                        fields: action.fields,
                        enabled: action.enabled,
                        ordering: action.ordering,
                        limit: action.limit,
                        offset: action.offset
                    }
                }
            }

        case 'setObjectType':
            return {
                ...state,
                objectType: action.objectType
            }

        case 'addObjectType':
            return {
                ...state,
                objectType: {
                    ...state.objectType,
                    [action.objectType.id]: action.objectType
                }
            }

        case 'addCube':
            return {
                ...state,
                cube: {
                    ...state.cube,
                    [action.cube.id]: action.cube
                }
            }

        case 'addCubeStats':
            return {
                ...state,
                cube: {
                    ...state.cube,
                    [action.cubeId]: {
                        ...state.cube[action.cubeId],
                        stats: action.stats
                    }
                }
            }

        case 'removeCubeById':
            return {
                ...state,
                cube: Object.values(state.cube)
                    .filter(cube => cube.id !== action.cubeId)
                    .reduce((cubes, cube) => ({
                        ...cubes,
                        [cube.id]: cube
                    }), {})
            }

        case 'setCubes':
            return {
                ...state,
                cube: action.cube
            }

        case 'setProject':
            return {
                ...state,
                project: action.project
            }

        case 'addMetric':
            return {
                ...state,
                metric: {
                    ...state.metric,
                    [action.metric.id]: action.metric
                }
            }

        case 'setMetric':
            return {
                ...state,
                metric: action.metric
                // todo: clear old filters and (may be) cubes?
            }

        // case 'setCurrentStep':
        //     return {
        //         ...state,
        //         projectCurrentStepId: action.currentStepId
        //     }

        case 'load':
            return {
                ...state,
                ...action.state
            }

        default:
            return state;
    }
}
export default dataStateReducer;

export const DataStateContext = React.createContext(initialDataState)
