import React, {useEffect, useState} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import '../../../../../../styles/scrollbar.css';
import Scrollbar from 'react-perfect-scrollbar'
import {directAxios} from "../../../../../../clients";
import {ApiEndpoint} from "../../../../../../config";
import {getValueRepr} from '../../../../../../helpers/repr'


const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
    },

    title: {
        marginBottom: '1rem',
        margin: '0 20px',
    },

    scrollContainer: {
        margin: '0 20px',
    },

    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },

    keyCell: {
        minWidth: 180
    },

    valueCell: {

    }
}));

let isLoading = false;

const ItemBarItemTable = function (props) {
    const classes = useStyles();

    const { itemview: { dataview }, itemId } = props;
    const metrics = dataview?.metrics ? dataview?.metrics: [];
    const { id: dataviewId, dataset_link: {dataset: {meta: {title: datasetTitle}}} } = dataview

    const [itemData, setItemData] = useState(null)

    const loadItemData = () => {
        const dataApi = new ApiEndpoint('dataview');
        directAxios
            .post(dataApi.endpoint('/item'), {
                dataview_id: dataviewId,
                item_id: itemId
            }).then((response) => {
                isLoading = false;
                setItemData(response.data.item)
            }).catch(() => {
                isLoading = false;
            });
    }

    useEffect(() => {
        if (dataviewId) {
            loadItemData()
        }
    }, [itemId])

    const maxHeight = window.innerHeight - 150

    return (
        <section className={classes.root}>
            <Typography variant="h3" className={classes.title}>{itemData?.title}</Typography>

            <TableContainer style={{maxWidth: '100%'}}>
                <Scrollbar>
                    <section className={classes.scrollContainer} style={{height: maxHeight}}>
                        <Table>
                            <TableBody>
                                {itemData && metrics.map(({metric}, mi) => (
                                    <TableRow key={mi} className={classes.row}>
                                        <TableCell align="left" key={0} className={classes.keyCell}>
                                            <b>{metric.meta.short}</b>
                                        </TableCell>
                                        <TableCell align="left" key={1} className={classes.valueCell}>
                                            {getValueRepr(itemData.values[metric.id])}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </section>
                </Scrollbar>
            </TableContainer>

        </section>
    )
}

export default ItemBarItemTable;