import React from "react";
import Scrollbar from "react-perfect-scrollbar";
import {Avatar, Divider, Grid, IconButton, Input, ListItem, ListItemSecondaryAction, Tooltip} from "@material-ui/core";
import {Close, Delete} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {isFieldObject, isFieldScalar, isFieldUUID} from "../../../helpers/field";
import {getFormatedValue} from "../../../helpers/repr";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 15px',
        margin: '10px 0',
        boxSizing: 'border-box'
    },

    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    controls: {
        position: 'relative',
        margin: '10px 0',
        textAlign: 'center',
    },

    fieldChip: {
        display: 'inline-block',
        maxWidth: 170,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '12px',
        lineHeight: '32px',
    },

    fieldWeight: {
        display: 'inline-block',
        width: 80,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '12px',
        lineHeight: '32px',
        textAlign: 'right'
    },

    operandWeightLabel: {
        textAlign: 'right'
    },

    operandRow: {
        margin: '2px 0'
    },

    addItemButton: {
        paddingTop: '1rem'
    },

    controlDivider: {
        margin: '1rem 0'
    },

    selectFieldPlace: {
        marginLeft: 10
    },

    lineWidthControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    deleteButton: {
        margin: 0,
        float: 'right'
    },


}));


const scalarFullTitle = (selectedMetric, selectedCube, selectedField, operand) => {
    if (operand.value) {
        return `${selectedMetric?.object?.meta?.title} / ${selectedMetric?.metric?.meta?.title} / ${selectedField?.title} = ${getFormatedValue(operand.value)}`
    }

    return `${selectedMetric?.object?.meta?.title} / ${selectedMetric?.metric?.meta?.title} / ${selectedField?.title}`
}
const dictFullTitle = (selectedMetric, selectedCube, selectedField, operand) => {
    const selectedFiledDict = selectedCube?.dicts?.find(dict => dict.field === operand.field)
    const selectedFieldValue = selectedFiledDict?.values.find(value => value.id === operand.value)

    if (selectedFieldValue) {
        return `${selectedMetric?.object?.meta?.title} / ${selectedMetric?.metric?.meta?.title} / ${selectedField?.title} = ${selectedFieldValue?.title}`
    }

    return `${selectedMetric?.object?.meta?.title} / ${selectedMetric?.metric?.meta?.title} / ${selectedField?.title}`
}

const scalarShortTitle = (selectedMetric, selectedCube, selectedField, operand) => selectedField?.title

const dictShortTitle = (selectedMetric, selectedCube, selectedField, operand) => {
    const selectedFiledDict = selectedCube?.dicts?.find(dict => dict.field === operand.field)
    const selectedFieldValue = selectedFiledDict?.values.find(value => value.id === operand.value)

    if (selectedFieldValue) {
        return `${selectedField?.title} / ${selectedFieldValue?.title}`
    }

    return selectedField?.title
}

export const WeightedIndexForm = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {metrics, cubes, metric, lineage, onDelete, onModify} = props
    const {algorithm, filters, operands, params} = lineage

    return (
        <div className={classes.root}>
            <div>

                <Grid container className={classes.operandRow}>
                    <Grid item xs={8}>
                        {t('widgets.alg_form.metric_and_field')}
                    </Grid>
                    <Grid item xs={4} className={classes.operandWeightLabel}>
                        {t('widgets.alg_form.weight')}
                    </Grid>
                </Grid>
                {
                    Object.entries(operands).map(((operandKeyPair) => {
                        const [key, operand] = operandKeyPair

                        const selectedMetric = metrics[operand.metric_id]
                        const selectedCube = cubes[operand.metric_id]
                        const availableFields = selectedCube?.struct?.fields.filter(fld => !isFieldObject(fld))
                        const selectedField = availableFields?.find(fld => fld.name === operand.field)
                        const maybeDict = isFieldUUID(selectedField)

                        const fullTitle = maybeDict
                            ? dictFullTitle(selectedMetric, selectedCube, selectedField, operand)
                            : scalarFullTitle(selectedMetric, selectedCube, selectedField, operand)

                        const shortTitle = maybeDict
                            ? dictShortTitle(selectedMetric, selectedCube, selectedField, operand)
                            : scalarShortTitle(selectedMetric, selectedCube, selectedField, operand)

                        return (
                            <Tooltip title={fullTitle}>
                                <Grid container key={key} className={classes.operandRow}>
                                    <Grid item xs={8}>
                                        <span className={classes.fieldChip}>{shortTitle}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span className={classes.fieldWeight}>{operand.weight}</span>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        )
                    }))
                }
            </div>
            <Divider className={classes.controlDivider}/>
            <div className={classes.controls}>
                <Button
                    onClick={() => {
                        if (onModify) onModify({metric, lineage})
                    }}
                    variant="contained"
                    size="small"
                >
                    {t('widgets.alg_form.modify')}
                </Button>

                <IconButton edge="end" aria-label="delete" size="small" className={classes.deleteButton}>
                    <Delete
                        onClick={() => {
                            if (onDelete) onDelete(metric)
                        }}
                        fontSize="small" />
                </IconButton>
            </div>
        </div>
    )
}
