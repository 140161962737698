import {useTranslation} from "react-i18next";
import {FormControl, Slider, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    filterControl: {
        width: 280,
        marginRight: '16px',
    },

    filterSelector: {
        width: '100%'
    },

    filterSliderTitle: {
        fontSize: '12px',
        marginBottom: '15px'
    },

    filterSlider: {
        width: '100%',
        '& .MuiSlider-markLabel': {
            fontSize: '10px'
        }
    },

}))


export default function TimestampFilter(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        filterField,
        timeseries,
        value,
        onChange,
    } = props

    return (
        <FormControl className={classes.filterControl} key={filterField.name}>
            <Typography gutterBottom className={classes.filterSliderTitle}>
                {filterField.title}
            </Typography>
            <Slider
                defaultValue={value ?? [parseInt(timeseries[0]), parseInt(timeseries[timeseries.length - 1])]}
                min={parseInt(timeseries[0])}
                max={parseInt(timeseries[timeseries.length - 1])}
                step={1}
                marks={timeseries ? timeseries.map(item => ({value: parseInt(item), label: item})) : []}
                getAriaValueText={(value) => (value)}
                valueLabelFormat={(value) => (value)}
                onChange={(e, value) => {

                }}
                onChangeCommitted={(e, value) => {
                    onChange(value)
                }}
                className={classes.filterSlider}
                valueLabelDisplay="auto"
                aria-labelledby="discrete-slider-custom"
            />
        </FormControl>
    )
}
