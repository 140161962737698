import React, {useContext} from "react";
import {Link} from 'react-router-dom'
import {
    AppBar, IconButton, Toolbar, Typography,
    Stepper, Step, StepButton, Menu, MenuItem,
    LinearProgress,
    ButtonGroup,
    Button,
    Divider,

} from "@material-ui/core";
import {AccountCircle, Apps, CloudQueue, MoreVert} from "@material-ui/icons";
import {makeStyles, styled} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import {DataStateContext, MapStateContext} from "../reducer";
import ProfileButton from '../../../../components/profile-button'
import HelpButton from '../../../../components/help-button'

import { getValueRepr } from '../../../../helpers/repr.js'
import { hasMetrics } from '../../../../helpers/data.js'
import { isManager } from '../../../../helpers/user.js'
import LangButton from "../../../../components/lang-button";
import MapIcon from '../../../../icons/MapIcon'
import ChartIcon from '../../../../icons/ChartIcon'


const AppTopBar = styled(AppBar)({
    flexGrow: 1,
    background: '#35424E',
    zIndex: 1000,
    '& .MuiToolbar-regular': {
        minHeight: '48px'
    }
})

const LogoButton = styled(IconButton)({
    padding: '12px',
    marginTop: '0'
})

const AppTitle = styled(Typography)({
    flexGrow: 1,
})


const useStyles = makeStyles((theme) => ({
    root: {

    },

    logoLayout: {
        boxSizing: 'border-box',
        display: 'block',
        width: 42
    },

    coordinatesLayout: {
        marginLeft: 0,
        color: '#D1D7DE',
        lineHeight: '14px'
    },

    projectStepsLayout: {
        marginLeft: 6,
        flexGrow: 1,
        color: '#D1D7DE',
        '& .MuiButton-label': {
            color: '#D1D7DE',
        },
        '& .MuiButtonGroup-groupedTextHorizontal:not(:last-child)': {
            borderColor: '#D1D7DE',
        }
    },

    pageSwitchButton: {
        padding: '0 12px 0 12px',
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        },
        '&.active': {
            opacity: 1
        },
    },

    default: {
        marginLeft: 40,
        flexGrow: 1,
    }
}));


const TopBar = (props) => {
    const classes = useStyles()
    const { t } = useTranslation();

    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {dataState, dataDispatch} = useContext(DataStateContext)
    const { me } = dataState
    const { visibleViewState, currentPage } = mapState

    const showPageSwitcher = hasMetrics(dataState) && isManager(me)

    return (
        <AppTopBar position="fixed">
            <Toolbar>
                <div className={classes.logoLayout}>
                    <Link to={'/'}>
                        <LogoButton edge="start" color="inherit" aria-label="menu">
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 1H1V8.4994M17 1V16M17 1L1 8.4994M17 16H1V8.4994M17 16L1 8.4994" stroke="#D1D7DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </LogoButton>
                    </Link>
                </div>

                {visibleViewState !== undefined &&
                    <div className={classes.coordinatesLayout}>
                        {getValueRepr(visibleViewState?.longitude)}, {getValueRepr(visibleViewState?.latitude)}, {getValueRepr(visibleViewState.zoom)}
                    </div>
                }

                {showPageSwitcher &&
                <div className={classes.projectStepsLayout}>
                    <ButtonGroup variant="text" color="default" aria-label="map and statistic">
                        <IconButton
                            size="medium"
                            className={classes.pageSwitchButton + (currentPage === 'map' ? ' active': '')}
                            onClick={() => {
                            mapDispatch({
                                type: 'setCurrentPage',
                                page: 'map'
                            })
                        }}>
                            <MapIcon style={{fontSize: '32px'}} />
                        </IconButton >
                        <IconButton
                            size="medium"
                            className={classes.pageSwitchButton + (currentPage === 'data' ? ' active': '')}
                            onClick={() => {
                            mapDispatch({
                                type: 'setCurrentPage',
                                page: 'data'
                            })
                        }}>
                            <ChartIcon style={{fontSize: '32px'}} />
                        </IconButton >
                    </ButtonGroup>
                </div>
                }

                {!showPageSwitcher && <div className={classes.default} />}

{/*
                <div className={classes.projectStepsLayout}>
                    <ProjectSteps />
                </div>
*/}

{/*                <IconButton edge="end" aria-label="menu">
                    <CloudQueue style={{'color': '#DEE6F1'}} />
                </IconButton>
                <IconButton edge="end" aria-label="menu">
                    <Apps style={{'color': '#DEE6F1'}}  />
                </IconButton>
                <IconButton edge="end">
                    <MoreVert style={{'color': '#DEE6F1'}}  />
                </IconButton>*/}


                <ProfileButton />
                <HelpButton />
                <LangButton />
            </Toolbar>

            { (mapState.isCalculating || mapState.isLoading) &&
            <LinearProgress />
            }
        </AppTopBar>
    );
}

export default TopBar;
