import {ArcLayer} from '@deck.gl/layers';
import _ from 'lodash'
import {v4} from 'uuid'
import {getDB, rowStructMap} from "../../../../../../db";
import {createActorLayerId} from '../../../../../../models/layer'
import {isDefined} from '../../../../../../helpers/predicate'


const agentGroups = {
    'kinder': {  //  < 17
        id: 1,
        radius: 1000,
        population: 9040,
    },
    'student': { // 17-30
        id: 2,
        radius: 1500,
        population: 7247,
    },
    'senior': {  // > 65
        id: 3,
        radius: 500,
        population: 15847,
    },
}
const sourcePlacesQueryRules = {
    1: {
        'building': {
            metric: {
                'building_function': '6ed6d8a9-615d-fb7e-c7c1-1ef6759e27da' // accomodation
            },
            tags: {
                'building': ['dormitory']
            }
        }
    },
}

const HOUSE_GROUP = 0

const targetPlacesQueryRules = {
    [HOUSE_GROUP]: {
        'building': {
            metric: {
                'src1_data_building_function_new_met': {
                    'building_function': '6ed6d8a9-615d-fb7e-c7c1-1ef6759e27da' // accomodation
                }
            },
            tags: {
                'building': ['dormitory']
            }
        }
    },
    1: { // supermarket, shop
        'building': {
            tags: {
                'shop': ['supermarket', 'mall'],
                'amenity': ['marketplace', 'supermarket'],
                'building': ['supermarket'],
            }
        },
    },
    2: { // clinic, doctor
        'building': {
            tags: {
                'amenity': ['doctor', 'doctors', 'dentist', 'clinic', 'hausarzt'],
                'healthcare': '*'
            }
        }
    },
    3: { // sports centre
        'building': {
            tags: {
                'leisure': ['sports_centre', 'sports_hall', 'playground', 'fitness_centre'],
                'sport': '*'
            }
        },
        'leisure': {
            tags: {
                'leisure': ['sports_centre', 'sports_hall', 'playground', 'fitness_centre', 'sport', 'swimming_pool'],
                'sport': '*'
            }
        }
    },
    4: { // park garden
        'leisure': {
            tags: {
                'leisure': ['park', 'garden', 'picnic_table', 'nature_reserve']
            },
            notTags: {
                'access': ['private', 'privat']
            }
        }
    },
    5: { // university
        'building': {
            tags: {
                'amenity': ['university'],
                'building': ['university']
            }
        }
    },
    6: { // mensa, fast food
        'building': {
            tags: {
                'amenity': ['fast_food']
            }
        }
    },
    7: { // cafe club bar, arts centre
        'building': {
            tags: {
                'amenity': ['cafe', 'restaurant', 'bar', 'pub', 'biergarten', 'art_centre']
            }
        }
    },
    8: { // library
        'building': {
            tags: {
                'amenity': ['library']
            }
        }
    },
    9: { // school
        'building': {
            tags: {
                'amenity': ['school'],
                'education': ['school']
            }
        },
    }
}
const links = [
    // agent group, target group, target weight, hours, move-out weight
    // HOUSE
    [1, 0, 0.99, [16, 7],       0.01], // kinder
    [2, 0, 0.99, [21, 7],       0.01], // student
    [3, 0, 0.99, [15, 5],       0.01], // senior

    [2, 0, 0.7, [15, 21],       0.01], // student

    // supermarket
    [1, 1, 0.2, [15, 18],      0.9],
    [2, 1, 0.8, [15, 21],      0.9],
    [3, 1, 0.9, [9,  15],      0.9],

    // clinic
    [1, 2, 0.1, [9, 12],       0.4],
    [2, 2, 0.3, [9, 12],       0.2],
    [3, 2, 0.8, [9, 15],       0.1],

    // sport
    [1, 3, 0.9, [15, 18],      0.5],
    [2, 3, 0.5, [15, 18],      0.1],
    [3, 3, 0.7, [6, 12],       0.1],

    // park
    [1, 4, 0.3, [15, 18],      0.4],
    [2, 4, 0.8, [12, 18],      0.3],
    [3, 4, 0.7, [9,  15],      0.1],

    // univer
    [1, 5, -0.1, [null, null], 1],
    [2, 5, 0.9,  [9,  18],     0.1],
    [3, 5, 0.1,  [12, 15],     0.1],

    // fast food
    [1, 6, -0.1, [null, null], 1],
    [2, 6, 0.9,  [12, 15],     0.5],
    [3, 6, 0.1,  [12, 15],     0.2],

    // cafe
    [1, 7, -0.1, [null, null], 1],
    [2, 7, 0.8,  [21,  0],     0.7],
    [3, 7, 0.5,  [12, 15],     0.2],

    // lib
    [1, 8, 0.1, [15, 18],      0.3],
    [2, 8, 0.9, [15, 18],      0.1],
    [3, 8, 0.3, [12, 15],      0.1],

    // school
    [1, 9, 0.9,  [9, 15],      0.01],
    [2, 9, -0.1, [null, null], 1],
    [3, 9, 0.2,  [9, 12],      0.01],
]


const isNotNullHours = (hours) => isDefined(hours) && hours.length >= 2 && isDefined(hours[0]) && isDefined(hours[1])
const getNormHour = (hour) => hour < 0 ? 24 + hour : hour % 24
const getNormFirstHour = (hour) => hour
const getNormSecondHour = (hours) => hours[1] > hours[0] ? hours[1] : hours[1] + 24
const isInHours = (hour, hours, delta = 0) => isNotNullHours(hours) && hour >= getNormFirstHour(hours[0] - delta) && hour < getNormSecondHour(hours) + delta

const filterPlacesByGroupAndHour = (group, hour) => {
    const targetGroups = links.reduce((acc, [linkAgentGroup, linkTargetGroup, weight, hours]) => (
        group === linkAgentGroup && isInHours(hour, hours, 1) ? acc.concat([linkTargetGroup]) : acc
    ), [])
    return Object.fromEntries(Object
        .entries(targetPlacesQueryRules)
        .filter(([group, rules]) => targetGroups.indexOf(parseInt(group)) > -1)
    )
}



const targetRuleToQueries = (
    groupNumber,
    groupObjects,
    columns = [
        'b.id', 'b.lng', 'b.lat',
        `${groupNumber} as group`,
    ],
    conditionRule = 'and'
) => Object.entries(groupObjects).map(([tableName, query]) => {

    const conditions = []

    // tags condition
    const tagsCond = Object.entries(query.tags).map(([tag, values]) => {
        if (values === '*') return `b.tags['${tag}'][1] is not null`;
        return `b.tags['${tag}'][1] in (${values.map(value => `'${value}'`).join(', ')})`
    })

    conditions.push(`(${tagsCond.join(' or ')})`)

    // not tags condition
    if (query.notTags) {
        const notTagsCond = Object.entries(query.notTags).map(([tag, values]) => {
            if (values === '*') return `b.tags['${tag}'][1] is null`;
            return `b.tags['${tag}'][1] not in (${values.map(value => `'${value}'`).join(', ')})`
        })
        conditions.push(`(${notTagsCond.join(' and ')})`)
    }

    if (query.metric) {

        const metricCond = Object.entries(query.metric).map(([metricTable, conditions]) => (
            `b.id in (
                    select ${tableName} 
                    from project.${metricTable} 
                    where ${Object.entries(conditions).map(([field, value]) => `${field} = '${value}'`).join(' and ')}
            )`))

        conditions.push(`(${metricCond.join(' or ')})`)
    }

    return `
        select
            ${columns.join(', ')}
        from project.${tableName} b
        where ${conditions.join(` ${conditionRule} `)} 
    `
})

const getPreviousSources = async (iteration) => {
    const DB = await getDB();
    const result = await DB.run(`
        select id, lng, lat, "group", "count", same, hour, agent_id
        from project.cell_state
        where iteration=${iteration} and count > 0
    `)
    return result.toArray().map(Object.fromEntries)
}


const DEGREE_TO_METER_K = 0.00001
const WEIGHT_LIMIT = 0.001
const randomChoice = (lst) => lst[Math.round(Math.random() * (lst.length - 1))]
const getDistance = (p0, p1) =>
    Math.sqrt(Math.pow(p0.lng - p1.lng, 2) + Math.pow(p0.lat - p1.lat, 2)) / DEGREE_TO_METER_K

const getWeight = (agentGroupId, targetGroupId) =>
    links.find(vector => vector[0] === agentGroupId && vector[1] === targetGroupId)[2]

// const getDistanceWeight = (radius, distance) => (radius-distance)/(radius+distance)
const getDistanceWeight = (radius, distance) => distance < radius ? 1 : radius / Math.pow(distance, 2)

var getMean = (lowerBound, upperBound) => (upperBound + lowerBound) / 2;
var getStdDeviation = (lowerBound, upperBound) => (upperBound - lowerBound) / 4;
const getNormalY = (x, mean, stdDev) => Math.exp((-0.5) * Math.pow((x - mean) / stdDev, 2));

const getHours = (agentGroupId, targetGroupId) =>
    links.find(vector => vector[0] === agentGroupId && vector[1] === targetGroupId)[3]

const getProbabilityToMoveOut = (agentGroupId, targetGroupId) =>
    links.find(vector => vector[0] === agentGroupId && vector[1] === targetGroupId)[4]

const getHourWeight = (hour, hours) => {
    // const dHour = hours[0] + (hours[1] - hours[0]) / 2

    if (!isDefined(hour) || !isNotNullHours(hours)) return 0
    if (!isInHours(hour, hours)) return 0

    const mean = getMean(hours[0], getNormSecondHour(hours))
    const stdDev = getStdDeviation(hours[0], getNormSecondHour(hours))
    return getNormalY(hour, mean, stdDev)
}

const targetChoice = (agentPlace, targets, theAgentGroup, hour) => {
    try {
        if (targets.length === 0) return null

        const currentPlaceHours = getHours(theAgentGroup.id, parseInt(agentPlace.group))
        const probabilityToMoveOut = getProbabilityToMoveOut(theAgentGroup.id, parseInt(agentPlace.group))
        if (isInHours(hour, currentPlaceHours) && Math.random() > probabilityToMoveOut) return null

        // console.log('H>',
        //     hour,
        //     getHours(theAgentGroup.id, parseInt(1)),
        //     getHourWeight(hour, getHours(theAgentGroup.id, parseInt(1)))
        // )

        const weightedTargets = targets.map(t => ({
            ...t,
            ww: getWeight(theAgentGroup.id, parseInt(t.group)),
            wd:  (agentPlace.agent_id === t.agent_id ? 1 : getDistanceWeight(theAgentGroup.radius, getDistance(agentPlace, t))),
            wh: (agentPlace.agent_id === t.agent_id ? 1 : getHourWeight(hour, getHours(theAgentGroup.id, parseInt(t.group)))),
            d: getDistance(agentPlace, t),
            w: getWeight(theAgentGroup.id, parseInt(t.group))
               * (agentPlace.agent_id === t.agent_id ? 1 : getDistanceWeight(theAgentGroup.radius, getDistance(agentPlace, t)))
               * (agentPlace.agent_id === t.agent_id ? 1 : getHourWeight(hour, getHours(theAgentGroup.id, parseInt(t.group))))

        }))
        // t.group > 0 ? ... : t.probability * getDistanceWeight(theAgentGroup.radius, getDistance(agentPlace, t))

        let weightSum = weightedTargets.reduce((s, v) => s + v.w, 0)


        if( Math.random() > 0.99) console.log('W>', {
            weightedTargets
        })

        if (weightSum < 0.01) return null

        const weightAvg = weightSum / weightedTargets.length
        const currentPlace = {
            ...agentPlace,
            w: getWeight(theAgentGroup.id, parseInt(agentPlace.group))
               * 1 // distance == 0
               * getHourWeight(hour, getHours(theAgentGroup.id, parseInt(agentPlace.group)))
               + 1 // tolerance to stay when go
               - weightAvg
        }

// console.log('weightAvg>', weightAvg)
// console.log('weightSum>', weightSum)
// console.log('currentPlace>', currentPlace.w)
        const targetsWithCurrentPlace = targets.concat([currentPlace])

        // recalc sum with current place
        weightSum += currentPlace.w

        const probabilities = weightedTargets.map(t => t.w / weightSum)
        const probabilityRanges = probabilities.reduce((ranges, v, i) => {
            var start = i > 0 ? ranges[i-1][1] : 0 - Number.EPSILON;
            ranges.push([start, v + start + Number.EPSILON]);
            return ranges;
        }, []);

        const randValue = Math.random()
        const index = probabilityRanges.findIndex((v, i) => randValue > v[0] && randValue <= v[1])
        return targetsWithCurrentPlace[index]
    } catch (e) {
        return null
    }
}

const getObjectAgentCount = (population, objectCount) => Math.round(2 * Math.random() * Math.ceil(population / objectCount))
const spreadFunction = (houses, population) => houses.map(getObjectAgentCount(population, houses.length))


async function prepareInitialState(agentGroupKey, group = 0, hour = 5) {
    const DB = await getDB();

    const agentGroup = agentGroups[agentGroupKey]

    await DB.createTable('cell_state', {
        fields: [
            {name: 'id', type: 'uuid not null', title: 'Object', is_key: true},
            {name: 'iteration', type: 'integer', title: 'Iteration', is_key: true},
            {name: 'hour', type: 'integer', title: 'Hour', is_key: true},
            {name: 'lng', type: 'float', title: 'Longitude', is_key: false},
            {name: 'lat', type: 'float', title: 'Latitude', is_key: false},
            {name: 'group', type: 'integer', title: 'Group', is_key: false},
            {name: 'count', type: 'integer', title: 'Count', is_key: false},
            {name: 'same', type: 'boolean', title: 'Same place', is_key: false},
            {name: 'agent_id', type: 'uuid', title: 'Agent', is_key: false},
        ],
        indexes: [
            {fields: ['id', 'iteration', 'hour']}
        ],
    }, 'CREATE OR REPLACE TABLE')

    await DB.createTable('agent_state', {
        fields: [
            {name: 'id', type: 'uuid not null', title: 'Object', is_key: true},
            {name: 'home_id', type: 'uuid not null', title: 'Home place', is_key: true},
            {name: 'home_lng', type: 'double', title: 'Home place longitude', is_key: false},
            {name: 'home_lat', type: 'double', title: 'Home place latitude', is_key: false},
            {name: 'group', type: 'integer', title: 'Group', is_key: false},
        ],
        indexes: [
            {fields: ['id', 'home_id']}
        ],
    }, 'CREATE OR REPLACE TABLE')

    await DB.createTable('agent_move', {
        fields: [
            // from, to, count, hour
            {name: 'from_id', type: 'uuid not null', title: 'From ID', is_key: false},
            {name: 'from_lng', type: 'double', title: 'From Longitude', is_key: false},
            {name: 'from_lat', type: 'double', title: 'From Latitude', is_key: false},
            {name: 'from_group', type: 'integer', title: 'From Group', is_key: false},

            {name: 'to_id', type: 'uuid not null', title: 'To ID', is_key: false},
            {name: 'to_lng', type: 'double', title: 'To Longitude', is_key: false},
            {name: 'to_lat', type: 'double', title: 'To Latitude', is_key: false},
            {name: 'to_group', type: 'integer', title: 'To Group', is_key: false},

            {name: 'iteration', type: 'integer', title: 'Iteration', is_key: true},
            {name: 'hour', type: 'integer', title: 'Hour', is_key: true},
            {name: 'count', type: 'integer', title: 'Count', is_key: false},
            {name: 'agent_id', type: 'uuid', title: 'Agent', is_key: false},
        ],
        indexes: [
            {fields: ['iteration']}
        ]
    }, 'CREATE OR REPLACE TABLE')

    const accomodationQuery = targetRuleToQueries(group, targetPlacesQueryRules[group], [`id`, 'lng', 'lat'], 'or').join(' union ')
    const accomodationObjects = (await DB.run(accomodationQuery)).toArray().map(Object.fromEntries)

    const agentState = []
    const cellState = []
    for (let i = 0; i < accomodationObjects.length; i++ ) {
        const { id: home_id, lng: home_lng, lat: home_lat } = accomodationObjects[i]
        const count = getObjectAgentCount(agentGroup.population, accomodationObjects.length)

        for (let i = 0; i < count; i++) {
            const newAgent = {
                id: v4(),
                home_id,
                home_lng,
                home_lat,
                group: agentGroup.id
            }

            const newCellState = {
                id: home_id,
                lng: home_lng,
                lat: home_lat,
                agent_id: newAgent.id,
                group,
                hour,
                iteration: 0,
                count: 1,
                same: false,
            }

            agentState.push(newAgent)
            cellState.push(newCellState)
        }
    }

    await DB.insertJSON('agent_state', agentState, {columns: {}})
    await DB.insertJSON('cell_state', cellState, {columns: {}})
}

export async function calculate(props) {

    const {agentGroup, endIteration, initialHour = 5} = props

    if (!isDefined(agentGroup) || !isDefined(endIteration)) return []

console.log('prepare>', {agentGroup, endIteration})

    const DB = await getDB();
    await prepareInitialState(agentGroup, HOUSE_GROUP, initialHour)

    const theAgentGroup = agentGroups[agentGroup]
    const agentGroupId = theAgentGroup.id
    const agentGroupRadius = theAgentGroup.radius

    const agentHouses = (await DB.run(`
                    select 
                        id as agent_id,
                        home_id as id, 
                        home_lng as lng, 
                        home_lat as lat, 
                        ${HOUSE_GROUP} as group
                    from project.agent_state
                `))
        .toArray()
        .map(Object.fromEntries)
        .reduce((houses, house) => ({...houses, [house.agent_id]: house}), {})

    try {
        for (let currentIteration = 1; currentIteration < endIteration; currentIteration++) {
            const currentHour = (initialHour + currentIteration) % 24
            const currentWeekDay = Math.floor((initialHour + currentIteration) / 24) % 7
            const previousIteration = currentIteration - 1

console.log('currentHour>', currentHour)
console.log('currentWeekDay>', currentWeekDay)

            const sourcePlaces = await getPreviousSources(previousIteration)
console.log('sourcePlaces>', sourcePlaces)

            const filteredTargetRules = filterPlacesByGroupAndHour(agentGroupId, currentHour)

            const targetPlacesQueries = Object.entries(filteredTargetRules).reduce((acc, [groupNumber, groupObjects]) => ([
                ...acc, ...targetRuleToQueries(groupNumber, groupObjects)
            ]), [])

            const isGetBackHome = isDefined(filteredTargetRules[HOUSE_GROUP])
            const targetPlaces = (await DB.run(targetPlacesQueries.join(' union ')))
                .toArray()
                .map(Object.fromEntries)

            const sourceTargetLinks = sourcePlaces.map((agentPlace, i) => {
                let availableTargets = targetPlaces.filter(targetPlace =>
                    // getDistance(agentPlace, targetPlace) < agentGroupRadius &&
                    targetPlace.group !== HOUSE_GROUP
                )

                if (isGetBackHome && isDefined(agentHouses[agentPlace.agent_id])) {
                    availableTargets.push(agentHouses[agentPlace.agent_id])
if (i === 3) console.log('getBackHome>', {currentHour, availableTargets})
                }

                return {
                    from: agentPlace,
                    to: targetChoice(agentPlace, availableTargets, theAgentGroup, currentHour),
                    count: agentPlace.count,
                    agent_id: agentPlace.agent_id
                }
            }) // .filter(place => place && !!place?.to)

            const newCellState = sourceTargetLinks.map(({from, to, count, agent_id}) => (
                !to
                    ? {...from, count, agent_id, hour: currentHour, iteration: currentIteration, same: true}
                    : {...to, count, agent_id, hour: currentHour, iteration: currentIteration, same: false}
            ))

            if (newCellState.length > 0) {
                await DB.insertJSON('cell_state', newCellState, {columns: {}})
            }

            const agentMove = sourceTargetLinks
                .filter(({to}) => !!to)
                .map(({
                    from,
                    to,
                    count,
                    agent_id
                }) => ({
                    from_id: from.id,
                    from_lng: from.lng,
                    from_lat: from.lat,
                    from_group: from.group,

                    to_id: to.id,
                    to_lng: to.lng,
                    to_lat: to.lat,
                    to_group: to.group,

                    count,
                    agent_id,
                    hour: currentHour,
                    iteration: currentIteration
                }))

            // для следующего кона надо записать все to с аггрегированным кол-вом,
            // и выбрать все те from, которые никуда не пошли -- их переносим на второй цикл

            if (agentMove.length > 0) {
                await DB.insertJSON('agent_move', agentMove, {columns: {}})
            }

        }
    } catch (e) {
        console.log('GEN Err>', e)
    }
}


async function getData(props) {

    const {hour} = props

    if (!isDefined(hour)) return []

    try {
        const DB = await getDB();

        return (await DB.run(`
            select
                from_id, from_lng, from_lat, from_group,  
                to_id, to_lng, to_lat, to_group,
                count,
                hour 
            from project.agent_move
            where hour=${getNormHour(hour)}
        `)).toArray().map(Object.fromEntries)

    } catch (e) {
        return []
    }
}


export default function createActorLayer(props) {
    return new ArcLayer({
        id: createActorLayerId, //userActivityLayerId
        data: getData(props),  // actorLayerFabric()
        // 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/bart-segments.json'
        pickable: true,
        getWidth: d => d.count,
        getSourcePosition: d => ([d.from_lng, d.from_lat]),
        getTargetPosition: d => ([d.to_lng, d.to_lat]),
        getSourceColor: d => ([0, 140, 0]),
        getTargetColor: d => ([140, 0, 0]),
        visible: true,
        opacity: props.opacity ? props.opacity : 1,
        transitions: {

        }
    })
}