import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {isFieldObject, isFieldTimestamp, isFieldUUID} from "../../../../../../../helpers/field";
import {makeStyles} from "@material-ui/core/styles";

import DictFilter from './dict-filter'
import TimestampFilter from './timestamp-filter.js'
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 16px'
    },

    pies: {},

    piesTitle: {},

    filterControl: {
        width: 280,
        marginRight: '16px',
    },

    filterSelector: {
        width: '100%'
    },

    filterSliderTitle: {
        fontSize: '12px',
        marginBottom: '15px'
    },

    filterSlider: {
        width: '100%',
        '& .MuiSlider-markLabel': {
            fontSize: '10px'
        }
    },

}))

const _createTuple = (title, value) => ({title, value})
export const aggregateFunctions = [
    _createTuple('Count', 'count'),
    _createTuple('Average', 'avg'),
    _createTuple('Minimum', 'min'),
    _createTuple('Maximum','max'),
    _createTuple('Sum', 'sum'),
    // _createTuple('Product', 'product'),
]

export default function MetricAggregate(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        value,
        onChange,
        except,
    } = props

    return (
        <section>
            <h3 className={classes.filteringByTitle}>Aggregate function</h3>
            <div>
                <FormControl className={classes.filterControl}>
                    <Select
                        labelId={`common-stat-agg-label`}
                        id={`common-stat-agg-selector`}
                        value={value ?? 'count'}
                        onChange={(e) => {
                            if (onChange) onChange(e.target.value)
                        }}
                        className={classes.filterSelector}
                    >
                        {aggregateFunctions.filter(item => !except || except.indexOf(item.value) === -1).map(item => (
                            <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

        </section>
    )

}
