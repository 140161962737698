import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        // fill: 'currentColor'
        stroke: 'currentColor', // #4E4F54
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.3rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none'
    }
}));

const ArcIcon = (props) => {
    const classes = useStyles();

    return(
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
             {...props}
            className={classes.root}
        >
                <path d="M0.5 10C0.5 10 0.630478 6.36032 3 5.32734C5.36952 4.29437 6 7.12265 6 7.12265" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M0.5 3.5C0.5 3.5 1.32371 0.342794 3.5 1.12265C5.67629 1.90251 6 7.12265 6 7.12265" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6 7.12264C6 7.12264 6.5 3.7453 8.5 2.62265C10.5 1.5 10.5 4.5 10.5 4.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6 7.27158C6 7.27158 7.30963 4.45047 8.90482 5.47523C10.5 6.5 10.5 10 10.5 10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};

export default ArcIcon;