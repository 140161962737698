import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    TextField,
    FormControl,
    InputLabel,
    Input
} from '@material-ui/core';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    formRow: {
        marginBottom: theme.spacing(2)
    },

    titleField: {
        maxWidth: 640,
        width: '50vw',
        // width: '50%',
        [theme.breakpoints.down(940)]: {
            width: '100%',
            maxWidth: 'none'
        },
    },

    textField: {
        maxWidth: 640,
        width: '50vw',
        [theme.breakpoints.down(940)]: {
            width: '100%',
            maxWidth: 'none'
        },
    }
}));

export default function MetaInfoStep(props) {
    const classes = useStyles();
    const { projectData, onChange } = props
    const { project: { name, about } } = projectData;
    const { t } = useTranslation();

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div className={classes.formRow}>
                <TextField
                    id="project-name"
                    value={name}
                    label={t('project_new.meta_info_step.project_name')}
                    variant='filled'
                    onChange={(e) => {
                        onChange({project: {title: e.target.value}})
                    }}
                    className={classes.titleField}
                />
            </div>
            <div className={classes.formRow}>
                <TextField
                    id="project-about"
                    value={about}
                    label={t('project_new.meta_info_step.about_project')}
                    multiline
                    rows={4}
                    variant='filled'
                    onChange={(e) => { onChange({project: {about: e.target.value}}) }}
                    className={classes.textField}
                />
            </div>
        </form>
    );
}