import React, {useEffect, useReducer, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    TableSortLabel,
    Toolbar,
    Typography,
    Checkbox,
    IconButton,
    Tooltip,
    FormControlLabel,
    Switch,
} from '@material-ui/core'

import {
    Delete as DeleteIcon,
    Filter as FilterIcon
} from '@material-ui/icons'

import {isFieldFilterable, isFieldTimestamp, normalizeDate} from '../../../../../../helpers/field'
import {DuckDBClient, getDB} from '../../../../../../db'
import {getAnyValue} from '../../../../../../helpers/repr'
import {useTranslation} from "react-i18next";
import {isScalarType} from "graphql";
import ScrollBar from "react-perfect-scrollbar";
import {initialMapState, mapStateReducer} from "../../../reducer";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    tableContainer: {
        overflow: 'auto'
    },
    tablePagination: {
        borderTop: '1px solid #e0e0e0'
    },
    tableHeaderLabel: {
        fontWeight: 'bold'
    },

    visuallyHidden: {}

}))


function EnhancedTableHead(props) {
    const classes = useStyles();

    const {
        columns,
        onSelectAllClick, order, orderBy,
        numSelected, rowCount, onRequestSort
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{'aria-label': 'select all desserts'}}
                    />
                </TableCell>
                {columns.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.tableHeaderLabel}
                        >
                            {headCell.label}
{/*
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
*/}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    columns: PropTypes.array.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const pageSizes = [
    25, 50, 100, 500
]

export default function MetricTable(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {metric} = props;

    const [mapState, mapDispatch] = useReducer(mapStateReducer, initialMapState)

    const [data, setData] = useState([])
    const [dataCount, setDataCount] = useState(0)
    const [columns, setColumns] = useState([])
    const [orderBy, setOrderBy] = useState(null)
    const [order, setOrder] = useState('asc')
    const [pageSize, setPageSize] = useState(pageSizes[0])
    const [page, setPage] = useState(0)

    const getTableSize = async (metric) => {
        const db = await getDB()
        const table = await db.table(metric.cube.name)
        return await table.count()
    }

    const loadTable = async (metric, page, pageSize, order, orderBy) => {
        const db = await getDB()
        const objectKey = metric?.object?.key

        const metricDicts = metric.cube.dicts.reduce((acc, item) => ({
            ...acc,
            [item.field]: item.values.reduce((valAcc, valItem) => ({...valAcc, [valItem.id]: valItem.title}), {})
        }), {})

        let dictJoin = ''
        let orderByField = null
        if(!!orderBy) {
            if (orderBy === objectKey) {
                orderByField = "coalesce(t.meta.title, '')"
            }else if (metricDicts[orderBy] && Object.keys(metricDicts[orderBy]).length > 0) {
                dictJoin = `left join (
                select id, title
                from (values ${Object.entries(metricDicts[orderBy]).map(([id, title]) => (
                    `('${id}', '${title.toString().replaceAll("'", '')}')`
                )).join(', ')}) dict(id, title)) as dict on dict.id=${orderBy}`
                orderByField = "coalesce(dict.title, '')"
            } else {
                orderByField = orderBy
            }
        }

        const query = `
            select
                c.*,
                t.meta 
            from ${DuckDBClient.project_schema}."${metric.cube.name}" c
            left join ${DuckDBClient.project_schema}."${objectKey}" as t on t.id = c.${objectKey}
            ${dictJoin}
            ${!!orderBy ? 'order by ' + orderByField + ' ' + order : ''}      
            limit ${pageSize}
            offset ${page * pageSize} 
        `

        const result = await db.run(query)
        return result.toArray().map(Object.fromEntries)
    }
    const getFieldValue = (row, field, objectKey, metricDicts) => {
        try {
            if (field.name === objectKey) {
                return row.meta?.title ?? t('project.data.chart.table.untitled')
            }

            if (isFieldTimestamp(field)) {
                return normalizeDate(row[field.name])
            }

            if (metricDicts[field.name]) {
                return metricDicts[field.name][row[field.name]]
            }

            return getAnyValue(row[field.name], 4)
        } catch (e) {
            return ''
        }
    }

    const prepareRow = (row, fields, objectKey, metricDicts) => fields.reduce((acc, fld) => {
        const value = getFieldValue(row, fld, objectKey, metricDicts);
        return {...acc, [fld.name]: value}
    }, {id: row[objectKey]})

    const setupData = (metric, page, pageSize, order, orderBy) => {
        const objectKey = metric?.object?.key
        const fields = metric.cube.struct.fields
        const metricDicts = metric.cube.dicts.reduce((acc, item) => ({
            ...acc,
            [item.field]: item.values.reduce((valAcc, valItem) => ({...valAcc, [valItem.id]: valItem.title}), {})
        }), {})

        mapDispatch({
            type: 'setCalculating',
            isCalculating: true
        })

        loadTable(metric, page, pageSize, order, orderBy).then((tableData) => {
            setData(tableData.map(row => prepareRow(row, fields, objectKey, metricDicts)))

            mapDispatch({
                type: 'setCalculating',
                isCalculating: false
            })
        }).catch((e) => {
            mapDispatch({
                type: 'setCalculating',
                isCalculating: false
            })

            console.log('E_t', e)
        })
    }

    useEffect(() => {
        if (!metric) return
        setupData(metric, page, pageSize, order, orderBy)
    }, [page, pageSize, order, orderBy])

    useEffect(() => {

        if (!metric) return

        getTableSize(metric).then(count => {
            setDataCount(count)
        })

        const columnFields = metric.cube.struct.fields.filter(item => isFieldFilterable(item))
        const tableWidth = window.innerWidth
        setColumns(columnFields.map(field => ({
            id: field.name,
            label: field.title,
            field: field.name,
            numeric: isScalarType(field),
            editable: isScalarType(field),
            width: Math.max(120, Math.floor(tableWidth / columnFields.length) - 50)
        })))

        setPage(0)
        setupData(metric, 0, pageSize, order, orderBy)
        setOrderBy(null)
        setOrder('asc')
    }, [metric])

    const makeOrdering = (initialRows, sortColumn, sortDirection) => rows => {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };

    const onHeaderDrop = (source, target) => {
        const columnSourceIndex = columns.findIndex(i => i.key === source);
        const columnTargetIndex = columns.findIndex(i => i.key === target);
        const newColumns = columns.concat([])
        newColumns.splice(columnTargetIndex, 0, newColumns.splice(columnSourceIndex, 1)[0])

        setColumns([]);
        setColumns(newColumns);
    };

    const tableHeight = window.innerHeight - 245

    return (
        <div className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <ScrollBar>
                    <section style={{height: tableHeight}}>
                        <Table
                            size={'medium'}
                            // className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                columns={columns}
                                classes={classes}
                                numSelected={0}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={() => {
                                }}
                                onRequestSort={(e, field) => {
                                    if (orderBy !== field) {
                                        setOrderBy(field)
                                        setOrder('asc')
                                    } else {
                                        setOrder(order === 'asc' ? 'desc': 'asc')
                                    }
                                }}
                                rowCount={dataCount}
                            />
                            <TableBody>
                                {data.map((row, rowIndex) => {
                                    const isItemSelected = false;
                                    const labelId = `enhanced-table-checkbox-${rowIndex}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => {}}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={false}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                />
                                            </TableCell>
                                            {columns.map((col, colIndex) => (
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    padding="none"
                                                    align={col.numeric ? 'right' : 'left'}>{row[col.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </section>
                </ScrollBar>
            </TableContainer>
            <TablePagination
                className={classes.tablePagination}
                rowsPerPageOptions={pageSizes}
                component="div"
                count={dataCount}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={(e, page) => {
                    setPage(page)
                }}
                onChangeRowsPerPage={(e) => {
                    setPageSize(parseInt(e.target.value))
                    setPage(0)
                }}
            />
        </div>
    );
}