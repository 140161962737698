import React, {useContext, useEffect, useState} from "react";
import {
    Paper,
    FormControlLabel,
    Button,
    Switch, FormControl, Select, MenuItem, InputLabel,
    Slider,
} from "@material-ui/core";

import {makeStyles} from '@material-ui/core/styles';

import {
    MapStateContext,
} from '../../../reducer'
import {useTranslation} from "react-i18next";
import {Restore} from "@material-ui/icons";
import {toDeckLayerOption} from "../layer-fabric";
import OpacitySelector from "./widgets/opacity-selector";
import {calculate} from './actor-layer'


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: 280,
        background: '#F5FAFF',
        borderRadius: 0,
        paddingBottom: 10,
        marginBottom: 10
    },
    title: {
        boxSizing: 'border-box',
        padding: '10px',
        margin: '0',
        fontWeight: 'normal',
        fontSize: '12pt'
    },
    displayModeControl: {
        background: '#F5FAFF'

    },
    colorBaseControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    colorBaseGradControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    colorBaseGradNumberControl: {
        margin: theme.spacing(1),
        minWidth: 95,
    },
    oneColorControl: {
        margin: theme.spacing(1)
    },
    lineWidthControl: {
        margin: theme.spacing(1)
    },
    opacityControl: {
        margin: theme.spacing(1)
    },
    gradientItem: {
        width: '100%',
        height: '40px',
    },
    layerControls: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    layerPublish: {
        marginTop: 4,
        marginLeft: 28
    },
    layerOrderingControls: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(1)
    },
    enabledControl: {

    },
    formControls: {
        margin: '10px'
    },
    formControl: {
        display: 'block',
        width: '100%'
    },
    formButtonControl: {
        marginTop: '10px',
        textAlign: 'center'
    },
    selectMetric: {
        width: '100%',
        display: 'block'
    },
    buttonEmulate: {
        width: '100%'
    },
    hourRulet: {
        margin: '3rem 0 0 0'
    },
}));

const INITIAL_HOUR = 5
const END_ITERATION = 24

export default function LayerActorsModel(props) {
    const classes = useStyles();
    const {mapState, mapDispatch} = useContext(MapStateContext)
    const { isCalculating } = mapState
    const {t} = useTranslation();

    const initialHour = INITIAL_HOUR
    const endIteration = END_ITERATION

    const [agentGroup, setAgentGroup] = useState('student')
    const [hour, setHour] = useState(initialHour + 1)
    const [isReady, setReady] = useState(false)


    const {
        id,
        isEnabled,
        opacity: layerOpacity
    } = props.layer

    const handleEnable = (newIsEnabled) => {
        mapDispatch({
            type: 'setLayerIsEnabled',
            layerId: id,
            isEnabled: newIsEnabled
        })
    }

    const setOption = (option, withDeck = true) => {
        const layerOption = withDeck ? Object.entries(option).reduce((opts, [opt, val]) => ({
            ...opts,
            ...toDeckLayerOption(id, opt, val)
        }), {}) : {}

        mapDispatch({
            type: 'setLayerOption',
            layerId: id,
            layerOption,
            option
        })
    }

    const calcActorLayer = async () => {
        mapDispatch({type: 'setCalculating', isCalculating: true})
        setReady(false)

        await calculate({
            initialHour,
            endIteration,
            agentGroup,
        })

        mapDispatch({type: 'setCalculating', isCalculating: false})

        mapDispatch({
            type: 'recalcActorsModel',
            layer: {...props.layer, agentGroup, hour}
        }, false)

        setReady(true)
    }

    const renderActorLayer = (hour) => {
        setHour(hour)
        mapDispatch({
            type: 'recalcActorsModel',
            layer: {...props.layer, agentGroup, hour}
        }, false)
    }


    const agentGroups = ['student', 'kinder', 'senior']


    return (
        <Paper className={classes.root}>
            <h4 className={classes.title}>{props.title}</h4>

            <div className={classes.layerControls}>
                <FormControlLabel
                    control={<Switch
                        size="small"
                        color="secondary"
                        checked={isEnabled}
                        onChange={() => handleEnable(!isEnabled)}
                    />}
                    label={t('project.leftbar.panel2.layer_is_enabled')}
                    className={classes.enabledControl}
                />
            </div>

            {isEnabled &&
            <div className={classes.formControls}>

                <FormControl className={classes.formControl}>
                    {/*<InputLabel>Agent group:</InputLabel>*/}
                    <Select
                        value={agentGroup}
                        onChange={(e) => {setAgentGroup(e.target.value)}}
                        className={classes.selectMetric}
                    >
                        {agentGroups.map(grp => (
                            <MenuItem value={grp}>{grp}</MenuItem>
                        ))}
                    </Select>
                </FormControl>


                <FormControl className={classes.formButtonControl}>
                    <div>Before using, just...</div>
                    <Button
                        onClick={calcActorLayer}
                        variant={"contained"}
                        className={classes.buttonEmulate}
                        disabled={isCalculating}
                    >
                        {isCalculating ? 'Calculating...' : 'Run model'}
                    </Button>
                </FormControl>

                <FormControl className={classes.formControl}>
                    <InputLabel>Hour:</InputLabel>
                    <Slider
                        value={hour}
                        onChange={(e, value) => {setHour(parseInt(value))}}
                        onChangeCommitted={(e, value) => {renderActorLayer(parseInt(value))}}
                        defaultValue={30}
                        getAriaValueText={(value) => `${value} hr`}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={initialHour + 1}
                        max={endIteration}
                        className={classes.hourRulet}
                        disabled={!isReady}
                    />
                </FormControl>

                <OpacitySelector
                    title={t('project.leftbar.panel2.opacity')}
                    value={layerOpacity}
                    onChange={(value) => {
                        setOption({
                            opacity: value
                        })
                    }}
                    disabled={!isReady}
                />


            </div>
            }

        </Paper>
    )
}