import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {isFieldObject, isFieldTimestamp, isFieldUUID} from "../../../../../../../helpers/field";
import {makeStyles} from "@material-ui/core/styles";

import DictFilter from './dict-filter'
import TimestampFilter from './timestamp-filter.js'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 16px'
    },

    pies: {},

    piesTitle: {},

    filterControl: {
        width: 280,
        marginRight: '16px',
    },

    filterSelector: {
        width: '100%'
    },

    filterSliderTitle: {
        fontSize: '12px',
        marginBottom: '15px'
    },

    filterSlider: {
        width: '100%',
        '& .MuiSlider-markLabel': {
            fontSize: '10px'
        }
    },

}))


export default function MetricFilters(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        metric,
        field,
        onChange,
        hideTimeSeries
    } = props

    const metricDicts = metric.cube.dicts.reduce((acc, item) => ({
        ...acc,
        [item.field]: item.values.reduce((valAcc, valItem) => ({...valAcc, [valItem.id]: valItem.title}), {})
    }), {})

    const [filterFields, setFilterFields] = useState([])
    const [filters, setFilters] = useState({})

    useEffect(() => {
        if (!metric) return

        setFilters({})
        setFilterFields(metric.cube.struct.fields
            .filter(item => (isFieldUUID(item) || (isFieldTimestamp(item) && !hideTimeSeries)) && !isFieldObject(item))
            .filter(item => (!field || item.name !== field.name))
            .filter(item => (!!metricDicts[item.name] || !!metric.cube.struct.timeseries))) // !!!
    }, [metric])

    useEffect(() => {
        if (onChange) onChange(filters)
    }, [filters])

    if (filterFields.length === 0) return null;

    return (
        <section>
            <h3 className={classes.filteringByTitle}>Filtering by</h3>
            <div>
                {filterFields.map((filterField) => {
                    if (isFieldTimestamp(filterField)) {
                        return <TimestampFilter filterField={filterField}
                                                value={filters[filterField.name] ?? null}
                                                timeseries={metric.cube.struct.timeseries}
                                                onChange={(value) => {
                                                    setFilters(existFilters => (value ? {
                                                        ...existFilters,
                                                        [filterField.name]: value
                                                    } : Object.fromEntries(Object.entries(existFilters).filter(([name, _]) => name !== filterField.name))))

                                                    if (onChange) onChange(filters)
                                                }}/>
                    }

                    return (
                        <DictFilter filterField={filterField}
                                    filterDict={metricDicts[filterField.name]}
                                    value={filters[filterField.name] ?? null}
                                    onChange={(value) => {
                                        setFilters(existFilters => (value ? {
                                            ...existFilters,
                                            [filterField.name]: value
                                        } : Object.fromEntries(Object.entries(existFilters).filter(([name, _]) => name !== filterField.name))))
                                    }}/>
                    )
                })}
            </div>

        </section>
    )

}
