import React, {useState, useEffect, useReducer, useContext} from "react";
import {
    Avatar,
    Button,
    IconButton,
    Collapse,
    List,
    ListSubheader,
    ListItemText,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction
} from "@material-ui/core";
import {
    ExpandMore,
    ExpandLess,
    Delete
} from '@material-ui/icons';
import {useQuery} from "@apollo/client";
import {makeStyles, styled} from '@material-ui/core/styles';


import {getStructure} from '../../../../actions/app/rest/dataset'
import {GetObjectTypes, GetDemoObjectTypes} from "../../../../actions/app/gql/data";
import {selectMetricsAction, deleteMetricAction, deleteObjectTypeAction} from '../../../../actions/app/data'
import apolloClient from "../../../../clients/apolloClient";
import {getAlgorithm} from '../../../../alg/index'
import {operandsToAlgorithmParams} from "../../../../helpers/alg";
import {MapStateContext, DataStateContext} from "../reducer";
import {appConfig} from '../../../../config'

import CheckTreeDialog from './map/data/checktreedialog'
import DataViewDialog from './map/data/dataviewdialog'
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        // padding: theme.spacing(0.5),
        background: 'none',
        padding: 0,
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
        maxWidth: 130,
        textOverflow: 'ellipsis'
    },
    tree: {
        minHeight: 300,
        maxHeight: '80vh',
        flexGrow: 1,
        minWidth: 300,
        maxWidth: '80vw',
    },
    controls: {
        marginTop: theme.spacing(1),
        textAlign: 'center'
    },
    dialog: {
        transition: 'all 0.3s easeInOutSine'
    },

    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    metricTreeItem: {
        label: {
            paddingLeft: 8
        }
    },

    list: {},

    listHeader: {
        fontSize: '1rem',
        fontWeight: 'bold',
        background: theme.color.darkGray
    },

    listDatasetItem: {
        borderBottom: '1px solid rgba(0,0,0,0.12)'
    },

    listItemText: {
        '& span': {
            fontSize: 12
        }
    },
}));


const ObjectTypeSelector = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    const {onModify} = props
    const [availableObjectTypes, setAvailableObjectTypes] = useState([])
    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {dataState, dataDispatch} = useContext(DataStateContext)
    const {
        metric: metrics,
        cube: cubes,
        project
    } = dataState;
    const {
        layers: existLayers
    } = mapState;

    const [isDialogOpened, setDialogOpened] = useState(false)

    const currentQuery = appConfig.isDemo ? GetDemoObjectTypes : GetObjectTypes;
    const {data: objectTypes, loading: otLoading, error: otError} = useQuery(currentQuery, {
        client: apolloClient,
        variables: {
            project_id: project?.id
        },
    });

    useEffect(() => {
        getStructure(project?.id).then(result => {
            const availObjectTypes = result.data.objectTypes.map(ot => {
                return {
                    ...ot,
                    children: ot.children.map(otCh => {
                        return {
                            ...otCh,
                            datasets: Object.values(otCh.metrics_link.reduce((datasets, link) => {
                                return {
                                    ...datasets,
                                    [link.dataset.id]: {
                                        ...link.dataset,
                                        metrics: datasets[link.dataset.id]
                                            ? datasets[link.dataset.id].metrics.concat([link.metric])
                                            : [link.metric]
                                    }
                                }
                            }, {}))
                        }
                    })
                }
            })

            setAvailableObjectTypes(availObjectTypes)
        }).catch(() => {

        })
    }, [project])

    const selectedDataTree = Object.values(metrics).reduce((data, item) => {
        const newData = {...data}
        const {litera, color, object, dataset, metric, lineage} = item;

        if (!(object.id in newData)) {
            newData[object.id] = {
                ...object,
                datasets: {}
            }
        }

        if (dataset && metric) {
            if (!(dataset.id in newData[object.id].datasets)) {
                newData[object.id].datasets[dataset.id] = {
                    ...dataset,
                    metrics: {}
                }
            }

            newData[object.id].datasets[dataset.id].metrics[metric.id] = {
                ...metric,
                lineage,
                color,
                litera
            };
        }

        return newData
    }, {})

    // useEffect(() => {
    //     if (objectTypes) {
    //
    //         const availObjectTypes = objectTypes.objectTypes.map(ot => {
    //             return {
    //                 ...ot,
    //                 children: ot.children.map(otCh => {
    //                     return {
    //                         ...otCh,
    //                         datasets: Object.values(otCh.metrics_link.reduce((datasets, link) => {
    //                             return {
    //                                 ...datasets,
    //                                 [link.dataset.id]: {
    //                                     ...link.dataset,
    //                                     metrics: datasets[link.dataset.id]
    //                                         ? datasets[link.dataset.id].metrics.concat([link.metric])
    //                                         : [link.metric]
    //                                 }
    //                             }
    //                         }, {}))
    //                     }
    //                 })
    //             }
    //         })
    //
    //         setAvailableObjectTypes(availObjectTypes)
    //     }
    // }, [objectTypes])

    const handleMetricDelete = (metricToDelete) => () => {
        deleteMetricAction(
            metricToDelete.id,
            {
                mapContext: [mapState, mapDispatch],
                dataContext: [dataState, dataDispatch],
            }
        )
    };

    const handleObjectDelete = (objectType) => () => {
        deleteObjectTypeAction(
            objectType.id,
            {
                mapContext: [mapState, mapDispatch],
                dataContext: [dataState, dataDispatch],
            }
        )
    }

    const handleObjectTypeDialog = () => {
        setDialogOpened(true)
    }

    const handleDialogOk = (metrics) => {
        selectMetricsAction({
            metrics,
            states: {
                mapContext: [mapState, mapDispatch],
                dataContext: [dataState, dataDispatch],
            },
            onBeforeLoading: (objectType) => {
                enqueueSnackbar(`Loading layer: ${objectType?.meta?.title}...`, {
                    autoHideDuration: 5000,
                    anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    variant: 'info'
                })
            },
            onAfterLoading: (objectType) => {
                enqueueSnackbar(`Layer loaded: ${objectType?.meta?.title}`, {
                    autoHideDuration: 3000,
                    anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    variant: 'info'
                })
            },
            onBeforeLoadingCube: (metric) => {
                enqueueSnackbar(`Loading metric: ${metric?.meta?.title}...`, {
                    autoHideDuration: 5000,
                    anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    variant: 'info'
                })
            },
            onAfterLoadingCube: (metric) => {
                enqueueSnackbar(`Metric loaded: ${metric?.meta?.title}`, {
                    autoHideDuration: 3000,
                    anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    variant: 'info'
                })
            },
        })
        setDialogOpened(false)
    }

    const handleDialogClose = () => {
        setDialogOpened(false)
    }

    const DataBrowserComponent = window.innerWidth < 768 ? CheckTreeDialog : DataViewDialog

    const [openedDatasets, setOpenedDatasets] = React.useState([]);
    const [openedMetrics, setOpenedMetrics] = React.useState([]);

    window.sm = metrics
    window.sdt = selectedDataTree

    const handleModifyAlgorithm = ({metric, lineage}) => {
        if (onModify) onModify()

        mapDispatch({
            type: 'setAlgorithm',
            algorithm: lineage.algorithm,
            title: metric.meta.title,
            params: {
                ...lineage.params,
                operands: operandsToAlgorithmParams(lineage.operands),
            },
            metricId: metric.id,
            objectType: lineage.object_type_id
        })
    }

    return (
        <>
            {/*
            <Paper component="ul" className={classes.root} elevation={0}>
                {selectedMetrics._map((data) => {
                    let icon;

                    return (
                        <li key={data.id}>
                            <Chip
                                icon={icon}
                                label={data.title}
                                onDelete={handleDelete(data)}
                                className={classes.chip}
                            />
                        </li>
                    );
                })}
            </Paper>
*/}
            {
                Object.values(selectedDataTree).map((item, i) => {
                    return (
                        <List
                            key={i}
                            component='nav'
                            subheader={
                                <ListSubheader component='div' className={classes.listHeader}>
                                    {item.meta.title}
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" size="small" onClick={handleObjectDelete(item)}>
                                            <Delete fontSize="small"/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListSubheader>
                            }
                            className={classes.list}
                        >
                            {
                                Object.values(item.datasets).map((datasetItem, j) => {
                                    const isOpened = openedDatasets.indexOf(datasetItem.id) > -1
                                    return (
                                        <>
                                            <ListItem button onClick={() => setOpenedDatasets(oldState => {
                                                return isOpened
                                                    ? oldState.filter(dsItem => dsItem !== datasetItem.id)
                                                    : oldState.concat(datasetItem.id)
                                            })} className={classes.listDatasetItem} key={`listitem${j}`}>
                                                {/*<Badge badgeContent={Object.values(datasetItem.metrics).length}></Badge>*/}
                                                <ListItemText>{datasetItem.meta.title}</ListItemText>
                                                {isOpened ? <ExpandLess/> : <ExpandMore/>}
                                            </ListItem>
                                            <Collapse in={isOpened} timeout="auto" key={`collapse${j}`} addEndListener={() => {}}>
                                                <List component='div' disablePadding>
                                                    {
                                                        Object.values(datasetItem.metrics).map((metricItem, k) => {
                                                            const hasLineage = metricItem?.lineage?.length
                                                            const lastLineage = hasLineage
                                                                ? metricItem.lineage.findLast(x => x)
                                                                : undefined
                                                            const algorithm = hasLineage && lastLineage
                                                                ? getAlgorithm(lastLineage.algorithm)
                                                                : null

                                                            if (hasLineage && algorithm) {
                                                                const isMetricOpened = openedMetrics.indexOf(metricItem.id) > -1
                                                                return (
                                                                    <>
                                                                        <ListItem button onClick={() => setOpenedMetrics(oldState => {
                                                                            return isMetricOpened
                                                                                ? oldState.filter(mxItem => mxItem !== metricItem.id)
                                                                                : oldState.concat([metricItem.id])
                                                                        })} className={classes.listDatasetItem} key={`listitem${k}`}>
                                                                            <ListItemIcon>
                                                                                <Avatar style={{background: metricItem.color}}
                                                                                        className={classes.litera}>
                                                                                    {metricItem.litera}
                                                                                </Avatar>
                                                                            </ListItemIcon>
                                                                            <ListItemText>{metricItem?.meta?.title}</ListItemText>
                                                                            {/*{isMetricOpened ? <ExpandLess/> : <ExpandMore/>}*/}
                                                                        </ListItem>
                                                                        <algorithm.form
                                                                            metrics={metrics}
                                                                            cubes={cubes}
                                                                            metric={metricItem}
                                                                            lineage={lastLineage}
                                                                            onModify={handleModifyAlgorithm}
                                                                            onDelete={handleMetricDelete(metricItem)}
                                                                        />
                                                                        {/*<Collapse in={isMetricOpened} timeout="auto" key={`collapse${k}`} addEndListener={() => {}}>*/}
                                                                        {/*</Collapse>*/}
                                                                    </>
                                                                )
                                                            }

                                                            return (
                                                                <ListItem button key={k}>
                                                                    <ListItemIcon>
                                                                        <Avatar style={{background: metricItem.color}}
                                                                                className={classes.litera}>
                                                                            {metricItem.litera}
                                                                        </Avatar>
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        className={classes.listItemText}>{metricItem.meta.title}</ListItemText>
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton edge="end" aria-label="delete"
                                                                                    size="small">
                                                                            <Delete
                                                                                onClick={handleMetricDelete(metricItem)}
                                                                                fontSize="small"/>
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </Collapse>
                                        </>
                                    )
                                })
                            }
                        </List>
                    );
                })
            }


            <div className={classes.controls}>
                <Button variant="contained" onClick={handleObjectTypeDialog}>{t('project.leftbar.panel1.add_data')}</Button>
            </div>

            <DataBrowserComponent key={'DataBrowserComponent'}
                                  data={availableObjectTypes}
                                  selected={metrics}
                                  isOpened={isDialogOpened}
                                  onOk={handleDialogOk}
                                  onClose={handleDialogClose}/>

        </>
    );
}

export default ObjectTypeSelector;