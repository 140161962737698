import React, {useContext, useEffect, useState, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Moment from 'moment'
import {
    isFieldObject,
    isFieldUUID,
    isFieldScalar,
    isFieldTimestamp,
    isFieldMultipleValue
} from '../../../../../../helpers/field'
import {isManager} from '../../../../../../helpers/user'

import {
    Modal,
    Backdrop,
    TextField,
    Fade,
    Card,

    Link,
    Badge,

    Tabs,
    Tab,

    Paper,

    FormControlLabel,

    CardActionArea,
    CardMedia,
    CardHeader,
    CardActions,
    CardContent,
    Button,
    Typography,
    Avatar,
    IconButton,

    Accordion,
    AccordionSummary,
    AccordionDetails,

    InputAdornment,

    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Collapse,

    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    Grid,

    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,

    Divider,
    Tooltip,

    CircularProgress,

} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
    ExpandLess, ExpandMore,
    MoreVert as MoreVertIcon,
    FavoriteBorder as FavoriteBorderIcon,
    Favorite as FavoriteIcon,

    ThumbUpAlt,
    ThumbDownAlt,

    ThumbUpAltOutlined,
    ThumbDownAltOutlined, Close as CloseIcon,
} from '@material-ui/icons'
import Scrollbar from "react-perfect-scrollbar";
import {getAlgorithm} from '../../../../../../alg'
import {DataStateContext, MapStateContext} from "../../../reducer";
import {getDB, PROJECT_SCHEMA} from "../../../../../../db";
import {useMutation, useQuery} from "@apollo/client";
import {
    GetComments, CreateComment, PublishComment, UnpublishComment, LikeComment, UnlikeComment, DeleteComment,
    ObjectRanks, RankObject, UnrankObject
} from "../../../../../../actions/app/gql/activity";
import apolloClient from "../../../../../../clients/apolloClient";
import {useTranslation} from "react-i18next";

import TransferSelect from '../../../../../../components/transferselect'
import {appConfig} from "../../../../../../config";


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 300,
        // maxWidth: 750,
        width: '70vw',
        background: theme.color.gray,
        [theme.breakpoints.down('md')]: {
            width: '80vw',
            // height: '80vh'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            // height: '100vh'
        }
        // maxHeight: '80vh',
    },

    tabContent: {
        display: 'none',
        height: '40vh'
    },

    title: {
        margin: 0,
        padding: 0
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    field: {
        width: '100%'
    },

    mainFields: {
        marginBottom: theme.spacing(2)
    },

    metrics: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        background: theme.color.gray
    },

    metricTitleRow: {
        '& .MuiFormControlLabel-label': {
            marginLeft: 8
        }
    },

    listMetricItem: {
        borderBottom: '1px solid rgba(0,0,0,0.12)'
    },

    listItemText: {
        '& span': {
            fontSize: 12
        }
    },

    formControl: {
        margin: '8px 0', // theme.spacing(1),
        minWidth: 120,
        // maxWidth: 300,
    },

    formControlMulti: {
        margin: '8px 0', //theme.spacing(1),
        minWidth: 120,
        // maxWidth: '97%',
    },

    comments: {},

    commentList: {
        width: '100%',
        // maxWidth: '36ch',
        // backgroundColor: theme.palette.background.paper,
    },

    replyCommentAuthor: {
        display: 'inline',
    },

    card: {
        // position: 'relative'
        overflow: 'hidden'
    },

    mainPart: {
        // maxHeight: '50vh',
        // // marginBottom: 120,
        // // overflow: 'hidden'
        // [theme.breakpoints.down('md')]: {
        //     height: '50vh'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     height: '50vh'
        // }
    },

    commentPart: {
        height: 140,
        padding: '0'
        // position: 'absolute',
        // bottom: 0,
        // left: 0,
        // right: 0
    },

    commentPartForm: {
        // padding: '16px 0'
    },

    commentSubpart: {},

    commentSubpartItem: {
        fontSize: '12px',
        marginLeft: 16,

        '&:first-child': {
            marginLeft: 0
        }
    },

    hiddenCommentText: {
        fontSize: 18,
        lineHeight: '26px',
        opacity: 0.5,
    },

    commentLalka: {
        float: 'right',
        opacity: 0.8,
        marginRight: 4,
        '&:hover': {
            opacity: 1
        }
    },

    commentLalkaIcon: {
        color: '#EC5280'
    },

    commentLalkaBadge: {
        '& .MuiBadge-badge': {
            fontSize: '10px',
            height: 16,
            minWidth: 16,
            padding: '0 2px'
        }
    },

    minorRatingIcon: {
        color: '#EC5280'
    },

    majorRatingIcon: {
        color: '#EC5280'
    },

    ratingContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },

    ratingBlock: {
        width: '100px',
    },

    ratings: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },

    ratingButton: {
        marginLeft: '24px',
        opacity: 0.8,
        '&:hover': {
            opacity: 1
        },
        '&:first-child': {
            marginLeft: 0
        }
    },

    ratingBadge: {
        '& .MuiBadge-badge': {}
    },

    sendCommentButtonWrapper: {
        position: 'relative'
    },

    sendCommentButtonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

    commentField: {
        width: '100%',
    },

    commentButton: {
        // float: 'right'
        width: '100%'
    },

    cardActions: {
        padding: 16,
        '& button': {
            marginLeft: 8,
            '&:first-child': {
                marginLeft: 0
            }
        }
    }

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const nanOrValue = (value, def = '') => isNaN(value) ? def : value
const prepareScalar = (field, value) => field.type === 'integer'
    ? nanOrValue(parseInt(value))
    : nanOrValue(parseFloat(value))


function MetricSuggestForm(props) {
    const classes = useStyles();

    const {
        cube,
        object,
        identity,
        onChange,
        disabled
    } = props

    const loadingFields = cube.struct.fields
        .filter(field => !isFieldObject(field) && !isFieldTimestamp(field))

    const [fieldWithValues, setFieldWithValues] = useState([])

    useEffect(() => {
        (async () => {
            const DB = await getDB();
            const rawValues = (await DB.run(`
                select 
                    ${loadingFields.map(field => `"${field.name}"`).join(', ')}
                from ${PROJECT_SCHEMA}."${cube.name}"
                where "${identity}" = '${object.id}'
            `)).toArray().map(Object.fromEntries)

            const fieldValues = loadingFields.map((field) => {
                const values = rawValues.map(row => row[field.name])
                const dict = cube.dicts.find(item => item.field === field.name)

                return {
                    ...field,
                    values,
                    dict,
                }
            })
            setFieldWithValues(fieldValues)
        })().then(() => {
        }).catch((e) => {
            console.log('MD_e', e)
        })
    }, [object])

    const nanOrValue = (value, def = '') => isNaN(value) ? def : value

    const prepareScalar = (field, value) => field.type === 'integer'
        ? nanOrValue(parseInt(value))
        : nanOrValue(parseFloat(value))

    const handleOnChange = (field) => (e) => {
        let value = e.target.value

        if (isFieldScalar(field)) {
            value = prepareScalar(field, value)
        }

        const newValue = fieldWithValues.map(row => (field.name === row.name ? {...row, values: [value]} : row))

        setFieldWithValues(
            newValue
        )

        if (onChange) onChange(
            cube.id,
            field.name,
            value,
            fieldWithValues
        )
    }

    if (!fieldWithValues.length) return null

    return (
        <div>
            {
                fieldWithValues
                    .filter(field => isFieldUUID(field) && field.dict && isFieldMultipleValue(field))
                    .map((field, fieldIndex) => {
                        const _label = `cube${cube.key}_field${field.name}`
                        const isMulti = isFieldMultipleValue(field)
                        const isSelectedValue = (value) => isMulti
                            ? field?.values?.indexOf(value.id) > -1
                            : value.id === field?.values[0]
                        return (
                            <>
                                <Typography variant={'h4'}>
                                    Function: {field.dict.values.filter(value => isSelectedValue(value)).map(value => value.title).join(', ')}
                                </Typography>

                                <FormControl fullWidth margin="normal" className={classes.formControl} key={fieldIndex}>
                                    <InputLabel id={_label}>Suggest {field.title}</InputLabel>
                                    <Select
                                        labelId={_label}
                                        id={_label}
                                        multiple={isFieldMultipleValue(field) ? true : undefined}
                                        autoWidth={isMulti}
                                        defaultValue={isMulti ? field.values : field.values[0]}
                                        onChange={handleOnChange(field)}
                                        input={<Input/>}
                                        MenuProps={MenuProps}
                                        disabled={disabled}
                                    >
                                        {!isMulti &&
                                        <MenuItem key={'undefined'}
                                                  value={null}
                                        >
                                            -undefined-
                                        </MenuItem>
                                        }
                                        {field.dict.values.map((value) => (
                                            <MenuItem key={value.key}
                                                      value={value.id}
                                                      selected={isSelectedValue(value)}
                                            >
                                                {value.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )
                    })
            }
        </div>
    )
}


function RecalculatableScalarField(props) {
    const classes = useStyles();

    const {
        key,
        projectId,
        objectId,
        metric,
        field,
        onChange,
        disabled
    } = props

    const {t} = useTranslation();

    const { lineage } = metric
    const hasLineage = Array.isArray(lineage) && lineage.length > 0

    const [isPending, setPending] = useState(false)
    const [value, setValue] = useState(prepareScalar(field, field.values[0]))

    const handleOnChange = (value) => {
        const newValue = prepareScalar(field, value)
        setValue(newValue)
        onChange(newValue)
    }

    const handleOnCalculate = async (e) => {

        if (isPending) return

        setPending(true)
        try {
            const lastLineage = lineage[0]
            const alg = getAlgorithm(lastLineage.algorithm)
            const result = await alg.calcOne(projectId, objectId, metric.title, lastLineage)
            handleOnChange(result[field.name])
        } catch (e) {
            console.log('E_re', e)
        } finally {
            setPending(false)
        }
    }

    const hasDefaultValue = prepareScalar(field, field.values[0]) !== undefined && prepareScalar(field, field.values[0]) !== null
    const inputProps = hasLineage && appConfig.isAlgEnabled
        ? {
            endAdornment: (<InputAdornment position="end">
                {isPending
                    ? <CircularProgress  />
                    : <Button onClick={handleOnCalculate}>{
                        hasDefaultValue
                            ? t('widgets.object_card.metric.recalc')
                            : t('widgets.object_card.metric.calc')
                    }</Button>
                }
            </InputAdornment>)
        }
        : undefined

    return (
        <FormControl fullWidth margin="normal" className={classes.formControl} key={key}>
            <TextField label={field.title}
                       value={value}
                       onChange={(e) => handleOnChange(e.target.value)}
                       disabled={disabled}
                       InputProps={inputProps}
            />
        </FormControl>
    )
}


function MetricForm(props) {
    const classes = useStyles();

    const {
        cube,
        metric,
        project,
        object,
        identity,
        onChange,
        disabled
    } = props

    const {t} = useTranslation();

    const { lineage } = metric
    const hasLineage = Array.isArray(lineage) && lineage.length > 0

    const loadingFields = cube.struct.fields
        .filter(field => !isFieldObject(field) && !isFieldTimestamp(field))

    const [fieldWithValues, setFieldWithValues] = useState([])

    useEffect(() => {
        (async () => {
            const DB = await getDB();
            const rawValues = (await DB.run(`
                select 
                    ${loadingFields.map(field => `"${field.name}"`).join(', ')}
                from ${PROJECT_SCHEMA}."${cube.name}"
                where "${identity}" = '${object.id}'
            `)).toArray().map(Object.fromEntries)

            const fieldValues = loadingFields.map((field) => {
                const values = rawValues.map(row => row[field.name])
                const dict = cube.dicts.find(item => item.field === field.name)

                return {
                    ...field,
                    values,
                    dict,
                }
            })
            setFieldWithValues(fieldValues)
        })().then(() => {
        }).catch((e) => {
            console.log('MD_e', e)
        })
    }, [object])

    const nanOrValue = (value, def = '') => isNaN(value) ? def : value

    const prepareScalar = (field, value) => field.type === 'integer'
        ? nanOrValue(parseInt(value))
        : nanOrValue(parseFloat(value))

    const handleOnChange = (field) => (e) => {
        let value = e.target.value

        const newValue = fieldWithValues.map(row => (field.name === row.name ? {...row, values: [value]} : row))
        const tags = isFieldUUID(field) && isFieldMultipleValue(field) && Array.isArray(value)
            ? value
                .map(valueId => field?.dict?.values?.find(dictValue => dictValue.id === valueId)?.tags)
                .filter(item => item !== null)
            : []

        setFieldWithValues(
            newValue
        )

        if (onChange) onChange(
            cube.id,
            field.name,
            value,
            fieldWithValues,
            tags
        )
    }

    if (!fieldWithValues.length) return null

    return (
        <div>
            {
                fieldWithValues
                    .map((field, fieldIndex) => {
                        const _label = `cube${cube.key}_field${field.name}`
                        if (isFieldUUID(field) && field.dict) {
                            const isMulti = isFieldMultipleValue(field)
                            const fieldClass = isMulti ? classes.formControlMulti : classes.formControl
                            const isSelectedValue = (value) => isMulti
                                ? field?.values?.indexOf(value.id) > -1
                                : value.id === field?.values[0]
                            const selectedValues = field.dict.values.filter(value => isSelectedValue(value))
                            const nonSelectedValues = field.dict.values.filter(value => !isSelectedValue(value))

                            if (isFieldMultipleValue(field)) {
                                return <TransferSelect
                                    options={field.dict.values}
                                    defaultValue={field.values}
                                    onChange={handleOnChange(field)}
                                    disabled={disabled}
                                />
                            }


                            return (
                                <FormControl fullWidth margin="normal" className={fieldClass} key={fieldIndex}>
                                    <InputLabel id={_label}>{field.title}</InputLabel>
                                    <Select
                                        labelId={_label}
                                        id={_label}
                                        multiple={isFieldMultipleValue(field) ? true : undefined}
                                        autoWidth={isMulti}
                                        defaultValue={isMulti ? field.values : field.values[0]}
                                        onChange={handleOnChange(field)}
                                        input={<Input/>}
                                        MenuProps={MenuProps}
                                        disabled={disabled}
                                    >
                                        {!isMulti &&
                                        <MenuItem key={'undefined'}
                                                  value={null}
                                        >
                                            -undefined-
                                        </MenuItem>
                                        }
                                        {field.dict.values.map((value) => (
                                            <MenuItem key={value.key}
                                                      value={value.id}
                                                      selected={isSelectedValue(value)}
                                            >
                                                {value.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        }


                        if (isFieldScalar(field)) {
                            return (
                                <RecalculatableScalarField
                                    field={field}
                                    metric={metric}
                                    projectId={project.id}
                                    objectId={object.id}
                                    onChange={handleOnChange(field)}
                                    key={fieldIndex}
                                    disabled={disabled}
                                />
                            )
                        }

                        return null
                    })
            }
        </div>
    )
}


function CommentList(props) {
    const classes = useStyles();
    const {me, comments, project, onLike, onPublish, onDelete} = props
    const {t} = useTranslation();

    const project_id = project?.id
    const user_id = me?.id

    const [publishComment, {data: publishedComment}] = useMutation(PublishComment, {client: apolloClient})
    const [unpublishComment, {data: unpublishedComment}] = useMutation(UnpublishComment, {client: apolloClient})

    const [deleteComment, {data: deletedComment}] = useMutation(DeleteComment, {client: apolloClient})

    const [likeComment, {data: likedComment}] = useMutation(LikeComment, {client: apolloClient})
    const [unlikeComment, {data: unlikedComment}] = useMutation(UnlikeComment, {client: apolloClient})

    const handlePublish = (comment_id) => {
        publishComment({variables: {comment_id}}).then(() => {
            if (onPublish) onPublish(comment_id, true)
        }).catch(() => {
        })
    }

    const handleUnpublish = (comment_id) => {
        unpublishComment({variables: {comment_id}}).then(() => {
            if (onPublish) onPublish(comment_id, false)
        }).catch(() => {
        })
    }

    const handleDelete = (comment_id) => {
        deleteComment({variables: {comment_id}}).then(() => {
            if (onDelete) onDelete(comment_id)
        })
    }

    const handleLalka = (comment_id, hasLalka) => {
        const operationPromise = !hasLalka
            ? likeComment({variables: {comment_id, project_id}})
            : unlikeComment({variables: {comment_id, project_id, user_id}})

        operationPromise.then(() => {
            if (onLike) onLike(comment_id, !hasLalka)
        }).catch(() => {
        })
    }

    return (
        <List className={classes.commentList}>
            {comments?.map((commentItem, commentIndex) => {
                const authorName = `${commentItem.user?.meta?.first_name ?? ''} ${commentItem.user?.meta?.last_name ?? ''}`.trim()
                const hasLalka = commentItem.ranks?.length > 0 ?? false
                const lalkaCount = commentItem.rankStat?.count ?? 0

                const isCommentPublished = commentItem.state > 0
                const canPublishComment = isManager(me) && !isCommentPublished
                const canUnpublishComment = isManager(me) && isCommentPublished

                const deleteTimeout = Moment(Date.now()).subtract(20, 'minutes')
                const deleteTimeDelta = Date.parse(commentItem.cr) - deleteTimeout.toDate()
                const deleteMinutesDelta = Math.round(deleteTimeDelta / 60000)
                const canDeleteComment = commentItem.user?.id === me?.id && Date.parse(commentItem.cr) > deleteTimeout.toDate()
                const isMyComment = me?.id === commentItem.user?.id

                const commentStyle = {
                    opacity: isCommentPublished ? 1 : 0.5
                }

                return (
                    <ListItem alignItems="flex-start" key={commentIndex}>
                        <ListItemAvatar>
                            <Avatar>{authorName.length > 0 ? authorName[0] : ''}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={authorName}
                            style={commentStyle}
                            secondary={
                                (!isCommentPublished && !isManager(me) && !isMyComment)
                                    ?
                                    <Typography
                                        component='span'
                                        variant='body2'
                                        color='textPrimary'
                                        className={classes.hiddenCommentText}
                                    >{t('widgets.object_card.comments.comment_hidden')}</Typography>
                                    :
                                    <React.Fragment>
                                        <Typography
                                            component='span'
                                            variant='body2'
                                            color='textPrimary'
                                            style={commentStyle}
                                            className={classes.replyCommentAuthor}
                                        >{commentItem.message?.text}</Typography>

                                        <Typography className={classes.commentSubpart}>
                                        <span
                                            className={classes.commentSubpartItem}>{Moment(commentItem.cr).fromNow()}</span>

                                            {/*
                                        <Link className={classes.commentSubpartItem} href="#" onClick={() => {
                                        }}>
                                            Reply
                                        </Link>
*/}

                                            {canDeleteComment &&
                                            <Link className={classes.commentSubpartItem} href="#" onClick={(e) => {
                                                e.preventDefault()
                                                handleDelete(commentItem.id)
                                            }}>
                                                {t('widgets.object_card.comments.delete')} (in {deleteMinutesDelta} {deleteMinutesDelta !== 1
                                                ? t('widgets.object_card.comments.minutes')
                                                : t('widgets.object_card.comments.minute')})
                                            </Link>
                                            }

                                            {canPublishComment &&
                                            <Link className={classes.commentSubpartItem} href="#" onClick={(e) => {
                                                e.preventDefault()
                                                handlePublish(commentItem.id)
                                            }}>
                                                {t('widgets.object_card.comments.publish')}
                                            </Link>
                                            }

                                            {canUnpublishComment &&
                                            <Link className={classes.commentSubpartItem} href="#" onClick={(e) => {
                                                e.preventDefault()
                                                handleUnpublish(commentItem.id)
                                            }}>
                                                {t('widgets.object_card.comments.unpublish')}
                                            </Link>
                                            }

                                            <Link href="#" onClick={(e) => {
                                                e.preventDefault()
                                                handleLalka(commentItem.id, hasLalka)
                                            }} className={classes.commentLalka}>
                                                <Badge badgeContent={lalkaCount} max={99} color="primary"
                                                       className={classes.commentLalkaBadge}>
                                                    {
                                                        hasLalka
                                                            ? <FavoriteIcon fontSize="small"
                                                                            className={classes.commentLalkaIcon}/>
                                                            : <FavoriteBorderIcon fontSize="small"
                                                                                  className={classes.commentLalkaIcon}/>
                                                    }
                                                </Badge>
                                            </Link>
                                        </Typography>

                                    </React.Fragment>
                            }
                        />
                    </ListItem>
                )
            })}
        </List>
    );
}


function CommentForm(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {me, replyTo: defaultReplyTo, onSend, project, object} = props
    const [commentText, setCommentText] = useState('')
    const [replyTo, setReplyTo] = useState(null)
    const [isSending, setSending] = useState(false)
    const myName = `${me?.meta?.first_name ?? ''} ${me?.meta?.last_name ?? ''}`.trim()

    //CreateComment
    const [createComment, {data: createdComment}] = useMutation(CreateComment,
        {
            client: apolloClient,
            // refetchQueries: [
            //     {query: GetComments,
            //         variables: {
            //             project_id: project?.id,
            //             object_id: object?.id,
            //             user_id: me?.id
            //         }
            //     }
            // ]
        })

    useEffect(() => {
        if (defaultReplyTo) {
            setReplyTo(defaultReplyTo)
        }
    }, [defaultReplyTo])

    const handleOnSend = () => {
        setSending(true)
        createComment({
            variables: {
                project_id: project?.id,
                object_id: object?.id,
                lng: object?.lng,
                lat: object?.lat,
                message: {
                    text: commentText
                },
                reply_to: replyTo
            }
        }).then(() => {
            if (onSend) onSend()

            setCommentText('')
            setReplyTo(null)

            setSending(false)
        }).catch((e) => {
            console.error('CM_e>', e)
            setSending(false)
        })
    }

    const handleTextChange = (comment) => {
        setCommentText(comment)
    }

    return (
        <div className={classes.commentPart}>
            <ListItem alignItems="flex-start" key="comment-form" className={classes.commentPartForm}>
                <ListItemAvatar>
                    <Avatar>{myName && myName.length ? myName[0] : 'A'}</Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={myName}
                    secondary={
                        <React.Fragment>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                            >
                                <Grid item xs={10}>
                                    <TextField label={t('widgets.object_card.comments.comment')}
                                               variant="filled"
                                               multiline
                                               placeholder={t('widgets.object_card.comments.please_type')}
                                               maxRows={2}
                                               rowsMax={2}
                                               className={classes.commentField}
                                               value={commentText}
                                               onChange={(e) => handleTextChange(e.target.value)}
                                               onKeyDown={(e) => e.ctrlKey && e.keyCode === 13 ? handleOnSend() : ''}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <div className={classes.sendCommentButtonWrapper}>
                                        <Button
                                            disabled={isSending || commentText.trim().length === 0}
                                            onClick={handleOnSend}
                                            className={classes.commentButton}
                                        >{t('widgets.object_card.comments.send')}</Button>

                                        {isSending &&
                                        <CircularProgress size={24} className={classes.sendCommentButtonProgress}/>}
                                    </div>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                />
            </ListItem>
        </div>
    )
}


function TagsTable(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {tags, metricTags, isObjectEditor, onChange, onNewTags} = props

    const tagsTable = Object.entries(Object.fromEntries(
        // tags ? Object.entries(tags) : []
        (metricTags
                ? metricTags.map(([tagName, tagValues]) => ([tagName, tagValues[0]]))
                : []
        ).concat(tags ? Object.entries(tags) : [])
    ))

    const [newTags, setNewTags] = useState([])

    const handleAddTag = () => {
        setNewTags(newTags.concat([['', '']]))
    }

    useEffect(() => {
        if (onNewTags) onNewTags(newTags)
    }, [newTags])

    return (
        <TableContainer
            component="div"
        >
            <Table className={classes.tagsTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('widgets.object_card.tags_table.tag_name')}</TableCell>
                        <TableCell>{t('widgets.object_card.tags_table.tag_value')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tagsTable.map(([tagName, tagValue], rowIndex) => {
                        const metricTag = metricTags.find(([metricTagName, _]) => metricTagName === tagName)

                        const TagField = metricTag ? (
                            <Autocomplete
                                options={metricTag[1]}
                                defaultValue={tagValue}
                                getOptionLabel={(option) => option}
                                className={classes.field}
                                freeSolo={true}
                                renderInput={(params) => <TextField
                                    {...params}
                                    className={classes.field}
                                    disabled={!isObjectEditor}
                                />}
                                onChange={(e, value) => {
                                    if (onChange) onChange(tagName, value)
                                    console.log('ch>', {tagName, value})
                                }}
                            />
                        ) : (
                            <TextField
                                defaultValue={tagValue}
                                className={classes.field}
                                fullWidth={true}
                                onChange={(e) => {
                                    if (onChange) onChange(tagName, e.target.value)
                                }}
                                disabled={!isObjectEditor}
                            />
                        )

                        return (
                            <TableRow key={rowIndex}>
                                <TableCell component="th" scope="row">
                                    {tagName}
                                </TableCell>
                                <TableCell>
                                    {TagField}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    {newTags.map(([tagName, tagValue], newTagIndex) => {
                        return (
                            <TableRow key={`new_${newTagIndex}`}>
                                <TableCell component="th" scope="row">
                                    <TextField
                                        defaultValue={tagName}
                                        className={classes.field}
                                        fullWidth={true}
                                        onChange={(e) => {
                                            newTags[newTagIndex][0] = e.target.value
                                            setNewTags(newTags)
                                        }}
                                        disabled={!isObjectEditor}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        defaultValue={tagValue}
                                        className={classes.field}
                                        fullWidth={true}
                                        onChange={(e) => {
                                            newTags[newTagIndex][1] = e.target.value
                                            setNewTags(newTags)
                                        }}
                                        disabled={!isObjectEditor}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    })
                    }
                    <TableRow key="add">
                        <TableCell component="th" scope="row">
                            <Button variant="contained" onClick={handleAddTag}>Add tag</Button>
                        </TableCell>

                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}


export default function BuildingCardModal(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {isOpened, onClose, onSave, onDelete, context} = props
    const [open, setOpen] = React.useState(isOpened ?? false);

    const {dataState, dataDispatch} = useContext(DataStateContext)
    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {cube, me, project} = dataState

    const {
        layer,
        object,
        type: objectType
    } = context ?? {}

    const objectMetrics = Object
        .values(mapState?.layers)
        .filter(lr => lr.objectType?.key === objectType)
        .reduce((metrics, lr) => ([...metrics, ...lr?.metrics]), [])

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    const isObjectEditor = me?.id === object?.user_id || isManager(me)

    const scrollRef = useRef(null)

    const [currentTab, setCurrentTab] = useState(0)

    const [currentObject, setCurrentObject] = useState({})
    const [objectNewTags, setObjectNewTags] = useState([])
    const [metricTags, setMetricTags] = useState([])
    const [currentMetrics, setCurrentMetrics] = useState({})
    const [openedMetrics, setOpenedMetrics] = useState([])
    const [comments, setComments] = useState([])

    useEffect(() => {
        setMetricTags([])
    }, [object])


    const {
        data: objectRanks,
        loading: objectRanksLoading,
        error: objectRanksError,
        refetch: refetchObjectRanks
    } = useQuery(ObjectRanks, {
        fetchPolicy: "no-cache",
        client: apolloClient,
        variables: {
            project_id: project?.id,
            object_id: object?.id,
            user_id: me?.id
        }
    })
    const [rankObject, {data: rankedObject}] = useMutation(RankObject, {client: apolloClient})
    const [unrankObject, {data: unrankedObject}] = useMutation(UnrankObject, {client: apolloClient})

    const handleRateObject = (rank) => rankObject({
        variables: {
            project_id: project?.id,
            object_id: object?.id,
            rank
        }
    }).then(() => {
        setObjectRating(existRank => (
            rank > 0
                ? [existRank[0] - Math.abs(myRating), existRank[1] + 1]
                : [existRank[0] + 1, existRank[1] - Math.abs(myRating)]
        ))
        setMyRating(rank)
    }).catch(() => {
    })

    const handleUnrateObject = () => unrankObject({
        variables: {
            project_id: project?.id,
            object_id: object?.id,
            user_id: me?.id
        }
    }).then(() => {
        const newRank = myRating > 0
            ? [objectRating[0], objectRating[1] - myRating]
            : [objectRating[0] + myRating, objectRating[1]]

        setObjectRating(newRank)
        setMyRating(0)

        checkUnactivateObject(comments, newRank)
    }).catch(() => {
    })

    const [myRating, setMyRating] = useState(0)
    const [objectRating, setObjectRating] = useState([0, 0])
    useEffect(() => {
        if (objectRanks) {
            setMyRating(objectRanks.objectRank?.rank ?? 0)
            setObjectRating(objectRanks.objectsRankStat.length > 0
                ? [objectRanks.objectsRankStat[0].count_minor, objectRanks.objectsRankStat[0].count_major]
                : [0, 0]
            )
        }
    }, [objectRanks])

    const handleOnLike = (commentId, isLike) => setComments(comments => comments.map((commentItem) =>
        commentItem.id === commentId ? {
            ...commentItem,
            ranks: isLike ? [{rank: 1}] : [],
            rankStat: {
                ...(commentItem.rankStat ? commentItem.rankStat : {}),
                count: Math.max((commentItem.rankStat?.count ?? 0) + (isLike ? 1 : -1), 0)
            }
        } : commentItem
    ))

    const handleOnPublish = (commentId, isPublish) => setComments(comments => comments.map((commentItem) =>
        commentItem.id === commentId ? {
            ...commentItem,
            state: isPublish ? 1 : 0
        } : commentItem
    ))

    const handleUpdateTag = (tagName, tagValue) => {
        if (!tagName) return

        const existTags = currentObject?.tags ? {...currentObject.tags} : {}

        if (existTags[tagName] && !tagValue) return;

        const tags = {
            ...existTags,
            [tagName]: tagValue
        }
        setCurrentObject({...currentObject, tags})
    }

    const handleNewTags = (newTags) => setObjectNewTags(newTags)
    const handleMetricTags = (tags) => setMetricTags(Object.entries(Object.fromEntries(tags)))

    const checkUnactivateObject = (checkComments, checkRating) => {
        if (checkComments.length === 0 && checkRating?.join(',') === '0,0') {
            dataDispatch({
                type: 'removeActivityObject',
                objectId: object?.id
            })

            mapDispatch({type: 'reloadUserActivity'})
        }
    }

    const handleOnDelete = (commentId) => {
        const newComments = comments.filter(commentItem => commentItem.id !== commentId)
        // const handleOnDelete = (commentId) => setComments(comments => comments.filter(commentItem => commentItem.id !== commentId))
        setComments(newComments)
        checkUnactivateObject(newComments, objectRating)
    }

    const {data: rawComments, loading: commentsLoading, error, refetch: refetchComments} = useQuery(GetComments, {
        fetchPolicy: "no-cache",
        client: apolloClient,
        variables: {
            project_id: project?.id,
            object_id: object?.id,
            user_id: me?.id
        }
    });

    useEffect(() => {
        if (rawComments) setComments(rawComments.comments)
    }, [rawComments])

    useEffect(() => {
        setCurrentObject(object)
        setCurrentMetrics([])
    }, [object])

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose(context)
    };

    const handleDelete = () => {
        setOpen(false);
        if (onDelete) onDelete(currentObject)
    }

    const handleSave = () => {
        setOpen(false);
        const tags = currentObject.tags ? currentObject.tags : {}
        currentObject.tags = {
            ...(metricTags? Object.fromEntries(metricTags
                .filter(([_, values]) => Array.isArray(values) && values.length > 0)
                .map(([tagName, values]) => ([tagName, values[0]]))): {}),
            ...tags,
            ...Object.fromEntries(objectNewTags), }
        if (onSave) onSave(currentObject, currentMetrics)
    }

    const handleSetMeta = (field) => (e) => {
        if (e?.target?.value === undefined) return

        const newState = {
            ...currentObject,
            meta: {
                ...currentObject?.meta,
                [field]: e?.target?.value
            },
            user_id: me.id
        }

        setCurrentObject(newState)
    }

    const handleMetricChange = (cubeId, fieldName, value, field, tags) => {

        if (tags && tags.length > 0) {
            const newMetricTags =
                Object.entries(tags
                    .reduce((newTags, tagMap) => ({
                        ...newTags, ...Object.fromEntries(
                            Object
                                .entries(tagMap)
                                .filter(([tag, values]) => values && values.length)
                            // .map(([tag, values]) => ([tag, values[0]])),
                        )
                    }), {}))

            handleMetricTags(newMetricTags)
        }

        setCurrentMetrics(metrics => ({
            ...metrics,
            [cubeId]: {
                ...(metrics[cubeId] ? metrics[cubeId] : {}),
                [fieldName]: value
            }
        }))
        setCurrentObject({...currentObject, user_id: me.id})
    }

    const scrollDown = () => {
        try {
            scrollRef.current._container.scrollTop = scrollRef.current._container.scrollHeight + 200
        } catch (e) {
            // ...
        }
    }

    useEffect(() => {
        if (isOpened && context) {
            setOpen(true);
        } else {
        }
    }, [isOpened])

    let interval;
    useEffect(() => {
        if (open) {
            interval = setInterval(() => {
                refetchComments().then(() => {
                    // setTimeout(() => scrollDown(), 10)
                }).catch(() => {
                })

                refetchObjectRanks().then(() => {
                }).catch(() => {
                })

            }, 20 * 1000)
        } else {
            if (interval) clearInterval(interval)
        }

        return () => clearInterval(interval);
    }, [open])


    if (!currentObject) return null

    const objectTypeName = layer?.objectType?.meta?.title

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Card className={classes.root}>

                    <div>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={11}>
                                <Tabs
                                    value={currentTab}
                                    onChange={(event, tabIndex) => setCurrentTab(tabIndex)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="Common"/>
                                    <Tab label="Metrics"/>
                                    <Tab label="Description"/>
                                </Tabs>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton aria-label="settings" onClick={handleClose}>
                                    <CloseIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>

                    {/*
                    <CardHeader
                        action={
                                <IconButton aria-label="settings" onClick={handleClose}>
                                    <CloseIcon/>
                                </IconButton>
                        }
                        title={objectTypeName}
                    />
*/}

                    <CardContent className={classes.card}>

                        <div className={classes.tabContent} style={{display: currentTab === 0 ? 'block' : 'none'}}>
                            <Scrollbar ref={scrollRef}>
                                <div className={classes.mainPart}>

                                    <div className={classes.mainFields}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                        >
                                            <Grid item xs={12} sm={8}>
                                                <h5 className={classes.title}>{t('widgets.object_card.information')}</h5>
                                                <FormControl fullWidth margin="normal" className={classes.formControl}
                                                             key="title">
                                                    <TextField
                                                        label={t('widgets.object_card.fields.title')}
                                                        value={currentObject?.meta?.title}
                                                        className={classes.field}
                                                        onChange={handleSetMeta('title')}
                                                        disabled={!isObjectEditor}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div className={classes.ratingContainer}>
                                                    <div className={classes.ratingBlock}>
                                                        <h5 className={classes.title}>{t('widgets.object_card.rating')}</h5>
                                                        <div className={classes.ratings}>
                                                            <Link href="#" onClick={(e) => {
                                                                e.preventDefault()

                                                                if (myRating < 0) {
                                                                    handleUnrateObject()
                                                                } else {
                                                                    handleRateObject(-1)

                                                                    dataDispatch({
                                                                        type: 'addActivityObjects',
                                                                        activityObjects: [object.id]
                                                                    })

                                                                    mapDispatch({type: 'reloadUserActivity'})
                                                                }
                                                            }} className={classes.ratingButton}>
                                                                <Badge badgeContent={objectRating[0]} max={99}
                                                                       color="primary"
                                                                       className={classes.ratingBadge}>
                                                                    {
                                                                        myRating < 0
                                                                            ? <ThumbDownAlt
                                                                                className={classes.minorRatingIcon}/>
                                                                            : <ThumbDownAltOutlined
                                                                                className={classes.minorRatingIcon}/>
                                                                    }
                                                                </Badge>
                                                            </Link>

                                                            <Link href="#" onClick={(e) => {
                                                                e.preventDefault()

                                                                if (myRating > 0) {
                                                                    handleUnrateObject()
                                                                } else {
                                                                    handleRateObject(1)

                                                                    dataDispatch({
                                                                        type: 'addActivityObjects',
                                                                        activityObjects: [object.id]
                                                                    })

                                                                    mapDispatch({type: 'reloadUserActivity'})
                                                                }
                                                            }} className={classes.ratingButton}>
                                                                <Badge badgeContent={objectRating[1]} max={99}
                                                                       color="primary"
                                                                       className={classes.ratingBadge}>
                                                                    {
                                                                        myRating > 0
                                                                            ? <ThumbUpAlt
                                                                                className={classes.majorRatingIcon}/>
                                                                            : <ThumbUpAltOutlined
                                                                                className={classes.minorRatingIcon}/>
                                                                    }

                                                                </Badge>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <h5 className={classes.title}>{t('widgets.object_card.comments.comments')} ({Array.isArray(comments) ? comments.length : 0})</h5>

                                    <div className={classes.comments}>

                                        {commentsLoading &&
                                        <div style={{textAlign: 'center'}}><CircularProgress/></div>}

                                        {!commentsLoading && comments.length > 0 &&
                                        <CommentList
                                            me={me}
                                            project={project}
                                            comments={comments}
                                            onLike={handleOnLike}
                                            onPublish={handleOnPublish}
                                            onDelete={handleOnDelete}
                                        />}

                                    </div>
                                </div>

                                <CommentForm me={me} project={project} object={object} onSend={() => {
                                    dataDispatch({
                                        type: 'addActivityObjects',
                                        activityObjects: [object.id]
                                    })

                                    mapDispatch({type: 'reloadUserActivity'})

                                    refetchComments().then(() => {
                                        setTimeout(() => scrollDown(), 10)
                                    }).catch(() => {
                                    })
                                }}/>

                            </Scrollbar>
                        </div>

                        <div className={classes.tabContent} style={{display: currentTab === 1 ? 'block' : 'none'}}>
                            <Scrollbar>
                                <h5 className={classes.title}>{t('widgets.object_card.metrics')}</h5>

                                <div className={classes.metrics}>
                                    <List component='div' disablePadding>
                                        {
                                            objectMetrics?.map((metric, metricIndex) => {
                                                const metricCube = cube[metric.id]
                                                const isMetricOpened = openedMetrics.indexOf(metric.id) > -1
                                                return (
                                                    <>
                                                        <ListItem key={`list-item-${metricIndex}`}
                                                                  button
                                                                  onClick={() => setOpenedMetrics(oldState => {
                                                                      return isMetricOpened
                                                                          ? oldState.filter(msItem => msItem !== metric.id)
                                                                          : oldState.concat(metric.id)
                                                                  })}
                                                                  className={classes.listMetricItem}
                                                        >
                                                            <ListItemAvatar>
                                                                <Avatar aria-label="recipe"
                                                                        className={classes.avatar}
                                                                        style={{background: metric.color}}>
                                                                    {metric.litera}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={metric.title}/>
                                                            {isMetricOpened ? <ExpandLess/> : <ExpandMore/>}
                                                        </ListItem>
                                                        <Collapse key={`collapse-${metricIndex}`}
                                                                  in={isMetricOpened}
                                                        >

                                                            <MetricForm
                                                                identity={layer?.objectType?.key}
                                                                project={project}
                                                                object={currentObject}
                                                                cube={metricCube}
                                                                metric={metric}
                                                                onChange={handleMetricChange}
                                                                disabled={!isObjectEditor}
                                                            />
                                                        </Collapse>
                                                    </>
                                                )
                                            })
                                        }
                                    </List>
                                </div>
                            </Scrollbar>
                        </div>

                        <div className={classes.tabContent} style={{display: currentTab === 2 ? 'block' : 'none'}}>
                            <Scrollbar>
                                <h5 className={classes.title}>{t('widgets.object_card.description')}</h5>

                                <div className={classes.mainFields}>
                                    <FormControl fullWidth margin="normal" className={classes.formControl}
                                                 key="about">
                                        <TextField
                                            label={t('widgets.object_card.fields.about')}
                                            multiline
                                            minRows={2}
                                            value={currentObject?.meta?.about}
                                            // variant="filled"
                                            className={classes.field}
                                            onChange={handleSetMeta('about')}
                                            disabled={!isObjectEditor}
                                        />
                                    </FormControl>
                                </div>

                                <h5 className={classes.title}>{t('widgets.object_card.tags')}</h5>

                                <TagsTable tags={object?.tags}
                                           metricTags={metricTags}
                                           isObjectEditor={isObjectEditor}
                                           onChange={handleUpdateTag}
                                           onNewTags={handleNewTags}
                                />
                            </Scrollbar>
                        </div>

                    </CardContent>

                    <CardActions className={classes.cardActions}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={8}>
                                {!isObjectEditor &&
                                <Button variant="contained" color="default" onClick={handleClose}>
                                    {t('widgets.object_card.close')}
                                </Button>
                                }
                                {isObjectEditor &&
                                <Button variant="contained" color="default" onClick={handleClose}>
                                    {t('widgets.object_card.cancel')}
                                </Button>
                                }
                                {isObjectEditor &&
                                <Button variant="contained" color="secondary" onClick={handleDelete}>
                                    {t('widgets.object_card.delete')} {objectTypeName ? objectTypeName.toLowerCase() : ''}
                                </Button>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                {isObjectEditor &&
                                <Button variant="contained" color="primary" onClick={handleSave}
                                        style={{float: 'right'}}>
                                    {t('widgets.object_card.save')}
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Fade>
        </Modal>
    );
}
