import React, {useEffect, useState} from "react";
import {
    TextLayer,
    IconLayer,
} from '@deck.gl/layers';
import {
    CompositeLayer,
} from '@deck.gl/core';
import GL from "@luma.gl/constants";
import {uuidStringify} from "../../../../../../helpers/uuid";
import LOKKPathLayer from './path-layer'
import LOKKObjectLayer from './object-layer'
import {isPossible3d} from "../../../../../../helpers/geom";
import {DEFAULT_HEIGHT} from "../../../../../../helpers/const";
import {userActivityDeckLayers} from '../../../../../../models/layer'


const getIconHandler = (me) => (d) => ({
    url: !!d.user_id
        ? d.user_id === me.id
            ? 'https://files.lokk-platform.com/user-avatar-c.png'
            : 'https://files.lokk-platform.com/user-avatar-g.png'
        : 'https://files.lokk-platform.com/comment-avatar-g.png',
    width: 512,
    height: 768,
    anchorY: 768,
    mask: false
})

const onExit = () => {
}  // data[0]?.options?.onExit

const iconSize = 50
const fontSize = 16

class LOKKUserActivityLayer extends CompositeLayer {
    renderLayers() {
        return [
            new IconLayer({
                id: userActivityDeckLayers.icon,
                data: this.props.data,
                pickable: true,

                getIcon: getIconHandler(this.props.me),

                getPosition: d => [d.lng, d.lat, isPossible3d(d.geom_type) ? DEFAULT_HEIGHT + 1 : 0],
                getSize: d => iconSize,
                // getColor: d => [20, 140, 0],
                // opacity: 0.9,

                onClick: d => d.object?.options?.onClick ? d.object.options.onClick(d) : onExit(),
                // onHover: d => d.object?.options?.onHover ? d.object.options.onHover(d) : onExit(),

                parameters: {
                    depthTest: false
                },

                visible: this.props.visible
            }),

/*
    // todo: was names of objects, now are disabled
            new TextLayer({
                id: userActivityDeckLayers.label,
                data: this.props.data,

                getPosition: d => [d.lng, d.lat, isPossible3d(d.geom_type) ? DEFAULT_HEIGHT + 1 : 0],
                getText: d => (d?.meta?.title ? d?.meta?.title : ''), // + ' ' + d?.id

                characterSet: 'auto',
                getSize: fontSize,
                getAngle: 0,
                getColor: [255, 255, 255],
                getTextAnchor: 'middle',
                getAlignmentBaseline: 'bottom',

                fontFamily: "'Montserrat', sans-serif",
                sizeScale: 1,
                sizeUnits: 'pixels',
                wordBreak: 'break-word',
                getPixelOffset: [0, -50],

                onClick: d => d.object?.options?.onClick ? d.object.options.onClick(d) : onExit(),

                parameters: {
                    depthTest: false
                },

                visible: this.props.visible
            })
*/

        ];
    }
}


LOKKUserActivityLayer.defaultProps = {
    ...LOKKUserActivityLayer.defaultProps,
    me: {type: 'string'}
}

export default LOKKUserActivityLayer;
