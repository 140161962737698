import React, {useState, useEffect, useReducer, useContext} from "react";
import FilterFabric from "../../../pages/project/map/components/map/filters/filter-fabric";
import {DataStateContext, MapStateContext} from "../../../pages/project/map/reducer";
import {makeStyles} from "@material-ui/core/styles";
import Scrollbar from "react-perfect-scrollbar";
import {
    Grid,
    Chip,
    Paper,
    Divider,
    InputLabel,
    Tooltip,
    Input,
    Button,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    TableContainer, Table, TableRow, TableCell, Typography, Slider,
} from "@material-ui/core";
import {
    AddCircleOutline,
    Close
} from '@material-ui/icons'
import {Avatar} from "@material-ui/core";
import {getDB} from "../../../db";
import {operandKey} from "../../../helpers/alg";
import {isFieldScalar, isFieldUUID, isFieldObject, isFieldTimestamp} from "../../../helpers/field";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        width: 280,
        background: '#D9DFE4',
        borderRadius: 0,
        padding: theme.spacing(1),
        boxSizing: 'border-box'
    },
    selectMetric: {
        width: '100%'
    },
    selectField: {
        width: 80
    },
    title: {
        boxSizing: 'border-box',
        padding: '1rem 10px',
        margin: '0'
    },
    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    selectObjectType: {
        width: 260
    },

    fieldChip: {
        display: 'inline-block',
        maxWidth: 120,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '12px',
        lineHeight: '32px',
    },

    operandRow: {
        margin: '2px 0'
    },

    inputControl: {
        width: '100%',
        textAlign: 'right'
    },

    formControl: {
        display: 'block'
    },

    addItemButton: {
        paddingTop: '1rem',
        width: '100%',
    },

    controlDivider: {
        margin: '1rem 0'
    },

    selectFieldPlace: {
        marginLeft: 10
    },

    lineWidthControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));

const SpreadMetric = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        struct,
        params,
        objectType: currentObjectTypeId,
        onChangeParams,
    } = props;

    const {dataState, dataDispatch} = useContext(DataStateContext)
    const {mapState, mapDispatch} = useContext(MapStateContext)

    const {
        // objectType
        metric: metrics,
        cube: cubes,
    } = dataState;

    const [selectedMetricId, setSelectedMetricId] = useState(null)
    const [selectedField, setSelectedField] = useState(null)
    const [selectedFilterFields, setSelectedFilterFields] = useState([])

    const availableMetrics = Object.values(metrics).filter(metric => {
        try {
            const cube = cubes[metric.id]
            const hasScalars = cube.struct.fields.findIndex(field => isFieldScalar(field)) > -1
            const isNotSameObject = metric.object.id !== currentObjectTypeId
            return hasScalars && isNotSameObject
        } catch (e) {
            return false
        }
    })
    const selectedCube = cubes[selectedMetricId]
    const availableFilterFields = selectedCube?.struct.fields
        .filter(field => (isFieldUUID(field) || isFieldTimestamp(field)) && !isFieldObject(field))
        .map(field => {
            if (isFieldUUID(field)) {
                field['values'] = selectedCube.dicts.find(d => d.field === field.name)?.values
            } else if (isFieldTimestamp(field)) {
                field['values'] = selectedCube.struct.timeseries.map(ts => ({id: ts, title: ts}))
            }

            return field
        })

    const availableFields = selectedCube?.struct.fields.filter(field => isFieldScalar(field))
    const isFieldsAvailable = !!selectedMetricId && availableFields && availableFields.length > 0
    const isFilterFieldsAvailable =  !!availableFilterFields && availableFilterFields.length > 0

    // const defaultOperands = mapState.algorithmParams && mapState.algorithmParams?.operands
    //     ? mapState.algorithmParams?.operands
    //     : {}
    // const [operands, setOperands] = useState(
    //     defaultOperands
    // )

    useEffect(() => {
        if (!currentObjectTypeId) {
            setSelectedMetricId(null)
            setSelectedField(null)
            setSelectedFilterFields([])
        }
    }, [currentObjectTypeId])

    useEffect(() => {
        setSelectedField(null)
        setSelectedFilterFields([])
    }, [selectedMetricId])


    return (
        <div>

            <div className={classes.lineWidthControl}>
                <Typography id="non-linear-slider" gutterBottom>
                    {t('project.leftbar.panel5.spreadmetric')}
                </Typography>
            </div>

            <Divider className={classes.controlDivider}/>
            <div>
                <div>
                    <FormControl class={classes.formControl}>
                        <InputLabel>{t('project.leftbar.panel5.metric')}:</InputLabel>
                        <Select
                            value={selectedMetricId}
                            onChange={(e) => setSelectedMetricId(e.target.value)}
                            className={classes.selectMetric}
                        >
                            <MenuItem value=""></MenuItem>
                            {
                                availableMetrics?.map((metric, mi) => (
                                    <MenuItem key={mi} value={metric.id}>{metric.meta.title}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
                {isFieldsAvailable &&
                <div>
                    {/*
                        ДАЛЬШЕ FIELD ИЗ СКАЛЯРОВ И ФИЛЬТРЫ ИЗ DICT
                    */}

                    <FormControl class={classes.formControl}>
                        <InputLabel>{t('project.leftbar.panel5.field')}:</InputLabel>
                        <Select
                            value={selectedField}
                            onChange={(e) => setSelectedField(e.target.value)}
                            className={classes.selectMetric}
                        >
                            <MenuItem value=""></MenuItem>
                            {
                                availableFields?.map((field, mi) => (
                                    <MenuItem key={mi} value={field.name}>{field.title}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
                }

                {isFilterFieldsAvailable &&
                <div>
                    <h5>Filters</h5>

                    {availableFilterFields.map(filterField => (
                        <FormControl class={classes.formControl}>
                            <InputLabel>{filterField.title}:</InputLabel>
                            <Select
                                value={selectedFilterFields[filterField.name]}
                                onChange={(e) => setSelectedFilterFields(
                                    existValues => ({...existValues, [filterField.name]: e.target.value})
                                )}
                                className={classes.selectMetric}
                            >
                                <MenuItem value=""></MenuItem>
                                {
                                    filterField.values?.map((value, vi) => (
                                        <MenuItem key={vi} value={value.id}>{value.title}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    ))}
                </div>
                }
            </div>
        </div>
    )

}
export default SpreadMetric;