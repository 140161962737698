import {getDB, rowStructMap} from "../../db";
import {objectSchema} from '../../models/object'

export default function userLayerDataFabric({mapDispatch, dataState}) {
    return async function getData() {

        mapDispatch({type: 'setCalculating', isCalculating: true})
        const {activityObjects} = dataState

        const activityObjectIdSQL = activityObjects && activityObjects.length
            ? activityObjects.map(identity => `'${identity}'`).join(', ')
            : 'null'

        const DB = await getDB();
        const objectTables = Object.values(dataState.objectType).map(ot => DB.getTableName(ot.name))
        const objectFieldsStruct = objectSchema.fields.reduce((acc, fld) => ({...acc, [fld.name]: fld.type}), {})
        const query = objectTables.map(tableName => `
            select 
                ${tableName}.id as id, 
                ${tableName}.meta as meta, 
                ${tableName}.lng as lng,
                ${tableName}.lat as lat,
                ${tableName}.geom_type as geom_type,
                ${tableName}.user_id as user_id
            from ${tableName}
            where user_id is not null or ${tableName}.id in (${activityObjectIdSQL})
        `).join(' union ')

        const result = await DB.run(query)

        mapDispatch({type: 'setCalculating', isCalculating: false})

        return result
            .toArray()
            .map(Object.fromEntries)
            .map(rowStructMap(objectFieldsStruct))
    }
}