import { gql } from "@apollo/client";

export const ProjectsQuery = gql`
    query ProjectsQuery {
        projects(order_by: {cr: asc}) {
            title
            about
            slug
            id
            key
        }
    }
`;

export const AmenityObjects = gql`
    query AmenityObjects ($frame: geometry) {
        raw_layers(where: {geom: {_st_intersects: $frame}, _and: {layer: {_neq: "landuse"}}}) {
            weight {
                weight
            }
            tag
            layer
            geom
            data
            center_geom
        }
    }
`

export const NearbyObjects = gql`
    query NearbyObjects($tile_id: Int) {
        raw_nearby_layers(where: {ogc_fid: {_eq: $tile_id}}) {
            tag
            layer
            count
            avg
        }
        raw_nearby_roads(where: {ogc_fid: {_eq: $tile_id}}) {
            highway
            count
            avg
            max
            min
        }        
    }
`

export const MapFootprints = gql`
    query MapFootprints ($frame: geometry, $length: float8) {
        raw_cadastre_wurzen(where: {geom: {_st_intersects: $frame}}) {
            w
            zoning
            prices
            ogc_fid
            nationalca
            label
            landuse
            id_localid
            id_namespa
            geom
            beginlifes
            area_m2int
            admin_unit
            admin_code
        }
        raw_road(where: {geom: {_st_intersects: $frame}, _and: {length: {_gt: $length}}}) {
            geom
            highway
            maxspeed
            id
        }
        raw_exists_nurshome_wurzen(where: {geom: {_st_intersects: $frame}}) {
            address
            building
            geom
            height
            id
            osmid
            unique_id
        }
        raw_cadastre_wurzen_aggregate {
            aggregate {
                min {
                    w
                }
                max {
                    w
                }
                count
            }
        }
    }
`
