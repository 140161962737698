import React, {useState, useEffect, useReducer, useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Slider} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    title: {
        margin: 0,
        padding: 0
    },
}));


const FilterSlider = (props) => {
    const classes = useStyles();

    const {field, timeseries, value: currentValue, onChange, onResetScalars} = props

    const [values, setValues] = useState(currentValue)
    const interpretValues = (val) => ({
        value: val,
        naturalValue: val.map(v => timeseries[v])
    })

    return (
        <div className={classes.root}>
            <h5 className={classes.title}>{field.title}</h5>
            {/*
                <InputLabel>
                    {field.title}
                </InputLabel>
*/}
            <Slider
                value={values.value}
                onChange={(e, val) => {
                    setValues(interpretValues(val))
                }}
                onChangeCommitted={(e, val) => {
                    if (onChange) onChange(interpretValues(val))
                    if (onResetScalars) onResetScalars()
                }}
                valueLabelDisplay="auto"
                step={null}
                min={0}
                max={timeseries.length - 1}
                marks={timeseries ? timeseries.map((ts, i) => ({value: i})) : []}
                aria-labelledby="range-slider"
                // getAriaValueText={value => timeseries[value]}
                valueLabelFormat={value => timeseries[value]}
            />

        </div>
    )
}

export default FilterSlider;