import { gql } from "@apollo/client";

export const ProjectCreate = gql`
    mutation ProjectCreate ($project: CreateProjectModel!, $geom: GeomModel!) {
        projectCreate(
            project: $project,
            geom: $geom
        ) {
            project_id
            project_key
        }
    }
`;

export const SetProjectCurrentStep = gql`
    mutation SetProjectCurrentStep ($project_id: uuid!, $current_step_id: uuid!) {
        updateProject(pk_columns: {id: $project_id}, _set: {current_step_id: $current_step_id}) {
            current_step_id
            currentStep {
                datasets
                dataset_items
                dataviews
                layers
                filters
                metrics
                algorithm
            }
        }
    }
`

export const ProjectQuery = gql`
    query Project ($project_id: uuid!) {
        project(id: $project_id) {
            meta {
                about
                title
            }
            steps(order_by: {ordering: asc}) {
                id
                title
                about
                ordering
            }
            currentStep {
                id
                title
                about
                ordering
                metrics
                layers
                filters
                dataviews
                datasets
                dataset_items
                algorithm
            }
            scale
            focus_geom
            geom
            id
        }
    }
`;