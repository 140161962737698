import React, {useContext} from "react";
import {PopupStateContext, AuthStateContext} from '../reducers'
import {logOut} from "../actions/auth";
import {Divider, IconButton, Button, Menu, MenuItem, Toolbar} from "@material-ui/core";
import {AccountCircle, ExitToApp} from "@material-ui/icons";
import { useTranslation } from 'react-i18next';
import {resources} from '../i18n'


const LangButton = (props) => {
    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const languages = Object.keys(resources)

    return (
        <div>
            <Button
                aria-label="account of current user"
                aria-controls="topbar-menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                size="small"
                style={{padding: '4px', minWidth: '25px', margin: '0 0 0 10px'}}
            >
                {i18n.language}
            </Button>
            <Menu
                id="topbar-menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                {
                    languages.map((lang) => (
                        <MenuItem selected={lang === i18n.language} onClick={() => {
                            i18n.changeLanguage(lang)
                            handleClose()
                        }}>{lang}</MenuItem>
                    ))
                }
            </Menu>
        </div>
    )
}

export default LangButton