import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
// import { RestLink } from "apollo-link-rest";
import { appConfig } from '../config'
import { getToken } from '../actions/auth'

const defaultOptions = {
  query: {
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache'
  },
};

const link = createHttpLink({
  uri: appConfig.api.graphql,
  // credentials: 'same-origin',
});

const applicationLink = setContext((_, { headers }) => {
  const token = getToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: applicationLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions,
});

// const restLink = new RestLink({
//   uri: appConfig.api.data,
// });
//
// export const restClient = new ApolloClient({
//   link: restLink,
//   cache: new InMemoryCache(),
//   defaultOptions,
// });

export default client;
