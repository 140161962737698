import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        fill: 'currentColor', // #4E4F54
        // stroke: 'currentColor', // #4E4F54
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.3rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none'
    }
}));

const HeatmapIcon = (props) => {
    const classes = useStyles();

    return (
        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg"
             {...props}
             className={classes.root}
        >
            <path
                d="M9.42346 6.57019C11.119 5.50683 11.1555 3.78249 10.8572 2.66027C10.559 1.53804 9.77401 0.588812 8.5024 0.190055C6.80692 -0.34162 6.05752 0.374978 5.44384 0.72173C4.83016 1.06848 4.04077 0.721731 2.56818 0.721731C-0.612929 1.0579 -1.09086 5.86501 2.56818 6.39913C4.16627 6.57019 4.88857 6.51539 6 7.04279C6 7.04279 7.72797 7.63354 9.42346 6.57019Z"
                />
            <path
                d="M9 5.54279C10.5 4.54279 10 2.54278 9.5 2.04279C9 1.54279 8.92481 1.32761 8 1.04278C6.76692 0.663016 6.19458 1.15735 5.5 1.54277C4.80542 1.9282 3.91169 1.44816 3.00005 1.54277C0.686513 1.78289 0.20667 5.2015 2.86779 5.58302C4.03004 5.7052 4.35682 5.58302 6 6.04279C6 6.04279 7.5 6.54279 9 5.54279Z"
                fill="#898C9E"/>
            <path
                d="M3.5 2.04279C2.67125 2.04279 2 2.71404 2 3.54279C2 4.37154 2.67125 5.04279 3.5 5.04279C4.32875 5.04279 5 4.37154 5 3.54279C5 2.71404 4.32875 2.04279 3.5 2.04279Z"
                fill="#C1C5CF"/>
        </svg>
    )
};

export default HeatmapIcon;