import React, {useState, useEffect, useReducer, useContext} from "react";
import FilterFabric from "../../../pages/project/map/components/map/filters/filter-fabric";
import {DataStateContext, MapStateContext} from "../../../pages/project/map/reducer";
import {makeStyles} from "@material-ui/core/styles";
import Scrollbar from "react-perfect-scrollbar";
import {
    Grid,
    Chip,
    Paper,
    Divider,
    InputLabel,
    Tooltip,
    Input,
    Button,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    TableContainer, Table, TableRow, TableCell, Typography, Slider,
} from "@material-ui/core";
import {
    AddCircleOutline,
    Close
} from '@material-ui/icons'
import {Avatar} from "@material-ui/core";
import {getDB} from "../../../db";
import {operandKey} from "../../../helpers/alg";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 280,
        background: '#D9DFE4',
        borderRadius: 0,
        padding: theme.spacing(1),
        boxSizing: 'border-box'
    },
    selectMetric: {
        width: 100
    },
    selectField: {
        width: 80
    },
    title: {
        boxSizing: 'border-box',
        padding: '1rem 10px',
        margin: '0'
    },
    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    selectObjectType: {
        width: 260
    },

    fieldChip: {
        display: 'inline-block',
        maxWidth: 120,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '12px',
        lineHeight: '32px',
    },

    operandRow: {
        margin: '2px 0'
    },

    inputControl: {
        width: '100%',
        textAlign: 'right'
    },

    addItemButton: {
        paddingTop: '1rem',
        width: '100%',
    },

    controlDivider: {
        margin: '1rem 0'
    },

    selectFieldPlace: {
        marginLeft: 10
    },

    lineWidthControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));

const GravityIndex = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        struct,
        params,
        objectType: currentObjectTypeId,
        onChangeParams,
    } = props;

    const {dataState, dataDispatch} = useContext(DataStateContext)
    const {mapState, mapDispatch} = useContext(MapStateContext)

    const {
        objectType
        // metric: metrics,
        // cube: cubes,
    } = dataState;

    const defaultOperands = mapState.algorithmParams && mapState.algorithmParams?.operands
        ? mapState.algorithmParams?.operands
        : {}
    const [operands, setOperands] = useState(
        defaultOperands
    )

    useEffect(() => {
        if (currentObjectTypeId) {
            setOperands(defaultOperands)
        }
    }, [currentObjectTypeId])

    const availableObjectTypes = Object.values(objectType)
    const [selectedObjectTypeId, setSelectedObjectTypeId] = useState(mapState.algorithmObjectType)
    const selectedObjectType = availableObjectTypes.find(ot => ot.id === selectedObjectTypeId)
    const [availableObjects, setAvailableObjects] = useState(null)

    useEffect(() => {
        if (selectedObjectType) Promise.resolve(getDB())
            .then(DB => DB.table(selectedObjectType?.name))
            .then(table => table.toArray())
            .then(array =>{
                setAvailableObjects(array)
            })
    }, [selectedObjectType])

    const [selectedTag, setSelectedTag] = useState(null)
    const [availableTags, setAvailableTags] = useState({})
    useEffect(() => {
        const accAvailableTags = {}

        // eslint-disable-next-line no-unused-expressions
        availableObjects?.forEach(obj => {
            obj.tags && Object.entries(obj.tags).forEach(([tag, value]) => {
                if (tag in accAvailableTags) {
                    if (!accAvailableTags[tag][value]) {
                        accAvailableTags[tag][value] = (Array.isArray(value) ? value.join(', ') : value).toString().toLowerCase()
                    }
                } else {
                    accAvailableTags[tag] = {
                        [value]: (Array.isArray(value) ? value.join(', ') : value).toString().toLowerCase()
                    }
                }
            })
        })

        setAvailableTags(accAvailableTags)
        setSelectedTag(null)

    }, [availableObjects])


    const selectedTagValues = availableTags && selectedTag && selectedTag in availableTags
        ? availableTags[selectedTag]
        : []

    const [selectedTagValue, setSelectedTagValue] = useState(null)

    // const [selectedFieldName, setSelectedFieldName] = useState(null)
    // const selectedMetric = Object.values(metrics).find(met => met.id === selectedMetricId)
    // const selectedCube = cubes[selectedMetricId]
    //
    // const availableMetrics = Object.values(metrics).filter(metric => metric.object.id === objectType)
    // const availableFields = selectedCube?.struct?.fields.filter(fld => !fld.is_key)
    //
    // const selectedField = availableFields?.find(fld => fld.name === selectedFieldName)

    const handleAddOperand = () => {
        setOperands(oldOperands => ({
            ...oldOperands,
            [operandKey(selectedObjectTypeId, selectedTag, selectedTagValue)]: {
                tag: selectedTag,
                value: selectedTagValue,
                objectType: selectedObjectType,
                weight: 1
            }
        }))

        setSelectedTag(null)
        setSelectedTagValue(null)
    }

    const handleChangeWeight = (key, value) => {
        const weight = parseFloat(value)

        if (!Number.isNaN(weight)) {
            setOperands(oldOperands => ({
                ...oldOperands,
                [key]: {
                    ...oldOperands[key],
                    weight
                }
            }))
        }
    }

    const handleDeleteOperand = (key) => {
        setOperands(oldOperands => (Object.keys(oldOperands)
            .filter(existKey => existKey !== key)
            .reduce((operands, opKey) => ({
                ...operands,
                [opKey]: oldOperands[opKey]
            }), {})))
    }

    const MIN_RADIUS = 500;
    const MAX_RADIUS = 5000;
    const [gravityRadius, setGravityRadius] = useState(
        mapState.algorithmParams?.gravityRadius
            ? mapState.algorithmParams?.gravityRadius
            : MIN_RADIUS
    );

    useEffect(() => {
        if (onChangeParams) {
            onChangeParams({
                operands,
                gravityRadius
            })
        }
    }, [operands, gravityRadius])

    const canAddOperand = selectedTag && selectedTagValue

    return (
        <div>

            <div className={classes.lineWidthControl}>
                <Typography id="non-linear-slider" gutterBottom>
                    {t('project.leftbar.panel5.gravity')}
                </Typography>

                <Slider
                    value={gravityRadius}
                    min={MIN_RADIUS}
                    step={100}
                    max={MAX_RADIUS}
                    getAriaValueText={(value) => (value + 'm')}
                    valueLabelFormat={(value) => (value + 'm')}
                    onChange={(e, value) => {
                        setGravityRadius(value)
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                />
            </div>

            {
                Object.values(operands).length > 0 &&
                <div>
                    <Scrollbar>

                        <Grid container className={classes.operandRow}>
                            <Grid item xs={8}>
                                {t('project.leftbar.panel5.metric_and_field')}
                            </Grid>
                            <Grid item xs={4}>
                                {t('project.leftbar.panel5.weight')}
                            </Grid>
                        </Grid>
                        {
                            Object.entries(operands).map(((operandKeyPair) => {
                                const [key, operand] = operandKeyPair
                                return (
                                    <Grid container key={key} className={classes.operandRow}>
                                        <Grid item xs={2}>
                                            <Tooltip title={operand.tag}>
                                                <Avatar style={{
                                                    // backgroundColor: operand.metric.color
                                                }} className={classes.litera}>{operand.tag[0]}</Avatar>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title={operand.value}>
                                                <span className={classes.fieldChip}>{operand.value}</span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Input
                                                type="number"
                                                className={classes.inputControl}
                                                value={operand.weight}
                                                onChange={(e) => handleChangeWeight(key, e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" onClick={() => handleDeleteOperand(key)}>
                                                <Close style={{fontSize: 14}}/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            }))
                        }
                    </Scrollbar>
                </div>
            }
            <Divider className={classes.controlDivider}/>
            <div>
                <FormControl>
                    <InputLabel>{t('project.leftbar.panel5.object_type')}:</InputLabel>
                    <Select
                        value={selectedObjectTypeId}
                        onChange={(e) => setSelectedObjectTypeId(e.target.value)}
                        className={classes.selectObjectType}
                    >
                        <MenuItem value="" />
                        {
                            availableObjectTypes.map((objectType, mi) => (
                                <MenuItem key={mi} value={objectType.id}>{objectType.meta?.title}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {selectedObjectType && availableTags && Object.keys(availableTags).length > 0 &&
                <div>
                    <FormControl>
                        <InputLabel>{t('project.leftbar.panel5.tag')}:</InputLabel>
                        <Select
                            value={selectedTag}
                            onChange={(e) => setSelectedTag(e.target.value)}
                            className={classes.selectMetric}
                        >
                            <MenuItem value=""></MenuItem>
                            {
                                Object.keys(availableTags).sort().map((tag, mi) => (
                                    <MenuItem key={mi} value={tag}>{tag}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl className={classes.selectFieldPlace}>
                        <InputLabel>{t('project.leftbar.panel5.field')}:</InputLabel>
                        <Select
                            value={selectedTagValue}
                            onChange={(e) => setSelectedTagValue(e.target.value)}
                            className={classes.selectField}
                        >
                            <MenuItem value=""></MenuItem>
                            {selectedTagValues &&
                            Object.keys(selectedTagValues).sort().map((tagKey, fi) => (
                                <MenuItem key={fi} value={tagKey}>{selectedTagValues[tagKey]}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl className={classes.addItemButton}>
                        <Button
                            color="primary"
                            startIcon={<AddCircleOutline />}
                            disabled={!canAddOperand}
                            onClick={(e) => handleAddOperand()}
                        >
                            {t('project.leftbar.panel5.add')}
                        </Button>
                    </FormControl>
                </div>
                }
            </div>
        </div>
    )

}
export default GravityIndex;