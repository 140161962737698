import React, {useContext, useEffect, useState} from "react";
import {DataStateContext, MapStateContext, MapStyleContext} from "../../reducer";
import {makeStyles} from "@material-ui/core/styles";
import {
    Tabs, Tab, Avatar,
    FormControl, InputLabel, Select, MenuItem,
    Grid
} from "@material-ui/core";

import {
    MetricTable, MetricStatistics, MetricPies,
    MetricHistograms, MetricBarCharts,
} from './widgets'
import {useTranslation} from "react-i18next";
import {isFieldObject, isFieldScalar, isFieldTimestamp, isFieldUUID} from "../../../../../helpers/field";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '60px 0 0 0'
    },

    dataSelector: {
        padding: '0 16px',
        margin: '16px 0',
        boxSizing: 'border-box',
        width: '100%'
    },

    dataSelectorTitle: {
        marginTop: 4,
        marginBottom: 0
    },

    dataSelectorSelect: {
        width: '100%'
    },

    dataSelectorItemObject: {
        display: 'inline-block',
        margin: '0 8px 0 0',
        fontSize: '18px',
        color: '#808080'
    },
    dataSelectorItemMetric: {
        fontSize: '18px',
        color: '#808080'
    },

    tabsPanel: {

    },

    litera: {
        display: 'inline-block',
        width: theme.spacing(2),
        height: theme.spacing(2),
        fontSize: 10,
        borderRadius: '50%',
        marginRight: 4,
        color: '#fff'
    },

}));



const ChartTabObject = (index, title, isAvailable, component) => ({
    index,
    title,
    isAvailable,
    component,
});



export default function Data(props) {
    const { t } = useTranslation();

    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {mapStyle, mapStyleDispatch} = useContext(MapStyleContext)
    const {dataState, dataDispatch} = useContext(DataStateContext)

    const classes = useStyles();
    const {metric, cube} = dataState
    const [activeTab, setActiveTab] = useState(0)

    const [availableMetrics, setAvailableMetrics] = useState([])
    const [availableTabs, setAvailableTabs] = useState([])
    const [activeMetric, setActiveMetric] = useState(null)

    useEffect(() => {
        setAvailableMetrics(Object
            .values(metric)
            .filter(metricItem => !!cube[metricItem.id])
            .reduce((metrics, metricItem) => {
                return metrics.concat([{
                    ...metricItem,
                    cube: cube[metricItem.id]
                }])
            }, []))

    }, [metric])

    useEffect(() => {

        if (!activeMetric) return;

        const tabs = [
            ChartTabObject(0, t('project.data.chart.table.title'), true, <MetricTable metric={activeMetric} />),
            ChartTabObject(1, t('project.data.chart.statistics.title'), true, <MetricStatistics metric={activeMetric} />),

            // ChartTabObject(2, t('project.data.chart.barchart.title'), true),
            // ChartTabObject(3, t('project.data.chart.timeseries.title'), true),
            // ChartTabObject(5, t('project.data.chart.grids.title'), true),
            // ChartTabObject(6, t('project.data.chart.comparation.title'), true),
        ]

        const hasDicts = activeMetric?.cube?.struct?.fields?.filter(item => isFieldUUID(item) && !isFieldObject(item))?.length > 0
        const hasTimeSeries = activeMetric?.cube?.struct?.fields?.filter(item => isFieldTimestamp(item))?.length > 0
        const hasScalars = activeMetric?.cube?.struct?.fields?.filter(item => isFieldScalar(item))?.length > 0

        const isPieChart = hasDicts
        const isHistogramChars = hasScalars && hasTimeSeries
        const isBarCharts = hasScalars && hasDicts

        if (isPieChart) tabs.push(
            ChartTabObject(2, t('project.data.chart.pies.title'), true, <MetricPies  metric={activeMetric} />)
        )

        if (isHistogramChars) tabs.push(
            ChartTabObject(3, t('project.data.chart.histogram.title'), true, <MetricHistograms  metric={activeMetric} />)
        )

        if (isBarCharts) tabs.push(
            ChartTabObject(3, t('project.data.chart.barcharts.title'), true, <MetricBarCharts  metric={activeMetric} />)
        )

        setAvailableTabs(tabs)
        setActiveTab(0)

    }, [activeMetric])

    useEffect(() => {
        if (!activeMetric && availableMetrics.length > 0) {
            setActiveMetric(availableMetrics[0])
        }
    }, [availableMetrics])


    const handleChangeMetric = (event) => {
        setActiveMetric(availableMetrics.find(metric => metric.id === event.target.value))
    };

    const isReady = availableMetrics.length > 0 && !!activeMetric

    if (!isReady) {
        return null
    }

    return (
        <div className={classes.root}>

            <FormControl className={classes.dataSelector}>
                <Grid container spacing={4}>
                    <Grid item>
                        <h3 className={classes.dataSelectorTitle}>{t('project.data.metric')}</h3>
                    </Grid>
                    <Grid item xs>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={activeMetric.id}
                            onChange={handleChangeMetric}
                            className={classes.dataSelectorSelect}
                        >
                            {availableMetrics.map((metric, idx) => {
                                return (
                                    <MenuItem value={metric.id} selected={activeMetric?.id === metric.id}>
                                        <span className={classes.dataSelectorItemObject}>{metric.object?.meta?.title}</span>
                                        <span className={classes.dataSelectorItemMetric}> / {metric.title}</span>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                </Grid>
            </FormControl>

            <Tabs
                value={activeTab}
                onChange={(e, val) => setActiveTab(val)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className={classes.tabsPanel}
            >
                {availableTabs.map((tab, i) => (
                    <Tab key={i} label={
                        <span>{tab.title}</span>
                    } />
                ))}
            </Tabs>

            {!!activeMetric && availableTabs[activeTab]?.component}

            {/*
            <Tabs
                value={activeTab}
                onChange={(e, val) => setActiveTab(val)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className={classes.tabsPanel}
            >
                {availableMetrics.map((metricItem, i) => {

                    // return (
                    //     <Tab key={i} label={`${metricItem.litera} ${metricItem.title}`} {...tabProps(i)} />
                    // )

                    return (
                        <Tab key={i} label={
                            <span>
                                <span style={{
                                    backgroundColor: metricItem.color
                                }} className={classes.litera}>{metricItem.litera}</span>
                                {metricItem.title}
                            </span>
                                // `${metricItem.litera} ${metricItem.title}`
                        } {...tabProps(i)} />
                    )
                })}
            </Tabs>
*/}
        </div>
    )
}