import React from "react";


import  {
    MAP_CHANGE_LAYER
} from '../actions/map'


export const initialMapState = {
    layerId: 1,
    datasets: {}
};

const mapReducer = function (state = initialMapState, action) {
    switch (action.type) {
        case MAP_CHANGE_LAYER:
            return {
                ...state,
                layerId: action.payload.layerId,
            };

        case 'setDatasets':
            return {
                ...state,
                datasets: action.datasets.reduce((acc, cur) => ({
                    ...acc,
                    [cur.id]: cur
                }), {})
            }

        default:
            return state;
    }
}
export default mapReducer;
