import React, {useState, useEffect, useContext} from "react";
import {DataStateContext} from "../../../pages/project/map/reducer";
import {makeStyles} from "@material-ui/core/styles";
import Scrollbar from "react-perfect-scrollbar";
import {
    Grid,
    Divider,
    InputLabel,
    Tooltip,
    Input,
    Button,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    Typography, Slider,
} from "@material-ui/core";
import {
    Close,
    AddCircle,
    AddCircleOutline
} from '@material-ui/icons'
import {Avatar} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {isFieldObject, isFieldUUID} from '../../../helpers/field'
import {getCubeDicts} from '../../../helpers/data'

const useStyles = makeStyles((theme) => ({
    root: {
        width: 280,
        background: '#D9DFE4',
        borderRadius: 0,
        padding: theme.spacing(1),
        boxSizing: 'border-box'
    },
    selectMetric: {
        width: 100
    },
    selectField: {
        width: 80
    },
    selectValueField: {
        width: 190
    },
    selectValue: {
        width: '100%'
    },
    title: {
        boxSizing: 'border-box',
        padding: '1rem 10px',
        margin: '0'
    },
    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    fieldChip: {
        display: 'inline-block',
        maxWidth: 120,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '12px',
        lineHeight: '32px',
    },

    operandRow: {
        margin: '2px 0'
    },

    inputControl: {
        width: '100%',
        textAlign: 'right'
    },

    addItemButton: {
        paddingTop: '1rem',
        width: '100%',
    },

    controlDivider: {
        margin: '1rem 0'
    },

    selectFieldPlace: {
        marginLeft: 10
    },

    lineWidthControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));

const metricFieldKey = (metricId, fieldName, fieldValue) => `${metricId}:${fieldName}:${fieldValue}`;

const WeightedIndex = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        struct,
        params,
        objectType,
        onChangeParams,
    } = props;

    const {dataState, dataDispatch} = useContext(DataStateContext)

    const {
        metric: metrics,
        cube: cubes,
    } = dataState;


    const [operands, setOperands] = useState({})

    const [selectedMetricId, setSelectedMetricId] = useState(null)
    const [selectedFieldName, setSelectedFieldName] = useState(null)
    const [selectedValue, setSelectedValue] = useState(null)

    const selectedMetric = Object.values(metrics).find(met => met.id === selectedMetricId)
    const selectedCube = cubes[selectedMetricId]
    const cubeDicts = getCubeDicts(selectedCube)

    const availableMetrics = Object.values(metrics);  // Object.values(metrics).filter(metric => metric.object.id === objectType)
    const availableFields = selectedCube?.struct?.fields.filter(fld => !isFieldObject(fld))
    const selectedField = availableFields?.find(fld => fld.name === selectedFieldName)


    const isDictField = selectedField && cubeDicts && cubeDicts[selectedFieldName]
    const availableDictValues = isDictField ? cubeDicts[selectedFieldName] : {}

    const handleAddOperand = () => {
        setOperands(oldOperands => ({
            ...oldOperands,
            [metricFieldKey(selectedMetricId, selectedFieldName, selectedValue)]: {
                field: selectedField,
                metric: selectedMetric,
                value: isDictField
                    ? {title: availableDictValues[selectedValue], id: selectedValue}
                    : selectedValue,
                is_dict: isDictField,
                weight: 1
            }
        }))

        setSelectedFieldName(null)
        setSelectedMetricId(null)
        setSelectedValue(null)
    }

    const handleChangeWeight = (key, value) => {

        const weight = parseFloat(value)

        if (!Number.isNaN(weight)) {
            setOperands(oldOperands => ({
                ...oldOperands,
                [key]: {
                    ...oldOperands[key],
                    weight
                }
            }))
        }


    }

    const handleDeleteOperand = (key) => {
        setOperands(oldOperands => (Object.keys(oldOperands)
            .filter(existKey => existKey !== key)
            .reduce((operands, opKey) => ({
                ...operands,
                [opKey]: oldOperands[opKey]
            }), {})))
    }

    const MIN_RADIUS = 500;
    const MAX_RADIUS = 5000;
    const [gravityRadius, setGravityRadius] = useState(MIN_RADIUS);

    useEffect(() => {
        if (onChangeParams) {
            onChangeParams({
                operands,
                gravityRadius
            })
        }
    }, [operands, gravityRadius])

    const canAddOperand = selectedFieldName && selectedMetricId

    return (
        <div>
            <div className={classes.lineWidthControl}>
                <Typography id="non-linear-slider" gutterBottom>
                    {t('project.leftbar.panel5.gravity')}
                </Typography>

                <Slider
                    value={gravityRadius}
                    min={MIN_RADIUS}
                    step={100}
                    max={MAX_RADIUS}
                    getAriaValueText={(value) => (value + 'm')}
                    valueLabelFormat={(value) => (value + 'm')}
                    onChange={(e, value) => {
                        setGravityRadius(value)
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                />
            </div>

            {
                Object.values(operands).length > 0 &&
                <div>
                    <Scrollbar>
                        <Grid container className={classes.operandRow}>
                            <Grid item xs={8}>
                                {t('project.leftbar.panel5.metric_and_field')}
                            </Grid>
                            <Grid item xs={4}>
                                {t('project.leftbar.panel5.weight')}
                            </Grid>
                        </Grid>
                        {
                            Object.entries(operands).map(((operandKeyPair) => {
                                const [key, operand] = operandKeyPair
                                const fieldTitle = `${operand.field.title}${(operand.is_dict && !!operand.value) ? ' / ' + operand.value.title : ''}`
                                return (
                                    <Grid container key={key} className={classes.operandRow}>
                                        <Grid item xs={2}>
                                            <Tooltip title={operand.metric.metric.meta.title}>
                                                <Avatar style={{
                                                    backgroundColor: operand.metric.color
                                                }} className={classes.litera}>{operand.metric.litera}</Avatar>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title={fieldTitle}>
                                                <span className={classes.fieldChip}>
                                                    {fieldTitle}
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Input
                                                type="number"
                                                className={classes.inputControl}
                                                value={operand.weight}
                                                onChange={(e) => handleChangeWeight(key, e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" onClick={() => handleDeleteOperand(key)}>
                                                <Close style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            }))
                        }
                    </Scrollbar>
                </div>
            }
            <Divider className={classes.controlDivider} />
            <div>
                <div>
                    <FormControl>
                        <InputLabel>{t('project.leftbar.panel5.object_type')}:</InputLabel>
                        <Select
                            value={selectedMetricId}
                            onChange={(e) => setSelectedMetricId(e.target.value)}
                            className={classes.selectMetric}
                        >
                            <MenuItem value="" />
                            {
                                availableMetrics.map((metric, mi) => (
                                    <MenuItem key={mi} value={metric.metric?.id}>
                                        {`${metric.object?.meta?.title} / ${metric.metric?.meta?.title}`}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl className={classes.selectFieldPlace}>
                        <InputLabel>{t('project.leftbar.panel5.field')}:</InputLabel>
                        <Select
                            value={selectedFieldName}
                            onChange={(e) => setSelectedFieldName(e.target.value)}
                            className={classes.selectField}
                        >
                            <MenuItem value="" />
                            {
                                availableFields?.map((field, fi) => (
                                    <MenuItem key={fi} value={field.name}>{field.title}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    {isDictField &&
                    <FormControl className={classes.selectValue}>
                        <InputLabel>{t('project.leftbar.panel5.value')}:</InputLabel>
                        <Select
                            value={selectedValue}
                            onChange={(e) => setSelectedValue(e.target.value)}
                            className={classes.selectValueField}
                        >
                            <MenuItem value="" />
                            {
                                Object.entries(availableDictValues)?.map(([value, title], fi) => (
                                    <MenuItem key={fi} value={value}>{title}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    }

                    <FormControl className={classes.addItemButton}>
                        <Button
                            color="primary"
                            startIcon={<AddCircleOutline />}
                            disabled={!canAddOperand}
                            onClick={(e) => handleAddOperand()}>
                            {t('project.leftbar.panel5.add')}
                        </Button>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export default WeightedIndex;