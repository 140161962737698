import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Tabs,
    Tab
} from '@material-ui/core';

import {
    Public,
    ScatterPlot,
    LocationCity
}
from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),

        },
    },
    formRow: {
        marginBottom: theme.spacing(2)
    }
}));

export default function ScaleStep(props) {
    const classes = useStyles();
    const { projectData, onChange } = props
    const { project: { scale } } = projectData;

    return (
            <div className={classes.root}>
                <Tabs
                    value={scale}
                    onChange={(e, newValue) => {
                        onChange({
                            project: {
                                scale: newValue
                            }
                        })
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="City scale" icon={<LocationCity />}/>
                    <Tab label="Region scale" icon={<ScatterPlot />} />
                    <Tab label="Country scale" icon={<Public />} />
                </Tabs>
            </div>
    );
}