import {getDB} from "../db";
import {isFieldObject, isFieldUUID} from "../helpers/field";


export async function prepareLayerCollection({layer, cube, filters, heightCube}) {

    const hasFilters = !!filters && filters?.length > 0

    const DB = await getDB();

    const isColorMetric = layer.colorMetricId && layer.colorMetricField
    const colorFilters = layer.colorMetricFilters
    const isHeightMetric = layer.height?.metricId && layer.height?.field
    const heightJoinKey = heightCube?.struct.fields.find(item => item.is_object)
    const heightJoinKeyName = heightJoinKey?.name

    const dataTable = await DB.table(layer.object.key)
    const joinKey = cube?.struct.fields.find(item => item.is_object)
    const joinKeyName = joinKey?.name

    const hasColorMetric = isColorMetric && joinKey
    const hasHeightMetric = isHeightMetric && heightJoinKey

    const metricField = cube?.struct.fields.find(item => item.name === layer.colorMetricField)
    const isMetricFieldDict = metricField && isFieldUUID(metricField) && !isFieldObject(metricField)

    if (!dataTable) {
        return null
    }

    let collection = dataTable.toCollection()

    if (hasColorMetric) {
        collection = collection.joinFields({
            rootKey: 'id',
            tableKey: joinKeyName,
            tableName: cube.name,
            tableFields: {
                [layer.colorMetricField]: 'metricValue'
            },
            aggregateFunction: isMetricFieldDict ? null : 'avg',
            filters: colorFilters
        })
    }

    if (hasHeightMetric) {
        collection = collection.joinFields({
            rootKey: 'id',
            tableKey: heightJoinKeyName,
            tableName: heightCube.name,
            tableFields: {
                [layer.height?.field]: 'heightMetricValue'
            },
            aggregateFunction: 'avg',
        })
    }

    if (hasFilters) {
        filters.forEach(filter => {
            collection = collection.filter({
                rootKey: 'id',
                tableKey: filter.cube_object,
                tableName: filter.cube_name,
                filterFields: filter.fields
            })
        })
    }

    return collection
}