import {v4 as uuidV4} from 'uuid'
import {arrayMin, arrayMax, sum, mean, std, quantile} from '../helpers/stats'


const calcFieldStats = (field, arr) => {

    const quantiles = [3, 4, 5, 6, 7, 8, 9, 10, 11, 20].reduce((quantiles, i) => {

        let currentQuantiles = {}
        for (let j = 1; j < i; j++) {
           currentQuantiles[`q_${i}_${j}`] = quantile(arr, j / i)
        }

        return {
            ...quantiles,
            ...currentQuantiles
        }
    }, {})

    return {
        min: arrayMin(arr),
        max: arrayMax(arr),
        avg: mean(arr),
        sum: sum(arr),
        quantiles
    }
}

const calcStats = (props, data) => {
    const dataFields = props?.struct?.fields.filter(fld => !fld.is_key)

    return dataFields.reduce((stats, field) => {
        return {
            ...stats,
            [field.name]: calcFieldStats(field, data.map(row => row[field.name]))
        }
    }, {})
}

const cubeDefaults = {
    count: 0,
    ver: 1,
    dicts: [],
    stats: {},
    struct: {}
}

export const CubeModel = (props, data) => {
    let { id, src_id } = props
    const count = data ? data.length : 0

    if (!src_id) {
        src_id = 0
    }

    const cubeId = id ? id : uuidV4()

    return {
        ...cubeDefaults,
        ...props,
        id: cubeId,
        name: `src${src_id}_cube_${cubeId}_met`,
        stats: data ? calcStats(props, data) : {},
        count,
    }
}

