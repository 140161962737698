import React, {useState, useEffect, useReducer, useContext} from "react";
import {MapStateContext, DataStateContext, CacheStateContext} from "../../reducer";
import {FormControl, InputLabel, MenuItem, Paper, Select, Typography, ListSubheader} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useQuery} from "@apollo/client";
import {useSnackbar} from 'notistack'

import {ListAlgorithms} from "../../../../../actions/gql/alg";
import apolloClient from "../../../../../clients/apolloClient";
import {CalculateStats} from '../../../../../actions/app/data'
import Button from "@material-ui/core/Button";
import {
    Grid,
    Input,
    CircularProgress
} from "@material-ui/core";
import {getAlgorithm, getAlgorithms} from '../../../../../alg'
import {getDB, AddTable, AddTableParquet, FillTable} from '../../../../../db'
import {useTranslation} from "react-i18next";
import {MetricModel} from "../../../../../models/metric";
import {CubeModel} from "../../../../../models/cube";
import {DEFAULT_LAYER_TYPE, LayerModel} from "../../../../../models/layer";
import {ApiEndpoint} from "../../../../../config";


const useStyles = makeStyles((theme) => ({
    root: {
        width: 280,
        background: '#F5FAFF',
        borderRadius: 0,
        padding: theme.spacing(1),
        boxSizing: 'border-box'
    },
    title: {
        boxSizing: 'border-box',
        padding: '1rem 10px',
        margin: '0'
    },
    subtitle: {
        fontSize: '10pt',
        margin: '0 0 10px 0'
    },

    algAbout: {},
    filterButton: {
        display: 'inline-block',
        float: 'right',
        transform: 'translate(12px, -6px)',
    },

    selectControl: {
        width: 260
    },

    inputControl: {
        width: 260
    },

    selectAlgPanel: {
        margin: '1rem 0'
    },

    selectAlgControl: {
        margin: '0 0 10px 0',
        minWidth: 150,
    },
    selectAlgOperandControl: {
        margin: '0 0 10px 0',
        minWidth: 150,
    },
    executeButtonContainer: {
        boxSizing: 'border-box',
        padding: theme.spacing(1),
        textAlign: 'center',
        position: 'relative',
        marginTop: '24px',
        background: '#D9DFE4',
    },

    executeButton: {
        background: '#F5FAFF',
    },

    buttonProgress: {
        // color: '#00cc00',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }

}));

const parquetApi = new ApiEndpoint('parquet')

export default function LayerAlg(props) {
    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {dataState, dataDispatch} = useContext(DataStateContext)

    const {t} = useTranslation();

    const {
        onExecute
    } = props


    const {
        project,
        metric: dataMetrics,
        filter: dataFilters,
        cube: dataCubes,
        objectType: dataObjectTypes,
    } = dataState;

    const metricObjectTypes = Object.values(dataMetrics).reduce((types, item) => {
        return {
            ...types,
            [item.object.id]: item.object
        }
    }, {})

    // const {data: algData, loading: projectLoading, error} = useQuery(ListAlgorithms, {
    //     client: apolloClient,
    //     variables: {},
    //     // skip: !isNotBlank,
    // });

    // useEffect(() => {
    //     if (algData) {
    //         const {listAlgorithms: {algorithms}} = algData;
    //         mapDispatch({
    //             type: 'setAlgorithms',
    //             algorithms
    //         })
    //     }
    // }, [algData])

    const {
        algorithm,
        // algorithms,
        algorithmParams,
        algorithmMetricId,
        algorithmObjectType,
        algorithmMetricTitle,
        isCalculating,
        layers: existLayers,
    } = mapState;

    const algorithms = getAlgorithms()

    const handleAlgSelect = (e) => {
        mapDispatch({
            type: 'setAlgorithm',
            algorithm: e.target.value
        })
    }

    const handleSaveHistory = () => {
        mapDispatch({
            type: 'addAlgorithmToHistory',
            item: {}
        })
    }

    const handleClearForm = () => {
        mapDispatch({
            type: 'resetAlgorithmForm',
        })

    }

    const handleAlgChangeParams = (params) => {
        mapDispatch({
            type: 'setAlgorithmParams',
            params,
        })
    }

    const algorithmInstance = getAlgorithm(algorithm)

    const handleAlgExecute = async (e) => {

        mapDispatch({
            type: 'setCalculating',
            isCalculating: true
        })

        const metricId = algorithmMetricId
        const existMetric = metricId ? dataMetrics[metricId] : null
        const metricLayer = metricId
            ? Object.values(existLayers).find(item =>
                Array.isArray(item.metrics) && item.metrics.find(metricItem => metricItem.id === metricId))
            : null

        const title = algorithmMetricTitle
        const filters = dataFilters

        const objectTypeMetric = metricObjectTypes[algorithmObjectType]
        const calcParams = {
            project,
            objectTypeMetric,
            title,
            algorithmParams,
            filters,
            dataState,
            existMetric,
            metricLayer,
            taskProps: {
                title: algorithmInstance.title,
                onCreate: (task) => {
                    console.log('CALC>>')
                    enqueueSnackbar(`Calculation starts: ${task.title}`, {
                        autoHideDuration: 3000,
                        anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                        variant: 'info'
                    })
                },
                onResult: (task, result) => {
                    console.log('end CALC>>')

                    mapDispatch({
                        type: 'setCalculating',
                        isCalculating: true
                    })
                    enqueueSnackbar(`Calculation success: ${task.title}, receiving results...`, {
                        autoHideDuration: 10000,
                        anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                        variant: 'info'
                    })

                    const projectObjectType = {
                        id: project.id,
                        key: project.id,
                        meta: project.meta,
                    };

                    const nextMetricIndex = Object.keys(dataMetrics).length
                    const newMetric = existMetric
                        ? {
                            ...existMetric,
                            ver: result.ver,
                            lineage: result.lineage
                        } : MetricModel({
                            id: result.metric_id,
                            index: nextMetricIndex,
                            title,
                            ver: result.ver,
                            lineage: result.lineage,
                        }, objectTypeMetric, projectObjectType)

                    const newCube = CubeModel({
                        id: newMetric.id,
                        src_id: 0,
                        ver: result.ver,
                        struct: {
                            fields: result.struct,
                            timeseries: result.timeseries
                        },
                    })

                    const layerType = objectTypeMetric.layerTypes.length
                        ? (objectTypeMetric.layerTypes.find(item => item.is_default)?.key || objectTypeMetric.layerTypes[0]).key
                        : DEFAULT_LAYER_TYPE

                    const source = parquetApi.endpoint(result.path)
                    AddTableParquet(newCube, source, !!metricId).then(async () => {

                        const stats = await CalculateStats(newCube)

                        dataDispatch({
                            type: 'addCube',
                            cube: newCube
                        })

                        dataDispatch({
                            type: 'addCubeStats',
                            cubeId: newCube.id,
                            stats: stats
                        })

                        dataDispatch({
                            type: 'addMetric',
                            metric: newMetric
                        })

                        let newLayer;

                        if (metricLayer) {
                            newLayer = metricLayer
                            mapDispatch({
                                type: 'updateLayer',
                                layer: metricLayer
                            })
                        } else {
                            newLayer = LayerModel({
                                title,
                                layerType,
                                metrics: [
                                    newMetric
                                ],
                                color: newMetric.color,
                                object: objectTypeMetric,
                                objectType: objectTypeMetric,
                                colorScheme: objectTypeMetric.colorScheme
                            })
                            mapDispatch({
                                type: 'addLayer',
                                layer: newLayer
                            })
                        }

                        handleSaveHistory(calcParams, {
                            cube: newCube,
                            metric: newMetric,
                            layer: newLayer
                        })

                        enqueueSnackbar(`Calculation ready: ${task.title}, ` + (
                            existMetric
                                ? `Metric ${newMetric.title} was updated`
                                : `Metric ${newMetric.title} was created`
                        ), {
                            autoHideDuration: 3000,
                            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                            variant: 'success',
                            action: () => (<Button variant="contained" onClick={onExecute({
                                cube: newCube,
                                metric: newMetric,
                                layer: newLayer
                            })
                            }>Show result</Button>)
                        })

                        mapDispatch({
                            type: 'setCalculating',
                            isCalculating: false
                        })
                    }).catch(() => {
                        mapDispatch({
                            type: 'setCalculating',
                            isCalculating: false
                        })
                        enqueueSnackbar(`Error was happen while writing metric: ${newMetric.title}`, {
                            autoHideDuration: 3000,
                            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                            variant: 'error'
                        })
                    })
                },
                onTimeout: (task) => {
                    enqueueSnackbar(`Calculation task is timed out: ${task.title}`, {
                        autoHideDuration: 3000,
                        anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                        variant: 'warning'
                    })
                }
            }
        };

        algorithmInstance.task(calcParams)
            .then(() => {
                handleClearForm()

                mapDispatch({
                    type: 'setCalculating',
                    isCalculating: false
                })

            })
            .catch((e) => {
console.log('T_e>', e)

                mapDispatch({
                    type: 'setCalculating',
                    isCalculating: false
                })

                enqueueSnackbar(`Something went wrong, please try again`, {
                    autoHideDuration: 3000,
                    anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    variant: 'warning'
                })

            })
    }

    const algorithmObjectTypeSelect = (e) => {
        mapDispatch({
            type: 'setAlgorithmObjectType',
            object_type_id: e.target.value
        })
    }

    const handleMetricTitleChange = (e) => {
        mapDispatch({
            type: 'setAlgorithmMetricTitle',
            title: e.target.value
        })
    }

    const algStruct = getAlgorithm(algorithm)

    if (!metricObjectTypes || metricObjectTypes.length === 0) {
        return null;
    }

    const isExecutionEnable = !isCalculating
        && algorithmMetricTitle
        && algorithmMetricTitle.length > 0
        && algorithmObjectType
        && Object.keys(algorithmParams).length > 0

    const isExecutionButtonVisible = !!algStruct
    const AlgInterface = algorithmInstance?.component  // getDebugAlgorithm().interface

    // const handleTestAlgo = async () => {
    //     const db = await getDB()
    //     const result = await db.run(`
    //         select b.*, m.*
    //         from ${db.project_schema}.building b
    //         join ${db.project_schema}."src1_data_building_widefunction_met" m on m.building=b.id
    //     `)
    //     const resultList = result.toArray().map(Object.fromEntries)
    //     console.log('>', resultList)
    // }

    return (
        <Paper className={classes.root}>
            {/*<h4 className={classes.title}>{props.title}</h4>*/}
            <div className={classes.selectAlgPanel}>
                <FormControl className={classes.selectAlgControl}>
                    <InputLabel shrink={!!algorithm}
                                id={'alg-' + props.layerId + '-label'}>{t('project.leftbar.panel5.select')}:</InputLabel>
                    <Select
                        labelId={'alg-' + props.layerId + '-label'}
                        id={'alg-' + props.layerId + '-value'}
                        value={algorithm}
                        onChange={handleAlgSelect}
                        className={classes.selectControl}
                    >
                        <MenuItem aria-label="None" value=""/>
                        {
                            Object.values(algorithms)
                                .map((item, ai) => <MenuItem key={ai} value={item.key}>{item.title}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                {
                    algStruct &&
                    <Typography caption className={classes.algAbout}>{algStruct.about}</Typography>
                }
            </div>

            <div>
                <FormControl className={classes.selectAlgControl}>
                    <InputLabel shrink={!!algorithmObjectTypeSelect}
                                id={'alg-ot-' + props.layerId + '-label'}>{t('project.leftbar.panel5.for_object_type')}:</InputLabel>
                    <Select
                        labelId={'alg-ot-' + props.layerId + '-label'}
                        id={'alg-ot-' + props.layerId + '-value'}
                        value={algorithmObjectType}
                        onChange={algorithmObjectTypeSelect}
                        className={classes.selectControl}
                    >
                        <MenuItem aria-label="None" value=""/>
                        {
                            Object.values(metricObjectTypes)
                                .map((item, ai) => <MenuItem key={ai} value={item.id}>{item.meta.title}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </div>

            <div>
                <FormControl className={classes.selectAlgControl}>
                    <InputLabel shrink={!!algorithmMetricTitle}
                                id={'alg-met-name-' + props.layerId + '-label'}>{t('project.leftbar.panel5.new_metric_name')}:</InputLabel>
                    <Input
                        // labelId={'alg-met-name-' + props.layerId + '-label'}
                        id={'alg-met-name-' + props.layerId + '-value'}
                        className={classes.inputControl}
                        value={algorithmMetricTitle}
                        onChange={handleMetricTitleChange}
                    />
                </FormControl>
            </div>

            {
                AlgInterface &&
                <AlgInterface
                    struct={algStruct}
                    params={algorithmParams}
                    objectType={algorithmObjectType}
                    onChangeParams={handleAlgChangeParams}
                />
            }

            {
                isExecutionButtonVisible &&
                <div>
                    <div className={classes.executeButtonContainer}>
                        <Grid container
                              spacing={0}
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                        >
                            <Grid item xs={4}>
                                <Button onClick={() => handleClearForm()}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={8}>
                                <Button
                                    onClick={handleAlgExecute}
                                    variant="contained"
                                    disabled={!isExecutionEnable}
                                    className={classes.executeButton}
                                >
                                    {t('project.leftbar.panel5.execute')}
                                </Button>

                                {isCalculating &&
                                <CircularProgress size={24} className={classes.buttonProgress}/>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }

            {/*
            <Button onClick={(e) => {
                e.preventDefault();
                handleTestAlgo();
            }}>Test alg</Button>
*/}

        </Paper>
    )
}