import {ApiEndpoint} from '../config'
import {authAxios} from '../clients'
import {getToken} from "../actions/auth";


const DEFAULT_TIMEOUT = 30 * 60

const operandDefaults = {
    object_type_id: null,
    weight: 1,
    tag: null,
    value: null
}
export function Operand(props) {
    return {
        ...operandDefaults,
        ...props
    }
}

export function Task(props) {
    this.id = props.id
    this.cr = Date.now()
    this.props = props
    this.title = props.title ?? `Task #${Object.keys(appTasks).length + 1}`
    this.timeout = props.timeout ?? DEFAULT_TIMEOUT
    this.onCreate = props.onCreate ?? ((task) => {})
    this.onResult = props.onResult ?? ((task, result) => {})
    this.onTimeout = props.onTimeout ?? ((task) => {})
    this.pendingInterval = null
}


const algEndpoint = new ApiEndpoint('alg')
const apiClient = authAxios({
    auth: {
        access_token: getToken()
    }
});

let appTasks = {}
export const createTask = (params, props) => {
    return apiClient.post(algEndpoint.endpoint('/async/execute'), params).then((response) => {
        const task = new Task({
            id: response.data.task_id,
            ...params,
            ...props
        })
        registerTask(task)
        return task
    })
}

export const registerTask = (task) => {
    appTasks[task.id] = task
    task.onCreate(task)
    task.pendingInterval = setInterval(() => {
        apiClient.get(algEndpoint.endpoint(`/async/check/${task.id}`))
            .then((response) => {
                if (response.data.state === 'SUCCESS') {
                    removeTask(task)
                    task.onResult(task, response.data.result)
                    return
                }

                if (task.cr + task.timeout * 1000 < Date.now()) {
                    removeTask(task)
                    task.onTimeout(task)
                }
            })
            .catch((e) => console.error)
        }, 2000)
}

export const removeTask = (task) => {
    clearInterval(task.pendingInterval)
    appTasks = Object.fromEntries(Object.entries(appTasks).filter(([id, _]) => id !== task.id))
}