import React, {useContext, useState} from "react";
import {
    Paper,
    Grid,
    Button,
    Tooltip, FormControl, InputLabel, Select, MenuItem, TextField,

} from "@material-ui/core";

import {makeStyles} from '@material-ui/core/styles';

import {
    MapStateContext,
    DataStateContext,
    MapStyleContext,
    saveableDataState,
    saveableMapState,
    saveableMapStyle
} from '../../../reducer'
import {useTranslation} from "react-i18next";
import {isDefined} from "../../../../../../helpers/fn";
import {addLayerObjectType, addLayerProps} from '../../../../../../models/layer'


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: 280,
        background: '#F5FAFF',
        borderRadius: 0,
        paddingBottom: 10,
        marginBottom: 10
    },
    title: {
        boxSizing: 'border-box',
        padding: '10px',
        margin: '0',
        fontWeight: 'normal',
        fontSize: '12pt'
    },

    layerControls: {
        boxSizing: 'border-box',
        padding: '10px',
        margin: '0',
    },

    layerCreateField: {
        width: '100%',
        margin: '4px 0'
    },

    layerControlItem: {
        textAlign: 'center'
    }

}));

export default function LayerNew(props) {
    const classes = useStyles();
    const {mapState, mapDispatch} = useContext(MapStateContext)
    const {dataState} = useContext(DataStateContext)
    const {mapStyle} = useContext(MapStyleContext)
    const {t} = useTranslation();

    const { layerId, layer, onCreated } = props
    const { objectType: objectTypeDict } = dataState
    const [selectedObjectType, setSelectedObjectType] = useState(null)
    const [layerTitle, setLayerTitle] = useState('')
    const [isUserEdited, setUserEdited] = useState(false)


    const handleDeleteLayer = () => {
        mapDispatch({
            type: 'removeLayerProto',
            layerId,
        })
    }

    const handleSaveLayer = () => {
        if (!isDefined(selectedObjectType)) return

        const objectType = objectTypeDict[selectedObjectType]
        const newLayer = addLayerProps(
            addLayerObjectType(layer, objectType),
            {
                title: layerTitle
            }
        )
        mapDispatch({
            type: 'addLayer',
            layer: newLayer
        })

        if (onCreated) onCreated(newLayer)
    }


    return (
        <Paper className={classes.root}>
            <h4 className={classes.title}>{t('widgets.layer_new.title')}</h4>

            <div className={classes.layerControls}>
                <FormControl key='field' className={classes.layerCreateField}>
                    <InputLabel>{t('widgets.layer_new.object_type')}:</InputLabel>
                    <Select
                        value={selectedObjectType}
                        onChange={(e) => {
                            setSelectedObjectType(e.target.value)
                            if (!isUserEdited) setLayerTitle(objectTypeDict[e.target.value]?.meta?.title)
                        }}
                    >
                        <MenuItem key={-1} value="">&times; {t('widgets.height_selector.clear_base')} </MenuItem>
                        {
                            Object.values(objectTypeDict)?.map((otItem, fi) => (
                                <MenuItem key={fi}
                                          value={otItem.id}>{otItem.meta.title}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl key='field' className={classes.layerCreateField}>
                    <TextField label={t('widgets.layer_new.layer_title')}
                               value={layerTitle}
                               onChange={(e) => {
                                   setLayerTitle(e.target.value)
                                   setUserEdited(true)
                               }}
                    />
                </FormControl>
                <FormControl className={classes.layerCreateField}>
                    <Grid container justify="space-between">
                        <Grid item xs={6} className={classes.layerControlItem}>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                handleDeleteLayer()
                            }}>{t('widgets.layer_new.cancel_button')}</Button>
                        </Grid>
                        <Grid item xs={6} className={classes.layerControlItem}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                handleSaveLayer()
                            }}>{t('widgets.layer_new.create_button')}</Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
        </Paper>
    )
}