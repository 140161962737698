export const trailTypes = [
    ['razed'],

    ['steps', 'footway', 'path', 'service', 'pedestrian', 'unclassified',],

    ['cycleway'],
    ['track', 'secondary', 'living_street', 'residential'],
    ['primary', 'motorway_link',],
    ['tertiary', 'tertiary_link',],

];

export function roadType(road) {
    let vendor = 0;

    trailTypes.forEach((types, i) => {
        const finded = types.findIndex((t) => String(road.highway).search(t) > -1)
        if (finded > -1) {
            vendor = i
        }
    });

    if (road.maxspeed) {
        if (road.maxspeed > 20 && road.maxspeed <= 30) {
            vendor = Math.max(2, vendor);
        } else if (road.maxspeed > 30 && road.maxspeed <= 50) {
            vendor = Math.max(3, vendor);
        } else if (road.maxspeed > 50 && road.maxspeed <= 70) {
            vendor = Math.max(4, vendor);
        } else if (road.maxspeed > 70) {
            vendor = Math.max(6, vendor)
        }
    }


    return vendor
}