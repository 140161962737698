import { gql } from "@apollo/client";


export const CreateComment = gql`
    mutation CreateComment($project_id: uuid!, $object_id: uuid!, $lng: float8, $lat: float8, $message: jsonb!, $reply_to: uuid) {
        createComment(object: {
            project_id: $project_id,
            object_id: $object_id,
            lng: $lng, 
            lat: $lat, 
            message: $message,
            reply_to: $reply_to
        }) {
            id
        }
    }
`

export const PublishComment = gql`
    mutation PublishComment($comment_id: uuid!) {
        updateComment(pk_columns: {id: $comment_id}, _set: {state: 1}, ) {
            state
            id
        }
    }
`
export const UnpublishComment = gql`
    mutation UnpublishComment($comment_id: uuid!) {
        updateComment(pk_columns: {id: $comment_id}, _set: {state: 0}) {
            state
            id
        }        
    }
`

export const DeleteComment = gql`
    mutation DeleteComment($comment_id: uuid!) {
        deleteComments(where: {
            id: {_eq: $comment_id}
        }) {
            affected_rows
            returning {
                id
                cr
                reply_to
            }
        }
    }
`

export const LikeComment = gql`
    mutation LikeComment($project_id: uuid!, $comment_id: uuid!) {
        rankComment(object: {comment_id: $comment_id, project_id: $project_id}) {
            comment_id
        }
    }
`

export const UnlikeComment = gql`
    mutation UnlikeComment($project_id: uuid!, $comment_id: uuid!, $user_id: uuid!) {
        unrankComment(comment_id: $comment_id, project_id: $project_id, user_id: $user_id) {
            comment_id
        }
    }
`

export const GetComments = gql`
    query GetComments($project_id: uuid!, $object_id: uuid!, $user_id: uuid) {
        comments(where: {
            project_id: {_eq: $project_id}, 
            object_id: {_eq: $object_id} 
        }, order_by: {cr: asc}) {
            id
            cr
            lat
            lng
            message
            up
            type_id
            state
            task_id
            task_state
            z
            user {
                id
                meta {
                    first_name
                    last_name
                }
            }
            replyComment {
                id
                lat
                lng
                message
                user {
                    id
                    meta {
                        first_name
                        last_name
                    }
                }
                state
                z
                cr
            }
            ranks(where: {user_id: {_eq: $user_id}}) {
                rank
                emo
                cr
            }
            rankStat {
                count
            }
        }
    }
`

export const ObjectRanks = gql`
    query ObjectRanks($project_id: uuid!, $object_id: uuid!, $user_id: uuid!) {
        objectsRankStat(where: {object_id: {_eq: $object_id}, project_id: {_eq: $project_id}}) {
            count_minor
            count_major
        }
        objectRank(object_id: $object_id, project_id: $project_id, user_id: $user_id) {
            rank
        }
    }
`


export const RankObject = gql`
    mutation RankObject($project_id: uuid!, $object_id: uuid!, $rank: Int!) {
        rankObject(
            object: {project_id: $project_id, object_id: $object_id, rank: $rank},
            on_conflict: {constraint: object_rank_pkey, update_columns: rank}
        ) {
            object_id,
            rank
        }
    }
`

export const UnrankObject = gql`
    mutation UnlikeComment($project_id: uuid!, $object_id: uuid!, $user_id: uuid!) {
        unrankObject(project_id: $project_id, object_id: $object_id, user_id: $user_id) {
            object_id
        }
    }
`

export const GetActivityObjects = gql`
    query GetActivity($project_id: uuid!) {
        comments(where: {project_id: {_eq: $project_id}}, distinct_on: object_id) {
            object_id
        }
        objectRanks(where: {project_id: {_eq: $project_id}}, distinct_on: object_id) {
            object_id
        }
    }
`