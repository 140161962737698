import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        fill: 'currentColor', // #4E4F54
        // stroke: 'currentColor', // #4E4F54
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none'
    }
}));

const PointsIcon = (props) => {
    const classes = useStyles();

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             width="13" height="14"
             viewBox="0 0 768 767.999994"
             fill="none"
             zoomAndPan="magnify" preserveAspectRatio="xMidYMid meet"
             version="1.0"
             className={classes.root}
        >
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 313.910156 260.230469 C 283.050781 260.230469 258.035156 285.246094 258.035156 316.105469 C 258.035156 346.960938 283.050781 371.980469 313.910156 371.980469 C 344.769531 371.980469 369.785156 346.960938 369.785156 316.105469 C 369.785156 285.246094 344.769531 260.230469 313.910156 260.230469 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 453.933594 260.230469 C 423.074219 260.230469 398.058594 285.246094 398.058594 316.105469 C 398.058594 346.960938 423.074219 371.980469 453.933594 371.980469 C 484.792969 371.980469 509.808594 346.960938 509.808594 316.105469 C 509.808594 285.246094 484.792969 260.230469 453.933594 260.230469 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 313.910156 395.863281 C 283.050781 395.863281 258.035156 420.878906 258.035156 451.738281 C 258.035156 482.597656 283.050781 507.613281 313.910156 507.613281 C 344.769531 507.613281 369.785156 482.597656 369.785156 451.738281 C 369.785156 420.878906 344.769531 395.863281 313.910156 395.863281 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 453.933594 395.863281 C 423.074219 395.863281 398.058594 420.878906 398.058594 451.738281 C 398.058594 482.597656 423.074219 507.613281 453.933594 507.613281 C 484.792969 507.613281 509.808594 482.597656 509.808594 451.738281 C 509.808594 420.878906 484.792969 395.863281 453.933594 395.863281 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 313.867188 157.097656 C 292.121094 157.097656 274.492188 174.726562 274.492188 196.472656 C 274.492188 218.21875 292.121094 235.847656 313.867188 235.847656 C 335.613281 235.847656 353.242188 218.21875 353.242188 196.472656 C 353.242188 174.726562 335.613281 157.097656 313.867188 157.097656 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 453.890625 157.097656 C 432.144531 157.097656 414.515625 174.726562 414.515625 196.472656 C 414.515625 218.21875 432.144531 235.847656 453.890625 235.847656 C 475.636719 235.847656 493.265625 218.21875 493.265625 196.472656 C 493.265625 174.726562 475.636719 157.097656 453.890625 157.097656 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 313.867188 530.675781 C 292.121094 530.675781 274.492188 548.304688 274.492188 570.050781 C 274.492188 591.796875 292.121094 609.425781 313.867188 609.425781 C 335.613281 609.425781 353.242188 591.796875 353.242188 570.050781 C 353.242188 548.304688 335.613281 530.675781 313.867188 530.675781 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 453.890625 530.675781 C 432.144531 530.675781 414.515625 548.304688 414.515625 570.050781 C 414.515625 591.796875 432.144531 609.425781 453.890625 609.425781 C 475.636719 609.425781 493.265625 591.796875 493.265625 570.050781 C 493.265625 548.304688 475.636719 530.675781 453.890625 530.675781 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 192.632812 276.6875 C 170.886719 276.6875 153.257812 294.316406 153.257812 316.0625 C 153.257812 337.808594 170.886719 355.4375 192.632812 355.4375 C 214.378906 355.4375 232.007812 337.808594 232.007812 316.0625 C 232.007812 294.316406 214.378906 276.6875 192.632812 276.6875 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 192.632812 412.320312 C 170.886719 412.320312 153.257812 429.949219 153.257812 451.695312 C 153.257812 473.441406 170.886719 491.070312 192.632812 491.070312 C 214.378906 491.070312 232.007812 473.441406 232.007812 451.695312 C 232.007812 429.949219 214.378906 412.320312 192.632812 412.320312 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 573.890625 276.6875 C 552.144531 276.6875 534.515625 294.316406 534.515625 316.0625 C 534.515625 337.808594 552.144531 355.4375 573.890625 355.4375 C 595.636719 355.4375 613.265625 337.808594 613.265625 316.0625 C 613.265625 294.316406 595.636719 276.6875 573.890625 276.6875 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 573.890625 412.320312 C 552.144531 412.320312 534.515625 429.949219 534.515625 451.695312 C 534.515625 473.441406 552.144531 491.070312 573.890625 491.070312 C 595.636719 491.070312 613.265625 473.441406 613.265625 451.695312 C 613.265625 429.949219 595.636719 412.320312 573.890625 412.320312 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 192.632812 157.097656 C 170.886719 157.097656 153.257812 174.726562 153.257812 196.472656 C 153.257812 218.21875 170.886719 235.847656 192.632812 235.847656 C 214.378906 235.847656 232.007812 218.21875 232.007812 196.472656 C 232.007812 174.726562 214.378906 157.097656 192.632812 157.097656 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 573.890625 157.097656 C 552.144531 157.097656 534.515625 174.726562 534.515625 196.472656 C 534.515625 218.21875 552.144531 235.847656 573.890625 235.847656 C 595.636719 235.847656 613.265625 218.21875 613.265625 196.472656 C 613.265625 174.726562 595.636719 157.097656 573.890625 157.097656 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 193.25 530.675781 C 171.503906 530.675781 153.875 548.304688 153.875 570.050781 C 153.875 591.796875 171.503906 609.425781 193.25 609.425781 C 214.996094 609.425781 232.625 591.796875 232.625 570.050781 C 232.625 548.304688 214.996094 530.675781 193.25 530.675781 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 574.507812 530.675781 C 552.761719 530.675781 535.132812 548.304688 535.132812 570.050781 C 535.132812 591.796875 552.761719 609.425781 574.507812 609.425781 C 596.253906 609.425781 613.882812 591.796875 613.882812 570.050781 C 613.882812 548.304688 596.253906 530.675781 574.507812 530.675781 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 313.90625 76.800781 C 299.410156 76.800781 287.65625 88.550781 287.65625 103.050781 C 287.65625 117.546875 299.410156 129.300781 313.90625 129.300781 C 328.40625 129.300781 340.15625 117.546875 340.15625 103.050781 C 340.15625 88.550781 328.40625 76.800781 313.90625 76.800781 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 453.929688 76.800781 C 439.433594 76.800781 427.679688 88.550781 427.679688 103.050781 C 427.679688 117.546875 439.433594 129.300781 453.929688 129.300781 C 468.425781 129.300781 480.179688 117.546875 480.179688 103.050781 C 480.179688 88.550781 468.425781 76.800781 453.929688 76.800781 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 313.90625 638.539062 C 299.410156 638.539062 287.65625 650.289062 287.65625 664.789062 C 287.65625 679.285156 299.410156 691.039062 313.90625 691.039062 C 328.40625 691.039062 340.15625 679.285156 340.15625 664.789062 C 340.15625 650.289062 328.40625 638.539062 313.90625 638.539062 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 453.929688 638.539062 C 439.433594 638.539062 427.679688 650.289062 427.679688 664.789062 C 427.679688 679.285156 439.433594 691.039062 453.929688 691.039062 C 468.425781 691.039062 480.179688 679.285156 480.179688 664.789062 C 480.179688 650.289062 468.425781 638.539062 453.929688 638.539062 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 103.050781 289.851562 C 88.550781 289.851562 76.800781 301.605469 76.800781 316.101562 C 76.800781 330.597656 88.550781 342.351562 103.050781 342.351562 C 117.546875 342.351562 129.300781 330.597656 129.300781 316.101562 C 129.300781 301.605469 117.546875 289.851562 103.050781 289.851562 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 103.050781 425.484375 C 88.550781 425.484375 76.800781 437.238281 76.800781 451.734375 C 76.800781 466.234375 88.550781 477.984375 103.050781 477.984375 C 117.546875 477.984375 129.300781 466.234375 129.300781 451.734375 C 129.300781 437.238281 117.546875 425.484375 103.050781 425.484375 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 664.789062 289.851562 C 650.289062 289.851562 638.539062 301.605469 638.539062 316.101562 C 638.539062 330.597656 650.289062 342.351562 664.789062 342.351562 C 679.285156 342.351562 691.039062 330.597656 691.039062 316.101562 C 691.039062 301.605469 679.285156 289.851562 664.789062 289.851562 "
                  fillOpacity="1" fillRule="nonzero"/>
            <path fill="rgb(30.589294%, 30.979919%, 32.939148%)"
                  d="M 664.789062 425.484375 C 650.289062 425.484375 638.539062 437.238281 638.539062 451.734375 C 638.539062 466.234375 650.289062 477.984375 664.789062 477.984375 C 679.285156 477.984375 691.039062 466.234375 691.039062 451.734375 C 691.039062 437.238281 679.285156 425.484375 664.789062 425.484375 "
                  fillOpacity="1" fillRule="nonzero"/>
        </svg>
    )
};

export default PointsIcon;