import React, {useContext, useEffect, useState, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {
    Modal,
    Backdrop,
    TextField,
    Fade,
    Card,

    InputAdornment,

    FormHelperText,

    Snackbar,

    Link,
    Badge,

    Paper,

    FormControlLabel,

    CardActionArea,
    CardMedia,
    CardHeader,
    CardActions,
    CardContent,
    Button,
    Typography,
    Avatar,
    IconButton,

    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    Grid,

    Divider,
    Tooltip,

    CircularProgress,

} from '@material-ui/core'

import {
    Alert,
} from '@material-ui/lab'

import {
    Close as CloseIcon,
    Visibility,
    VisibilityOff
} from '@material-ui/icons'

import {authAxios} from '../../clients/directAxios'
import {ApiEndpoint} from '../../config'

import {PopupStateContext, AuthStateContext} from "../../reducers";
import {getToken} from "../../actions/auth";



const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 300,
        maxWidth: 350,
        width: '50vw',
        background: theme.color.gray,
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            // height: '100vh'
        }
        // maxHeight: '80vh',
    },

    title: {
        margin: 0,
        padding: 0
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    field: {
        width: '100%'
    },

    mainFields: {
        marginBottom: theme.spacing(2)
    },

    metrics: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        background: theme.color.gray
    },

    metricTitleRow: {
        '& .MuiFormControlLabel-label': {
            marginLeft: 8
        }
    },

    listMetricItem: {
        borderBottom: '1px solid rgba(0,0,0,0.12)'
    },

    listItemText: {
        '& span': {
            fontSize: 12
        }
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },

    comments: {},

    commentList: {
        width: '100%',
        // maxWidth: '36ch',
        // backgroundColor: theme.palette.background.paper,
    },

    replyCommentAuthor: {
        display: 'inline',
    },

    card: {
        // position: 'relative'
    },

    mainPart: {
        // maxHeight: '70vh',
        // marginBottom: 120,
        // overflow: 'hidden'
        [theme.breakpoints.down('md')]: {
            // height: '50vh'
        },
        [theme.breakpoints.down('sm')]: {
            // height: '50vh'
        }
    },

    commentPart: {
        height: 140,
        padding: '0 0 0 16px'
        // position: 'absolute',
        // bottom: 0,
        // left: 0,
        // right: 0
    },

    commentSubpart: {},

    commentSubpartItem: {
        fontSize: '12px',
        marginLeft: 16,

        '&:first-child': {
            marginLeft: 0
        }
    },

    hiddenCommentText: {
        fontSize: 18,
        lineHeight: '26px',
        opacity: 0.5,
    },

    commentLalka: {
        float: 'right',
        opacity: 0.8,
        marginRight: 4,
        '&:hover': {
            opacity: 1
        }
    },

    commentLalkaIcon: {
        color: '#EC5280'
    },

    commentLalkaBadge: {
        '& .MuiBadge-badge': {
            fontSize: '10px',
            height: 16,
            minWidth: 16,
            padding: '0 2px'
        }
    },

    minorRatingIcon: {
        color: '#EC5280'
    },

    majorRatingIcon: {
        color: '#EC5280'
    },

    ratings: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },

    ratingButton: {
        marginLeft: '24px',
        opacity: 0.8,
        '&:hover': {
            opacity: 1
        },
        '&:first-child': {
            marginLeft: 0
        }
    },

    ratingBadge: {
        '& .MuiBadge-badge': {}
    },

    sendCommentButtonWrapper: {
        position: 'relative'
    },

    sendCommentButtonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

    commentField: {
        width: '100%',
    },

    commentButton: {
        // float: 'right'
        width: '100%'
    },

    cardActions: {
        padding: 16,
        '& button': {
            marginLeft: 8,
            '&:first-child': {
                marginLeft: 0
            }
        }
    }

}));


const prepareProfile = (me) => (me ? {
    ...me,
    auth: {
        username: me?.meta?.email,
        password: ''
    }
} : null)


const getProfileStructure = (meStruct) => ({
    meta: meStruct?.meta,
    auth: meStruct?.auth
})

const defaultError = {
    common: null,
    fields: {}
}


export default function ProfileModal(props) {
    const classes = useStyles();
    const {authState, authDispatch} = useContext(AuthStateContext)
    const {popupState, popupDispatch} = useContext(PopupStateContext)

    const {me} = authState
    const {profile} = popupState

    const [meState, setMeState] = useState(prepareProfile(me))
    const [showPassword, setShowPassword] = useState(false)

    const [errors, setErrors] = useState(defaultError)

    const [success, setSuccess] = useState(null)

    const apiClient = authAxios({
        auth: {
            access_token: getToken()
        }
    });

    useEffect(() => {
        setMeState(prepareProfile(me))
    }, [me])

    const handleClose = () => {
        popupDispatch({
            type: 'closePopup',
            popup: 'profile'
        })
    }

    const handleSave = () => {
        setSuccess(null)

        const authApi = new ApiEndpoint('auth')
        apiClient.post(authApi.endpoint('/profile'), getProfileStructure(meState)).then(() => {
            setErrors(defaultError)
            setSuccess('Profile saved')
        }).catch((error) => {
            if (error.response) {
                const fields = error.response.data?.details ? error.response.data.details.reduce((acc, err) => (err.loc && err.msg ? {
                    ...acc,
                    [err.loc[err.loc.length - 1]]: err.msg
                }: acc), {}) : {}
                setErrors({common: error.response.data?.error, fields})
            } else {
                setErrors({common: 'System error', fields: []})
            }
        })
    }

    const handleSetMeta = (field) => (e) => {
        if (e?.target?.value === undefined) return

        const newState = {
            ...meState,
            meta: {
                ...meState.meta,
                [field]: e?.target?.value
            }
        }

        setMeState(newState)
    }

    const handleSetAuth = (field) => (e) => {
        if (e?.target?.value === undefined) return

        const newState = {
            ...meState,
            auth: {
                ...meState.auth,
                [field]: e?.target?.value
            }
        }

        setMeState(newState)
    }

    if (!me) return null
    const myName = `${meState?.meta?.first_name ?? ''} ${meState?.meta?.last_name ?? ''}`.trim()

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={profile.open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={profile.open}>
                <Card className={classes.root}>
                    <CardHeader
                        /*
                                                avatar={
                                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                                        {(layer?.objectType?.meta?.title ?? ' ')[0]}
                                                    </Avatar>
                                                }
                        */
                        // subheader="-"
                        action={
                            <IconButton aria-label="settings" onClick={handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        }
                        title={myName}
                    />
                    <CardContent className={classes.card}>
                        <div className={classes.mainPart}>

                            <h5 className={classes.title}>Information</h5>

                            {errors.common &&
                            <Alert severity="error">{errors.common}</Alert>
                            }

                            {success &&
                            <Snackbar open={!!success} autoHideDuration={3000} onClose={() => setSuccess(null)}>
                                <Alert onClose={handleClose} severity="success">
                                    {success}
                                </Alert>
                            </Snackbar>
                            }

                            <div className={classes.mainFields}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin="normal" className={classes.formControl}
                                                     key="firstName">
                                            <TextField
                                                id="me-first-name"
                                                label="First name"
                                                value={meState?.meta?.first_name}
                                                className={classes.field}
                                                onChange={handleSetMeta('first_name')}
                                                helperText={errors.fields['first_name']}
                                                error={!!errors.fields['first_name']}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin="normal" className={classes.formControl}
                                                     key="lastName">
                                            <TextField
                                                id="me-last-name"
                                                label="Last name"
                                                value={meState?.meta?.last_name}
                                                className={classes.field}
                                                onChange={handleSetMeta('last_name')}
                                                helperText={errors.fields['last_name']}
                                                error={!!errors.fields['last_name']}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>

                            <h5 className={classes.title}>Location</h5>
                            <div className={classes.mainFields}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin="normal" className={classes.formControl}
                                                     key="country">
                                            <TextField
                                                id="me-country"
                                                label="Country"
                                                value={meState?.meta?.country}
                                                className={classes.field}
                                                onChange={handleSetMeta('country')}
                                                helperText={errors.fields['country']}
                                                error={!!errors.fields['country']}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin="normal" className={classes.formControl}
                                                     key="city">
                                            <TextField
                                                id="me-city"
                                                label="City"
                                                value={meState?.meta?.city}
                                                className={classes.field}
                                                onChange={handleSetMeta('city')}
                                                helperText={errors.fields['city']}
                                                error={!!errors.fields['city']}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>

                            <h5 className={classes.title}>Authorization</h5>

                            <div className={classes.mainFields}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin="normal" className={classes.formControl}
                                                     key="login">
                                            <TextField
                                                id="me-login"
                                                label="Login"
                                                value={meState?.auth?.username}
                                                className={classes.field}
                                                onChange={handleSetAuth('username')}
                                                helperText={errors.fields['username']}
                                                error={!!errors.fields['username']}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin="normal" className={classes.formControl}
                                                     key="password" error={!!errors.fields['password']}>
                                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                            <Input
                                                id="standard-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={meState?.auth?.password}
                                                onChange={handleSetAuth('password')}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            onMouseDown={(e) => e.preventDefault()}
                                                        >
                                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {!!errors.fields['password'] &&
                                            <FormHelperText>{errors.fields['password']}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </div>

                        </div>
                    </CardContent>

                    <CardActions className={classes.cardActions}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={8}>
                                <Button variant="contained" color="default" onClick={handleClose}>
                                    Close
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="contained" color="primary" onClick={handleSave}
                                        style={{float: 'right'}}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Fade>
        </Modal>
    );
}
