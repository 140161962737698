import React, {useState, useEffect, useReducer, useContext} from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Badge, Button, Collapse, TextField, Slider, Grid} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab"
import {makeStyles} from "@material-ui/core/styles";
import SeriesSelector from "./series-selector";
import {isFieldKey, isFieldScalar, isFieldTimestamp, isFieldUUID} from '../../../../../../../helpers/field'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    title: {
        margin: 0,
        padding: 0
    },
}));


const FilterSelector = (props) => {
    const classes = useStyles();

    const {field, dict, value: currentValue, onChange, onResetScalars} = props

    const [values, setValues] = useState(currentValue)
    const handleChangeValues = (value, naturalValue) => {
        setValues({
            value,
            naturalValue
        })
    }

    useEffect(() => {
        if (onChange) onChange(values)
    }, [values])


    return (
        <div className={classes.root}>
            <h5 className={classes.title}>{field.title}</h5>
            <Autocomplete
                multiple
                id="tags-standard"
                options={dict.values}
                getOptionLabel={(option) => option.title}
                defaultValue={[]}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        // label={field.title}
                        placeholder=""
                    />
                )}
                onChange={(e, values) => {
                    handleChangeValues(values.map(item => item.id), values.map(item => item.id))
                    if (onResetScalars) onResetScalars()
                }}
            />
        </div>
    )
}

export default FilterSelector;