
const INITIAL_APP_STATE = {
    currentProject: {}
};

export default function (state = INITIAL_APP_STATE, action) {
    switch (action.type) {
        // case GET_IDEA:
        //     return {
        //         ...state,
        //         currentIdea: action.payload,
        //     };
        default:
            return state;
    }
}
