import {createTask} from "../../../services/task-manager";

export default async function WeightedGravityIndexTask({
                                                    objectTypeMetric, project, title, algorithmParams,
                                                    filters, dataState,
                                                    existMetric, metricLayer, taskProps
                                                })
{
    const {operands, gravityRadius} = algorithmParams

    const executeParams = {
        project_id: project.id,
        algorithm: "weightgravity",
        object_type_id: objectTypeMetric.id,
        ver: existMetric ? existMetric.ver : 1,
        title,
        filter: [],
        params: {
            gravityRadius
        },
        operands: Object.values(operands).map(operand => ({
            object_type_id: operand.object_type_id,
            weight: operand.weight,
            metric_id: operand.metric_id,
            field: operand.field,
            tag: operand.tag,
            value: operand.value
        })),
    }

    if (existMetric) {
        executeParams['metric_id'] = existMetric.id
    }

    return createTask(executeParams, taskProps)
}