import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        // fill: 'currentColor'
        stroke: 'currentColor', // #4E4F54
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
        marginTop: '-6px',
    }
}));

const ArcIcon = (props) => {
    const classes = useStyles();

    return(
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"
             {...props}
             className={classes.root}
        >
            <path d="M9.88938 13.1361L5.64824 9.31915M5.64824 9.31915L2.25536 4.6539M5.64824 9.31915L10.7376 5.50212" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.1036 13.1361L5.64828 9.31913L2.67947 9.31912" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};

export default ArcIcon;