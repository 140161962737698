import React, {useContext, useEffect, useReducer} from "react";
import {DataStateContext, initialMapState, MapStateContext, mapStateReducer, MapStyleContext} from "../reducer";
import {makeStyles} from "@material-ui/core/styles";
import Mapleftbar from "./map/mapleftbar";
import DataBar from "./map/databar/databar";
import ItemBar from "./map/itembar/itembar";
import MapComponent from "./map/map";
import Data from './data'

const useStyles = makeStyles((theme) => ({
    root: {

    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#2e2e2e'
    },

    loadingLabel: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate (-50%, -50%)',
        textAlign: 'center',
        color: '#4e4e4e',
        fontSize: 32,
    },
}));

const Main = (props) => {
    const classes = useStyles()

    const {isProjectLoaded, currentPage} = props
    const isCurrentPageMap = currentPage === 'map'

    const {mapState, mapDispatch} = useContext(MapStateContext)

    useEffect(() => {
        if (isCurrentPageMap) mapDispatch({ type: 'setup', })

    }, [currentPage])

    if (!isProjectLoaded) {
        return (
            <div className={classes.loadingContainer}>
                <div className={classes.loadingLabel}>Loading...</div>
            </div>
        )
    }

    if (isCurrentPageMap) {
        return (
            <React.Fragment>
                <Mapleftbar />

                <DataBar />

                <ItemBar />

                <MapComponent />
            </React.Fragment>
        )
    }

    return (
        <Data />
    )
}

export default Main