export const HEATMAP_ZOOM_POINT = 11;
export const MAXIMUM_ZOOM = 19;
export const HEATMAP_RADIUS = 30;
export const TRAIL_LENGTH = 800;
export const DEFAULT_HEIGHT = 20;
export const DEFAULT_HEIGHT_SCALE = 1;
export const MAX_HEIGHT = 100;

export const HEIGHT_DEFAULT_STRUCTURE = {
    value: DEFAULT_HEIGHT,
    scale: DEFAULT_HEIGHT_SCALE,
    metricId: null,
    field: null
}