import React, {useContext, useEffect, useState, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {
    Modal,
    Backdrop,
    TextField,
    Fade,
    Card,

    InputAdornment,

    FormHelperText,

    Snackbar,

    Link,
    Badge,

    Paper,

    FormControlLabel,

    CardActionArea,
    CardMedia,
    CardHeader,
    CardActions,
    CardContent,
    Button,
    Typography,
    Avatar,
    IconButton,

    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    Grid,

    Divider,
    Tooltip,

    CircularProgress,

} from '@material-ui/core'

import {
    Alert,
} from '@material-ui/lab'

import {
    Close as CloseIcon,
    ChevronRight, ChevronLeft,
    Visibility,
    VisibilityOff
} from '@material-ui/icons'

import {authAxios} from '../../clients/directAxios'
import {ApiEndpoint} from '../../config'

import {PopupStateContext, AuthStateContext} from "../../reducers";
import {getToken} from "../../actions/auth";
import {useTranslation} from "react-i18next";



const useStyles = makeStyles((theme) => ({
    root: {
        width: '90vw',
        background: theme.color.gray,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100vw',
            width: 'auto',
            maxHeight: '100vh'
            // height: '100vh'
        }
        // maxHeight: '80vh',
    },

    title: {
        margin: 0,
        padding: 0
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    field: {
        width: '100%'
    },

    mainFields: {
        marginBottom: theme.spacing(2)
    },

    metrics: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        background: theme.color.gray
    },

    metricTitleRow: {
        '& .MuiFormControlLabel-label': {
            marginLeft: 8
        }
    },

    listMetricItem: {
        borderBottom: '1px solid rgba(0,0,0,0.12)'
    },

    listItemText: {
        '& span': {
            fontSize: 12
        }
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },

    comments: {},

    commentList: {
        width: '100%',
        // maxWidth: '36ch',
        // backgroundColor: theme.palette.background.paper,
    },

    replyCommentAuthor: {
        display: 'inline',
    },

    card: {
        position: 'relative',
        padding: '0',
        '&:last-child': {
            padding: '0',
        }
    },

    arrowLeft: {
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: 5,
        width: 50,
        height: 50,
        transform: 'translate(0, -25px)'
    },

    arrowRight: {
        position: 'absolute',
        display: 'block',
        top: '50%',
        right: 5,
        width: 50,
        height: 50,
        transform: 'translate(0, -25px)'
    },

    closeButton: {
        position: 'absolute',
        display: 'block',
        top: 5,
        right: 5,
        width: 50,
        height: 50,
    },

    mainPart: {
        // maxHeight: '70vh',
        // marginBottom: 120,
        // overflow: 'hidden'
        [theme.breakpoints.down('md')]: {
            // height: '50vh'
        },
        [theme.breakpoints.down('sm')]: {
            // height: '50vh'
        }
    },

    mainSlideImg: {
        display: 'block',
        width: '100%'
    },

    commentPart: {
        height: 140,
        padding: '0 0 0 16px'
        // position: 'absolute',
        // bottom: 0,
        // left: 0,
        // right: 0
    },

    commentSubpart: {},

    commentSubpartItem: {
        fontSize: '12px',
        marginLeft: 16,

        '&:first-child': {
            marginLeft: 0
        }
    },

    hiddenCommentText: {
        fontSize: 18,
        lineHeight: '26px',
        opacity: 0.5,
    },

    commentLalka: {
        float: 'right',
        opacity: 0.8,
        marginRight: 4,
        '&:hover': {
            opacity: 1
        }
    },

    commentLalkaIcon: {
        color: '#EC5280'
    },

    commentLalkaBadge: {
        '& .MuiBadge-badge': {
            fontSize: '10px',
            height: 16,
            minWidth: 16,
            padding: '0 2px'
        }
    },

    minorRatingIcon: {
        color: '#EC5280'
    },

    majorRatingIcon: {
        color: '#EC5280'
    },

    ratings: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },

    ratingButton: {
        marginLeft: '24px',
        opacity: 0.8,
        '&:hover': {
            opacity: 1
        },
        '&:first-child': {
            marginLeft: 0
        }
    },

    ratingBadge: {
        '& .MuiBadge-badge': {}
    },

    sendCommentButtonWrapper: {
        position: 'relative'
    },

    sendCommentButtonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

    commentField: {
        width: '100%',
    },

    commentButton: {
        // float: 'right'
        width: '100%'
    },

    cardActions: {
        textAlign: 'right',
        padding: 16,
        '& button': {
            marginLeft: 8,
            '&:first-child': {
                marginLeft: 0
            }
        }
    }

}));


const prepareProfile = (me) => (me ? {
    ...me,
    auth: {
        username: me?.meta?.email,
        password: ''
    }
} : null)


const getProfileStructure = (meStruct) => ({
    meta: meStruct?.meta,
    auth: meStruct?.auth
})

const defaultError = {
    common: null,
    fields: {}
}


const boardingUri = 'https://files.lokk-platform.com/boarding/'
const boardingSlides = ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png']

export default function WelcomeTourModal(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const {popupState, popupDispatch} = useContext(PopupStateContext)
    const {welcomeTour} = popupState

    const [currentSlide, setCurrentSlide] = useState(0)

    const isLandscape = window.innerWidth > window.innerHeight
    const isMobile = !isLandscape && window.innerWidth <= 1024

    const currentSlideUrl = boardingUri + (isMobile ? 'm/': '') + boardingSlides[currentSlide]


    const handleClose = () => {
        popupDispatch({
            type: 'closePopup',
            popup: 'welcomeTour'
        })
    }

    const swipeLeft = () => {
        if (currentSlide <= 0) return
        setCurrentSlide(currentSlide - 1)
    }

    const swipeRight = () => {
        if (currentSlide >= boardingSlides.length - 1) return
        setCurrentSlide(currentSlide + 1)
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={welcomeTour.open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={welcomeTour.open}>
                <Card className={classes.root}>
{/*
                    <CardHeader
                        action={
                            <IconButton aria-label="settings" onClick={handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        }
                        title={t('popups.welcome-tour.title')}
                    />
*/}
                    <CardContent className={classes.card}>
                        <div className={classes.mainPart}>
                            <img src={currentSlideUrl} className={classes.mainSlideImg} />
                        </div>
                        <IconButton className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                        <IconButton className={classes.arrowLeft} onClick={swipeLeft}>
                            <ChevronLeft />
                        </IconButton>
                        <IconButton className={classes.arrowRight} onClick={swipeRight}>
                            <ChevronRight />
                        </IconButton>
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    );
}
