import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {isFieldFilterable, isFieldObject, isFieldTimestamp, isFieldUUID} from "../../../../../../helpers/field";
import {isScalarType} from "graphql";
import {RadialChart} from 'react-vis'
import {FormControl, Grid, InputLabel, MenuItem, Select, Slider, Typography} from "@material-ui/core";
import LegendTable from '../../../../../../components/legend-table'
import {DuckDBClient, getDB} from "../../../../../../db";
import {getFormatedValue} from '../../../../../../helpers/repr'
import {gradientTable} from '../../../../../../helpers/color'
import {MetricAggregate, MetricFilters} from './filter'


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 16px'
    },

    pies: {

    },

    piesTitle: {

    },

}))


function MetricPie(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        metric,
        field,
        dict
    } = props

    const colorDomain = gradientTable[20][1]

    const [aggregate, setAggregate] = useState('count')
    const [aggregateField, setAggregateField] = useState('*')
    const [chartData, setChartData] = useState([])

    const [filters, setFilters] = useState({})

    const loadData = async (filters) => {
        const db = await getDB()
        const objectKey = metric?.object?.key

        const filterConditions = Object.entries(filters)
            .filter(([filterName, filterValue]) => (filterValue !== undefined && filterValue !== null && (!Array.isArray(filterValue) || filterValue.length > 0)))
            .map(([filterName, filterValue]) => {
                if (Array.isArray(filterValue)) {
                    if (filterValue.length === 2 && filterValue[0] === filterValue[1]) {
                        return `${filterName} = '${filterValue[0]}'`
                    } else if (filterValue.length === 2 && filterValue[0] !== filterValue[1]) {
                        return `${filterName} between '${filterValue[0]}' and '${filterValue[1]}'`
                    } else {
                        return `${filterName} in (${filterValue.map(val => `'${val}'`).join(', ')})`
                    }
                }

                return `${filterName}='${filterValue}'`
            })
        const whereClause = filterConditions.length > 0 ? `where ${filterConditions.join(' and ')}` : ''

        const query = `
            select
                ${field.name} as field,
                ${aggregate}(${aggregateField}) as value
            from ${DuckDBClient.project_schema}."${metric.cube.name}" c
            ${whereClause}
            group by ${field.name}
        `

        const result = await db.run(query)
        return result.toArray().map(Object.fromEntries)
    }

    useEffect(() => {
        if (!metric) return

        loadData(filters).then(data => {
            const summary = data.reduce((acc, item) => (acc + parseFloat(item.value)), 0)
            setChartData(data.sort((a, b) => a.value < b.value ? 1 : -1).map((item, i) => {
                    const value = parseFloat(item.value)
                    const percent = parseFloat(item.value) / summary * 100
                    const label = dict[item.field]
                    const subLabel = `${getFormatedValue(percent, 2)}%`
                    const title = [label, subLabel]
                    const color = colorDomain[i % colorDomain.length]

                    return {
                        value,
                        label,
                        color,
                        title,
                        subLabel,
                        percent,
                        angle: value,
                        // style: {stroke: '#000000'}
                    }
                }
            ))
        }).catch((e) => console.log('E_c>', e))
    }, [metric, filters, aggregate])


    return (
        <section>
            <h2>{field.title}</h2>
            <Grid container spacing={2}>
                <Grid item>
                    <RadialChart
                        animation={"gentle"}
                        innerRadius={100}
                        radius={125}
                        colorType={'literal'}
                        colorDomain={colorDomain}
                        data={chartData}
                        width={300}
                        height={300}
                        padAngle={0.02}
                        showLabels={false}
                        labelsAboveChildren={false}
                        onValueMouseOver={(segment, e) => {
                            const target = e.event.currentTarget
                            window.e = e.event
                            window.w = target
                            // target.setAttribute('style', target.getAttri);
                            target.style = 'stroke: #000000!important; stroke-width: 2px!important'
                        }}
                        onValueMouseOut={(s, e) => {
                            const target = e.event.currentTarget
                            target.style = ''
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <LegendTable table={chartData} title={`${field.title} legend`} />
                </Grid>
                <Grid item lg>
                    <MetricFilters metric={metric} field={field} onChange={(filters) => {
                        setFilters(filters)
                    }} />
                </Grid>
            </Grid>

        </section>
    )
}


export default function MetricPies(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {metric} = props;

    const metricDicts = metric.cube.dicts.reduce((acc, item) => ({
        ...acc,
        [item.field]: item.values.reduce((valAcc, valItem) => ({...valAcc, [valItem.id]: valItem.title}), {})
    }), {})

    // const [mapState, mapDispatch] = useReducer(mapStateReducer, initialMapState)

    // by dict-fields:
    //      if has_scalar then count(...) avg(...) sum(...)
    //      else count(*),
    //      filter by: timestamp

    // const scalarFields = metric.cube.struct.fields.filter(item => isScalarType(item))
    // const timestampField = metric.cube.struct.fields.find(item => isFieldTimestamp(item))
    const dictFields = metric?.cube?.struct?.fields?.filter(item => isFieldUUID(item) && !isFieldObject(item))

    return (
        <div className={classes.root}>
            {dictFields && dictFields.map((field) => (
                <MetricPie metric={metric} field={field} dict={metricDicts[field.name]} />
            ))}

        </div>
    )
}