import GL from "@luma.gl/constants";
import {
    PathLayer,
} from '@deck.gl/layers';
import {uuidStringify} from "../../../../../../helpers/uuid";


class LOKKPathLayer extends PathLayer {
    //todo: тут какая-то шваль с координатами, они из 0 рисуются
    initializeState(params) {
        super.initializeState(params);
        const attributeManager = this.getAttributeManager();

        attributeManager.addInstanced({
            identity: {size: 16, accessor: 'getIdentity', type: GL.UNSIGNED_BYTE}
        })
    }
    getIdentity() {
        return this.identity
    }
    getPickingInfo(pickParams) {
        const data = super.getPickingInfo(pickParams)

        // const identityData = this.getAttributeManager().getAttributes().identity.value
        const identityData = this.getAttributeManager().getAttributes().identity.state.binaryValue.value
        const identityBytes = identityData.subarray(data.index * 16, data.index * 16 + 16)
        const identity = uuidStringify(identityBytes)

        window.vvv = this.getAttributeManager().getAttributes().identity

        if (data.index === 3434 || data.index === 3438) {
            const ids = []
            for(let iii = 2210; iii < 2240; iii++) {
                const ixxb = identityData.subarray(iii * 16, iii * 16 + 16)
                const ixx = uuidStringify(ixxb)
                ids.push(ixx)
            }

console.log('>> >> >>', {
    i: data.index,
    b: identityBytes,
    idl: this.getAttributeManager().getAttributes().identity.length,
    ids,
    identity
})
window.bbb = identityData
        }

        return {...data, identity}
    }
    shouldUpdateState(params) {
        const {props, oldProps, context, changeFlags} = params
        return  super.shouldUpdateState(params)
    }
}


LOKKPathLayer.defaultProps = {
    ...LOKKPathLayer.defaultProps,
    getIdentity: {type: 'accessor', value: d => d.identity}
}

export default LOKKPathLayer;