import React, {useState, useEffect, useReducer, useContext} from "react";
import {
    Button,
    Select, MenuItem, InputLabel, TextField,
    Accordion, AccordionSummary, AccordionDetails,
    Checkbox,
    Paper,
    BottomNavigation,
    BottomNavigationAction,
    Tooltip,
    FormControl,
    Typography,
    Slider

} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {
    ArcIcon,
    HeatmapIcon,
    LineIcon,
    PointsIcon,
    PolygonIcon,
    TrailIcon,
    ObjectIcon
} from '../../../../../../../icons'

import {DEFAULT_LAYER_TYPE} from '../../../../../../../models/layer'
import {useTranslation} from "react-i18next";


const iconButtonClass = (theme) => ({
    flex: 1,
    color: 'rgba(0, 0, 0, 0.54)',
    background: theme.color.gray,
    padding: '15px',
    boxSizing: 'border-box',
    maxWidth: '20px',
    minWidth: '20px',
    width: '20px',
    height: '20px',
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    border: 0,
    cursor: 'pointer',
    margin: '0 10px 0 0',
    display: 'inline-flex',
    outline: 0,
    position: 'relative',
    alignItems: 'center',
    userSelect: 'none',
    borderRadius: 0,
    verticalAlign: 'middle',
    justifyContent: 'center',

    textDecoration: 'none',
    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
    '-webkit-tap-highlight-color': 'transparent',
})


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 10px'
    },

    title: {
        margin: '0 0 10px 0',
        padding: 0
    },

    buttonBar: {
        display: 'flex',
        justifyContent: 'start'
    },

    iconButton: {
        ...iconButtonClass(theme),

        // '&:first-child': {
        //     marginLeft: 0
        // },

        '&:hover': {
            background: theme.color.semiGray
        },
    },

    iconButtonActive: {
        ...iconButtonClass(theme),

        background: theme.color.semiGray
    },

    displayModeControl: {
        background: theme.color.gray,

    },
}));


const layerTypeIcons = {
    'polygon': <PolygonIcon />,
    'heatmap': <HeatmapIcon />,
    'point': <PointsIcon />,
    'trail': <TrailIcon />,
    'line': <LineIcon />,
    'arc': <ArcIcon />,
    'object': <ObjectIcon />
}

const FormSelector = function (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const title = props.title ? props.title : t('project.leftbar.panel2.form')
    const { layerTypes } = props.objectType

    const defaultLayerType = layerTypes.find(item => item.is_default)?.key || DEFAULT_LAYER_TYPE
    const layerFormType = props.value ? props.value : defaultLayerType;
    // const [layerFormType, setLayerFormType] = React.useState(props.value ? props.value : defaultLayerType);

    return (
        <div className={classes.root}>
            <h5 className={classes.title}>{title}</h5>
            <div className={classes.buttonBar}>
                {
                    layerTypes
                        .filter((item) => item.key !== 'trail') // todo: temporary disable trail
                        .map((item, tooltipIndex) => (
                        <Tooltip title={t(`project.leftbar.panel2.${item.meta.title?.toLowerCase()}`)} key={tooltipIndex}>
                            <button
                                className={item.key === layerFormType ? classes.iconButtonActive : classes.iconButton}
                                onClick={(e) => {
                                    // setLayerFormType(item.key)
                                    if(props.onChange) props.onChange(item.key)
                                }}
                            >
                                {layerTypeIcons[item.icon]}
                            </button>
                        </Tooltip>
                    ))
                }
            </div>
        </div>
    );
}

export default FormSelector;