import React, {useContext, useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import {
    Typography,
    Slider, Avatar, Badge
} from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MapStateContext, DataStateContext } from "../../../reducer";
import { getValueRepr } from '../../../../../../helpers/repr'
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";


const Accordion = withStyles((theme) => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
    root: {
        background: theme.color.gray,
        border: 'none',
        marginBottom: -1,
        minHeight: 20,
        padding: '0 10px',
        '&$expanded': {
            background: theme.color.semiGray,
            minHeight: 20,
        },
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: 0,
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        display: 'block',
        marginTop: 10,
        padding: 0,
    },
}))(MuiAccordionDetails);


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 10px'
    },
    container: {
        // display: 'flex',
        // flexWrap: 'wrap',
    },
    litera: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },
    metricName: {
        marginLeft: theme.spacing(1)
    },
    fieldName: {
        marginLeft: theme.spacing(2)
    },
    selectControlPanel: {

    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 260,
    },
    dialogContent: {
        overflow: 'hidden'
    },
    addButtonContainer: {
        boxSizing: 'border-box',
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    subtitle: {
        margin: 0,
        padding: '0 24px'
    },
    selectControl: {
        width: 200,
    },
    okButtonLayout: {
        verticalAlign: 'bottom'
    },
    okButton: {
        padding: '4px 0',
        width: 60,
        minWidth: 60,
        borderRadius: 0
    },
    addFilterSpan: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.75,
        textTransform: 'uppercase',
    },
    addFilterLayout: {
        margin: 0
    }
}));

function valuetext(value) {
    return getValueRepr(value)  // , ' °C'
}

export default function LayerFilterDialog(props) {
    const classes = useStyles();
    const { mapState, mapDispatch } = useContext(MapStateContext)
    const { dataState, dataDispatch } = useContext(DataStateContext)
    const { layerId, activeFilters } = props;
    const currentLayer = mapState.layers[layerId]

    const [open, setOpen] = React.useState(false);
    const [filterMetric, setFilterMetric] = React.useState(null);
    const [filterMetricField, setFilterMetricField] = React.useState(null);

    const currentDataset = mapState.datasets[props.layerId];
    const { metrics: datasetMetrics  } = currentLayer;
    const { cube: dataCubes } = dataState;
    // const currentMetric = colorMetricId ? datasetMetrics.find(item => item.metric?.id === colorMetricId) : null

    const currentMetric = datasetMetrics.find(item => item.id === filterMetric)
    const currentCube = dataCubes[currentMetric?.id]
    const cubeFields = currentCube?.struct?.fields

    const metricMin = currentCube?.stats[filterMetricField]?.min;
    const metricMax = currentCube?.stats[filterMetricField]?.max;

    const [value, setValue] = React.useState([metricMin || 0, metricMax || 0]);

    // const activeFilterIds = Object.keys(activeFilters);

    useEffect(() => {
        if (filterMetric && filterMetricField) {
            setValue([metricMin, metricMax])
        }
    }, [filterMetric, filterMetricField])

    const handleMetricChange = (event) => {
        setFilterMetric(event.target.value || null);
    };

    const handleMetricFieldChange = (event) => {
        setFilterMetricField(event.target.value || null);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setValue([0,0]);
        setFilterMetric(null);
    };

    const handleOk = () => {
        setOpen(false);
        setValue([0,0]);
        setFilterMetric(null);
        setFilterMetricField(null);

        setExpanded(false)

        mapDispatch({
            type: 'addLayerFilter',
            metricId: filterMetric,
            metric: currentMetric,
            fieldName: filterMetricField,
            field: cubeFields.find(item => item.name === filterMetricField),
            layerId,
            stats: currentCube?.stats[filterMetricField],
            dict: currentCube?.dicts.find(item => item.field === filterMetricField),
            value
        })
    }

    const [expanded, setExpanded] = React.useState(false);

    const handleValueChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>

            <Accordion square expanded={expanded} onChange={(event, isExpanded) => {
                setExpanded(isExpanded);
            }}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <span className={classes.addFilterSpan}>Add filter</span>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl className={classes.selectControlPanel}>
                        <InputLabel id={'alg-' + props.layerId + '-label'}>On metric:</InputLabel>
                        <Select
                            // native
                            value={filterMetric}
                            onChange={handleMetricChange}
                            // input={<Input id="demo-dialog-native" />}
                            className={classes.selectControl}
                            labelId={'layer-' + props.layerId + '-filter-based-on-label'}
                            id={'layer-' + props.layerId + '-filter-based-on-value'}
                        >
                            <MenuItem aria-label="None" value="" />
                            {
                                datasetMetrics && datasetMetrics
                                    // .filter(item => !activeFilterIds.includes(item.id))
                                    .map((item, mi) => (
                                        <MenuItem key={mi} value={item.id}>
                                            <Avatar style={{background: item.color}}
                                                    className={classes.litera}>
                                                {item.litera}
                                            </Avatar>
                                            <span className={classes.metricName}>{item.metric.meta.title}</span>
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl className={classes.selectControlPanel}>
                        <InputLabel id={'alg-' + props.layerId + '-field-label'}>On field:</InputLabel>
                        <Select
                            // native
                            value={filterMetricField}
                            onChange={handleMetricFieldChange}
                            className={classes.selectControl}
                            labelId={'layer-' + props.layerId + '-filter-based-on-fld-label'}
                            id={'layer-' + props.layerId + '-filter-based-on-fld-value'}
                        >
                            <MenuItem aria-label="None" value="" />
                            {
                                cubeFields && cubeFields
                                    .filter((item, fi) => fi > 0)
                                    .map((item, fi) => (
                                        <MenuItem key={fi} value={item.name}>
                                            {item.is_key && <Badge badgeContent='key' />}
                                            <span className={classes.fieldName}>{item.title}</span>
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl className={classes.okButtonLayout}>
                        <Button onClick={handleOk} color="primary" className={classes.okButton}>
                            Ok
                        </Button>
                    </FormControl>
                </AccordionDetails>
            </Accordion>


{/*
            <div className={classes.addButtonContainer}>
                <Button onClick={handleClickOpen}
                        // variant="contained"
                        variant="outlined"
                        color="primary">Add filter</Button>
            </div>

            <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>Filtering</DialogTitle>
                <h4 className={classes.subtitle}>{props.title}</h4>
                <DialogContent className={classes.dialogContent}>
                    <form className={classes.container}>
                        <div>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="demo-dialog-native">Filter based on:</InputLabel>
                                <Select
                                    // native
                                    value={filterMetric}
                                    onChange={handleMetricChange}
                                    // input={<Input id="demo-dialog-native" />}

                                    labelId={'layer-' + props.layerId + '-filter-based-on-label'}
                                    id={'layer-' + props.layerId + '-filter-based-on-value'}
                                >
                                    <MenuItem aria-label="None" value="" />
                                    {
                                        datasetMetrics && datasetMetrics
                                            .filter(item => item.metric)
                                            .filter(item => !activeFilterIds.includes(item.metric.id))
                                            ._map((item, mi) => <MenuItem key={mi} value={item.metric.id}>{item.metric.meta.title}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        {filterMetric &&
                        <div>
                            <FormControl className={classes.formControl}>
                                <Typography id="range-slider" gutterBottom>
                                    Values:
                                </Typography>
                                <Slider
                                    value={value}
                                    onChange={handleValueChange}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                    getAriaValueText={valuetext}
                                    valueLabelFormat={valuetext}
                                    // getAriaLabel={valuetext}
                                    min={metricMin}
                                    max={metricMax}
                                />
                            </FormControl>
                        </div>
                        }
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleOk} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
*/}
        </div>
    );
}