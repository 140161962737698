import React, {useState, useEffect} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import { useHistory } from 'react-router-dom'
import {
    IconButton,
    Card,
    CardActions,
    CardContent,
    CardActionArea,
    Container,
    Typography,
    Button,
    Grid,

    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,

    Snackbar
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import {
    AddCircle,
    Delete,
    DeleteForever
} from '@material-ui/icons'

import css from 'styled-components'

import { appConfig, ApiEndpoint, webLink } from '../../config'
import apolloClient from '../../clients/apolloClient'
import { ProjectsQuery } from '../../actions/app/gql/app'

import TopBar from '../../components/topbar'
import {DeleteStep} from "../../actions/gql/step";
import {ProjectQuery, ProjectDelete} from "../../actions/app/gql/projectQuery";
import {GetMe} from "../../actions/app/gql/data";
import {isManager} from '../../helpers/user'
import {useTranslation} from "react-i18next";

const ClickArea = css.div`
    cursor: pointer;
`

const PlusIconPlace = css.div`
    height: 120px;
    text-align: center;
`

const useStyles = makeStyles((theme) => ({
    root: {

    },
    panel: {
        position: 'relative',
        boxSizing: 'border-box',
        padding: '120px 0 80px 0',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#29323C'
    },
    title: {
        boxSizing: 'border-box',
        padding: '0 0 10px 0',
        margin: '0',
        ...theme.typography.h3
    },
    lead: {

    },
    card: {
        width: 275,
        background: '#DEE6F1',
        minHeight: 170,
    },
    cardNew: {
        width: 275,
        background: '#DEE6F1',
        minHeight: 170,
    },
    prefix: {
        display: 'inline-block',
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 'bold'
    },
    about: {
        height: '3rem'
    },
    pos: {
        marginBottom: 12,
    },
}));


const DeleteDialog = (props) => {
    const {
        onAgree,
        onCancel,
        title,
        isOpened
    } = props
    const { t } = useTranslation();

    return (
        <Dialog open={isOpened}>
            <DialogTitle>{t('projects.popup.delete.title', {title})}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('projects.popup.delete.text')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onCancel}>
                    {t('projects.popup.delete.cancel')}
                </Button>
                <Button autoFocus onClick={onAgree} color="primary">
                    {t('projects.popup.delete.delete')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


const NewProjectCard = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    return (
            <Card className={classes.cardNew}>
                <ClickArea onClick={() => {
                    history.push(webLink('project', 'new'))
                }}>
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom className={classes.prefix}>
                            {t('projects.new_project')}
                        </Typography>
                        <PlusIconPlace>
                            <AddCircle style={{
                                'fontSize': '96px',
                                'margin': '12px 0 0 0',
                                'opacity': 0.3,
                            }} />
                        </PlusIconPlace>
                    </CardContent>
                </ClickArea>
            </Card>
    )
}

const ProjectCard = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const { item, onDelete, canDelete } = props;

    const [ projectDelete ] = useMutation(ProjectDelete, { client: apolloClient })

    const [isDialogOpened, setDialogOpened] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const handleDeleteProject = () => {
        projectDelete({
            variables: {
                project_id: item.id
            }
        }).then(() => {
            if (onDelete) {
                onDelete(item.id)
            }

            setDialogOpened(false)
        }).catch((err) => {
            console.log('err>', err)
            errorMessage(err.message)
        })
    }

    const handleCancel = () => {
        setDialogOpened(false)
    }

    return (
            <Card className={classes.card} key={item.id}>

                <ClickArea onClick={() => {
                    history.push(webLink('project', item.key))
                }}>
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom className={classes.prefix}>
                            {t('projects.project')}
                        </Typography>
                        <h4 className={classes.title}>
                            {item.meta.title}
                        </h4>
                        <Typography variant="body2" component="p" className={classes.about}>
                            {item.meta.about}
                        </Typography>
                    </CardContent>
                </ClickArea>
                    <CardActions>
                        <Grid container justify="space-between">
                            <Button size="small" onClick={() => {
                                history.push(webLink('project', item.key))
                            }}>{t('projects.learn_more')}</Button>

                            {canDelete &&
                            <IconButton size="small" color="secondary" onClick={() => {
                                setDialogOpened(true)
                            }}>
                                <DeleteForever />
                            </IconButton>
                            }
                        </Grid>
                    </CardActions>

                <Snackbar

                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={errorMessage !== null}
                    message={errorMessage}
                />

                <DeleteDialog
                    title={item.meta.title}
                    onClose={() => setErrorMessage(null)}
                    isOpened={isDialogOpened}
                    onAgree={handleDeleteProject}
                    onCancel={handleCancel}
                    />
            </Card>
    );
}

// Viewport settings
function Map() {
    const classes = useStyles()

    // const isNotBlank = query && query !== "";
    const { data, loading, error, refetch } = useQuery(ProjectsQuery, {
        fetchPolicy: "no-cache",
        client: apolloClient,
        // variables: {},
        // skip: !isNotBlank,
    });


    const {data: meData, loading: meLoading, error: meError } = useQuery(GetMe, {
        client: apolloClient,
        fetchPolicy: "no-cache",
    })

    const [me, setMe] = useState({})
    useEffect(() => {
        if (!meData || meLoading) return;
        setMe(meData.me[0])
    }, [meData])

    const showAddProject = isManager(me)
    const canDeleteProject = isManager(me)

    const handleProjectDelete = () => {
        refetch().catch(() => {})
    }

    return (
        <>

            <TopBar />

            <div className={classes.panel}>
                <Container>
                    <Grid container
                          spacing={3}
                          direction="row"
                          justify="center"
                          alignItems="flex-start"
                    >
                        {data?.appProjects.map((item, i) => {
                            return (
                                <Grid item key={i}>
                                    <ProjectCard item={item}
                                                 onDelete={handleProjectDelete}
                                                 canDelete={canDeleteProject}
                                    />
                                </Grid>
                            )
                        })}
                        {showAddProject &&
                        <Grid item key={-1}>
                            <NewProjectCard />
                        </Grid>
                        }
                    </Grid>
                </Container>
            </div>


        </>
    );
}

export default Map;